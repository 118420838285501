import React, { useContext } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { AuthContext } from "../../context/Contexts";
import { useAuth } from "../../hooks/useAuth";
import { useProjects, useProjectsApi } from "../../hooks/useProject";
import { VerifyFrame } from "../modal-dialog/VerifyFrame";
import "./ListProjects.scss";
import moment from 'moment';
import * as globals from "../../globals";

const getSearchString = (project) => `${project.label} ${project.client}`.toLocaleLowerCase();

const isSearchMatch = (project, searchTerms) => {
    
    const projectStr = getSearchString(project);
    for(let term of searchTerms){
        if(!projectStr.includes(term)){
            return false;
        }
    };
    return true;
}



export const ListProjects = (props) => {
    
    const history = useHistory();
    const routerParams = useParams();

    const authContext = useContext(AuthContext);
    let subscription_id = globals.player_sub || routerParams.subscription_id;
    
        
    //const [projects, projectsLoading, projectsError] = useProjects(subscription_id);
    const [projects, projectsLoading, projectsError] = useProjects(subscription_id);
    const [groupByClient, setGroupByClient] = useState(true);
    const api = useProjectsApi();

    
    

    const [searchText, setSearchText] = useState(null);

    const [searchTerms, setSearchTerms] = useState(null);

    const debouncedUpdateSearchTerms = (text) => {
        // todo: debounce
        setSearchTerms(text?.toLocaleLowerCase().split(" "));
    }

    const byLabel = (a, b) => a.label?.localeCompare(b.label);

    let filteredProjects = projects;
    if( searchTerms?.length > 0 ){
        filteredProjects = projects.filter(p => isSearchMatch(p, searchTerms));
    }

    filteredProjects = Array.isArray(filteredProjects) ? filteredProjects : null; // prevent crash when expired token
    const orderedProjects = filteredProjects ? [...(filteredProjects || [])]?.sort(byLabel) : null;

    const groupBy = groupByClient ? "subscription_label" : null;
    //const groupBy2 = groupByClient ? "client" : null;
        

    let groupedProjects = [];
    orderedProjects?.forEach(proj => {
        //const groupValue = (proj[groupBy] || "") + (proj[groupBy2] || "")
        const groupValue = proj[groupBy]
        let findGroup = groupedProjects.find(g => g.value === groupValue);
        if (!findGroup) {
            findGroup = {
                value: groupValue,
                label: groupValue,
                projects: []
            }
            groupedProjects.push(findGroup);
        }
        findGroup.projects.push(proj);
        //findGroup.projects = findGroup.projects.sort(byLabel);
    })

    // sort groups
    groupedProjects = groupedProjects.sort(byLabel);

    
    return <div className="list-projects">
        <div className="actions table-actions">

            {/* <span className='subscription' >
                <select
                    //className="fa"
                    style={{
                        width: 250,
                        padding: "5px 10px",
                        marginRight: "10px"
                    }}
                >
                    <option>All Subscriptions</option>
                </select>

                <select
                    //className="fa"
                    style={{
                        width: 250,
                        padding: "5px 10px",
                        marginRight: "10px"
                    }}
                >
                    <option>All Clients</option>
                </select>
            </span> */}

            <span className='search' >
                {/* <i className="fad fa-search"/> */}
                <input type="text"
                    //className="fa"
                    style={{
                        width: "100%",
                        borderRadius: 10,
                        padding: "5px 10px",
                    }}
                    placeholder="Search projects"
                    value={searchText || ""}
                    onChange={ev => {
                        const newText = ev.target.value;
                        setSearchText(newText);
                        debouncedUpdateSearchTerms(newText);
                    }}
                />
            </span>

            <span className="filter nowrap">
                <span className="btn icon no-text margin-right">
                    <i className="fas fa-filter"/>
                </span>
                <span className={`btn icon no-text ${groupByClient ? "sel" : ""}`}
                    onClick={ev => setGroupByClient(!groupByClient)}
                >
                    <i className="fas fa-building"/>
                </span>
            </span>

            <span 
                className='btn action nowrap' 
                onClick={async () => {
                    console.log('clicked Create New Project');
                    // todo: open new project window
                    const newInfo = await api.newItem({ label: "New Project" }, null, { subscription_id });
                    const newId = newInfo?.newId;
                    history.push(`/subscription/${subscription_id}/project/${newId}`);
                }}
            >
                Create new project
            </span>
        </div>

        <div className="list">
            {projectsLoading ? <div className='projects-message'>Loading projects...</div> :
            projectsError ? <div className='projects-message error'>Error loading projects: {projectsError}</div> :
                <div className='projects-list'>
                    {groupedProjects.map((group, groupIdx) =>
                        <React.Fragment key={`group___${group.label}`}>
                            {group.label ?
                                <div className="group-header">
                                    {group.label}
                                </div>
                            : null}
                            <div className="group">
                            {
                                group.projects.map(project => {

                                    const sub_id = project.subscription_id || subscription_id;
                                    const sub_label = project.subscription_label;

                                    return <div className='project-block' key={project.id}>
                                        <Link 
                                            className='full-label'
                                            to={`/subscription/${sub_id}/project/${project.id}`} 
                                        >
                                            {!groupByClient && sub_label ? 
                                                <div className='subscription-label'>
                                                    {sub_label}/
                                                </div> 
                                                : null
                                            }
                                            <div className='project-label'>
                                            {project.label}
                                            </div>
                                            <div className='client-label'>
                                                {project.date ? <>
                                                    <span className="date">{project.date}</span>
                                                    <span className="separator">|</span>
                                                </> : null}
                                                <span className="clabel">{project.client || "Client unspecified"}</span>
                                            </div>
                                            <div className='project-modified'>
                                                Created {moment.utc(project.created).local().fromNow()}
                                            </div>

                                            {/* <div className="project-actions">
                                                <span className="btn toggle small light margin-right">
                                                    Edit
                                                </span>
                                                <span className="btn toggle small light margin-right">
                                                    View
                                                </span>
                                            </div> */}
                                        </Link>
                                        
                                        <div className='actions'>
                                            {/* <span className='action-btn'>
                                                <i className='fal fa-star' />
                                            </span> */}
                                            <Popup trigger={
                                                <span className='action-btn'>
                                                    <i className='fal fa-ellipsis-v' />
                                                </span>}
                                                position="bottom right"
                                                >
                                                <div className="popup-menu">
                                                    <div className="menu-item">Clone project</div>
                                                    <div className="separator"/>
                                                    <Popup modal trigger={
                                                        <div className="menu-item">Delete project</div>
                                                    }>
                                                        {closeModal => <VerifyFrame
                                                            title={"Delete project"}
                                                            label={project.label}
                                                            close={closeModal}
                                                            confirmed={async () => {
                                                                await api.deleteItem(project.id);
                                                            }}
                                                        />}
                                                    </Popup>
                                                </div>
                                            </Popup>
                                        </div>
                                    </div>;
                                })
                            }
                            </div>
                        </React.Fragment>
                    )}
        </div>
            }
        </div>
    </div>;
}