import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useHistory } from 'react-router-dom';
import { controllers } from '../../api/controllers';
import { EditDataSourceContext } from '../../context/Contexts';
import { useAuth } from '../../hooks/useAuth';
import { useDataSource, useDataSourcesApi } from '../../hooks/useDataSource';
import { useDataSourceRevision } from '../../hooks/useDataSourceRevision';
import { useProjectId } from '../../hooks/useProjectId';
import { useQuery } from '../../hooks/useQuery';
import { DataSourceRevisionSelector } from './DataSourceRevisionSelector';
import "./ViewRevisionInfo.scss";


export const ViewRevisionInfo = (props) => {

    const dsContext = useContext(EditDataSourceContext);
    const ds = dsContext?.ds;
    

    const [rev, revLoading, revError, revRefresh] = useDataSourceRevision(ds?.id, dsContext.selectedRevisionId);
    const info = rev ? {
        ...rev,
        fields: rev.fields, // ? "<hidden>" : undefined,
        inspection: rev.inspection, // ? "<hidden>" : undefined,
        udata: rev.udata ? "<hidden>" : undefined
    } : null;

    return <div className="view-revision-info">
        <div className="heading-pane">
            <DataSourceRevisionSelector width={400} />
        </div>
        <div className="flow-section">
            <div style={{ marginBottom: 10 }}>
                <ReactJson src={ds}
                    name={"dataset"}
                    theme={"rjv-default"}
                    iconStyle="circle"
                    displayDataTypes={false}
                    enableClipboard={true}
                    collapsed={1}
                    />
            </div>
            {/* <pre>
                dataset:&nbsp;  
                {JSON.stringify(ds, null, 4)}
            </pre> */}
            {revLoading ? "loading..." :
                <ReactJson src={info}
                    name={"revision"}
                    theme={"rjv-default"}
                    iconStyle="circle"
                    displayDataTypes={false}
                    enableClipboard={true}
                    collapsed={1}                
                    />
                // <pre>
                //     revision:&nbsp; 
                //     {JSON.stringify(info, null, 4)}
                // </pre>
            }
        </div>
    </div>
    
    
}


