import { useEffect, useState } from "react";
import { LineChart, ResponsiveContainer } from "recharts";
import { useRecoilValue } from "recoil";
import { useLazyLib } from "../../hooks/useLazyLib";
import { runRender } from "../../utils/ContentProcessor";
import { defaultLib } from "../../utils/Lib";
import { computeContextState } from "./ComputeContext";

const api = {};

export const LineChart1 = (props) => {

    const item = props.item;
    const style = { margin: 0, ...props.style, ...item?.style }
    const display = props.display;
    const displayClass = props.displayClass;
    const jdata = item.jdata;
    
    const width = (display === "inline-block" ? 400 : "100%");
    const height = (display === "block-fill" ? "100%" : 300);

    const [rendered, setRendered] = useState(null);

    const rResources = props.resources;
    
    const render = item?.jdata?.render;

    const lib2 = useLazyLib();
    const lib = {
        ...defaultLib,
        ...lib2
    };
    const context = {};

    // get data from hook
    const cHook = item?.jdata?.contextHooks?.[0];
    const ccState = useRecoilValue(computeContextState(cHook?.id));

    useEffect(() => {
        if( !lib2 ) return;
        if( !render ) return;
        (async () => {

            // render
            const r = runRender(render, context, rResources, api, lib, ccState);
            setRendered(r || null);

        })();

    }, [render, ccState, lib2, rResources]); // [item, render, rResources, value, lib2]);


    const fallback = <LineChart />;

    return <div className={`arbitrary-chart ${displayClass}`} style={style} {...props.atts}>
        <ResponsiveContainer width={width} height={height}>
            {rendered || fallback}
            {/* <LineChart
                data={data1}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 20,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart> */}
        </ResponsiveContainer>
    </div>
};
