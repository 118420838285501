import { constructs } from "../api/contructs";
import { createSyncedApiHook } from "../api/createSyncedApiHook";
import { createUseItemHook, createUseItemsHook } from "./createUseItemsHook";

const construct = constructs.page;

export const usePage = createUseItemHook(construct);
export const usePages = createUseItemsHook(construct);
export const usePagesApi = createSyncedApiHook(construct);


export const useGetPageIdByUrl = (subscription_id, project_id, parent_id, url) => {

    //console.log('useGetPageIdByUrl', subscription_id, project_id, parent_id, url);

    const [ parentPage, parentLoading, parentError ] = usePage(parent_id);

    const [ pages, pagesLoading, pagesError ] = usePages();
    
    const loading = parentLoading || pagesLoading;
    const error = parentError || pagesError;

    const entries = parent_id ? parentPage?.children : pages;
    //console.log('entries', entries);

    const entry = entries?.find(entry => entry.url === url) || entries?.[0];
    //console.log('entry', entry);

    const id = entry?.id;

    return [
        id,
        loading,
        error
    ]

}

