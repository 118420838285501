import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import * as restMethods from "../../api/restMethods";
import * as globals from "../../globals";
import { useAuth } from "../../hooks/useAuth";
import { useDataSource, useDataSourceApi } from "../../hooks/useDataSource";
import { useDataSourceRevisions } from "../../hooks/useDataSourceRevision";
import { useProjectId } from "../../hooks/useProjectId";
import { DataSourceRevision } from "./DataSourceRevision";
import "./DataSourceRevisions.scss";
import { UploadDataFileModal } from "./UploadDataFileModal";

export const DataSourceRevisions = (props) => {

    const id = props.id;
    const [ds, dsLoading, dsError, dsTriggerReload] = useDataSource(id);
    const [revisions, revsLoading, revsError, revsTriggerReload] = useDataSourceRevisions(id);

    const { subscription_id, project_id } = useProjectId();

    const srevisions = revisions?.sort?.((a, b) => b.id - a.id);
    const active_revision_id = srevisions?.length > 0 ? srevisions[0].id : null;

    const reload = () => {
        //console.log("DataSourceRevisions.reload called");
        dsTriggerReload(); // loading ds in case pointers moved
        //revsTriggerReload();
    }

    useEffect(() => {
        //console.log("ds changed");
        revsTriggerReload?.();
    }, [ds])

    useEffect(() => {
        //console.log("revisions changed");
    }, [revisions])


    return <div className="data-source-revisions">
        <div className="actions-header">
            <Popup
                contentStyle={{
                    width: 500
                }}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                trigger={
                    <span className="btn action margin-right"
                        onClick={() => {
                            //alert("not implemented");
                        }}
                    >
                        Upload... &nbsp;<i className="fal fa-arrow-to-top" />
                    </span>
                }
                modal
            >
                {close => <UploadDataFileModal
                    ds={ds}
                    id={id}
                    close={close}
                    reload={reload}
                />}
            </Popup>
            <span className="btn action-light"
                onClick={() => reload()}
            >
                <i className="fad fa-sync" />
                &nbsp; Refresh
            </span>
        </div>
        <div className="revisions-table">
            <div className="width-limit">
                {revsLoading ? <span className="message">Loading revisions...</span> : null}
                {revsError ? <span className="message">{revsError}</span> : null}
                {srevisions?.map((rev, revIndex) => {
                    const active = active_revision_id === rev.id;

                    const right_rev = revIndex > 0 ? srevisions[revIndex - 1].id : null;
                    const left_rev = srevisions[revIndex].id;
                    const query = `left_ds=${ds.id}&left_rev=${left_rev}&right_ds=${ds.id}&right_rev=${right_rev}`

                    const _right_rev = rev.id; // revIndex > 0 ? srevisions[revIndex - 1].id : null;
                    const _left_rev = revIndex < srevisions.length - 1 ? srevisions[revIndex + 1].id : null;
                    const _query = `left_ds=${ds.id}&left_rev=${_left_rev}&right_ds=${ds.id}&right_rev=${_right_rev}`
                    const compareUrl = revIndex < srevisions.length - 1 ? `/subscription/${subscription_id}/project/${project_id}/tool/schema-diff?${_query}` : null;
                    
                    return <React.Fragment key={rev.id}>

                        {false && revIndex > 0 ?
                            <div className="rev-separator">
                                <div className="dots"/>
                                <Link 
                                    className="compare-link"
                                    to={`/subscription/${subscription_id}/project/${project_id}/tool/schema-diff?${query}`}
                                >
                                    <i className="fal fa-code-compare" /> compare
                                </Link>
                                <div className="dots"/>
                            </div>
                        : null}

                        <DataSourceRevision ds={ds} ds_id={ds.id} id={rev.id} entry={rev} reload={reload} compareUrl={compareUrl} />
                        
                    </React.Fragment>
                }
                )}
            </div>
        </div>
    </div>
}