import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constructs } from "../api/contructs";
import { createSyncedApiHook } from "../api/createSyncedApiHook";
import { createUseItemHook, createUseItemsHook } from "./createUseItemsHook";

const construct = constructs["config"];

export const useConfig = createUseItemHook(construct);
export const useConfigs = createUseItemsHook(construct);
export const useConfigsApi = createSyncedApiHook(construct);


export const useConfigState = (id, configId) => {

    const dispatch = useDispatch();
    const [config, configLoading, configError] = useConfig(configId);

    const configState = useSelector(state => state.configState[id]);
    //const [stateLoading, setStateLoading] = useState(false);
    //const [stateError, setStateError] = useState(null);
    
    const configStateLoading = !configState && configLoading;
    const configStateError = !configState && configError;

    useEffect(() => {
        
        if (configState) return;
        if (!config) return;
        if (configLoading) return;
        if (configError) return;
        
        // load a state for this pup from the basecase
        const value = config.baseCase;
        dispatch({
            type: "CONFIG_STATE_SET",
            id,
            value
        })

    }, [id, config, configLoading, configError])

    return [configState, configStateLoading, configStateError];

}

