import { useState } from "react";
import { ModalFrame } from "../modal-dialog/ModalFrame"

export const NewFilterDlg = (props) => {

    const [filter, setFilter] = useState(props.filter);

    return <ModalFrame close={props.close}
        title={props.title || "New Filter"}
        buttons={[
            {
                key: "okay",
                label: "Okay",
                callback: () => {
                    props.onSave?.(filter);
                }
            },
            // {
            //     key: "cancel",
            //     label: "Cancel",
            //     callback: () => {
            //         props.close?.();
            //     }
            // },

        ]}
    >
        <div className='form1' style={{ maxWidth: 500 }}>
            <label className="label-line">
                Label:
            </label>
            <div className="value-line">
                <input type="text"
                    autoFocus
                    className='full-width'
                    value={filter.label}
                    onChange={(ev) => {
                        const newFilter = {
                            ...filter,
                            label: ev.target.value
                        }
                        setFilter(newFilter);
                    }}/>
            </div>

            <label className="label-line">
                Syntax:
            </label>
            <div className="value-line">
                <input type="text"
                    className='full-width code' 
                    value={filter.syntax}
                    onChange={(ev) => {
                        const newFilter = {
                            ...filter,
                            syntax: ev.target.value
                        }
                        setFilter(newFilter);
                    }}/>
            </div>
        </div>
    </ModalFrame>
    
}