import { useContext, useState } from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { controllers } from "../api/controllers";
import { AuthContext } from "../context/Contexts";
//import { apis, useApi } from "../api/_apis";
import { genericParseEntry } from "../store/CreateGenericReducer";
import { useAuth } from "./useAuth";
import { useProjectId } from "./useProjectId";

export const createUseItemsHook = (options) => () => {

    const name = options.name;
    const namePlural = options.namePlural || name + 's';
    const storeName = options.storeName || namePlural;
    const reduxNamePlural = namePlural.toUpperCase();
    
    const [reloadCounter, setReloadCounter] = useState(1);
    const [lastReload, setLastReload] = useState(1);

    const { project_id, subscription_id } = useProjectId();
    const dispatch = useDispatch();
    //const api = useApi();
    const controller = controllers[name];
    const auth = useAuth();
    const authContext = useContext(AuthContext);
    
    const { entries, loading, error } = useSelector(state => ({
        entries: state[storeName].entries,
        loading: state[storeName].loading,
        error: state[storeName].error
    }), shallowEqual)

    useEffect(() => {
        const forceReload = reloadCounter != lastReload;

        if (project_id && !loading && (forceReload || (!error && !entries))) {
            dispatch({ type: `${reduxNamePlural}_SET_LOADING_ENTRIES` });
            (async () => {
                try {
                    const entries = await controller.auth_getItems(auth, { subscription_id, project_id });
                    if( entries?.error ){
                        if( entries.error === "invalid_token"){
                            dispatch({ type: "INVALID_TOKEN_ENCOUNTERED" });
                            authContext.getNewToken();
                        }
                        throw Error(entries.error);
                    }

                    dispatch({ type: `${reduxNamePlural}_SET_ENTRIES`, entries });
                }
                catch (err) {
                    dispatch({ type: `${reduxNamePlural}_SET_LOADING_ENTRIES`, loading: false, error: err.message });
                    //dispatch({ type: `${reduxNamePlural}_SET_ENTRIES`, entries: null, error: err.message });
                }
                setLastReload(reloadCounter);
            })();
        }
    }, [project_id, reloadCounter]);

    const reload = () => {
        setReloadCounter(reloadCounter+1);
    }

    return [
        entries,
        loading,
        error,
        reload
    ]
    
}

export const createUseItemHook = (options) => (id) => {
    
    const name = options.name;
    const namePlural = options.namePlural || name + 's';
    const storeName = options.storeName || namePlural;
    const parseEntry = options.parseEntry || genericParseEntry;
    const reduxName = name.toUpperCase(); // SHOULDN'T WE CHECK construct FOR THIS?

    const [reloadCounter, setReloadCounter] = useState(1);
    const [lastReload, setLastReload] = useState(1);

    const { project_id, subscription_id } = useProjectId();
    const auth = useAuth();
    const authContext = useContext(AuthContext);

    const dispatch = useDispatch();
    //const api = useApi();
    const controller = controllers[name];

    

    const { item, loading, error } = useSelector(state => ({
        item: state[storeName].dict?.[id],
        loading: state[storeName].entryLoading?.[id],
        error: state[storeName].entryError?.[id],
    }), shallowEqual);

    useEffect(() => {
        const forceReload = reloadCounter != lastReload;

        if( id && !loading && (forceReload || (!item && !error)) ){
            dispatch({ type: `${reduxName}_SET_LOADING`, id });
            (async () => {
                try {
                    const value = await controller.auth_getItem(auth, { subscription_id, project_id }, id);
                    if( value?.error ){
                        if( value.error === "invalid_token" ){
                            dispatch({ type: "INVALID_TOKEN_ENCOUNTERED" });
                            authContext.getNewToken();
                        }
                        throw Error(value?.error);
                    }

                    const newItem = {
                        ...value,
                        id
                    }
                    const newEntry = parseEntry(newItem);
                    dispatch({
                        type: `${reduxName}_SET`,
                        id,
                        entry: newEntry,
                        value: newItem
                    });
                }
                catch (err) {
                    dispatch({ type: `${reduxName}_SET_LOADING`, id, loading: false, error: err.message });
                    //dispatch({ type: `${reduxName}_SET`, id, value: null, error: err.message });
                }
                setLastReload(reloadCounter);
            })();
        }
    }, [subscription_id, project_id, id, reloadCounter])

    const reload = () => {
        setReloadCounter(reloadCounter+1);
    }

    return [
        item,
        loading,
        error,
        reload
    ];

}