import { useContext, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { projectSocketApi } from "../../api/projectSocketApi";
import { ContentItemContext, ProjectIdContext, SubscriptionIdContext, UrlRootContext } from "../../context/Contexts";
import { ROOT_ID } from "../../hooks/helper/ContentItemHelper";
import { useContentItem } from "../../hooks/useContentItem";
import { useEnforceFullProjectId } from "../../hooks/useEnforceProjectIdFromRoute";
import { useProject } from "../../hooks/useProject";
import { DisplayContext } from "../content/DisplayContext";
import { EditContentContext } from "../content/EditContentContext";
import { PresentContent } from "../content/PresentContent";
import { Main } from "../nav/Main";
import "./PlayProject.scss";
//import { RenderPage } from "../PreviewLayout";

const message = (str) => <div className="message centered h-centered">
    {str}
</div>;

export const PlayProject = (props) => {

    // get subscription id
    const subscriptionContext = useContext(SubscriptionIdContext);
    const subscription_id = subscriptionContext.subscription_id;

    // get project id
    const params = useParams();
    const project_id = params.project_id;

    // force redux to clear itself if needed
    useEnforceFullProjectId(subscription_id, project_id);

    const [project, projectLoading, projectError] = useProject(subscription_id, project_id);

    const location = useLocation();
    const pathSegments = 
        location.pathname.split('/')
        .slice(3); // eats "p" and project-id

    const dispatch = useDispatch();

    // project socket
    const psocket = useRef(null);
    useEffect(() => {
        if( !subscription_id || !project_id ) return null;

        psocket.current = projectSocketApi.connect(subscription_id, project_id, null, dispatch);

        // cleanup
        return () => {
            console.log("useEffect cleanup");
            psocket.current.disconnect();
        }
    }, [subscription_id, project_id])

    const context = {
        project,
        pagePath: `/p/${project_id}`,
        pageLabel: `[root]`,
        breadCrumb: []
    }

    if( projectError ) return message("Error loading project");
    if( projectLoading ) return message("Project loading...");

    return <ProjectIdContext.Provider value={{ project_id, subscription_id }}>
        <UrlRootContext.Provider value={{ url: `/p/${project_id}` }}>
            <ContentItemContext.Provider value={context}>
                <PlayRoot />
            </ContentItemContext.Provider>
        </UrlRootContext.Provider>
    </ProjectIdContext.Provider>;

}

export const PlayRoot = (props) => {

    // this loads the root content item (and ALL its children)
    const [root, rootLoading, rootError, rootReload] = useContentItem(ROOT_ID);
    // todo: add loading message
    if( rootLoading ) return message("Project loading...");
    if( rootError ) return message("Error: " + rootError);

    const editContext = {
        dsRevisionSlot: false ? "__dev" : "__prod"
    }

    
    if( !root ) return null; // yeah i would like to return a message, for projects with no content, but 
    // unfortunately even when there does exist content, root is undefined for a bit until it is loaded.
    return <Main v2>
        <div className="project-content">
            <DisplayContext.Provider value={"block-fill"}>
                <EditContentContext.Provider value={editContext}>
                    <PresentContent id={root?.id} />
                </EditContentContext.Provider>
            </DisplayContext.Provider>
        </div>
    </Main>
}
