export const NavButtons = [
    { key: "info", label: "Project", icon: "fad fa-campground" },
    { key: 'data', label: "Datasets", icon: "fad fa-database", childPathRoot: "/data-source/", openMenu: true },
//    { key: 'reports', label: "Reports", icon: "fad fa-file-contract", childPathRoot: "/reports/", openMenu: true },
    { key: 'collections', label: "Collections", icon: "fad fa-album-collection", childPathRoot: '/collection/', openMenu: true },
    //{ key: 'pages', label: "Pages", icon: "fad fa-file", childPathRoot: "/page/", openMenu: true },
    { key: 'content', label: "Content", icon: "fad fa-file", openMenu: true },
    //{ key: 'components', label: "Components", icon: "fal fa-atom", openMenu: true, childPathRoot: "/form/" },
    
    //{ key: 'filter-books', label: "Filters", icon: "fad fa-filter", openMenu: true, childPathRoot: "/filter-book/" },
    //{ key: 'menus', label: "Menus", icon: "fad fa-list", childPathRoot: "/menu/", openMenu: true },
    { key: 'selections', label: "State", icon: "fad fa-hand-pointer", openMenu: true, childPathRoot: "/selection/" },
    { key: 'colors', label: "Colors", icon: "fad fa-brush", childPathRoot: '/palette/', openMenu: true },
    { key: 'images', label: "Images", icon: "fad fa-images" },
    //{ key: 'files', label: "Files", icon: "fad fa-folders" },
    //{ key: 'dictionary', label: "Dictionaries", icon: "fad fa-books" },
    //{ key: 'components', label: "Components", icon: "fad fa-atom" },
    { key: 'models', label: "Models", icon: "fad fa-function", openMenu: true, childPathRoot: "/model/" },
    //{ key: 'forms', label: "Forms", icon: "fad fa-caret-square-down", openMenu: true, childPathRoot: "/form/" },
    
    //{ key: 'codebooks', label: "Codebooks", icon: "fad fa-file", childPathRoot: "/codebook/", openMenu: true },
    { key: 'tools', label: "Tools", icon: "fad fa-tools", childPathRoot: "/tool/", openMenu: true },

    //{ key: 'asdf', label: "asdf", icon: "fad fa-rocket", childPathRoot: "/tool/", openMenu: true },
    //{ key: 'asdf2', label: "asdf2", icon: "fad fa-unicorn", childPathRoot: "/tool/", openMenu: true },
    
]
    .map(button => {
        return {
            ...button,
            icon: button.icon.replace("fad ", "fas ")
        }
    })
