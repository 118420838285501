import { useState } from "react";
import { Link } from "react-router-dom";
import { useRecentSubscriptions, useSubscriptions } from "../../hooks/useSubscriptions";


const getMatches = (searchText, items) => {
    let text = searchText.toLocaleLowerCase();
    let textArr = text.split(" ");
    return items.filter(item => {
        let itemText = item.label.toLocaleLowerCase();
        for( let t of textArr ){
            if(!itemText.includes(t)) return false;
        }
        return true;
    })
}

export const RecentSubscriptionsBlock = (props) => {

    const [subscriptions, subscriptionsLoading, subscriptionsError, refreshSubscriptions] = useSubscriptions();
    const [searchText, setSearchText] = useState(null);

    let recentSubscriptions = subscriptions
        ?.filter(s => s.last_access)
        ?.sort((a, b) => b.last_access?.localeCompare(a.last_access)); // string compare since last_access is a string

    if( searchText ){
        recentSubscriptions = getMatches(searchText, subscriptions);
    }

    return <div className="recent-block">
        <div className="title">
            <span className="label">
                Recent Subscriptions
            </span>
            <span className="btn action smaller new">
                New
            </span>
        </div>
        <div className="search">
            <input type="text" placeholder="&#xF002; Find a subscription" 
                value={searchText || ""}
                onChange={(ev) => setSearchText(ev.target.value)}
            />
        </div>
        <div className="item-list">
            {subscriptionsLoading && <div className="item loading">
                Loading...
            </div>}
            {recentSubscriptions?.map(item =>
                <Link key={item.id} className="item" to={`/subscription/${item.id}`}>
                    <i className="fad fa-briefcase" />&nbsp; {item.label}
                </Link>
            )}
            <Link className="item view-all" to={`/subscriptions`}>
                View all subscriptions...
            </Link>
        </div>
    </div>;
    

}