import { useContext, useState } from "react";
import { useHistory } from "react-router";
import * as restMethods from "../../api/restMethods";
import { AuthContext } from "../../context/Contexts";
import * as globals from "../../globals";
import { useQuery } from "../../hooks/useQuery";
import "../MainLayout.scss";
import "../nav/LeftMenu.scss";
import { AuthOuter } from "./AuthOuter";
import "./Login.scss";

const translateError = (msg) => {
    switch (msg) {
        case "Failed to fetch":
            return "There is a problem connecting to the server. Please check your internet connection or try again later.";
        case "Invalid grant: user credentials are invalid":
            return "User credentials are invalid or expired.";
        default:
            return msg;
    }
}


export const UpdatePassword = (props) => {

    const query = useQuery();

    const history = useHistory();
    const email = query.get("email");
    const recovery_code = query.get("code");

    const [password, setPassword] = useState(null);
    
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);
    
    const authContext = useContext(AuthContext);
    const [step, setStep] = useState(1);

    const updatePassword = async () => {

        const body = {
            email,
            recovery_code,
            new_password: password
        };
        console.log('submit Password Recovery request', body);
        let success = false;

        setError(false);
        setFetching(true);
        try {
            const res1 = await restMethods.postJson(null, `${globals.apiRoot}/auth/update-password`, body);
            console.log('res1', res1);
            if (res1.status === 200) {
                console.log("success updating password");
                success = true;
            }
            else {
                setError("Failed to change password. Your password recovery code may have expired. Please try again or contact us.")
            }
        }
        catch (err) {
            setError(err.message);
        }
        setFetching(false);

        if (success) {
            setStep(2);
        }
        
    }


    return <AuthOuter title={step === 1 ? "Create New Password" : "Password Updated"} showBackLink>

        {error ? <div className="error">
            <i className="fas fa-warning" /><br/>
            {translateError(error)}
        </div> : null}
        {step === 1 ? <>
            <div className="entry">
                <div className="label">
                    Email:
                </div>
                <input id="email" type="text" placeholder="email" defaultValue={email || ""} disabled />
            </div>

            <div className="entry">
                <div className="label">
                    New Password:
                </div>
                <input id="password" type="password" placeholder="password" value={password || ""} onChange={(ev) => setPassword(ev.target.value)} />
            </div>
        </> :
            <>
                <div className="entry">
                    <div className="label">
                        <p>
                            Your password has been updated. Click OK to return to login page.
                        </p>
                    </div>
                </div>
            </>
        }
        
        {fetching ? <div>
            Please wait...
        </div> : null}
        <div className="submit-section">
            
            <button type="submit" className="login" disabled={fetching || !password} onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();

                if (step === 1) {
                    updatePassword();
                }
                else {
                    history.push("/");
                }
            }}>
                {step === 1 ? "Submit" : "OK"}
            </button>


        </div>
        

    </AuthOuter>



}