// eslint-disable-next-line
import * as Babel from '@babel/standalone';
import React from 'react';

const babelOptions = {
    presets: ["env", "react"]
};

export const isObj = (obj) => typeof obj === 'object' && obj !== null && !Array.isArray(obj);

export const parseValueType = (obj) => {

    if (obj === undefined || obj === null) return null;

    if (typeof obj === "number") return "number";

    if (typeof obj === "string") return "string";

    if (typeof obj === "boolean") return "boolean";

    if (Array.isArray(obj)) return "array";

    if (isObj(obj) && Object.keys(obj)?.length === 1) {
        const t = Object.keys(obj)[0];
        if (t === "$binding") {
            return "binding";
        }
        else if (t === "$expr") {
            return "expr";
        }
        else if (t === "$func") {
            return "func"
        }
    }

    return "unknown";

}

export const parseValueObject = (obj) => {

    if (obj === undefined || obj === null) return null;

    if (typeof obj === "number") return { type: "number", value: obj };

    if (typeof obj === "string") return { type: "string", value: obj };

    if (typeof obj === "boolean") return { type: "boolean", value: obj };

    if (Array.isArray(obj)) return { type: "array", value: obj };

    if (isObj(obj) && Object.keys(obj)?.length === 1) {
        const t = Object.keys(obj)[0];
        if (t === "$binding") {
            return { type: "binding", value: obj[t] };
        }
        else if (t === "$expr") {
            return { type: "expr", value: obj[t] };
        }
        else if (t === "$func") {
            return { type: "func", value: obj[t] };
        }
    }

    return { type: "unknown", value: obj };

}

export const ifString = (val) => (typeof val === "string") ? val : null;


export const parseBindingTargets = (obj) => {

    if (!isObj(obj)) return null;

    let targets = [];
    Object.keys(obj).forEach(key => {

        let childObj = obj[key];
        let childTargets = parseBindingTargets(childObj);
        if (childTargets?.length > 0) {
            childTargets.forEach(childTarget => {
                //targets.push(`${key}.${childTarget}`)
                targets.push({ key: `${key}.${childTarget.key}`, value: childTarget.value });
            })
        }
        else {
            targets.push({ key, value: ifString(childObj) });
        }

    })
    return targets;

}



export const transpileJs = (syntax) => Babel.transform(syntax, babelOptions);

export const transpileJsx = (syntax) => Babel.transform("(React) => { return (" + syntax + " )\r\n }", babelOptions);

//export const transpileJsxComponent = (syntax) => Babel.transform("(React) => { return (" + syntax + " )\r\n }", babelOptions);

export const processPropertyType = (pType, pValue, context) => {

    if (pType === "$func") {

        // get the string value
        const strValue = processPropertyValue(pValue, context);
        if (!strValue) return null;

        // transpile it
        const codeStr = transpileJs(strValue);

        // eval the function (don't call the function, just convert it from string to a js function)
        const func = eval(codeStr);
        return func;

    }
    else {
        throw (new Error("unknown propertyValueType " + pType));
    }

}

export const processPropertyValue = (propertyValue, context) => {

    if (propertyValue === null || propertyValue === undefined) return propertyValue;

    // use this instead?... if( isObj(propertyValue)) { 
    if (Object.isObject(propertyValue)) {
        const keys = Object.keys(propertyValue);
        if (keys?.length === 1 && keys[0].startsWith("$")) {
            try {
                return processPropertyType(keys[0], propertyValue[keys[0]], context);
            }
            catch {
                return propertyValue;
            }
        }
    }
    return propertyValue;

}












export const getPropertyValue = (propertyValue, context, api) => {

    try{

        if (isObj(propertyValue)) {
            const keys = Object.keys(propertyValue);
            if (keys?.length === 1 && keys[0].startsWith("$")) {
                const pType = keys[0];
                const pValue = propertyValue[pType];
                switch( pType ){
                    case "$func": {

                        // get the string value
                        const strValue = getPropertyValue(pValue, context, api);
                        if (!strValue) return null;

                        // transpile it
                        const codeStr = transpileJs(strValue);

                        // eval the function (don't call the function, just convert it from string to a js function)
                        const func = eval(codeStr?.code);

                        // call the function
                        return func(context, api);
                    }
                    case "$expr": {

                        // get the string value
                        const strValue = getPropertyValue(pValue, context, api);
                        if (!strValue) return null;

                        // transpile it
                        //const codeStr = transpileJsx("(context, api) => `" + strValue + "`");
                        const codeStr = transpileJsx("(context, api) => <>" + strValue + "</>");

                        // eval the function (don't call the function, just convert it from string to a js function)
                        const func = eval(codeStr?.code)(React);

                        // call the function
                        return func(context, api);
                    }
                    case "$react": {

                        // get the string value
                        const strValue = getPropertyValue(pValue, context, api);
                        if (!strValue) return null;

                        // transpile it
                        const codeStr = transpileJsx(strValue);

                        // eval the function (don't call the function, just convert it from string to a js function)
                        const func = eval(codeStr?.code)(React);

                        // call the function
                        const Component = func(context, api);
                        return <Component />
                    }
                    case "$binding": {

                        console.log('$binding');

                        // get the string value
                        const strValue = getPropertyValue(pValue, context, api);
                        if (!strValue) return null;

                        console.log('binding strValue', strValue);

                        // split it
                        const keys = strValue.split('.');
                        console.log('binding keys', keys);

                        if (!(keys?.length > 0)) {
                            return null;
                        }

                        let iter = context;
                        for (let key of keys) {
                            iter = iter?.[key];
                        }

                        console.log('binding result', iter);

                        return <span>{iter}</span>; // what if this is json?

                    }
                    default:


                }
            }
        }
        return propertyValue;

    }
    catch(err){
        return err.message;
    }

}



export const wrapInComputeFunction = (syntax) => {
    return "async (context, resources, api, lib) => {\r\n" + syntax + "\r\n}\r\n";
}

export const runCompute = async (userCode, context, resources, api, lib) => {
    if (!userCode || !userCode.trim()) return null;

    // console.log('runCompute.context', context);
    // console.log('runCompute.resources', resources);
    //console.log('runCompute.api', api);
    //console.log('runCompute.lib', lib);

    const code = wrapInComputeFunction(userCode);
    const babelResult = transpileJs(code);
    const js = babelResult.code;
    const compute = eval(js);
    const r = await compute(context, resources, api, lib);
    //console.log('runCompute is returning', r);
    return r;

}

// export const wrapInRenderFunction = (syntax) => {
//     return "(props) => {\r\nconst { data, context } = props;\r\n\r\n\r\n" + syntax + "\r\n\r\n}\r\n";
// }

export const runRender = (userCode, context, resources, api, lib, payload) => {
    //console.log("runRender payload", payload);
    
    if (!userCode || !userCode.trim()) return null;
    const { result, computing, needsCompute, status } = payload;

    const code = userCode;// wrapInRenderFunction(userCode);
    const babelResult = transpileJsx(`(result, context, resources, api, lib, computing, needsCompute, status) => {
        ${code}
    }`);
    const js = babelResult.code;
    //console.log('js', js);
    try{
        const func = eval(js)(React);
        const jsxCrud = func(result, context, resources, api, lib, computing, needsCompute, status);
        return jsxCrud;// <Component />;

        
        
    }
    catch(err){
        return <div>{err.message}</div>
        // const Component = (props) => <div>{err.message}</div>
        // return Component;// <Component />;
    }

}


// export const runRenderV2 = (userCode, context, resources, api, lib, payload) => {
    
//     if (!userCode || !userCode.trim()) return null;
//     const { value, running, needsCompute, status, error } = payload;

//     const code = userCode;// wrapInRenderFunction(userCode);
//     const babelResult = transpileJsx(`(result, context, resources, api, lib, computing, needsCompute, status) => {
        
//         ${code}
//     }`);
//     const js = babelResult.code;
//     //console.log('js', js);
//     try{
//         const func = eval(js)(React);
//         const jsxCrud = func(value, context, resources, api, lib, running, needsCompute, status);
//         return jsxCrud;// <Component />;
//     }
//     catch(err){
//         return <div>{err.message}</div>
//         // const Component = (props) => <div>{err.message}</div>
//         // return Component;// <Component />;
//     }

// }





// const codeStr = transpileJsx("(context, api) => <>" + strValue + "</>");
// // eval the function (don't call the function, just convert it from string to a js function)
// const func = eval(codeStr?.code)(React);
// export const transpileJsx = (syntax) => Babel.transform("(React) => { return (" + syntax + " )\r\n }", babelOptions);




export const compileReact = (userCode, data, context, resources, api, lib) => {
    
    if (!userCode || !userCode.trim?.()) return null;

    //console.log('runRender.data', data);

    const code = userCode;// wrapInRenderFunction(userCode);
    const babelResult = transpileJsx(`(result, context, resources, api, lib) => {
        const data = result; // temp
        ${code}
    }`);
    const js = babelResult.code;
    try{
        const fR = eval(js)(React);
        const fC = fR(data, context, resources, api, lib);
        return fC;
    }
    catch(err){
        return (props) => <div>{err.message}</div>
    }

}


export const compileJs100 = (syntax, context, resources, api, lib, setStatus) => {
    
    if (!syntax || !syntax.trim()) return () => ({ error: "syntax missing" });

    try{
        const babelResult = Babel.transform(`async (React, context, resources, api, lib, setStatus) => {
            ${syntax}
        }`,
            babelOptions
        )
        const js = babelResult.code;
        const fR = eval(js);
        const fC = (async () => await fR(React, context, resources, api, lib, setStatus));
        return fC;
    }
    catch(err){
        return () => <div>{err.message}</div>
    }

}
