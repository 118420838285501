import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import Popup from "reactjs-popup";
import { PipelineEditorContext, PipelineStageEditorContext } from "../../../../context/Contexts";
import { GetDistinctValues } from "../../../../utils/FieldValuesUtil";
import { FIELD_STRINGIFIER, getLowerCaseSearchTerms, isMatch } from "../../../../utils/SearchHelper";
import { SelectFieldsContext, SelectFieldsList } from "../../../common/SelectFieldsList";
import { ModalFrame } from "../../../modal-dialog/ModalFrame";
import { InspectionContext, StagesInspectButton } from "../inspector/StagesInspectButton";
import "./EditFilterStage.scss";

export const EditFilterStage = (props) => {

    const pipelineContext = useContext(PipelineEditorContext);
    const stageContext = useContext(PipelineStageEditorContext);
    const stage = stageContext.stage;

    
    // let defaultValue = Object.entries(stage.data?.$sort || {})
    //     .map(e => ({ field: e[0], dir: e[1] }))

    let defaultValue = stage.data?.[`$mstudio.match`] || [];

    const [inspection, setInspection] = useState(null);
    const [filters, setFilters] = useState(defaultValue);

    const save = (newFilters) => {
        const newData = { '$mstudio.match': newFilters };
        stageContext.replaceStageData(newData);
    }

    const allFields = inspection ? Object.entries(inspection)?.map(entry_arr => {
        const key = entry_arr[0];
        const val = entry_arr[1];
        return {
            name: key
        }
    }) : [];


    return <div className="edit-filter-stage">
        <InspectionContext.Provider value={{ setInspection, inspection }}>
            {/* <div style={{ marginBottom: 10 }}>
                Filters:
            </div> */}
            {filters?.map((f, idx) => {
                let res = [];
                res.push(<FilterItem 
                    key={idx} 
                    entry={f} 
                    fields={allFields}
                    saveEntry={(newFilter) => {
                        const newEntries = filters.map(e => e === f ? newFilter : e);
                        setFilters(newEntries);
                        save(newEntries);
                    }}
                    deleteEntry={() => {
                        const newEntries = filters.filter(e => e !== f);
                        setFilters(newEntries);
                        save(newEntries);
                    }}
                />);
                if( idx === -1 ){
                    res.push(<div style={{ marginTop: 10, marginBottom: 10 }}>
                        ...then by:
                    </div>)
                }
                return res;
            })}
            <FilterItem
                key={"new-item"}
                entry={null}
                fields={allFields}
                saveEntry={(newEntry) => {
                    //newEntry = { ...newEntry, dir: newEntry.dir || 1 };
                    const newEntries = [...(filters || []), newEntry];
                    setFilters(newEntries);
                    save(newEntries);
                }}
            />
        </InspectionContext.Provider>
    </div>
    


}


const FilterItem = (props) => {

    //const [entry, setEntry] = useState(props.entry);
    const entry = props.entry;
    

    return <div className="filter-entry">
        <span className="icon">
            {entry ?
                <i className="fal fa-filter" />
                : null
            }
        </span>
        <Popup
            modal
            keepTooltipInside
            contentStyle={{ height: 600, maxHeight: "100%" }}
            trigger={
                <span className={`syntax ${entry ? "" : "new-entry"}`}>
                    {entry ? entry : "[add filter...]"}
                </span>
            }
        >
            {close => <SelectFilter
                fields={props.fields}
                syntax={entry}
                close={close}
                save={(field) => {
                    const newEntry = field; // { ...entry, field };
                    props.saveEntry(newEntry);
                    close();
                }}
            />}
        </Popup>
        
        <span 
            className={`actions btn icon light ${entry ? "" : "disabled invisible"}`}
            onClick={() => props.deleteEntry?.()}
        >
            <i className="fal fa-times"/>
        </span>
    </div>
}

const SelectFilter = (props) => {

    const [field, setField] = useState(null);
    const [selFields, setSelFields] = useState(null);
    const [selValues, setSelValues] = useState(null);
    const [syntax, setSyntax] = useState(props.syntax);
    const ic = useContext(InspectionContext);
    const inspection = ic?.inspection;

    useEffect(() => {
        const field_exprs = [];
        const sel_vals = selValues?.map(v => distinctValues?.find(dv => dv.val == v))
            .map(dv => typeof(dv.val) === "string" ? `"${dv.val}"` : dv.val)

        selFields?.forEach(f1 => {
            if( !sel_vals?.length  ){

            }
            else if( sel_vals?.length === 1 ){
                field_exprs.push(`${f1}==${sel_vals[0]}`)
            }
            else{
                field_exprs.push(`${f1} in (${sel_vals.join(",")})`)
            }
        })
        setSyntax(field_exprs.join(" or "));
    }, [selFields, selValues])

    const distinctValues = useMemo(() => {
        const fields = selFields?.map(f => ({ name: f }));
        const dv = GetDistinctValues(fields, inspection);
        return dv;
    }, [selFields])

    

    const { inspecting, inspect } = props;

    //const sFields = fields.filter(f => f.name?.includes())
    const searchTerms = getLowerCaseSearchTerms(field);
    const fields = props.fields?.filter(f => isMatch(f, searchTerms, FIELD_STRINGIFIER));

    const noFields = !fields || fields.length < 1;

    return <ModalFrame
        title={"Select Filter"}
        style={{ height: "100%" }}
        //contentStyle={{ position: "relative", padding: 0 }}
        close={props.close}
        buttons={[
            {
                label: "OK",
                callback: () => {
                    props.save?.(syntax);
                }
            },
            {
                label: "Cancel",
                callback: () => {
                    props.close?.();
                }
            }
        ]}
    >
        <div className="edit-filter-modal" >

            <div className="top">
                <div className='label'>
                    Syntax:
                </div>
                <div className='tb'>
                    <input type="text" 
                        style={{ width: "100%" }}
                        className="text1"
                        value={syntax || null}
                        onChange={(ev) => setSyntax(ev.target.value)}
                    />
                </div>
            </div>

            <div className="left full-dock">
                <div className="top">
                    <StagesInspectButton />
                </div>
                <div className="center">
                    <SelectFieldsContext.Provider value={{
                        fields,
                        selectedFieldNames: selFields,
                        setSelectedFieldNames: (vals) => {
                            setSelValues(null);
                            setSelFields(vals);
                        }
                    }}>
                        <SelectFieldsList />
                    </SelectFieldsContext.Provider>                    
                </div>
            </div>
            
            <div className="main">
                <select
                    multiple
                    className="values-select"
                    value={selValues || []}
                    onChange={(ev) => {
                        const options = ev.target.options;
                        let newVal = [];
                        for(let option of options){
                            if( option.selected ){
                                newVal.push(option.value);
                            }
                        }
                        setSelValues(newVal);
                    }}
                >
                    {
                        distinctValues?.map(dv =>
                            <option key={dv.val} value={dv.val} className="item group">
                                {dv.label || dv.val}
                            </option>
                        )
                    }
                </select>
            </div>

        </div>
    </ModalFrame>
    

}