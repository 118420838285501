import { useContext } from "react";
import { AuthContext } from "../../context/Contexts";
import "./EditSubscription.scss";

export const EditSubscription = (props) => {
    
    const authContext = useContext(AuthContext);
    const subscription = {
        todo: "show selected subsription instead of default subscription",
        defaultSubscription: authContext?.selectedSubscription,
    }
    const todo = ["todo"];

    return <div className="edit-account">
        <div style={{ marginBottom: 10 }}>
            Subscription:
        </div>
        <div>
            <select style={{ width: 250 }}>
                <option>{subscription.defaultSubscription?.label}</option>
            </select>
        </div>
        <pre>
            {JSON.stringify(subscription, null, 2)}
        </pre>
        <div>
            Hosting Pool(s):
        </div>
        <pre>
            {JSON.stringify(todo, null, 2)}
        </pre>
        <div>
            Billing / Invoices:
        </div>
        <pre>
            {JSON.stringify(todo, null, 2)}
        </pre>
    </div>

}
