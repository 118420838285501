import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import { useProjectId } from "../../../hooks/useProjectId";
import { HookList } from "./HookList";
import { SelectParentContextBox } from "./SelectParentContextBox";
import { SelectResourceBox } from "./SelectResourceBox";

export const EditContextHooks = (props) => {

    const id = props.id;
    const contentItems = useSelector(state => state.contentItems);
    const item = contentItems?.dict?.[id];

    const [list, setList] = useState(props.value);

    useEffect(() => {
        setList(props.value);
    }, [props.value])

    
    const [parentTree, setParentTree] = useState(null);

    // todo: move this into the select box because it's wasteful here
    useEffect(() => {
        let tree = [];
        let ci = item;
        while( ci ){
            if( ci !== item && 
                (
                    ci.ctype === "mstudio.context-pipeline" 
                    || ci.ctype === "mstudio.context-js"
                )  ){
                tree.push(ci);
            }
            ci = contentItems?.dict?.[ci?.parent_id];
        }
        setParentTree(tree);
    }, [item])

    const names = list?.map(entry => entry.name);

    return <div style={{ marginBottom: 10 }}>
        
        <HookList value={list} onDelete={(r) => {
            const newList = list.filter(e => e.name !== r.name);
            setList(newList);
            props.onChange?.(newList);
        }} />
        <div>
            <div style={{ textAlign: "right" }}>
            <Popup
                keepTooltipInside
                position="bottom right"
                contentStyle={{
                    width: 500,
                    height: 350,
                    backgroundColor: "teal"
                }}
                trigger={
                    
                        <span className='btn action smaller' style={{ marginRight: 10 }}>
                            <i className='fal fa-plus' />&nbsp; Connect...
                        </span>
                    
                }
            >
                {closeModal => <SelectParentContextBox items={parentTree} existingNames={names} close={closeModal} onAdd={r => {
                    if (!r.name) {
                        // do nothing; resource name is blank
                    }
                    else if (list?.find(entry => entry.name === r.name)) {
                        // do nothing; resource name is already in use !?
                    }
                    else {
                        // add resource
                        const newList = [...(list || []), r];
                        setList(newList);
                        props.onChange?.(newList);
                    }
                }} />}
                </Popup>
            </div>
        </div>

    </div>
}