import { docLinks } from "../../doclinks";
import { newId } from "../../utils/uuid";
import { PIPELINE_STAGES } from "../aggregation/stages/_PipelineStages";
import { ModalFrame } from "../modal-dialog/ModalFrame";
import "./SelectStage.scss";

export const SelectStage = (props) => {
    
    const { close, addStage } = props;

    const stages = PIPELINE_STAGES;//.sort((a, b) => a.label.localeCompare(b.label));
    
    return <ModalFrame title="Select Stage" className="no-content-padding" 
        contentStyle={{ }}

        close={close}
        helpLink={docLinks.stages}
        buttons={[
            { 
                label: "Cancel",
                callback: () => {
                    close?.();
                }
            }
        ]}
    >
        <div className="select-pipeline-stage">
            <div className="items">
                {stages.map(item =>
                    <div className="item" onClick={() => {
                        addStage({ 
                            id: newId(), 
                            type: item.id,
                            data: item.initialValue,
                            expanded: true 
                        })
                        close?.();
                    }}>
                        <div className="title">
                            {item.label}
                        </div>
                        <div className="icon-box">
                            <i className={item.icon || "fal fa-rocket"} />
                        </div>
                        <div className="description">
                        </div>
                        {item.helpLink ?
                            <a className="help-link" href={item.helpLink} target="_blank" onClick={ev => {
                                //ev.preventDefault();
                                ev.stopPropagation();
                            }}>
                                <i className="fas fa-question-circle"/>
                            </a>
                            : null
                        }
                        
                    </div>
                )}
            </div>
        </div>
    </ModalFrame>
}