import { useState } from "react";
import Popup from "reactjs-popup";
import { useContentItem } from "../../../hooks/useContentItem";
import { EditCPipelineBox } from "./EditCPipelineBox";
import "./EditCRSyntax.scss";
import { EditCRSyntaxBox } from "./EditCRSyntaxBox";
import { EditCRSyntaxBox2 } from "./EditCRSyntaxBox2";




export const EditCPipeline = (props) => {

    const [popupExpanded, setPopupExpanded] = useState(false);

    const id = props.id;
    const [item] = useContentItem(id);
    
    if( !item ) return null;

    return <div>
        <Popup
            modal
            closeOnEscape={false}
            closeOnDocumentClick={false}
            contentStyle={{
                width: "100%",
                height: "100%",
                background: "transparent"
            }}
            trigger={
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        border: "1px solid #cfcfcf",
                        width: "100%",
                        padding: "5px 10px",
                        userSelect: "none"
                    }}
                >
                    {props.placeholder || "[syntax]"}
                    <span style={{ float: "right" }}>
                        <i className='fal fa-ellipsis-h'/>
                    </span>
                </div>
            }
        >
            {closeModal => 
                <EditCPipelineBox
                    title={"Syntax"}
                    pipeline={item?.jdata?.pipeline}
                    resources={props.resources}
                    onChange={props.onChange}
                    close={closeModal}
                />}
        </Popup>
    </div>


}
