import { Link } from "react-router-dom";
import { useCollections } from "../../../hooks/useCollection";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useEffect, useState } from "react";

import { scaleLinear } from "d3-scale";
import { color } from "d3-color";

import moment from "moment";
import "./ProjectUsersTile.scss";
import { useStatsApi } from "../../../hooks/useStatsApi";
import { useProjectId } from "../../../hooks/useProjectId";
import { docLinks } from "../../../doclinks";

const getDayValue = (dateObj) => {
    const month = dateObj.getUTCMonth() + 1; //months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    return year * 10000 + month * 100 + day;
}

function getDateXDaysAgo(numOfDays, date) {
    const daysAgo = new Date(date.getTime());
    daysAgo.setDate(date.getDate() - numOfDays);
    return daysAgo;
}



export const ProjectUsersTile = (props) => {

    return <div className="tile white-bg pad">
        <div className="title">
            Users &amp; Telemetry

            <div className="help-link">
                <a href={docLinks.user_management} target="_blank" className="icon">
                    <i className="fal fa-question-circle"/>
                </a>
            </div>
        </div>


        <ProjectStats />

        {/* <div style={{
            height: 150, width: "100%", border: "1px solid #cfcfcf",
            position: "relative"
        }}>
            
            
        </div> */}

        <div>
            <Link className="edit-link" to="#">
                User access and telemetry...
            </Link>
        </div>
        
        
    </div>
}



const ff = scaleLinear()
        .domain([0, 50])
        //.range(["red", "yellow", "green"]);
        .range(["#95cbe7", "#1298df"]);




export const ProjectStats = (props) => {

    const [stats, setStats] = useState(null);
    const [loading, setLoading] = useState(false);
    const api = useStatsApi();
    const { subscription_id, project_id } = useProjectId();

    useEffect(() => {
        if( loading ) return;

        setLoading(true);
        const f = async () => {
            //const x = await api.getMyLoginStats();
            const x = await api.getProjectLoginStats(subscription_id, project_id);
            setStats(x);
            setLoading(false);
        }
        f();
    }, [])

    // get today
    const now = new Date();

    console.log("project stats", stats);

    // get last sunday
    const sunday = getDateXDaysAgo(now.getDay(), now);

    let days = [];
    // const nbDays = 100;
    // for( let i = 1; i <= nbDays; i++ ){
    //     const daysAgo = nbDays - i;
    //     const dateObj = getDateXDaysAgo(daysAgo, now);
    //     const day = getDayValue(dateObj);
    //     days.push({ dateObj, day });
    // }
    let dict = stats?.reduce?.((prev, row) => {
        prev[row.day] = row;
        return prev;
    }, {});
    

    const nbWeeks = 17;
    let weeks = [];
    for( let i = 1; i <= nbWeeks; i++ ){
        const weeksAgo = nbWeeks - i;
        const dateObj = getDateXDaysAgo(weeksAgo * 7, sunday);
        weeks.push({ dateObj });
    }

    return <div className="project-stats">
        {loading ? 
            <span className='loading'>Loading...</span> 
            :
            <div className="box">
                <span className="week-column">
                    {["S", "M", "T", "W", "Th", "F", "S"].map((w, idx) => 
                        <span key={idx} className="day-label">
                            {w}
                        </span>
                    )}
                </span>
                {weeks.map((weekObj, idx) => {

                    const days = [];
                    for( let di = 0; di > -7; di-- ){
                        const dateObj = getDateXDaysAgo(di, weekObj.dateObj);
                        days.push({ dateObj });
                    }

                    return <span key={idx} className="week-column" xtitle={weekObj.dateObj.toDateString()}>
                        {days.map((dayObj, dayIdx) => {
                            const cell = dict?.[getDayValue(dayObj.dateObj)];
                            const hit = cell?.events;
                            const hitsStr = `${cell?.events} hits`;
                            const dayStr = moment.utc(dayObj.dateObj).local().fromNow();
                            const future = dayObj.dateObj > now;
                            
                            const style = {};
                            if( hit ){
                                const bgColor = ff(cell?.events);
                                const bgColorHex = color(bgColor)?.formatHex();
                                style.backgroundColor = bgColorHex;
                            }
                            
                            

                            return <span key={dayIdx} 
                                className={`day-box ${dayIdx === 0 || dayIdx == 6 ? "weekend" : ""} ${loading ? "loading" : ""} ${future ? "future" : ""}`}
                                style={style}
                                title={hitsStr + "\n" + dayStr + "\n" + dayObj.dateObj.toDateString()}>
                                    
                            </span>
                        })}
                    </span>
                })}
            </div>
        }
    </div>
}