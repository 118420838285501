import Popup from "reactjs-popup";
import { TextFrame } from "../../modal-dialog/VerifyFrame";
import "./StringPropertyEditor.scss";

export const StringPropertyEditor = (props) => {
    return <span className="string-property-editor">
        <Popup
            modal
            trigger={
                <span className="text-box">{props.value || "not set"}</span>
            }
        >
            {closeModal => <TextFrame
                buttonLabel="OK"
                title={`Edit ${props.label || "Value"}`}
                label={props.label}
                initialTextValue={props.value}
                close={closeModal} confirmed={async (text) => {
                    props.onChange?.(text);
                    closeModal();
                }}
            />}
        </Popup>
        {props.value && <span 
            className="del"
            onClick={(ev) => {
                props.onChange?.(undefined);
            }}
        >
            <i className="fal fa-times" />
        </span>}
    </span>
}

