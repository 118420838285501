// eslint-disable-next-line
import * as Babel from '@babel/standalone';

const babelOptions = {
    presets: ["env"]
};

export const transpileJs = (syntax) => Babel.transform(syntax, babelOptions)?.code;

export const compileFunction = (syntax) => {
    const js = transpileJs(syntax);
    return eval(js);
}

