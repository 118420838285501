import { useContext, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useDispatch } from "react-redux";
//import SplitterLayout from "react-splitter-layout";
import SplitterLayout from "../../extlib/react-splitter-layout/SplitterLayout";
import { computeApi } from "../../../api/computeApi";
import { ContentItemContext, HasComputeContext, HasPipelinesContext, HasRenderContext, HasRenderTableContext } from "../../../context/Contexts";
import { useAuth } from "../../../hooks/useAuth";
import { useLazyLib } from "../../../hooks/useLazyLib";
import { useProjectId } from "../../../hooks/useProjectId";
import { compileFunction } from "../../../utils/Compiler";
import { runRender } from "../../../utils/ContentProcessor";
import { defaultLib } from "../../../utils/Lib";
import { DataTable } from "../../common/DataTable";
import { ErrorBoundary } from "../../error-boundary/ErrorBoundary";
import { EditComputeSyntax } from "./EditComputeSyntax";
import { truncateObjectAtLevel } from "./EditCRSyntaxBox";
import { EditPipelines } from "../../pipeline/EditPipelines";
import "./EditCRSyntaxBox2.scss";

import { EditRenderSyntax } from "./EditRenderSyntax";
import { EditRenderTableSyntax } from "./EditRenderTableSyntax";

const CODE_TABS = [
    { id: "pipelines", label: "Pipelines" },
    { id: "compute", label: "Compute" },
    { id: "render", label: "Render" },
    { id: "table-specs", label: "Table Specs" },
//    { id: "settings", label: "Settings" },
]

const HELPER_TABS = [
    { id: "rendered", label: "Rendered" },
    { id: "renderedTable", label: "RenderedTable" },
    { id: "data", label: "Result" },
    { id: "context", label: "Context" },
    //{ id: "api", label: "API" },
    { id: "lib", label: "Lib" },
    { id: "resources", label: "Resources" },
]

const defaultApiHack = {
}



export const EditCRSyntaxBox2 = (props) => {

    const { subscription_id, project_id } = useProjectId();
    const auth = useAuth();
    const dispatch = useDispatch();

    const lib2 = useLazyLib();
    const lib = {
        ...defaultLib,
        ...lib2
    };
    
    
    const getLibHack = () => truncateObjectAtLevel(lib, 1);
    

    const [pipelines, setPipelines] = useState(props.pipelines);
    const [compute, setCompute] = useState(props.compute);
    const [payload, setPayload] = useState({ computing: false, needsCompute: false, result: null, status: null });

    const [render, setRender] = useState(props.render);
    const [renderTable, setRenderTable] = useState(props.renderTable);
    const [rendered, setRendered] = useState(null);
    const [renderedTable, setRenderedTable] = useState(null);
    

    const [selCodeTab, setSelCodeTab] = useState("pipelines");
    const [selHelperTab, setSelHelperTab] = useState("rendered");
    
    // const [resources, resourcesLoading, resourcesError] = useResources(props.resources, true);
    // const allResourcesReady = (resources && !resourcesLoading) || !item?.resources?.length;
    // let rResources = null;
    // if (allResourcesReady) {
    //     rResources = reduceResources(resources, subscription_id, project_id, dispatch, auth);
    // }
    const rResources = props.resources;

    const [expanded, setExpanded] = useState(false);

    const context = useContext(ContentItemContext);
    
    useEffect(() => {
        setCompute(props.compute);
    }, [props.compute])

    useEffect(() => {
        setRender(props.render);
    }, [props.render])

    useEffect(() => {
        setRenderTable(props.renderTable);
    }, [props.renderTable])

    const env = {
        resources: rResources,
        context: context
    };

    const runCompute = async () => {

        //props.compute should get pipelines as an Input
        //if props.compute is blank, maybe it should run the pipelines???
        let pipelines2 = pipelines
            .filter(p => !p.disabled)
            .map(p => {
                const stages = p.stages
                    ?.filter(s => !s.disabled)
                    ?.map(s => s.data);
                return {
                    id: p.id,
                    dataSourceId: p.dataSourceId,
                    stages,
                    compute: async (args) => {
                        const res = await computeApi.computeV2(
                            auth, { ...env, args }, subscription_id, project_id, 
                            p.dataSourceId,
                            "__dev",
                            stages,
                            { 
                                limit: 100 // cursor limit
                            }
                        );
                        return res;
                    }
                }
            });

        const computeSyntax = "async (context, lib, resources, pipelines, setStatus) => { " + compute + " \n\n}\n";
        const computeFunc = compileFunction(computeSyntax);
        const setStatus = (s) => setPayload({ ...payload, status: s })
        let allResults = null;
        try{
            allResults = computeFunc?.(context, lib, rResources, pipelines2, setStatus);
        }
        catch(err ){
            console.error(err);
        }



        // // todo: this runs each pipeline sequentially, but perhaps they could each run asynchronously
        // let allResults = {};
        // for( let pipeline of pipelines ){
        //     const enabled_stages = pipeline?.stages
        //         ?.filter(s => !s.disabled)
        //         ?.map(s => s.data);
        //     try {
        //         const res = await computeApi.computeV2(
        //             auth,
        //             env,
        //             subscription_id,
        //             project_id, 
        //             pipeline.dataSourceId, 
        //             "__dev",
        //             enabled_stages,
        //             {
        //                 limit: 100 // cursor limit
        //             });
        //         allResults[pipeline.id] = res;
        //     }
        //     catch (err) {
        //         allResults[pipeline.id] = { error: err.message };
        //     }
        // }


        return allResults;
    }

    // compute stages
    useEffect(() => {

        if( !pipelines ) return;
        
        (async () => {
            setPayload({ ...payload, computing: true });
            try{
                const cres = await runCompute();
                setPayload({ ...payload, computing: false, result: cres });
            }
            catch(err){
                console.error(err);
                setPayload({ ...payload, computing: false, error: err });
            }
        })();

    }, [compute, pipelines])

    const api = {
        compute: runCompute
    };


    // compile the renderer
    useEffect(() => {

        if( !lib2 ) return;
        if( !render ) return;
        console.log("running render");
        
        (async () => {

            // render
            const r = runRender(render, context, rResources, api, lib, payload);
            setRendered(r || null);

        })();

    }, [render, rResources, payload]);

    // compile the table renderer
    useEffect(() => {

        if( !lib2 ) return;
        if( !renderTable ) return;
        console.log("running renderTable");
        
        (async () => {

            // render table
            const rt = runRender(renderTable, context, rResources, api, lib, payload);
            const r2 = rt ? <div>
                <div style={{ 
                    margin: 10,
                    marginBottom: 15,
                    fontWeight: 600,
                    textTransform: "uppercase",
                    fontSize: 13,
                    textAlign: "center"
                    
                }}>
                    {rt.title}
                </div>
                <div style={{ 
                    
                    //height: 350, 
                    //position: "relative", 
                    //border: "1px solid #cfcfcf",
                    marginBottom: 15
                }}>
                    <center>
                    <DataTable className="auto-height" {...rt} />
                    </center>
                </div>
            </div>
            : "table not rendered";
            setRenderedTable(r2);

        })();

    }, [renderTable, rResources, payload]);



    const saveMe = () => {
        props.onChange?.({
            compute,
            pipelines,
            render,
            renderTable
        })
    }

    const context1 = {
        pipelines,
        setPipelines
    }

    const cContext = {
        compute,
        setCompute
    }

    const rContext = {
        render,
        setRender
    }

    const rtContext = {
        renderTable,
        setRenderTable
    }
   

    return <HasPipelinesContext.Provider value={context1}>
        <HasComputeContext.Provider value={cContext}>
            <HasRenderContext.Provider value={rContext}>
                <HasRenderTableContext.Provider value={rtContext}>
                    <div className="edit-crsyntax-box2">
                        <div className={`frame1 ${expanded ? "expanded" : ""}`}>
                            <div className="titlebar full-dock">

                                <div className="center xtab-section">
                                    {/* Title goes here */}
                                </div>

                                <span className="buttons right">
                                <span className="btn icon" onClick={() => saveMe()}>
                                        <i className="fas fa-save" />
                                    </span>
                                    <span className="btn icon" onClick={() => setExpanded(!expanded)}>
                                        <i className="fal fa-expand" />
                                    </span>
                                    <span className="btn icon" onClick={() => props.close?.()}>
                                        <i className="fal fa-times" />
                                    </span>
                                </span>

                            </div>
                            <div className="main">
                                <div className="all" >
                                    <SplitterLayout primaryIndex={1}>
                                        <div className="full-dock fill-parent-absolute">
                                            <div className="top tab-section no-margin">
                                                {CODE_TABS.map(tab =>
                                                    <span 
                                                        key={tab.id}
                                                        className={`tab ${selCodeTab === tab.id ? "sel" : ""}`}
                                                        onClick={() => setSelCodeTab(tab.id)}
                                                    >
                                                        {tab.label}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="center fill-parent-absolute" style={{ overflow: "hidden" }} >
                                                {selCodeTab === "compute" ? <EditComputeSyntax /> : null}
                                                {selCodeTab === "pipelines" ? <EditPipelines noOutput /> : null}
                                                {selCodeTab === "settings" ? <div>
                                                    edit settings
                                                </div> : null}
                                                {selCodeTab === "render" ? <EditRenderSyntax /> : null}
                                                {selCodeTab === "table-specs" ? <EditRenderTableSyntax /> : null}
                                            </div>
                                        </div>
                                        <div className="full-dock fill-parent-absolute">
                                            <div className="top tab-section no-margin">
                                                {HELPER_TABS.map(tab =>
                                                    <span 
                                                        key={tab.id}
                                                        className={`tab ${selHelperTab === tab.id ? "sel" : ""}`}
                                                        onClick={() => setSelHelperTab(tab.id)}
                                                    >
                                                        {tab.label}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="center full-dock fill-parent-absolute">
                                                {payload.computing &&
                                                    <div className="top compute-running">
                                                        Computing...
                                                    </div>
                                                }
                                                <div className="center">
                                                    {selHelperTab === "rendered" && 
                                                        <ErrorBoundary>
                                                            {rendered}
                                                        </ErrorBoundary>
                                                    }
                                                    {selHelperTab === "renderedTable" && 
                                                        <ErrorBoundary>
                                                            {renderedTable}
                                                        </ErrorBoundary>
                                                    }
                                                    {selHelperTab === "data" && 
                                                        <div className="json-wrap">
                                                            <ReactJson
                                                                src={payload.result || undefined}
                                                                name={"result"}
                                                                theme={"rjv-default"}
                                                                iconStyle="circle"
                                                                displayDataTypes={false}
                                                                enableClipboard={true}
                                                                collapsed={1}
                                                                />
                                                        </div>
                                                        // pipelines?.map(p => {
                                                        //     const res = computeResult?.[p.id];
                                                        //     return <ViewRecords result={res} stages={{ todo: 1 }} />;
                                                        // })
                                                        
                                                    }
                                                    {selHelperTab === "context" ? 
                                                        <div className="json-wrap">
                                                            <ReactJson src={context}
                                                                name={"context"}
                                                                theme={"rjv-default"}
                                                                iconStyle="circle"
                                                                displayDataTypes={false}
                                                                enableClipboard={true}
                                                                collapsed={1}
                                                            />
                                                        </div>
                                                    : selHelperTab === "resources" ? 
                                                        <div className="json-wrap">
                                                            <ReactJson src={props.resources}
                                                                name={"resources"}
                                                                theme={"rjv-default"}
                                                                iconStyle="circle"
                                                                displayDataTypes={false}
                                                                enableClipboard={true}
                                                                collapsed={1}
                                                                />
                                                        </div>
                                                    : selHelperTab === "api" ? 
                                                        <div className="json-wrap">
                                                            <ReactJson src={{}}
                                                                name={"api"}
                                                                theme={"rjv-default"}
                                                                iconStyle="circle"
                                                                displayDataTypes={false}
                                                                enableClipboard={true}
                                                                collapsed={1}
                                                            />
                                                        </div>
                                                    : selHelperTab === "lib" ?
                                                        <div className="json-wrap">
                                                            <ReactJson
                                                                src={getLibHack()}
                                                                name={"lib"}
                                                                theme={"rjv-default"}
                                                                iconStyle="circle"
                                                                displayDataTypes={false}
                                                                enableClipboard={true}
                                                                collapsed={1}
                                                                />
                                                        </div>
                                                    : null
                                                    }



                                                </div>
                                            </div>
                                        </div>
                                    </SplitterLayout>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </HasRenderTableContext.Provider>
            </HasRenderContext.Provider>
        </HasComputeContext.Provider>
    </HasPipelinesContext.Provider>;

}

