import { useState } from "react";
import { useFilterBooksApi } from "../../hooks/useFilterBook";
import { ModalFrame } from "../modal-dialog/ModalFrame";


export const NewFilterBook = (props) => {

    const [newName, setNewName] = useState("");
    const api = useFilterBooksApi();

    const createItem = async (pal) => {
        return await api.newItem(pal);
    }

    return <ModalFrame close={props.close}
        title="Create new filter book"
        buttons={[
            {
                label: "Create",
                disabled: !newName,
                callback: async () => {
                    const item = {
                        label: newName,
                        //type: newType
                    }
                    setNewName("");
                    const itemInfo = await createItem(item);
                    props?.onNew(itemInfo);
                    props.close();
                }
            },
        ]}
    >
        <div className='form1' style={{ maxWidth: 500 }}>
            <label className="label-line">
                Label:
            </label>
            <div className="value-line">
                <input type="text"
                    autoFocus className='full-width' 
                    placeholder="Enter label for new filter book"
                    value={newName}
                    onChange={(ev) => {
                        setNewName(ev.target.value)
                    }}/>
            </div>
            
        </div>

    </ModalFrame>

    
}


