import "./AddChildContent.scss";
import { getAllowedChildTypes, getTypeInfo } from "./ContentTypes";


export const AddChildContentModal = (props) => {

    const info = getTypeInfo(props.type);
    const parentAllowedTypes = props.parentAllowedTypes || [];
    const allowedTypes = getAllowedChildTypes(props.type, parentAllowedTypes)

    const categories = allowedTypes?.reduce((accum, typeName) => {
        const typeInfo = getTypeInfo(typeName);
        let find = accum.find(c => c.label === typeInfo.category);
        if (!find) {
            find = {
                label: typeInfo.category,
                items: []
            }
            accum.push(find);
        }
        find.items.push(typeName);
        return accum;
    }, [])
        .map(cat => ({
            ...cat,
            label: cat.label || "Other"
        }));
    
    const closeModal = props.close;

    return <div className='add-child-content-win'>
        <div className='title'>
            Add child content:
        </div>
        <div className='left'>
            <div className='category-menu'>
                {categories?.map(category => {

                    return <div>
                        <div className="category-label">{category.label}</div>
                        <div className="menu">
                            {category?.items.map(ctype => {

                                const info = getTypeInfo(ctype);
                                const newDefaults = info?.newDefaults || [
                                    {
                                        label: ctype.label,
                                        value: info?.newDefault
                                    }
                                ]
                                return newDefaults?.map(newDefault =><span key={ctype} className="menu-item" onClick={() => {
                                    const newObj = {
                                        //id: newId(),
                                        ctype: ctype,
                                        //value: newDefault.value,
                                        ...newDefault.value,
                                    };
                                    props.onAdd?.(newObj);
                                    closeModal();
                                }}>
                                    <span className='label'>
                                        {(newDefault?.label || info?.label)
                                            .split('\n')
                                            .map(textRun => <div>{textRun}</div>)
                                        }
                                    </span>
                                    <span className='icon'>
                                        <i className={info.icon || 'fal fa-cube'} />
                                    </span>
                                </span>);

                            })}
                        </div>
                    </div>

                    
                })}
                
            </div>
        </div>
        {/* <div className="right">
            selected type info goes here
        </div> */}
    </div>;
    
}