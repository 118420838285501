import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { controllers } from "../api/controllers";
import { constructs } from "../api/contructs";
import { useAuth } from "./useAuth";
import { useProjectId } from "./useProjectId";

export const useResource = (type, id) => {
    
    const {project_id, subscription_id} = useProjectId();
    const reduxDispatch = useDispatch();
    const auth = useAuth();

    const construct = constructs[type];
    const { item, loading, error } = useSelector(state => ({
        item: construct ? state[construct.storeName].dict?.[id] : null,
        loading: construct ? state[construct.storeName].dict?.[id]?.loading : null,
        error: construct ? state[construct.storeName].dict?.[id]?.error : null,
    }), shallowEqual); 

    // effect checks to see if we need to load anything
    useEffect(() => {

        // no resource requested
        if (!construct) return;

        // already loaded or loading
        if( item ) return;

        const f = async () => {
            
            const controller = controllers[type];
            const actionName = construct.actionName;

            reduxDispatch({ type: `${actionName}_SET_LOADING`, id });
            try {
                const item = await controller.auth_getItem(auth, { subscription_id, project_id }, id);
                // todo: might want to add an invalid_token check here like in createUseItemsHook
                const newValue = {
                    ...item,
                    id
                };
                const newEntry = construct.parseEntry(newValue);
                reduxDispatch({ type: `${actionName}_SET`, id, entry: newEntry, value: newValue });
            }
            catch (err) {
                //reduxDispatch({ type: `${actionName}_SET`, id, value: null, error: err.message });
                reduxDispatch({ type: `${actionName}_SET_LOADING`, id, loading: false, error: err.message });
            }
            
        }
        f();
        
    }, [subscription_id, project_id, type, id]);

    return [
        item,
        loading,
        error
    ]

}
