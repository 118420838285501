import React, { useContext, useEffect, useState } from "react";
import { ContentItemContext, PageContext, ResourcesContext } from "../../../context/Contexts";
import { useContentItem } from "../../../hooks/useContentItem";
import { useContentItemsApi } from "../../../hooks/useContentItems";
import { useResources } from "../../../hooks/useResources";
import { DataBindButton } from "../../edit/DataBindButton";
import { InstancesInput } from "../../instances/InstancesInput";
import { EditBackground } from "./EditBackground";
import { EditBorder } from "./EditBorder";

const TABS = [
    { key: "content", label: "Content" },
    { key: "style", label: "Border" },
    { key: "size", label: "Size" },
    { key: "background", label: "Background" },
]

export const EditTileProperties = (props) => {

    const api = useContentItemsApi();
    const page = useContext(PageContext);
    const context = useContext(ContentItemContext);

    const [item] = useContentItem(page?.id, props.content_item_id);
    
    // const [item, setItem] = useState(props.content_item);
    // useEffect(() => {
    //     setItem(props.content_item);
    // }, [props.content_item])

    const [selTab, setSelTab] = useState("content");

    const [resources, resourcesLoading, resourcesError] = useResources(item?.resources);

    if (!item) return null;

    const updateItem = async (newItem) => {
        //setItem(newItem);
        await api.updateItem(item.id, newItem, { page_id: page.id });
    }

    
    const rowPad = 5;

    return <ContentItemContext.Provider value={context}>
        <ResourcesContext.Provider value={resources}>
            <div>

                {/* <div className='form-group-header'>
                    Tile
                </div> */}

                {/* <EditResources value={item.resources} onChange={async (newList) => {
                    const newItem = {
                        ...item,
                        resources: newList
                    };
                    await updateItem(newItem);
                }} /> */}


                <div className='h-dock' style={{ marginBottom: rowPad }}>
                    
                    <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                        Instances
                    </span>
                    <span className='center'>
                        <InstancesInput
                            style={{ width: "100%" }}
                            value={item.instances}
                            onChange={async (newInstances) => {
                                console.log('instancesInput changed', newInstances)
                                const newItem = {
                                    ...item,
                                    instances: newInstances
                                };
                                await updateItem(newItem);
                            }}
                        />
                    </span>
                    <span className='right v-center' style={{ marginLeft: 10 }}>
                        <DataBindButton/>
                    </span>
                </div>

                <div className='h-dock' style={{ marginBottom: rowPad }}>
                    
                    <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                        Visible
                    </span>
                    <span className='center v-center'>
                        <input type="checkbox" />
                    </span>
                    <span className='right v-center' style={{ marginLeft: 10 }}>
                        <DataBindButton/>
                    </span>
                </div>

                <div style={{ marginBottom: 15 }} />

                <div className='tab-section reset-margin' style={{ marginBottom: 20 }}>
                    {TABS.map(tab =>
                        <span
                            key={tab.key}
                            className={`tab ${selTab === tab.key ? "sel" : ""}`}
                            onClick={() => setSelTab(tab.key)}
                        >
                            {tab.label}
                        </span>
                    )}
                    
                </div>

                {selTab === "content" ? <>
                    
                    <div className='form-group-header'>
                        Padding
                    </div>

                    <div>todo!</div>

                    <hr />
                    
                    <div className='form-group-header'>
                        Content
                    </div>

                    <div className='h-dock' style={{ marginBottom: rowPad }}>
                        <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                            Title:
                        </span>
                        <span className='center'>
                        </span>
                        <span className='right v-center' style={{ marginLeft: 10 }}>
                            <DataBindButton />
                        </span>
                    </div>

                    <div className='h-dock' style={{ marginBottom: rowPad }}>
                        <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                            Subtitle:
                        </span>
                        <span className='center'>
                        </span>
                        <span className='right v-center' style={{ marginLeft: 10 }}>
                            <DataBindButton />
                        </span>
                    </div>

                    <div className='h-dock' style={{ marginBottom: rowPad }}>
                        <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                            Paragraph(s):
                        </span>
                        <span className='center'>
                        </span>
                        <span className='right v-center' style={{ marginLeft: 10 }}>
                            <DataBindButton />
                        </span>
                    </div>

                    <hr/>

                    <div className='form-group-header'>
                        Call to action
                    </div>

                    <div className='h-dock' style={{ marginBottom: rowPad }}>
                        <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                            Button text:
                        </span>
                        <span className='center'>
                        </span>
                        <span className='right v-center' style={{ marginLeft: 10 }}>
                            <DataBindButton />
                        </span>
                    </div>

                    <div className='h-dock' style={{ marginBottom: rowPad }}>
                        <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                            Link target:
                        </span>
                        <span className='center'>
                        </span>
                        <span className='right v-center' style={{ marginLeft: 10 }}>
                            <DataBindButton />
                        </span>
                    </div>

                </> : null}

                {selTab === "style" ?
                    <EditBorder
                        value={item.border}
                        onChange={async (val) => {
                            const newItem = {
                                ...item,
                                border: val
                            };
                            await updateItem(newItem);
                        }}
                    />
                    : null}

                {selTab === "background" ?
                    <EditBackground
                        value={item.background}
                        onChange={async (val) => {
                            const newItem = {
                                ...item,
                                background: val
                            }
                            await updateItem(newItem);
                        }}
                    />
                    : null}
                    
                    
                
                {selTab === "size" ?
                    <>
                        <div className='form-group-header'>
                            Cell Spanning
                        </div>

                        <div className='h-dock' style={{ marginBottom: rowPad }}>
                            <span className='left v-center nowrap' style={{ width: 100, marginRight: 10 }}>
                                Column Span
                            </span>
                            <span className='center'>
                                <input type="range" style={{ width: "100%" }} minValue={1} maxValue={10} value={1} />
                            </span>
                            <span className="right2 v-center" style={{ marginLeft: 10 }}>
                                {1}
                            </span>
                            <span className='right v-center' style={{ marginLeft: 10 }}>
                                <DataBindButton/>
                            </span>
                        </div>

                        <div className='h-dock' style={{ marginBottom: rowPad }}>
                            <span className='left v-center nowrap' style={{ width: 100, marginRight: 10 }}>
                                Row Span
                            </span>
                            <span className='center'>
                                <input type="range" style={{ width: "100%" }} minValue={1} maxValue={10} value={1} />
                            </span>
                            <span className="right2 v-center" style={{ marginLeft: 10 }}>
                                {1}
                            </span>
                            <span className='right v-center' style={{ marginLeft: 10 }}>
                                <DataBindButton/>
                            </span>
                        </div>

                        <hr/>

                        <div className='form-group-header'>
                            Size
                        </div>

                        <div className='h-dock' style={{ marginBottom: rowPad }}>
                            <span className='left v-center nowrap' style={{ width: 100, marginRight: 10 }}>
                                
                            </span>
                            <span className='center' >

                                <div className='flex-vcenter' style={{ padding: "5px 0px" }}>
                                    <label><input type="radio" name={`tile-${item.id}-size`} /> Minimum required by content</label>
                                </div>
                                <div className='flex-vcenter' style={{ padding: "5px 0px" }}>
                                    <label><input type="radio" name={`tile-${item.id}-size`} /> Fill parent</label>
                                </div>
                                <div className='flex-vcenter' style={{ padding: "5px 0px" }}>
                                    <label><input type="radio" name={`tile-${item.id}-size`} /> Specify:</label>
                                </div>
                                
                            </span>
                            <span className='right v-center' style={{ marginLeft: 10, visibility: "hidden" }}>
                                <DataBindButton/>
                            </span>
                            
                        </div>

                        <hr/>
                    </>
                    : null
                }
                
                    

            </div>
            </ResourcesContext.Provider>
    </ContentItemContext.Provider>
}

