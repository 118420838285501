import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constructs } from "../api/contructs";
import { createSyncedApiHook } from "../api/createSyncedApiHook";
import { createUseItemHook, createUseItemsHook } from "./createUseItemsHook";

const construct = constructs["selection"];

export const useSelection = createUseItemHook(construct);
export const useSelections = createUseItemsHook(construct);
export const useSelectionsApi = createSyncedApiHook(construct);



export const useSelectionState = (selectionId) => {

    const dispatch = useDispatch();
    const [selection, selectionLoading, selectionError] = useSelection(selectionId);
    const stateId = selectionId;
    const selectionState = useSelector(state => state.selectionState[stateId]);
    
    const selectionStateLoading = !selectionState && selectionLoading;
    const selectionStateError = !selectionState && selectionError;

    const setState = (value) => {
        dispatch({
            type: "SELECTION_STATE_SET",
            id: stateId,
            value
        })
    }

    useEffect(() => {
        
        if (selectionState) return;
        if (!selection) return;
        if (selectionLoading) return;
        if (selectionError) return;
        
        // load a state for this pup from the defaultValue
        const value = selection.defaultValue; // oh, individual user initial values!?!? maybe one day
        setState(value);
        // dispatch({
        //     type: "SELECTION_STATE_SET",
        //     id: stateId,
        //     value
        // })

    }, [stateId, selection, selectionLoading, selectionError])

    return [selectionState, selectionStateLoading, selectionStateError, setState];

}

