import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { GetToken_Password } from "../../api/oauth";
import * as restMethods from "../../api/restMethods";
import { AuthContext } from "../../context/Contexts";
import * as globals from "../../globals";
import "./Login.scss";
import "../MainLayout.scss";
import "../nav/LeftMenu.scss";
import { AuthOuter } from "./AuthOuter";

const translateError = (msg) => {
    switch (msg) {
        case "Failed to fetch":
            return "There is a problem connecting to the server. Please check your internet connection or try again later.";
        case "Invalid grant: user credentials are invalid":
            return "User credentials are invalid or expired, or account is disabled.";
        default:
            return msg;
    }
}

const GetUser = async (auth) => {
    const res = await restMethods.getJson(auth, `${globals.apiRoot}/user`);
    return await res.json();
}

// const GetSubscription = async (auth, id) => {
//     const res = await restMethods.getJson(auth, `${globals.apiRoot}/subscription/${id}`);
//     return await res.json();
// }

export const StoreToken = async (token) => {
    console.log("StoreToken", token);

    if (!token) {
        sessionStorage.removeItem("authToken");
        return;
    }

    // store authToken
    sessionStorage.setItem("authToken", JSON.stringify(token));
    
}

const StoreTokenEtc = async (token) => {
    //console.log("StoreTokenEtc", token);

    await StoreToken(token);

    if (!token) {
        sessionStorage.removeItem("authUser");
        //sessionStorage.removeItem("selectedSubscription");
        return;
    }

    // load and store authUser
    const auth = { token: token?.access_token }
    const user = await GetUser(auth);
    sessionStorage.setItem("authUser", JSON.stringify(user));

    // load and store selectedSubscription
    // const subscription = await GetSubscription(auth, user.default_subscription_id);
    // sessionStorage.setItem("selectedSubscription", JSON.stringify(subscription));
        
    //console.log("StoreTokenEtc.exit");
}


export const Login = (props) => {

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);
    //const authcontext = useContext(AuthContext);
    const authContext = useContext(AuthContext);

    const login = async () => {
        setError(false);
        setFetching(true);
        try {
        
            const token = await GetToken_Password(globals.client_id, globals.client_secret, email, password, "myscope")
            console.log('token', token);
        
            if (token.error) {
                setError(token.error_description);
            }
            else {
                console.log('no error')

                await StoreTokenEtc(token);
                authContext.setAuthToken(token);
                //authContext.reloadAuth();
                
            }
            
        }
        catch (err) {
            setError(err.message);
        }
        setFetching(false);

    }


    return <AuthOuter title="Sign in">

        {error ? <div className="error">
            <i className="fas fa-warning" /><br/>
            {translateError(error)}
        </div> : null}

        <div className="entry">
            <div className="label">
                Email:
            </div>
            <input id="email" type="text" placeholder="email" value={email || ""} onChange={(ev) => setEmail(ev.target.value)} />
        </div>
        <div className="entry">
            <div className="label">
                Password:
            </div>
            <input id="password" type="password" placeholder="password" value={password || ""} onChange={(ev) => setPassword(ev.target.value)} />
        </div>
        {fetching ? <div>
            Authenticating...
        </div> : null}
        <div className="submit-section">
            
            
            <button type="submit" className="login" disabled={fetching} onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
                login();
            }}>
                Login
            </button>

            <span className="forgot-password">
                <Link to="/recover-password">Recover password</Link>
            </span>

        </div>
        <div className="signup-section">
            <div className="intro">
                Don't have an account?
                <Link to="/sign-up" className="sign-up-link">
                    Sign up
                </Link>
            </div>
        </div>

    </AuthOuter>



}