
import { createContext } from "react";
import { atomFamily, selector, selectorFamily } from "recoil";

// export const computeContextSelector = selector({
//     key: 'computeResultsForScope',
//     get: (args1, args2) => {
//         console.log("args1", args1);
//         console.log("args2", args2);
//         return null;
//     }
// })

export const any = (arr, f) => {
    for( let item in arr ){
        if( f(item) ) return true;
    }
    return false;
}

export const computeContextStates = atomFamily({
    key: 'computeResultsForScope',
    default: selectorFamily({
        key: "computeResultsForScope/Default",
        get: hooks => ({get}) => {
            if( !hooks?.length ) return {};
            if( hooks.length === 1 ) return get(computeContextState(hooks[0].id));

            const result_arr = hooks.map(hook => ({ hook, state: get(computeContextState(hook.id)) }));

            const computing = any(result_arr, (r) => r?.computing);
            const needsCompute = any(result_arr, (r) => r?.needsCompute);
            const error = any(result_arr, (r) => r?.error);

            // memoize??
            return {
                computing,
                needsCompute,
                error,
                result: result_arr.reduce((acc, el) => {
                    return {
                        ...acc,
                        [el.hook.name]: el.state
                    }
                }, {})
            }

        }
    }),
});


export const computeContextState = atomFamily({
    key: 'computeResultForScope',
    default: {
        // result: undefined,
        // computing: false,
        // needsCompute: false,
        // error: undefined
    }
});

export const ComputeRunnerContext = createContext();

