import { useState } from "react";
import { useParams } from "react-router-dom";
import { ModalFrame } from "../modal-dialog/ModalFrame";
import { usePalettes } from "../../hooks/usePalette";
import { BlobClient, BlobServiceClient, BlockBlobClient } from "@azure/storage-blob";
import * as globals from "../../globals";
//import { useApi } from "../../utils/apiControllers";
import { useDispatch } from "react-redux";
import { useImagesApi } from "../../hooks/useImage";


export const NewImage = (props) => {

    const routerParams = useParams();
    const subscription_id = routerParams.subscription_id;
    const project_id = routerParams.project_id;

    const api = useImagesApi();
    //const api = useApi();

    const [newName, setNewName] = useState("");

    const dispatch = useDispatch();

    const [uploading, setUploading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null);


    const uploadFiles = async () => {
        
        const fileInput = document.getElementById("file-input");
        try {
            setUploading(true);
            setUploadStatus("Uploading files...");

            const promises = [];
            for (const file of fileInput.files) {

                setUploadStatus(`Creating image ${file.name}`);

                let ext = file.name.split('.').pop();

                let image = {
                    label: file.name,
                    ext,
                }
                const r = await api.newItem(image);
                console.log('newImage retVal ', r);
                const newId = r?.newId;
                const uploadInfo = r?.uploadInfo;

                if (uploadInfo) {
                    console.log("uploadInfo", uploadInfo);


                    setUploadStatus(`Uploading ${file.name} (${newId})`);
                    const blobClient = new BlockBlobClient(uploadInfo.url);
                    await blobClient.uploadData(file);

                    image.filename = uploadInfo.filename;
                    r.dispatch(); // complete the newItem event dispatch
                    
                    // even with the delayed dispatch, there's still a problem with this
                    // other socket listeners never receive notification that the upload finished
                    // so let's do this for now:                    
                    //await api.updateItem(newId, image);
                    // that still doesn't guarantee a webclient will load the image, doh.
                    // work on this later
                }

                //await api.replaceImage(project_id, { ...image, _id: newId });
                //image.fileName = `${newId}.${ext}`;
                
                // refresh any visualization that points to this since we uploaded the dang filename now
                //dispatch({ type: "IMAGE_TOUCH", id: newId });
                
            }
            await Promise.all(promises);

            setUploadStatus("Done.");
            setUploading(false);

            //listFiles();
        }
        catch (error) {
            setUploadStatus("Error: " + error.message)
            setUploading(false);
        }
    }



    return <ModalFrame close={props.close}
        title="Upload image"
        buttons={[
            {
                label: "Upload",
                callback: async () => {
                    await uploadFiles();
                    // const pal = {
                    //     label: newName,
                    //     //type: newType
                    // }
                    // setNewName("");
                    // createPalette(pal);
                    // props.close();
                }
            },
        ]}
    >
        {uploadStatus ? <div>upload status: {uploadStatus}</div> :
            <div className='form1' style={{ maxWidth: 500 }}>
                <label className="label-line">
                    File:
                </label>
                <div className="value-line">
                    <input type="file" id="file-input" multiple className='full-width' />
                    {/* <input type="file"
                    className='full-width' 
                    placeholder="enter name of new color palette"
                    /> */}
                </div>
            </div>
        }

    </ModalFrame>

    
}

