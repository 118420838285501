import { useState } from "react";
import { useConfig } from "../../hooks/useConfig";
import { useModel, useModelsApi } from "../../hooks/useModel";

import { EditJsModel } from "./EditJsModel";
import "./EditModel.scss";
import { EditStageModel } from "./EditStageModel";
import { MODEL_TYPES } from "./NewModel";

const tabs = [
    { id: 'xtran', label: 'Xtran' },
    //{ id: 'parameters', label: 'Parameters' },
    { id: 'utils', label: 'Utilities' },
    { id: 'test', label: 'Test xBeta' },
    { id: 'iprob', label: 'iProb' }
]

const safeStr = (obj) => {
    if( !obj ) return null;
    return JSON.stringify(obj, null, 4);
}

export const EditModel = (props) => {

    const id = props.id;
    const [model, modelLoading, modelError] = useModel(id);

    const modelType = MODEL_TYPES?.find(m => m.id === model?.type);

    console.log("model", model);



    return <div className='edit-model'>

        <div className='fixed-area'>
        
            <div className='form-row'>
                <div className='property'>
                    Model:
                </div>
                <div className='property-value'>
                    {model?.label} &nbsp;<i className='fal fa-pencil' style={{
                        color: "#afafaf"
                    }} />
                </div>
            </div>

            <div className='form-row'>
                <div className='property'>
                    Model Type:
                </div>
                <div className='property-value'>
                    <select style={{
                        width: 250,
                        padding: 5
                    }}
                        disabled
                    >
                        <option>{modelType?.label}</option>
                    </select>
                </div>
            </div>

        </div>


        <div className='content-area' style={{
            position: "relative",
            //border: "5px solid purple",
            overflow: "hidden",
            }}>

            {
                model?.type === "stage" ? <EditStageModel id={id} key={id} />
                : model?.type === "js" ? <EditJsModel id={id} key={id} />
                : <span>model type not implemented</span>
            }

        </div>
        
    </div>
    
}