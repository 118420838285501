import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router";
import Popup from 'reactjs-popup';
import { useCollections, useCollectionsApi } from "../../hooks/useCollection";
import { useProjectId } from "../../hooks/useProjectId";
import { TextFrame, VerifyFrame } from "../modal-dialog/VerifyFrame";
import { CollectionEntriesView } from "./CollectionEntriesView";
import { NewCollection } from "./NewCollection";
import { useAuth } from "../../hooks/useAuth";
import { LeftMenu } from "../nav/LeftMenu";
import { useDispatch } from "react-redux";
import { Main } from "../nav/Main";


export const Collections = (props) => {
    
    const { project_id, subscription_id } = useProjectId();
    const dispatch = useDispatch();

    const routerParams = useParams();
    const selectedCollectionId = routerParams.id;
    const history = useHistory();

    const api = useCollectionsApi();
    const [collections, collectionsLoading, collectionsError] = useCollections();

    const selectedCollection = collections?.find(e => e.id === selectedCollectionId);

    const closeLeftMenu = () => {
        //dispatch({ type: "LEFT_MENU_OPEN_SET", value: false });
    }
    
    return <>
        
        <LeftMenu>
            <div className='title'>
                Collections
            </div>
            <div className='top-section'>

            </div>
            <div className='scroller'>
                <div className='summary'>
                    <p>
                        Define product lists, brand lists, key segments and more. Collections
                        may be used in instances and selectors throughout the project."
                    </p>
                </div>
                {
                    collectionsLoading ? <div className='list-content'>Loading...</div> :
                    collectionsError ? <div className='list-content error'>Error loading collections</div> :
                    !collections ? null :
                    [...collections]
                        .sort((a, b) => a.label?.localeCompare(b.label))
                        .map(collection => <div key={collection.id} className={`menu-item ${collection.id === selectedCollectionId ? 'sel' : ''}`}>
                        <span className='grip'>
                            <i className='fad fa-box' />
                        </span>
                        <Link
                            className='label'
                            to={`/subscription/${subscription_id}/project/${project_id}/collection/${collection.id}`}
                            onClick={() => closeLeftMenu()}
                        >
                            {collection.label}
                        </Link>
                        <Popup
                            nested
                            contentStyle={{ padding: '0px' }}
                            trigger={<span className='menu' ><i className='far fa-ellipsis-h' /></span>}
                            position="right top">
                            {closeMenu => <ul className='popup-menu'>

                                    <Popup modal trigger={
                                        <li>Rename</li>
                                    }>
                                        {closeModal => <TextFrame
                                            title={"Rename collection"}
                                            label={collection.label}
                                            initialTextValue={collection.label}
                                            close={closeModal} confirmed={async (text) => {
                                                const patchBody = {
                                                    label: text
                                                };
                                                await api.patchItem(collection.id, patchBody);
                                                closeMenu();
                                            }}
                                        />}
                                    </Popup>


                                    <Popup modal trigger={
                                        <li>Delete collection</li>
                                    }>
                                        {closeModal => <VerifyFrame
                                            title={"Delete collection"}
                                            label={collection.label}
                                            close={closeModal} confirmed={async () => {
                                                await api.deleteItem(collection.id);
                                            }}
                                        />}
                                    </Popup>
                            </ul>}
                        </Popup>
                    </div>)
                }
                <div className='menu-item-stub center'>
                    <Popup
                        modal
                        trigger={
                            <div className="btn action" >
                                <i className='glyph fal fa-plus' /> New collection
                            </div>
                        }>
                        {close => <NewCollection close={close} createCollection={async (coll) => {
                            const newInfo = await api.newItem(coll);
                            const newId = newInfo.newId;
                            // navigate to it
                            history.push(`/subscription/${subscription_id}/project/${project_id}/collection/${newId}`)
                            return newId;
                        }}/>}
                    </Popup>
                </div>
                
            </div>
        </LeftMenu>
            
        <Main>
            {selectedCollection ? <CollectionEntriesView collection_id={selectedCollectionId} /> : null}
        </Main>

    </>;


    
}

