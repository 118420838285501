import { Link } from "react-router-dom";
import { docLinks } from "../../../doclinks";
import { useCollections } from "../../../hooks/useCollection";
import { useProjectId } from "../../../hooks/useProjectId";

export const CollectionsTile = (props) => {

    const { subscription_id, project_id } = useProjectId();
    const [collections, collectionsLoading, collectionsError] = useCollections();

    return <div className="tile white-bg pad">
        <div className="title">
            Collections

            <div className="help-link">
                <a href={docLinks.collections} target="_blank" className="icon">
                    <i className="fal fa-question-circle"/>
                </a>
            </div>
        </div>

        <div className="details">

            {collectionsLoading ? <span className="loading">loading...</span> : null}
            
            <div>
                {collections?.map(coll => 
                    <Link
                        key={coll.id}
                        className="item-row"
                        to={`/subscription/${subscription_id}/project/${project_id}/collection/${coll.id}`}
                    >
                        <i className="fad fa-album"/>
                        &nbsp; {coll.label}
                    </Link>
                )}
            </div>

        </div>

        <Link className="edit-link" to={`/subscription/${subscription_id}/project/${project_id}/collections`}>
            Edit collections...
        </Link>

        
    </div>
}