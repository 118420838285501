import { useEffect, useState } from "react";
import { debounce } from "../../../utils/debounce";
import { ColorPickerInput } from "../../edit/ColorPickerInput";
import { DataBindButton } from "../../edit/DataBindButton";
import { ImagePickerInput } from "../../edit/ImagePickerInput";


const STYLES = [
    { value: "none", description: "Specifies no border. This is default" },
    //{ value: "hidden",  description: "The same as \"none\", except in border conflict resolution for table elements" },
    { value: "dotted",  description: "Specifies a dotted border" },
    { value: "dashed",  description: "Specifies a dashed border" },
    { value: "solid",   description: "Specifies a solid border" },
    { value: "double",  description: "Specifies a double border" },
    { value: "groove",  description: "Specifies a 3D grooved border. The effect depends on the border-color value" },
    { value: "ridge",   description: "Specifies a 3D ridged border. The effect depends on the border-color value" },
    { value: "inset",   description: "Specifies a 3D inset border. The effect depends on the border-color value" },
    { value: "outset",  description: "Specifies a 3D outset border. The effect depends on the border-color value" },
    //{ value: "initial", description: "Sets this property to its default value. Read about initial" },
    //{ value: "inherit", description: "Inherits this property from its parent element. Read about inherit" },
]


const debouncedOnChange = debounce((value, callback) => {
    console.log('debounced onChange', value)
    callback();
}, 1000);



export const EditBorder = (props) => {

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const onChange = (newValue) => {
        props?.onChange(newValue);
    }

    const rowPad = 10;

    return <>
        
        <div className='form-group-header'>
            Border
        </div>

        <div className='h-dock' style={{ marginBottom: rowPad }}>
            <span className='left v-center nowrap' style={{ width: 100, marginRight: 10 }}>
                Color:
            </span>
            <span className='center'>
                <ColorPickerInput value={value?.color} onChange={(newColor) => {
                    const newValue = {
                        ...value,
                        color: newColor
                    }
                    setValue(newValue);
                    onChange(newValue);
                }} />
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton />
            </span>
        </div>

        <div className='h-dock' style={{ marginBottom: rowPad }}>
            <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                Width:
            </span>
            <span className='center'>
                <input
                    type="range"
                    style={{ width: "100%" }}
                    min={0} max={20}
                    value={value?.width}
                    onChange={(ev) => {
                        const newValue = {
                            ...value,
                            width: ev.target.value
                        }
                        setValue(newValue);
                        debouncedOnChange(newValue, () => onChange(newValue));
                    }}
                />
            </span>
            <span className="right2 v-center" style={{ marginLeft: 10 }}>
                {value?.width}px
            </span>
            <span className='right v-center' style={{ marginLeft: 10, visibility: "hidden" }}>
                <DataBindButton />
            </span>
        </div>

        <div className='full-dock' style={{ marginBottom: rowPad }}>
            <span className='left v-center nowrap' style={{ width: 100, marginRight: 10 }}>
                Style
            </span>
            <span className='center'>
                <div>
                    <select
                        className="full-width"
                        value={value?.style}
                        onChange={(ev) => {
                            const newValue = {
                                ...value,
                                style: ev.target.value
                            };
                            setValue(newValue);
                            onChange(newValue);
                        }}
                    >
                        {STYLES.map(entry =>
                            <option
                                key={entry.value}
                                value={entry.value}
                            >
                                {entry.value}
                            </option>
                        )}
                    </select>
                </div>
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton />
            </span>
        </div>

        

    </>

}