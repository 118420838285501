import { genericParseEntry, immutableRemoveKey } from "./CreateGenericReducer";

const initialState = {

}



/**
 * Creates a "Generic" reducer which can ideally be used for all main constructs (projects, collections, pages, palettes, images, ...)
 * @param {*} name - Singular name of the construct (e.g., project, page, collection, palette)
 * @returns 
 */
export const CreateGenericChildReducer = (construct) => {

    const name = construct.name;
    //const namePlural = construct.namePlural || name + "s";
    const uName = construct.actionName || name.toUpperCase(); // (e.g., PAGE)
    const uNamePlural = construct.actionNamePlural || uName + "S"; // (e.g., PAGES)

    return (state = initialState, action) => {

        switch (action.type) {
            case `CLEAR_PROJECT`: return {
                ...initialState,
                full_project_id: action.new_fid
            }
            case `CLEAR_SUBSCRIPTION`: return {
                ...initialState,
                subscription_id: action.new_sid
            }
            case `${uNamePlural}_SET_LOADING_ENTRIES`: return {
                ...state,
                [action.parent_id]: {
                    ...state[action.parent_id],
                    loading: action.loading === false ? undefined : true,
                    error: action.error
                }
            }
            case `${uNamePlural}_SET_ENTRIES`: return {
                ...state,
                [action.parent_id]: {
                    ...state[action.parent_id], // we don't want to clear dict, because it could've been loaded without/before entries
                    entries: action.error ? state[action.parent_id]?.entries : action.entries, // don't replace if error
                    loading: undefined,
                    error: action.error,
                    ...action.extra
                }
            }
            case `${uNamePlural}_ADD_ENTRY`: return {
                ...state,
                [action.parent_id]: {
                    ...state[action.parent_id],
                    entries: state[action.parent_id]?.entries ? [
                        ...state[action.parent_id].entries.filter(e => e.id !== action.entry?.id),
                        action.entry
                    ] : null
                }
            }
            case `${uNamePlural}_ADD_VALUE`: {
                throw Error("not implemented");
                if( action.parent_id ){
                    const newEntry = action.entry || genericParseEntry({ ...action.value, id: action.id });
                    return {
                        ...state,
                        // note: we aren't adding the id-entry here, because we need to add it to parent instead
                        dict: {
                            ...state.dict,
                            // add the id-entry to the parent item's .children if the parent item exists
                            [action.parent_id]: state.dict[action.parent_id] ?
                                { 
                                    ...state.dict[action.parent_id],
                                    children: [...(state.dict[action.parent_id].children || []), newEntry]
                                } 
                                : undefined,
                            // add the value
                            [action.id]: action.value
                        }
                    }
                }
                else return {
                    ...state,
                    entries: state.entries ? [
                        ...state.entries.filter(e => e.id !== action.id),
                        (action.entry || genericParseEntry({ ...action.value, id: action.id }))
                    ] : null,
                    dict: {
                        ...state.dict,
                        [action.id]: action.value
                    }
                }
            }
            case `${uName}_SET_LOADING`: return { // e.g., PAGE_SET_LOADING
                ...state,
                [action.parent_id]: {
                    ...state[action.parent_id],
                    entryLoading: {
                        ...state[action.parent_id]?.entryLoading,
                        [action.id]: action.loading === false ? undefined : true // note: if action.loading is undefined, this should set to true
                    }
                }
            }
            case `${uName}_SET`: {
                return {
                    ...state,
                    [action.parent_id]: {
                        ...state[action.parent_id],
                        entries: state[action.parent_id]?.entries?.map(e => 
                            e.id === action.id ? (
                                action.error ? e : // if error, don't replace the entry
                                (action.entry || genericParseEntry({ ...action.value, id: action.id })) 
                            )
                            : e),
                        dict: {
                            ...state[action.parent_id]?.dict,
                            [action.id]: {
                                ...(action.error ? state[action.parent_id]?.dict?.[action.id] : action.value), // if there's an error, don't replace the value
                            }
                        },
                        entryLoading: immutableRemoveKey(state[action.parent_id]?.entryLoading, action.id),
                        entryError: immutableRemoveKey(state[action.parent_id]?.entryError, action.id)
                    }
                }
            }
            case `${uName}_PATCH`: {
                return {
                    ...state,
                    [action.parent_id]: {
                        ...state[action.parent_id],
                        entries: state[action.parent_id]?.entries?.map(e => (e.id === action.id) ? {
                                ...e,
                                ...(action.entry || construct.parseEntry({ ...action.value, id: action.id }))
                            } : e
                        ),
                        dict: {
                            ...state[action.parent_id]?.dict,
                            [action.id]: {
                                ...state[action.parent_id]?.dict?.[action.id],
                                ...action.value, // if there's an error, don't replace the value
                            }
                        },
                    }
                }
            }
            case `${uName}_DELETE`: return {
                ...state,
                [action.parent_id]: {
                    ...state[action.parent_id],
                    entries: state[action.parent_id]?.entries?.filter(entry => entry.id !== action.id),
                    dict: immutableRemoveKey(state[action.parent_id]?.dict, action.id),
                    entryLoading: immutableRemoveKey(state[action.parent_id]?.entryLoading, action.id),
                    entryError: immutableRemoveKey(state[action.parent_id]?.entryError, action.id)
                }
            }
            default:
        }

        return state;
    }

}

