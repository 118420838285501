import React, { useEffect, useState } from 'react';
import { computeApi } from '../../api/computeApi';
import { EditDataSourceContext, HasDataSourcesContext, SelectDataSourceContext } from '../../context/Contexts';
import { useAuth } from '../../hooks/useAuth';
import { useDataSource, useDataSources } from '../../hooks/useDataSource';
import { useDataSourceRevision, useDataSourceRevisions } from '../../hooks/useDataSourceRevision';
import { useProjectId } from '../../hooks/useProjectId';
import { DataSourceRevisionSelector } from '../data-sources/DataSourceRevisionSelector';
import { DataSourceSelector } from "../data-sources/DataSourceSelector";
import "./StringExplorer.scss";


export const StringExplorer = (props) => {

    const auth = useAuth();
    const { subscription_id, project_id } = useProjectId();
    const [selectedDataSourceId, setSelectedDataSourceId] = useState(null);
    const [dsList, dsListLoading, dsListError, dsListRefresh] = useDataSources();
    const [ds, dsLoading, dsError, dsReload] = useDataSource(selectedDataSourceId);

    const [revisions, revsLoading, revsError, revsTriggerReload] = useDataSourceRevisions(selectedDataSourceId);
    const [selectedRevisionId, setSelectedRevisionId] = useState(null);
    const [revision, revisionLoading, revisionError, revisionReload] = useDataSourceRevision(selectedDataSourceId, selectedRevisionId);

    const [selectedField, setSelectedField] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [result, setResult] = useState(null);



    //console.log("result", result);

    const contextValue = {
        dsList,
        dsListLoading,
        dsListError,
        dsListRefresh,
        //selectedDs: selectedDataSourceId,
        //setSelectedDs: setSelectedDataSourceId
    };

    const context2Value = {
        ds: selectedDataSourceId,
        dsLoading: false,
        dsError: false,
        dsTriggerRefresh: () => null,
        revisions,
        revsLoading,
        revsError,
        revsTriggerReload,
        selectedRevisionId,
        setSelectedRevisionId
    }

    const fields = revision?.fields;
    const strFields = fields?.filter(f => f.type === "string");

    useEffect(() => {

        if( !selectedDataSourceId || !selectedRevisionId || !selectedField ) return;

        (async () => {
            const stages = [
                { 
                    $match: {
                        $and: [
                            { [selectedField.name]: {$ne:null} },
                            { [selectedField.name]: {$ne:""} }
                        ]
                        
                    }
                },
                {
                    $project: {
                        _id: 1,
                        result: `$${selectedField.name}`
                    }
                }
            ];
            const options = {};

            setError(null);
            setLoading(true);
            try{
                const res = await computeApi.compute(auth, subscription_id, project_id, selectedDataSourceId, selectedRevisionId, stages, options);
                setResult(res);
                setLoading(false);
            }
            catch(err){

                setError(err);
                setLoading(false);
            }
        })();


    }, [selectedDataSourceId, selectedRevisionId, selectedField?.name])
    

    return <div className="string-explorer">
        <HasDataSourcesContext.Provider value={contextValue}>
            <SelectDataSourceContext.Provider value={contextValue}>
                <div className="heading-pane">
                    <DataSourceSelector 
                        width={400} 
                        id={selectedDataSourceId}
                        onChange={newId => {
                            setSelectedDataSourceId(newId);
                        }}
                        />
                    <EditDataSourceContext.Provider value={context2Value}>
                        <DataSourceRevisionSelector width={400}/>
                    </EditDataSourceContext.Provider>
                </div>
                <div className="select-vars">
                    {strFields?.map(f => 
                        <div 
                            className={`field-item ${selectedField === f ? "sel" : ""}`}
                            onClick={() => setSelectedField(f)}
                        >
                            <span className="field-name">
                                {f.name}
                            </span>
                            <span className="field-label">
                                {f.label}
                            </span>
                        </div>
                    )}
                </div>
                <div className="view-content">
                    {
                    loading ? "loading..." : 
                    error ? "error: " + error :
                    result?.data?.map?.(row => 
                        <div className="result-row">
                            {row.result}
                            <a 
                                href={`/subscription/${subscription_id}/project/${project_id}/tool/record-explorer?ds=${selectedDataSourceId}&rev=${selectedRevisionId}&_id=${row._id}`}
                                className="_id">
                                view record
                            </a>
                        </div>
                    )}
                </div>
            </SelectDataSourceContext.Provider>
        </HasDataSourcesContext.Provider>
    </div>
    
    
}


