import { useContext, useLayoutEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import { HasDataSourcesContext } from "../../context/Contexts";
import { getDataSourceIconClass } from "../../utils/IconHelper";
import "./DataSourceSelector.scss";

export const DataSourceSelector = (props) => {

    const context = useContext(HasDataSourcesContext);
    const ref1 = useRef();

    const { dsList, dsListLoading, dsListError, dsListRefresh } = context;
    //const { selectedDs, setSelectedDs } = context;
    const selectedDs = dsList?.find(ds => ds.id === props.id);

    const allowedDsList = dsList?.filter(ds => ds.structured || ds.type === "flow"); // only allow structured data sources (what about flows?)

    let style = {};
    if( props.width ){
        style.width = props.width;
    }

    const [popupWidth, setPopupWidth] = useState(null);

    useLayoutEffect(() => {
        setPopupWidth(ref1?.current?.offsetWidth);
    })

    return <div className="ds-selector" style={style} ref={ref1}>

        <span className="selector-title">
            Select DataSource
        </span>

        <Popup
            keepTooltipInside
            arrow={false}
            contentStyle={{ 
                width: popupWidth, 
                border: "1px solid #202020",
                boxShadow: "-5px 5px 5px #808080"
            }}
            trigger={
                <span className="selection">
                    <span className="text">
                    <i className={`fad ${getDataSourceIconClass(selectedDs)}`} style={{ marginRight: 10 }} />
                        {selectedDs?.label || "[Click to select data source]"}
                    </span>
                </span>
            }
        >
            {close => <div className="popup-menu max-height-small style-alt1">
                {dsListLoading ? "loading..." : null}
                {allowedDsList?.map(ds => 
                    <div
                        key={ds.id}
                        className={`menu-item mini ${ds.id === selectedDs?.id ? "sel" : ""}`}
                        onClick={() => {
                            props.onChange?.(ds.id);
                            close();
                        }}
                    >
                        <i className={`fad ${getDataSourceIconClass(ds)}`} style={{ marginRight: 10 }} />
                        {ds.label}
                    </div>
                )}
            </div>
            }
        </Popup>
 
    </div>
}