import JSON5 from "json5";
import { ROOT_ID } from "../hooks/helper/ContentItemHelper";


const initialState = {
    loading: false,
    entries: null, // array that stores (in order) very basic info like: { id, label }
    dict: {} // dictionary that stores all details about each entry: { id, label, ... } (e.g., a collection entry might have a lot of additional detail)
}

/**
 * Helps remove a dict entry in easier one-line syntax
 * @param {*} dict - The source dictionary
 * @param {*} key - The key of the entry to remove from dictionary
 * @returns 
 */
export const immutableRemoveKey = (dict, key) => {
    if( !dict ) return undefined;
    let newDict = { ...dict };
    delete newDict[key]; // key might be null, but js allows null as an object key
    if( Object.keys(newDict)?.length === 0 ) return undefined;
    return newDict;
}

export const immutableRemoveKeys = (dict, keys) => {
    if( !dict ) return undefined;
    let newDict = { ...dict };
    keys?.forEach(key => delete newDict[key]);
    if( Object.keys(newDict)?.length === 0 ) return undefined;
    return newDict;
}


/**
 * Generic method to extract an entry from a value object. A value object is stored in state.dict whereas an entry is lighter and is stored in state.entries.
 * For constructs that need something other than the generic parsing, ideally those actions would prove an already parsed entry.
 * @param {*} valueObj 
 * @returns 
 */
export const genericParseEntry = valueObj => ({
    id: valueObj.id,
    label: valueObj.label
});

/**
 * Creates a "Generic" reducer which can ideally be used for all main constructs (projects, collections, pages, palettes, images, ...)
 * @param {*} name - Singular name of the construct (e.g., project, page, collection, palette)
 * @returns 
 */
export const CreateGenericReducer = (construct) => {

    const name = construct.name;
    //const namePlural = construct.namePlural || name + "s";
    const uName = construct.actionName || name.toUpperCase(); // (e.g., PAGE)
    const uNamePlural = construct.actionNamePlural || uName + "S"; // (e.g., PAGES)

    // todo: find a better name for this function
    // it is only used by the 'project' construct
    // it's only purpose is the parse the settings_str into settings
    const parseStuffIfNeeded = (value) => {
        if( uName !== "PROJECT" ) return value;
        if( !value?.settings_str ) return value;
        let settings = null;
        try{
            settings = JSON5.parse(value.settings_str);
        }
        catch(err){
            console.error("Error parsing project settings", err);
        }
        return {
            ...value,
            settings
        }
    }
    
    return (state = initialState, action) => {

        switch (action.type) {
            case "INVALID_TOKEN_ENCOUNTERED" : {
                return state;
            }
            case `CLEAR_PROJECT`: {
                if( construct.isSubscription ) return state; // ignore for subscription
                return {
                    ...initialState,
                    full_project_id: action.new_fid
                }
            }
            case `CLEAR_SUBSCRIPTION`: return {
                ...initialState,
                subscription_id: action.new_sid,
                socket: state.socket
            }
            // case `${uNamePlural}_CLEAR_PAGE`: return { // HUH!?? why is "_PAGE" here?
            //     ...initialState,
            //     page_id: action.new_id
            // }
            case `${uName}_SOCKET_UPDATE` : return {
                ...state,
                socket: {
                    ...state.socket,
                    ...action.patch
                }
            }
            case `${uNamePlural}_SET_MISC_VALUE`: return { // e.g., PAGES_SET_MISC_VALUE
                ...state,
                [action.name]: action.value
            }
            case `${uNamePlural}_SET_LOADING_ENTRIES`: return { // e.g., PAGES_SET_LOADING_ENTRIES
                ...state,
                loading: action.loading === false ? undefined : true,
                error: action.error
            }
            case `${uNamePlural}_SET_ENTRIES`: return { // e.g., PAGES_SET_ENTRIES
                ...state,
                entries: action.error ? state?.entries : action.entries, // don't replace if error; uh, don't call this if error (todo)
                loading: undefined,
                error: action.error,
                ...action.extra
            }
            case `${uNamePlural}_ADD_ENTRY`: return { // e.g., PAGES_ADD_ENTRY
                ...state,
                entries: state.entries ? [
                    ...state.entries.filter(e => e.id !== action.entry?.id),
                    action.entry
                ] : null
            }
            case `${uNamePlural}_ADD_VALUE`: { // e.g., PAGES_ADD_VALUE
                if( action.parent_id ){
                    const newEntry = action.entry || genericParseEntry({ ...action.value, id: action.id });
                    return {
                        ...state,
                        // note: we aren't adding the id-entry here, because we need to add it to parent instead
                        dict: {
                            ...state.dict,
                            // add the id-entry to the parent item's .children if the parent item exists
                            [action.parent_id]: state.dict[action.parent_id] ?
                                { 
                                    ...state.dict[action.parent_id],
                                    children: [...(state.dict[action.parent_id].children || []), newEntry]
                                } 
                                : undefined,
                            // add the value
                            [action.id]: action.value
                        }
                    }
                }
                else return {
                    ...state,
                    entries: state.entries ? [
                        ...state.entries.filter(e => e.id !== action.id),
                        (action.entry || genericParseEntry({ ...action.value, id: action.id }))
                    ] : null,
                    dict: {
                        ...state.dict,
                        [action.id]: action.value
                    }
                }
            }
            case `${uName}_SET_LOADING`: return { // e.g., PAGE_SET_LOADING
                ...state,
                entryLoading: {
                    ...state.entryLoading,
                    [action.id]: action.loading === false ? false : true
                },
                entryError: action.error ? {
                    ...state.entryError,
                    [action.id]: action.error
                } : state.entryError
            }
            case `${uName}_SET`: {
                return {
                    ...state,
                    entries: 
                        state.entries?.map(e => 
                            e.id === action.id ? (action.entry || genericParseEntry({ ...action.value, id: action.id }))
                        : e),
                    dict: {
                        ...state.dict,
                        [action.id]: {
                            ...parseStuffIfNeeded(action.value),
                        }
                    },
                    entryLoading: immutableRemoveKey(state.entryLoading, action.id),
                    entryError: immutableRemoveKey(state.entryError, action.id)
                }
            }
            case `${uName}_SET_MULTIPLE`: {

                // this is only used by content-item
                // when a content-item is loaded, its non-lazy-loaded children will be loaded too
                // thus, *_set_multiple is called to store all the children

                // if called by useRootContentItem, action.newEntries will be provided and
                // will have only zero or one elements. The one element will be the id and ctype of
                // root content item if exists
                
                // Even though multiple values are being set, a single "id" will exist in the action,
                // and is the id of the content-item that triggered the load

                // construct new 
                const newDict = { ...state.dict };
                if( action.values ){
                    for( let item of action.values ){
                        newDict[item.id] = item;
                    }
                }

                const newState = {
                    ...state,
                    dict: newDict
                };

                if( action.newEntries ){
                    // called from useContentItem when loading ROOT
                    newState.entries = action.newEntries;
                }

                {
                    // called from an individual (content) item, so clear loading bit and error for that item
                    newState.entryLoading = immutableRemoveKey(state.entryLoading, action.id);
                    newState.entryError = immutableRemoveKey(state.entryLoading, action.id);
                }

                return newState;

            }
            case `${uName}_PATCH`: {
                return { 
                    ...state,
                    entries: state.entries?.map(e => 
                        (e.id === action.id) ? (
                            action.entry ? { ...e, ...action.entry }
                                : genericParseEntry({ ...state.dict?.[action.id], ...action.value })
                            )
                        : e)
                        ,
                    dict: {
                        ...state.dict,
                        [action.id]: {
                            ...state.dict?.[action.id],
                            ...parseStuffIfNeeded(action.value)
                        }
                    },
                    entryLoading: immutableRemoveKey(state.entryLoading, action.id),
                    entryError: immutableRemoveKey(state.entryError, action.id)
                }
            }
            case `${uName}_PATCH_CHILD_ORDER`: {
                return { 
                    ...state,
                    dict: {
                        ...state.dict,
                        [action.id]: {
                            ...state.dict?.[action.id],
                            children: action.ordered_ids.map((id_entry) => 
                                ({ 
                                    ...state.dict?.[action.id].children?.find(ch => ch.id === id_entry.id),
                                    display_index: id_entry.display_index
                                })
                            )
                        }
                    },
                    entryLoading: immutableRemoveKey(state.entryLoading, action.id),
                    entryError: immutableRemoveKey(state.entryError, action.id)
                }
            }
            case `${uName}_DELETE`: return {
                ...state,
                entries: state.entries?.filter(entry => entry.id !== action.id),
                dict: immutableRemoveKey(state.dict, action.id),
                entryLoading: immutableRemoveKey(state.entryLoading, action.id),
                entryError: immutableRemoveKey(state.entryError, action.id)
            }
            case `${uName}_SET_EXPANDED`: return { // this guy is for PAGE only
                ...state,
                expanded:
                    action.value && state.expanded?.includes(action.id) ? state.expanded :
                    action.value ? [...(state.expanded || []), action.id] :
                    state.expanded?.filter(i => i !== action.id)
            }
            case `${uName}_SET_SELECTED_INSTANCE`: return { // this is probablye for PAGE only too
                ...state,
                selectedInstances: {
                    ...state.selectedInstances,
                    [action.id]: action.value
                }
            }
            default:
        }

        return state;
    }

}

