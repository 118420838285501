import { useState } from "react";
import { SelectDataSourceContext } from "../../../context/Contexts";
import { ControlledCodeEditorFillContainer } from "../../code-editor/CodeEditor";
import { DataSourceSelector } from "../DataSourceSelector";
import { FakeChart1 } from "../FakeChart1"
import { EtlNode } from "./EtlNode"


export const JsonSyntaxEtlNodeEditor = (props) => {
    const { data, saveData } = props;

    const [label, setLabel] = useState(data?.label);
    const [syntax, setSyntax] = useState(data?.syntax);

    return <div 
        className="full-dock fill-parent-absolute"
    >
        <div className="top">
            <input type="text" placeholder="Optional node label" style={{ width: "100%" }}
                value={label} onChange={(ev) => setLabel(ev.target.value)}
            />
        </div>
        <div className="center" style={{ border: "1px solid teal", overflow: "hidden" }}>
            <ControlledCodeEditorFillContainer 
                editorProps={{
                    options: {
                    lineNumbers: "off",
                    folding: false,
                    wordWrap: false,
                    minimap: {
                        enabled: false
                    }
                    }
                }}
                language="json"
                value={syntax}
                onChange={(newCode) => setSyntax(newCode)}
            />
        </div>
        <div className="bottom" style={{ paddingTop: 5 }}>
            <button style={{ padding: "5px 10px" }} onClick={() => {
                saveData({
                    ...data,
                    label,
                    syntax
                })
            }}>
                Save
            </button>
        </div>
    </div>
}


export const GenericEtlNodeEditor = (props) => {
    const { data, saveData } = props;

    const [label, setLabel] = useState(data?.label);

    return <div>
        <div>
            [generic etl node editor]
        </div>
        <div>
            <input type="text" value={label || ""} onChange={(ev) => setLabel(ev.target.value)} />
        </div>
        <pre>
            {JSON.stringify(data, null, 4)}
        </pre>
        <div>
            <button 
                onClick={() => {
                    const newData = {
                        ...data,
                        label
                    }
                    saveData(newData);
                }}
            >
                Save
            </button>
        </div>
        

    </div>;
}

//onChange={(newId) => setPipeline({ ...pipeline, dataSourceId: newId})}

export const DataSourceEtlNodeEditor = (props) => {
    const { data, saveData } = props;
    return <div>
        <DataSourceSelector id={data?.dataSourceId}
            onChange={(newId) => saveData({ ...data, dataSourceId: newId })}
            width={"100%"}
        />
    </div>
}

export const FilterEtlNodeEditor = (props) => {
    const { data, saveData } = props;

    const [label, setLabel] = useState(data?.label);
    const [syntax, setSyntax] = useState(data?.syntax);

    return <div 
        className="full-dock fill-parent-absolute"
    >
        <div className="top">
            <input type="text" placeholder="Optional node label" style={{ width: "100%" }}
                value={label} onChange={(ev) => setLabel(ev.target.value)}
            />
        </div>
        <div className="center" style={{ border: "1px solid teal", overflow: "hidden" }}>
            <ControlledCodeEditorFillContainer 
                editorProps={{
                    options: {
                    lineNumbers: "off",
                    folding: false,
                    wordWrap: false,
                    minimap: {
                        enabled: false
                    }
                    }
                }}
                language="json"
                value={syntax}
                onChange={(newCode) => setSyntax(newCode)}
            />
        </div>
        <div className="bottom" style={{ paddingTop: 5 }}>
            <button style={{ padding: "5px 10px" }} onClick={() => {
                saveData({
                    ...data,
                    label,
                    syntax
                })
            }}>
                Save
            </button>
        </div>
    </div>
};

export const StackNFilesEtlNodeEditor = GenericEtlNodeEditor;

export const JoinFilesEtlNodeEditor = (props) => {
    const { data, saveData } = props;

    const [label, setLabel] = useState(data?.label);
    const [localField, setLocalField] = useState(data?.localField);
    const [foreignField, setForeignField] = useState(data?.foreignField);

    const labelStyle1 = {
        padding: "2px 5px",
    }

    const labelStyle1b = {
        ...labelStyle1,
        marginTop: 10
    }



    return <div 
        className="full-dock fill-parent-absolute"
    >
        <div className="top">
            <input type="text" placeholder="Optional node label" style={{ width: "100%" }}
                value={label} onChange={(ev) => setLabel(ev.target.value)}
            />

        </div>
        <div className="center" style={{ border: "1px solid #cfcfcf", overflow: "hidden", padding: 10, marginTop: 10 }}>
            <div style={labelStyle1}>
                Local field:
            </div>
            <input type="text" placeholder="Field name" style={{ width: "100%" }}
                value={localField} onChange={(ev) => setLocalField(ev.target.value)}
            />

            <div style={labelStyle1b}>
                Foreign field:
            </div>
            <input type="text" placeholder="Field name" style={{ width: "100%" }}
                value={foreignField} onChange={(ev) => setForeignField(ev.target.value)}
            />
        </div>
        <div className="bottom" style={{ paddingTop: 5 }}>
            <button style={{ padding: "5px 10px" }} onClick={() => {
                saveData({
                    ...data,
                    label,
                    localField,
                    foreignField,
                })
            }}>
                Save
            </button>
        </div>
    </div>
};


export const ComputeEtlNodeEditor = GenericEtlNodeEditor;
export const MapEtlNodeEditor = GenericEtlNodeEditor;



export const RemoveFieldsEtlNodeEditor = (props) => {
    const { data, saveData } = props;

    const [label, setLabel] = useState(data?.label);
    const [syntax, setSyntax] = useState(data?.syntax);

    return <div 
        className="full-dock fill-parent-absolute"
    >
        <div className="top">
            <input type="text" placeholder="Optional node label" style={{ width: "100%" }}
                value={label} onChange={(ev) => setLabel(ev.target.value)}
            />
        </div>
        <div className="center" style={{ border: "1px solid teal", overflow: "hidden" }}>
            <ControlledCodeEditorFillContainer 
                editorProps={{
                    options: {
                    lineNumbers: "off",
                    folding: false,
                    wordWrap: false,
                    minimap: {
                        enabled: false
                    }
                    }
                }}
                language="json"
                value={syntax}
                onChange={(newCode) => setSyntax(newCode)}
            />
        </div>
        <div className="bottom" style={{ paddingTop: 5 }}>
            <button style={{ padding: "5px 10px" }} onClick={() => {
                saveData({
                    ...data,
                    label,
                    syntax
                })
            }}>
                Save
            </button>
        </div>
    </div>
}

export const LimitEtlNodeEditor = (props) => {
    const { data, saveData } = props;

    const [label, setLabel] = useState(data?.label);
    const [syntax, setSyntax] = useState(data?.syntax);

    return <div 
        className="full-dock fill-parent-absolute"
    >
        <div className="top">
            <input type="text" placeholder="Optional node label" style={{ width: "100%" }}
                value={label} onChange={(ev) => setLabel(ev.target.value)}
            />
        </div>
        <div className="center" style={{ border: "1px solid teal", overflow: "hidden" }}>
            <ControlledCodeEditorFillContainer 
                editorProps={{
                    options: {
                    lineNumbers: "off",
                    folding: false,
                    wordWrap: false,
                    minimap: {
                        enabled: false
                    }
                    }
                }}
                language="json"
                value={syntax}
                onChange={(newCode) => setSyntax(newCode)}
            />
        </div>
        <div className="bottom" style={{ paddingTop: 5 }}>
            <button style={{ padding: "5px 10px" }} onClick={() => {
                saveData({
                    ...data,
                    label,
                    syntax
                })
            }}>
                Save
            </button>
        </div>
    </div>
}

export const ChangeFieldsEtlNodeEditor = GenericEtlNodeEditor;
export const CustomEtlNodeEditor = GenericEtlNodeEditor;
export const DataOutEtlNodeEditor = GenericEtlNodeEditor;
export const XslTransformEditor = GenericEtlNodeEditor;

