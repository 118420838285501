import { useContext } from "react";
import { AuthContext } from "../../context/Contexts";
import "./EditAccount.scss";

export const EditAccount = (props) => {
    
    const authContext = useContext(AuthContext);
    const user = authContext?.authUser;
    const account = {
        accountId: user?.accountId,
        accountName: user?.accountName,
        isAccountAdmin: user?.isAccountAdmin
    }
    const admins = ["todo"];
    const users = ["todo"];

    return <div className="edit-account">
        <div>
            Account:
        </div>
        <pre>
            {JSON.stringify(account, null, 2)}
        </pre>
        <div>
            Account administrator(s):
        </div>
        <pre>
            {JSON.stringify(admins, null, 2)}
        </pre>
        <div>
            Account users:
        </div>
        <pre>
            {JSON.stringify(users, null, 2)}
        </pre>
    </div>

}