import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import { EditDataSourceContext } from "../../context/Contexts";
import "./DataSourceRevisionSelector.scss";

export const DataSourceRevisionSelector = (props) => {

    const dsContext = useContext(EditDataSourceContext);
    const ds = dsContext.ds;
    const revisions = dsContext.revisions;
    const ref1 = useRef();
    
    let style = {};
    if( props.width ){
        style.width = props.width;
    }

    const [popupWidth, setPopupWidth] = useState(null);

    useLayoutEffect(() => {
        setPopupWidth(ref1?.current?.offsetWidth);
    })

    //if( !ds || !revisions ) return null;

    const sortedRevisions = revisions?.sort((a, b) => b.id - a.id);

    const devOn = ds?.dev_revision && revisions && (dsContext.selectedRevisionId === ds?.dev_revision);
    const prodOn = ds?.prod_revision && revisions && (dsContext.selectedRevisionId === ds?.prod_revision);

    const selectedRevision = revisions?.find(r => r.id === dsContext.selectedRevisionId);

    const adjustSelection = (amt) => {
        if( !sortedRevisions ) return;
        const idx = sortedRevisions.indexOf(selectedRevision);
        let newIdx = idx + amt;
        if( newIdx < 0 ) return;
        if( newIdx >= sortedRevisions.length ) return;
        const newRevision = sortedRevisions[newIdx];
        dsContext.setSelectedRevisionId(newRevision.id);
    }

    return <div className="ds-revision-selector" style={style} ref={ref1}>

        <span className="selector-title">
            Select Revision
            <span style={{ float: "right" }}>
                <span className={`mini-env-pill dev ${devOn ? "on" : ""}`}
                    onClick={() => dsContext.setSelectedRevisionId(ds?.dev_revision)}
                >
                    Dev
                </span>
                <span className={`mini-env-pill dev ${prodOn ? "on" : ""}`}
                    onClick={() => dsContext.setSelectedRevisionId(ds?.prod_revision)}
                >
                    Prod
                </span>
            </span>
        </span>

        <span className={`env-pill dev ${devOn ? "on" : ""}`}
            onClick={() => dsContext.setSelectedRevisionId(ds?.dev_revision)}
        >
            dev
        </span>
        <span className={`env-pill prod ${prodOn ? "on" : ""}`}
            onClick={() => dsContext.setSelectedRevisionId(ds?.prod_revision)}
        >
            prod
        </span>
        <span className="minus" onClick={() => adjustSelection(1)}>
            <i className="fal fa-minus-circle" />
        </span>
        <Popup
            keepTooltipInside
            arrow={false}
            contentStyle={{ width: popupWidth }}
            trigger={
                <span className="selection" style={{ position: "relative" }}>
                    <span className="revision-id">
                        {selectedRevision?.id}
                    </span>
                    <span className="text">
                        {(!ds || !sortedRevisions) ? "loading..." :
                            selectedRevision ? (selectedRevision?.notes || "(no notes)") :
                            "[select a revision]"
                        }
                        { devOn && <span className="env-tag">dev</span> }
                        { prodOn && <span className="env-tag">prod</span> }
                    </span>
                    <span style={{ position: "absolute", right: 10 }}>
                        <i className="fal fa-chevron-down" />
                    </span>
                </span>
            }
        >
            {close => <div className="popup-menu max-height-small style-alt1">
                {sortedRevisions?.map(rev => 
                    <div
                        key={rev.id}
                        className={`menu-item mini ${rev.id === dsContext.selectedRevisionId ? "sel" : ""}`}
                        onClick={() => {
                            dsContext.setSelectedRevisionId(rev.id);
                            close();
                        }}
                    >
                        <span className="revision-id">{rev.id}</span>
                        &nbsp; {rev.notes || '(no notes)'}
                        { rev.id === ds.dev_revision && <span className="env-tag">dev</span> } 
                        { rev.id === ds.prod_revision && <span className="env-tag">prod</span> } 
                    </div>
                )}
            </div>}
        </Popup>
        <span className="plus" onClick={() => adjustSelection(-1)}>
            <i className="fal fa-plus-circle" />
        </span>

    </div>
}