import { useContext, useState } from "react"
import { HasDataSourcesContext, HasPipelineContext, HasPipelinesContext, SelectDataSourceContext } from "../../context/Contexts"
import { useDataSources } from "../../hooks/useDataSource";
import { EditPipeline } from "./EditPipeline";
import "./EditPipelines.scss";

const getUniquePipelineId = (pipelines) => {
    let i = 1;
    while(pipelines?.find(p => p.id === `p${i}`)){
        i++
    }
    return `p${i}`;
}

export const EditPipelines = (props) => {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const { pipelines, setPipelines } = useContext(HasPipelinesContext);
    
    const selectedPipeline = pipelines?.[selectedIndex];

    const pContext = {
        pipeline: selectedPipeline,
        setPipeline: (val) => {
            setPipelines(pipelines.map(p => p === selectedPipeline ? val : p));
        }
    }
    
    const [dsList, dsListLoading, dsListError, dsListRefresh] = useDataSources();
    const dsContextValue = {
        dsList,
        dsListLoading,
        dsListError,
        dsListRefresh,
    };

    return <HasDataSourcesContext.Provider value={dsContextValue}>
        <div className="edit-pipelines">
            <div className="select-index">

                {/* select pipeline */}
                {pipelines?.map((p, pIdx) => 
                    <div key={p.id} className={`mini-tab ${pIdx === selectedIndex ? "sel" : ""}`}
                        onClick={() => setSelectedIndex(pIdx)}
                    >
                        {p.id}
                        <span className="btn del" onClick={() => {
                            // delete pipeline
                            setPipelines(pipelines.filter(p1 => p1 !== p));
                        }}>
                            <i className="fal fa-times" />
                        </span>
                    </div>
                )}

                <div className="mini-tab plus" onClick={() => {
                    // add new pipeline
                    setPipelines([...(pipelines || []), { id: getUniquePipelineId(pipelines) }]);
                }}>
                    +
                </div>

            </div>
            <div className="selected-pipeline">
                {selectedPipeline ? 
                    <HasPipelineContext.Provider value={pContext}>
                        <EditPipeline showRevisionId />
                    </HasPipelineContext.Provider>
                : null}
            </div>
        </div>
    </HasDataSourcesContext.Provider>;

}