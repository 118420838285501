import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import * as globals from "../../globals";
import "./LeftMenu.scss";


export const LeftMenu = (props) => {

    const open = useSelector(state => state.ui.leftMenuOpen);
    const [pinned, setPinned] = useState(false);
    const dispatch = useDispatch();

    let actualPinned = pinned;
    let actualOpen = open;

    const collapsePoint = props.v2 ? globals.metrics["left-menu-collapse-point2"] :
        globals.metrics["left-menu-collapse-point"];

    const skinny = useMediaQuery(`(max-width: ${collapsePoint}px)`);

    // if skinny, ignore pinned state
    if (skinny) actualPinned = false;

    // if wide enough, force pin
    if (!skinny) actualPinned = true;


    // if pinned, force open
    if (actualPinned) {
        actualOpen = true;
    }

    // if skinny and !open, okay

    let showChevron = !actualPinned;

    // if v3, screw all rules
    if( true ){
        // actualPinned = open;
        actualOpen = open;
        showChevron = true;
    }

    let classNames = [];
    classNames.push("left-menu");
    classNames.push(props.className || "");
    classNames.push(actualOpen ? "open" : "collapsed");


    return <div className={classNames.join(" ")}>
        {showChevron && <div
            className="openment-tab"
            onClick={() => {
                //setOpen(!open);
                dispatch({ type: "LEFT_MENU_OPEN_SET", value: !open });
            }}
        >
            {actualOpen ? <i className="far fa-chevron-down"/> : <i className="far fa-chevron-up"/>}
        </div>
        }
        <div className="left-menu-content">
            {props.children}
        </div>
    </div>;
}

