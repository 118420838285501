import { memo, useContext, useEffect, useState } from "react";
import { Handle, useUpdateNodeInternals } from "react-flow-renderer";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import { EtlFlowContext } from "../../../context/Contexts";
import "./EtlNode.scss"

const NODE_SHAPES = [
    { label: "rectangle", width: 225, height: 65, borderRadius: 0 },
    { label: "square 120", width: 120, height: 120, borderRadius: 0 },
    { label: "square 120", width: 120, height: 120, borderRadius: 0 },
    { label: "circle 80", width: 80, height: 80, borderRadius: 80 },
    { label: "square 500x300", width: 430, height: 300, borderRadius: 0 },
]

const SWATCH_COLORS = [
    {
        swatches: [
            { bg: "#ffffffa2", fg: "#404040" },
            { bg: "#dfdfdfa2", fg: "#404040" },
            { bg: "#cfcfcfa2", fg: "#404040" },
            { bg: "#afafafa2", fg: "#404040" },
            { bg: "#8f8f8fa2", fg: "#404040" },
            { bg: "#6f6f6fa2", fg: "#efefef" },
            { bg: "#4f4f4fa2", fg: "#efefef" },
            { bg: "#2f2f2fa2", fg: "#efefef" },
            { bg: "#0f0f0fa2", fg: "#efefef" },
            { bg: "#000000a2", fg: "#efefef" },
        ]
    },
    {
        swatches: [
            { bg: "#6096B4B2", fg: "#404040" },
            { bg: "#93BFCFB2", fg: "#404040" },
            { bg: "#BDCDD6B2", fg: "#404040" },
            { bg: "#EEE9DAB2", fg: "#404040" },

            { bg: "#992222", fg: "#efefef" },
        ]
    }
    
    

    
]

const ifNull = (val, fallback) => (val === null || val === undefined) ? fallback : val;

export const EtlNode = props => {

    const flowContext = useContext(EtlFlowContext);

    const updateNodeInternals = useUpdateNodeInternals();
    //const [rotation, setRotation] = useState(props.rotation || 0);

    const rotation = props.data?.rotation || 0;
    const setRotation = (newValue) => {
        const newData = {
            ...props.data,
            rotation: newValue
        }
        flowContext.setNodeData(props.id, newData);
    }

    const inputRotation = props.data?.inputRotation || 0;
    const setInputRotation = (newValue) => {
        const newData = {
            ...props.data,
            inputRotation: newValue
        }
        flowContext.setNodeData(props.id, newData);
    }

    const outputRotation = props.data?.outputRotation || 0;
    const setOutputRotation = (newValue) => {
        const newData = {
            ...props.data,
            outputRotation: newValue
        }
        flowContext.setNodeData(props.id, newData);
    }

    const bgColor = props.data?.bgColor || "#ffffffa2";
    const fgColor = props.data?.fgColor || "#404040";
    const setColors = (newValue) => {
        const newData = {
            ...props.data,
            bgColor: newValue.bg,
            fgColor: newValue.fg
        }
        flowContext.setNodeData(props.id, newData);
    }

    const setNodeShape = (newValue) => {
        const newData = {
            ...props.data,
            width: newValue.width,
            height: newValue.height,
            borderRadius: newValue.borderRadius
        }
        flowContext.setNodeData(props.id, newData);
    }

    //console.log("props.data", props.data);
    const width = props.data?.width || 225; // 225
    const height = props.data?.height || 65; // 65;
    const borderRadius = props.data?.borderRadius || 0;

    const origin_x = width / 2;
    const origin_y = height / 2;

    // const data = props.data;
    const nbInputs = ifNull(props.nbInputs, 1);
    const nbOutputs = ifNull(props.nbOutputs, 1);

    useEffect(() => {
        updateNodeInternals(props.id);
    }, [rotation, inputRotation, outputRotation, bgColor, fgColor, width, height, borderRadius]);
    
    const pin_spacing = 12;
    
    const pinWidth = 10; // width of base of triangle (might be rotated)
    const pinHeight = 8; // height of trangle (might be rotated)

    const getDot = (degrees) => {
        const pw = 10;
        const ph = 10;
        
        const xAdjust = (degrees == 180 || degrees == 270) ? -pinHeight : 0;
        const yAdjust = (degrees == 90 || degrees == 180) ? -pinWidth : 0;
        

        return <svg 
            
            height={pw}
            width={ph} 
        style={{
            pointerEvents: "none",
            //border: "1px dashed green",
            position: "absolute",
            left: "0",
            top: "0",
            transformOrigin: `0 0`,
            transform: `rotate(${degrees}deg) translate(${xAdjust}px,${yAdjust}px)`
        }}>
            <ellipse 
                cx={pw/2.0}
                cy={ph/2.0}
                rx={pw/2.0 - 1}
                ry={ph/2.0 - 1}
                style={{
                    stroke: "rgba(0,0,0,0.7)",
                    strokeThickness: 2,
                    fill: "rgba(0,0,0,0.5)"
                }}
            />
        </svg>
    }

    const getTriangle = (degrees, form = "out") => {

        const pw = pinWidth;// - 2.0;
        const ph = pinHeight;// - 1.0;
        
        const xAdjust = (degrees == 180 || degrees == 270) ? -pinHeight : 0;
        const yAdjust = (degrees == 90 || degrees == 180) ? -pinWidth : 0;
        
        const pointsStr = form === "out" ? `0,0 ${ph},${pw/2.0-1}, ${ph},${pw/2.0+1}, 0,${pw}`
            : form === "in" ? `${ph},0 0,${pw/2.0}, 0,${pw/2.0}, ${ph},${pw}`
            : form === "inb" ? `${ph},0 4,0 4,${pw}, ${ph},${pw}`
            : null;

        return <svg 
            
            height={pw}
            width={ph} 
        style={{
            pointerEvents: "none",
            //border: "1px dashed green",
            position: "absolute",
            left: "0",
            top: "0",
            transformOrigin: `0 0`,
            transform: `rotate(${degrees}deg) translate(${xAdjust}px,${yAdjust}px)`
        }}>
            <polygon 
                points={pointsStr}
                style={{
                    fill: "rgba(0,0,0,0.7)"
                }}
            />
        </svg>
    }
    
        
    let handles = [];

    // input handles
    {
        const pinRotation = (rotation + inputRotation) % 4;
        const pins_ystart = origin_y - (nbInputs - 1) * pin_spacing / 2;
        const pins_xstart = origin_x - (nbInputs - 1) * pin_spacing / 2;
    
        for (let i = 0; i < nbInputs; i++) {
            const dist = 2;
            let handle = {
                id: "input" + (i + 1),
                input: true,
                type: "target",
                style: {
                    //background: "#555",
                    //padding: 4,
                    zIndex: 2,
                    background: "transparent",
                    display: "relative",
                    border: "0",
                    borderRadius: "0",
                },
                pinLabel: `${i + 1}`,
                pinStyle: {}
            }
            if (pinRotation === 0) {
                handle.position = "left";
                handle.style.top = pins_ystart + i * pin_spacing;
                handle.style.left = 0 + dist; //pinHeight + 2 + pinHeight;
                handle.style.width = pinHeight;
                handle.style.height = pinWidth;
                handle.pinShape = getTriangle(180, "inb");
            }
            else if (pinRotation === 1) {
                handle.position = "top";
                handle.style.left = pins_xstart + i * pin_spacing;
                handle.style.top = 0 + dist;// - pinHeight;
                handle.style.width = pinWidth;
                handle.style.height = pinHeight;
                handle.pinShape = getTriangle(270, "inb");
            }
            else if (pinRotation === 2) {
                handle.position = "right";
                handle.style.top = pins_ystart + i * pin_spacing;
                handle.style.right = 0 + dist;// - pinHeight;
                handle.style.width = pinHeight;
                handle.style.height = pinWidth;
                handle.pinShape = getTriangle(0, "inb");
            }
            else {
                handle.position = "bottom";
                handle.style.left = pins_xstart + i * pin_spacing;
                handle.style.bottom = 0 + dist;// - pinHeight;
                handle.style.width = pinWidth;
                handle.style.height = pinHeight;
                handle.pinShape = getTriangle(90, "inb");
            }
            handles.push(handle);
        }
    }

    

    // output handles
    {
        const pinRotation = (rotation + outputRotation) % 4;
        const pins_ystart = origin_y - (nbOutputs - 1) * pin_spacing / 2;
        const pins_xstart = origin_x - (nbOutputs - 1) * pin_spacing / 2;
        for (let i = 0; i < nbOutputs; i++) {
            let handle = {
                id: "output" + (i + 1),
                type: "source",
                style: {
                    //border: "1px dashed red",
                    background: "transparent",
                    display: "relative",
                    border: "0",
                    zIndex: 2,
                    //overflow: "hidden",
                    //background: "#555",
                    //background: "red",
                    //transform: "rotate(45deg)",
                    borderRadius: "0",
                    // "::after": {
                    //     content: "hi"
                    // }
                },
                pinLabel: `${i + 1}`,
                pinStyle: {
                    //transform: "rotate(45deg)"
                    //border: "3px solid blue"
                }
            };
            if (pinRotation === 0) {
                handle.position = "right";
                handle.style.top = pins_ystart + i * pin_spacing;
                handle.style.right = 0 - pinHeight + 2;
                handle.style.width = pinHeight;
                handle.style.height = pinWidth;
                handle.pinShape = getTriangle(0);
            }
            else if (pinRotation === 1) {
                handle.position = "bottom";
                handle.style.left = pins_xstart + i * pin_spacing;
                handle.style.bottom = 0 - pinHeight + 2;
                handle.style.width = pinWidth;
                handle.style.height = pinHeight;
                handle.pinShape = getTriangle(90);
            }
            else if (pinRotation === 2) {
                handle.position = "left";
                handle.style.top = pins_ystart + i * pin_spacing;
                handle.style.left = 0 - pinHeight + 2;
                handle.style.width = pinHeight;
                handle.style.height = pinWidth;
                handle.pinShape = getTriangle(180);
            }
            else {
                handle.position = "top";
                handle.style.left = pins_xstart + i * pin_spacing;
                handle.style.top = 0 - pinHeight + 2;
                handle.style.width = pinWidth;
                handle.style.height = pinHeight;
                handle.pinShape = getTriangle(270);
            }
            handles.push(handle);
        }
    }

    
  
    return <>
        
        {handles.map((h, idx) => 
            <Handle key={idx} {...h} isConnectable={props.isConnectable} >{h.pinShape}</Handle>
        )}
        
        <div className={`etl-node ${props.selected ? "sel" : ""}`} 
            style={{
                width,
                height,
            }}
        >

            <div className="etl-content" style={{
                backgroundColor: bgColor,
                color: fgColor,
                borderRadius
            }}>

                {props.icon ?
                    <div className="icon">
                        {props.icon}
                    </div>
                    : null
                }
                
                <div className="label">
                    {props.children || "ETL Node"}
                </div>
               
                <span className="actions">
                    {/* <span className="btn icon small" onClick={() => {
                        setRotation(rotation === 3 ? 0 : rotation + 1);
                    }}>
                        <i className="fal fa-sync"/>
                    </span> */}
                    <Popup
                        closeOnDocumentClick={true}
                        closeOnEscape={true} 
                        position="right top"
                        keepTooltipInside={true}
                        trigger={
                            <span className="btn icon small">
                                <i className="fal fa-ellipsis-h"/>
                            </span>
                        }>
                        {close => {
                            //flowContext?.setNodeMenuCloser?.({ close });
                            console.log("flowContext", flowContext);
                            return <div className="popup-menu">
                                <div className="menu-group">
                                    <div className="menu-item" onClick={() => {
                                        // do something
                                        props.edit?.();
                                    }}>
                                        Edit...
                                    </div>
                                </div>
                                <div className="menu-group">
                                    <div className="menu-item" onClick={() => {
                                        setRotation(rotation === 3 ? 0 : rotation + 1);
                                        close();
                                    }}>
                                        Rotate all pins
                                    </div>
                                    <div className="menu-item" onClick={() => {
                                        setInputRotation(inputRotation === 3 ? 0 : inputRotation + 1);
                                        close();
                                    }}>
                                        Rotate input pin(s)
                                    </div>
                                    <div className="menu-item" onClick={() => {
                                        setOutputRotation(outputRotation === 3 ? 0 : outputRotation + 1);
                                        close();
                                    }}>
                                        Rotate output pin
                                    </div>
                                </div>
                                <div className="menu-group">
                                    <div className="menu-item no-hover">
                                        <span className="etl-node-swatches">
                                            {SWATCH_COLORS.map(group =>
                                                <span className="swatch-group">
                                                    {group.swatches.map(sw =>
                                                        <span className="swatch" 
                                                            style={{
                                                                backgroundColor: sw.bg,
                                                                color: sw.fg
                                                            }}
                                                            onClick={() => {
                                                                setColors(sw);
                                                            }}
                                                        >
                                                            a
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="menu-group">
                                    {NODE_SHAPES.map(ns =>
                                        <div className="menu-item"
                                            onClick={() => setNodeShape(ns)}
                                        >
                                            {ns.label}
                                        </div>
                                    )}
                                    
                                </div>
                            </div>
                        }}
                    </Popup>

                </span>
                

                


            </div>

        </div>
      
    </>

};
