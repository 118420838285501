import { useEffect, useState } from "react";
import { ControlledCodeEditor, ControlledCodeEditorFillContainer } from "../code-editor/CodeEditor";
import "./EditAtt.scss";

const VIEWS = [
    { key: "form", label: "Form" },
    { key: "json", label: "JSON" },
]

export const EditAtt = (props) => {

    const [selectedView, setSelectedView] = useState(VIEWS[0].key);
    const [code, setCode] = useState(null)

    useEffect(() => {
        if (!props.att) return;
        const j = JSON.stringify(props.att, null, 4);
        setCode(j);
    }, props.att);

    const att = props.att;
    if( !att ) return <div>att missing</div>;

    let levels = att.levels || att.options;

    if( att.type === "checkbox" ){
        levels = [
            { value: 1, label: "(Checked)" },
            { value: 0, label: "(Not checked)" },
            
        ]
    }

    let type = att.type;
    if (att.type === "select") {
        type = "discrete";
    }
    else if (att.type === "checkbox") {
        type = "discrete";
    }

    

    const types = ["discrete", "contin"];
    const visTypes = {
        discrete: ["dropdown", "radios", "checkbox", "hidden"],
        contin: ["textbox", "hidden"]
    }
    const coderTypes = {
        discrete: ["dummy"],
        contin: ["linterp", "lin-quad", "thermometer"]
    }

    return <div className="edit-att-ui">
        <div className="att-name">
            [<span className='att-name'>{att.name}</span>]

            <div style={{ float: "right" }}>
                {VIEWS.map(view =>
                    <span
                        className={`btn toggle ${view.key === selectedView ? "sel" : ""}`}
                        onClick={() => setSelectedView(view.key)}
                    >
                        {view.label}
                    </span>
                )}
            </div>
        </div>
        {selectedView === "form" ?
            <>
                <div className="att-type">
                    Type:
                    <select defaultValue={type}>
                        {types.map(t =>
                            <option key={t} value={t}>
                                {t}
                            </option>
                        )}
                    </select>
                    &nbsp;&nbsp;
                    UI Visualizer:
                    <select defaultValue={type}>
                        {visTypes[type]?.map(t =>
                            <option key={t} value={t}>
                                {t}
                            </option>
                        )}
                    </select>
                    &nbsp;&nbsp;
                    xtran coder:
                    <select defaultValue={type}>
                        {coderTypes[type]?.map(t =>
                            <option key={t} value={t}>
                                {t}
                            </option>
                        )}
                    </select>
                </div>
                <div className="att-label">
                    <span className="att-type">
                        label:
                    </span>
                    <span className="att-label">{att.label}</span>
                </div>
                {levels ?
                    <div className="levels">
                        <div className="att-level header">
                            <div className="level-value">
                                value
                            </div>
                            <div className="level-label">
                                label
                            </div>
                            <div className="level-coeff">
                                coeff
                            </div>
                        </div>
                        {levels.map((level, i) => <EditLevel att={att} level={level} levelIndex={i} />)}
                    </div>
                    : null
                }
                <div>
                    <button style={{
                        border: "1px solid #cfcfcf",
                        backgroundColor: "#ffffff",
                        padding: "7px 10px"
                    }}>
                        <i className="fal fa-plus" />&nbsp; Add level
                    </button>
                </div>
            </> :
            <>
                <div style={{ height: 400 }}>
                    <ControlledCodeEditorFillContainer
                        language="json"
                        value={code}
                        onChange={(newCode) => setCode(newCode)}
                    />
                </div>
            </>
        }
    </div>
}

export const EditLevel = (props) => {

    const { att, level, levelIndex } = props;
    const coeff = `${att.name}${level.value}`

    return <div className="att-level">
        {/* <div className="grip">
            <i className="fad fa-grip-lines"/>
        </div> */}
        <div className="level-vis">
            <span className="btn icon light">
                <i className="fal fa-plus-circle"/>
            </span>
        </div>
        <div className="level-value">
            <input type="text" defaultValue={level.value} />
        </div>
        <div className="level-label">
            <input type="text" defaultValue={level.label} />
        </div>
        <div className="level-coeff" style={{ position: "relative" }}>
            <i className="fal fa-check-circle" style={{
                position: "absolute",
                left: 5,
                top: 7,
                color: "#229922"
            }} />
            <input type="text" defaultValue={coeff} style={{
                width: "100%",
                paddingLeft: 30,
                paddingRight: 30
            }} />
            <span className="btn icon light" style={{
                position: "absolute",
                right: 0
            }}>
                <i className="fal fa-ellipsis-h"/>
            </span>
            
        </div>
        <div className="actions">
            <span className="btn icon light">
                <i className="fal fa-times"/>
            </span>
        </div>
    </div>

}