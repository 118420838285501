import { DiffEditor } from "@monaco-editor/react";
import json5 from "json5";
import { createContext, useContext, useEffect, useState } from "react";
import "./EditAggItems.scss";

export const EditAggItemsContext = createContext();

const EDIT_MODES = [
    { id: "edit", label: "edit" },
    { id: "diff-edit", label: "diff-edit" },
]

const PROPS = [
    { id: "id" },
    { id: "label" },
    { id: "syntax" },
    { id: "fmt" },
    { id: "*" },
]

export const EditAggItems = (props) => {

    const de_options = {
        //renderSideBySide: false
    };

    const [editMode, setEditMode] = useState("diff-edit");
    

    const context = useContext(EditAggItemsContext);
    const options = context?.options;
    //const { aggSyntax, setAggSyntax } = context;

    const [compareProp, setCompareProp] = useState(options?.compareProp);
    
    //const saveSyntax = context?.saveSyntax;

    // if props specifies that this is an array of strings, transform the aggSyntax
    

    const aggSyntax = options?.isStringArray ? json5.parse(context?.syntax).join("\n")
        : context?.syntax;

    //const aggArr = json5.parse(context?.syntax);
    const aggArr = context?.items?.map(i => i?.[compareProp] || ".");

    const [oldSyntax, setOldSyntax] = useState(aggSyntax);
    const [newSyntax, setNewSyntax] = useState(aggSyntax);

    useEffect(() => {
        const arr = context?.items?.map(i => i?.[compareProp]);
        const s = arr?.join("\n");
        setOldSyntax(s);
    }, [context?.items, compareProp])
    

    // useEffect(() => {
    //     console.log("setting diff editor new syntax");
    //     setNewSyntax(aggSyntax);
    // }, [aggSyntax])

    const [editor, setEditor] = useState(null);
    const saveSyntax = () => {
        const med = editor.getModifiedEditor();
        const str = med.getValue();
        const ss = options?.isStringArray ? JSON.stringify(str?.split('\n'), null, 4) : str;
        console.log("saving ss", ss);
        context?.saveSyntax?.(ss);
    }

    const handleEditorMount = (editor) => {
        console.log("handleEditorMount", editor)
        setEditor(editor);
        // const modifiedEditor = editor.getModifiedEditor();
        // modifiedEditor.onDidChangeModelContent((_) => {
        //     console.log(modifiedEditor.getValue());
        // });
      }

    const openFind = () => {
        editor?.focus();
        const m_editor = editor?.getModifiedEditor();
        const action = m_editor?.getAction?.("actions.find");
        void action?.run();
    }

    const openFindAndReplace = () => {
        editor?.focus();
        const m_editor = editor?.getModifiedEditor();
        const action = m_editor?.getAction?.("editor.action.startFindReplaceAction");
        void action?.run();
    }

    return <div className="edit-agg-items">
        <div className="full-dock fill-parent-absolute">
            <div className="top" style={{ padding: 10 }}>

                <div>

                    {/* edit modes */}
                    <span className="btn-group">
                        {EDIT_MODES.map(m =>
                            <span 
                                key={m.id} 
                                onClick={ev => setEditMode(m.id)}
                                className={`btn toggle-small ${m.id === editMode ? "sel" : ""}`}
                            >
                                {m.label}
                            </span>
                        )}
                    </span>

                    

                </div>

                <div style={{ float: "right" }}>
                <button onClick={() => openFind()}>find</button>
                    <button onClick={() => openFindAndReplace()}>replace</button>
                </div>

                {/* diff editor options */}
                {editMode === "diff-edit" && <div style={{
                    //borderTop: "1px solid #cfcfcf",
                    marginTop: 10,
                    //paddingTop: 10
                }}>
                    Compare to:&nbsp;
                    {PROPS.map(p => 
                        <span 
                            key={p.id} 
                            onClick={ev => setCompareProp(p.id)}
                            className={`btn toggle-small ${p.id === compareProp ? "sel" : ""}`}
                        >
                            {p.label || p.id}
                        </span>
                    )}
                </div>}

                
            </div>
            <div className="center" style={{ position: "relative", border: "1px solid #cfcfcf" }}>
                <DiffEditor
                    height="100%"
                    defaultLanguage="javascript"
                    original={oldSyntax}
                    modified={newSyntax}
                    options={de_options}
                    onMount={handleEditorMount}
                />
                {/* <ControlledCodeEditorFillContainer
                    value={aggSyntax}
                    onChange={str => setNewSyntax(str)}
                    language="text"
                    editorProps={{
                        options: {
                        lineNumbers: x => aggArr?.[x-1],
                        lineNumbersMinChars: 1 + aggArr?.reduce((max, el) => Math.max(max, el?.length), 1),
                        glyphMargin: false
                        }
                    }}
                    onMount={handleEditorMount todo}
                /> */}
            </div>
            <div className="bottom" style={{ textAlign: "right" }}>
                
                <button onClick={() => {
                    // todo: save
                    saveSyntax?.(newSyntax);
                    props.close();
                }}>
                    save
                </button>
                <button onClick={() => {
                    props.close();
                }}>
                    cancel
                </button>
            </div>
        </div>
    </div>
}