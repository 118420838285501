import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";
import Popup from 'reactjs-popup';
import { useAuth } from "../../hooks/useAuth";
import { useDataSource, useDataSources, useDataSourcesApi } from "../../hooks/useDataSource";
import { useClusterApi } from "../../hooks/useClusterApi";
import { useProjectId } from "../../hooks/useProjectId";
import { TextFrame, VerifyFrame } from "../modal-dialog/VerifyFrame";
import { LeftMenu } from "../nav/LeftMenu";
import { Main } from "../nav/Main";
import { EditDataSource } from "./EditDataSource";
import { NewDataSource } from "./NewDataSource";
import { useEffect, useState } from "react";
import { format } from "d3-format";
import { UsageBar } from "./UsageBar";
import { FlowTools } from "./FlowTools";
import { getDataSourceIconClass } from "../../utils/IconHelper";


const toGb = (b, fmt = ".1f") => {
    const kb = b / 1024;
    const mb = kb / 1024;
    const gb = mb / 1024;
    return format(fmt)(gb);
}

export const DataSources = (props) => {

    const { project_id, subscription_id } = useProjectId();
    const dispatch = useDispatch();

    const routerParams = useParams();
    const selectedItemId = routerParams.id;
    const history = useHistory();

    const api = useDataSourcesApi();
    const auth = useAuth();
    const cluster_api = useClusterApi();
    
    const [items, itemsLoading, itemsError] = useDataSources();
    const [selectedItem] = useDataSource(selectedItemId);
    const [clusterStats, setClusterStats] = useState(null);

    const closeLeftMenu = () => {
        //dispatch({ type: "LEFT_MENU_OPEN_SET", value: false });
    }

    useEffect(() => {

        // get cluster stats
        // (async () => {
        //     console.log("getting cluster stats...");
        //     const res = await cluster_api.getStats();
        //     const s = await res.json();
        //     console.log("cluster stats", s);
        //     setClusterStats(s);
        // })();

    }, []);

    const showFlowTools = selectedItem?.type === "flow";

    return <>
        
        <LeftMenu>
            <div className='title'>
                Data Sources
            </div>
            <div className='top-section' style={{
                
            }}>
                {clusterStats ?
                    <>
                    <hr style={{ marginTop: 0 }}/>
                    <div style={{ padding: "0 20px" }}>
                        <div style={{ textAlign: "center"}}>
                            Mounted Disk Usage (per Subscription)
                        </div>
                        <div style={{ textAlign: "center", marginTop: 5 }}>
                            {toGb(clusterStats.fsUsedSize)} GB used of {toGb(clusterStats.fsTotalSize)} GB ({format(".0%")(clusterStats.fsUsedSize / clusterStats.fsTotalSize)})
                        </div>
                        <div>
                            <UsageBar
                                style={{ marginTop: 10 }}
                                usage={clusterStats.fsUsedSize / clusterStats.fsTotalSize}
                            />
                        </div>
                        </div>
                        <hr/>
                    </>
                    : null
                }
                
            </div>
            <div className={`scroller ${showFlowTools ? "vsplit": ""}`}>
                <div className="top-section">
                    <div className='summary'>
                        {/* <p>
                            Select a data source or create new.
                        </p> */}
                    </div>
                    {
                        itemsError ? <div className='list-content error'>Error loading data sources</div> :
                        itemsLoading ? <div className='list-content'>Loading...</div> :
                        !items ? null :
                        [...items]
                            .sort((a, b) => a.label?.localeCompare(b.label))
                            .map(item => <div key={item.id} className={`menu-item ${item.id === selectedItemId ? 'sel' : ''}`}>
                                <span className='grip'>
                                    <i className={`fad ${getDataSourceIconClass(item)}`} />
                                </span>
                                <Link
                                    className='label'
                                    to={`/subscription/${subscription_id}/project/${project_id}/data-source/${item.id}`}
                                    onClick={() => closeLeftMenu()}
                                    title={item.label}
                                >
                                    {item.label}
                                </Link>
                                <Popup
                                    nested
                                    contentStyle={{ padding: '0px' }}
                                    trigger={<span className='menu' ><i className='far fa-ellipsis-h' /></span>}
                                    position="right top">
                                    {closeMenu => <ul className='popup-menu'>
                                            <Popup modal
                                                contentStyle={{ width: 500 }}
                                                trigger={
                                                <li>Rename</li>
                                            }>
                                                {closeModal => <TextFrame
                                                    title={"Rename data source"}
                                                    label={item.label}
                                                    initialTextValue={item.label}
                                                    close={closeModal} confirmed={async (text) => {
                                                        const patchBody = {
                                                            label: text
                                                        };
                                                        await api.patchItem(item.id, patchBody);
                                                        closeMenu();
                                                    }}
                                                />}
                                            </Popup>
                                        
                                            <Popup modal trigger={
                                                <li>Delete data source</li>
                                            }>
                                                {closeModal => <VerifyFrame
                                                    title={"Delete data source"}
                                                    label={item.label}
                                                close={closeModal}
                                                confirmed={async () => {
                                                    await api.deleteItem(item.id);
                                                    // navigate away?
                                                    closeMenu();
                                                }}
                                            />}
                                            </Popup>
                                    </ul>}
                                </Popup>
                            </div>)
                    }
                    <div className='menu-item-stub center'>
                        <Popup
                            contentStyle={{
                                width: 500
                            }}
                            modal
                            closeOnDocumentClick={false}
                            trigger={
                                <div className="btn action" >
                                    <i className='glyph fal fa-plus' /> New data source
                                </div>
                            }>
                            {close => <NewDataSource
                                close={close}
                                onNew={(itemInfo) => {
                                    // reload the selected dataset

                                    // workaround: reload all
                                    

                                    history.push(`/subscription/${subscription_id}/project/${project_id}/data-source/${itemInfo.newId}`);
                            }} />}
                        </Popup>
                    </div>
                </div>
                {showFlowTools ? <FlowTools className="bottom-section"/> : null}
            </div>
        </LeftMenu>

        <Main ignoreRightMenu>
            {selectedItem ? <EditDataSource id={selectedItemId} /> : null}
        </Main>

    </>;


    
}

