import Radium from "radium";
import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { useLazyLib } from "../../hooks/useLazyLib";
import { useProject } from "../../hooks/useProject";
import { runRender } from "../../utils/ContentProcessor";
import { defaultLib } from "../../utils/Lib";
import "./ArbitraryContent.scss";
import { computeContextStates } from "./ComputeContext";


const api = {};

export let CustomRender = (props) => {

    const item = props.item;

    // get data from hook
    const cHook = item?.jdata?.contextHooks?.[0];
    //const ccState = useRecoilValue(computeContextState(cHook?.id));

    const ccStateR = useRecoilValue(computeContextStates(item?.jdata?.contextHooks));//(item?.jdata?.contextHooks));
    //const ccStateR = ccState2;// item?.jdata?.contextHooks?.length > 1 ? ccState2 : ccState2[item?.jdata?.contextHooks[0].name];
    
    const payload = ccStateR || {};
    
    const [project, projectLoading, projectError] = useProject();
    const [rendered, setRendered] = useState(null);

    const resources = props.resources;
    const render = item?.jdata?.render;

    const lib2 = useLazyLib();
    const lib = useMemo(() => ({
        ...defaultLib,
        ...lib2
    }), [lib2]);
    const context = {
        project
    };

    useEffect(() => {
        if( !lib2 ) return;
        if( !render ) return;
        if( !project ) return;
        (async () => {

            // render
            const r = runRender(render, context, resources, api, lib, payload);
            //setRendered(<div>hi</div>)
            setRendered(r || null);

        })();

    }, [render, ccStateR, lib2, resources]); // will this render excessively now that I changed from ccState to ccState2?

    if( !project ) return null;

    const style = { ...props.style, ...item?.style }
    return <div className={` ${props.displayClass}`} style={style} {...props.atts}>
        {rendered}
    </div>
    
};


CustomRender = Radium(CustomRender);