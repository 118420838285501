import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as globals from "../globals";

export const useEnforceProjectIdFromRoute = () => {

    const routerParams = useParams();
    
    const route_sid = globals.player_sub || routerParams.subscription_id;
    const route_pid = routerParams.project_id;
    const route_fid = `${route_sid}.${route_pid}`;
    const redux_fid = useSelector(state => state.projects.full_project_id);

    const dispatch = useDispatch();

    if (redux_fid !== route_fid) {
        dispatch({ type: "CLEAR_PROJECT", new_fid: route_fid });
    }

    return [route_pid, route_sid];

}


export const useEnforceFullProjectId = (subscription_id, project_id) => {

    const force_fid = `${subscription_id}.${project_id}`;
    const redux_fid = useSelector(state => state.projects.full_project_id);

    const dispatch = useDispatch();

    if (force_fid !== redux_fid) {
        dispatch({ type: "CLEAR_PROJECT", new_fid: force_fid });
    }

    return null;

}

