import { useState } from "react";
import Popup from "reactjs-popup";
import "./ImageSwatch.scss";
import { ImageEditor } from "./ImageEditor";
import * as globals from "../../globals";



export const ImageSwatch = (props) => {

    const item = props.item;
    const subscription_id = props.subscription_id;
    const project_id = props.project_id;

    const filename = `client1-images/${subscription_id}/${project_id}/${item.filename}`;// || `client1-images/${project_id}/${item._id}.${ext}`;

    const [editorOpen, setEditorOpen] = useState(false);

    const deleteMe = (ev) => {

        ev.preventDefault();
        ev.stopPropagation();
        
        props.onDelete?.();
    }

    const imgStyle = {
        ...props.imgStyle
    };

    const swatch = <div
        className={`image-swatch ${props.selected ? 'sel' : ''} ${props.hideHover ? ' hide-hover' : ''} ${props.staticHeight ? 'static-height' : ''}`}
        onClick={props.onClick}
        {...props.style}
    >
        <span className='fill' style={{
            //backgroundColor: bg,
            //color: fg
        }}
            onClick={() => {
                setEditorOpen(true);
            }}
        >
            
            {!props.hideDelete ?
                <div className='btn-delete' onClick={deleteMe}>
                    <i className='fal fa-times' />
                </div>
                : null}
            
            <div className='img-container'>
                <img src={`${globals.blobUrl}${filename}`} className={`img ${props.imgClass || ""}`} style={imgStyle}/>
            </div>

            <div className={`swatch-name ${props.imgClass || ""}`}>
                {item.label}
            </div>
            


        </span>

        <Popup modal nested open={editorOpen} onClose={() => setEditorOpen(false)}>
            {closeModal => <ImageEditor
                subscription_id={subscription_id}
                project_id={project_id}
                image_id={item?.id}
                close={closeModal}
                onSave={async (newItem) => {
                    await props.onSave?.(newItem);
                    setEditorOpen(false);
            }} />}
        </Popup>

    </div>;

    return swatch;


}
