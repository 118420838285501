import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { useImage } from "../../hooks/useImage";
import { useProjectId } from "../../hooks/useProjectId";
import { parseBindingTargets, parseValueObject, parseValueType } from "../../utils/ContentProcessor";
import { newId } from "../../utils/uuid";
import { ImagePicker } from "../images/ImagePicker";
//import "./ContentValueInput.scss";


export const ImagePickerInput = (props) => {

    const [value, setValue] = useState(props.value);
    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const [image, imageLoading, imageError] = useImage(value);

    const label = imageLoading ? "loading..." :
        imageError ? imageError :
            (image?.label || "[none]")
        ;
    
    const onChange = (newValue) => {
        props.onChange?.(newValue);
    }


    return <div className="h-dock" style={{ border: "1px solid #cfcfcf", backgroundColor: "#ffffff", ...props.style }} >
        <span className="center">
            <Popup
                modal
                trigger={
                    <div className="flex-vcenter" style={{ 
                        //backgroundColor: "red", 
                        maxWidth: 220,
                        overflow: "hidden",
                        textOverflow: "clip",
                        whiteSpace: "nowrap",
                        width: "100%", 
                        height: "28px",
                        padding: "0 10px",
                        cursor: "pointer"
                    }}>{label}</div>
                }
            >
                {close => <ImagePicker
                    close={close}
                    onSelect={(img) => {
                        setValue(img?.id);
                        onChange(img?.id);
                        close();
                    }}
                />}
            </Popup>
        </span>
        {value ?
            <span className="right">

                {/* delete button */}
                <span className="btn icon" onClick={() => {
                    setValue(null);
                    onChange(null);
                }}>
                    <i className="fal fa-times" />
                </span>
                
            </span>
            : null
        }
    </div>



}