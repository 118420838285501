import { useContext, useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import * as globals from "../globals";
import * as restMethods from "../api/restMethods";
import { AuthContext } from "../context/Contexts";

const getTeams = async (auth, all) => {
    const url = `${globals.apiRoot}/teams${all ? "?all=1" : ""}`;
    var response = await restMethods.getJson(auth, url);
    return await response.json();
}


export const useTeams = (options) => {

    // TODO: move this into REDUX to eliminate fetches each time?
    // But if so, the websocket might need to cooperate to notify of changes

    const c = useContext(AuthContext);
    const auth = useAuth();
    const [teams, setTeams] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [refreshCounter, setRefreshCounter] = useState(0);
    const all = options?.all || false;

    useEffect(() => {

        if( all && !c?.authUser?.isSuperAdmin ) return;

        const f = async () => {
            setLoading(true);
            try {
                const subs = await getTeams(auth, all);
                setTeams(subs);
            }
            catch (err) {
                console.error(err);
                setError(err.message);
                setTeams([]);
            }
            setLoading(false);
        }
        f();

    }, [refreshCounter, all]);

    const refresh = () => setRefreshCounter(refreshCounter+1);

    return [teams, loading, error, refresh]

}

