import { useContext } from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { controllers } from "../api/controllers";
import { constructs } from "../api/contructs";
import { createSyncedApiHook } from "../api/createSyncedApiHook";
import { AuthContext } from "../context/Contexts";
import { useAuth } from "./useAuth";
import { useProjectId } from "./useProjectId";
import * as globals from "../globals";
import * as restMethods from "../api/restMethods";

export const useSubscription = (id) => {

    const auth = useAuth();
    const dispatch = useDispatch();

    const { subscription, loading, error } = useSelector(state => ({
        subscription: state.subscriptions.dict?.[id],
        loading: state.subscriptions.entryLoading?.[id],
        error: state.subscriptions.entryError?.[id]
    }), shallowEqual);

    useEffect(() => {
        (async () => {
            if (id && !loading && !error) {
                try {
                    dispatch({ type: "SUBSCRIPTION_SET_LOADING", id });
                    
                    const url = `${globals.apiRoot}/subscription/${id}`;
                    var response = await restMethods.getJson(auth, url);
                    var value = await response.json();
                    
                    dispatch({ type: "SUBSCRIPTION_SET", id, value });

                }
                catch (err) {
                    dispatch({ type: "SUBSCRIPTION_SET", id, value: null, error: err.message });
                }
            }
        })();
    }, [id]);

    return [
        subscription,
        loading,
        error
    ];

}


