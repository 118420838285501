import "./ViewRecords.scss";
import { DataTable } from "../common/DataTable";
import ReactJson from "react-json-view";
import { useState } from "react";
import { tableToExcel, tableToString } from "../../utils/ExportUtils";
import { saveAs } from 'file-saver';

const VIEWS = [
    { id: "json", label: "JSON" },
    { id: "table", label: "Table" },
    { id: "stages", label: "Input stages" },
    { id: "meta", label: "Meta" },
]

const copyToClipboard = str => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText)
        return navigator.clipboard.writeText(str);
    return Promise.reject('The Clipboard API is not available.');
};

export const ViewRecords = (props) => {

    const [selView, setSelView] = useState(VIEWS[1].id);
    const result = props.result;
    const stages = props.stages;

    const [smartObj, setSmartObj] = useState(false);
    const [allowNest, setAllowNest] = useState(true);

    let rows = result?.data;
    let metaRow = result?.meta;

    const exportTable = async () => {
        const buffer = await tableToExcel(rows, null);
        const fileName = "export.xlsx";
        saveAs(
            new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
            fileName
        )
    };

    const copyTable = async () => {
        const str = await tableToString(rows, null);
        await copyToClipboard(str);
        alert("copied");

    }

    return <div className="view-records">
        <div className="tab-section no-margin header">
            {VIEWS.map(view =>
                <div key={view.id} 
                    className={`tab ${selView === view.id ? "sel" : ""}`}
                    onClick={() => setSelView(view.id)}
                >
                    {view.label}
                </div>
            )}
        </div>
        <div className="data-content">
            {selView === "json" ?
                <div className="dc-content">
                    <ReactJson 
                        name={"result_subset"}
                        iconStyle="circle" 
                        theme={"rjv-default"}
                        displayDataTypes={false}
                        collapsed={1} 
                        src={rows || undefined}
                        style={{ margin: 10 }}
                    />
                </div>
                : selView === "stages" ?
                <div className="dc-content">
                    <ReactJson 
                        name={"stages"}
                        iconStyle="circle" 
                        theme={"rjv-default"}
                        displayDataTypes={false}
                        collapsed={4} 
                        src={stages || undefined}
                        style={{ margin: 10 }}
                    />
                </div>
                : selView === "meta" ?
                <div className="dc-content">
                    <ReactJson 
                        name={"meta"}
                        iconStyle="circle" 
                        theme={"rjv-default"}
                        displayDataTypes={false}
                        src={metaRow || undefined}
                        style={{ margin: 10 }}
                    />
                </div>
                :
                <>
                    <div className="dc-header">
                        {!false && <span className={`btn icon ${smartObj ? "sel" : ""}`} 
                            title="Box objects"
                            onClick={() => {
                                setSmartObj(!smartObj)
                                setAllowNest(false);
                            }}
                            >
                                <i className="fal fa-box" />
                            </span>
                        }
                        <span className={`btn icon ${allowNest ? "sel" : ""}`}
                            title="Breakout nesting"
                            onClick={() => {
                                setAllowNest(!allowNest)
                                setSmartObj(false);
                            }}
                        >
                            <span className="fa-flip-horizontal" style={{ display: "inline-block" }}>
                                <i className={"fad fa-rotate-90 fa-list-tree"} />
                            </span>
                        </span>
                        <span style={{ float: "right", marginRight: 20 }}>
                            <span style={{ marginRight: 10 }}>
                                Export:
                            </span>
                            <span className={`btn icon`} onClick={() => exportTable()} title="Download as Excel">
                                <i className="fal fa-arrow-down-to-line" />
                            </span>
                            <span className={`btn icon`} onClick={() => copyTable()} title="Copy table into clipboard">
                                <i className="fal fa-copy" />
                            </span>
                        </span>
                    </div>
                    <div className="dc-content">
                        <DataTable data={rows} autoGenerateColumns allowNest={allowNest} smartObj={smartObj} />
                    </div>
                </>
                
            }
        </div>
    </div>;
}