import { useCollection, useCollections } from "./useCollection";
import { useConfig, useConfigs } from "./useConfig";
import { useDataSource, useDataSources } from "./useDataSource";
import { useFilterBook, useFilterBooks } from "./useFilterBook";
import { useModel, useModels } from "./useModel";
import { usePalette, usePalettes } from "./usePalette";
import { useSelection, useSelections } from "./useSelection";

export const getHooksForType = (type) => {
    if( type === "collection")
        return [useCollection, useCollections];
    else if( type === "palette")
        return [usePalette, usePalettes];
    else if( type === "selection")
        return [useSelection, useSelections];
    else if( type === "data-source" )
        return [useDataSource, useDataSources];
    else if( type === "model" )
        return [useModel, useModels];
    else if( type === "config" )
        return [useConfig, useConfigs];
    else if( type === "filter-book" )
        return [useFilterBook, useFilterBooks];
    else 
        return null;
}
