import { useContext } from "react";
import { AuthContext } from "../../context/Contexts";
import "./EditProfile.scss";

export const EditProfile = (props) => {

    const authContext = useContext(AuthContext);
    const user = authContext?.authUser;

    return <div className="edit-profile">
        <pre>
            {JSON.stringify(user, null, 2)}
        </pre>
    </div>
}