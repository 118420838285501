import { useContext, useEffect, useState } from "react";
import { PageContext } from "../../../context/Contexts";
import { useContentItem } from "../../../hooks/useContentItem";
import { useContentItemsApi } from "../../../hooks/useContentItems";
import { ControlledCodeEditorFillContainer } from "../../code-editor/CodeEditor";
import { DataBindButton } from "../../edit/DataBindButton";
import { InstancesInput } from "../../instances/InstancesInput";
import JSON5 from "json5";

const safeJson = (obj) => {
    if (!obj) return null;
    return JSON.stringify(obj, null, 4);
}

export const GenericContentPropertyEditor = (props) => {

    const api = useContentItemsApi();
    const page = useContext(PageContext);

    const id = props.content_item_id;
    const [item] = useContentItem(page?.id, id);

    const [itemStr, setItemStr] = useState(safeJson(item));
    useEffect(() => {
        setItemStr(safeJson(item));
    }, [item])

    if (!item) return null;

    const rowPad = 10;

    const updateItem = async (newItem) => {
        console.log('updateItem', id, newItem);
        await api.updateItem(id, newItem, { page_id: page.id });
    }

    return <div>

        <div className='h-dock' style={{ marginBottom: rowPad }}>
                    
            <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                Instances
            </span>
            <span className='center'>
                <InstancesInput
                    style={{ width: "100%" }}
                    value={item.instances}
                    onChange={async (newInstances) => {
                        const newItem = {
                            ...item,
                            instances: newInstances
                        };
                        await updateItem(newItem);
                    }}
                />
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton/>
            </span>
        </div>

        <div style={{ marginBottom: 10 }}>
            <span className="btn action"
                onClick={async () => {
                    const newItem = {
                        ...item,
                        ...JSON5.parse(itemStr)
                    }
                    //console.log('newItem', newItem);
                    await updateItem(newItem);
                }}
            >
                Save JSON
            </span>
        </div>
        <div style={{
            border: "1px solid #cfcfcf",
            position: "relative",
            height: 400
        }}>
            <ControlledCodeEditorFillContainer
                editorProps={{
                    options: {
                        lineNumbers: 'off',
                        glyphMargin: false,
                        folding: false,
                        minimap: {
                            enabled: false
                        }
                    }
                }}
                language="json"
                value={itemStr}
                onChange={(code) => {
                    setItemStr(code);
                }}
            />
        </div>


    </div>
}
