import { useState } from "react";
import { useFriends } from "../../hooks/useFriends";
import "./SelectUserWin.scss";

const getMatches = (searchText, items) => {
    let text = searchText.toLocaleLowerCase();
    let textArr = text.split(" ");
    return items.filter(item => {
        let itemText = item.name?.toLocaleLowerCase() + " " + item.email?.toLocaleLowerCase();
        for( let t of textArr ){
            if(!itemText.includes(t)) return false;
        }
        return true;
    })
}


export const SelectUserWin = (props) => {

    const [searchText, setSearchText] = useState(null);
    const [friends, friendsLoading] = useFriends();


    let excludeUserIds = props.excludeUserIds || [];

    let friends2 = friends?.filter?.(f => !excludeUserIds?.includes(f.id));

    const matches = friends && searchText ? getMatches(searchText, friends2) : null;

    const selectUser = (user) => {
        props.selectUser?.(user);
        props.close?.();
    }

    return <div className="select-user-win">

        <div className="search">
            <input type="text" placeholder="&#xF002; Find a user" 
                value={searchText || ""} 
                onChange={(ev) => setSearchText(ev.target.value)}
            />
        </div>

        {searchText && <>

            {friendsLoading ? 
                <div>
                    Loading...
                </div>
                : matches ? 
                <div className="user-list">
                    {matches.map(u => {
                        const hoverText = `${u.name} <${u.email}>`;
                        return <div key={u.id} className="user-item" title={hoverText} onClick={() => selectUser(u)}>
                            <i className="fas fa-user-circle" />
                            {u.name} 
                            <span className="email">
                                &lt;{u.email}&gt;
                            </span>
                        </div>
                    })}
                </div>
                :
                <div>
                    [no matches]
                </div>
            
            }
        </>
        }

    </div>

}