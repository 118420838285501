import { useState } from "react";
import { useParams } from "react-router-dom";
import { ModalFrame } from "../modal-dialog/ModalFrame";
import { usePalettes, usePalettesApi } from "../../hooks/usePalette";


export const NewPalette = (props) => {

    const routerParams = useParams();
    const project_id = routerParams.project_id;

    const [newName, setNewName] = useState("");
    const api = usePalettesApi();

    const createPalette = async (pal) => {
        return await api.newItem(pal);
    }

    return <ModalFrame close={props.close}
        title="Create new color palette"
        buttons={[
            {
                label: "Create palette",
                disabled: !newName,
                callback: async () => {
                    const pal = {
                        label: newName,
                        //type: newType
                    }
                    setNewName("");
                    const palInfo = await createPalette(pal);
                    props?.onNew(palInfo);
                    props.close();
                }
            },
        ]}
    >
        <div className='form1' style={{ maxWidth: 500 }}>
            <label className="label-line">
                Name:
            </label>
            <div className="value-line">
                <input type="text"
                    autoFocus className='full-width' 
                    placeholder="enter name of new color palette"
                    value={newName}
                    onChange={(ev) => {
                        setNewName(ev.target.value)
                    }}/>
            </div>
            
        </div>

    </ModalFrame>

    
}

