import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";
import Popup from 'reactjs-popup';
import { useProjectId } from "../../hooks/useProjectId";
import { getReportIconClass } from "../../utils/IconHelper";
import { TextFrame, VerifyFrame } from "../modal-dialog/VerifyFrame";
import { LeftMenu } from "../nav/LeftMenu";
import { Main } from "../nav/Main";


export const Reports = (props) => {

    const { project_id, subscription_id } = useProjectId();

    const [items, itemsLoading, itemsError] = [];

    const routerParams = useParams();
    const selectedItemId = routerParams.id;
    const history = useHistory();

    const closeLeftMenu = () => {}

    return <>
        
        <LeftMenu>
            <div className='title'>
                Reports
            </div>
            <div className='top-section' style={{
                
            }}>
            </div>
            <div className={`scroller`}>
                <div className="top-section">
                    <div className='summary'>
                        {/* <p>
                            Select a data source or create new.
                        </p> */}
                    </div>
                    {
                        itemsError ? <div className='list-content error'>Error loading reports</div> :
                        itemsLoading ? <div className='list-content'>Loading...</div> :
                        !items ? null :
                        [...items]
                            .sort((a, b) => a.label?.localeCompare(b.label))
                            .map(item => <div key={item.id} className={`menu-item ${item.id === selectedItemId ? 'sel' : ''}`}>
                                <span className='grip'>
                                    <i className={`fad ${getReportIconClass(item)}`} />
                                </span>
                                <Link
                                    className='label'
                                    to={`/subscription/${subscription_id}/project/${project_id}/report/${item.id}`}
                                    onClick={() => closeLeftMenu()}
                                    title={item.label}
                                >
                                    {item.label}
                                </Link>
                                <Popup
                                    nested
                                    contentStyle={{ padding: '0px' }}
                                    trigger={<span className='menu' ><i className='far fa-ellipsis-h' /></span>}
                                    position="right top">
                                    {closeMenu => <ul className='popup-menu'>
                                            <Popup modal trigger={
                                                <li>Rename</li>
                                            }>
                                                {closeModal => <TextFrame
                                                    title={"Rename report"}
                                                    label={item.label}
                                                    initialTextValue={item.label}
                                                    close={closeModal} confirmed={async (text) => {
                                                        const patchBody = {
                                                            label: text
                                                        };
                                                        //await api.patchItem(item.id, patchBody);
                                                        closeMenu();
                                                    }}
                                                />}
                                            </Popup>
                                        
                                            <Popup modal trigger={
                                                <li>Delete report</li>
                                            }>
                                                {closeModal => <VerifyFrame
                                                    title={"Delete data source"}
                                                    label={item.label}
                                                close={closeModal}
                                                confirmed={async () => {
                                                    //await api.deleteItem(item.id);
                                                    // navigate away?
                                                    closeMenu();
                                                }}
                                            />}
                                            </Popup>
                                    </ul>}
                                </Popup>
                            </div>)
                    }
                    <div className='menu-item-stub center'>
                        <Popup
                            modal
                            closeOnDocumentClick={false}
                            trigger={
                                <div className="btn action" >
                                    <i className='glyph fal fa-plus' /> New report
                                </div>
                            }>
                            {close => <div>todo</div>}
                        </Popup>
                    </div>
                </div>
                
            </div>
        </LeftMenu>

        <Main ignoreRightMenu>
            content goes here
        </Main>

    </>;


    
}

