
import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Popup from "reactjs-popup";
import { DataSourceContext, EditDataSourceContext, HasPipelineContext, PipelineEditorContext } from "../../context/Contexts";

// import { EditDataSourceContext, PipelineEditorContext } from "../../context/Contexts";
import { useAuth } from "../../hooks/useAuth";
import { useDataSourceRevision } from "../../hooks/useDataSourceRevision";
import { useProjectId } from "../../hooks/useProjectId";
import { newId } from "../../utils/uuid";


import "./EditPipelineStages.scss";
// import { EditPipelineStage } from "./EditPipelineStage";
//import { PIPELINE_STAGES } from "../aggregation/stages/_PipelineStages";
import { EditPipelineStage } from "./EditPipelineStage";
import { SelectStage } from "./SelectStage";
import { docLinks } from "../../doclinks";
import { PIPELINE_STAGES } from "../aggregation/stages/_PipelineStages";
import { SavePipelineWin, SavePipelineWinStyle } from "./SavePipelineWin";
import { LoadPipelineWin, LoadPipelineWinStyle } from "./LoadPipelineWin";
import json5 from "json5";
import { useSavedPipelinesApi } from "../../hooks/usePipeline";
import { toast } from "react-toastify";
// import { ViewRecords } from "./ViewRecords";
// import { HasDataSourceContext } from "../../../context/Contexts";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const EditPipelineStages = (props) => {

    const auth = useAuth();
    const { subscription_id, project_id } = useProjectId();

    const [filename, setFilename] = useState(null);
    const [loadPipelineWinIsOpen, setLoadPipelineWinIsOpen] = useState(false);
    const [savePipelineData, setSavePipelineData] = useState(false);

    const saveApi = useSavedPipelinesApi();

    const showRevisionId = props.showRevisionId;

    const ds = useContext(DataSourceContext);
    const edsContext = useContext(EditDataSourceContext); // has revision id

    //const [revision, revLoading, revError] = useDataSourceRevision(ds?.id, props.revisionId || ds?.dev_revision);
    const revisionId = edsContext ? edsContext.selectedRevisionId :
        props.revisionId || ds?.dev_revision;

    const [revision, revLoading, revError] = useDataSourceRevision(ds?.id, revisionId);

    const fields = revision?.fields;

    const { pipeline, setPipeline } = useContext(HasPipelineContext);

    const stages = pipeline?.stages || [];
    const setStages = (val) => {
        setPipeline({ ...pipeline, stages: val });
    }

    //const [stages, setStages] = useState([]);
    const [computing, setComputing] = useState(false);

    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);

    const enabled_stages = stages
        ?.filter(s => !s.disabled)
        ?.map(s => s.data);

    // update pipeline when stages change
    useEffect(() => {

    }, [stages])

    // // compute stages
    // useEffect(() => {

    //     if( !ds || !revision ) return;

    //     console.log("compute stages");
        
    //     (async () => {
    //         setComputing(true);
    //         setError(null);
    //         try {
    //             // const enabled_stages = stages
    //             //     .filter(s => !s.disabled)
    //             //     .map(s => s.data);
    //             console.log("estages", enabled_stages);
    //             const res = await computeApi.compute(
    //                 auth, 
    //                 subscription_id,
    //                 project_id, 
    //                 ds?.id, 
    //                 revision?.id,
    //                 enabled_stages,
    //                 {
    //                     limit: 100 // cursor limit
    //                 });
    //             setResult(res);
    //         }
    //         catch (err) {
    //             setError(err.message);
    //             setResult({ error: err.message });
    //         }
    //         setComputing(false);
    //     })();

    // }, [subscription_id, project_id, ds, revision, stages])

    const addStage = (stage) => {
        setStages([...stages, stage]);
    }

    const makeStage = (id) => {
        const item = PIPELINE_STAGES.find(i => i.id === id);
        return { 
            id: newId(), 
            type: item.id,
            data: item.initialValue,
            expanded: true 
        }
    }

    const addPreset1 = (stage) => {
        setStages([...stages, 
            makeStage("match"),
            makeStage("aggregate"),
            makeStage("sort")
        ]);
    }

    const deleteStage = (stage) => {
        setStages(stages.filter(s => s !== stage));
    }

    const replaceStage = (stage, replacement) => {
        setStages(stages.map(s => s === stage ? replacement : s));
    }


    const onDragEnd = (result) => {
        
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        const newStages = reorder(
            stages,
            result.source.index,
            result.destination.index
        );
        setStages(newStages);
    
    }

    const copyPipeline = async () => {
        const str = JSON.stringify(stages, null, 4);
        await navigator.clipboard.writeText(str);
    }

    const pastePipeline = async () => {
        try{
            const str = await navigator.clipboard.readText();
            const newStages = JSON.parse(str);
            setStages(newStages);
        }
        catch(err){
            alert(err.message);
        }
    }

    const expandAllStages = () => {
        const newStages = stages.map(s => ({ ...s, expanded: true }));
        setStages(newStages);
    }

    const collapseAllStages = () => {
        const newStages = stages.map(s => ({ ...s, expanded: false }));
        setStages(newStages);
    }

    const clearPipeline = () => {
        setStages([]);
        setFilename(null);
    }

    const saveClicked = () => {
        if( filename ){
            savePipeline();
        }
        else{
            savePipelineAs();
        }
    }

    const savePipeline = () => {
        toast.promise(
            async () => {
                const str = JSON.stringify(stages, null, 4);
                
                await saveApi.newItem({ label: filename, overwrite: true, jdata: str});
            },
            {
                pending: "Saving...",
                error: "Error saving",
                success: "Saved."
            },
            {
                autoClose: 1000
            }
        )
    }

    const savePipelineAs = () => {
        const str = JSON.stringify(stages, null, 4);
        setSavePipelineData(str);
    }

    return <PipelineEditorContext.Provider value={{ 
        deleteStage,
        replaceStage,
        dataSource: ds,
        revision, // (is the loaded revision with meta)
        stages
    }}>
        <div className="edit-pipeline-stages-v2">

            <Popup open={loadPipelineWinIsOpen}
                modal
                onClose={() => setLoadPipelineWinIsOpen(false)}
                contentStyle={LoadPipelineWinStyle}
            >
                <LoadPipelineWin
                    open={(item, fname) => {
                        const newStages = json5.parse(item.jdata); // jdata is unparsed at the time of writing, but seems like it shouldn't be
                        setStages(newStages);
                        setFilename(fname);
                    }}
                    close={() => {
                        setLoadPipelineWinIsOpen(false);
                    }}
                />
            </Popup>

            <Popup 
                open={savePipelineData ? true : false}
                modal
                onClose={() => setSavePipelineData(null)}
                contentStyle={SavePipelineWinStyle}
            >
                <SavePipelineWin
                    data={savePipelineData}
                    saved={(fname) => {
                        setFilename(fname);
                    }}
                    close={() => {
                        setSavePipelineData(null)
                    }} 
                />
            </Popup>

            {showRevisionId && <div className="pipeline-header1">
                {revLoading && <span className="message">loading revision...</span>}
                {revError && <span className="message error">{revError}</span>}
                {revision && <span>
                    Revision: [{revision?.id}] {revision?.notes || "(no notes)"}
                </span>}

            </div>}

            
            {<div className="pipeline-header2">
                
                <div className="hlabel">
                    Pipeline stages:
                </div>
                <div className="filename">
                    {filename}
                </div>
                <div className="hbuttons">
                    <button className="hbtn" title="Copy pipeline as JSON"
                        onClick={() => {
                            copyPipeline();
                        }}
                    >
                        <i className="fa-sharp fa-solid fa-copy" />
                    </button>
                    <button className={`hbtn ${filename ? "" : "disabled"}`} title="Save"
                        onClick={() => {
                            if( filename ){
                                savePipeline();
                            }
                        }}
                    >
                        <i className="fa-sharp fa-solid fa-save" />
                    </button>
                    <button className={`hbtn`} title="Save As"
                        onClick={() => {
                            savePipelineAs();
                        }}
                    >
                        <i className="fa-sharp fa-solid fa-floppy-disk-circle-arrow-right" />
                    </button>
                    <Popup
                        contentStyle={{
                            width: 240
                        }}
                        position='bottom right'
                        trigger={
                            <button className="hbtn"
                            >
                                <i className="fal fa-ellipsis" />
                            </button>
                        }
                    >
                        {closeMenu => <div className='popup-menu'>
                            <div className="menu-group">
                                <div className="menu-item" 
                                    onClick={async () => {
                                        await copyPipeline();
                                        closeMenu();
                                    }}
                                >
                                    Copy pipeline
                                    <span className='icon'>
                                        <i className="fa-sharp fa-solid fa-copy" />
                                    </span>
                                </div>
                                <div className="menu-item"
                                    onClick={async () => {
                                        await pastePipeline();
                                        closeMenu();
                                    }}
                                >
                                    Paste pipeline from clipboard
                                    <span className='icon'>
                                        <i className="fal fa-paste" />
                                    </span>
                                </div>
                            </div>
                            <div className="menu-group">
                                <div className="menu-item" onClick={() => {
                                    setLoadPipelineWinIsOpen(true);
                                }}>
                                    Open pipeline...
                                </div>
                                <div className={`menu-item ${filename ? "" : "disabled"}`} onClick={() => {
                                    if( filename ){
                                        closeMenu();
                                        savePipeline();
                                    }
                                }}>
                                    Save pipeline
                                </div>
                                <div className="menu-item" onClick={async () => {
                                    closeMenu();
                                    savePipelineAs();
                                }}>
                                    Save pipeline as...
                                </div>
                            </div>
                            <div className="menu-group">
                                <div className="menu-item" onClick={() => {
                                    expandAllStages();
                                    closeMenu();
                                }}>
                                    Expand all stages
                                </div>
                                <div className="menu-item" onClick={() => {
                                    collapseAllStages();
                                    closeMenu();
                                }}>
                                    Collapse all stages
                                    <span className="icon">
                                        <i className="fal fa-square-minus"/>
                                    </span>
                                </div>
                            </div>
                            <div className="menu-group" onClick={() => {
                                clearPipeline();
                                closeMenu();
                            }}>
                                <div className="menu-item">
                                    Clear pipeline
                                    <span className="icon">
                                        <i className="fal fa-skull" />
                                    </span>
                                </div>
                            </div>
                        </div>}
                    </Popup>
                </div>
            </div>}


            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            className={`pipeline-content ${snapshot.isDraggingOver ? "dragging-over" : ""}`}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {stages.map((stage, idx) => 
                                <Draggable key={stage.id} draggableId={stage.id} index={idx}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            
                                        >
                                            {/* [edit pipeline stage]
                                            <pre>
                                                {JSON.stringify(stage, null, 4)}
                                            </pre> */}
                                            <EditPipelineStage 
                                                handleProps={provided.dragHandleProps}
                                                key={idx}
                                                stage={stage} 
                                                onChanged={(newStage) => {
                                                    const newStages = stages.map(s => s === stage ? newStage : s);
                                                    //console.log("newStages", newStages);
                                                    setStages(newStages);
                                                }}
                                                className={`${snapshot.isDragging ? "dragging" : ""}`}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            )}
                            {provided.placeholder}

                            {stages?.length == 0 ?
                            <div className="presets">
                                <div className="note">
                                    Pipeline is empty. Add a stage or select preset:
                                </div>
                                <div className="preset" onClick={() => {
                                    addPreset1();
                                }}>
                                    <i className="far fa-filter"/>
                                    <i className="far fa-sigma"/>
                                    <i className="far fa-arrow-down-square-triangle"/>
                                </div>
                            </div>
                            : null}

                            <div className="pipeline-add-stage">
                                <Popup
                                    modal
                                    contentStyle={{
                                        width: 700,
                                        maxWidth: "100%",
                                        height: 400,
                                        maxHeight: "100%",
                                        overflow: "hidden"
                                        
                                    }}
                                    trigger={
                                        <span className="btn action">
                                            <i className="fal fa-plus" />&nbsp; Add stage
                                        </span>
                                    }>
                                        {closeMenu => <SelectStage close={closeMenu} addStage={addStage} />}
                                </Popup>
                            </div>

                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            
        </div>
    </PipelineEditorContext.Provider>
    ;
}