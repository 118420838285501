import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { EditContentContext } from "../content/EditContentContext";
import "./ArbitraryContent.scss";
import { computeContextState, ComputeRunnerContext } from "./ComputeContext";


export const ComputeButton = (props) => {
    const item = props.item;

    const ec = useContext(EditContentContext);

    const crc = useContext(ComputeRunnerContext);

    // get data from hook
    const cHook = item?.jdata?.contextHooks?.[0];
    const ccState = useRecoilValue(computeContextState(cHook?.id));
    //const runner = useRecoilValue(computeRunnerForScopeState(cHook?.id));
    const runner = crc[cHook?.id];

    const label = ccState?.computing ? "Running..." :
        "Run";

    const style = { ...props.style, ...item?.style }
    return <div className={`arbitrary-button ${props.displayClass}`} style={style} {...props.atts}
        onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            if( ec.mode === "edit" && (ev.altKey)){
                ec.setSelectionId(item?.id);
            }
            else{
                runner?.run?.();
            }
        }}
    >
        <span style={{ display: "inline-block", marginRight: 6 }}>
            {ccState?.running ?
                <i className={`far fa-circle-notch fa-spin`}/> 
                : <i className={`far fa-circle-play`}/> 
            }
        </span>
        {label}
    </div>
};

