// valid value formats:
// (d3 formats)

export const getExcelValueFormat = (format) => {
    switch( format ){
        case ".0f" : return "0";
        case ".1f" : return "0.0";
        case ".2f" : return "0.00";
        case ".3f" : return "0.000";
        case ".4f" : return "0.000";
        case ".0%" : return "0%";
        case ".1%" : return "0.0%";
        case ".2%" : return "0.00%";
        case ".3%" : return "0.000%";
        case ".4%" : return "0.000%";
        case ",.0f" : return "#,##0";
        case ",.1f" : return "#,##0.0";
        case ",.2f" : return "#,##0.00";
        case ",.3f" : return "#,##0.000";
        case ",.4f" : return "#,##0.0000";
        case "$,.0f" : return "$#,##0";
        case "$,.1f" : return "$#,##0.0";
        case "$,.2f" : return "$#,##0.00";
        case "$,.3f" : return "$#,##0.000";
        case "$,.4f" : return "$#,##0.0000";
    }
    return "0.0000";
}