import { useContext, useEffect, useState } from "react";
import { PipelineEditorContext, PipelineStageEditorContext } from "../../../../context/Contexts";
import { debounce } from "../../../../utils/debounce";
import { computeApi } from "../../../../api/computeApi";
import { useAuth } from "../../../../hooks/useAuth";
import { useProjectId } from "../../../../hooks/useProjectId";
import Popup from "reactjs-popup";
import { EditMeasures } from "./EditMeasures";
import "./EditAggregateStage.scss";
import { AggsDimContainer } from "./AggsDimContainer";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { newId } from "../../../../utils/uuid";
import { GroupStage } from "./GroupStage";
import { getStageInnerData } from "../_PipelineStages";
import { EditGroups } from "./EditGroups";
import { InspectionContext } from "../inspector/StagesInspectButton";

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
  
    destClone.splice(droppableDestination.index, 0, removed);
  
    const result = {};
    result.s = sourceClone;
    result.d = destClone;
  
    return result;
};

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};



export const EditAggregateStage = (props) => {

    const stageContext = useContext(PipelineStageEditorContext);

    const [aggsOpen, setAggsOpen] = useState(false);
    const [groupsOpen, setGroupsOpen] = useState(null);

    const stage = stageContext.stage;
    const stageInnerData = getStageInnerData(stage?.data);

    const [inspection, setInspection] = useState(null);

    const model = new GroupStage(stageInnerData);
    const defaultColDims = (model.colDimIds || []).map(id => ({ id, ...model.getDim(id)}));
    const defaultRowDims = (model.rowDimIds || []).map(id => ({ id, ...model.getDim(id)}));

    const [dims, setDims] = useState(model.dims);
    const [rowDims, setRowDims] = useState(defaultRowDims);
    const [colDims, setColDims] = useState(defaultColDims);
    
    const save = (newStageData) => {
        stageContext.replaceStageData({ "$mstudio.group_v2": newStageData });
    }

    const onDragEnd = (result) => {

        const { source, destination } = result;
    
        // dropped outside the list
        if (!destination) {
          return;
        }

        const sId = source.droppableId;
        const dId = destination.droppableId;

        const sDims = sId === "rows" ? rowDims : colDims;
        const dDims = dId === "rows" ? rowDims : colDims;

        let newStageData = null;

        if (sId === dId) {
            const newItems = reorder(sDims, source.index, destination.index);
            const sSetter = sId === "rows" ? setRowDims : setColDims;
            sSetter(newItems);

            newStageData = {
                dims: dims,
                rows: (sId === "rows") ? newItems.map(d => d.id) : rowDims.map(d => d.id),
                cols: (sId === "cols") ? newItems.map(d => d.id) : colDims.map(d => d.id)
            }

        } 
        else {
            const result = move(sDims, dDims, source, destination);
            const sSetter = sId === "rows" ? setRowDims : setColDims;
            const dSetter = dId === "rows" ? setRowDims : setColDims;
            sSetter(result.s);
            dSetter(result.d);

            newStageData = {
                dims: dims,
                rows: (sId === "rows") ? result.s.map(d => d.id) : result.d.map(d => d.id),
                cols: (sId === "cols") ? result.s.map(d => d.id) : result.d.map(d => d.id)
            }
        }

        save(newStageData);
        
    }

    const strip_items__id = (items) => items?.map(i => {
        const ni = { ... i };
        delete ni._id;
        return ni;
    })

    const strip_dims__id = (dims) => dims?.map(d => {
        return { ...d, items: strip_items__id(d.items) }
    });

    const saveDim = (dim, newDim) => {

        // trim the new id in case it has spaces
        newDim.id = newDim.id?.trim();

        // if dim id is blank, don't allow changing it
        if( !newDim.id ){
            newDim.id = dim.id;
            alert("Axis id cannot be blank. Other changes were saved.")
        }

        // if there's already another dim with the same id as newDim, don't allow changing it
        if( (dim.id !== newDim.id) && dims.find(d => d != dim && d.id === newDim.id) ){
            newDim.id = dim.id; // change it back
            alert("Could not change axis id due to conflict with existing axis. Other changes were saved.")
        }

        const newDims = dims.map(d => d.id === dim.id ? newDim : d);
        const newRowDims = rowDims.map(d => d.id === dim.id ? newDim : d);
        const newColDims = colDims.map(d => d.id === dim.id ? newDim : d);
        setDims(newDims);
        setRowDims(newRowDims);
        setColDims(newColDims);
        

        const newStageData = {
            dims: newDims,
            rows: newRowDims.map(d => d.id),
            cols: newColDims.map(d => d.id)
        }
        save(newStageData);
    }

    

    const addGroupsClicked = (obj) => {
        const getter = obj.containerId === "rows" ? rowDims : colDims;
        const setter = obj.containerId === "rows" ? setRowDims : setColDims;

        const newDim = {
            id: model.getUnusedDimId(),
            type: "group",
            label: "Groups",
            items: [
                //{ id: "derp1", label: "group 1", "syntax": "something==1", "fill": "red" }
            ]
        };

        const newDims = [...dims, newDim];
        const newContainerValue = [...getter, newDim];
        
        setDims(newDims);
        setter(newContainerValue);

        const newStageData = {
            dims: newDims,
            rows: rowDims.map(d => d.id),
            cols: colDims.map(d => d.id),
            [obj.containerId]: newContainerValue.map(d => d.id)
        }
        save(newStageData);

        setGroupsOpen(newDim.id);
    }

    const deleteClicked = (obj) => {

        const dim = obj.item;

        const getter = obj.containerId === "rows" ? rowDims : colDims;
        const setter = obj.containerId === "rows" ? setRowDims : setColDims;

        const newDims = dims.filter(d => d.id !== dim?.id);
        
        
        const newContainerValue = getter.filter(d => d.id !== dim?.id);
        
        setDims(newDims);
        setter(newContainerValue);

        const newStageData = {
            dims: newDims,
            rows: rowDims.map(d => d.id),
            cols: colDims.map(d => d.id),
            [obj.containerId]: newContainerValue.map(d => d.id)
        }
        save(newStageData);
    }

    const swapClicked = (obj) => {
        const d1 = rowDims;
        const d2 = colDims;
        setRowDims(d2);
        setColDims(d1);

        const newStageData = {
            dims,
            rows: d2.map(d => d.id),
            cols: d1.map(d => d.id),
        }
        save(newStageData);

    }

    const itemClicked = (obj) => {
        if( obj.item?.type === "agg"){
            setAggsOpen(true);
        }
        else if( obj.item?.type === "group" ){
            setGroupsOpen(obj.item?.id);
        }
        else{
            // todo
            console.log("todo: handle item type", obj.item);
        }
    }

    return <div className="edit-agg-stage">
        <InspectionContext.Provider value={{ inspection, setInspection }}>
            
            <Popup 
                modal 
                open={aggsOpen}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                overlayStyle={{ 
                    border: "none" ,
                }}
                contentStyle={{

                    margin: "auto",

                    width: 1200,
                    minWidth: "90%",
                    maxWidth: "100%",

                    height: 700,
                    minHeight: "90%",
                    maxHeight: "100%",
                    
                }}
            >
                <EditMeasures 
                    close={(s) => {
                        setAggsOpen(false);
                    }} 
                    dim={dims?.find(d => d.type === "agg")}
                    saveDim={saveDim}
                />
            </Popup>

            <Popup 
                modal 
                open={groupsOpen ? true : false}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                overlayStyle={{ 
                    border: "none" ,
                }}
                contentStyle={{
                    
                    margin: "auto",

                    width: 1200,
                    minWidth: "90%",
                    maxWidth: "100%",

                    height: 700,
                    minHeight: "90%",
                    maxHeight: "100%",
                }}
            >
                <EditGroups
                    close={(s) => {
                        setGroupsOpen(null);
                    }} 
                    dim={dims?.find(d => d.id === groupsOpen)}
                    saveDim={saveDim}
                />
            </Popup>

            <DragDropContext onDragEnd={onDragEnd}>
                <div className="row-entry">
                    <div className="c-label">
                        Columns:
                    </div>
                    <AggsDimContainer id="cols" items={colDims} 
                        addGroupsClicked={addGroupsClicked} 
                        deleteClicked={deleteClicked}
                        itemClicked={itemClicked}
                    />
                </div>

                <div className="row-entry">
                    <div className="c-label">
                        <span onClick={() => save()}>
                        Rows: 
                        </span>
                        <div className="btn icon" style={{ flexShrink: 0, marginLeft: 5 }}
                            onClick={swapClicked}
                        >
                            <i className="fal fa-arrow-up-arrow-down"/>
                        </div>
                    </div>
                    <AggsDimContainer id="rows" items={rowDims} 
                        addGroupsClicked={addGroupsClicked}
                        deleteClicked={deleteClicked}
                        itemClicked={itemClicked}
                    />
                </div>
            </DragDropContext>

        </InspectionContext.Provider>
    </div>
    
}