import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Link
} from "react-router-dom";
import * as restMethods from "../../api/restMethods";
import { AuthContext } from "../../context/Contexts";
import * as globals from "../../globals";
import { useAuth } from "../../hooks/useAuth";
import { TABS } from "../dashboard/DashboardOuter";
import { TopBar } from "../nav/TopBar";
import "./SelectSubscription.scss";

const getSubscriptions = async (auth) => {
    const url = `${globals.apiRoot}/subscriptions`;
    var response = await restMethods.getJson(auth, url);
    return await response.json();
}

export const SelectSubscription = (props) => {

    const selTab = TABS[1].id;

    const auth = useAuth();
    const authContext = useContext(AuthContext);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);
    const [subscriptions, setSubscriptions] = useState(null);
    const sel_id = null; // authContext?.authUser?.selected_subscription_id;
    const dispatch = useDispatch();

    useEffect(() => {

        const f = async () => {
            setLoading(true);
            try {
                const subs = await getSubscriptions(auth);
                setSubscriptions(subs);
            }
            catch (err) {
                setError(err.message);
                setSubscriptions([]);
            }
            setLoading(false);
        }
        f();

    }, []);

    return <div className='select-subscription-page'>

        <TopBar />

        <div className="main empty-space1">

            <div className='tab-space tab-section no-margin' style={{ margin: "0 10px" }}>
                {TABS.map(tab =>
                    <Link 
                        key={tab.id} 
                        className={`tab ${selTab === tab.id ? "sel" : ""}`}
                        to={tab.to}
                    >

                        {tab.label}
                    </Link>
                )}
                
            </div>

            <div className="tab-content" >
                <div className="list-subscriptions">

                <div className="list">
                    {loading ? <div className="subscriptions-message">Loading...</div> : null}
                    {error ? <div className="subscriptions-message">{error}</div> : null}

                    <div className="subscriptions-list">
                        <div className="group">
                        {subscriptions?.map(sub => 
                            <div className="subscription-block" key={sub.id}>
                                <Link
                                    key={sub.id}
                                    className={`clickable-area ${sub.id === sel_id ? "sel" : ""}`}
                                    to={`/subscription/${sub.id}`}
                                    >
                                        <div className="info-section">

                                            <div className="id-label">
                                                <span className="l1">
                                                    Subscription
                                                </span>
                                            </div>
                                            <div className="subscription-label">
                                                <span className="l1">
                                                    {sub.label}
                                                </span>
                                            </div>
                                            <div className="permission-set">
                                                <span className="label1">
                                                    Your Permissions:
                                                </span>
                                                <span className="reader-pill">
                                                    Reader
                                                </span>
                                                {sub.roles.includes("edit") ? 
                                                    <span className="editor-pill">
                                                        Editor
                                                    </span> : null
                                                }
                                                
                                            </div>

                                        </div>
                                        <div className="logo-section">
                                            [image, logo]
                                        </div>
                                    
                                </Link>
                            </div>
                        )}
                        </div>
                    </div>

                </div>

                </div>
            </div>

            
        </div>
    
    </div>;
};

