import { useContext, useState } from "react";
import Popup from "reactjs-popup";
import { PipelineEditorContext, PipelineStageEditorContext } from "../../../../context/Contexts";
import { useAuth } from "../../../../hooks/useAuth";
import { useProjectId } from "../../../../hooks/useProjectId";
import { SelectFieldsContext, SelectFieldsList } from "../../../common/SelectFieldsList";
import { InspectionContext, StagesInspectButton } from "../inspector/StagesInspectButton";
//import { getStageInnerData } from "../_PipelineStages";
import "./EditSelectStage.scss";

const parseNewRoot = (nr) => {
    let fields = [];
    if( nr ){
        Object.entries(nr).forEach(entry_arr => {
            const field = entry_arr[0];
            const value = entry_arr[1];
            if( typeof value === "object" ){
                const childFields = parseNewRoot(value);
                childFields.forEach(cf => fields.push(`${field}.${cf}`));
            }
            else{
                fields.push(field);
            }
        })
    }
    return fields;
}

const getNewRoot = (fields, p) => {
    const newRoot = {};
    if( fields ){
        for( let field of fields ){
            
            if(field.includes('.')){
                const idx = field.indexOf('.');
                const prec = field.substring(0, idx);

                // find all fields that start with prec
                const childFields = fields
                    .filter(f => f.startsWith(prec + "."))
                    .map(f => f.substring(idx + 1));
                
                const r = getNewRoot(childFields, prec);
                newRoot[prec] = r;
            }
            else{
                const field2 = p ? `${p}.${field}` : field;
                newRoot[field] = `$${field2}`
            }
        }
    }
    return newRoot;
}

export const EditSelectStage = (props) => {

    const stageContext = useContext(PipelineStageEditorContext);
    const stage = stageContext.stage;

    const _newRoot = stage?.data?.["$replaceRoot"]?.["newRoot"];
    const _fn = parseNewRoot(_newRoot);

    const [inspection, setInspection] = useState(null);
    const [addFields, setAddFields] = useState([]);

    const save = (val) => {

        try{
            const newRoot = getNewRoot(val);
            const newData = {
                $replaceRoot: {
                    newRoot
                }
            };
            stageContext.replaceStageData(newData)
        }
        catch{}
    }

    const fields = inspection ? Object.entries(inspection)?.map(entry_arr => {
        const key = entry_arr[0];
        const val = entry_arr[1];
        return {
            name: key
        }
    }) : [];


    return <div style={{ height: 200 }} className="edit-select-stage">
        <div className="top">
            <button onClick={() => {
                save([]);
            }}>
                clear
            </button>
        </div>
        <div className="entries">
            {_fn?.map((entry, idx) => 
                <div key={idx} className="entry">{entry}</div>
            )}
        </div>
        <div className="bot">
            <Popup 
                modal={true}
                contentStyle={{
                    height: 600,
                    maxHeight: "100%"
                }}
                trigger={<button>
                    add...
                </button>}>
                {close => <div className="select-fields-window full-dock">
                    <div className="top">
                        Select field(s)
                        <InspectionContext.Provider value={{
                            setInspection
                        }}>
                            <StagesInspectButton />
                        </InspectionContext.Provider>
                    </div>
                    <div className="center">
                        <SelectFieldsContext.Provider value={{
                            fields,
                            selectedFieldNames: addFields,
                            setSelectedFieldNames: setAddFields
                        }}>
                            <SelectFieldsList />
                        </SelectFieldsContext.Provider>
                    </div>
                    <div className="bottom">
                        <button onClick={() => {
                            const newFields = [
                                ...(_fn || []),
                                ...addFields
                            ]
                            setAddFields(null);
                            save(newFields);
                            close();
                        }}>
                            OK
                        </button>
                        <button onClick={() => {
                            close();
                        }}>
                            Cancel
                        </button>
                    </div>
                </div>}
            </Popup>
        </div>
    </div>
    
}