import JSON5 from "json5";
import { useEffect, useState } from "react";
import { useConfig, useConfigsApi } from "../../hooks/useConfig";
import { EditAtt } from "./EditAtt";
import "./EditConfigAtts.scss";


const safeJson = (obj) => {
    if (!obj) return null;
    return JSON.stringify(obj, null, 4);
}



export const EditConfigAtts = (props) => {

    const id = props.id;
    console.log('EditConfigAtts.id', id);
    const [config, configLoading, configError] = useConfig(id);
    const api = useConfigsApi();

    const [attsCode, setAttsCode] = useState(config?.attsString || safeJson(config?.atts));

    useEffect(() => {
        setAttsCode(config?.attsString || safeJson(config?.atts));

        // set testProductState
        if (config?.atts) {
            //setTestProductState(getDefaultProductState(config.atts));
        }

    }, [config?.atts])

    const saveAttributes = async () => {
        console.log('saveAttributes', id);
        try {
            const newItem = {
                ...config,
                atts: JSON5.parse(attsCode),
                attsString: attsCode
            };
            await api.updateItem(id, newItem);
        }
        catch (err) {
            alert(err.message);
        }
    }

    return <div className="edit-config-atts">
        {config?.atts?.map(att => <EditAtt att={att} />)}
    </div>
    
}