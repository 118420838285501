import { useState } from "react";
import { constructs } from "../../../api/contructs";
import { useCollections } from "../../../hooks/useCollection";
import { useConfigs } from "../../../hooks/useConfig";
import { useDataSources } from "../../../hooks/useDataSource";
import { useFilterBooks } from "../../../hooks/useFilterBook";
import { useImages } from "../../../hooks/useImage";
import { useModels } from "../../../hooks/useModel";
import { usePages } from "../../../hooks/usePage";
import { usePalettes } from "../../../hooks/usePalette";
import { useSelections } from "../../../hooks/useSelection";
import "./SelectResourceBox.scss";

// const RESOURCE_TYPES = [
//     { key: "palette", label: "Color Palette", defaultName: "palette" },
//     { key: "collection", label: "Collection", defaultName: "collection" },
//     { key: "data-source", label: "DataSource", defaultName: "db" },
//     { key: "image", label: "Image", defaultName: "image" },
//     { key: "model", label: "Model", disabled: true },
//     { key: "config", label: "Config", disabled: true },
// ]

const RESOURCE_TYPES = Object.values(constructs).map(construct => {
    
    return construct.isResource ? {
        key: construct.name,
        label: construct.label,
        defaultName: construct.name // default resource name for syntax
    } : null
})
    .filter(_ => _) // not null


const getName = (type, existingNames, prev) => {
    if (prev && !existingNames.includes(prev))
        return prev;
    
    const root = RESOURCE_TYPES.find(rt => rt.key === type)?.defaultName;
    if (!existingNames.includes(root))
        return root;
    
    let i = 2;
    while (existingNames.includes(`${root}${i}`)) {
        i++;
    }
    return `${root}${i}`;
}

export const SelectParentContextBox = (props) => {

    const existingNames = props.existingNames || [];

    const [selectedResourceType, setSelectedResourceType] = useState(null);
    const [selectedResourceId, setSelectedResourceId] = useState(null);
    const [resourceName, setResourceName] = useState(null);

    const items = props.items;

    // todo: the other types too

    return <div className='select-resource-box full-dock'>

        <div className="top" style={{ padding: 10 }}>
            <div style={{ marginBottom: 5 }}>
                Name: (unique identifier for this hook)
            </div>
            <input type="text" style={{ width: 200, marginRight: 10 }}
                value={resourceName || ""}
                onChange={(ev) => {
                    setResourceName(ev.target.value);
                }}
            />
            {!resourceName ? null : existingNames.includes(resourceName) ?
                <span key="1">
                    <i className='fas fa-warning' style={{ color: "#990000", marginRight: 5, fontSize: "10pt" }}/>
                    Name is already in use by another resource.
                </span>
                : <span key="2">
                    <i className='fas fa-check-circle' style={{ color: "#009900", marginRight: 5, fontSize: "10pt" }} />
                    Name is okay
                </span>}
        </div>

        <div className="left" style={{ padding: 10, overflowY: "auto" }}>
            <div style={{ marginBottom: 5 }}>
                Select Resource Type:
            </div>
            <div>n/a</div>
        </div>

        <div className="center" style={{ padding: 10 }}>
            {selectedResourceType ?
                <div style={{ marginBottom: 5 }}>
                    Select an item:
                </div>
                : null}

            {items?.map(entry => 
                <div 
                    key={entry.id} 
                    className={`resource-item ${selectedResourceId === entry.id ? "sel" : ""}`} 
                    onClick={() => {
                        setResourceName("cc1");
                        setSelectedResourceId(entry.id);
                        setSelectedResourceType(entry.ctype);
                    }}>
                    {entry.label}
                </div>
            )}


        </div>
        
        <div className="bottom" style={{ padding: 10, backgroundColor: "#efefef", textAlign: "right" }}>
            <span className={`btn action margin-right ${selectedResourceId ? "" : "disabled"}`} onClick={() => {
                if (!selectedResourceId) return null;
                if (!resourceName) return null;
                if (!selectedResourceType) return null;

                const r = {
                    name: resourceName,
                    type: selectedResourceType,
                    id: selectedResourceId
                };
                props.onAdd?.(r);
                props.close?.();
            }}>
                Add
            </span>
            <span className="btn action" onClick={() => props.close?.()}>
                Cancel
            </span>
        </div>
        

    </div>
}