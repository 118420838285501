import "./ModalFrame.scss"

export const ModalFrame = (props) => {

    const close = () => props.close?.();

    return <div className={`modal-frame ${props.className || ""}`} style={{ ...props.style }}>
        <div className='modal-frame-header'>
            <div className='close-button' onClick={close} title="Close">
                <i className='fal fa-times' />
            </div>
            {props.helpLink ? 
                <a className="help-button" target="_blank" href={props.helpLink} title="Help on this window">
                    <i className='fas fa-question-circle' />
                </a> 
                : null
            }
            {
                props.allowExpand ?
                <div className='expand-toggle-button' onClick={() => props.onToggleExpansionClicked?.()}>
                    <i className='fal fa-expand' />
                </div>
                : null
            }
            <div className='title'>
                {props.title || "Title"}
            </div>
            {props.caption ?
                <div className='caption'>
                    {props.caption}
                </div> : null
            }
        </div>
        <div className='modal-frame-content' style={{ ...props.contentStyle }}>
            {props.children}
        </div>
        <div className='modal-frame-footer'>
            <span className='button-section'>
                {props.buttons?.map((button, idx) => <div key={idx + button.label} className={`btn action margin-right ${button.className} ${button.disabled ? ' disabled' : ''}`}
                    onClick={() => {
                        //console.log('onClick', button);
                        if (button.callback && !button.disabled) {
                            button.callback(button);
                        }
                    }}
                >
                    {button.label}
                </div>)}
                
            </span>
        </div>
    </div>;

}