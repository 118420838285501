import { useState } from "react";
import { ModalFrame } from "./ModalFrame"


export const VerifyFrame = (props) => {

    const buttons = props.buttons || [
        {
            label: "Delete",
            callback: () => {
                props.confirmed?.(props.payload)
                props.close();
            }
        }
    ]

    return <ModalFrame
        title={props.title || "Are you sure?"}
        buttons={buttons}
        close={props.close}
    >
        <p>
            Are you sure you want to delete "{props.label || "item"}"?
        </p>

    </ModalFrame>

}

export const TextFrame = (props) => {

    const buttons = props.buttons || [
        {
            label: props.buttonLabel || "Save",
            callback: () => {
                props.confirmed?.(label);
                props.close();
            }
        }
    ]

    const [label, setLabel] = useState(props.initialTextValue || "");

    return <ModalFrame
        title={props.title || "title"}
        buttons={buttons}
        close={props.close}
    >
        <div className='form1'>

            <div className='label'>
                <div className='label-line'>
                    {props.label || "Label"}
                </div>
                <div className='value-line'>
                    <input type="text" className="full-width" value={label} onChange={(ev) => setLabel(ev.target.value)}/>
                </div>
            </div>

        </div>

    </ModalFrame>

}