import { BlobServiceClient } from "@azure/storage-blob";
import { useState } from "react";
import { useParams } from "react-router";
import * as globals from "../../globals";
import { ModalFrame } from "../modal-dialog/ModalFrame";
import axios from "axios";

import "./UploadDataFileModal.scss";
import { getAuthHeaders } from "../../api/restMethods";
import { useAuth } from "../../hooks/useAuth";
import { CONNECTORS } from "./connectors/_Connectors";
import { NewDataSourceContext } from "./NewDataSource";

export const UploadDataFileModal = (props) => {

    const routerParams = useParams();
    const id = props.id;
    const subscription_id = routerParams.subscription_id;
    const project_id = routerParams.project_id;
    const ds = props.ds;

    const [buttonText, setButtonText] = useState(null);
    const [working, setWorking] = useState(false);
    const [workStatusMessage, setWorkStatusMessage] = useState(null);
    const [notes, setNotes] = useState(null);
    const [makeDev, setMakeDev] = useState(true);

    const auth = useAuth();

    const connector_id = ds.connector || (ds.type === "file" ? "sav" : null);
    const selConnector = CONNECTORS.find(c => c.id === connector_id);
    const SelWidget = selConnector?.widget || ((props) => <div>not implemented</div>);

    const hackHandler = {

    }

    const providerObj = {
        setButtonText,
        hackHandler,
        setPage: () => null,
        notes,
        working,
        setWorking,
        workStatusMessage,
        setWorkStatusMessage
    };

    return <NewDataSourceContext.Provider value={providerObj}>

        <ModalFrame
            close={props.close}
            title={"Upload revision"}
            buttons={[
                {
                    disabled: working || workStatusMessage,
                    label: buttonText || "Upload",
                    callback: async () => {
                        const new_id = await hackHandler?.buttonClicked?.({ makeDev });
                        if (new_id) {
                            console.log("new_id", new_id);
                            props.reload?.(new_id);
                            props.close?.();
                        }
                    }
                },
            ]}
        >
            <SelWidget id={id} connector_id={selConnector?.id} alreadyExists getFlow={props.getFlow} />

            <div className="input-section">
                <p style={{ margin: "10px 0 0 0"}}>
                    Revision notes:<br/>
                    <textarea 
                        disabled={working}
                        type="text" 
                        id="notes" 
                        className="full-width two-lines"
                        placeholder="Write a note about what changed in this revision."
                        value={notes}
                        onChange={(ev) => setNotes(ev.target.value)}
                        />
                </p>
            </div>

            <div className='label'>
                <label className='label-line' style={{ fontSize: "9pt" }}>
                    <input type="checkbox" checked={makeDev} onChange={ev => setMakeDev(ev.target.checked)} /> Mount file and set as 'Dev' revision
                </label>
            </div>

        </ModalFrame>
    </NewDataSourceContext.Provider>;
}