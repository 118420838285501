import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { useEnforceSubscriptionId, useEnforceSubscriptionIdFromRoute } from "../../hooks/useEnforceSubscriptionIdFromRoute";
// import { ProjectLayout } from "./ProjectLayout";
// import "./ProjectLayout.scss";
// import { SelectProject } from "./select-project/SelectProject";
import * as globals from "../../globals";
import { subscriptionSocketApi } from "../../api/subscriptionSocketApi";
import { SubscriptionIdContext } from "../../context/Contexts";
import { PlayProject } from "./PlayProject";
import { PlayerListProjects } from "./PlayerListProjects";
import { TopBar } from "../nav/TopBar";
import { Main } from "../nav/Main";
import "./PlayerLayout.scss";

export const PlayerLayout = (props) => {

    const dispatch = useDispatch();
    const subscription_id = globals.player_sub;
    useEnforceSubscriptionId()
    
    const ssocket = useRef(null);

    useEffect(() => {
        if( !subscription_id ) return null;

        ssocket.current = subscriptionSocketApi.connect(subscription_id, null, dispatch);

        // cleanup
        return () => {
            console.log("subscription socket cleanup");
            ssocket.current.disconnect();
        }
    }, [subscription_id])


    return <SubscriptionIdContext.Provider value={{ subscription_id }}>
        <div className="player-layout">
            <TopBar />

            <div className="player-main">
                <Switch>
                    <Route path="/p/:project_id">
                        <PlayProject />
                    </Route>
                    <Route path="/" exact>
                        <PlayerListProjects />
                    </Route>
                    <Route>
                        <div className="message centered h-centered">
                            [not implemented]
                        </div>
                    </Route>
                </Switch>
            </div>
        </div>
    </SubscriptionIdContext.Provider>;

}