export class GroupStage {

    constructor(jdata){
        this.dims = jdata?.dims || [];
        this.rowDimIds = jdata?.rows || [];
        this.colDimIds = jdata?.cols || [];
    }

    dims;

    rowDimIds; // array of dim ids
    colDimIds; // array of dim ids

    toJson = () => {
        return {
            dims: this.dims,
            rows: this.rows,
            cols: this.cols
        }
    }

    getDim = (id) => {
        return this.dims.find(d => d.id === id);
    }

    dimIds = ["g", "h", "i", "j", "k", "gg", "hh", "ii", "jj", "kk"];

    getUnusedDimId = (prec = "g") => {
        let idx = 0;
        while(this.dims.find(d => d.id === this.dimIds[idx])){
            idx++;
            if( idx >= this.dimIds.length ){
                return this.getUnusedDimIdOld();
            }
        }
        return this.dimIds[idx];
    }

    getUnusedDimIdOld = (prec = "g") => {
        let idx = 1;
        while(this.dims.find(d => d.id === `${prec}${idx}`)){
            idx++;
        }
        return `${prec}${idx}`;
    }

    reduceMeta = (meta) => {
        
        let newMeta = {
            ...meta
        };

        for(let id of this.colDimIds){
            const dim = this.getDim(id);
            newMeta[id] = dim ? JSON.parse(JSON.stringify(dim)) : null; // copied so consumer doesn't screw it up?
        }

        return newMeta;
    }

    inspectItemKeys = (dim) => {
        let keys = {};
        if( dim.items ){
            for( let item of dim.items ){
                Object.keys(item).forEach(key => {
                    if( !keys[key] ){
                        keys[key] = 1;
                    }
                })
            }
        }
        return Object.keys(keys);
    }

    reduceFields = (fields) => {
        
        let newFields = [];

        for( let id of this.rowDimIds ){
            const rowDim = this.getDim(id);
            const keys = this.inspectItemKeys(rowDim);
            for( let key of keys ){
                newFields.push({ name: `${id}.${key}` });
            }
        }

        for( let id of this.colDimIds ){
            const colDim = this.getDim(id);
            //console.log("colDim", colDim);
            if( colDim.items ){
                for( let item of colDim.items ){
                    newFields.push({ name: `${item.id}` });
                }
            }
        }


        return newFields;
    }

    getFieldsUsed = () => {

    }
}