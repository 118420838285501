import { useContext, useState } from "react";
import { useContentItem } from "../../hooks/useContentItem";
import { EditContentContext } from "./EditContentContext";
import "./DocTreeNode.scss";
import { registryDict } from "./ContentRegistry";
import { fallbackSvgPath } from "./SelectNewContentItem";
import Popup from "reactjs-popup";
import { useContentItemsApi } from "../../hooks/useContentItems";
import { EditItemContextMenu } from "./EditItemContextMenu";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

export const GetIcon = (ctype) => {
    const entry = registryDict?.[ctype];
    if( !entry ) return;

    const svgPath = entry?.svgPath || fallbackSvgPath;
    const glyph = <svg height={16} width={16} viewBox="0 0 16 16">
        {svgPath && <path d={svgPath} fillRule="evenodd" fill={entry?.svgPath ? "#606060" : "#cfcfcf"} />}
    </svg>;
    return glyph;

}

export const DocTreeNode = (props) => {

    const id = props.id;
    const api = useContentItemsApi();
    const level = props.level || 0;

    const history = useHistory();

    const [expanded, setExpanded] = useState(true);
    const [item, itemLoading, itemError, itemReload] = useContentItem(id);
    const editContext = useContext(EditContentContext);
    
    const selected = editContext.selectionId === id;
    const hasChildren = item?.children;
    const children = item?.children;

    if( itemLoading ) return <div className="doctree-message">Loading...</div>;
    if( itemError ) return <div className="doctree-message">Error</div>;
    if( !item ) return null;
    const jdata = item.jdata;
    

    const deleteChild = async (childId, childLabel) => {
        editContext.setMessage(
            <div>
                <div style={{ marginBottom: 5 }}>
                    <i className="fas fa-skull" />&nbsp; <span className="alt-color">Delete "{childLabel}"</span> Are you sure?
                </div>
                <div>
                    <button onClick={async (ev) => {
                        ev.stopPropagation();
                        ev.preventDefault();

                        editContext.setMessage("Deleting...");
                        await api.deleteItem(childId);
                        editContext.setMessage(null);

                        const reload = editContext.reloadStack?.[editContext.reloadStack?.length - 1];
                        //await itemReload?.();
                        await reload?.();

                        editContext.setMessage(null);
                    }}>
                        Yes
                    </button>
                    <button onClick={(ev) => {
                        ev.stopPropagation();
                        ev.preventDefault();
                        editContext.setMessage(null);
                    }}>
                        Cancel
                    </button>
                </div>
            </div>
        );
        
        
    }

    const addChild = async (entry, key) => {
        editContext.setMessage("Creating child...");
        const obj = {
            ctype: entry.id,
            label: entry.label
        }
        if( key ){
            obj.key = key;
        }
        if( entry.jdata ){
            obj.jdata = entry.jdata;
        }
        
        const newInfo = await api.newItem(obj, id); // todo: add displayIndex somehow
        editContext.setMessage("Loading...");
        await itemReload?.();
        
        editContext.setSelectionId(newInfo.newId); // select the new item
        //editContext.setSelectionId(null); // clear selection

        editContext.setMessage(null);
    }

    const newEditContext = {
        ...editContext,
        deleteChild,
        addChild,
        item,
        itemStack: [...editContext.itemStack, item],
        reloadStack: [...editContext.reloadStack, async () => {
            editContext.setMessage("Reloading...");
            await itemReload();
            editContext.setMessage(null);
        }],
        // navigate: () => {
        //     const s = editContext.navigate();
        //     console.log("navigation called on " + item?.label);
        //     return s;
        // }
    };

    const parentItem = editContext.itemStack[editContext.itemStack.length - 1];
    if( parentItem?.ctype === "mstudio.tabcontrol" ){
        newEditContext.navigate = () => {
            // first call all parent navigations
            const search = editContext.navigate();
            //console.log("navigation+ called on " + item?.label);
            const parsedSearch = queryString.parse(search);

            // now navigate to me
            const newSearch = {
                ...parsedSearch,
                [parentItem.id]: id
            }
            //console.log(item.label + " returning:", queryString.stringify(newSearch));
            return queryString.stringify(newSearch);
            
            //const url = `${history.location.pathname}?${queryString.stringify(newSearch)}`;
        }
    }

    const toggleLock = async () => {
        const new_jdata = { ...jdata };
        if( jdata?.locked ){
            editContext.setMessage("Unlocking...");
            delete new_jdata.locked;
        }
        else{
            editContext.setMessage("Locking...");
            new_jdata.locked = true;
        }
        
        await api.patchItem(item.id, { jdata: new_jdata });
        editContext.setMessage(null);
    }

    const toggleVisibility = async () => {
        const new_jdata = { ...jdata };
        if( jdata.visible === false ){
            editContext.setMessage("Unhiding...");
            delete new_jdata.visible;
        }
        else{
            editContext.setMessage("Hiding...");
            new_jdata.visible = false;
        }
        
        await api.patchItem(item.id, { jdata: new_jdata });
        editContext.setMessage(null);
    }

    return <div id={`docnode-${id}`} className="doctree-node" style={{}}>
        <div className={`doctree-label ${selected ? "sel" : ""}`}
            style={{
                paddingLeft: level * 20
            }}
            onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();

                // if already selected, de-select
                if( editContext.selectionId === id ){
                    editContext.setSelectionId(null);
                    return;
                }

                // select the item
                editContext.setSelectionId(id);

                // navigate to it (in case it's a child of tabcontrol(s))
                const search = history.location.search;
                const newSearch = newEditContext.navigate();
                if( search != newSearch ){
                    const url = `${history.location.pathname}?${newSearch}`;
                    history.push(url);
                }
                
            }}
        >
            <div className="expander" onClick={(ev) => {
                setExpanded(!expanded);
                ev.stopPropagation();
            }}>
                {GetIcon(item?.ctype)}
                {!expanded && item?.children?.length && <span className="ch-count">({item?.children?.length})</span>}
            </div>
            {/* {hasChildren ?
                <div className="expander" onClick={() => hasChildren ? setExpanded(!expanded) : null}>
                    {expanded ? <i className='fal fa-folder-open'/> : hasChildren ? <i className='fal fa-folder' /> : <i className='fal fa-cube' />}
                </div>
                : <span style={{ display: "inline-block", width: 26 }} />
            } */}
            <div className="label">
                {item?.label || item?.ctype || id}
            </div>
            <div className="lock" onClick={() => toggleLock()}>
                {jdata?.locked ? <i className="fas fa-thumbtack locked" /> : <i className="fal fa-thumbtack unlocked" />}
            </div>
            <div className="vis" onClick={() => toggleVisibility()}>
                {jdata?.visible === false ? <i className="fas fa-eye-slash hidden" /> : <i className="fal fa-eye visible" />}
            </div>
            <Popup
                keepTooltipInside
                position={"right top"}
                trigger={
                    <div className="menu-btn">
                        <i className="fal fa-ellipsis" />
                    </div>
                }
            >
                {close => 
                    <EditContentContext.Provider value={newEditContext}>
                        <EditItemContextMenu close={close} />
                    </EditContentContext.Provider>
                }
            </Popup>
        </div>
        {expanded && children?.map?.(child => {
            return <EditContentContext.Provider key={child.id} value={newEditContext}>
                <DocTreeNode id={child.id} level={level + 1} />
            </EditContentContext.Provider>
        })}
    </div>;
}