import { useContext } from "react";
import { useContentItemsApi } from "../../hooks/useContentItems";
import { ContentAdderButton } from "../content/ContentAdderButton";
import { DisplayContext, getDisplayClass } from "../content/DisplayContext";
import { EditContentContext } from "../content/EditContentContext";
import { PresentContent } from "../content/PresentContent";
import { GenWrapper } from "./ArbitraryContent"
import "./DivLayout.scss";

export const DivLayout = (props) => {

    const ec = useContext(EditContentContext);
    const mode = ec.mode;
    const item = ec.item;
    const children = item?.children;
    const child = children?.[0];
    const locked = item?.jdata?.locked;
    const style = { ...props.style, ...item?.style }

    return <div className={`mstudio-div-layout ${props.displayClass}`} style={style} {...props.atts}>
        {child ?  
            <PresentContent id={child.id} />
            : mode === "edit" ? 
                !locked && <ContentAdderButton label="Add content" />
            : null
        }
    </div>

    
}