export const StyleProperties = {


    BORDER: { name: "border", "type": "border" },
    // border-[side-]color (name, hex, rgb, hsl, transparent)
    // border-[side-]style (1-4 values)
    // border-[side-]width 25px 10px 4px 35px
    // border-[side]-style
    // border[-side] (shorthand): width style(required) color
    // border-radius

    PADDING: { name: "padding", "type": "padding" },
    // padding (shorthand)
    // (note box-sizing)

    background: { name: "style.background", "type": "string" },
    // background-color
    // background-image
    // background-repeat
    // background-attachment
    // background-position
    // background (shorthand)

    margin: { name: "style.margin", "type": "string" },
    // margin[-side]
    // note: margin-collapse

    width: { name: "style.width", type: "string" },
    maxWidth: { name: "style.maxWidth", type: "string" },
    minWidth: { name: "style.minWidth", type: "string" },
    // width: {auto, length, %, initial, inherit}
    // max-width
    // min-width

    height: { name: "style.height", type: "string" },
    maxHeight: { name: "style.maxHeight", type: "string" },
    minHeight: { name: "style.minHeight", type: "string" },
    // height
    // max-height
    // min-height

    //BOX_SIZING
    // content-box, border-box


    // SHOULD OUTLINE BE USED!!!
    // outline-style
    // outline-color
    // outline-width
    // outline-offset
    // outline


    // text stuff
    COLOR: {},
    BACKGROUND_COLOR: {},
    TEXT_ALIGN: {}, // left, center, right, justify
    TEXT_ALIGN_LAST: {}, // same as above, but only for the last line
    TEXT_DECORATION: {},
    // text-decoration-line {overline, line-through, underline, [multiple]}
    // text-decoration-color
    // text-decoration-style {solid, double, dotted, dashed, wavy}
    // text-decoration-thickness {auto, length, %}
    // text-decoration (shorthand): line(required) color style thickness


    TEXT_TRANSFORM: {},
    // none, uppercase, lowercase, capitalize(, initial, inherit)

    TEXT_SPACING: {},
    // text-indent
    // letter-spacing
    // line-height
    // word-spacing
    // white-space

    TEXT_SHADOW: {},
    // text-shadow (shorthand?)


    // TEXT EFFECTS
    // text-overflow {clip, ellipsis?, visible?} ??
    // word-wrap {break-word} ??
    // word-break {keep-all, break-all} ??
    // writing-mode {horizontal-tb, vertical-rl} ??


    // LINKS
    // a:link
    // a:visited
    // a:hover
    // a:active

    // LISTS
    // ul, ol


}