import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { usePage, usePagesApi } from "../../../hooks/usePage";
import { InstancesInput } from "../../instances/InstancesInput";
import "./EditPageProps.scss";

import { debounce } from "../../../utils/debounce";
import { PageContext } from "../../../context/Contexts";

const debouncedSaver = debounce((obj, updatePage) => {
    console.log("saving", obj);
    updatePage(obj);
}, 1000);

export const EditPageProps = (props) => {

    const page = useContext(PageContext);
    
    const api = usePagesApi();

    const [label, setLabel] = useState(page?.label);
    const [url, setUrl] = useState(page?.url);
    const [instances, setInstances] = useState(page?.instances);
    const [layout, setLayout] = useState(page?.layout);

    useEffect(() => {
        if( !page ) return;
        setLabel(page.label);
        setUrl(page.url);
        setInstances(page.instances);
        setLayout(page.layout);
    }, [page])

    useEffect(() => {
        if (label !== page?.label
            || url !== page?.url
            || layout !== page?.layout
            || JSON.stringify(instances) !== JSON.stringify(page?.instances)
        ) {

            const newPage = {
                ...page,
                label,
                url,
                layout,
                instances
            };
            console.log("newPage", newPage);

            debouncedSaver(newPage, (x) => {
                //api.updateItem(page.id, x);
                api.patchItem(page.id, x);
            });
            
        }
    }, [label, url, layout, instances])

    const updateItem = async (newPage) => {
        await api.updateItem(page.id, newPage);
    }

    return <div className='edit-page-props'>
        
        <div className='form1'>

            <div className='label'>
                <div className='label-line'>
                    Label
                </div>
                <div className='value-line'>
                    <input type="text" className="full-width" value={label} onChange={(ev) => setLabel(ev.target.value)}/>
                </div>
            </div>

            <div className='label'>
                <div className='label-line'>
                    Url
                </div>
                <div className='value-line'>
                <input type="text" className="full-width" value={url} onChange={(ev) => setUrl(ev.target.value)}/>
                </div>
            </div>

            <div className='label'>
                <div className='label-line'>
                    Layout
                </div>
                <div className='value-line'>
                    <select value={layout} onChange={(ev) => setLayout(ev.target.value)} className="full-width">
                        {[
                            { key: "vscroll", label: "Vertical Scroll" },
                            { key: "fill", label: "Fill" },
                        ].map(entry => <option key={entry.key} value={entry.key}>{entry.label}</option>)}
                    </select>
                </div>
            </div>

            <div className='label'>
                <div className='label-line'>
                    Instances <i className='fal fa-question-circle'/>
                </div>
                <div className='value-line'>
                    <InstancesInput value={instances} onChange={v => setInstances(v)}/>
                </div>
            </div>

            <div className='label'>
                <div className='label-line'>
                    Show In Menu
                </div>
                <div className='value-line'>
                    <input type="checkbox" checked={page?.showInMenu} onChange={
                        async (ev) => {
                            const newPage = {
                                ...page,
                                showInMenu: ev.target.checked
                            };
                            await updateItem(newPage);
                        }
                    } />
                </div>
            </div>

            <div className='label'>
                <div className='label-line'>
                    Menu Item Only (no visits to this page)
                </div>
                <div className='value-line'>
                    <input type="checkbox" checked={page?.noVisit} onChange={
                        async (ev) => {
                            const newPage = {
                                ...page,
                                noVisit: ev.target.checked
                            };
                            await updateItem(newPage);
                        }
                    } />
                </div>
            </div>


        </div>



    </div>;

    
}