import { EtlNodeTypeInfosArray } from "./etl/EtlNodes";
import "./FlowTools.scss";

const WIDGETS = EtlNodeTypeInfosArray;

// const WIDGETS = [
//     { id: "datasource", label: "Data source", icon: "fad fa-database" },
//     { id: "filter", label: "Filter", icon: "fas fa-filter" },
//     { id: "add-fields", label: "Add fields", icon: "fad fa-plus-hexagon" },
//     { id: "change-fields", label: "Change fields", icon: "fad fa-random" },
//     { id: "map", label: "Map", icon: "fad fa-random" },
//     { id: "aggregate", label: "Aggregate", icon: "fad fa-function" },
//     { id: "stackN", label: "Stack", icon: "fad fa-layer-group" },
//     { id: "join", label: "Join", icon: "fad fa-layer-group fa-rotate-90" },
//     { id: "dataout", label: "Output", icon: "fad fa-database" },
//     { id: "xslt", label: "XSL Transform", icon: "fad fa-transformer-bolt" },
// ]


export const FlowTools = (props) => {

    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };
    
    return <div className={`flow-tools ${props.className || ""}`}>

        <p>
            Click and drag widgets from below onto the data flow canvas.
        </p>

        <div className={`widget-grid`}>

            {WIDGETS.map(widget => 
                <div className="widget-button" key={widget.id}
                    onDragStart={(event) => onDragStart(event, widget.id)}
                    draggable
                >
                    <span className="icon">
                        <i className={widget.icon} />
                    </span>
                    {widget.label}
                </div>
            )}
            
        </div>

    </div>
}