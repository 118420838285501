import Editor from "@monaco-editor/react";
import { useState } from "react";
import { ControlledCodeEditorFillContainer } from "../../../code-editor/CodeEditor";
import { DataSourceSelector } from "../../DataSourceSelector";
import "./AddFieldsEtlNodeEditor.scss";

const TABS = [
    { id: "ui", label: "UI" },
    { id: "json", label: "JSON" }
];

export const AddFieldsEtlNodeEditor = (props) => {
    const { data, saveData } = props;

    const [selTab, setSelTab] = useState(TABS[1].id);
    const [label, setLabel] = useState(data?.label);
    const [syntax, setSyntax] = useState(data?.syntax);

    return <div 
        className="add-fields-etl-node-editor"
    >
        <div className="top">
            <input type="text" placeholder="Optional node label" style={{ width: "100%" }}
                value={label} onChange={(ev) => setLabel(ev.target.value)}
            />
        </div>
        <div className="center">
            <div className="tab-section2">
                {TABS.map(t =>
                    <span key={t.id} className={`tab ${selTab === t.id ? "sel" : ""}`}
                        onClick={ev => setSelTab(t.id)}
                    >
                        {t.label}
                    </span>
                )}
            </div>
            {selTab === "ui" ?
                <div className="ui-editor">
                    <div className="actions">
                        buttons go here
                    </div>
                    <div className="list">
                        list goes here
                    </div>
                </div>
            : (selTab === "json") ? 
                <Editor
                    style={{ height: "100%" }}
                    editorProps={{
                        options: {
                            lineNumbers: "off",
                            folding: false,
                            wordWrap: false,
                            minimap: {
                                enabled: false
                            }
                        }
                    }}
                    language="json"
                    value={syntax}
                    onChange={(newCode) => setSyntax(newCode)}
                />
            : null
            }
        </div>
        <div className="bottom" style={{ paddingTop: 5 }}>
            <button style={{ padding: "5px 10px" }} onClick={() => {
                saveData({
                    ...data,
                    label,
                    syntax
                })
            }}>
                Save
            </button>
        </div>
    </div>
}

