import { useDataSource } from "../../../hooks/useDataSource"
import { FakeChart1 } from "../FakeChart1"
import { AddFieldsEtlNodeEditor } from "./editors/AddFieldsEtlNodeEditor"
import { EtlNode } from "./EtlNode"
import { ComputeEtlNodeEditor, DataOutEtlNodeEditor, DataSourceEtlNodeEditor, FilterEtlNodeEditor, GenericEtlNodeEditor, JoinFilesEtlNodeEditor, LimitEtlNodeEditor, MapEtlNodeEditor, RemoveFieldsEtlNodeEditor, StackNFilesEtlNodeEditor, XslTransformEditor } from "./EtlNodeEditors"



export const DataSourceEtlNode = (props) => {

    const [ds, dsLoading, dsError] = useDataSource(props.data?.dataSourceId);

    return <EtlNode
        {...props}
        nbInputs={0}
        nbOutputs={1}
        icon={<i className="fad fa-database"/>}
    >
        {ds?.label || props.data?.label || "Data Source"}

    </EtlNode>
}

export const FilterEtlNode = (props) => {
    return <EtlNode
        {...props}
        nbInputs={1}
        nbOutputs={1}
        icon={<i className="fas fa-filter"/>}
    >
        {props.data?.label || "Filter"}
    </EtlNode>
}

export const StackFilesEtlNode = (props) => {
    return <EtlNode
        {...props}
        nbInputs={2}
        nbOutputs={1}
        icon={<i className="fad fa-layer-group"/>}
        width={90}
    >
        {props.data?.label || "Stack"}
    </EtlNode>
}

export const JoinFilesEtlNode = (props) => {
    return <EtlNode
        {...props}
        nbInputs={2}
        nbOutputs={1}
        icon={<i className="fad fa-layer-group fa-rotate-90"/>}
    >
        {props.data?.label || "Join"}
    </EtlNode>
}

export const ComputeEtlNode = (props) => {
    return <EtlNode
        {...props}
        nbInputs={1}
        nbOutputs={1}
        icon={<i className="fad fa-function"/>}
    >
        {props.data?.label || "Aggregate"}
    </EtlNode>
}

export const MapEtlNode = (props) => {
    return <EtlNode
        {...props}
        nbInputs={1}
        nbOutputs={1}
        icon={<i className="fad fa-random"/>}
    >
        {props.data?.label || "Map"}
    </EtlNode>
}

export const LimitEtlNode = (props) => {
    return <EtlNode
        {...props}
        nbInputs={1}
        nbOutputs={1}
        icon={<i className="fad fa-scissors"/>}
    >
        {props.data?.label || "Limit"}
    </EtlNode>
}


export const AddFieldsEtlNode = (props) => {
    return <EtlNode
        {...props}
        nbInputs={1}
        nbOutputs={1}
        icon={<i className="fad fa-plus-hexagon"/>}
    >
        {props.data?.label || "Add Fields"}
    </EtlNode>
}

export const RemoveFieldsEtlNode = (props) => {
    return <EtlNode
        {...props}
        nbInputs={1}
        nbOutputs={1}
        icon={<i className="fad fa-plus-hexagon"/>}
    >
        {props.data?.label || "Remove Fields"}
    </EtlNode>
}

export const ChangeFieldsEtlNode = (props) => {
    return <EtlNode
        {...props}
        nbInputs={1}
        nbOutputs={1}
        icon={<i className="fad fa-random"/>}
    >
        {props.data?.label || "Change Fields"}
    </EtlNode>
}

export const CustomEtlNode = (props) => {
    return <EtlNode
        {...props}
        nbInputs={1}
        nbOutputs={0}
        xicon={<i className="fad fa-random"/>}
    >
        {props.data?.xlabel || <FakeChart1/>}

    </EtlNode>
}

export const DataOutEtlNode = (props) => {
    return <EtlNode
        {...props}
        nbInputs={1}
        nbOutputs={0}
        icon={<i className="fad fa-database"/>}
    >
        {props.data?.label || "Output"}
    </EtlNode>
}

export const XslTransform = (props) => {
    return <EtlNode
        {...props}
        nbInputs={2}
        nbOutputs={1}
        icon={<i className="fad fa-transformer-bolt"/>}
    >
        {"XSL Transform"}
    </EtlNode>
}


export const EtlNodeTypes = {
    "datasource": DataSourceEtlNode,
    "dataout": DataOutEtlNode,
    "filter": FilterEtlNode,
    "stack": StackFilesEtlNode,
    "compute": ComputeEtlNode, // deprecated
    "aggregate": ComputeEtlNode,
    "project": MapEtlNode, // deprecated
    "map": MapEtlNode,
    "add-fields": AddFieldsEtlNode,
    "limit": LimitEtlNode,
    "remove-fields": RemoveFieldsEtlNode,
    "change-fields": ChangeFieldsEtlNode,
    "join": JoinFilesEtlNode,
    "custom": CustomEtlNode,
    "xslt": XslTransform
}

export const EtlNodeTypeInfos = {
    "datasource":    { label: "Data Source",    viewer: DataSourceEtlNode    , editor: DataSourceEtlNodeEditor,   icon: "fad fa-database" },
    "filter":        { label: "Filter",         viewer: FilterEtlNode        , editor: FilterEtlNodeEditor,       icon: "fas fa-filter" },
    "add-fields":    { label: "Add fields",     viewer: AddFieldsEtlNode     , editor: AddFieldsEtlNodeEditor,    icon: "fad fa-plus-hexagon" },
    "remove-fields": { label: "Remove fields",  viewer: RemoveFieldsEtlNode  , editor: RemoveFieldsEtlNodeEditor, icon: "fad fa-minus-hexagon" },
    //"change-fields": { label: "Change fields",  viewer: ChangeFieldsEtlNode  , editor: ChangeFieldsEtlNode,      icon: "fad fa-random" },
    "map":           { label: "Map",            viewer: MapEtlNode           , editor: MapEtlNodeEditor,          icon: "fad fa-random" },
    "custom":       { label: "Custom/Aggregate", viewer: ComputeEtlNode       , editor: ComputeEtlNodeEditor,     icon: "fad fa-function" },
    "stack":         { label: "Stack",          viewer: StackFilesEtlNode   , editor: StackNFilesEtlNodeEditor, icon: "fad fa-layer-group", data: {} },
    "join":          { label: "Join",           viewer: JoinFilesEtlNode     , editor: JoinFilesEtlNodeEditor,   icon: "fad fa-layer-group fa-rotate-90" },
    "dataout":       { label: "Output",         viewer: DataOutEtlNode       , editor: DataOutEtlNodeEditor,     icon: "fad fa-database" },

    "limit":       { label: "Limit",         viewer: LimitEtlNode       , editor: LimitEtlNodeEditor,     icon: "fad fa-scissors" },

    //"xslt":          { label: "XSL Transform",  viewer: XslTransform         , editor: XslTransformEditor,       icon: "fad fa-transformer-bolt" },

    // "compute":       { label: "", widget: ComputeEtlNode }, // deprecated
    // "project":       { label: "", widget: MapEtlNode }, // deprecated
    // "custom":        { label: "", widget: CustomEtlNode },
    
}

export const EtlNodeTypeInfosArray = Object.entries(EtlNodeTypeInfos).map(entry => ({
    id: entry[0],
    ...entry[1]
}))