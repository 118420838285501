import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Popup from 'reactjs-popup';
import { usePalettes } from "../../hooks/usePalette";
import { usePalette } from "../../hooks/usePalette";
import { useProjectId } from "../../hooks/useProjectId";
import { VerifyFrame } from "../modal-dialog/VerifyFrame";
import { NewImage } from "./NewImage";

import { useImages, useImagesApi } from "../../hooks/useImage";
import { useImage } from "../../hooks/useImage";
import { ImageSwatch } from "./ImageSwatch";
import "./Images.scss";

import { BlobServiceClient } from "@azure/storage-blob";
import * as globals from "../../globals";
import { useEffect, useState } from "react";
import { Main } from "../nav/Main";

const OBJECT_FITS = [
    //{ key: "fill", label: "Fill" },
    { key: "contain", label: "Contain" },
    { key: "cover", label: "Cover (Fill)" },
    //{ key: "scale-down", label: "Scale Down" },
]

const VIEW_SIZES = [
    { key: "small", label: "Small" },
    { key: "med", label: "Medium" },
    { key: "large", label: "Large" },
    { key: "xl", label: "XL" },
    { key: "xxl", label: "XXL" },
    
]


export const Images = (props) => {

    const {project_id, subscription_id} = useProjectId();
    

    const [objectFit, setObjectFit] = useState("contain");
    const [viewSize, setViewSize] = useState("small");
        
    const api = useImagesApi();
    const [images, imagesLoading, imagesError] = useImages();
        

    const content =
        imagesError ? <div className='list-content error'>Error loading images</div> :
        imagesLoading ? <div className='list-content unknown'>Loading...</div> :
        <div className={`list-content swatches swatch-size-${viewSize}`}>
            {images?.map((image, imageIdx) => {
                //const name = item.name;
                const key = `[${imageIdx}] ${image.label}`;
                const swatch = <ImageSwatch
                    subscription_id={subscription_id}
                    project_id={project_id}
                    key={key}
                    item={image}
                    imgStyle={{ objectFit: objectFit }}
                    imgClass={viewSize}
                    onDelete={async () => {
                        await api.deleteItem(image.id, image.filename);
                    }}

                    onSave={async (newSwatch) => {
                        // const newList = list.map(i => i.tempName !== image.tempName ? i : newSwatch);
                        // const newSwatches = newList.map(i => ({
                        //     name: i.name,
                        //     fg: i.fg,
                        //     bg: i.bg
                        // }));
                        // const newPal = {
                        //     ...palette,
                        //     swatches: newSwatches
                        // }
                        // await updatePalette(palette_id, newPal);
                    }}
                    
                />;

                return swatch;

            })}
        </div>
    
    return <Main noLeftMenu>
        <div className='images-viewbox'>

            <div className='top-header'>
                <span className='left-section'>
                </span>
                <span className='right-section'>
                    Fitment:&nbsp;&nbsp;
                    {OBJECT_FITS.map(fitment => <span className={`view-button ${objectFit === fitment.key ? 'sel' : ''}`} key={fitment.key} onClick={() => setObjectFit(fitment.key)}>
                        {fitment.label}
                    </span>
                    )}

                    View Size:&nbsp;&nbsp;
                    {VIEW_SIZES.map(size => <span className={`view-button ${viewSize === size.key ? 'sel' : ''}`} key={size.key} onClick={() => setViewSize(size.key)}>
                        {size.label}
                    </span>
                    )}
                
                </span>
            </div>

            <div className='actions-header'>
                <Popup modal trigger={
                    <span className='btn action'>
                        <i className='fal fa-plus' />&nbsp; Upload Image(s)...
                    </span>
                }>
                    {close => <NewImage close={close} />}
                </Popup>
            </div>

            {content}

        </div>
    </Main>;


    
}


