import { useState } from "react";
import Popup from "reactjs-popup";
import { useRecoilValue } from "recoil";
import { useContentItem } from "../../../hooks/useContentItem";
import { computeContextState } from "../../content-mstudio/ComputeContext";
import "./EditCRSyntax.scss";
import { EditJsSyntaxBox } from "./EditJsSyntaxBox";
import { EditRSyntaxBox } from "./EditRSyntaxBox";


export const EditJsSyntaxWrapper = (props) => {
    const id = props.id;
    const [item] = useContentItem(id);
    console.log("item", item);
    return <EditJsSyntax item={item} syntax={item?.jdata?.syntax} {...props} />;
}

export const EditJsSyntax = (props) => {

    const [popupExpanded, setPopupExpanded] = useState(false);

    const item = props.item;
    const cHook = item?.jdata?.contextHooks?.[0];
    const ccState = useRecoilValue(computeContextState(cHook?.id));

    if( !item ) return null;

    return <div>
        <Popup
            modal
            closeOnEscape={false}
            closeOnDocumentClick={false}
            contentStyle={{
                width: "100%",
                height: "100%",
                background: "transparent"
            }}
            trigger={
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        border: "1px solid #cfcfcf",
                        width: "100%",
                        padding: "5px 10px",
                        userSelect: "none"
                    }}
                >
                    <span style={{ display: "inline-block", marginRight: 10 }}>
                        {props.placeholder || "[syntax]"}
                    </span>
                    <span style={{ float: "right" }}>
                        <i className='fal fa-ellipsis-h'/>
                    </span>
                </div>
            }
        >
            {closeModal => 
                <EditJsSyntaxBox
                    title={"Syntax"}
                    item={item}
                    syntax={props.syntax}
                    resources={props.resources}
                    payload={ccState}
                    onChange={props.onChange}
                    close={closeModal}
                />}
        </Popup>
    </div>


}

