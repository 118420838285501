import * as d3Format from 'd3-format';
import * as d3Color from 'd3-color';
import { getProperty } from 'dot-prop';
import { isObj } from '../../utils/ContentProcessor';
import { getExcelValueFormat } from './DataTabelValueFormat';
import { getAlignment, getColumnWidth } from './DataTableHelper';

const isNull = (x) => x === null || x === undefined;

const ifNull = (x, fallback) => isNull(x) ? fallback : x;

const getRowValue = (row, key) => {
    try{
        return getProperty(row, key);
    }
    catch{
        return row?.[key];
    }
}

export const DataTableCell = (props) => {

    const { row, col } = props;
    const { lastInCategory, lastCategory } = props;

    let val = getRowValue(row, col.key);
        
    let valStr;
    let cellStyle={
        width: getColumnWidth(col),
        minWidth: col.width,
        textAlign: getAlignment(col),
        ...col.style,
        ...col.cellStyle
    }

    let fillColor = col.fillColor;
    if( col.fillColorKey ){
        fillColor = getProperty(row, col.fillColorKey);
    }
    if( fillColor ){
        cellStyle.backgroundColor = fillColor;
    }

    if( lastInCategory && !lastCategory ){
        cellStyle.borderRight = "2px solid #cfcfcf";
    }

    // if((colIdx === cat.columns.length - 1) && (catIdx < categories.length - 1)){
    //     cellStyle.borderRight = "2px solid #cfcfcf";
    // }
    
    let cellClasses = "";
    let title = null;
    let n = null;
    let markup = null;

    if (col.type === "string") {
        if (val === null) valStr = <i>null</i>;
        else if (val === undefined) valStr = null;
        else if (isObj(val)) valStr = "[object]";
        else valStr = val;
    }
    else if (col.type === "boolean") {
        if (val === null) valStr = <i>null</i>;
        else if (val === undefined) valStr = <i>undefined</i>;
        else if (val === true) valStr = <i>true</i>;
        else if (val === false) valStr = <i>false</i>;
        else valStr = <i>error</i>;
    }
    else if (col.type === "number") {
        
        if (isNaN(val) || val === null || val === undefined) {
            valStr = ".";
        }
        else if (isObj(val)) valStr = "[object]";
        else {
            valStr = col.valueFormat ? d3Format.format(col.valueFormat)(val) : val;
        }
    }
    else if( col.type === "percentbar" ){

        const colWidth = getColumnWidth(col);
        const barWidth = colWidth - 60;
        const barHeight = 20;
        const barColor = "#3264c8";

        let valStr = null;
        let valWidth = null;
        if (isNaN(val) || val === null || val === undefined) {
            valStr = ".";
        }
        else if (isObj(val)) {
            valStr = "[object]";
        }
        else {
            valWidth = barWidth * val;
            const valueFormat = col.valueFormat || ".0%";
            valStr = d3Format.format(valueFormat)(val);
        }


        const bar = valWidth ? <div style={{
                display: "inline-block",
                position: "absolute",
                left: 0,
                top: 0,
                backgroundColor: barColor,
                width: valWidth,
                height: barHeight,
                margin: 0
            }}/> : null;
        

        return <td className={`cell even-padding percent-bar`} key={col.key} style={cellStyle} title={title}>
            <div className="bar-cell">
                <div style={{ 
                    //border: "1px solid #000000",
                    backgroundColor: "#efefef",
                    padding: 0,
                    margin: 0,
                    width: barWidth,
                    height: barHeight,
                    position: "relative"
                }}>
                    {bar}
                </div>
            </div>
            <div className="value-cell">
                {valStr}
            </div>
        </td>;

        // if (isNaN(val) || val === null || val === undefined) {
        //     valStr = ".";
        // }
        // else if (isObj(val)) valStr = "[object]";
        // else {
        //     valStr = col.valueFormat ? d3Format.format(col.valueFormat)(val) : val;
        // }

    }
    else if( col.type === "bar" ){

        const colWidth = getColumnWidth(col);
        const barWidth = colWidth - 60;
        const barHeight = 20;
        let barColor = col.barColorKey ? getProperty(row, col.barColorKey) :
            col.barColor || "#3264c8";

        let valStr = null;
        let valWidth = null;
        if (isNaN(val) || val === null || val === undefined) {
            valStr = ".";
        }
        else if (isObj(val)) {
            valStr = "[object]";
        }
        else {
            valWidth = barWidth * (val / col.maxVal);
            const valueFormat = col.valueFormat || "0.0";
            valStr = d3Format.format(valueFormat)(val);
        }


        return <td className={`cell even-padding scaled-bar`} key={col.key} style={cellStyle} title={title}>
            <div style={{
                display: "flex"
            }}>
                <span className="bar-cell" style={{
                    display: "inline-block",
                    height: barHeight,
                    width: valWidth,
                    backgroundColor: barColor,
                    top: 0,
                    lineHeight: `${barHeight}px`
                }}>
                    
                </span>
                <span className="value-cell" style={{
                    display: "inline-block",
                    height: barHeight,
                    top: 0,
                    lineHeight: `${barHeight}px`
                }}>
                    {valStr}
                </span>
            </div>
        </td>;


    }
    else if (col.type === "comet" || col.type === "dumbbell") {

        const childCols = col.cols;
        const stackWidth = childCols.reduce((acc, col) => acc + getColumnWidth(col), 0);

        const v1 = getRowValue(row, childCols?.[0]?.key);
        const v2 = getRowValue(row, childCols?.[1]?.key);

        const max = ifNull(col.maxVal,1);
        const min = ifNull(col.minVal,0);
        const range = max - min;
        //console.log("min, max, range", min, max, range);

        const pct1 = !isNull(v1) ? (v1 - min) / range : null;
        const pct2 = !isNull(v2) ? (v2 - min) / range : null;

        // console.log("v1, v2", v1, v2, row?.m?.label);
        // console.log("pct1, pct2", pct1, pct2);

        const r = 3;

        // pad left and right using radius so a value of zero (or max) doesn't chop circle in half
        const pad = r + 1; // +1 for border width
        const stackWidth2 = stackWidth - (pad * 2.0);

        const x1 = !isNull(pct1) ? pad + (pct1 * stackWidth2) : null;
        const x2 = !isNull(pct2) ? pad + (pct2 * stackWidth2) : null;
        
        const streakFill = x1 > x2 ? "#ec7a08" : "#82c341";
        const dumbbell_connector = "#afafaf";

        const color1 = childCols?.[0]?.fill || "#000000";
        const color2 = childCols?.[1]?.fill || "#000000";

        const style = {
            //border: "1px dashed red"
            //backgroundColor: "#ffffff"
            //borderLeft: "1px solid #dfdfdf",
            //borderRight: "1px solid #dfdfdf"
        };

        const track = <>
            <line x1={0} y1={10} x2={stackWidth} y2={10} stroke={"rgba(0,0,0,0.15)"} strokeDasharray="2,1" strokeWidth={1} />
        </>

        if( col.type === "comet" ){
            valStr = !isNull(x1) && !isNull(x2) &&
                <svg width={stackWidth} height={20} style={style}>
                    {track}
                    <polygon points={`${x1},10 ${x2},7 ${x2},13, ${x1},10`}
                        fill={streakFill}
                        />
                    <ellipse cx={x2} cy={10} rx={r} ry={r} fill={"black"} />
                </svg>;
        }
        else{
            valStr = <svg width={stackWidth} height={20} style={style}>
                {track}
                {!isNull(x1) && !isNull(x2) && 
                    <line 
                        x1={x1} y1="10" x2={x2} y2="10" 
                        style={{ stroke: dumbbell_connector, strokeWidth: 2.5 }} 
                    />
                }
                {!isNull(x1) && <ellipse cx={x1} cy={10} rx={r} ry={r} fill={color1} />}
                {!isNull(x2) && <ellipse cx={x2} cy={10} rx={r} ry={r} fill={color2} />}
            </svg>
        }
        
        return <td 
            className={`cell ${col.type}`} 
            key={col.key} 
            style={cellStyle} 
            title={title}
            colSpan={col.cols?.length}
        >
            {valStr}
            {markup ? <span className="markup">{markup}</span> : null}
            {n ? <span className="n-size">{n}</span> : null}
        </td>
        
        
    }
    else {
        if (val === null) valStr = <i>null</i>;
        else if (val === undefined) valStr = null;
        else if (isObj(val) && props.smartObj) {
            const sObj = toSmartObj(val);
            valStr = sObj?.content;
            title = sObj?.title;
            if( sObj?.className ){
                cellClasses += " " + sObj?.className;
            }
            n = sObj?.n;
            markup = sObj?.markup;
        }
        else if (isObj(val)) valStr = JSON.stringify(val);
        else if (Array.isArray(val)) valStr = "[array]";
        else valStr = val;
    }
    return <td className={`cell ${cellClasses}`} key={col.key} style={cellStyle} title={title}>
        {valStr}
        {markup ? <span className="markup">{markup}</span> : null}
        {n ? <span className="n-size">{n}</span> : null}
    </td>

}


const defaultValFmt = d3Format.format(",.4f");

export const toSmartObj = (obj) => {
    if( !obj ) return null;
    if( obj.val !== undefined ) {
        try{
            const fmt = obj.format ? d3Format.format(obj.format) : defaultValFmt;
            return {
                className: "val-obj",
                title: JSON.stringify(obj, null, 4), // `n=${obj.n}`,
                content: (obj.val === null ? "." : fmt(obj.val)),
                n: obj.n
            }
            ;
        }
        catch{
            // value format probably failed
            return {
                className: "val-obj",
                //title: JSON.stringify(obj, null, 4),// `n=${obj.n}`,
                content: obj.val,
                n: obj.n
            }
        }
    }
    if( obj.label !== null && obj.label !== undefined && obj.id ){
        return {
            className: "label-obj",
            title: JSON.stringify(obj, null, 4), // `id=${obj.id}`,
            content: obj.label,
            markup: obj.syntax
        }
        ;
    }
    return {
        className: "generic-obj",
        content: JSON.stringify(obj)
    }
}




export const ExportDataTableCell = (xcell, obj) => {

    const { row, col } = obj;
    const { lastInCategory, lastCategory } = obj;

    let val = getProperty(row, col.key);

    const hAlign = getAlignment(col);
    xcell.alignment = { vertical: 'center', horizontal: hAlign };

    let fillColor = col.fillColor;
    if( col.fillColorKey ){
        fillColor = getProperty(row, col.fillColorKey);
    }
    if( fillColor ){
        const c = "FF" + d3Color.color(fillColor)?.formatHex().substring(1);
        xcell.fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{ argb: c }
        };
    }
        
    if( lastInCategory && !lastCategory ){
        xcell.border = {
            ...xcell.border,
            right: {
                style: "thin",
                color: { argb: "ffcfcfcf" }
            }
        }
    }
    
    if (col.type === "string") {
        if (val === null){ }
        else if (val === undefined){ }
        else if (isObj(val)) xcell.value = "[object]";
        else xcell.value = val;
    }
    else if (col.type === "number") {
        xcell.alignment.indent = 1;
        
        if (isNaN(val) || val === null || val === undefined) {
            xcell.value = ".";
        }
        else if (isObj(val)) xcell.value = "[object]";
        else {
            xcell.value = val;
            xcell.numFmt = getExcelValueFormat(col.valueFormat)
        }
    }
    else {
        
        if (val === null){ }
        else if (val === undefined){ }
        else if (isObj(val) && obj.smartObj) {
            const sObj = toSmartObj(val);
            xcell.value = sObj?.content;
            // title = sObj?.title;
            // if( sObj?.className ){
            //     cellClasses += " " + sObj?.className;
            // }
            // n = sObj?.n;
            // markup = sObj?.markup;
        }
        else if (isObj(val)) xcell.value = JSON.stringify(val);
        else if (Array.isArray(val)) xcell.value = "[array]";
        else xcell.value = val;
    }
    // return <td className={`cell ${cellClasses}`} key={col.key} style={cellStyle} title={title}>
    //     {valStr}
    //     {markup ? <span className="markup">{markup}</span> : null}
    //     {n ? <span className="n-size">{n}</span> : null}
    // </td>

}