import Editor from "@monaco-editor/react";
import { useEffect, useState } from "react";
import "./CodeEditor.scss";

//import { debounce } from "../../utils/debounce";

// maybe I shouldn't debounce this!
// const debouncedOnChange = debounce((obj, func) => {
//     //console.log('debounced onChange', obj);
//     func?.(obj);
// }, 1000);

export const ControlledCodeEditor = (props) => {
    const language = props.language || "javascript";
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    return <div className='code-editor'>
        {props.codeStart ?
            <div className='code-start'>{props.codeStart}</div>
            : null
        }
        <Editor
            className='editor'
            defaultLanguage={language}
            defaultValue={value}
            height={props.height || 300}
            width={props.width || 800}
            theme={props.theme}
            options={{
                lineNumbers: "off",
                folding: false,
                wordWrap: "bounded",
                minimap: {
                    enabled: false
                }
            }}
            
            onChange={(v) => {
                setValue(v);
                //debouncedOnChange(v, props?.onChange);
                props?.onChange(v);
            }}
        />
        {props.codeEnd ?
            <div className='code-end'>{props.codeEnd}</div>
            : null
        }
    </div>;
}

// onChange={handleEditorChange}
// onMount={handleEditorDidMount}
// beforeMount={handleEditorWillMount}
// onValidate={handleEditorValidation}

export const ControlledCodeEditorFillContainer = (props) => {
    const language = props.language || "javascript";
    const [value, setValue] = useState(props.value);
    const editorProps = props.editorProps;
    const message = props.message;
    //const monaco = useMonaco();

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    // useEffect(() => {
    //     console.log('monaco instance', monaco);
    //     if (!monaco) return;

    //     monaco.languages.registerCompletionItemProvider('yak', {
    //         provideCompletionItems: () => {
    //             var suggestions = [{
    //                 label: 'simpleText',
    //                 kind: monaco.languages.CompletionItemKind.Text,
    //                 insertText: 'simpleText'
    //             }, {
    //                 label: 'testing',
    //                 kind: monaco.languages.CompletionItemKind.Keyword,
    //                 insertText: 'testing(${1:condition})',
    //                 insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
    //             }, {
    //                 label: 'pctinrange',
    //                 kind: monaco.languages.CompletionItemKind.Snippet,
    //                 insertText: [
    //                     'pctinrange(${1:expr},${2:min},${3:max})',
    //                 ].join('\n'),
    //                 insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    //                 documentation: 'Percent with range (inclusive of min and max)'
    //             }];
    //             return { suggestions: suggestions };
    //         }
    //     });

    // }, [monaco])

    return <div className='code-editor' style={{ width: "100%", height: "100%", position: "relative", ...props.style }}>
        <Editor
            {...editorProps}
            //className='editor'
            defaultLanguage={language}
            theme={props.theme}
            options={{
                ...editorProps?.options,
                tabSize: 4,
                // lineNumbers: num => num < 10 ? `asdf${num}` : null,
                // lineNumbersMinChars: 50,
                // glyphMargin: false
                //readOnly: (message ? true : false) // seems to cause cross-origin error
            }}
            value={value}
            height={"100%"}
            width={"100%"}
            disabled
            onChange={(v) => {
                setValue(v);
                //debouncedOnChange(v, props?.onChange);
                props.onChange?.(v);
            }}
            onMount={ev => props.onMount?.(ev)}
        />
        {message && <div style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.1)",
            userSelect: "none"
        }}>
            {message}
        </div>
        }
    </div>;
}