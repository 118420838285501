import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import { ContentItemContext, PageContext, UrlRootContext } from "../../context/Contexts";
import { usePage, usePages, usePagesApi } from "../../hooks/usePage";
import { useProject } from "../../hooks/useProject";
import { useProjectId } from "../../hooks/useProjectId";
import { LeftMenu } from "../nav/LeftMenu";
import { getTypeInfo } from "../page-content/ContentTypes";
import { EditPageProps } from "../page-content/edit/EditPageProps";
import { LoadParentContext } from "./EditPageStack";
import { NewPage } from "./NewPage";
import { PageListItem } from "./PageListItem";
import { Main } from "../nav/Main";
import "./Pages.scss";
import { RightMenu } from "../nav/RightMenu";
import { ReactSortable } from "react-sortablejs";


const TABS = [
    { key: "pages", label: "Pages" },
    //{ key: "tree", label: "Page Layout Tree" },
]

export const PageEditorContext = React.createContext('page-editor');

export const Pages = (props) => {

    
    const history = useHistory();
    const routerParams = useParams();
    const { project_id, subscription_id } = useProjectId();

    let selectedPageId = routerParams.page_id;
    let selectedMenuId = routerParams.menu_id;
    let selectedTabKey = routerParams.tab_id;
    if( !selectedTabKey ){
        if( selectedPageId ){
            selectedTabKey = "pages";
        }
        else if( selectedMenuId ){
            selectedTabKey = "menus"
        }
        else{
            selectedTabKey = TABS[0].key
        }
    }

    const api = usePagesApi();
    const [project, projectLoading, projectError] = useProject();
    const [pages, pagesLoading, pagesError] = usePages();

    // set selected page to a default value if not specified
    if (!selectedPageId && !pagesLoading && pages?.[0]?.id) {
        selectedPageId = pages[0].id;
    }

    const dispatch = useDispatch();
    const showUrl = useSelector(state => state.pages.showUrl);

    const [hideHover, setHideHover] = useState(false);

    const [selContentItem, setSelContentItem] = useState(null);
    const [selItemContext, setSelItemContext] = useState(null);
    const ctypeInfo = getTypeInfo(selContentItem?.["ctype"]);
    const PropertyEditor = ctypeInfo?.propertyEditor;

    const [isDev, setIsDev] = useState(true);
    
    const [frameBoxing, setFrameBoxing] = useState(false);

    const [selectedPage, selectedPageLoading, selectedPageError] = usePage(selectedPageId);
        
    const orderedPages = pages; // they are already ordered from api call

    const savePageOrder = async (ids) => {
    }

    const rootContext = {
        project: { ...project }
    }
    delete rootContext.project.settings_str;
    delete rootContext.project.schema_str;
    delete rootContext.project.loading;
    delete rootContext.project.error;

    

    const setSelectedContentItem = (content_item, context, options) => {
        setSelItemContext(context);
        setSelContentItem(content_item);
        if( options?.openSyntax ){
            // eh!?!?
        }
        if(content_item){
            dispatch({ type: "RIGHT_MENU_OPEN_SET", value: true });
        }
        else{
            dispatch({ type: "RIGHT_MENU_OPEN_SET", value: false });
        }
    }

    return <PageEditorContext.Provider value={{
        setSelectedContentItem,
        frameBoxing,
        setFrameBoxing,
        isDev
    }}>
        
        <LeftMenu v2>
            <div className="tab-section">
                {TABS.map(tab => 
                    <Link 
                        key={tab.key}
                        to={`/subscription/${subscription_id}/project/${project_id}/pages/${tab.key}`}
                        className={`tab ${tab.key === selectedTabKey ? "sel" : ""}`}>
                        {tab.label}
                    </Link>
                )}
            </div>
            {/* <div className='title'>
                Pages &amp; Components
            </div> */}
            <div className='top-section' style={{ padding: "0 20px 5px 20px", textAlign: "right" }}>
                <span style={{ fontSize: "8pt" }}>
                    Use data:&nbsp;
                </span>
                <span className={`btn toggle-small ${isDev ? "sel" : ""}`}
                    onClick={() => setIsDev(true)}>
                        dev
                </span>
                <span className={`btn toggle-small ${isDev ? "" : "sel"}`}
                    onClick={() => setIsDev(false)}>
                        prod
                </span>
                {/* <div className='tab-section'>
                    {TABS.map((tab) => {
                        return <span key={tab.id} className={`tab ${tab.id === selTab ? 'sel' : ''}`} onClick={() => setSelTab(tab.id)}>
                            {tab.icon ? <i className={tab.icon}/> : null} {tab.label}
                        </span>
                    })}
                </div> */}
            </div>
            
            <div className='scroller'>
                <div className='summary'>
                    <p>
                        Pages should be organized to reflect URL structure. Pages need not
                        match menus or menu ordering, but they can. Select a page to edit the content. Use
                        ellipsis for additional page actions.
                    </p>
                </div>
                <div className='action-bar-sticky'>

                    <span className={`btn toggle margin-right`}
                        style={{
                            padding: "7px 7px"
                        }}
                        onClick={() => {
                        // todo: expand all or collapse all
                        //dispatch({ type: "PAGES_SET_MISC_VALUE", name: "showUrl", value: !showUrl });
                    }}>
                        <i className='fal fa-plus-circle'/>
                    </span>

                    <span className={`btn toggle ${showUrl ? "sel" : ""}`} onClick={() => {
                        dispatch({ type: "PAGES_SET_MISC_VALUE", name: "showUrl", value: !showUrl });
                    }}>
                        Show Urls
                    </span>

                    <Popup
                        modal
                        trigger={
                            <div className="btn action float-right" >
                                <i className='glyph fal fa-plus' /> New page
                            </div>
                        }>
                        {close => <NewPage close={close} createPage={async (page) => {
                            const newInfo = await api.newItem(page);
                            history.push(`/subscription/${subscription_id}/project/${project_id}/page/${newInfo.newId}`);
                        }}/>}
                    </Popup>
                </div>
                
                {pagesError ? <div className='list-something error'>Error loading pages</div> :
                pagesLoading ? <div className='list-content'>Loading...</div> :
                !pages ? null :
                    <ReactSortable
                        animation={200}
                        delayOnTouchStart={true}
                        delay={2}
                        handle={".grip"}
                        list={orderedPages}
                        setList={(newList) => {
                        }}
                        onStart={() => setHideHover(true)}
                        onEnd={() => {
                            // todo: save

                            setHideHover(false);
                        }}
                    >
                        {orderedPages.map(page => <PageListItem
                            key={page.id}
                            project_id={project_id}
                            page_id={page.id}
                            selectedId={selectedPageId}
                            hideHover={hideHover}
                            onPageSelected={() => {
                                setSelContentItem(null);
                            }}
                            />)}
                        
                    </ReactSortable>
                }
                
            </div>
        </LeftMenu>

        <RightMenu
            open={selContentItem ? true : false}
            className={`${selContentItem ? 'open' : 'collapsed'}`}>

            <div className='title'>
                Edit {ctypeInfo?.label} Properties
                <span className='right'>
                    <span className='btn icon' onClick={() => {

                        setSelectedContentItem(null)
                    }}>
                        <i className='fal fa-times'/>
                    </span>
                </span>
                <div style={{ fontSize: 9, color: "#a0a0a0" }}>{selContentItem?.id || selectedPageId}</div>
            </div>
            <div className='top-section'>
                <div>
                
                </div>
            </div>
            
            <div className='scroller'>
                <div className='summary'>
                    <p>
                        Edit properties for the selected content item here. Click the
                        save button to save your changes. Click the close
                        button (top-right) to cancel and/or close this window. Have a nice day.
                    </p>
                </div>
                <div className='action-bar-sticky'>
                </div>
                
                <div>
                    {PropertyEditor ?
                        <ContentItemContext.Provider value={selItemContext}>
                            <PageContext.Provider value={selectedPage}>
                                {PropertyEditor ? <PropertyEditor
                                    content_item={selContentItem}
                                    content_item_id={selContentItem?.id}
                                /> : null}
                            </PageContext.Provider>
                        </ContentItemContext.Provider>
                        : null
                    }
                </div>    
            </div>

        </RightMenu>

        <Main v2>
            <UrlRootContext.Provider value={{ url: `/subscription/${subscription_id}/project/${project_id}/page`, format: "id" }}>
                <ContentItemContext.Provider value={rootContext}>
                    {selectedPageLoading ? <div>Loading...</div>
                        : selectedPageError ? <div>Error loading selected page</div>
                        : !selectedPage ? null :
                        <LoadParentContext key={selectedPageId} page={selectedPage} />}
                </ContentItemContext.Provider>
            </UrlRootContext.Provider>
        </Main>

    </PageEditorContext.Provider>;


    
}

const PrintTree = (props) => {
    const node = props.node;
    const indent = props.indent || 0;
    if (!node) return null;
    return <div style={{ marginLeft: indent * 10}}>
        {node.label || `[${getTypeInfo(node._ctype)?.label}]` || node.id}
        {node.children?.map(child => {
            return <PrintTree node={child} indent={indent + 1}/>
        })}
    </div>

}


