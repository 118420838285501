import { useContext, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useDispatch } from "react-redux";
//import SplitterLayout from "react-splitter-layout";
import SplitterLayout from "../../extlib/react-splitter-layout/SplitterLayout";
import { computeApi } from "../../../api/computeApi";
import { ContentItemContext, HasComputeContext, HasDataSourcesContext, HasPipelineContext, HasPipelinesContext, HasRenderContext, HasRenderTableContext } from "../../../context/Contexts";
import { useAuth } from "../../../hooks/useAuth";
import { useLazyLib } from "../../../hooks/useLazyLib";
import { useProjectId } from "../../../hooks/useProjectId";
import { compileFunction } from "../../../utils/Compiler";
import { runRender } from "../../../utils/ContentProcessor";
import { defaultLib } from "../../../utils/Lib";
import { DataTable } from "../../common/DataTable";
import { ErrorBoundary } from "../../error-boundary/ErrorBoundary";
import { EditComputeSyntax } from "./EditComputeSyntax";
import { truncateObjectAtLevel } from "./EditCRSyntaxBox";
import { EditPipelines } from "../../pipeline/EditPipelines";
import "./EditCRSyntaxBox2.scss";

import { EditRenderSyntax } from "./EditRenderSyntax";
import { EditRenderTableSyntax } from "./EditRenderTableSyntax";
import { EditPipeline } from "../../pipeline/EditPipeline";
import { useDataSources } from "../../../hooks/useDataSource";
import { EditPipelineWithPreview } from "../../pipeline/EditPipelineWithPreview";

const CODE_TABS = [
    { id: "pipeline", label: "Pipeline" },
]

const HELPER_TABS = [
    { id: "context", label: "Context" },
    { id: "lib", label: "Lib" },
    { id: "resources", label: "Resources" },
]

export const EditCPipelineBox = (props) => {

    const { subscription_id, project_id } = useProjectId();
    const auth = useAuth();
    const dispatch = useDispatch();

    const [dsList, dsListLoading, dsListError, dsListRefresh] = useDataSources();
    const dsContextValue = {
        dsList,
        dsListLoading,
        dsListError,
        dsListRefresh,
    };

    const lib2 = useLazyLib();
    const lib = {
        ...defaultLib,
        ...lib2
    };
    
    const getLibHack = () => truncateObjectAtLevel(lib, 1);
    

    const [pipeline, setPipeline] = useState(props.pipeline);

    const [payload, setPayload] = useState({ computing: false, needsCompute: false, result: null, status: null });

    const [selCodeTab, setSelCodeTab] = useState("pipeline");
    const [selHelperTab, setSelHelperTab] = useState("data");
    
    const rResources = props.resources;

    const [expanded, setExpanded] = useState(false);

    const context = useContext(ContentItemContext);
    
    useEffect(() => {
        setPipeline(props.pipeline);
    }, [props.pipeline])

    const env = {
        resources: rResources,
        context: context
    };

    // const runCompute = async () => {

    //     const p = pipeline;

    //     const stages = p.stages
    //         ?.filter(s => !s.disabled)
    //         ?.map(s => s.data);
        
    //     const revisionId = "__dev";
    //     const r1 = await computeApi.computeV2(
    //         auth, 
    //         { ...env }, 
    //         subscription_id, 
    //         project_id, 
    //         p.dataSourceId, 
    //         revisionId, 
    //         stages, 
    //         { 
    //             limit: 100 // cursor limit
    //         }
    //     );
    //     return r1;

    // }

    // compute stages
    // useEffect(() => {

    //     if( !pipeline ) return;
        
    //     (async () => {
    //         setPayload({ ...payload, computing: true });
    //         try{
    //             const cres = await runCompute();
    //             setPayload({ ...payload, computing: false, result: cres });
    //         }
    //         catch(err){
    //             console.error(err);
    //             setPayload({ ...payload, computing: false, error: err });
    //         }
    //     })();

    // }, [pipeline])

    // const api = {
    //     compute: runCompute
    // };

    const saveMe = () => {
        props.onChange?.(pipeline)
    }

    const context1 = {
        pipeline,
        setPipeline
    }


    // const cContext = {
    //     compute,
    //     setCompute
    // }

    // const rContext = {
    //     render,
    //     setRender
    // }

    // const rtContext = {
    //     renderTable,
    //     setRenderTable
    // }
   

    return <HasPipelineContext.Provider value={context1}>
        <HasDataSourcesContext.Provider value={dsContextValue}>
            <div className="edit-crsyntax-box2">
                <div className={`frame1 ${expanded ? "expanded" : ""}`}>
                    <div className="titlebar full-dock">

                        <div className="center xtab-section">
                            {/* Title goes here */}
                        </div>

                        <span className="buttons right">
                        <span className="btn icon" onClick={() => saveMe()}>
                                <i className="fas fa-save" />
                            </span>
                            <span className="btn icon" onClick={() => setExpanded(!expanded)}>
                                <i className="fal fa-expand" />
                            </span>
                            <span className="btn icon" onClick={() => props.close?.()}>
                                <i className="fal fa-times" />
                            </span>
                        </span>

                    </div>
                    <div className="main">
                        <div className="all" >
                            <SplitterLayout primaryIndex={0}>
                                <div className="full-dock fill-parent-absolute">
                                    <div className="top tab-section no-margin">
                                        {CODE_TABS.map(tab =>
                                            <span 
                                                key={tab.id}
                                                className={`tab ${selCodeTab === tab.id ? "sel" : ""}`}
                                                onClick={() => setSelCodeTab(tab.id)}
                                            >
                                                {tab.label}
                                            </span>
                                        )}
                                    </div>
                                    <div className="center fill-parent-absolute" style={{ overflow: "hidden" }} >
                                        {selCodeTab === "compute" ? <EditComputeSyntax /> : null}
                                        {selCodeTab === "pipelines" ? <EditPipeline showRevisionId={true} /> : null}
                                        {selCodeTab === "pipeline" ? <EditPipelineWithPreview /> : null}
                                        {selCodeTab === "settings" ? <div>
                                            edit settings
                                        </div> : null}
                                        {selCodeTab === "render" ? <EditRenderSyntax /> : null}
                                        {selCodeTab === "table-specs" ? <EditRenderTableSyntax /> : null}
                                    </div>
                                </div>
                                {/* <div className="full-dock fill-parent-absolute">
                                    <div className="top tab-section no-margin">
                                        {HELPER_TABS.map(tab =>
                                            <span 
                                                key={tab.id}
                                                className={`tab ${selHelperTab === tab.id ? "sel" : ""}`}
                                                onClick={() => setSelHelperTab(tab.id)}
                                            >
                                                {tab.label}
                                            </span>
                                        )}
                                    </div>
                                    <div className="center full-dock fill-parent-absolute">
                                        {payload.computing &&
                                            <div className="top compute-running">
                                                Computing...
                                            </div>
                                        }
                                        <div className="center">
                                            {selHelperTab === "rendered" && 
                                                <ErrorBoundary>
                                                    {rendered}
                                                </ErrorBoundary>
                                            }
                                            {selHelperTab === "renderedTable" && 
                                                <ErrorBoundary>
                                                    {renderedTable}
                                                </ErrorBoundary>
                                            }
                                            {selHelperTab === "data" && 
                                                <div className="json-wrap">
                                                    <ReactJson
                                                        src={payload.result || undefined}
                                                        name={"result"}
                                                        theme={"rjv-default"}
                                                        iconStyle="circle"
                                                        displayDataTypes={false}
                                                        enableClipboard={true}
                                                        collapsed={1}
                                                        />
                                                </div>
                                                // pipelines?.map(p => {
                                                //     const res = computeResult?.[p.id];
                                                //     return <ViewRecords result={res} stages={{ todo: 1 }} />;
                                                // })
                                                
                                            }
                                            {selHelperTab === "context" ? 
                                                <div className="json-wrap">
                                                    <ReactJson src={context}
                                                        name={"context"}
                                                        theme={"rjv-default"}
                                                        iconStyle="circle"
                                                        displayDataTypes={false}
                                                        enableClipboard={true}
                                                        collapsed={1}
                                                    />
                                                </div>
                                            : selHelperTab === "resources" ? 
                                                <div className="json-wrap">
                                                    <ReactJson src={props.resources}
                                                        name={"resources"}
                                                        theme={"rjv-default"}
                                                        iconStyle="circle"
                                                        displayDataTypes={false}
                                                        enableClipboard={true}
                                                        collapsed={1}
                                                        />
                                                </div>
                                            : selHelperTab === "api" ? 
                                                <div className="json-wrap">
                                                    <ReactJson src={{}}
                                                        name={"api"}
                                                        theme={"rjv-default"}
                                                        iconStyle="circle"
                                                        displayDataTypes={false}
                                                        enableClipboard={true}
                                                        collapsed={1}
                                                    />
                                                </div>
                                            : selHelperTab === "lib" ?
                                                <div className="json-wrap">
                                                    <ReactJson
                                                        src={getLibHack()}
                                                        name={"lib"}
                                                        theme={"rjv-default"}
                                                        iconStyle="circle"
                                                        displayDataTypes={false}
                                                        enableClipboard={true}
                                                        collapsed={1}
                                                        />
                                                </div>
                                            : null
                                            }



                                        </div>
                                    </div>
                                </div> */}
                            </SplitterLayout>
                        </div>
                    </div>
                    
                </div>
            </div>
        </HasDataSourcesContext.Provider>
    </HasPipelineContext.Provider>;

}

