import { useEffect, useState } from "react";
import { useImage, useImagesApi } from "../../hooks/useImage";
import { getImagePath } from "../../utils/ImageUtils";
import { ModalFrame } from "../modal-dialog/ModalFrame";


const imgStyle = {
        width: 430,
        height: 360,
        objectFit: "contain"
}
    


export const ImageEditor = (props) => {

    const subscription_id = props.subscription_id;
    const project_id = props.project_id;
    const image_id = props.image_id;

    
    const api = useImagesApi();
    const [image, imageLoading, imageError] = useImage(image_id);

    const imagePath = getImagePath(subscription_id, project_id, image?.filename);

    const [label, setLabel] = useState(image?.label);
    const id = image_id;

    useEffect(() => {
        setLabel(image?.label);
    }, [image])
    

    return <ModalFrame title="Edit Image"
        buttons={[
            {
                label: "Save",
                callback: async () => {

                    const newImage = {
                        ...image,
                        label: label
                    }
                    await api.updateItem(image_id, newImage)
                    props?.close();

                    // const newItem = {
                    //     label: newName,
                        
                    // }
                    // props?.onSave(newItem);
                }
            },
            
        ]}
        close={props.close}
    >
        <div className='form1'>

            <div style={{ display: "flex", rowGap: 15, columnGap: 15, marginBottom: 15 }}>

                <div style={{ width: 450 }}>

                    <div style={{ flexGrow: "1" }}>
                        <div className="value-line" style={{
                            border: "1px solid #cfcfcf",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: 380
                        }}>
                            {image ? <img src={imagePath} style={imgStyle} /> : "..."}
                        </div>
                    </div>

                </div>

                <div style={{ width: 350 }}>

                    <div >
                        <label className="label-line">
                            <span className='code-font bold'>.label</span>:
                        </label>
                        <div className="value-line">
                            <input type="text"
                                autoFocus className='full-width'
                                placeholder="(e.g., good, bad, neutral, primary, comparison)"
                                value={label || ""}
                                onChange={(ev) => {
                                    setLabel(ev.target.value)
                                }} />
                        </div>
                    </div>

                    <div >
                        <label className="label-line">
                            <span className='code-font bold'>.id</span>:
                        </label>
                        <div className="value-line">
                            <input type="text"
                                className='full-width'
                                value={image?.id}
                                disabled
                            />
                        </div>
                    </div>

                    
                </div>

                

        
            </div>
            
    
            
        </div>


        
    </ModalFrame>
}