import "./Dashboard.scss";
import { RecentProjectsBlock } from "./RecentProjectsBlock";
import { RecentSubscriptionsBlock } from "./RecentSubscriptionsBlock";
import { UserSection } from "./UserSection";

export const Dashboard = (props) => {
   

    return <div className="tab-content top-dashboard-home">

        <div className="section recently-used">

            <RecentSubscriptionsBlock />

            <div className="recent-block">
                <div className="title">
                    <span className="label">
                        Activity
                    </span>
                </div>
                <div className="item-list">
                    {/* {recentSubscriptions.map(item =>
                        <div className="item">
                            <i className="fad fa-briefcase" />  {item.label}
                        </div>
                    )}
                    <div className="item view-all">
                        View all subscriptions...
                    </div> */}
                </div>
            </div>


        </div>

        <div className="section recent-activity">

            <RecentProjectsBlock />

            
        </div>

        <UserSection />

    </div>


}

