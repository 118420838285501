import { constructs } from "../api/contructs";
import { createSyncedApiHook } from "../api/createSyncedApiHook";
import { createUseChildItemHook, createUseChildItemsHook } from "./createUseChildItemsHook";
import { createUseItemHook, createUseItemsHook } from "./createUseItemsHook";

const construct = constructs["data-source-revision"];

export const useDataSourceRevision = createUseChildItemHook(construct);
export const useDataSourceRevisions = createUseChildItemsHook(construct);
//export const useDataSourceRevisionsApi = createSyncedApiHook(construct);

