import './DataTable.scss';
import * as d3Format from 'd3-format';
import React, { useState } from 'react';
import { isObj } from '../../utils/ContentProcessor';
import { getProperty } from 'dot-prop';
import { ExportDataTableCell } from './DataTableCell';
import { saveAs } from 'file-saver';

const DEFAULT_COL_WIDTH_NUMBER = 100;
const DEFAULT_COL_WIDTH_STRING = 200;
const DEFAULT_COL_WIDTH_PERCENTBAR = 200;
const DEFAULT_COL_WIDTH_BAR = 200;
const DEFAULT_COL_WIDTH = 100;
const DEFAULT_COL_WIDTH_COMET_SEGMENT = 100;

export const getColumnWidth = (col) => {
    if( col.width ) return col.width;
    if( col.type === "number" ) return DEFAULT_COL_WIDTH_NUMBER;
    else if( col.type === "string" ) return DEFAULT_COL_WIDTH_STRING;
    else if( col.type === "percentbar" ) return DEFAULT_COL_WIDTH_PERCENTBAR;
    else if( col.type === "bar" ) return DEFAULT_COL_WIDTH_BAR;
    else if( col.parent?.type === "comet" ) return DEFAULT_COL_WIDTH_COMET_SEGMENT;
    else return DEFAULT_COL_WIDTH;
}

export const getColumnType = (col) => {
    if( col.type ) return col.type;
    if( col.parent?.type === "comet" ) return "number";
    if( col.parent?.type === "dumbbell" ) return "number";
    return null;
}

export const needsMinMax = (col) => {

    // if already specified, don't need it
    if( !isNull(col.minVal) && !isNull(col.maxVal) ) return false;

    // some types need it
    return col.type === "bar" ||
        col.type === "comet" ||
        col.type === "dumbbell";
}

export const getAlignment = (col) => {
    if( col.alignment ) return col.alignment;
    if( col.type === "number" ) return "right";
    else if( col.type === "string" ) return "left";
    else if( col.type === "bar" ) return "left";
    else if( col.type === "object" ) return "left";
    else if( col.parent?.type === "comet" ) return "left";
    else if( col.parent?.type === "dumbbell" ) return "left";
    else return "center";
}

export const getHeaderAlignment = (col) => {
    const a = getAlignment(col)
    return (a === "right") ? "center" : a;
}

export const getColumnGlyph = (col) => {
    const ctype = col.type || col.parent?.type;
    if( ctype === "comet" ){
        const colIndex = col.parent?.cols?.indexOf(col.parent?.cols?.find(c => c.key === col.key));
        
        const r = 3;
        const r2 = colIndex === 1 ? r : 1;
        const color = colIndex === 1 ? "#000000" : "#afafaf";
        return <span className='th-glyph'>
            <svg width={r*2} height={r*2}>
                <ellipse cx={r} cy={r} rx={r2} ry={r2} fill={color} />
            </svg>
        </span>;
    }
    else if( ctype === "dumbbell" ){
        const color = col.fill || "#000000";
        const r = 3;
        return <span className='th-glyph'>
            <svg width={r*2} height={r*2}>
                <ellipse cx={r} cy={r} rx={r} ry={r} fill={color} />
            </svg>
        </span>;
    }
    return null;
}

export const isCellObj = (obj) => {
    if(!isObj(obj))
        return false;
    if( obj.val !== undefined && obj.n !== undefined ){
        return true;
    }
}

export const toCellJsx = (obj) => {
    return <span title={`n=${obj.n}`}>{obj?.val}</span>;
}

const valFmt = d3Format.format(",.4f");

export const toSmartObj = (obj) => {
    if( !obj ) return null;
    if( obj.val !== undefined ) {
        try{
            const fmt = obj.format ? d3Format.format(obj.format) : valFmt;
            return {
                className: "val-obj",
                title: JSON.stringify(obj, null, 4), // `n=${obj.n}`,
                content: (obj.val === null ? "." : fmt(obj.val)),
                n: obj.n
            }
            ;
        }
        catch{
            // value format probably failed
            return {
                className: "val-obj",
                //title: JSON.stringify(obj, null, 4),// `n=${obj.n}`,
                content: obj.val,
                n: obj.n
            }
        }
    }
    if( obj.label && obj.id ){
        return {
            className: "label-obj",
            title: JSON.stringify(obj, null, 4), // `id=${obj.id}`,
            content: obj.label,
            markup: obj.syntax
        }
        ;
    }
    return {
        className: "generic-obj",
        content: JSON.stringify(obj)
    }
}

export const isNull = x => x === null || x === undefined;

const inspectColumnType = (obj) => {
    if( typeof obj === "number" )
        return "number";
    return "string";
}

const inspectNumberFormat = (obj) => {
    if( Number.isInteger(obj) ) return ",.0f";
    return ".4f";
}

export const getObjectType = (obj) => {
    if( !obj ) return null;

    if( typeof(obj) === "object" ){
        return (
            obj === null ? null :
            obj === undefined ? null :
            Array.isArray(obj) ? "array" : 
            "object"
        );
    }
    else if( typeof(obj) === "string" ){
        return "string";
    }
    else if( typeof(obj) === "number" ){
        return "number";
    }
    else if( typeof(obj) === "boolean" ){
        return "boolean";
    }
}


const _inspectValue = (ikeys_dict, ikeys, key, val, valType, nestLevel) => {
    if( valType === "object" && nestLevel > 0 ){
        Object.entries(val).forEach(entry => {
            const childKey = entry[0];
            const childVal = entry[1];
            const childValType = getObjectType(childVal);
            _inspectValue(ikeys_dict, ikeys, key + "." + childKey, childVal, childValType, nestLevel - 1);
        });
    }
    else{
        const find = ikeys_dict[key];
        if( !find ){
            let inspection = {
                key,
                types: valType ? [valType] : []
            };
            ikeys_dict[key] = inspection;
            ikeys.push(inspection);
        }
        else{
            if( valType ){
                if( !find.types.includes(valType) ){
                    find.types.push(valType);
                }
            }
        }
    }
}

const _consolidateTypes = (types) => {
    if( !types?.length ) return null;
    if( types.length === 1 ) return types[0];
    
    // multiple types, what to do?
    //console.log("any", types);
    return "any"; // ??
}

const inspectColumns = (rows, nestLevel = 0) => {
    let inspected_keys_dict = {};
    let inspected_keys = [];
    rows?.forEach?.(row => {
        Object.entries(row).forEach(entry => {
            const key = entry[0];
            const val = entry[1];
            const valType = getObjectType(val);
            _inspectValue(inspected_keys_dict, inspected_keys, key, val, valType, nestLevel);
        })
    })
    
    // finalize types
    inspected_keys?.forEach(ikey => {
        ikey.type = _consolidateTypes(ikey.types);
        delete ikey.types;
    })

    return inspected_keys;
}

export const GetDataTableLayout = (props, state) => {

    const { sortState } = state;

    let data = props.data || props.rows || [];
    let columns = props.columns || props.cols || [];
    if( props.autoGenerateColumns || !columns ){
        const allowNest = props.allowNest;
        // inspect keys/types from all rows (limit row count maybe?)
        columns = data && inspectColumns(data, allowNest ? 1 : 0);
    }

    // if allowNest, convert columns to col/cats??
    if( props.allowNest ){
        //console.log("columns", columns);
        columns = columns.map(col => {
            let c = { ...col };
            if( col.key?.includes("." )){
                const idx = col.key.indexOf(".");
                c.category = col.key.substring(0, idx);
                c.label = col.key; // col.key.substring(idx);
            }
            return c;
        })
    }
    
    const options = props.options || {};
    const { rowHover, selectRow } = options;
    
    const sortKey = sortState?.key;
    const sortDir = sortState?.dir; // asc or desc
    const exploded_columns = explodeCols(columns);
    const sortColumn = sortKey ? exploded_columns?.find(col => col.key === sortKey) : null;
    
    // ADD DESCRIPTIVES
    let columns2 = columns?.map(col => {
        return {
            ...col
        }
    })
    let columns_needing_minmax = columns2.filter(col => needsMinMax(col));
    for( let row of data ){
        columns_needing_minmax.forEach(col => {
            
            // check col value
            if( col.key ){
                const val = getProperty(row, col.key);
                if(!isNull(val)){
                    col.minVal = isNull(col.minVal) ? val : Math.min(col.minVal, val);
                    col.maxVal = isNull(col.maxVal) ? val : Math.max(col.maxVal, val);
                }
            }

            // check child col values if any
            if( col.cols ){
                for( let childCol of col.cols ){
                    const val = getProperty(row, childCol.key);
                    if(!isNull(val)){
                        col.minVal = isNull(col.minVal) ? val : Math.min(col.minVal, val);
                        col.maxVal = isNull(col.maxVal) ? val : Math.max(col.maxVal, val);
                    }       
                }
            }
        })
    }

    // SECTIONS
    const sectionKey = props.sectionKey || options?.sectionKey;
    //console.log("sectionKey", sectionKey);
    let sections = [];
    let showSectionHeadings = false;
    if( sectionKey ){
        // group into sections
        let sectionDict = {};
        showSectionHeadings = true;
        for( let row of data ){
            const section = getProperty(row, sectionKey);
            let s = sectionDict[section];
            if(!s){
                s = {
                    label: section,
                    rows: []
                }
                sections.push(s);
                sectionDict[section] = s;
            }
            s.rows.push(row);
        }
    }
    else{
        let s = {
            label: null,
            rows: data
        }
        sections.push(s);
    }


    // SORT
    if( sortKey && data ){
        for( let section of sections ){
            section.rows = [...section.rows].sort((a, b) => {
                const val1 = getProperty(a, sortKey);
                const val2 = getProperty(b, sortKey);
                const dirMultiplier = sortDir === "asc" ? 1.0 : -1.0;
                if(isNull(val1) && isNull(val2)) return 0;

                // this means null is less than zero, and nulls placement depends on direction of sort
                // if(isNull(val1)) return -1 * dirMultiplier;
                // if(isNull(val2)) return 1 * dirMultiplier;

                // this always places null at the bottom, regardless of sort direction
                if(isNull(val1)) return 1;
                if(isNull(val2)) return -1;

                const sortColType = getColumnType(sortColumn);
                if( sortColType === "number" ){
                    return (val1 - val2) * dirMultiplier;
                }
                else if( sortColType === "string" ){
                    return val1?.localeCompare?.(val2) * dirMultiplier;
                }
                else{
                    // unknown sort
                }
            })
        }
    }

    // CATEGORIES
    const getCategoryKey = (col) => `${col.category}${col.sticky ? "[sticky]" : ""}`;
    let categories = [];
    {
        let lastCategoryObj = undefined;
        for(let c of columns2){
            if( lastCategoryObj && getCategoryKey(c) === lastCategoryObj.uKey ){
                lastCategoryObj.columns.push(c);
            }
            else{
                lastCategoryObj = {
                    label: c.category,
                    uKey: getCategoryKey(c),
                    columns: [c],
                    sticky: c.sticky
                }
                categories.push(lastCategoryObj);
            }
        }
    }

    const hasAtLeastOneCategory = categories.find(c => c.label) ? true : false;

    return { sections, columns: columns2, categories, hasAtLeastOneCategory, showSectionHeadings };

}


export const ExportDataTable = async (props, state) => {
    
    const ExcelJS = await import("exceljs");
    const workbook = new ExcelJS.Workbook();
    

    const sheet1 = workbook.addWorksheet('Sheet1');
    
    const font = {
        name: 'Segoe UI',
        family: 1,
        size: 9,
      };

    const { sections, columns, categories, hasAtLeastOneCategory, showSectionHeadings } = 
        GetDataTableLayout(props, state || {});
    
    
    let rowPos = 1;

    // define column widths
    {
        let xcols = [];
        for( let i in columns ){
            const col = columns[i];
            const width = getColumnWidth(col) / 6;
            xcols.push({ key: `column${i}`, width, style: { font } })
        }
        sheet1.columns = xcols;
    }

    // fill everything white?
    {
        const nbRows = (hasAtLeastOneCategory ? 1 : 0) // cat headers
            + 1 // col headers
            + (showSectionHeadings ? sections.length : 0) // section headers
            + sections.reduce((acc, section) => acc + section.rows.length, 0); // rows
        const nbCols = columns.length;
        for( let i = 0; i < nbRows + 1; i++ ){
            for( let j = 0; j < nbCols + 1; j++ ){
                sheet1.getRow(1+i).getCell(1+j).fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "ffffffff" }
                }
            }
        }
    }

    // add category headers if exists
    if( hasAtLeastOneCategory ){
        const xrow = sheet1.getRow(rowPos++);
        let i = 1;
        for( let ci in categories ){
            const cat = categories[ci];
            const xcell = xrow.getCell(i);
            xcell.value = cat.label;
            xcell.alignment = { vertical: 'middle', horizontal: 'center' };
            //xcell.span = cat.columns?.length;
            sheet1.mergeCells(rowPos - 1, i, rowPos - 1, i + cat.columns.length - 1);

            i += cat.columns?.length;
        }
    }

    // add column headers
    {
        const xrow = sheet1.getRow(rowPos++);
        for( let i in columns ){
            const col = columns[i];
            const xcell = xrow.getCell(`column${i}`);
            xcell.value = col.label || col.key;
            xcell.border = {
                bottom: {
                    style: "medium",
                    color: { argb: "ff1298df" }
                }
            }
            const hAlign = getHeaderAlignment(col);
            xcell.alignment = { vertical: 'bottom', horizontal: hAlign };
        }
    }

    // add rows in sections
    sections?.forEach((section, sectionIndex) => {

        if( showSectionHeadings ){
            const xrow = sheet1.getRow(rowPos++);
            const xcell = xrow.getCell(1);
            xcell.value = section.label;
            xcell.style = {
                font: {
                    ...font,
                    bold: true
                }
            };
            // xcell.border = {
            //     bottom: {
            //         style: "medium",
            //         color: { argb: "ffcfcfcf" }
            //     }                
            // }
            for( let i = 0; i < columns.length; i++ ){
                let xc = xrow.getCell(i+1);
                xc.border = {
                    bottom: {
                        style: "thin",
                        color: { argb: "ffcfcfcf" }
                    }                
                }
                
            }
        }

        section.rows.forEach((row, rowIndex) => {
            const xrow = sheet1.getRow(rowPos++);
            let columnIndex = 1;

            {categories.forEach((cat, catIdx) => 
                cat.columns.forEach((col, colIdx) => {

                    const xcell = xrow.getCell(columnIndex++);
                    const lastInCategory = colIdx === cat.columns.length - 1;
                    const lastCategory = catIdx === categories.length - 1;

                    ExportDataTableCell(xcell, {
                        row,
                        col,
                        lastInCategory,
                        lastCategory
                    })

                })
            )}

        })
    })

    const buffer = await workbook.xlsx.writeBuffer({ base64: true });
    const fileName = "export.xlsx";
    saveAs(
        new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        fileName
    )

}

export const explodeCols = (cols) => {

    let newCols = [];
    for(let parent of cols){
        if( parent.cols ){
            parent.cols?.forEach(childCol => {
                newCols.push({ parent, ...childCol });
            })
        }
        else{
            newCols.push(parent);
        }
    }
    return newCols;

}