import { useState } from "react";
import { createContext, useEffect } from "react";
import { useContext } from "react";
import { atom, atomFamily, useRecoilState } from "recoil";
import { computeApi } from "../../api/computeApi";
import { useAuth } from "../../hooks/useAuth";
import { useProjectId } from "../../hooks/useProjectId";
import { ContentAdderButton } from "../content/ContentAdderButton";
import { DisplayContext } from "../content/DisplayContext";
import { EditContentContext } from "../content/EditContentContext";
import { PresentContent } from "../content/PresentContent";
import { computeContextState, ComputeRunnerContext } from "./ComputeContext";

export const PipelineComputeContext = (props) => {
    console.log("PipelineComputeContext render");

    const id = props.id;
    const ec = useContext(EditContentContext);
    const mode = ec.mode;
    const item = ec.item;
    const firstChild = item?.children?.[0];

    const auth = useAuth();
    const { subscription_id, project_id } = useProjectId();
    
    
    const pipeline = item.jdata?.pipeline;
    const datasource_id = pipeline?.dataSourceId;
    const stages = pipeline?.stages
        ?.filter(s => !s.disabled)
        ?.map(s => s.data);
    const options = {};
    
    const resources = props.resources;
    
    const [ccState, setCCState] = useRecoilState(computeContextState(id));
    const [runCounter, setRunCounter] = useState(0);
    const [lastRunCounter, setLastRunCounter] = useState(0);
    
    const run = async () => {
        

        // update status
        setCCState({ 
            result: ccState?.result, // keep old value until the new one is ready
            running: true
        })

        // run
        try{
            const context = { subscription_id, project_id };
            const env = { resources, context };
            console.log("pcc env", env);
            const revision = ec.dsRevisionSlot; // e.g., "__dev"
            const r = await computeApi.computeV2(auth, env, subscription_id, project_id, datasource_id, revision, stages, options);
            setCCState({
                result: r,
            })
        }
        catch( err ){
            setCCState({
                result: null, // cState?.result, // should we keep existing result? maybe not?
                error: err.message
            })
        }

        setLastRunCounter(runCounter);
    }

    const runAfter = () => {
        setRunCounter(runCounter + 1);
    }

    const crc = useContext(ComputeRunnerContext);
    const new_crc = {
        ...crc,
        [id]: {
            run,
            runAfter
        }
    }

    // run if auto recalc
    useEffect(() => {
        if( (runCounter === lastRunCounter) && item.jdata?.recalc !== "auto" ) return;
        run();
    }, [pipeline, resources, runCounter] )

    const style = {
        //backgroundColor: "#ffffff",
        //padding: "5px 10px",
        ...props.style,
        ...item?.style
    }

    return <ComputeRunnerContext.Provider value={new_crc}>
        <div className={`mstudio-compute-context ${props.displayClass}`} style={style} {...props.atts}>
            {firstChild ?  
                <PresentContent id={firstChild.id} />
                :
                (mode === "edit" &&
                    <DisplayContext.Provider value={"block-fill"}>
                        <ContentAdderButton label="Set content" xdescription="Hint: Use a layout (e.g., stack or grid) if multiple content items are needed" />
                    </DisplayContext.Provider>
                )
            }
        </div>
    </ComputeRunnerContext.Provider>
    

}