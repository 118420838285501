
import { useLocation, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { NavButtons as _buttons } from "./_NavButtonsDef";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import "./BottomBar.scss";
import { useState } from "react";
import { batch, useDispatch } from "react-redux";

const repeatStr = (str, count) => 
    (!count || count < 1 || isNaN(count)) ? "" :
    count === 1 ? str :
    str + repeatStr(str, count - 1);

// TODO: this is written super inefficient.

// TODO: when expanding, it should overlay the rest of the screen rather than push it up.


export const BottomBar = (props) => {

    const routerParams = useParams();
    const loc = useLocation();
    const subscription_id = routerParams.subscription_id;
    const project_id = routerParams.project_id;
    const dispatch = useDispatch();

    let buttons = _buttons;

    const [showAll, setShowAll] = useState(false);

    const buttonWidth = 105; // note: this must match scss file's var
    const buttonHeight = 80; // note: this must match scss file's var

    const b5 = useMediaQuery(`(max-width: ${5 * buttonWidth}px)`);
    const b6 = useMediaQuery(`(max-width: ${6 * buttonWidth}px)`);
    const b7 = useMediaQuery(`(max-width: ${7 * buttonWidth}px)`);
    const b8 = useMediaQuery(`(max-width: ${8 * buttonWidth}px)`);
    const b9 = useMediaQuery(`(max-width: ${9 * buttonWidth}px)`);
    const b10 = useMediaQuery(`(max-width: ${10 * buttonWidth}px)`);
    const b11 = useMediaQuery(`(max-width: ${11 * buttonWidth}px)`);
    const b12 = useMediaQuery(`(max-width: ${12 * buttonWidth}px)`);
    const b13 = useMediaQuery(`(max-width: ${13 * buttonWidth}px)`);
    const b14 = useMediaQuery(`(max-width: ${14 * buttonWidth}px)`);
    const b15 = useMediaQuery(`(max-width: ${15 * buttonWidth}px)`);

    const nbVisibleButtonSpaces = b5 ? 4 :
        b6 ? 5 :
        b7 ? 6 :
        b8 ? 7 :
        b9 ? 8 :
        b10 ? 9 :
        b11 ? 10 :
        b12 ? 11 :
        b13 ? 12 :
        b14 ? 13 :
        b15 ? 14 :
        15;

    let visibleButtons = buttons.filter((_, idx) => idx < nbVisibleButtonSpaces - 0);
    let hiddenButtons = buttons.filter((_, idx) => idx >= nbVisibleButtonSpaces - 0);

    

    const showMoreButton = nbVisibleButtonSpaces < buttons.length;

    const nbButtonRows = Math.ceil((buttons.length + 0.0) / nbVisibleButtonSpaces);
    const height = showAll ? nbButtonRows * buttonHeight : buttonHeight;

    // console.log("nbVisibleButtonSpaces", nbVisibleButtonSpaces);
    // console.log("buttons", buttons);
    // console.log("visibleButtons", visibleButtons);
    // console.log("hiddenButtons", hiddenButtons);
    // console.log("nbButtonRows", nbButtonRows);

    // if !showAll and selected button is past point of visibility, re-order temporarily
    if( !showAll ){
        const selectedButton = buttons.find(button => {
            if( loc?.pathname?.startsWith(`/subscription/${subscription_id}/project/${project_id}/${button.key}`) ) return true;
            if( button.childPathRoot && loc?.pathname?.startsWith(`/subscription/${subscription_id}/project/${project_id}${button.childPathRoot}`) ) return true;
            return false;
        });
        
        const selectedButtonIndex = buttons.indexOf(selectedButton);
        if( selectedButtonIndex >= nbVisibleButtonSpaces - 0 ){
            buttons = buttons.filter(b => b !== selectedButton);
            buttons.splice(nbVisibleButtonSpaces - 1, 0, selectedButton);
            visibleButtons = buttons.filter((_, idx) => idx < nbVisibleButtonSpaces - 0);
            hiddenButtons = buttons.filter((_, idx) => idx >= nbVisibleButtonSpaces - 0);
        }
    }

    const openMenu = () => {
        dispatch({ type: "LEFT_MENU_OPEN_SET", value: true });
    }
    

    let style = {
        height,
        gridTemplateColumns: repeatStr("1fr ", visibleButtons.length + 1),
        gridTemplateRows: repeatStr(`${buttonHeight}px `, nbButtonRows)
    }

    let centerTheButtons = true;
    
    let spacer1 = false;
    if( buttons.length <= nbVisibleButtonSpaces ){
        if( centerTheButtons ){
            style.gridTemplateColumns = "1fr " + repeatStr(`${buttonWidth}px `, visibleButtons.length) + " 1fr";
            spacer1 = true;
        }
        else{
            style.gridTemplateColumns = repeatStr(`${buttonWidth}px `, visibleButtons.length) + " 1fr";
        }
    }

    return <div className="nav-bottom-bar" style={style}>
        {spacer1 ? <div/> : null}
        {visibleButtons.map(button => 
            <NavLink 
                key={button.key}
                className='bar-button'
                to={`/subscription/${subscription_id}/project/${project_id}/${button.key}`}
                isActive={(match, location) => {
                    return match || (button.childPathRoot && location?.pathname?.startsWith(`/subscription/${subscription_id}/project/${project_id}${button.childPathRoot}`))
                }}
                activeClassName="sel"
                onClick={() => {
                    if (button.openMenu) openMenu();
                    setShowAll(false);
                }}
            >
                <i className={`glyph ${button.icon}`} />
                {button.label}
            </NavLink>
        )}
        {showMoreButton ? 
            <div className="bar-button" onClick={(ev) => setShowAll(!showAll)}>
                <i className={showAll ? `glyph far fa-chevron-down` : `glyph far fa-chevron-up`}/>
                {showAll ? "Less" : "More..."}
            </div>
        : null}
        {hiddenButtons.map(button =>
            <NavLink 
                key={button.key}
                className='bar-button'
                to={`/subscription/${subscription_id}/project/${project_id}/${button.key}`}
                isActive={(match, location) => {
                    return match || (button.childPathRoot && location?.pathname?.startsWith(`/subscription/${subscription_id}/project/${project_id}${button.childPathRoot}`))
                }}
                activeClassName="sel"
                onClick={() => {
                    if (button.openMenu) openMenu();
                    setShowAll(false);
                }}
            >
                <i className={`glyph ${button.icon}`} />
                {button.label}
            </NavLink>
        )}
    </div>;

}