import { DiffEditor } from "@monaco-editor/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SelectDataSourceContext, SelectDataSourceRevisionContext } from "../../context/Contexts";
import { useDataSources } from "../../hooks/useDataSource";
import { useDataSourceRevision, useDataSourceRevisions } from "../../hooks/useDataSourceRevision";
import { useProjectId } from "../../hooks/useProjectId";
import { useQuery } from "../../hooks/useQuery";
import { CalcDiffTool } from "./CalcDiffTool";
import "./SchemaDiff.scss";

const TABS = [
    { id: "schema", label: "Schema" },
    { id: "data", label: "Data" },
    { id: "calcs", label: "Calculations" },
]

const isDigit = (ch) => '0123456789'.includes(ch);
const isDelimiter = (ch) => '_-.'.includes(ch);
// breaks the string into an array
// breaks occur when the str transitions from/to string and numeric
// breaks also occur when a delimiter is encountered: _
const smartDelimit = (str) => {
    if( !str ) return str;

    str = str.toLowerCase();

    if( str.length < 2 ) return [str];

    const pieces = [];
    let currentPiece = "";
    let state_isDigit = null;
    for( let i = 0; i < str.length; i++ ){
        const i_isDigit = isDigit(str[i]);
        const i_isDelimiter = isDelimiter(str[i]);
        if( !currentPiece.length ){
            currentPiece = str[i];
            state_isDigit = isDigit(str[i]);
        }
        else if( i_isDigit !== state_isDigit ){
            pieces.push(currentPiece); // push the previously accumulated piece
            state_isDigit = i_isDigit;
            currentPiece = str[i];
        }
        else if(isDelimiter(str[i])){
            pieces.push(currentPiece); // push the previously accumulated piece
            currentPiece = str[i];
        }
        else{
            currentPiece += str[i];
        }
    }
    if( currentPiece.length > 0 ){
        pieces.push(currentPiece);
    }
    const str_out = pieces.join("|");
    //console.log(`smartDelimit input=\"${str}\" output=\"${str_out}\"`)
    
    return pieces.map(str => {
        let i = parseInt(str);
        if( isNaN(i) ) return str;
        return i;
    })

}

const minify = (rev, ds_id, ii, alpha) => {
    if( !rev ) return;
    let str = "";

    let data = {
        datasource_id: ds_id,
        revision_id: rev.id,
        //revision: rev.revision,
        created: rev.created,
        file_info: rev.file_info,
        meta: rev.meta
    };
    //delete data.meta;
    str += JSON.stringify(data, null, 4) + "\n\n";
    
    let fields = rev.fields;
    if( !fields ) return str;

    if( alpha ){
        fields = [...fields].sort((aa, bb) => {
            // smart alpha breaks the variable into sections
            // based on a delimiter OR a transition from text to numeric
            const aPieces = smartDelimit(aa.name);
            const bPieces = smartDelimit(bb.name);
            for(let i = 0; i < aPieces.length; i++ ){
                const a = aPieces[i];
                const b = bPieces[i];
                if( a === undefined ) return -1;
                if( b === undefined ) return 1;
                if( isNaN(a) === false && isNaN(b) === false ){
                    // they are both numbers
                    const cmp = a - b;
                    if( cmp != 0 ) return cmp;
                }
                else{
                    // they are not both numbers
                    // they might both be strings, but maybe not
                    // maybe one is a number
                    // thus i'm converting to strings here
                    const cmp = `${a}`?.localeCompare(`${b}`);
                    if( cmp != 0 ) return cmp;
                }
            }
            return 0;
        });
        //console.log("sortedFields", fields.map(f => f.name));
    }

    fields?.forEach(field => {

        str += `[${field.name}] :${field.type}${field.label ? " " + field.label : ""}\n`;
        const valueLabels = field.valueLabels;
        if( valueLabels ){
            const strs = valueLabels.map(vl => `[${field.name}==${vl.val}] ${vl.label}`);
            str += strs.join("\n") + "\n";
        }

        if( ii ){
            const inspection = rev.inspection?.[field.name];
            if( inspection?.i ){
                if( inspection.d?.nDistinct && inspection.d.nDistinct !== inspection.i?.length ){
                    //inspectionClipped = true;
                    //str += "inspection:descriptives\t" + JSON.stringify(inspection.d) + "\n";
                }
                else{
                    //const strs = inspection.i.map(i => `[inspected ${field.name}==${i.v}] n=${i.n}`);
                    const strs = inspection.i.map(i => `[inspected ${field.name}==${i.v}]`);
                    str += strs.join("\n") + "\n";
                    //str += "inspection:values\t" + JSON.stringify(inspection.i) + "\n";
                }
            }
        }

    });

    // if( rev.inspection ){
    //     str += "\n\n";
    //     str += JSON.stringify(rev.inspection, null, 4);
    // }

    return str;

}

const get_udata = (rev, ds_id) => {
    if( !rev ) return;

    return rev.udata || "Not implemented for structured data. Use calculations.";
}




const SchemaSelector = (props) => {

    const id = props.id;
    const query = useQuery();
    const history = useHistory();
    const {project_id, subscription_id} = useProjectId();

    // get list of available data sources from context
    const dsContext = useContext(SelectDataSourceContext);
    const { dsList, dsListLoading, dsListError } = dsContext;

    // get selected datasource id from URL
    const selectedDsId = query.get(`${id}_ds`);
    useEffect(() => {
        console.log("ue selectedDsId", selectedDsId);
        dsContext.setSelectedId(selectedDsId);
    }, [selectedDsId]);

    // get list of available revisions from context
    const revContext = useContext(SelectDataSourceRevisionContext);
    const { revisions, revisionsLoading, revisionsError } = revContext;

    // get selected revision id from URL
    const selectedRevisionId = query.get(`${id}_rev`);
    useEffect(() => {
        console.log("ue selectedRevisionId", selectedRevisionId);
        revContext.setSelectedId(selectedRevisionId);
    }, [selectedRevisionId])


    return <div className="schema-selector">

        {/* select data source */}
        <select 
            disabled={dsListLoading}
            className="select-ds"
            value={dsContext.selectedId || "[select]"}
            onChange={(ev) => {
                query.set(`${id}_ds`, ev.target.value);
                query.delete(`${id}_rev`);
                const newQueryStr = query.toString();
                history.push(`/subscription/${subscription_id}/project/${project_id}/tool/schema-diff?${newQueryStr}`)
            }}
        >
            <option value="[select]" disabled>
                {dsListLoading ? "loading data sources..." : "[Select data source...]"}
            </option>
            {dsList?.map(ds => 
                <option key={ds.id} value={ds.id}>
                    {ds.label}
                </option>
            )}
        </select>


        {/* select revision */}
        <select
            disabled={!selectedDsId || revisionsLoading} 
            className="select-revision"
            value={revContext.selectedId || "[select]"}
            onChange={(ev) => {
                //setSelectedRevision(ev.target.value);
                query.set(`${id}_rev`, ev.target.value);
                const newQueryStr = query.toString();
                history.push(`/subscription/${subscription_id}/project/${project_id}/tool/schema-diff?${newQueryStr}`)
            }}
        >
            <option value="[select]" disabled>
                {(!selectedDsId) ? "" : revisionsLoading ? "loading revisions..." : "[Select revision...]"}
            </option>
            {revisions?.sort((a,b) => b.id - a.id)?.map(rev =>
                <option 
                    key={rev.id} 
                    value={rev.id}
                >
                    {rev.id}: {rev.notes}
                </option>
            )}
        </select>
        
    </div>
}



export const SchemaDiff = (props) => {
    

    const history = useHistory();
    const loc = useLocation();
    const query = useQuery();
    const selTabId = query.get("tab") || TABS[0].id;

    const diffEditorRef = useRef(null);
    // const [code1, setCode1] = useState(null);
    // const [code2, setCode2] = useState(null);
    const [codeArr, setCodeArr] = useState(null);
    const [dataValue, setDataValue] = useState(null);
    const [includeInspection, setIncludeInspection] = useState(false);
    const [alpha, setAlpha] = useState(false);

    const [dsList, dsListLoading, dsListError, dsListRefresh] = useDataSources();
    const [ds1Id, setDs1Id] = useState(null);
    const [ds2Id, setDs2Id] = useState(null);

    const ds1 = dsList?.find(ds => ds.id === ds1Id);
    const ds2 = dsList?.find(ds => ds.id === ds2Id);
    //console.log("schemadiff", ds1, ds2);

    const [revisions1, revisions1Loading, revisions1Error] = useDataSourceRevisions(ds1Id);
    const [revisions2, revisions2Loading, revisions2Error] = useDataSourceRevisions(ds2Id);
    const [rev1Id, setRev1Id] = useState(null);
    const [rev2Id, setRev2Id] = useState(null);
    const [revision1, revision1Loading, revision1Error] = useDataSourceRevision(ds1Id, rev1Id);
    const [revision2, revision2Loading, revision2Error] = useDataSourceRevision(ds2Id, rev2Id);

    // console.log("revision1", rev1Id, revision1, revision1Loading, revision1Error);
    // console.log("revision2", rev2Id, revision2, revision2Loading, revision2Error);

    useEffect(() => {
        //console.log("revision useEffect called", revision1, revision2);
        if( revision1 && revision2 ){
            const code1 = minify(revision1, ds1Id, includeInspection, alpha);
            const code2 = minify(revision2, ds2Id, includeInspection, alpha);
            console.log("setting CodeArr", [code1, code2]);
            setCodeArr([code1, code2]);

            const d1 = get_udata(revision1, ds1Id);
            const d2 = get_udata(revision2, ds2Id);
            setDataValue([d1, d2]);
        }
        else{
            //console.log("setting CodeArr to null");
            //setCodeArr(null);
        }
        // setCode1(minify(revision1, ds1Id));
        // setCode2(minify(revision2, ds2Id));
    }, [revision1, revision2, includeInspection, alpha]);
    

    const dsContextValue = {
        dsList,
        dsListLoading,
        dsListError,
        dsListRefresh,
    };

    const revContextValue = {}

    function handleEditorDidMount(editor, monaco) {
        diffEditorRef.current = editor;
    }


    return <div className="schema-diff-tool">

        <div className="left-selector">
            <SelectDataSourceContext.Provider value={{ 
                ...dsContextValue,         
                selectedId: ds1Id,
                setSelectedId: setDs1Id
            }}>
                <SelectDataSourceRevisionContext.Provider value={{
                    ...revContextValue,
                    revisions: revisions1,
                    revisionsLoading: revisions1Loading,
                    revisionsError: revisions1Error,
                    selectedId: rev1Id,
                    setSelectedId: setRev1Id
                }}>
                    <SchemaSelector id={"left"}/>
                </SelectDataSourceRevisionContext.Provider>
            </SelectDataSourceContext.Provider>
            
        </div>
        
        <div className="right-selector">
            <SelectDataSourceContext.Provider value={{ 
                ...dsContextValue,         
                selectedId: ds2Id,
                setSelectedId: setDs2Id
            }}>
                <SelectDataSourceRevisionContext.Provider value={{
                    ...revContextValue,
                    revisions: revisions2,
                    revisionsLoading: revisions2Loading,
                    revisionsError: revisions2Error,
                    selectedId: rev2Id,
                    setSelectedId: setRev2Id
                }}>
                    <SchemaSelector id={"right"}/>
                </SelectDataSourceRevisionContext.Provider>
            </SelectDataSourceContext.Provider>
        </div>

        <div className="options">
            <div className="tab-section no-margin">
                {TABS.map(tab => 
                    <div 
                        className={`tab ${tab.id === selTabId ? "sel" : ""}`}
                        onClick={() => {
                            query.set("tab", tab.id);
                            const newQueryStr = query.toString();
                            history.push(`${loc.pathname}?${newQueryStr}`);
                        }}
                    >
                        {tab.label}
                    </div>
                )}
            </div>
        </div>
        
        <div className="main-diff">

            {(rev1Id === null || rev2Id === null) ? <div className="message">Select 2 Revisions</div>
                : (revision1Loading || revision2Loading) ? <div className="message">Loading data...</div>
                : (!codeArr || codeArr.length < 2) ? <div className="message">Error</div>
                : selTabId === "schema" ? <div className="fill-parent-absolute full-dock">
                    <div className="top" style={{ padding: "10px 20px" }}>
                        <span className={`btn toggle ${includeInspection ? "sel" : ""}`}
                            onClick={() => setIncludeInspection(!includeInspection)}
                        >
                            Include inspection
                        </span>
                        <span className={`btn toggle ${alpha ? "sel" : ""}`}
                            style={{ marginLeft: 5 }}
                            onClick={() => setAlpha(!alpha)}
                        >
                            Order fields
                        </span>
                    </div>
                    <div className="center fill-parent-absolute">
                        <DiffEditor
                            name="schema"
                            height="100%"
                            defaultLanguage="javascript"
                            original={codeArr[0]}
                            modified={codeArr[1]}
                            options={{
                                unicodeHighlight: {
                                    ambiguousCharacters: false,
                                }
                            }}
                            onMount={handleEditorDidMount}
                        />
                    </div>
                </div>
                : selTabId === "data" ? <DiffEditor
                    name="udata"
                    height="100%"
                    defaultLanguage="javascript"
                    original={dataValue[0]}
                    modified={dataValue[1]}
                    onMount={handleEditorDidMount}
                />
                : selTabId === "calcs" ? <CalcDiffTool
                        ds1={ds1}
                        ds2={ds2}
                        rev1={revision1}
                        rev2={revision2}
                />
                : null
            }
        
        </div>
        

    </div>;

}