import Editor from "@monaco-editor/react";
import { format } from "d3-format";
import React, { useContext, useEffect, useState } from 'react';
import { useRef } from "react";
import { toast } from "react-toastify";
import { EditDataSourceContext } from "../../context/Contexts";
import { useDataSourceRevision } from "../../hooks/useDataSourceRevision";
import { FieldSearch } from '../common/FieldSearch';
import { DataSourceRevisionSelector } from "./DataSourceRevisionSelector";
import "./ViewFields.scss";
import { autoTrim as applyAutoTrim, formatFilterGroup, formatMR, formatSR, formatSRSet, jsonEncodeLabel, replaceVarNames } from "./ViewFieldsHelper";

const VALUES_TABS = [
    { id: "default", label: "Default", canCopy: false },
    { id: "json", label: "JSON", canCopy: true },
    { id: "xml", label: "XML (xtable)", canCopy: true },
    { id: "xmlf", label: "XML (filter)", canCopy: true },
    { id: "names", label: "Names", canCopy: true },
    { id: "tabbed", label: "Tabbed", canCopy: true },
//    { id: "insp", label: "Inspections" },
]

const find_matches = (fields, searchTerms) => {
    try{
        // todo: this regex isn't perfect. probably fix it by removing the regexp and doing manually
        const regex = new RegExp(searchTerms.map(t => `(?=.*${t})`).join( "" ), "i");
        return fields.filter(f => regex.test(`[${f.name}] :${f.type} ${f.label}`));
    }
    catch{
        return [];
    }
}

const nFormat = format(",.0f");
const sortDirs = ["asc", "desc", null];

const isNull = x => x === null || x === undefined;

const getSortGlyph = (type, dir) => {
    let glyph = null;
    if( type === "string" ){
        if( dir === "asc" ) glyph = <i className="fas fa-arrow-down-a-z" />;
        else if( dir === "desc" ) glyph = <i className="fas fa-arrow-up-z-a" />;
    }
    else if( type === "number" ){
        if( dir === "asc" ) glyph = <i className="fas fa-arrow-down-1-9" />;
        else if( dir === "desc" ) glyph = <i className="fas fa-arrow-up-9-1" />;
    }
    else{
        if( dir === "asc" ) glyph = <i className="fas fa-chevron-down" />;
        else if( dir === "desc" ) glyph = <i className="fas fa-chevron-up" />;
    }
    return glyph && <span style={{ marginLeft: 5 }}>{glyph}</span>
}

const getBoolValue = b => (b === null || b === undefined) ? null : b ? 1 : 0;

export const ViewFields = (props) => {

    const dsContext = useContext(EditDataSourceContext);
    const ds = dsContext?.ds;

    const [rev, revLoading, revError, revRefresh] = useDataSourceRevision(ds?.id, dsContext.selectedRevisionId);
    
    const [selectedFieldNames, setSelectedFieldNames] = useState(null); // selected fields
    const [searchTerms, setSearchTerms] = useState(null);
    const [valuesTab, setValuesTab] = useState("default");
    const [sortInfo, setSortInfo] = useState(null);
    const [showVariables, setShowVariables] = useState(true);
    const [autoTrim, setAutoTrim] = useState(true);

    const editorRef = useRef(null);

    // field dictionary for faster lookups only
    const [fieldDict, setFieldDict] = useState(null);
    useEffect(() => {
        if(!rev?.fields) return;

        const dic = rev.fields.reduce((acc, f) => {
            acc[f.name] = f;
            return acc;
        }, {})
        setFieldDict(dic);

    }, [rev])


    const valuesTabObj = VALUES_TABS.find(t => t.id === valuesTab);

    // all available fields
    const fields = rev?.fields;

    // fields available after search
    const matchingFields = searchTerms?.length > 0 ? find_matches(fields, searchTerms) : fields;

    // merge value labels of selected fields
    let valueInfos = [];
    let selFieldStrs = [];

    // variable labels for all selected fields
    // let selectedVarLabels = [];
    // selFields?.forEach(fName => {
    //     const f = fieldDict?.[fName];
    //     selectedVarLabels.push(f.label);
    // });
    let selectedVarLabels = selectedFieldNames?.map(fName => fieldDict?.[fName]?.label) || [];
    const selectedVarLabels2 = replaceVarNames(selectedVarLabels, selectedFieldNames);
    const selectedVarLabelsTrimmed = applyAutoTrim(selectedVarLabels2);
    
    const nbSelectedFields = selectedFieldNames?.length;

    let tFields = [];
    let inspections = [];
    const revInspected = rev?.inspection;
    let totalN = rev?.inspection?.[" ___n"];
    
    let tabbedEntries = [];

    for( let i = 0; i < selectedFieldNames?.length; i++ ){

        const fieldName = selectedFieldNames[i];
        const field = fieldDict?.[fieldName];
        const trimmedVarLabel = selectedVarLabelsTrimmed?.[i];

        const fInspection = rev?.inspection?.[fieldName];
        if( fInspection ){
            inspections.push(fInspection);
        }
                    
        
        //selFieldStrs.push(`${f.name}\t${tLabel || f.label}`);
        selFieldStrs.push(`{ "name": "${field.name}", "label": "${jsonEncodeLabel(autoTrim ? trimmedVarLabel : field.label)}" }`);
        tabbedEntries.push(`${field.name}\t${(autoTrim ? trimmedVarLabel : field.label)}`);
        //selectedVarLabels.push(field.label);

        let inspectionClipped = false;


        let valueLabelsAndInspectedValues = field?.valueLabels?.map(vl => ({
            val: vl.val,
            label: vl.label,
            n: revInspected ? 0 : null, // if inspected, set n to zero and inspection will override if it found a value
            declared: true,
        })) || [];

        if( fInspection ){
            const parseValue = v => field.type === "numeric" ? parseFloat(v) : v;
            fInspection.i?.forEach(i => {
                const inspectedValue = i.v;// parseValue(i.v);
                const n = i.n;
                let find = valueLabelsAndInspectedValues.find(v => v.val === inspectedValue);
                if( find ){
                    find.n = n;
                }
                else{
                    valueLabelsAndInspectedValues.push({ val: inspectedValue, n });
                }
            })
            if( fInspection.d?.nDistinct && fInspection.d.nDistinct !== i.length ){
                inspectionClipped = true;
                //valueLabelsAndInspectedValues.push({ note: "Inspection clipped. nDistinct=" + fInspection.d.nDistinct })
            }
        }

        // something about multi-labeling
        valueLabelsAndInspectedValues?.forEach(vl1 => {
            const infoEntry = valueInfos.find(info => vl1.val === info.val);
            if( infoEntry ){
                infoEntry.nbVars++;
                if( infoEntry.label !== vl1.label ){
                    infoEntry.label = "*";
                }
                //infoEntry.n = (infoEntry.n || 0) + vl1.n; // STATE MUTATION!
            }
            else{
                valueInfos.push({ ...vl1, nbVars: 1 });
            }
        });

        tFields.push({
            ...field,
            n: revInspected ? (fInspection?.n || 0) : null,
            nMissing: revInspected && totalN ? (totalN - (fInspection?.n || 0)) : null,
            //tLabel: trimmedVarLabel,
            label: autoTrim ? trimmedVarLabel : field.label,
            inspectionClipped
        })
    };

    const vlMap = (vl) => vl.note ? `// NOTE: ${vl.note}` : `{ "val": ${vl.val === null ? "null" : (typeof(vl.val) === "string") ? `"${jsonEncodeLabel(vl.val)}"` : vl.val}${vl.label ? `, "label": "${jsonEncodeLabel(vl.label)}"` : ""} }`

    //let editorValue = "vars:\n" + selFieldStrs.join("\n") + "\n\nvalues:\n" + valueLabels.map(vl => `${vl.val}\t${vl.label}`).join("\n");

    // exclude non declared
    // let jsonValueStr = showVariables ? "{\n\t\"fields\": [\n\t\t" + selFieldStrs.join(",\n\t\t") + "\n\t],\n\t\"values\": [\n\t\t" + valueInfos.filter(vi => vi.declared).map(vlMap).join(",\n\t\t") + "\n\t]\n}\n"
    //     : "{\n\t\"values\": [\n\t\t" + valueInfos.filter(vi => vi.declared).map(vlMap).join(",\n\t\t") + "\n\t]\n}\n";
    let jsonValueStr = showVariables ? "{\n\t\"fields\": [\n\t\t" + selFieldStrs.join(",\n\t\t") + "\n\t],\n\t\"values\": [\n\t\t" + valueInfos.map(vlMap).join(",\n\t\t") + "\n\t]\n}\n"
        : "{\n\t\"values\": [\n\t\t" + valueInfos.map(vlMap).join(",\n\t\t") + "\n\t]\n}\n";
    
    let xmlfValueStr = formatFilterGroup(tFields, valueInfos, ds.label);
    
    let xmlValueStr = "";
    {
        if( tFields?.length === 1 && valueInfos?.length > 1 ){
            xmlValueStr += "" + formatSR(tFields[0], valueInfos, ds.label);
        }
        else if( tFields?.length > 1 && valueInfos?.length > 1 
            && !(valueInfos?.length === 2 && valueInfos[0].val === 0 && valueInfos[1].val === 1)
            && !(valueInfos?.length === 2 && valueInfos[0].val === 1 && valueInfos[0].val === 1)
        ){
            xmlValueStr += "" + formatSRSet(tFields, valueInfos, ds.label);
        }
        else{
            xmlValueStr += "" + formatMR(tFields, ds.label);
        }
    
    }

    // let tabbedEntries = [];
    // if( showVariables ){
    //     tabbedEntries = selFieldStrs;
    // }
    tabbedEntries.push("");
    valueInfos?.forEach(vi => {
        //tabbedEntries.push(JSON.stringify(vi)); //$`${}\t${}`)
        tabbedEntries.push(`${vi.val}\t${vi.label}`)
    })
    
    const sortClicked = (name, type) => {
        if( sortInfo?.name === name ){
            const idx = (sortDirs.indexOf(sortInfo.dir) + 1) % sortDirs.length;
            setSortInfo({ ...sortInfo, dir: sortDirs[idx] })
        }
        else{
            setSortInfo({ name, type, dir: sortDirs[0] });
        }
    }

    // sort valueInfos
    if( sortInfo?.dir ){
        if(sortInfo.type === "string"){
            valueInfos = sortInfo.dir === "asc" ? valueInfos.sort((a, b) => a[sortInfo.name]?.localeCompare(b[sortInfo.name])) :
                valueInfos.sort((a, b) => b[sortInfo.name]?.localeCompare(a[sortInfo.name]));
        }
        else if( sortInfo.type === "number" ){
            valueInfos = sortInfo.dir === "asc" ? valueInfos.sort((a, b) => a[sortInfo.name] - b[sortInfo.name]) :
                valueInfos.sort((a, b) => b[sortInfo.name] - a[sortInfo.name]);
        }
        else if( sortInfo.type === "bool" ){
            valueInfos = sortInfo.dir === "asc" ? valueInfos.sort((a, b) => getBoolValue(a[sortInfo.name]) - getBoolValue(b[sortInfo.name])) :
                valueInfos.sort((a, b) => getBoolValue(b[sortInfo.name]) - getBoolValue(a[sortInfo.name]));
        }
        
    }


    let cols = [];
    cols.push({ key: "declared",   type: "bool",   label: "" });
    cols.push({ key: "val",    type: "number", label: "Value" });
    cols.push({ key: "label",  type: "string", label: "Label" });
    cols.push({ key: "n",      type: "number", label: "Freq" });
    if( nbSelectedFields > 1 ){
        cols.push({ key: "nbVars", type: "number", label: "# Vars" });
    }

    const copyClicked = () => {
        const _syntax = editorRef.current?.getValue();
        navigator.clipboard.writeText(_syntax);
        toast.info('Copied', {
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
        });
    };

    const exportClicked = () => {

    }



    return <div className="view-dataset-fields">
        <div className="heading-pane">
            <DataSourceRevisionSelector width={400}/> 
        </div>
        <div className="flow-section1 full-dock">

            <div className="top" style={{ marginBottom: 5 }}>
                <div className="section-title">Fields</div>
                
                <FieldSearch onChange={(ev) => {
                    setSearchTerms(ev.terms);
                    //setSearchOptions
                }} />

            </div>

            {revLoading ? <div className="loading-indicator center">
                    loading fields...
                </div> :
                <div className="center fill-parent-absolute" style={{border: "1px solid #cfcfcf"}}>
                    <select 
                        multiple
                        className="fields-select"
                        style={{border: 0}}
                        value={selectedFieldNames || []}
                        onChange={(ev) => {
                            const options = ev.target.options;
                            let newVal = [];
                            for(let option of options){
                                if( option.selected ){
                                    newVal.push(option.value);
                                }
                            }
                            setSelectedFieldNames(newVal);
                        }}
                    >
                        {matchingFields?.map(field => {
                            const fieldType = field.type === "string" || field.type === 1 || field.type?.startsWith?.("varchar") ? "string" : "numeric";
                            return <option 
                                key={field.name} 
                                className={fieldType} 
                                value={field.name}
                                data-content={field.label}
                            >
                                {field.name}
                            </option>
                        })}
                    </select>
                </div>
            }
        </div>
        <div className="flow-section2 full-dock">
            <div className="top" style={{ marginBottom: 5 }}>
                <div className="section-title">Values</div>
                <div className="tab-section2" style={{ display: "inline-block", marginBottom: 5 }}>
                    {VALUES_TABS.map(tab =>
                        <span key={tab.id} className={`tab ${valuesTab === tab.id ? "sel" : ""}`}
                            onClick={() => setValuesTab(tab.id)}
                        >
                            {tab.label}
                        </span>
                    )}
                </div>
                <div>
                    <span className={`btn toggle-small ${showVariables ? "sel" : ""}`} style={{ marginRight: 5 }}
                        onClick={() => setShowVariables(!showVariables)}
                    >
                        Show Fields
                    </span>
                    {showVariables &&
                        <span className={`btn toggle-small ${autoTrim ? "sel" : ""}`} style={{ marginRight: 5 }}
                            onClick={() => setAutoTrim(!autoTrim)}
                        >
                            Auto-trim
                        </span>
                    
                    }
                    {valuesTabObj?.canCopy && 
                        <span className="btn icon" title='Copy' onClick={() => copyClicked()}>
                            <i className="fal fa-copy" />
                        </span>
                    }
                    {valuesTab === "xml" &&
                        <a className="btn icon" title="Help on xtable" 
                            href="https://docs.metricstudios.com/docs/visualization/gallery/xtable/"
                            target="_blank"
                        >
                            <i className="fal fa-question-circle" />
                        </a>
                    }
                    {valuesTab === "default" &&
                        <span className="btn icon" title='Export' onClick={() => exportClicked()}>
                            <i className="fal fa-arrow-to-bottom" />
                        </span>
                    }
                </div>
            </div>
            <div className="values-section center">
                {valuesTab === "default" && selectedFieldNames?.length &&
                    <div>

                        {showVariables &&
                            <table className="value-infos-table" style={{ marginBottom: 10 }}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Label</th>
                                        <th>N (Valid)</th>
                                        <th>Missing</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tFields?.map(field =>
                                        <tr key={field.name} className="value-info">
                                            <td className="value-label">{field.name}</td>
                                            <td className="value-label">{field.label}</td>
                                            <td className="n"><span className="missing">{isNull(field.n) ? "?" : nFormat(field.n)}</span></td>
                                            <td className="n"><span className="missing">{isNull(field.nMissing) ? "?" : nFormat(field.nMissing)}</span></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }

                        <table className="value-infos-table">
                            <thead>
                                <tr>
                                    {cols.map(col =>
                                        <th key={col.key}
                                            onClick={() => sortClicked(col.key, col.type)}
                                        >
                                            {col.label}
                                            {sortInfo?.name === col.key &&
                                                <span style={{ marginLeft: 5, fontSize: 11, fontWeight: "normal" }}>
                                                    {getSortGlyph(sortInfo?.type, sortInfo?.dir)}
                                                </span>
                                            }
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                            {valueInfos?.map(info => {
                                return <tr key={info.val} className={`value-info ${info.declared ? "declared" : "inspected"}`}>
                                    <td>
                                        {info.declared ? 
                                            <i style={{ fontSize: 6 }} className="fas fa-circle" /> : 
                                            <i style={{ fontSize: 6 }} className="fal fa-circle" />
                                        }
                                    </td>
                                    <td className="value">
                                        {info.val}
                                    </td>
                                    <td className="value-label">
                                        {info.label}
                                    </td>
                                    <td className="n">
                                        {info.n === null || info.n === undefined ? <span className="missing">?</span> : nFormat(info.n)}
                                    </td>
                                    {nbSelectedFields > 1 &&
                                        <td className="vars">
                                            {info.nbVars === nbSelectedFields ? 
                                                "(all)" : 
                                                <span>{info.nbVars} of {nbSelectedFields}</span>
                                            }
                                        </td>
                                    }
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>
                }
                {valuesTab === "json" && <Editor
                    className='editor'
                    defaultLanguage={'json'}
                    value={jsonValueStr}
                    height={"100%"}
                    width={"100%"}
                    options={{
                        lineNumbers: "off",
                        folding: false,
                        wordWrap: "none",
                        //wordWrap: "bounded",
                        minimap: {
                            enabled: false
                        }
                    }}
                    onMount={(editor, monaco) => {
                        editorRef.current = editor;
                    }}
                />}
                {valuesTab === "xml" && <Editor
                    className='editor'
                    defaultLanguage={'xml'}
                    value={xmlValueStr}
                    height={"100%"}
                    width={"100%"}
                    options={{
                        lineNumbers: "off",
                        folding: false,
                        wordWrap: "none",
                        //wordWrap: "bounded",
                        minimap: {
                            enabled: false
                        }
                    }}
                    onMount={(editor, monaco) => {
                        editorRef.current = editor;
                    }}
                />}
                {valuesTab === "xmlf" && <Editor
                    className='editor'
                    defaultLanguage={'xml'}
                    value={xmlfValueStr}
                    height={"100%"}
                    width={"100%"}
                    options={{
                        lineNumbers: "off",
                        folding: false,
                        wordWrap: "none",
                        //wordWrap: "bounded",
                        minimap: {
                            enabled: false
                        }
                    }}
                    onMount={(editor, monaco) => {
                        editorRef.current = editor;
                    }}
                />}
                {valuesTab === "insp" && <Editor
                    className='editor'
                    defaultLanguage={'json'}
                    value={JSON.stringify(inspections, null, 4)}
                    height={"100%"}
                    width={"100%"}
                    options={{
                        lineNumbers: "off",
                        folding: false,
                        wordWrap: "none",
                        //wordWrap: "bounded",
                        minimap: {
                            enabled: false
                        }
                    }}
                    onMount={(editor, monaco) => {
                        editorRef.current = editor;
                    }}
                />}
                {valuesTab === "names" && <Editor
                    className='editor'
                    defaultLanguage={'txt'}
                    value={selectedFieldNames.join("\r\n")}
                    height={"100%"}
                    width={"100%"}
                    options={{
                        lineNumbers: "off",
                        folding: false,
                        wordWrap: "none",
                        //wordWrap: "bounded",
                        minimap: {
                            enabled: false
                        }
                    }}
                    onMount={(editor, monaco) => {
                        editorRef.current = editor;
                    }}
                />}
                {valuesTab === "tabbed" && <Editor
                    className='editor'
                    defaultLanguage={'txt'}
                    value={tabbedEntries.join("\r\n")}
                    height={"100%"}
                    width={"100%"}
                    options={{
                        lineNumbers: "off",
                        folding: false,
                        wordWrap: "none",
                        //wordWrap: "bounded",
                        minimap: {
                            enabled: false
                        }
                    }}
                    onMount={(editor, monaco) => {
                        editorRef.current = editor;
                    }}
                />}
            </div>
        </div>
    </div>
    
    
}


