import React, { useContext } from "react"
import { DisplayContext, getDisplayClass } from "./DisplayContext";
import { EditContentContext } from "./EditContentContext"
import "./EditWrapper.scss";
import Popup from "reactjs-popup";
import { EditItemContextMenu } from "./EditItemContextMenu";


export const EditWrapper = (props) => {

    const editContext = useContext(EditContentContext);

    //if( editContext.mode !== "edit" ) return props.children; // not needed. wrapper isn't used when mode !== edit

    const item = editContext.item;
    const selected = item && editContext.selectionId === item?.id;

    const onClick = (ev) => {
        editContext.setSelectionId(selected ? null : item?.id);
        ev.stopPropagation();
        //ev.preventDefault(); // shouldn't be needed?
    }

    const atts = { onClick }

    return React.Children.map(props.children, (child) => 
        selected ?
            React.cloneElement(child, { 
                style: {
                    ...child.props.style,
                    border: "2px solid #64c8ff"
                },
                atts
            })
            :
            React.cloneElement(child, {
                atts
            })
    );
}

// only used when mode=edit
export const EditWrapperOld = (props) => {
    
    const editContext = useContext(EditContentContext);
    const display = useContext(DisplayContext);
    const displayClass = getDisplayClass(display);


    const mode = editContext.mode;
    const item = editContext.item;
    const style = item?.style;
    const parentItem = editContext.itemStack[editContext.itemStack.length - 2];
    
    const selected = item && editContext.selectionId === item?.id;
    const select = () => editContext.setSelectionId(item?.id);
    const deSelect = () => editContext.setSelectionId(null);

    
    if( selected ){
        return <div className={`edit-wrapper selected ${displayClass}`} style={style}
            onClick={(ev) => {
                deSelect();
                ev.stopPropagation();
                ev.preventDefault();
            }}
        >
            <div className="buttons">
                {parentItem && <div className="btn transparent" 
                    title="Select parent"
                    onClick={(ev) => {
                        editContext.setSelectionId(parentItem?.id);
                        ev.preventDefault();
                        ev.stopPropagation();
                    }}
                >
                    <i className="fal fa-circle-arrow-up-left" style={{ color: "#ffffff" }} />
                </div>}
                <span className="label">
                    {item?.label || item?.ctype}
                </span>
                <Popup
                    arrow={false}
                    position="bottom right"
                    keepTooltipInside={true}
                    trigger={
                        <div className="btn" 
                            onClick={(ev) => {
                                
                            }}
                        >
                            <i className="fad fa-list" />
                        </div>
                    }
                >
                    {close => <div className="edit-wrapper-menu">
                        EDIT STYLES
                    </div>}
                </Popup>
                <Popup
                    arrow={false}
                    position="bottom right"
                    keepTooltipInside={true}
                    trigger={
                        <div className="btn" 
                            onClick={(ev) => {
                                
                            }}
                        >
                            <i className="fal fa-ellipsis" />
                        </div>
                    }
                >
                    {close => <EditItemContextMenu close={close} />
                    }
                </Popup>
            </div>
            <>
            {props.children}
            </>
        </div>
    }
    else{
        return <div className={`edit-wrapper ${displayClass}`} style={style} onClick={(ev) => {
            select();
            ev.stopPropagation();
            ev.preventDefault();
        }}>
            {/* <div className="buttons">
                <span>
                    [{item.ctype + "/" + item?.id}]
                </span>
            </div> */}
            {props.children}
        </div>
    }

}