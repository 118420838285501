import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { useProjectId } from "../../../hooks/useProjectId";
import { ResourceList } from "./ResourceList";
import { SelectResourceBox } from "./SelectResourceBox";

export const EditResources = (props) => {

    const [list, setList] = useState(props.value);

    useEffect(() => {
        setList(props.value);
    }, [props.value])

    const names = list?.map(entry => entry.name);

    return <div style={{ marginBottom: 10 }}>
        
        <ResourceList value={list} onDelete={(r) => {
            const newList = list.filter(e => e.name !== r.name);
            setList(newList);
            props.onChange?.(newList);
        }} />
        <div>
            <div style={{ textAlign: "right" }}>
            <Popup
                keepTooltipInside
                position="bottom right"
                contentStyle={{
                    width: 500,
                    height: 350,
                    backgroundColor: "teal"
                }}
                trigger={
                    
                        <span className='btn action smaller' style={{ marginRight: 10  }}>
                            <i className='fal fa-plus' />&nbsp; Add resource...
                        </span>
                    
                }
            >
                {closeModal => <SelectResourceBox existingNames={names} close={closeModal} onAdd={r => {
                    if (!r.name) {
                        // do nothing; resource name is blank
                    }
                    else if (list?.find(entry => entry.name === r.name)) {
                        // do nothing; resource name is already in use !?
                    }
                    else {
                        // add resource
                        const newList = [...(list || []), r];
                        setList(newList);
                        props.onChange?.(newList);
                    }
                }} />}
                </Popup>
            </div>
        </div>

    </div>
}