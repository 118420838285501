import { useMemo } from "react";
import { useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { controllers } from "../api/controllers";
import { constructs } from "../api/contructs";
import { PageEditorContext } from "../components/pages/Pages";
import { useAuth } from "./useAuth";
import { useProjectId } from "./useProjectId";

// TODO: memoize this somehow so it doesn't return a different object on each render
// or maybe just return a hash that could be used for equality check! (equalityHash)
// OR create useShallowCompareEffect and use that to check for changes to resources

// here's what i'll do
// i'll repeat the following loop until everything is done: !?!?

//  * figure out what resources need to be loaded
//  * in a single dispatch, set them all to LOADING (is a single dispatch needed??)
//  * load them all asynchronously but don't SET them until everything is loaded (await all?)
//  * set everything as loaded

// what about things i don't know beforehand, like:
//      - which revision to load of a data-source
//      - which data-source (and thus revision) to load for a selection
//      - which images to load for a collection




export const useResources = (list, reduce) => {
    
    const {project_id, subscription_id} = useProjectId();
    const reduxDispatch = useDispatch();
    const auth = useAuth();

    const peContext = useContext(PageEditorContext);
    const isDev = peContext?.isDev;

    // get the loaded data-sources first, because we need to find the dev and prod pointers, before attaching the additional loaders
    const list_ds = list?.filter(item => item.type === "data-source");
    const dsRevisionDict = useSelector(state => list_ds?.reduce((accum, entry) => {
        const construct = constructs[entry.type];
        const val = state[construct.storeName].dict?.[entry.id];
        accum[entry.name] = isDev ? val?.dev_revision : val?.prod_revision;
        return accum;
    }, {}), shallowEqual);
    //console.log("dsRevisionDict", dsRevisionDict);

    // get the loaded selectors first, because we need to find any attached resources, argh this is confusing
    // const list_sel = list?.filter(item => item.type === "selection");
    // const selDsDict = useSelector(state => list_sel?.reduce((accum, entry) => {
    //     const construct = constructs[entry.type];
    //     const val = state[construct.storeName].dict?.[entry.id];
    //}, {}), shallowEqual)

    const [listEx, setListEx] = useState(null);
    useEffect(() => {
        //console.log("list changed OR dsRevisionDict changed", list, dsRevisionDict);
        let newList = list ? [...list] : null;
        if( list ){
            for( let item of list ){
                if( item.type === "data-source" ){
                    // attach selected revision
                    // can only attach if data_source has been loaded AND dev or prod pointer is set!
                    // const ds = dsDict[item.name];
                    // const revId = isDev ? ds?.dev_revision : ds?.prod_revision;
                    const revId = dsRevisionDict[item.name];
                    if( revId ){
                        newList.push({ name: item.name + ".rev" , id: revId, parent_id: item.id, type: "data-source-revision" });
                    }
                }
                else if( item.type === "collection" ){
                    // attach all the images that should be loaded (if load images parameter??)
                    // todo:
                    //newList.push({ name: item.name + "_imagePaths" });
                }
                // else if( item.type === "selection" ){
                //     const sel = selDict[item.name];
                //     if( sel && sel.resources?.length ){
                //         for( let r of sel.resources ){
                //             newList.push({ name: item.name + `.r.${r.name}`, type: r.type,  id: })
                //         }
                //     }
                // }
            }
        }
        setListEx(newList);
    }, [list, dsRevisionDict])
        

    // valuesDict extracts the requested objects from redux state
    // this should not modify or wrap those objects, to prevent this useResources hook from re-running excessively
    // (this hook would re-run because valuesDict is returned via reducedresources)
    // Note that this uses shallowEqual -- but i'm not wrapping my head around that right now
    const valuesDict = useSelector(state => listEx?.reduce((accum, entry) => {

        const construct = constructs[entry.type];
        let val, loading, error;

        let parent_type = construct.parents?.[0];
        if( parent_type ){
            // item has a parent_id (e.g., item of type data-source-revision has parents ['data-source'])
            const parent_id = entry.parent_id;
            val = state[construct.storeName][parent_id]?.dict?.[entry.id];
            loading = state[construct.storeName][parent_id]?.entryLoading?.[entry.id];
            error = state[construct.storeName][parent_id]?.entryError?.[entry.id];
        }
        else{
            // item doesn't have a parent id (e.g., data-source, image, collection)
            val = state[construct.storeName].dict?.[entry.id];
            loading = state[construct.storeName].entryLoading?.[entry.id];
            error = state[construct.storeName].entryError?.[entry.id];
        }

        // special case for selection, but I might need to re-think this
        if (construct.name === "selection") {
            const lookup = state.selectionState?.[entry.id]
            const rval = lookup || val?.defaultValue;
            //val = JSON5.parse(rval);
            val = rval;
        }

        accum[entry.name] = val;
        if( loading ){
            accum[entry.name + ".loading"] = true;
        }
        if( error ){
            accum[entry.name + ".error"] = error;
        }

        return accum;
    }, {}), shallowEqual) || {};
    //console.log("valuesDict", valuesDict);

    // effect checks to see if we need to load anything
    useEffect(() => {
        //console.log("listEx changed", listEx);

        // no resources requested?
        if (!listEx) return;

        // items that need to be loaded (they aren't already triggered for load, and aren't already loaded or error'd)
        const needToLoad = listEx.filter(e => !valuesDict[e.name] && !valuesDict[e.name + ".loading"] && !valuesDict[e.name + ".error"]);
        if (!needToLoad?.length)
            return;
        
        for( let entry of needToLoad ){
            const construct = constructs[entry.type];
            const actionName = construct.actionName;
            reduxDispatch({ type: `${actionName}_SET_LOADING`, id: entry.id, parent_id: entry.parent_id });
        }

        // stuff to load:
        const f = async () => {
            for (let entry of needToLoad) {
                const construct = constructs[entry.type];
                const controller = controllers[entry.type];
                const actionName = construct.actionName;
                const parent_id_name = construct?.parents?.[0] ? construct?.parents[0] + "_id" : null;
                const parent_id = entry.parent_id; // might be undefined
                        
                //reduxDispatch({ type: `${actionName}_SET_LOADING`, id: entry.id, parent_id });
                try {
                    //console.log("api loading entry", entry);
                    const parentInjection = parent_id ? { [parent_id_name]: entry.parent_id } : null;
                    const item = await controller.auth_getItem(auth, { subscription_id, project_id, ...parentInjection }, entry.id);
                    // todo: might want to add an invalid_token check here like in createUseItemsHook
                    const newValue = {
                        ...item,
                        id: entry.id
                    };
                    const newEntry = construct.parseEntry(newValue);
                    reduxDispatch({ type: `${actionName}_SET`, id: entry.id, parent_id, entry: newEntry, value: newValue });
                }
                catch (err) {
                    //reduxDispatch({ type: `${actionName}_SET`, id: entry.id, value: null, error: err.message });
                    reduxDispatch({ type: `${actionName}_SET_LOADING`, id: entry.id, parent_id, loading: false, error: err.message });
                }

            }
        }
        f();
    }, [listEx]);
    //}, [list, dsRevisionDict]);


    
    const loading = listEx?.find(e => valuesDict[e.name + ".loading"]) ? true : false;
    const allLoaded = !loading;
    const error = listEx?.find(e => valuesDict[e.name]?.error)?.error;

    // const resources = allLoaded ? listEx?.map(e => {
    //     return {
    //         ...e,
    //         value: valuesDict[e.name]
    //     }
    // }) : null;

    const resources = useMemo(() => {
        //console.log("resources memo", allLoaded, listEx);
        if( !allLoaded ) return null;
        return listEx?.map(e => ({ ...e, value: valuesDict[e.name] }));
    }, [allLoaded, listEx, valuesDict])


    // let reducedResources = reduce ? resources?.reduce((a, e) => {
    //     a[e.name] = e.value;
    //     a["__TYPE__" + e.name] = e.type;
    //     a["__ID__" + e.name] = e.id;
    //     return a;
    // }, {}) : resources;
    
    const reducedResources = useMemo(() => {
        //console.log("reducedResources memo");
        if( !reduce ) return resources;
        return resources?.reduce((a, e) => {
            a[e.name] = e.value;
            a["__TYPE__" + e.name] = e.type;
            a["__ID__" + e.name] = e.id;
            return a;
        }, {});
    }, [reduce, resources])


    const retVal = useMemo(() => {
        //console.log("rr retVal memo");
        return [reducedResources, loading, error];
    }, [reducedResources, loading, error])


    return retVal;

}

