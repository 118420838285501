const getCommonLeftChars = (a, b) => {
    if( !a || !b ) return null;
    let i = 0;
    while( i < a?.length && i < b?.length && a[i] === b[i] ){
        i++;
    }
    return a.substring(0, i);
}

export const getCommonLeftStr = (strs) => {
    if( strs.length < 2 ) return null;
    let common = strs[0];
    for( let i = 1; i < strs.length; i++ ){
        common = getCommonLeftChars(common, strs[i]);
    }
    return common;
}

const getCommonRightChars = (a, b) => {
    if( !a || !b ) return null;
    let i = 0;
    while( i < a.length && i < b.length && a[a.length - 1 - i] === b[b.length - 1 - i] ){
        i++;
    }
    return a.substring(a.length - i, a.length);
}

export const getCommonRightStr = (strs) => {
    if( strs.length < 2 ) return null;
    let common = strs[0];
    for( let i = 1; i < strs.length; i++ ){
        common = getCommonRightChars(common, strs[i]);
    }
    return common;
}

const minLength = (str, len) => {
    if( str?.length < len ) return "";
    return str;
}

export const replaceVarNames = (labels, varNames) => {
    let retVal = [];
    for( let i = 0; i < labels.length; i++ ){
        let label = labels[i];
        let name = varNames[i];
        label = label?.replaceAll(name, "[var]");
        retVal.push(label);
    }
    return retVal;
}

export const autoTrim = (strs) => {
    if(!strs || strs?.length < 2 ) return strs;

    const commonLeft = minLength(getCommonLeftStr(strs) || "", 5);
    const commonRight = minLength(getCommonRightStr(strs) || "", 15);

    return strs.map(str => str ? str.substring(commonLeft.length, str.length - commonRight.length) : null);
}



const xmlEncodeLabel = (label) => {
    if( !label ) return null;
    label = label.replaceAll("<b>", "");
    label = label.replaceAll("</b>", "");
    label = label.replaceAll("&", "&amp;");
    label = label.replaceAll("\"", "&quot;");
    label = label.replaceAll("<", "&lt;");
    label = label.replaceAll(">", "&gt;");
    return label;
}

export const jsonEncodeLabel = (label) => {
    if( !label ) return null;
    //label = label.replaceAll("<b>", "");
    //label = label.replaceAll("</b>", "");
    //label = label.replaceAll("&", "&amp;");
    label = label.replaceAll("\"", "\\\"");
    //label = label.replaceAll("<", "&lt;");
    //label = label.replaceAll(">", "&gt;");
    return label;
}

export const formatMR = (fields, dsLabel) => {

    let lines = [];
    lines.push("<!-- mr -->");
    lines.push("<xtable dataset=\"" + dsLabel + "\">");
    lines.push("\t<metrics>");
    //lines.push("\t\t<xsl:copy-of select=\"/root/metrics[@name='basic']/metric\"/>");
    lines.push("\t\t<metric key=\"pct\" label=\"Percent\" valueFormat=\".0%\" syntaxTemplate=\"pctin({{entry}},1)\"/>");
    lines.push("\t</metrics>");
    lines.push("\t<entries type=\"array\">");
    fields.forEach(field => {
        lines.push(`\t\t<entry value=\"${field.name}\" label=\"${xmlEncodeLabel(field.label)}\" />`);
    })
    lines.push("\t</entries>");
    lines.push("</xtable>");

    return lines.join("\n");

}

export const formatSR = (field, valueLabels, dsLabel) => {

    let lines = [];
    lines.push("<!-- sr -->");
    lines.push("<xtable dataset=\"" + dsLabel + "\">");
    lines.push("\t<metrics>");
    //lines.push("\t\t<xsl:copy-of select=\"/root/metrics[@name='basic']/metric\"/>");
    lines.push("\t\t<metric key=\"pct\" label=\"Percent\" valueFormat=\".0%\" syntaxTemplate=\"pctin({{entry}},{{entry2}})\"/>");
    lines.push("\t</metrics>");
    lines.push("\t<entries type=\"array\">");
    valueLabels.forEach(vl => {
        lines.push(`\t\t<entry value=\"${field.name}\" value2=\"${vl.val}\" label=\"${xmlEncodeLabel(vl.label)}\" />`);
    })
    lines.push("\t</entries>");
    lines.push("</xtable>");

    return lines.join("\n");

}


export const formatSRSet = (fields, values, dsLabel) => {

    let lines = [];
    lines.push("<!-- sr set w/ options -->" );
    lines.push("<xtable dataset=\"" + dsLabel + "\">" );
    lines.push("\t<metrics>" );
    //lines.push("\t\t<xsl:copy-of select=\"/root/metrics[@name='default']/metric\"/>" );
    lines.push("\t\t<metric key=\"pct\" label=\"Percent\" valueFormat=\".0%\" syntaxTemplate=\"pctin({{entry}},{{option}})\"/>" );
    lines.push("\t</metrics>" );
    lines.push("\t<optionSets>" );
    lines.push(`\t\t<optionSet name=\"options1\" label=\"Options\" stack=\"true\" stackWidth=\"500\">` );
    values.forEach(vl => {
        lines.push(`\t\t\t<option value="${vl.val}" label="${xmlEncodeLabel(vl.label)}" />` );
    })
    lines.push("\t\t</optionSet>" );
    lines.push("\t</optionSets>" );
    lines.push("\t<entries type=\"array\">" );
    fields.forEach(field => {
        lines.push(`\t\t<entry value="${field.name}" label="${xmlEncodeLabel(field.label)}" />` );
    })
    lines.push("\t</entries>" );
    lines.push("</xtable>" );

    return lines.join("\n");

}

export const formatFilterGroup = (fields, valueLabels, dsLabel) => {

    const fieldNames = fields.map(f => f.name);
    const commonField = getCommonLeftStr(fieldNames) || fields?.[0]?.name;
    const useFieldLabel = fields?.length > 1;

    let lines = [];
    lines.push(`<filterGroup name="${dsLabel}-${commonField}" label="todo" category="todo" dataset="${dsLabel}" >`);
    fields.forEach(field => {
        valueLabels.forEach(vl => {
            lines.push(`\t<filter syntax="${field.name}==${vl.val}" label="${xmlEncodeLabel(useFieldLabel ? field.label : vl.label)}" />`);
        })
    })
    lines.push("</filterGroup>" );

    return lines.join("\n");

}
