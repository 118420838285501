import React from "react";
import { EditContentItemWrapper } from "../../edit/EditContentItemWrapper";



export const ViewSpacer = (props) => {

    const item = props.content_item;

    const height = item.height || 20;

    return <EditContentItemWrapper {...props} actionsPosition="above">
        <div style={{ height }}>
            
        </div>
    </EditContentItemWrapper>
        
}


