import { useEffect, useState } from "react";
import "./DataBindButton.scss";


export const DataBindButton = (props) => {

    const [bind, setBind] = useState(props.value);

    useEffect(() => {
        setBind(props.value);
    }, [props.value])

    return <span className={`data-bind-button ${bind ? "sel" : ""}`} key={bind ? "1" : "2"} onClick={() => setBind(!bind)}>
        <i className={bind ? "fas fa-plug" : "fal fa-plug"}/>
    </span>
    

}