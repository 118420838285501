import "./Teams.scss";
import { RecentProjectsBlock } from "./RecentProjectsBlock";
import { TeamsBlock } from "./TeamsBlock";
import { UserSection } from "./UserSection";
import { useParams } from "react-router-dom";
import { TeamUsersBlock } from "./TeamUsersBlock";
import { useState } from "react";

const TABS = [
    { id: "users", label: "Users" },
    { id: "access", label: "Access" },
]

export const Teams = (props) => {

    const [selTab, setSelTab] = useState("users");
   
    const params = useParams();
    const selTeamId = params.team_id;
    

    return <div className={`tab-content teams-dashboard`}>

        <div className="section recently-used">

            <TeamsBlock selectedTeamId={selTeamId} />

        </div>

        <div className="section recent-activity">

            <div className="tab-section2">
                {TABS.map(tab =>
                    <div className={`tab ${tab.id === selTab ? "sel" : ""}`}
                        onClick={() => setSelTab(tab.id)}
                    >
                        {tab.label}
                    </div>
                )}
            </div>

            {selTab === "users" && <TeamUsersBlock key={selTeamId} teamId={selTeamId} />}
            {selTab === "access" && <div style={{ padding: "10px 0" }}>
                todo: display subscriptions and projects that this team has access to.
            </div>}

            
        </div>

        

    </div>


}

