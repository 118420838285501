import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PageContext } from "../../../context/Contexts";
import { useAuth } from "../../../hooks/useAuth";
import { useContentItem } from "../../../hooks/useContentItem";
import { useContentItemsApi } from "../../../hooks/useContentItems";
import { useProjectId } from "../../../hooks/useProjectId";
import { reduceResources } from "../../../hooks/helper/reduceResources";
import { useResources } from "../../../hooks/useResources";
import { DataBindButton } from "../../edit/DataBindButton";
import { InstancesInput } from "../../instances/InstancesInput";
import { EditBackground } from "./EditBackground";
import { EditBorder } from "./EditBorder";
import { EditCRSyntax, EditCRSyntaxV2 } from "./EditCRSyntax";
import { EditResources } from "./EditResources";

const TABS = [
    { key: "content", label: "Content" },
    { key: "style", label: "Border" },
    { key: "background", label: "Background" },
]

   

export const EditJsxCardProperties = (props) => {

    const {project_id, subscription_id} = useProjectId();
    const [selTab, setSelTab] = useState("content");
    const api = useContentItemsApi();
    const page = useContext(PageContext);
    const dispatch = useDispatch();
    const auth = useAuth();

    const id = props.content_item_id;
    const [item, itemLoading, itemError] = useContentItem(page?.id, id);

    //const item = props.content_item;
    

    const [resources, resourcesLoading, resourcesError] = useResources(item?.resources, true);

    useEffect(() => {
        console.log("resources changed", resources);
    }, [resources])
    
    const rResources = reduceResources(resources, subscription_id, project_id, dispatch, auth);


    if (!item) return null;

    const updateItem = async (newItem) => {
        console.log('updateItem', newItem);
        await api.updateItem(item.id, newItem, { page_id: page.id });
    }

    const rowPad = 10;

    return <div>

        <div className='h-dock' style={{ marginBottom: rowPad }}>
                    
            <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                Instances
            </span>
            <span className='center'>
                <InstancesInput
                    style={{ width: "100%" }}
                    value={item.instances}
                    onChange={async (newInstances) => {
                        console.log('instancesInput changed', newInstances)
                        const newItem = {
                            ...item,
                            instances: newInstances
                        };
                        await updateItem(newItem);
                    }}
                />
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton/>
            </span>
        </div>

        <div className='h-dock' style={{ marginBottom: rowPad }}>
            
            <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                Visible
            </span>
            <span className='center v-center'>
                <input type="checkbox" />
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton/>
            </span>
        </div>

        <div className='h-dock' style={{ marginBottom: rowPad }}>
            
            <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                Allow Export
            </span>
            <span className='center v-center'>
                <input type="checkbox" checked={item?.allowExport} onChange={
                    async (ev) => {
                        const newItem = {
                            ...item,
                            allowExport: ev.target.checked
                        };
                        await updateItem(newItem);
                    }
                } />
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton/>
            </span>
        </div>

        <div className='h-dock' style={{ marginBottom: rowPad }}>
            
            <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                Fill Parent Absolute
            </span>
            <span className='center v-center'>
                <input type="checkbox" checked={item?.fillParentAbsolute} onChange={
                    async (ev) => {
                        const newItem = {
                            ...item,
                            fillParentAbsolute: ev.target.checked
                        };
                        await updateItem(newItem);
                    }
                } />
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton/>
            </span>
        </div>


        <div className="tab-section reset-margin">
            {TABS.map(tab =>
                <div
                    key={tab.key}
                    onClick={() => setSelTab(tab.key)}
                    className={`tab ${selTab === tab.key ? "sel" : ""}`}
                >
                    {tab.label}
                </div>
            )}
        </div>

        {selTab === "content" ? <>
            <EditResources value={item.resources} onChange={async (newList) => {
                const newItem = {
                    ...item,
                    resources: newList
                };
                await updateItem(newItem); //api.updateItem(item.id, newItem, { page_id: page.id });
            }} />

            <div style={{ marginBottom: 10 }}>
                Compute &amp; Render:
            </div>
            <div style={{ marginBottom: 10 }}>
                <label>
                    <input type="checkbox" 
                        checked={item.delayCompute}
                        onChange={async (ev) => {
                                const newItem = {
                                    ...item,
                                    delayCompute: ev.target.checked
                                };
                                await updateItem(newItem);
                        }}
                    /> Delay Compute
                </label>
            </div>
            <div style={{ marginBottom: 20 }}>
                <EditCRSyntaxV2
                    item={item}
                    resources={rResources}
                    placeholder={"[compute, render, renderTable]"}
                    onChange={
                        async (newStuff) => {
                            const newItem = {
                                ...item,
                                compute: newStuff.compute,
                                pipelines: newStuff.pipelines,
                                render: newStuff.render,
                                renderTable: newStuff.renderTable,
                            }
                            await updateItem(newItem);
                        }
                    }
                />
            </div>

            {/* <div style={{ marginBottom: 10 }}>
                Syntax (old):
            </div>
            <div style={{ marginBottom: 20 }}>
                <EditCRSyntax
                    item={item}
                    resources={rResources}
                    placeholder={"[compute, render, renderTable]"}
                    onChange={
                        async (newStuff) => {
                            const newItem = {
                                ...item,
                                compute: newStuff.compute,
                                render: newStuff.render,
                                renderTable: newStuff.renderTable,
                            }
                            await updateItem(newItem);
                        }
                    }
                />
            </div> */}


            

            

        </>
            : null
        }

        {selTab === "style" ?
                    <EditBorder
                        value={item.border}
                        onChange={async (val) => {
                            const newItem = {
                                ...item,
                                border: val
                            };
                            await updateItem(newItem);
                        }}
                    />
                    : null}

                {selTab === "background" ?
                    <EditBackground
                        value={item.background}
                        onChange={async (val) => {
                            const newItem = {
                                ...item,
                                background: val
                            }
                            await updateItem(newItem);
                        }}
                    />
                    : null}

    </div>
}