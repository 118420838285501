//import { version as v } from "../package.json";
import pkg from "../package.json";
const v = pkg.version;

const live = true;
const apiRootEndpoint = live ? "m-api.metricstudios.com" : "localhost:3050";
export const apiRoot = `${live ? "https" : "http"}://${apiRootEndpoint}`;

const yakApiRootEndpoint = "localhost:5000"
export const yakApiRoot = `http://${yakApiRootEndpoint}`;

export const webSocketEndpoint = `${live ? "https" : "http"}://${apiRootEndpoint}`;

export const blobUrl = "https://mstudio.blob.core.windows.net/";

export const tempImageRoot = `${blobUrl}client1-images/`;

export const client_id = "mstudio_webclient";
export const client_secret = "not-used";

export const version = v; // process.env.REACT_APP_VERSION || "1.0";
export const build = process.env.REACT_APP_BUILD || "0";
export const player_sub = process.env.REACT_APP_PLAYER_SUB;
export const player_title = process.env.REACT_APP_PLAYER_TITLE;
export const login_note = process.env.REACT_APP_LOGIN_NOTE;
export const login_image = process.env.REACT_APP_LOGIN_IMAGE;
export const login_bgColor = process.env.REACT_APP_LOGIN_BGCOLOR;
export const login_bgColor2 = process.env.REACT_APP_LOGIN_BGCOLOR2;

// also set these in metrics.scss
export const metrics = {
    "left-menu-collapse-point": 900,
    "left-menu-collapse-point2": 1400
}
