import "./Swatch.scss";

export const Swatch = (props) => {

    const name = props.name;
    const fg = props.fg;
    const bg = props.bg;

    const swatch = <div
        className={`swatch ${props.selected ? 'sel' : ''} ${props.hideHover ? ' hide-hover' : ''} ${props.staticHeight ? 'static-height' : ''}`}
        onClick={props.onClick}
        {...props.style}
    >
        <span className='fill' style={{
            backgroundColor: bg,
            color: fg
        }}
            onClick={props.onFillClicked}
        >
            <div className='inner-swatch' style={{
                backgroundColor: fg
            }}>
                &nbsp;
            </div>
            {!props.hideDelete ?
                <div className='btn-delete' onClick={props.onDeleteClicked}>
                    <i className='fal fa-times' />
                </div>
                : null}
            <div className='swatch-name'>
                {name}
            </div>
            <div>
                Fill: {bg?.toUpperCase?.()}
            </div>
            <div>
                Text: {fg?.toUpperCase?.()}
            </div>
        </span>

    </div>;

    return swatch;


}
