import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useDataSource } from "../../hooks/useDataSource";
import { useProjectId } from "../../hooks/useProjectId";
import { LeftMenu } from "../nav/LeftMenu";
import { Main } from "../nav/Main";
import { LogicInspector } from "./LogicInspector";
import { LogicInspectorOld } from "./LogicInspectorOld";
import { SchemaDiff } from "./SchemaDiff";
import { StringExplorer } from "./StringExplorer";

const items = [
    { key: "schema-diff", label: "Dataset Schema Diff", icon: "fad fa-not-equal" },
    { key: "logic-inspector", label: "Logic Inspector", icon: "fad fa-screwdriver" },
    { key: "logic-inspector2", label: "Logic Inspector old", icon: "fad fa-screwdriver" },
    { key: "scratch-pipeline", label: "Scratch Pipeline", icon: "fad fa-function" },
    { key: "string-explorer", label: "Strings Explorer", icon: "fal fa-comments-alt" },
    { key: "rec-view", label: "Record Viewer", icon: "fal fa-file" },
    { key: "distinct", label: "Distinct Values", icon: "fal fa-table-cells" },
]

export const Tools = (props) => {

    const { project_id, subscription_id } = useProjectId();
    const dispatch = useDispatch();

    const routerParams = useParams();
    const selectedItemId = routerParams.id;

    //const [selectedItem] = useDataSource(selectedItemId);

    const closeLeftMenu = () => {
        //dispatch({ type: "LEFT_MENU_OPEN_SET", value: false });
    }


    return <>
        
        <LeftMenu>
            <div className='title'>
                Tools
            </div>
            <div className='top-section' style={{
                
            }}>
            </div>
            <div className='scroller'>
                <div className='summary'>
                    {/* <p>
                        Select a data source or create new.
                    </p> */}
                </div>
                {
                    [...items]
                        .sort((a, b) => a.label?.localeCompare(b.label))
                        .map(item => <div key={item.key} className={`menu-item ${item.key === selectedItemId ? 'sel' : ''}`}>
                            <span className='grip'>
                                <i className={item.icon || 'fad fa-tools'} />
                            </span>
                            <Link
                                className='label'
                                to={`/subscription/${subscription_id}/project/${project_id}/tool/${item.key}`}
                                onClick={() => closeLeftMenu()}
                                title={item.label}
                            >
                                {item.label}
                            </Link>
                        </div>)
                }
            </div>
        </LeftMenu>

        <Main>
            {selectedItemId === "schema-diff" ? <SchemaDiff /> : null}
            {selectedItemId === "string-explorer" ? <StringExplorer /> : null}
            {selectedItemId === "logic-inspector" ? <LogicInspector /> : null}
            {selectedItemId === "logic-inspector2" ? <LogicInspectorOld /> : null}
        </Main>

    </>;


    
}

