import { useContext, useEffect, useState } from "react";
import { DataSourceContext, EditDataSourceContext, HasDataSourcesContext, HasPipelineContext } from "../../context/Contexts";
import { useDataSource } from "../../hooks/useDataSource";
import { DataSourceSelector } from "../data-sources/DataSourceSelector";
import { DataSourceRevisionSelector } from "../data-sources/DataSourceRevisionSelector";
import "./EditPipelineWithPreview.scss";
import { EditPipelineStages } from "./EditPipelineStages";
import { EditPipeline } from "./EditPipeline";
import { PreviewPipelineResults } from "./PreviewPipelineResults";
import { useAuth } from "../../hooks/useAuth";
import { useProjectId } from "../../hooks/useProjectId";
import { ViewRecords } from "../aggregation/ViewRecords";
import { computeApi } from "../../api/computeApi";
import { docLinks } from "../../doclinks";

const DEFAULT_LIMIT = 30;

export const EditPipelineWithPreview = (props) => {

    const [runCounter, setRunCounter] = useState(0);
    const [showStages, setShowStages] = useState(true);
    const [applyLimit, setApplyLimit] = useState(true);
    const [limitStr, setLimitStr] = useState(`${DEFAULT_LIMIT}`);

    const { pipeline, setPipeline } = useContext(HasPipelineContext);
    const [ds, dsLoading, dsError] = useDataSource(pipeline?.dataSourceId);
    const [result, setResult] = useState(null);
    const [computing, setComputing] = useState(false);
    const [error, setError] = useState(null);
    const [expired, setExpired] = useState(false);
    const auth = useAuth();
    const { subscription_id, project_id } = useProjectId();

    const edsContext = useContext(EditDataSourceContext); // has revision id
    const revisionId = edsContext ? edsContext.selectedRevisionId :
        ds?.dev_revision;

    const resources = props.resources;
    const context = {};
        
    const enabled_stages = pipeline?.stages
        ?.filter(s => !s.disabled)
        ?.map(s => s.data)
        || [];

    const run = () => {
        setRunCounter(runCounter + 1);
    };

    const save = () => {
        alert("todo");
    }

    useEffect(() => {
        setExpired(true);
        setError(null);
    }, [subscription_id, project_id, ds, revisionId, pipeline])

    // compute stages
    useEffect(() => {

        if( !ds || !revisionId || !enabled_stages ) return;

        const limit = applyLimit ? (parseInt(limitStr) || DEFAULT_LIMIT) : null;

        (async () => {
            setComputing(true);
            setError(null);
            try {
                //console.log("estages", enabled_stages);
                const res = await computeApi.computeV2(
                    auth,
                    { resources, context },
                    subscription_id,
                    project_id, 
                    ds?.id, 
                    revisionId,
                    enabled_stages,
                    {
                        limit // cursor limit
                    });
                setResult(res);
            }
            catch (err) {
                setError(err.message);
                setResult({ error: err.message });
            }
            setComputing(false);
            setExpired(false);
        })();

    }, [runCounter])

    return <div className="edit-pipeline-with-preview">
        {showStages &&
            <div className="stages full-dock">
                <div className="top title">
                    Pipeline
                    <a className="help" href={docLinks.aggregationPipeline} target="_blank" title="Help on aggregation pipeline">
                        <i className="fal fa-question-circle" />
                    </a>
                    {/* Scratch Pipeline
                    <button
                        className="btn-save"
                        onClick={() => save()}
                    >
                        save
                    </button>
                    <button
                        className="btn-save"
                        onClick={() => save()}
                    >
                        save as...
                    </button>
                    <button
                        className="btn-save"
                        onClick={() => save()}
                    >
                        load
                    </button> */}
                    <button 
                        className="collapser" 
                        onClick={() => setShowStages(false)}
                    >
                        <i className="fas fa-caret-left" />
                    </button>
                </div>
                <div className="center" style={{ position: "relative"}}>
                    <EditPipeline />
                </div>
            </div>
        }
        <div className="results full-dock">
            <div className="top title">
                {!showStages && <button
                    className="expander"
                    onClick={() => setShowStages(true)}
                >
                    <i className="fas fa-caret-right" />
                </button>} 
                <div className="text">Records</div>
                <div className="actions">
                    <span className={`btn action smaller ${computing ? "disabled" : ""} `} onClick={() => run()}>
                        {false && computing &&
                            <i className={`fal fa-spinner fa-pulse`} style={{ marginRight: 5 }} />
                        }
                        Run
                    </span>
                    <span>
                        <span className="btn action-light" onClick={() => setApplyLimit(!applyLimit)}>
                            {applyLimit ? 
                                <i key={1} className="fad fa-toggle-on" style={{ "--fa-primary-color": "white", "--fa-secondary-color": "dodgerblue", "--fa-secondary-opacity": 1.0 }}/> : 
                                <i key={3} className="fad fa-toggle-off off-color" />
                                
                            }
                            &nbsp; Limit:
                        </span>
                        <input type="text" 
                            style={{ width: 80 }} 
                            value={limitStr}
                            onChange={ev => setLimitStr(ev.target.value)}
                            disabled={!applyLimit} 
                            />
                    </span>
                </div>
            </div>
            <div className="center">
                <ViewRecords result={result} stages={enabled_stages} />
                {computing && <span className="message">
                    <span className="pop">
                        <i className={`fal fa-spinner fa-pulse`} style={{ marginRight: 5 }} />
                        Computing...
                    </span>
                </span>}
                {expired && !computing && !error && <span className="message">
                    <span className="pop">
                        <span onClick={() => run()} style={{ userSelect: "none" }}>
                            <i className="fad fa-message-arrow-up-right" />
                            Click "Run" button to update.
                        </span>
                        <span className="btn-dismiss" onClick={() => setExpired(false)}>
                            dismiss
                        </span>

                    </span>
                </span>}
                {error && <span className="message error">
                    <span className="pop">
                        <i className="fad fa-message-exclamation fa-1x" />
                        Error: {error}
                        <span className="btn-dismiss" onClick={() => setError(null)}>
                            dismiss
                        </span>
                    </span>
                </span>}
            </div>
            
        </div>
    </div>

}