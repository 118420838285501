import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";
import Popup from 'reactjs-popup';
import { useProjectId } from "../../hooks/useProjectId";
import { useSelection, useSelections, useSelectionsApi } from "../../hooks/useSelection";
import { TextFrame, VerifyFrame } from "../modal-dialog/VerifyFrame";
import { LeftMenu } from "../nav/LeftMenu";
import { Main } from "../nav/Main";
import { EditSelection } from "./EditSelection";
import { NewSelection } from "./NewSelection";


export const Selections = (props) => {

    const { project_id, subscription_id } = useProjectId();
    const dispatch = useDispatch();

    const routerParams = useParams();
    const selectedItemId = routerParams.id;
    const history = useHistory();

    const api = useSelectionsApi();
    
    const [items, itemsLoading, itemsError] = useSelections();
    const [selectedItem] = useSelection(selectedItemId);

    const closeLeftMenu = () => {
        //dispatch({ type: "LEFT_MENU_OPEN_SET", value: false });
    }

    return <>
        
        <LeftMenu>
            <div className='title'>
                User State
            </div>
            <div className='top-section'>

            </div>
            <div className='scroller'>
                <div className='summary'>
                    <p>
                        Session-wide variables are used to store the user's selections, e.g., filters, metrics, configs and anything you want. You'll likely wish to provide a default value.
                    </p>
                </div>
                {
                    itemsError ? <div className='list-content error'>Error loading selections</div> :
                    itemsLoading ? <div className='list-content'>Loading...</div> :
                    !items ? null :
                    [...items]
                        .sort((a, b) => a.label?.localeCompare(b.label))
                        .map(item => <div key={item.id} className={`menu-item ${item.id === selectedItemId ? 'sel' : ''}`}>
                            <span className='grip'>
                                <i className='fad fa-hand-pointer' />
                            </span>
                            <Link
                                className='label'
                                to={`/subscription/${subscription_id}/project/${project_id}/selection/${item.id}`}
                                onClick={() => closeLeftMenu()}
                            >
                                {item.label}
                            </Link>
                            <Popup
                                nested
                                contentStyle={{ padding: '0px' }}
                                trigger={<span className='menu' ><i className='far fa-ellipsis-h' /></span>}
                                position="right top">
                                {closeMenu => <ul className='popup-menu'>
                                        <Popup modal trigger={
                                            <li>Rename</li>
                                        }>
                                            {closeModal => <TextFrame
                                                title={"Rename selection"}
                                                label={item.label}
                                                initialTextValue={item.label}
                                                close={closeModal} confirmed={async (text) => {
                                                    const newItem = {
                                                        ...selectedItem,
                                                        label: text
                                                    };
                                                    await api.updateItem(item.id, newItem);
                                                }}
                                            />}
                                        </Popup>
                                    
                                        <Popup modal trigger={
                                            <li>Delete selection</li>
                                        }>
                                            {closeModal => <VerifyFrame
                                                title={"Delete selection"}
                                                label={item.label}
                                            close={closeModal}
                                            confirmed={async () => {
                                                await api.deleteItem(item.id);
                                                // navigate away?
                                            }}
                                        />}
                                        </Popup>
                                </ul>}
                            </Popup>
                        </div>)
                }
                <div className='menu-item-stub center'>
                    <Popup
                        modal
                        trigger={
                            <div className="btn action" >
                                <i className='glyph fal fa-plus' /> New selection
                            </div>
                        }>
                        {close => <NewSelection close={close} onNew={(itemInfo) => {
                            history.push(`/subscription/${subscription_id}/project/${project_id}/selection/${itemInfo.newId}`);
                        }} />}
                    </Popup>
                </div>
                
            </div>
        </LeftMenu>

        <Main>
            {selectedItem ? <EditSelection key={selectedItem.id} id={selectedItem.id}/> : null}
        </Main>
    </>;


    
}

