import { useContext, useEffect, useState } from "react";
import { PageContext } from "../../../context/Contexts";
import { useContentItem } from "../../../hooks/useContentItem";
import { useContentItemsApi } from "../../../hooks/useContentItems";
import { DataBindButton } from "../../edit/DataBindButton";
import { InstancesInput } from "../../instances/InstancesInput";
import { EditBackground } from "./EditBackground";
import { EditBorder } from "./EditBorder";
import { debounce } from "../../../utils/debounce";
import JSON5 from "json5";
import { ControlledCodeEditorFillContainer } from "../../code-editor/CodeEditor";

const TABS = [

    { key: "layout", label: "Layout" },
    { key: "style", label: "Border" },
    { key: "background", label: "Background" },
]

const debouncedSaveValue = debounce((val, callback) => {
    callback?.(val);
}, 2000);

const safeJson = (obj) => {
    if (!obj) return null;
    return JSON.stringify(obj, null, 4);
}


export const EditRowProperties = (props) => {

    const api = useContentItemsApi();
    const page = useContext(PageContext);

    const id = props.content_item_id;
    const [item] = useContentItem(page?.id, id);

    const [styleStr, setStyleStr] = useState(safeJson(item?.style));
    useEffect(() => {
        setStyleStr(safeJson(item?.style));
    }, [item?.style])


    const [gridTemplateColumns, setGridTemplateColumns] = useState(item?.gridTemplateColumns);
    
    useEffect(() => {
        if (!item) return;
        setGridTemplateColumns(item.gridTemplateColumns);
    }, [item]);

    const [selTab, setSelTab] = useState("layout");

    if (!item) return null;

    const rowPad = 10;

    const updateItem = async (newItem) => {
        console.log('updateItem', id, newItem);
        await api.updateItem(id, newItem, { page_id: page.id });
    }

    const debouncedUpdateItem = (newItem) => {
        console.log('debounced');
        debouncedSaveValue(newItem, updateItem);
    }

    

    return <div>
     

        <div className='h-dock' style={{ marginBottom: rowPad }}>
                    
            <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                Instances
            </span>
            <span className='center'>
                <InstancesInput
                    style={{ width: "100%" }}
                    value={item.instances}
                    onChange={async (newInstances) => {
                        const newItem = {
                            ...item,
                            instances: newInstances
                        };
                        await updateItem(newItem);
                    }}
                />
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton/>
            </span>
        </div>

        <div className='tab-section reset-margin' style={{ marginBottom: 20 }}>
                    {TABS.map(tab =>
                        <span
                            key={tab.key}
                            className={`tab ${selTab === tab.key ? "sel" : ""}`}
                            onClick={() => setSelTab(tab.key)}
                        >
                            {tab.label}
                        </span>
                    )}
                    
                </div>


        {selTab === "layout" ?
            <div className="form1">
                <div className='label'>
                    <div className='label-line'>
                        gridTemplateColumns
                    </div>
                    <div className='value-line'>
                        <input
                            type="text"
                            className="full-width"
                            placeholder={"repeat(auto-fill,minmax(200px,1fr)"}
                            value={gridTemplateColumns}
                            onChange={(ev) => {
                                const newValue = ev.target.value;
                                setGridTemplateColumns(newValue);

                                const newItem = {
                                    ...item,
                                    gridTemplateColumns: newValue
                                }
                                debouncedUpdateItem(newItem);
                            }} />
                    </div>
                </div>



                <div style={{ marginBottom: 10 }}>
                    <span className="btn action"
                        onClick={async () => {
                            const newItem = {
                                ...item,
                                style: JSON5.parse(styleStr)
                            }
                            //console.log('newItem', newItem);
                            await updateItem(newItem);
                        }}
                    >
                        Save Style
                    </span>
                </div>
                <div style={{
                    border: "1px solid #cfcfcf",
                    position: "relative",
                    height: 400
                }}>
                    <ControlledCodeEditorFillContainer
                        editorProps={{
                            options: {
                                lineNumbers: 'off',
                                glyphMargin: false,
                                folding: false,
                                minimap: {
                                    enabled: false
                                }
                            }
                        }}
                        language="json"
                        value={styleStr}
                        onChange={(code) => {
                            setStyleStr(code);
                        }}
                    />
                </div>



            </div>
        : null}

        {selTab === "style" ?
            <EditBorder
                value={item.border}
                onChange={async (val) => {
                    const newItem = {
                        ...item,
                        border: val
                    };
                    await updateItem(newItem);
                }}
            />
            : null}

        {selTab === "background" ?
            <EditBackground
                value={item.background}
                onChange={async (val) => {
                    const newItem = {
                        ...item,
                        background: val
                    }
                    await updateItem(newItem);
                }}
            />
            : null}


    </div>
}
