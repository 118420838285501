import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/Contexts";
import { useTeams } from "../../hooks/useTeams";
import { MyLoginStats } from "./MyLoginStats";
import "./UserSection.scss";

export const UserSection = (props) => {

    const authContext = useContext(AuthContext);
    const [teams, teamsLoading, teamsError, refreshTeams] = useTeams();

    return <div className="section user">

        <div className="name-etc">

            <div className="name">
                <span className="text">
                    {authContext?.authUser?.name}
                </span>
            </div>
            <span className="account">
                {authContext?.authUser?.accountName} 
                <div>
                    <i className="fas fa-shield-halved" />
                </div>
            </span>

        </div>

        <div className="photo">
            <span className="photo-border-outer">
                <img 
                    style={{
                        width: 100,
                        height: 100,
                        objectFit: "cover"

                    }}
                    src={`https://mstudio.blob.core.windows.net/avatars/${authContext?.authUser?.id}.jpg`} 
                />
            </span>
        </div>

        <div className="bottom">
            Achievements
            <div className="achievements-strip">
                <span className="btn-achievement">
                    <i className="fad fa-square-0"/>
                </span>
                <span className="btn-achievement">
                    <i className="fad fa-ban-bug"/>
                </span>
                <span className="btn-achievement">
                    <i className="fad fa-message-smile"/>
                </span>
                <span className="btn-achievement">
                    <i className="fas fa-pizza-slice"/>
                </span>
                <span className="btn-achievement">
                    <i className="fad fa-brush"/>
                </span>
            </div>

            <hr/>

            <MyLoginStats />

            <hr/>

            <div style={{ display: "flex", flexDirection: "row" }}>
                <span style={{ flexGrow: 1 }}>
                    My Teams
                </span>
                <span className="btn action small">New</span>
            </div>
            <div className="team-list">
                {teams?.filter?.((_, i) => i < 5)?.map(team =>
                    <Link key={team.id} className="team-item" to={`/team/${team.id}`}>
                        <i className="fad fa-people-group" /> {team.name}
                    </Link>
                )}
                <Link className="team-item view-more" to={`/teams`}>
                    View all teams...
                </Link>
            </div>
        </div>
        
    </div>

}