import React, { useContext } from "react";
import { PageContext } from "../../context/Contexts";
import { useContentItem } from "../../hooks/useContentItem";
import { getTypeInfo as getContentTypeInfo } from "./ContentTypes";

const wrapMessage = (message) => null;

export const LoadContentItem = (props) => {

    const page = useContext(PageContext);
    const id = props.id;

    const [contentItem, contentItemLoading, contentItemError] = useContentItem(page.id, id);

    if (contentItemLoading) return wrapMessage("Loading content item...");
    if (contentItemError) return wrapMessage("Error loading content item.");
    if (!contentItem) return null;

    const ctype = contentItem["ctype"];
    const ctypeInfo = getContentTypeInfo(ctype);

    let Component = ctypeInfo.viewer;

    return <Component
        level={props.level + 1}
        
        page={page}
        content_item={contentItem}
        edit={props.edit}
        pType={props.pType}
    />;

}

