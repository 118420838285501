import { useState } from "react";
import Popup from "reactjs-popup";
import "./Swatch.scss";
import { SwatchEditor } from "./SwatchEditor";
import { Swatch } from "./Swatch";


export const SwatchEdit = (props) => {

    const name = props.name;
    const fg = props.fg;
    const bg = props.bg;

    const [editorOpen, setEditorOpen] = useState(false);

    const deleteMe = (ev) => {

        ev.preventDefault();
        ev.stopPropagation();
        
        props.onDelete?.();
    }

    return <>
        <Swatch
            name={name}
            fg={fg}
            bg={bg}
            onClick={props.onClick}
            style={props.style}
            onFillClicked={() => {
                setEditorOpen(true);
            }}
            onDeleteClicked={deleteMe}
        />
        <Popup
            modal
            nested
            open={editorOpen}
            onClose={() => setEditorOpen(false)}
            closeOnDocumentClick={false}
        >
            {closeModal => <SwatchEditor
                close={closeModal}
                bg={bg} fg={fg} name={name}
                onSave={async (newItem) => {
                    await props.onSave?.(newItem);
                    setEditorOpen(false);
            }} />}
        </Popup>

    </>;


}
