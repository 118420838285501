import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useDataSourcesApi } from "../../../hooks/useDataSource";
import { CONNECTORS } from "./_Connectors";
import * as globals from "../../../globals";
import axios from "axios";
import { getAuthHeaders } from "../../../api/restMethods";
import { ModalFrame } from "../../modal-dialog/ModalFrame";
import { NewDataSourceContext } from "../NewDataSource";
import { format } from "d3-format";
import { fileSizeFormat } from "../../../utils/FileSizeFormat";

const fmt1 = format(",.0f");


export const PasteConnector = (props) => {

    const connector_id = props.connector_id;

    const routerParams = useParams();
    const subscription_id = routerParams.subscription_id;
    const project_id = routerParams.project_id;

    const ndsContext = useContext(NewDataSourceContext);
    const { working, setWorking } = ndsContext;
    const { workStatusMessage, setWorkStatusMessage } = ndsContext;
    const { setButtonText } = ndsContext;
    

    const [newName, setNewName] = useState("");
    const [error, setError] = useState(null);
    const [pasting, setPasting] = useState(false);
    const [pastedData, setPastedData] = useState(null);
    const [parseInfo, setParseInfo] = useState(null);
    const auth = useAuth();
    const api = useDataSourcesApi();

    
    setButtonText("Upload");
    ndsContext.hackHandler.buttonClicked = async () => {
        let id = props.id;
        if (!props.alreadyExists) {
            id = await createDataSource();
        }
        const success = await uploadRevision(id); // returns true if success
        return success ? id : null;
    }

    const createDataSource = async () => {

        let newId = null;

        setWorkStatusMessage("Creating data source...");
        setWorking(true);
        
        try {

            // create data source
            let item = {
                type: "file",
                fileType: "csv",
                structured: true,
                connector: connector_id,
                label: parseInfo?.name || "Data source"
            }
            const itemInfo = await api.newItem(item);
            newId = itemInfo.newId;

        }
        catch (err) {
            setError(err.message);
        }
        setWorking(false);

        return newId;
        
    }

    const uploadRevision = async (ds_id) => {

        try {

            setWorkStatusMessage(`Uploading data [${parseInfo.name}]...`);
            setWorking(true);

            const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/data-source/${ds_id}/file`;

            let formData = {
                pastedData
            }
            if (ndsContext.notes) {
                formData.notes = ndsContext.notes;
            }

            await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    ...getAuthHeaders(auth)
                },
                onUploadProgress: (ev) => {
                    console.log("onUploadProgress", ev);
                }
            });

            setWorking(false);
            return true;
            
        }
        catch (error) {
            setError(error.message);
            setWorking(false);
            return false;
        }
    }

    

    

    return <div className='form1 new-data-source' style={{ maxWidth: 500 }}>

        <div className="upload-data-file">

            <div className="icon" style={{ fontSize: 50, float: "right", marginRight: 10 }}>
                <i className="fal fa-paste" />
            </div>

            <div className="instructions" style={{ maxWidth: 400, marginBottom: 20 }}>
                <p>
                    Copy content into clipboard, then click the paste button below:<br />
                    <br/>
                    Excel Reminder:
                    <ul style={{ marginTop: 0 }}>
                        <li>Copy full precision (~16 decimal places)</li>
                        <li>Turn off any filters</li>
                    </ul>
                </p>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 20,
                    fontSize: 12,
                    //border: "1px dashed teal"
                }}>

                    <button style={{
                        padding: 20
                    }}
                        onClick={async () => {
                            setPasting(true);
                            const str = await navigator.clipboard.readText();
                            setPastedData(str);
                            setParseInfo(parseData(str));
                            //console.log("pastedContent", str);
                            setPasting(false);
                        }}
                    >
                        Paste
                    </button>
                    <div style={{ 
                        //border: "1px dashed red"
                     }}>
                        {pasting ? <div>
                            pasting...
                        </div>
                            :
                            <div style={{ lineHeight: "20px" }}>
                                <div>
                                    {fileSizeFormat(pastedData?.length || 0)} pasted
                                </div>
                                {parseInfo?.error ?
                                    <div>Error</div>
                                    : null
                                }
                                {parseInfo?.name ?
                                    <div>
                                        <b>{parseInfo.name}</b> {`(${fmt1(parseInfo.nbRows || 0)} rows x ${fmt1(parseInfo.nbCols || 0)} cols)`}
                                    </div>
                                    : null
                                }
                                {parseInfo?.name && parseInfo?.nbCols > 0 && parseInfo?.nbRows > 0 ?
                                    <div>
                                        Click upload button below to continue.
                                    </div>
                                    : null
                                }
                                
                            </div>
                        }
                    </div>

                </div>
            </div>

            <div className="input-section">
                {working ?
                    <div style={{ fontSize: "10pt", marginBottom: 5 }}>
                        Please wait. Creating data source...
                    </div>
                    : null
                }
                {(working || workStatusMessage) ?
                    <div style={{ fontSize: "10pt", marginBottom: 5 }}>
                        {workStatusMessage}
                    </div>
                    : null
                }
                
            </div>
            
        </div>

        <div className="label-line">
        </div>

        {error && !working ?
            <div>
                <p style={{ color: "red" }}>
                    <i className="fas fa-warning" />
                    &nbsp;
                    {error}
                </p>
            </div>
            : null
        }
        
    </div>


}



const parseData = (str) => {
    if (!str) return null;

    const lines = str.split("\n");
    if (lines?.length < 2) return { error: "Expecting at least 2 lines (table name and col headers)" };

    const name = lines[0]?.trim();
    const firstRow = lines[1];
    const cols = firstRow.split('\t');

    if (name.length > 100 || name.split('\t')?.length > 1)
        return { error: "Unexpected name" };

    return {
        name,
        nbRows: lines.length - 2,
        nbCols: cols?.length
    }

}
