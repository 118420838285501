import { format } from "d3-format"

const fmt0 = format(",.0f");
const fmt1 = format(",.1f");


export const fileSizeFormat = (bytes) => {
    if (bytes < 1024)
        return fmt0(bytes) + " B";
    else if (bytes < (1024 * 1024))
        return fmt1(bytes / 1024) + " KB";
    else if (bytes < (1024 * 1024 * 1024))
        return fmt1(bytes / 1024 / 1024) + " MB";
    else if (bytes < (1024 * 1024 * 1024 * 1024))
        return fmt1(bytes / 1024 / 1024 / 1024) + " GB";
    else
        return fmt1(bytes / 1024 / 1024 / 1024 / 1024) + " TB";
}