import { useSelector } from "react-redux";



export const Main = (props) => {

    const open = useSelector(state => state.ui.leftMenuOpen);
    const _rm_open = useSelector(state => state.ui.rightMenuOpen) && !props.ignoreRightMenu;

    let rm_open = props.rightMenuOpen === undefined ? _rm_open : props.rightMenuOpen;
    
    const version = props.v2 ? "v2" : "v1";

    return <div
        className={`project-main ${open ? "" : "lm-closed"} ${rm_open ? "right-menu-open" : ""} ${version} ${props.noLeftMenu ? "no-left-menu" : ""} ${props.className || ""}`}>
        {props.children}
    </div>;

}

