import { parseResource } from "./parseResource";
import JSON5 from "json5";

export const reduceResourcesStage1Hack = (resources) => {
    let reducedResources = resources?.reduce((a, e) => {
        a[e.name] = e.value;
        a["__TYPE__" + e.name] = e.type;
        a["__ID__" + e.name] = e.id;
        return a;
    }, {});
    return reducedResources;
}

export const reduceResources = (r, subscription_id, project_id, dispatch, auth) => {
    if (!r) return {};

    const keys = Object.keys(r).filter(k => !k.startsWith("__TYPE__") && !k.startsWith("__ID__") && !k.endsWith(".rev"));

    const rr = keys.reduce((acc, key) => {
        const id = r[`__ID__${key}`];
        const type = r[`__TYPE__${key}`];
        let value = r[key];

        if( type === "data-source" ){
            const rev = r[`${key}.rev`];
            value = { 
                ...value,
                revision: rev?.id,
                created: rev?.created,
                mounted: rev?.mounted,
                fields: rev?.fields,
                inspection: rev?.inspection,
            };
            delete value.dev_revision;
            delete value.prod_revision;
            if( rev?.udata ){
                try{
                    value.value = JSON5.parse(rev.udata);
                }
                catch(err){
                    value.valueError = err.message;
                }
            }
        }

        const pValue = parseResource(type, value, { subscription_id, project_id, dispatch, id, auth });
        acc[key] = pValue;
        return acc;
    }, {});

    return rr;

}
