import { useContext, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useDispatch } from "react-redux";
//import SplitterLayout from "react-splitter-layout";
import SplitterLayout from "../../extlib/react-splitter-layout/SplitterLayout";
import { computeApi } from "../../../api/computeApi";
import { ContentItemContext, HasComputeContext, HasPipelinesContext, HasRenderContext, HasRenderTableContext } from "../../../context/Contexts";
import { useAuth } from "../../../hooks/useAuth";
import { useLazyLib } from "../../../hooks/useLazyLib";
import { useProjectId } from "../../../hooks/useProjectId";
import { compileFunction } from "../../../utils/Compiler";
import { runRender } from "../../../utils/ContentProcessor";
import { defaultLib } from "../../../utils/Lib";
import { DataTable } from "../../common/DataTable";
import { ErrorBoundary } from "../../error-boundary/ErrorBoundary";
import { EditComputeSyntax } from "./EditComputeSyntax";
import { truncateObjectAtLevel } from "./EditCRSyntaxBox";
import { EditPipelines } from "../../pipeline/EditPipelines";
import "./EditCRSyntaxBox2.scss";

import { EditRenderSyntax } from "./EditRenderSyntax";
import { EditRenderTableSyntax } from "./EditRenderTableSyntax";
import { useResources } from "../../../hooks/useResources";
import { reduceResources } from "../../../hooks/helper/reduceResources";

const CODE_TABS = [
    { id: "render", label: "Render" },
]

const HELPER_TABS = [
    { id: "rendered", label: "Rendered" },
    { id: "data", label: "Result" },
    { id: "context", label: "Context" },
    { id: "lib", label: "Lib" },
    { id: "resources", label: "Resources" },
]

const defaultApiHack = {
}

export const EditRSyntaxBox = (props) => {

    const { subscription_id, project_id } = useProjectId();
    const auth = useAuth();
    const dispatch = useDispatch();

    const item = props.item;

    const lib2 = useLazyLib();
    const lib = {
        ...defaultLib,
        ...lib2
    };
    
    
    const getLibHack = () => truncateObjectAtLevel(lib, 1);
    

    const [pipelines, setPipelines] = useState(props.pipelines);
    const [compute, setCompute] = useState(props.compute);
    //const [payload, setPayload] = useState({ computing: false, needsCompute: false, result: null, status: null });
    const payload = props.payload;

    const [render, setRender] = useState(props.render);
    const [rendered, setRendered] = useState(null);

    const [selCodeTab, setSelCodeTab] = useState("render");
    const [selHelperTab, setSelHelperTab] = useState("rendered");

    const [resources, resourcesLoading, resourcesError] = useResources(item?.jdata?.resources, true);
    const rResources = reduceResources(resources, subscription_id, project_id, dispatch, auth);

    const [expanded, setExpanded] = useState(false);

    const context = useContext(ContentItemContext);
    
    useEffect(() => {
        setRender(props.render);
    }, [props.render])

    const env = {
        resources: rResources,
        context: context
    };


    const api = {
        
    };


    // compile the renderer
    useEffect(() => {

        if( !lib2 ) return;
        if( !render ) return;
        console.log("running render");
        
        (async () => {

            // render
            const r = runRender(render, context, rResources, api, lib, payload);
            setRendered(r || null);

        })();

    }, [render, payload, resourcesLoading]);


    const saveMe = () => {
        props.onChange?.(render)
    }

    const rContext = {
        render,
        setRender
    }


    return <HasRenderContext.Provider value={rContext}>
        <div className="edit-crsyntax-box2">
            <div className={`frame1 ${expanded ? "expanded" : ""}`}>
                <div className="titlebar full-dock">

                    <div className="center xtab-section">
                        {/* Title goes here */}
                    </div>

                    <span className="buttons right">
                    <span className="btn icon" onClick={() => saveMe()}>
                            <i className="fas fa-save" />
                        </span>
                        <span className="btn icon" onClick={() => setExpanded(!expanded)}>
                            <i className="fal fa-expand" />
                        </span>
                        <span className="btn icon" onClick={() => props.close?.()}>
                            <i className="fal fa-times" />
                        </span>
                    </span>

                </div>
                <div className="main">
                    <div className="all" >
                        <SplitterLayout primaryIndex={1}>
                            <div className="full-dock fill-parent-absolute">
                                <div className="top tab-section no-margin">
                                    {CODE_TABS.map(tab =>
                                        <span 
                                            key={tab.id}
                                            className={`tab ${selCodeTab === tab.id ? "sel" : ""}`}
                                            onClick={() => setSelCodeTab(tab.id)}
                                        >
                                            {tab.label}
                                        </span>
                                    )}
                                </div>
                                <div className="center fill-parent-absolute" style={{ overflow: "hidden" }} >
                                    {selCodeTab === "compute" ? <EditComputeSyntax /> : null}
                                    {selCodeTab === "pipelines" ? <EditPipelines noOutput /> : null}
                                    {selCodeTab === "settings" ? <div>
                                        edit settings
                                    </div> : null}
                                    {selCodeTab === "render" ? <EditRenderSyntax /> : null}
                                    {selCodeTab === "table-specs" ? <EditRenderTableSyntax /> : null}
                                </div>
                            </div>
                            <div className="full-dock fill-parent-absolute">
                                <div className="top tab-section no-margin">
                                    {HELPER_TABS.map(tab =>
                                        <span 
                                            key={tab.id}
                                            className={`tab ${selHelperTab === tab.id ? "sel" : ""}`}
                                            onClick={() => setSelHelperTab(tab.id)}
                                        >
                                            {tab.label}
                                        </span>
                                    )}
                                </div>
                                <div className="center full-dock fill-parent-absolute">
                                    {payload.computing &&
                                        <div className="top compute-running">
                                            Computing...
                                        </div>
                                    }
                                    <div className="center">
                                        {selHelperTab === "rendered" && 
                                            <ErrorBoundary>
                                                {rendered}
                                            </ErrorBoundary>
                                        }
                                        {selHelperTab === "renderedTable" && 
                                            <ErrorBoundary>
                                                {/* {renderedTable} */}
                                            </ErrorBoundary>
                                        }
                                        {selHelperTab === "data" && 
                                            <div className="json-wrap">
                                                <ReactJson
                                                    src={payload.result || undefined}
                                                    name={"result"}
                                                    theme={"rjv-default"}
                                                    iconStyle="circle"
                                                    displayDataTypes={false}
                                                    enableClipboard={true}
                                                    collapsed={1}
                                                    />
                                            </div>
                                            // pipelines?.map(p => {
                                            //     const res = computeResult?.[p.id];
                                            //     return <ViewRecords result={res} stages={{ todo: 1 }} />;
                                            // })
                                            
                                        }
                                        {selHelperTab === "context" ? 
                                            <div className="json-wrap">
                                                <ReactJson src={context}
                                                    name={"context"}
                                                    theme={"rjv-default"}
                                                    iconStyle="circle"
                                                    displayDataTypes={false}
                                                    enableClipboard={true}
                                                    collapsed={1}
                                                />
                                            </div>
                                        : selHelperTab === "resources" ? 
                                            <div className="json-wrap">
                                                <ReactJson src={rResources}
                                                    name={"resources"}
                                                    theme={"rjv-default"}
                                                    iconStyle="circle"
                                                    displayDataTypes={false}
                                                    enableClipboard={true}
                                                    collapsed={1}
                                                    />
                                            </div>
                                        : selHelperTab === "api" ? 
                                            <div className="json-wrap">
                                                <ReactJson src={{}}
                                                    name={"api"}
                                                    theme={"rjv-default"}
                                                    iconStyle="circle"
                                                    displayDataTypes={false}
                                                    enableClipboard={true}
                                                    collapsed={1}
                                                />
                                            </div>
                                        : selHelperTab === "lib" ?
                                            <div className="json-wrap">
                                                <ReactJson
                                                    src={getLibHack()}
                                                    name={"lib"}
                                                    theme={"rjv-default"}
                                                    iconStyle="circle"
                                                    displayDataTypes={false}
                                                    enableClipboard={true}
                                                    collapsed={1}
                                                    />
                                            </div>
                                        : null
                                        }



                                    </div>
                                </div>
                            </div>
                        </SplitterLayout>
                    </div>
                </div>
                
            </div>
        </div>
    </HasRenderContext.Provider>;

}

