import React, { useEffect, useState } from "react";

export const useLazyLib = () => {

    const [lib, setLib] = useState(null);

    useEffect(() => {
        import("../utils/Lib2")
            .then(obj => {
                setLib(obj?.Lib2);
            })
            .catch(err => {
                console.error(err);
            })
        }
    );

    return lib;
  
}
