import { EditAggregateStage } from "./group/EditAggregateStage";
import { EditLimitStage } from "./limit/EditLimitStage";
import { EditSkipStage } from "./skip/EditSkipStage";
import { EditSampleStage } from "./sample/EditSampleStage";
import { EditSortStage } from "./sort/EditSortStage";
import { EditGenericStage } from "./EditGenericStage";
import { GenericStage } from "./GenericStage";
import { EditCustomStage } from "./custom/EditCustomStage";
import { GroupStage } from "./group/GroupStage";
import { EditSelectStage } from "./select/EditSelectStage";
import { EditFilterStage } from "./filter/EditFilterStage";
import { docLinks } from "../../../doclinks";

export const PIPELINE_STAGES = [
    {
        id: "match",
        label: "Filter",
        icon: "far fa-filter",
        helpLink: docLinks.stage_filter,
        //initialValue: { $match: {} },
        initialValue: { '$mstudio.match': [] },
        //editor: EditGenericStage,
        editor: EditFilterStage,
        model: GenericStage,
        
    },
    {
        id: "limit",
        label: "Limit",
        icon: "far fa-scissors",
        helpLink: docLinks.stage_limit,
        initialValue: { $limit: 20 },
        editor: EditLimitStage,
        model: GenericStage
    },
    {
        id: "skip",
        label: "Skip",
        icon: "far fa-angles-right",
        helpLink: docLinks.stage_skip,
        initialValue: { $skip: 5 },
        editor: EditSkipStage,
        model: GenericStage
    },
    {
        id: "aggregate",
        label: "Aggregate",
        icon: "far fa-sigma",
        helpLink: docLinks.stage_aggregate,
        initialValue: { ["$mstudio.group_v2"]: {
            dims: [{ id: "m", type: "agg", label: "Aggregations", items: [] }],
            rows: ["m"]
        }},
        editor: EditAggregateStage,
        model: GroupStage
    },
    {
        id: "columnize",
        label: "Pivot",
        icon: "far fa-table-pivot",
        initialValue: { 
            ["$mstudio.columnize"]: {
                col: "dim1",
                rows: ["m"],
                cells: ["cell"]
            }
        },
        editor: EditGenericStage,
        model: GenericStage
    },
    // {
    //     id: "columnize2",
    //     label: "Pivot (2g)",
    //     initialValue: { 
    //         ["$mstudio.columnize"]: {
    //             col: "dim1",
    //             rows: ["m", "dim2"],
    //             cells: ["cell"]
    //         }
    //     },
    //     editor: EditGenericStage,
    //     model: GenericStage
    // },
    {
        id: "sort",
        label: "Sort",
        icon: "far fa-arrow-down-square-triangle",
        helpLink: docLinks.stage_sort,
        initialValue: { $sort: null },
        editor: EditSortStage,
        model: GenericStage
    },
    {
        id: "sample",
        label: "Sample",
        icon: "far fa-eye-dropper",
        helpLink: docLinks.stage_sample,
        initialValue: { $sample: { size: 200 } },
        editor: EditSampleStage,
        model: GenericStage
    },
    {
        id: "select",
        label: "Select",
        icon: "far fa-arrow-pointer",
        helpLink: docLinks.stage_select,
        initialValue: { $project: null },
        editor: EditSelectStage,
        model: GenericStage
    },
    {
        id: "map",
        label: "Map",
        icon: "far fa-shuffle",
        //helpLink: docLinks.stage_select,
        initialValue: { $project: null },
        editor: EditGenericStage,
        model: GenericStage
    },
    // {
    //     id: "addfields",
    //     label: "Add Fields",
    //     initialValue: { $addFields: {
    //         newField: "asdf"
    //     } },
    //     editor: EditGenericStage,
    //     model: GenericStage
    // },
    {
        id: "ms-addfields",
        label: "Add Fields",
        icon: "fa-sharp fa-solid fa-square-plus",
        helpLink: docLinks.stage_addFields,
        initialValue: { ['$mstudio.addFields']: {
            newField: "syntax"
        } },
        editor: EditGenericStage,
        model: GenericStage
    },
    {
        id: "ms-dropfields",
        label: "Drop Fields",
        icon: "far fa-square-minus",
        //helpLink: docLinks.stage_addFields,
        initialValue: { ['$mstudio.dropFields']: {
            todo: "syntax"
        } },
        editor: EditGenericStage,
        model: GenericStage
    },
    // {
    //     id: "wf",
    //     label: "Add Window Functions",
    //     initialValue: { $setWindowFields: {
    //         partitionBy: "optional",
    //         sortBy: {
    //             "sortField1": 1, // sort order
    //             "sortField2": 1
    //         },
    //         output: {
    //             "rank": { 
    //                 "$rank": {} 
    //             }
    //         }
    //     } },
    //     editor: EditGenericStage,
    //     model: GenericStage
    // },
    {
        id: "ms-wf",
        label: "Add Window Fields",
        icon: "far fa-window-frame",
        helpLink: docLinks.stage_addWindowFields,
        initialValue: { '$mstudio.setWindowFields': {
            partitionBy: "optional",
            output: {
                "net": "avg(cell.val)"
            }
        } },
        editor: EditGenericStage,
        model: GenericStage
    },
    // {
    //     id: "code",
    //     label: "Manual code stage",
    //     initialValue: { $setWindowFields: null },
    //     editor: null,
    //     model: GenericStage
    // },
    {
        id: "custom-stage",
        label: "Custom Stage",
        icon: "fab fa-js",
        initialValue: { ["$mstudio.custom-stage"]: { resourceId: "todo" } },
        editor: EditCustomStage,
        model: GenericStage
    },
    // {
    //     id: "custom-accumulator",
    //     label: "Custom Accumulator",
    //     initialValue: { ["$mstudio.custom-accumulator"]: { resourceId: "todo" } },
    //     editor: EditGenericStage,
    //     model: GenericStage
    // },
]


export const getStageInnerData = (stageData) => {
    if( !stageData ) return null;
    const firstKey = Object.keys(stageData)?.[0];
    if( !firstKey ) return null;
    return stageData[firstKey];
}