import { useContext, useState } from "react";
import { PipelineStageEditorContext } from "../../../context/Contexts";
import { debounce } from "../../../utils/debounce";
import { ControlledCodeEditorFillContainer } from "../../code-editor/CodeEditor";
import { getStageInnerData } from "./_PipelineStages";
import JSON5 from "json5";

const debounce1000 = debounce((callback, ...args) => {
    callback(args);
}, 1000);

export const EditGenericStage = (props) => {

    const stageContext = useContext(PipelineStageEditorContext);
    const stage = stageContext.stage;
    const firstKey = stage?.data ? Object.keys(stage?.data)?.[0] : null;
    const innerData = stage?.data?.[firstKey]; // getStageInnerData(stage?.data);

    const [stageStr, setStageStr] = useState(JSON.stringify(innerData, null, 4));

    const save = (val) => {
        try{
            //const i = parseInt(val);
            //stageContext.replaceStageData({ $limit: i });
            stageContext.replaceStageData({
                [firstKey]: JSON5.parse(val)
            })
        }
        catch{}
    }

    return <div style={{ height: 200 }}>
        <ControlledCodeEditorFillContainer 
            language="json" 
            value={stageStr}
            onChange={(newCode) => {
                setStageStr(newCode)
                debounce1000(save, newCode);
            }} 
            editorProps={{
                options: {
                    lineNumbers: 'off',
                    glyphMargin: false,
                    folding: false,
                    minimap: {
                        enabled: false
                    }
                }
            }}
        />
    </div>
    
}