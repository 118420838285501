import { useState } from "react";
import "./FieldSearch.scss";
import { debounce } from "../../utils/debounce";

const debounce1000 = debounce((callback, ...args) => {
    callback(...args);
}, 500);


export const FieldSearch = (props) => {

    const [searchText, setSearchText] = useState(null);

    const updateSearchTerms = (stext) => {
        const terms = stext ? stext.split(" ") : null;
        const options = {};
        props.onChange?.({ terms, options });
    }

    return <div className="field-search">

        <span className="icon">
            <i className="fal fa-search"/>
        </span>

        <span className="search-main">
            <span className="text">
                <input 
                    type="text" 
                    className="tb1"
                    value={searchText || ""}
                    placeholder={"search term(s)"}
                    onChange={(ev) => {
                        const str = ev.target.value;
                        setSearchText(str);
                        debounce1000(updateSearchTerms, str);
                    }}/>
            </span>
        </span>

        <span className="options">
            <span className="btn" title={"Match case"}>
                <i className="fal fa-font-case"/>
            </span>
            <span className="btn" title={"Match whole word"}>
                <span className="center" style={{ textDecoration: "underline" }}>
                    ab
                </span>
            </span>
            <span className="btn" title={"Use regular expression"}>
                <span className="center">
                    .
                    <i className="fal fa-asterisk"/>
                </span>
            </span>
            <span className="btn" title={"Search value labels"}>
                <span className="center" style={{ }}>
                    vals
                </span>
            </span>
        </span>

        <span className="cancel"
            onClick={() => {
                setSearchText(null);
                updateSearchTerms(null);        
            }}
        >
            <i className="fal fa-times"/>
        </span>
    </div>
}
