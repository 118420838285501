export const UsageBar = (props) => {

    const usage = props.usage || 0;
    const rUsage = Math.round(usage * 100);

    return <div style={{
        ...props.style,
        display: "grid",
        gridTemplateColumns: `${rUsage}% ${100 - rUsage}%`,
        border: "1px solid #cfcfcf",
        backgroundColor: "#ffffff"
    }}>
        <div style={{
            display: "inline-block",
            backgroundColor: "#1298df",
            height: "15px"
        }}>
        </div>
        
    </div>
}