import { useContext } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/Contexts";
import { useAuth, useAuthUser } from "../../hooks/useAuth";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { useTeams } from "../../hooks/useTeams";

const TEAM_TABS = [
    { id: "my", label: "My Teams" },
    { id: "all", label: "All" },
    { id: "companies", label: "Companies" }
]

const getMatches = (searchText, items) => {
    let text = searchText.toLocaleLowerCase();
    let textArr = text.split(" ");
    return items.filter(item => {
        let itemText = item.name?.toLocaleLowerCase();
        for( let t of textArr ){
            if(!itemText.includes(t)) return false;
        }
        return true;
    })
}

export const TeamsBlock = (props) => {

    
    const { authUser } = useContext(AuthContext);
    const [searchText, setSearchText] = useState(null);
    //const [showAllTeams, setShowAllTeams] = useState(false);
    const [whichTeams, setWhichTeams] = useState("my");
    const [teams, teamsLoading, teamsError, refreshTeams] = useTeams();
    const [allTeams, allTeamsLoading, allTeamsError, refreshAllTeams] = useTeams({ all: true })

    const selectedTeamId = props.selectedTeamId;

    let displayTeams = whichTeams === "my" ? teams : allTeams;

    if( whichTeams === "companies" ){
        displayTeams = displayTeams.filter(team => team.is_company);
    }

    if( searchText ){
        displayTeams = getMatches(searchText, teams);
    }

    const isSuperAdmin = authUser?.isSuperAdmin;

    const teamTabs = isSuperAdmin ? TEAM_TABS : [TEAM_TABS[0]];

    return <div className="recent-block">
        <div className="title">
            <span className={`label ${isSuperAdmin ? "tab-section2 no-margin" : ""}`}>
                {teamTabs.map(ttab =>
                    <span className={`tab ${isSuperAdmin && whichTeams === ttab.id ? "sel" : ""}`} onClick={() => setWhichTeams(ttab.id)}>
                        {ttab.label}
                    </span>
                )}
            </span>
            
            <span className="btn action smaller new">
                New
            </span>
        </div>
        <div className="search">
            <input type="text" placeholder="&#xF002; Find a team" 
                value={searchText || ""}
                onChange={(ev) => setSearchText(ev.target.value)}
            />
        </div>
        <div className="item-list">
            {teamsLoading && <div className="item loading">
                Loading...
            </div>}
            {displayTeams?.map(team =>
                <Link key={team.id} className={`item ${(selectedTeamId === team.id ? "sel" : "")}`} to={`/team/${team.id}`}>
                    {team.is_company ?
                        <i className={`fas fa-building`} style={{ margin: "0 3px" }} />
                        : <i className={`fad fa-people-group`} />
                    }
                    &nbsp; {team.name}
                </Link>
            )}
        </div>
    </div>;
    

}