import { ViewJsxCard } from "./view/ViewJsxCard";
import { ViewReactCard } from "./view/ViewReactCard";
import { ViewRow } from "./view/ViewRow";
import { ViewSection } from "./view/ViewSection";
import { ViewTile } from "./view/ViewTile";
import { ViewUnknownWidget } from "./view/ViewUnknownWidget";
import { EditPageProps } from "./edit/EditPageProps";
import { EditJsxCardProperties } from "./edit/EditJsxCardProperties";
import { EditReactCardProperties } from "./edit/EditReactCardProperties";
import { EditTileProperties } from "./edit/EditTileProperties";
import { GenericContentPropertyEditor } from "./edit/GenericContentPropertyEditor";
import { EditRowProperties } from "./edit/EditRowProperties";
import { Configurator, ViewConfigAsRows } from "../configurator/Configurator";
import { ViewSpacer } from "./view/ViewSpacer";
import { ViewCarousel } from "./view/ViewCarousel";
import { SelectCGroupsInput } from "../filter-books/SelectCGroupsInput";
import { EditConfiguratorProperties } from "./edit/EditConfiguratorProperties";
import { EditCGroupsSelectProperties } from "./edit/EditCGroupsSelectProperties";
import { SelectCollectionEntryInput } from "../filter-books/SelectCollectionEntryInput";
import { EditCollectionEntrySelectProperties } from "./edit/EditCollectionEntrySelectProperties";

export const CTYPE_PAGE = "PAGE";
export const CTYPE_SECTION = "SECTION";
export const CTYPE_ROW = "ROW";
export const CTYPE_TILE = "TILE";
export const CTYPE_JSX = "JSX";
export const CTYPE_REACT = "REACT";
export const CTYPE_DATACARD = "DATACARD";
export const CTYPE_CONFIGURATOR = "CONFIGURATOR";
export const CTYPE_SPACER = "SPACER";
export const CTYPE_CAROUSEL = "CAROUSEL";
export const CTYPE_SELECT_CGROUPS = "SELECT_CGROUPS";
export const CTYPE_SELECT_COLLECTION_ENTRY = "SELECT_COLLECTION_ENTRY";

export const getTypeInfo = (ctype) => {
    switch (ctype) {
        case CTYPE_PAGE: return {
            label: "Page",
            newDefault: {},
            viewer: null,
            propertyEditor: EditPageProps
        };
        case CTYPE_SECTION: return {
            label: "Section",
            category: "Layout",
            icon: "fal fa-square-dashed",
            newDefault: {},
            viewer: ViewSection,
            propertyEditor: GenericContentPropertyEditor
        };
        case CTYPE_ROW: return {
            category: "Layout",
            label: "Row",
            icon: "fal fa-rectangle-wide",
            newDefaults: [
                {
                    label: "Row\n(2 columns)",
                    value: {
                        style: {
                            minHeight: 150,
                            gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))"
                        }
                    }
                },
                {
                    label: "Row\n(3 columns)",
                    value: {
                        style: {
                            minHeight: 150,
                            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))"
                        }
                    }
                },
                {
                    label: "Row\n(4 columns)",
                    value: {
                        style: {
                            minHeight: 150,
                            gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))"
                        }
                    }
                },
            ],
            viewer: ViewRow,
            propertyEditor: EditRowProperties
        };
        case CTYPE_TILE: return {
            label: "Tile",
            icon: "fas fa-square",
            category: "Regular Content",
            newDefault: {
                text: "default tile text"
            },
            viewer: ViewTile,
            propertyEditor: EditTileProperties
        };
        case CTYPE_JSX: return {
            category: "Custom Content",
            label: "JSX Content",
            icon: "fab fa-js",
            newDefaults: [
                {
                    label: "JSX basic",
                    value: {
                        render: "return <div style={{ height: 50 }}>blank</div>;\n"
                    }
                },
                {
                    label: "JSX computed",
                    value: {
                        compute: "return [\r\n\t{ label: \"First\", a: 1.2, b: 3.4 },\r\n\t{ label: \"Second\", a: 5.6, b: 7.8 },\r\n]\r\n",
                        render: "return <div>\r\n\t<pre>\r\n\t\t{JSON.stringify(result,null,4)}\r\n\t</pre>\n</div>;\n"
                    }
                },
            ],
            hasCRSyntax: true,
            viewer: ViewJsxCard,
            propertyEditor: EditJsxCardProperties
        };
        case CTYPE_REACT: return {
            category: "Custom Content",
            label: "React Component",
            icon: "fab fa-react",
            newDefault: {
                render: "return (props) => {\r\n\treturn <div>hello from React</div>\r\n};"
            },
            hasCRSyntax: true,
            viewer: ViewReactCard,
            propertyEditor: EditReactCardProperties
        };
        case CTYPE_CONFIGURATOR: return {
            category: "Simulator",
            label: "Configurator",
            icon: "fal fa-list",
            newDefault: {
                layout: "rows"
            },
            viewer: Configurator,
            propertyEditor: EditConfiguratorProperties
        }
        case CTYPE_SPACER: return {
            label: "Spacer",
            category: "Layout",
            icon: "fal fa-distribute-spacing-vertical",
            newDefaults: [
                {
                    label: "Spacer",
                    value: {
                        height: 20
                    }
                },
            ],
            viewer: ViewSpacer,
            propertyEditor: GenericContentPropertyEditor
        }
        case CTYPE_CAROUSEL: return {
            category: "Layout",
            label: "Carousel",
            icon: "fal fa-gallery-thumbnails",
            newDefault: {
                
            },
            viewer: ViewCarousel,
            propertyEditor: GenericContentPropertyEditor
        }
        case CTYPE_SELECT_CGROUPS: return {
            category: "Selection",
            label: "Select C. Groups",
            icon: "fal fa-hand-pointer",
            newDefault: {
                filterBookId: null,
                selectorId: null
            },
            viewer: SelectCGroupsInput,
            propertyEditor: EditCGroupsSelectProperties
        }
        case CTYPE_SELECT_COLLECTION_ENTRY: return {
            category: "Selection",
            label: "Select Collection Entry",
            icon: "fal fa-hand-pointer",
            newDefault: {
                collectionId: null,
                selectorId: null
            },
            viewer: SelectCollectionEntryInput,
            propertyEditor: EditCollectionEntrySelectProperties
        }
        default: return {
            category: "Other",
            label: ctype,
            viewer: ViewUnknownWidget,
            propertyEditor: GenericContentPropertyEditor
        }
    }
}

export const getAllowedChildTypes = (ctype, parentAllowedTypes) => {
    if (!ctype) return null;
    switch (ctype) {
        case CTYPE_PAGE: return [
            CTYPE_SECTION,
            CTYPE_ROW,
            CTYPE_TILE,
            CTYPE_JSX,
            CTYPE_REACT,
            CTYPE_CONFIGURATOR,
            CTYPE_SPACER,
            CTYPE_CAROUSEL,
            CTYPE_SELECT_CGROUPS,
            CTYPE_SELECT_COLLECTION_ENTRY
        ];
        case CTYPE_SECTION: return parentAllowedTypes;
        case CTYPE_ROW: return [CTYPE_TILE, CTYPE_JSX, CTYPE_REACT, CTYPE_SELECT_CGROUPS, CTYPE_SPACER, CTYPE_SELECT_COLLECTION_ENTRY];
        case CTYPE_CAROUSEL: return [CTYPE_TILE, CTYPE_JSX, CTYPE_REACT, CTYPE_SECTION, CTYPE_ROW];
        default: return [];
    }
}

// const const page = {


//     children: [
//         {
//             _id: "...",
//             _ctype: "PAGE",
//             children: [

//             ]

//         }
//     ]

// }
