import Popup from "reactjs-popup"
import { useSelection, useSelectionState } from "../../hooks/useSelection"
import { EditContentItemWrapper } from "../edit/EditContentItemWrapper"
import { SelectCGroupsDlg } from "./SelectCGroupsDlg"
import "./SelectCGroupsInput.scss"

export const SelectCGroupsInput = (props) => {

    const item = props.item;
    const [state, stateLoading, stateError] = useSelectionState(item.jdata?.selectionId);
    
    const selectionId = item.jdata?.selectionId; // selection?.label;
    let label = "[Select Comparison Groups]";
    if (state && Array.isArray(state)) {
        if (state.length === 0) {
            // leave label as is
        }
        else if (state.length === 1) {
            label = "1 Group selected"
        }
        else {
            label = `${state.length} Comparison Groups selected`
        }
        //label = state.map(f => f.label)?.join(", ")
    }

    const style1 = {
        ...item.style
    };
    
    const content =
        stateLoading ? <div className="message h-centered no-pad">Loading selection...</div> :
            stateError ? <div className="message h-centered error no-pad">Error loading selection...</div> :
                <Popup
                    modal
                    nested
                    closeOnDocumentClick={false}
                    closeOnEscape={false}
                    trigger={<div className="select-cgroups-input" style={style1}>
                        {label}
                    </div>}
                >
                    {close => <SelectCGroupsDlg id={item?.jdata?.filtersId} selectionId={selectionId} close={close} />}
                </Popup>;

    return <EditContentItemWrapper {...props} actionsPosition="above">
        {content}
    </EditContentItemWrapper>
    
}