
import { useContext, useEffect, useState } from "react";
import Popup from "reactjs-popup";
//import { PipelineEditorContext, PipelineStageEditorContext } from "../../context/Contexts";
import "./EditPipelineStage.scss";
import { PIPELINE_STAGES } from "../aggregation/stages/_PipelineStages";
import { PipelineEditorContext, PipelineStageEditorContext } from "../../context/Contexts";
import { debounce } from "../../utils/debounce";

const debounceKeypress = debounce((callback, arg) => {
    callback(arg);
}, 1000);


export const EditPipelineStage = (props) => {

    const stage = props.stage;
    //const [expanded, setExpanded] = useState(true);

    const pipelineEditorContext = useContext(PipelineEditorContext);

    const proto = PIPELINE_STAGES.find(s => s.id === stage?.type);
    

    // compute prior stages
    const stageIndex = pipelineEditorContext.stages.indexOf(stage);
    const priorStages = pipelineEditorContext.stages.filter((s, i) => i < stageIndex);

    //const stageLabel = proto.label;
    const [stageLabel, setStageLabel] = useState(stage.label);

    useEffect(() => {
        setStageLabel(stage.label);
    }, [stage])

    if( !proto ) return <div>stage type unknown</div>;

    const saveStageLabel = (val) => {
        console.log("saveStageLabel", val);
        try{
            const newStage = {
                ...stage,
                label: val,
            }
            props.onChanged?.(newStage);
        }
        catch{}
    }

    const disabled = stage?.disabled;
    const enabled = !disabled;

    const toggleEnabled = () => {
        const newStage = {
            ...stage,
            disabled: !stage.disabled
        };
        if( !newStage.disabled ){
            delete newStage.disabled;
        }
        props.onChanged?.(newStage);
    }

    const toggleExpanded = () => {
        const newStage = {
            ...stage,
            expanded: !stage.expanded
        }
        props.onChanged?.(newStage);
    }

    const replaceStageData = (newData) => {
        const newStage = {
            ...stage,
            data: newData
        }
        props.onChanged?.(newStage);
    }

    const Editor = proto.editor;

    return <PipelineStageEditorContext.Provider value={{
        stage,
        priorStages,
        replaceStageData
    }}>
        <div className={`pipeline-stage ${props.className || ""}`}>
            <div className="stage-header">
                <span className="section1">
                    <span className="btn icon disabled" {...props.handleProps} title="Click and hold to drag">
                        <i className="fas fa-grip" style={{ color: "#808080" }}/>
                    </span>
                    <span className="btn icon" onClick={() => toggleExpanded()} title={`Click to ${stage?.expanded ? "collapse" : "expand"}`}>
                        <i className={stage?.expanded ? "fal fa-folder-open" : "fal fa-folder"}/>
                    </span>
                    <span className="btn icon" title={`Click to ${enabled ? "disable stage" : "enable stage"}`}
                        onClick={() => toggleEnabled()}
                    >
                        <i className={enabled ? "fad fa-toggle-on toggle-on" : "fad fa-toggle-off toggle-off"} style={{ fontSize: 15 }}/>
                    </span>
                </span>
                <span className="stage-label">
                    <input type="text" className="input-stage-label" value={stageLabel || undefined} placeholder={proto.label} 
                        onChange={(ev) => {
                            setStageLabel(ev.target.value);
                            debounceKeypress(saveStageLabel, ev.target.value);
                        }}
                    />
                </span>
                <span className="section3">
                    {proto.helpLink ? 
                        <a className="btn icon" href={proto.helpLink} target="_blank" title="Help on this stage">
                            <i className="fal fa-question-circle"/>
                        </a> 
                        : null
                    }

                    <Popup
                        position="bottom right"
                        arrow={false}
                        trigger={
                            <span className="btn icon" title="Stage actions menu">
                                <i className="far fa-ellipsis"/>
                            </span>
                        }
                    >
                        {close => <div className="popup-menu">
                            <div className="menu-item disabled">
                                Duplicate
                            </div>
                            <div className="separator"/>
                            <div className="menu-item disabled">
                                Insert Stage Before
                            </div>
                            <div className="menu-item disabled">
                                Append Stage After
                            </div>
                            <div className="separator"/>
                            <div className="menu-item" onClick={() => {
                                pipelineEditorContext?.deleteStage(stage);
                                close();
                            }}>
                                <i className="icon fal fa-trash-alt"/> 
                                Delete stage
                            </div>
                        </div>}
                    </Popup>

                    <span 
                        title="Delete stage"
                        className="btn icon" 
                        onClick={() => {
                            pipelineEditorContext?.deleteStage(stage);
                        }}
                    >
                        <i className="far fa-times"/>
                    </span>
                    
                    {/* <Popup 
                        modal
                        keepTooltipInside
                        trigger={
                            <span className="btn icon" >
                                <i className="fal fa-brackets-curly"/>
                            </span>
                        }
                    >
                        {close => <EditStageJson close={close} />}
                    </Popup> */}
                    
                </span>
            </div>
            {stage?.expanded ?
                <div className={`stage-data ${disabled ? "disabled" : ""}`}>
                    {Editor ? <Editor /> : <>
                        <pre>
                            {JSON.stringify(stage, null, 2)}
                        </pre>
                    </>}
                </div>
                : null
            }
            {false ?
                <div className="stage-collapser"
                    onClick={() => toggleExpanded()}
                >
                    <i className={stage?.expanded ? `fal fa-chevron-up` : `fal fa-chevron-down`} />
                </div>
                : null
            }
        </div>
    </PipelineStageEditorContext.Provider>;
    
}