import { PasteConnector } from "./PasteConnector";
import { PasteMetaConnector } from "./PasteMetaConnector";
import { SavUploadConnector } from "./SavUploadConnector";
import { FlowConnector } from "./FlowConnector";
import { XlsxUploadConnector } from "./XlsxUploadConnector";

export const CONNECTORS = [
    {
        id: "sav",
        label: ".sav File Upload",
        xstyle: {
            //backgroundColor: "red",
            //color: "#ffffff",
            backgroundImage: "url('/images/connectors/spss-logo.png')",
            backgroundPosition: "center top",
            backgroundSize: "50%",
            backgroundRepeat: "no-repeat"
        },
        widget: SavUploadConnector
    },
    {
        id: "xlsx",
        label: "Excel File Upload (.xlsx)",
        xstyle: {
        },
        widget: XlsxUploadConnector
    },
    {
        id: "ascii",
        label: "ASCII Table Upload (CSV, DAT)",
        xstyle: {
            //backgroundColor: "red",
            //color: "#ffffff",
            backgroundImage: "url('/images/connectors/spss-logo.png')",
            backgroundPosition: "center top",
            backgroundSize: "50%",
            backgroundRepeat: "no-repeat"
        },
        //widget: SavUploadConnector
    },
    {
        id: "json",
        label: "JSON Upload (semi-structured)",
        xstyle: {
            //backgroundColor: "red",
            //color: "#ffffff",
            backgroundImage: "url('/images/connectors/spss-logo.png')",
            backgroundPosition: "center top",
            backgroundSize: "50%",
            backgroundRepeat: "no-repeat"
        },
        //widget: SavUploadConnector
    },
    {
        id: "xml",
        label: "XML Upload (semi-structured)",
        xstyle: {
            //backgroundColor: "red",
            //color: "#ffffff",
            backgroundImage: "url('/images/connectors/spss-logo.png')",
            backgroundPosition: "center top",
            backgroundSize: "50%",
            backgroundRepeat: "no-repeat"
        },
        //widget: SavUploadConnector
    },
    {
        id: "paste",
        label: "Paste table from clipboard",
        sources: [
            { id: "ptab", label: "Paste delimited data from clipboard" },
        ],
        widget: PasteConnector
    },
    {
        id: "paste-meta",
        label: "Paste meta from clipboard",
        sources: [
            { id: "pjson", label: "Paste json or xml data from clipboard" },
        ],
        widget: PasteMetaConnector
    },
    {
        id: "flow",
        label: "Data flow (ETL)",
        sources: [
            {
                id: "blank1",
                label: "Blank flow"
            },
            {
                id: "example1",
                label: "Example flow: merge waves"
            },
            {
                id: "example2",
                label: "Example flow: join coefficients"
            },
            {
                id: "example3",
                label: "Example flow: create recodes"
            }
        ],
        widget: FlowConnector
    },
    {
        id: "qualtrics",
        label: "Qualtrics data pull",
    },
    {
        id: "load_api",
        label: "Load from API",
        sources: [
            {
                id: "rest",
                label: "REST API"
            },
            {
                id: "ftp",
                label: "FTP or SFTP pull"
            },
            {
                id: "sql",
                label: "SQL database pull"
            },
            {
                id: "mongodb",
                label: "MongoDB database pull"
            },
        ]
    },
    {
        id: "load_sftp",
        label: "Load from FTP/SFTP",
        sources: [
            {
                id: "rest",
                label: "REST API"
            },
            {
                id: "ftp",
                label: "FTP or SFTP pull"
            },
            {
                id: "sql",
                label: "SQL database pull"
            },
            {
                id: "mongodb",
                label: "MongoDB database pull"
            },
        ]
    },
    {
        id: "sql",
        label: "External SQL db",
        sources: [
            {
                id: "sql",
                label: "SQL database"
            },
            {
                id: "monbodb",
                label: "MongoDB database"
            }
        ]
    },
    {
        id: "mongodb",
        label: "External Mongo db",
        sources: [
            {
                id: "sql",
                label: "SQL database"
            },
            {
                id: "monbodb",
                label: "MongoDB database"
            }
        ]
    },
]
