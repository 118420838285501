import { Link, Route, Switch, useLocation } from "react-router-dom";
import { TopBar } from "../nav/TopBar";
import { Dashboard } from "./Dashboard";
import "./DashboardOuter.scss";
import { Teams } from "./Teams";

export const TABS = [
    { id: "dashboard", alt_id: "", label: "Dashboard", to: "/" },
    { id: "subscriptions", alt_id: "subscription", label: "Subscriptions", to: "/subscriptions" },
    { id: "teams", alt_id: "team", label: "Teams", to: "/teams" },
]

export const DashboardOuter = (props) => {

    const loc = useLocation();
    
    let pcs = loc.pathname.split('/');
    let selTab = pcs?.[1];
    

    return <div className="top-dashboard">

        <TopBar />
        
        <div className="main empty-space1">

            <div className="width-limiter">
            
                <div className='tab-space tab-section no-margin' style={{ margin: "0 10px" }}>
                    {TABS.map(tab =>
                        <Link 
                            key={tab.id} 
                            className={`tab ${(selTab === tab.id || selTab === tab.alt_id) ? "sel" : ""}`}
                            to={tab.to}
                        >

                            {tab.label}
                        </Link>
                    )}
                    
                </div>

                <Switch>
                    <Route path="/teams" component={Teams} />
                    <Route path="/team/:team_id" component={Teams} />
                    <Route path="/" component={Dashboard} />
                </Switch>

            </div>

        </div>

    </div>;
}

