import { Link } from "react-router-dom";
import { docLinks } from "../../../doclinks";
import { useCollections } from "../../../hooks/useCollection";

const HELP_LINKS = [
    { label: "Getting Started", url: docLinks.cloud_getting_started },
    // { label: "Tutorial Videos", url: "https://metricstudios.com/articles" },
    // { label: "Samples and Templates", url: "https://metricstudios.com/docs/report-builder" },
    // { label: "Syntax Reference", url: "https://metricstudios.com/docs/report-builder/syntax" },
    // { label: "Discussions/ Issues", url: "https://metricstudios.com/discuss" },
    { label: "Analytics", url: docLinks.analytics },
    { label: "Visualization", url: docLinks.visualization },
    { label: "(All Documentation)", url: docLinks.home }, 
]

export const HelpTile = (props) => {

    return <div className="help-tile tile gray-bg pad">
        <div className="title">
            Tools &amp; Knowledgebase
        </div>

        <div>
            {HELP_LINKS.map((item, idx) =>
            
                <a key={idx} href={item.url} target="_blank" rel="noreferrer" className="item-row">
                    {/* <i className="fal fa-external-link" style={{ fontSize: 9 }}/> */}
                    &nbsp; {item.label}
                </a>
            )}
        </div>

        
    </div>
}