import "./LogicInspector.scss";
import Chart from 'react-google-charts'
import { useEffect, useState } from "react";
import { computeApi } from "../../api/computeApi";
import { useAuth } from "../../hooks/useAuth";
import { useProjectId } from "../../hooks/useProjectId";


const GetVarsFromExprs = (exprs) => {
    return exprs.reduce((a, e) => {
        const vars = e.GetVars();
        vars?.forEach(v => {
            if( !a.includes(v) ){
                a.push(v);
            }
        })
        return a;
    }, [])
}

class Condition {
    constructor(){

    }
    Verify = (obj) => { throw Error("not implemented"); }
    GetVars = () => { throw Error("not implemented"); }
}

class EqualsCondition extends Condition {
    constructor(name, value){
        super();
        this.name = name;
        this.value = value;
    }
    Verify = (obj) => this.value === null ? (obj?.[this.name] === null || obj?.[this.name] === undefined) : obj?.[this.name] === this.value;
    GetVars = () => [this.name];
}

class IsValidCondition extends Condition {
    constructor(name){
        super();
        this.name = name;
    }
    Verify = (obj) => (obj?.[this.name] !== null && obj?.[this.name] !== undefined);
    GetVars = () => [this.name];
}


class AlwaysTrueCondition extends Condition {
    constructor(){
        super();
    }
    Verify = () => true;
    GetVars = () => [];
}

class AndCondition extends Condition {
    constructor(...exprs){
        super();
        this.exprs = exprs;
    }
    Verify = (obj) => {
        for( let expr of this.exprs ){
            const v = expr.Verify(obj);
            if( !v ) return false;
        }
        return true;
    }
    GetVars = () => GetVarsFromExprs(this.exprs);
}

class OrCondition extends Condition {
    constructor(...exprs){
        super();
        this.exprs = exprs;
    }
    Verify = (obj) => {
        for( let expr of this.exprs ){
            const v = expr.Verify(obj);
            if( v ) return true;
        };
        return false;
    }
    GetVars = () => GetVarsFromExprs(this.exprs);
}

export const LogicInspectorOld = (props) => {

    const auth = useAuth();
    const { subscription_id, project_id } = useProjectId();

    const [data, setData] = useState(null);

    let stages = []

    // stages.push({
    //     buckets: [
    //         { expr: new AlwaysTrueCondition(), label: "Total" }
    //     ]
    // })

    stages.push({
        buckets: [
            { expr: new EqualsCondition( "qa1_01", 1 ), "label": "T-shirts" },
            { expr: new EqualsCondition( "qa1_02", 1 ), "label": "Casual tops (excluding t-shirts)" },
            { expr: new EqualsCondition( "qa1_03", 1 ), "label": "Jeans" },
            { expr: new EqualsCondition( "qa1_04", 1 ), "label": "Pants and/or shorts (excluding jeans)" },
            { expr: new EqualsCondition( "qa1_05", 1 ), "label": "Underwear bottoms" },
            { expr: new EqualsCondition( "qa1_06", 1 ), "label": "Panties" },
            { expr: new EqualsCondition( "qa1_07", 1 ), "label": "Bras" },
            { expr: new EqualsCondition( "qa1_08", 1 ), "label": "Socks" },
            { expr: new EqualsCondition( "qa1_09", 1 ), "label": "Sweatshirts/Sweatpants" },
            { expr: new EqualsCondition( "qa1_10", 1 ), "label": "Active/athletic apparel (e.g., yoga pants, track pants, etc.)" },
            { expr: new EqualsCondition( "qa1_11", 1 ), "label": "Sleepwear" },
            { expr: new EqualsCondition( "qa1_12", 1 ), "label": "Swimwear" },
            { expr: new EqualsCondition( "qa1_13", 1 ), "label": "Tailored (e.g., dress shirts, dress pants, suits, sport coat, etc.)" },
            { expr: new EqualsCondition( "qa1_14", 1 ), "label": "Dresses, skirt, jumpsuit" },
            { expr: new EqualsCondition( "qa1_15", 1 ), "label": "Outerwear, jacket" },
            { expr: new EqualsCondition( "qa1_98", 1 ), "label": "Other apparel" },
            { expr: new EqualsCondition( "qa1_99", 1 ), "label": "I won't be buying apparel in the next month" }
        ]
    });

    


    stages.push({
        buckets: [
            {
                expr: new OrCondition(
                    new IsValidCondition("qa2_91"),
                    new IsValidCondition("qa2_02"),
                    new IsValidCondition("qa2_03"),
                    new IsValidCondition("qa2_04"),
                    new IsValidCondition("qa2_05"),
                    new IsValidCondition("qa2_06"),
                    new IsValidCondition("qa2_07"),
                    new IsValidCondition("qa2_08"),
                    new IsValidCondition("qa2_09"),
                    new IsValidCondition("qa2_10"),
                    new IsValidCondition("qa2_98")
                ), label: "qa2_(any) is valid"
            },
            {
                expr: new AndCondition(
                    new EqualsCondition( "qa2_91", null),
                    new EqualsCondition( "qa2_02", null),
                    new EqualsCondition( "qa2_03", null),
                    new EqualsCondition( "qa2_04", null),
                    new EqualsCondition( "qa2_05", null),
                    new EqualsCondition( "qa2_06", null),
                    new EqualsCondition( "qa2_07", null),
                    new EqualsCondition( "qa2_08", null),
                    new EqualsCondition( "qa2_09", null),
                    new EqualsCondition( "qa2_10", null),
                    new EqualsCondition( "qa2_98", null)
                ), label: "qa2_* is null"
            }
        ]
    })

    
    stages.push({
        buckets: [
            { 
                expr: new OrCondition(
                    new EqualsCondition( "qa8_1", 1 ),
                    new EqualsCondition( "qa8_2", 1 ),
                    new EqualsCondition( "qa8_3", 1 ),
                    new EqualsCondition( "qa8_4", 1 ),
                    //new EqualsCondition( "qa8_9", 1 )
                ), "label": "qa8_(1..4)==1"
            },
            { 
                expr: new EqualsCondition( "qa8_9", 1 ), "label": "qa8_9==1"
            },
            // { 
            //     expr: new EqualsCondition( "qa8_9", 0 ), "label": "qa8_9==0"
            // },
            // { 
            //     expr: new EqualsCondition( "qa8_9", null ), "label": "qa8_9==."
            // }

        ],
    });

    // stages.push({
    //     buckets: [
    //         { expr: new EqualsCondition( "qa9_01", 1 ), "label": "qa9_01==1 Entertainment" },
    //         { expr: new EqualsCondition( "qa9_01", 0 ), "label": "qa9_01==0 Entertainment" },
    //         { expr: new EqualsCondition( "qa9_01", null ), "label": "qa9_01==. Entertainment" },
    //         // { expr: new EqualsCondition( "qa9_02", 1 ), "label": "qa9_02 Tech" },
    //         // { expr: new EqualsCondition( "qa9_03", 1 ), "label": "qa9_03 Auto" },
    //         // { expr: new EqualsCondition( "qa9_04", 1 ), "label": "Sports Equipment" },
    //         // { expr: new EqualsCondition( "qa9_05", 1 ), "label": "Footwear" },
    //         // { expr: new EqualsCondition( "qa9_06", 1 ), "label": "Accessories (handbag, wallet)" },
    //         // { expr: new EqualsCondition( "qa9_07", 1 ), "label": "Food" },
    //         // { expr: new EqualsCondition( "qa9_08", 1 ), "label": "Educational needs" },
    //         // { expr: new EqualsCondition( "qa9_09", 1 ), "label": "Home" },
    //         // { expr: new EqualsCondition( "qa9_10", 1 ), "label": "Beauty" },
    //         // { expr: new EqualsCondition( "qa9_11", 1 ), "label": "Travel" },
    //         // { expr: new EqualsCondition( "qa9_98", 1 ), "label": "Other" },
    //         // { expr: new EqualsCondition( "qa9_99", 1 ), "label": "I didn't forego buying apparel for these categories" }
    //     ]
    // });

    stages = stages.map(s => {
        const exprs = s.buckets.map(b => b.expr);
        const vars = GetVarsFromExprs(exprs);
        return {
            ...s,
            uniqueVars: vars
        }
    })

    const allUniqueVars = stages.reduce((a, s) => {
        s.uniqueVars?.forEach(v => {
            if( !a.includes(v) ){
                a.push(v);
            }
        })
        return a;
    }, [])

    console.log("stages", stages);
    console.log("uvars", allUniqueVars);

    useEffect(() => {

        (async () => {

            //const datasource_id = "c32109c9d51b4d7f9ef0cdb0c76a8799";
            //const revision_id = 1;
            const datasource_id = "dc54bc31a2c5493d84b74412d2a1fad4";
            const revision_id = 5;
            

            const uniqueVars = allUniqueVars;

            const idObj = uniqueVars.reduce((a, v) => {
                a[v] = `$${v}`;
                return a;
            }, {}); 

            const mstages = [{
                $group: {
                    _id: idObj,
                    n: {
                        $sum: 1
                    }
                }
            }];
            console.log("mstages", mstages);

            const d = await computeApi.compute(auth, subscription_id, project_id, datasource_id, revision_id, mstages);
            setData(d?.data);
            console.log("data", d);

        })();

        
    }, [])


    // process the data
    let chartData = null;
    if( data ){

        chartData = [
            [ "From", "To", "N" ]
        ]
        
        console.log("process stages")
        for( let stageIndex = 0; stageIndex < stages.length - 1; stageIndex++ ){

            const stage1 = stages[stageIndex];
            const stage2 = stages[stageIndex+1];
            console.log("process stages", stage1, stage2);

            // for all combinations of stage1 and stage2
            for( let b1 of stage1.buckets ){
                for( let b2 of stage2.buckets ){
                    let accum = 0;
                    data?.forEach?.(row => {
                        
                        let check1 = b1.expr.Verify(row._id);
                        let check2 = b2.expr.Verify(row._id);
                        if( check1 && check2 ){
                            accum += row.n;
                        }
                        //console.log("row checks", row, check1, check2, b1, b2);

                    })
                    //chartData.push([`[${b1.name}==${b1.value}]`, `[${b2.name}==${b2.value}]`, accum]);
                    if( accum ){
                    chartData.push([b1.label, b2.label, accum]);
                    }
                }
            }

        }
    }

    console.log("chartData", chartData);


    return <div className="logic-inspector">
        <div>
            logic inspector
        </div>


        {chartData ? 
            <Chart
                width={600}
                height={'600px'}
                chartType="Sankey"
                loader={<div>Loading Chart</div>}
                data={chartData}
                options={{
                    sankey: {
                        xlink: { color: { fill: '#d799ae' } },
                        node: {
                            width: 20,
                            //nodePadding: 20
                        }
                    }
                }}
                
            /> : "loading"}
    </div>
}