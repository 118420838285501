import Editor from "@monaco-editor/react";
import { useEffect, useState } from "react";
import { NavLink, Prompt } from "react-router-dom";
import { useProject, useProjectsApi } from "../../hooks/useProject";
import { useProjectId } from "../../hooks/useProjectId";
import { Main } from "../nav/Main";
import "./ProjectSettings.scss";
import { SettingsViewer, SettingsViewerContext } from "./SettingsViewer";

const SECTIONS = [
    { id: "settings", label: "Settings UI" },
    { id: "settings-raw", label: "Settings JSON" },
    { id: "schema", label: "Schema" },
]


export const ProjectSettings = (props) => {

    const api = useProjectsApi();
    const [selSection, setSelSection] = useState(SECTIONS[0].id);
    const { project_id, subscription_id } = useProjectId();
    const [project, projectLoading, projectError] = useProject();
    
    const [code, setCode] = useState();
    const [schema, setSchema] = useState();
    const [isDirty, setIsDirty] = useState(false);
    const [saving, setSaving] = useState(false);

    // const dCode = useDebounce(code, 2000);
    // const dSchema = useDebounce(schema, 2000);
    // useEffect(() => {
    //     save();
    // }, [dCode, dSchema]);
    

    useEffect(() => {
        if( !project ) return;
        setCode(project.settings_str);
        setSchema(project.schema_str);
        if( !project.schema_str && selSection === "settings" ){
            setSelSection("settings-raw");
        }
    }, [project])

    const save = async () => {
        setSaving(true);
        console.log('saving settings');
        const patchBody = {
            settings_str: code,
            schema_str: schema
        };
        await api.patchItem(project.id, patchBody);
        setIsDirty(false);
        setSaving(false);
    }

    const save2 = async (_code, _schema) => {
        setSaving(true);
        console.log('saving settings');
        const patchBody = {
            settings_str: _code,
            schema_str: _schema
        };
        await api.patchItem(project.id, patchBody);
        setIsDirty(false);
        setSaving(false);
    }

    return <Main noLeftMenu>
        <Prompt
            when={isDirty}
            message="Are you sure you want to leave without saving?"
        />
        <div className='project-settings'>

            <div className='top-header'>
                <span className='left-section'>
                    <span className="title">
                        Project Settings
                    </span>
                    <NavLink className="back-link" to={`./`}>
                        Close
                    </NavLink>
                </span>
                <span className='right-section'>
                    <button
                        onClick={() => save()}
                    >
                        Save
                    </button>
                    <span className='dirty-label'>
                        {saving ? "saving" : isDirty ? "dirty" : "saved"}
                    </span>
                </span>
            </div>

            {/* <div className='actions-header'>
                
            </div> */}

            <div className="main full-dock">
                <div className="top tab-section no-margin">
                    {SECTIONS.map(s =>
                        <div key={s.id}
                            onClick={() => setSelSection(s.id)}
                            className={`tab ${selSection === s.id ? "sel" : ""}`}
                        >
                            {s.label}
                        </div>
                    )}
                </div>
                <div className="center">
                    {projectLoading && <div className="message">Loading project...</div>}
                    {project && selSection === "settings" &&
                        <SettingsViewerContext.Provider value={{
                            code,
                            setCode: (val) => {
                                setIsDirty(true);
                                setCode(val);
                                save2(val, schema);
                            },
                            schema
                        }}>
                            <SettingsViewer />
                        </SettingsViewerContext.Provider>
                        
                    }
                    {project && selSection === "settings-raw" &&
                        <Editor
                            defaultLanguage={"json"}
                            theme={props.theme}
                            options={{
                                tabSize: 4,
                                minimap: {
                                    enabled: true
                                }
                            }}
                            value={code}
                            height={"100%"}
                            width={"100%"}
                            onChange={(v) => {
                                setIsDirty(true);
                                setCode(v);
                            }}
                        />
                    }
                    {project && selSection === "schema" &&
                        <Editor
                            defaultLanguage={"json"}
                            theme={props.theme}
                            options={{
                                tabSize: 4,
                                minimap: {
                                    enabled: true
                                }
                            }}
                            value={schema}
                            height={"100%"}
                            width={"100%"}
                            onChange={(v) => {
                                setIsDirty(true);
                                setSchema(v);
                            }}
                        />
                    }
                </div>
            </div>

        </div>
    </Main>;


    
}


