import { useState } from "react";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import { useImage } from "../../hooks/useImage";
import { getImagePath } from "../../utils/ImageUtils";
import { CollectionEntryEditor } from "./CollectionEntryEditor";
import "./CollectionEntryTile.scss";



export const CollectionEntryTile = (props) => {

    const item = props.item || {};
    
    const routerParams = useParams();
    const subscription_id = routerParams.subscription_id;
    const project_id = routerParams.project_id;

    const [image, imageLoading, imageError] = useImage(item?.imageId);
    const filepath = getImagePath(subscription_id, project_id, image?.filename);

    const [editorOpen, setEditorOpen] = useState(false);

    const deleteMe = (ev) => {

        ev.preventDefault();
        ev.stopPropagation();

        props.onDelete?.();
    }

    const swatch = <div
        className={`collection-entry-tile ${props.selected ? 'sel' : ''} ${props.hideHover ? ' hide-hover' : ''} ${props.staticHeight ? 'static-height' : ''}`}
        onClick={() => {
            setEditorOpen(true);
        }}
        {...props.style}
    >
            
        {!props.hideDelete ?
            <div className='btn-delete' onClick={deleteMe}>
                <i className='fal fa-times' />
            </div>
            : null}
        
        <div className="image">
            {filepath ? <img src={filepath} /> : "[image]" }
        </div>

        <div className='id'>
            {item?.id}
        </div>
            
        <div className='label'>
            {item?.label}
        </div>

        <div className="info">
            {item?.description}
        </div>

        <Popup modal nested open={editorOpen} onClose={() => setEditorOpen(false)}>
            {closeModal => <CollectionEntryEditor
                close={closeModal}
                item={item}
                onSave={async (newItem) => {
                    await props.onSave?.(newItem);
                    setEditorOpen(false);
                }} />}
        </Popup>

        
    </div>;

    return swatch;


}
