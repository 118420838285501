import { useContext, useState } from "react";
import { ModalFrame } from "../modal-dialog/ModalFrame";
import moment from 'moment';
import { AuthContext } from "../../context/Contexts";

// export const VerifyFrame = (props) => {

//     const buttons = props.buttons || [
//         {
//             label: "Delete",
//             callback: () => {
//                 props.confirmed?.(props.payload)
//                 props.close();
//             }
//         }
//     ]

//     return <ModalFrame
//         title={props.title || "Are you sure?"}
//         buttons={buttons}
//         close={props.close}
//     >
//         <p>
//             Are you sure you want to delete "{props.label || "item"}"?
//         </p>

//     </ModalFrame>

// }

export const PatchFrame = (props) => {

    const [tab, setTab] = useState(props.tab || "patch");
    const [item, setItem] = useState({ ...props.rev });
    const [notes1, setNotes1] = useState(props.rev?.notes);
    const [notes2, setNotes2] = useState(null);
    const [makeDev, setMakeDev] = useState(true);

    const authContext = useContext(AuthContext);

    const saveButton = {
        label: (tab === "patch") ? "Patch" : "Save New",
        callback: () => {
            const data = {
                method: tab, // "patch" or "new"
                ...(tab === "patch" ? {
                    ds_id: props.ds?.id,
                    id: props.rev?.id,
                    notes: notes1
                } : {
                    ds_id: props.ds?.id,
                    notes: notes2,
                    makeDev
                })
            }
            props.onSave?.(data);
            props.close?.();
        }
    }
    const buttons = [ saveButton ];
    
    

    return <ModalFrame
        title={props.title || (tab === "patch" ? "Patch Existing Revision" : "Save as New Revision")}
        buttons={buttons}
        close={props.close}
    >
        <div className="full-dock" style={{ fontSize: "9pt" }}>

            <div className="top tab-section no-margin">
                <div className={`tab ${tab === "patch" ? "sel" : ""}`} onClick={() => setTab("patch")}>
                    Patch
                </div>
                <div className={`tab ${tab === "new" ? "sel" : ""}`} onClick={() => setTab("new")}>
                    New Revision
                </div>
                
            </div>

            <div className='center form1' style={{ paddingTop: 10 }}>

                {tab === "patch" ? <>
                    <div className='label'>
                        <div className='label-line'>
                            Revision: <b>{item.id}</b>
                        </div>
                    </div>

                    <div className='label'>
                        <div className='label-line'>
                            Created: {moment.utc(item.created).local().fromNow()}
                        </div>
                    </div>

                    {/* <div className='label'>
                        <div className='label-line'>
                            Author: {item.author}
                        </div>
                    </div> */}

                    <div className='label'>

                        <div className='label-line'>
                            Edit Notes:
                        </div>

                        <div className='value-line'>
                            <textarea key={'ta-patch'} className="full-width" 
                                style={{ height: 100 }}
                                value={notes1 || ""}
                                onChange={(ev) => {
                                    setNotes1(ev.target.value)
                                }}
                            />
                        </div>

                    </div>

                </> :
                <>

                    <div className='label'>
                        <div className='label-line'>
                            Author: {authContext?.authUser?.name}
                        </div>
                    </div>

                    <div className='label'>

                        <div className='label-line'>
                            Notes:
                        </div>
                        
                        <div className='value-line'>
                            <textarea key='ta-newrev' className="full-width" 
                                placeholder="Write notes about what changed in this revision"
                                style={{ height: 100 }}
                                value={notes2 || ""}
                                onChange={(ev) => setNotes2(ev.target.value)}
                            />
                        </div>

                    </div>

                    <div className='label'>
                        <label className='label-line' style={{ fontSize: "9pt" }}>
                            <input type="checkbox" checked={makeDev} onChange={ev => setMakeDev(ev.target.checked)} /> Set as 'Dev' revision
                        </label>
                    </div>


                </>}

                

                

            </div>

        </div>
        

    </ModalFrame>

}