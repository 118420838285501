import { useContext } from "react";
import { useContentItemsApi } from "../../hooks/useContentItems";
import { ContentAdderButton } from "../content/ContentAdderButton";
import { DisplayContext, getDisplayClass } from "../content/DisplayContext";
import { EditContentContext } from "../content/EditContentContext";
import { PresentContent } from "../content/PresentContent";
import { GenWrapper } from "./ArbitraryContent"
import "./VDockLayout.scss";

export const VH_DOCK_HINT = "Hint: Consider adding a VScroller before adding content";

export const VDockLayout = (props) => {

    const ec = useContext(EditContentContext);
    const mode = ec.mode;
    const item = ec.item;
    const children = item?.children;
    const topChild = children?.find(child => child.key === "top");
    const bottomChild = children?.find(child => child.key === "bottom");
    const centerChild = children?.find(child => child.key === "center");
    const locked = item?.jdata?.locked;
    // const display = useContext(DisplayContext);
    // const displayClass = getDisplayClass(display);
    const style = { ...props.style, ...item?.style }

    return <div className={`mstudio-v-dock-layout ${props.displayClass}`} style={style} {...props.atts}>
        {(topChild || mode === "edit") &&
            <div className="top">
                <DisplayContext.Provider value={"block"}>
                    {topChild ?  
                        <PresentContent id={topChild.id} />
                        :
                        !locked && <ContentAdderButton keyName="top" label="Add top content" />
                    }
                </DisplayContext.Provider>
            </div>
        }
        <div className="center">
            <DisplayContext.Provider value={"block-fill"}>
                {centerChild ?  
                    <PresentContent id={centerChild.id} />
                    : mode === "edit" ? 
                        !locked && <div className="_block-fill" style={{ padding: 10 }}>
                            <ContentAdderButton keyName="center" label="Add center content" description={VH_DOCK_HINT}  />
                        </div>
                    : null
                }
            </DisplayContext.Provider>
        </div>
        {(bottomChild || mode === "edit") &&
            <div className="bottom">
                <DisplayContext.Provider value={"block"}>
                    {bottomChild ?  
                        <PresentContent id={bottomChild.id} />
                        :
                        !locked && <ContentAdderButton keyName="bottom" label="Add bottom content" />
                    }
                </DisplayContext.Provider>
            </div>
        }
    </div>

    
}