import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import * as globals from "../globals";
import * as restMethods from "../api/restMethods";

const getFriends = async (auth) => {
    const url = `${globals.apiRoot}/my-friends`;
    var response = await restMethods.getJson(auth, url);
    return await response.json();
}


export const useFriends = () => {

    // TODO: move this into REDUX to eliminate fetches each time?
    // But if so, the websocket might need to cooperate to notify of changes

    const auth = useAuth();
    const [users, setUsers] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [refreshCounter, setRefreshCounter] = useState(0);

    useEffect(() => {

        const f = async () => {
            setLoading(true);
            try {
                const list = await getFriends(auth);
                setUsers(list);
            }
            catch (err) {
                console.error(err);
                setError(err.message);
                setUsers([]);
            }
            setLoading(false);
        }
        f();

    }, [refreshCounter]);

    const refresh = () => setRefreshCounter(refreshCounter+1);

    return [users, loading, error, refresh]

}