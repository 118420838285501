import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useDataSourcesApi } from "../../../hooks/useDataSource";
import { CONNECTORS } from "./_Connectors";
import * as globals from "../../../globals";
import axios from "axios";
import { getAuthHeaders } from "../../../api/restMethods";
import { ModalFrame } from "../../modal-dialog/ModalFrame";
import { NewDataSourceContext } from "../NewDataSource";
import { format } from "d3-format";
import { fileSizeFormat } from "../../../utils/FileSizeFormat";

const fmt1 = format(",.0f");


export const FlowConnector = (props) => {

    const connector_id = props.connector_id;

    const routerParams = useParams();
    const subscription_id = routerParams.subscription_id;
    const project_id = routerParams.project_id;

    const ndsContext = useContext(NewDataSourceContext);
    const { working, setWorking } = ndsContext;
    const { workStatusMessage, setWorkStatusMessage } = ndsContext;
    const { setButtonText } = ndsContext;
    

    const [newLabel, setNewLabel] = useState("");
    const [error, setError] = useState(null);
    const auth = useAuth();
    
    
    const api = useDataSourcesApi();

    
    setButtonText(props.alreadyExists ? "Save revision" : "Create Flow");
    ndsContext.hackHandler.buttonClicked = async () => {
        let id = props.id;
        if( !props.alreadyExists) {
            id = await createDataSource();
            // don't create revision yet
            return id;
        }
        else{
            const rev_id = await uploadRevision(id); // returns true if success
            return rev_id;
        }
    }

    const createDataSource = async () => {

        let newId = null;

        setWorkStatusMessage("Creating data source...");
        setWorking(true);
        
        try {

            // create data source
            let item = {
                fileType: "file",
                type: "flow",
                connector: connector_id,
                structured: true,
                label: newLabel || "Untitled data flow"
            }
            const itemInfo = await api.newItem(item);
            newId = itemInfo.newId;

        }
        catch (err) {
            setError(err.message);
        }
        setWorking(false);

        return newId;
        
    }

    const uploadRevision = async (ds_id) => {

        try {

            setWorkStatusMessage(`Uploading data...`);
            setWorking(true);

            const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/data-source/${ds_id}/file`;

            let formData = {
                pastedData: JSON.stringify(props.getFlow?.(), null, 4)
            }
            if (ndsContext.notes) {
                formData.notes = ndsContext.notes;
            }

            const res = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    ...getAuthHeaders(auth)
                },
                onUploadProgress: (ev) => {
                    console.log("onUploadProgress", ev);
                }
            });
            //console.log("axios res", res);

            setWorking(false);

            return res.data?.newId;
            
        }
        catch (error) {
            setError(error.message);
            setWorking(false);
            return null;
        }
    }

    

    return <div className='form1 new-data-source' style={{ maxWidth: 500 }}>

        <div className="upload-data-file">

            <div className="icon" style={{ fontSize: 50, float: "right", marginRight: 10 }}>
                <i className="fad fa-random" />
            </div>

            {!props.alreadyExists && <>
                <div className="input-section" style={{ marginBottom: 15 }}>
                    <div style={{ fontSize: "10pt", marginBottom: 5 }}>
                        Label:
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Enter label for new data flow"
                            style={{ width: 350 }}
                            value={newLabel}
                            onChange={ev => setNewLabel(ev.target.value)}
                        />
                    </div>
                </div>

                <div className="input-section" style={{ marginBottom: 20 }}>
                    <p>
                        <div style={{ marginBottom: 5 }}>
                            Select data flow template:
                        </div>
                        <select style={{ width: 250 }}>
                            <option>Default</option>
                        </select>
                    </p>
                </div>
            </>}

            <div className="input-section">
                {(working || workStatusMessage) ?
                    <div style={{ fontSize: "10pt", marginBottom: 5 }}>
                        {workStatusMessage}
                    </div>
                    : null
                }
            </div>
            
        </div>

        <div className="label-line">
        </div>

        {error && !working ?
            <div>
                <p style={{ color: "red" }}>
                    <i className="fas fa-warning" />
                    &nbsp;
                    {error}
                </p>
            </div>
            : null
        }
        
    </div>


}



const parseData = (str) => {
    if (!str) return null;

    const lines = str.split("\n");
    if (lines?.length < 2) return { error: "Expecting at least 2 lines (table name and col headers)" };

    const name = lines[0]?.trim();
    const firstRow = lines[1];
    const cols = firstRow.split('\t');

    if (name.length > 100 || name.split('\t')?.length > 1)
        return { error: "Unexpected name" };

    return {
        name,
        nbRows: lines.length - 2,
        nbCols: cols?.length
    }

}
