import { useRecoilValue } from "recoil";
import { DataTable } from "../common/DataTable";
import { computeContextState } from "./ComputeContext";


export const DataTableContent = (props) => {
    console.log("DataTableContent render");

    const item = props.item;
    const style = { margin: 0, ...props.style, ...item?.style }
    const display = props.display;
    const displayClass = props.displayClass;
    const jdata = item.jdata;
    
    // get data from hook
    const cHook = item?.jdata?.contextHooks?.[0];
    const ccState = useRecoilValue(computeContextState(cHook?.id));
    console.log("DataTableContent ccState", ccState);

    const cols = jdata?.columns;
    const result = ccState?.result;
    const rows = result?.data || result?.rows || result;

    return <DataTable className={`auto-height x${displayClass}`} style={style} {...props.atts}
        rows={rows}
        autoGenerateColumns={!cols}
        columns={cols}
        allowNest={true}
    />
     
};
