import { createContext, useContext, useRef, useState } from "react";
import "./SettingsViewer.scss";
import JSON5 from "json5";
import Popup from "reactjs-popup";
import { TextFrame } from "../modal-dialog/VerifyFrame";

const DEFAULT_CATEGORY = "General";

export const SettingsViewerContext = createContext();


export const SettingsViewer = (props) => {

    const context = useContext(SettingsViewerContext);
    //const code = context?.code;
    const setCode = context?.setCode;
    const ref1 = useRef();
    const [selCategory, setSelCategory] = useState(null);

    let settings = [];

    try{
        settings = context?.schema ? JSON5.parse(context?.schema) : [];
    }
    catch( err ){
        console.log("error", err);
    }

    let data = {};
    try{
        data = context?.code ? JSON5.parse(context?.code) || {} : {};
    }
    catch( err ){
        console.log("error", err);
        data = {};
    }

    const handleChange = () => {
        setCode(JSON.stringify(data, null, 4));
    }

    const scrollCategoryIntoView = (category) => {
        setSelCategory(category);
    }

    // inspect categories
    const categories = settings?.reduce((acc, el) => {
        const category = el.category || DEFAULT_CATEGORY;
        if( !acc.includes(category)){
            acc.push(category);
        }
        return acc;
    }, [])

    const list = selCategory ? [selCategory] : categories;

    return <div className="settings-viewer fill-parent full-dock">
        <div className="tree left">
            {categories?.map(category =>
                <div className={`tree-item ${selCategory === category ? "sel" : ""}`} onClick={() => {
                    scrollCategoryIntoView(category);
                }}>
                    {category}
                </div>
            )}
            <div className={`tree-item ${selCategory ? "" : "sel"}`} onClick={() => { scrollCategoryIntoView(null); }}>
                (All)
            </div>
        </div>
        <div className="editor center" ref={ref1}>
            {list?.map(category => {
                const category_settings = settings.filter(s => (s.category || DEFAULT_CATEGORY) === category);
                return <div key={category} id={category} className="category">
                    <div className="title">
                        {category}
                    </div>
                    {category_settings.map((setting, si) =>
                        <div key={`${setting.name}/${si}`} className="setting">
                            <div className="label">
                                {setting.label || setting.name || "name or label missing"}
                            </div>
                            {setting.description && 
                                <div className="description">
                                    {setting.description}
                                </div>
                            }
                            {setting.type === "select" ?
                                <select value={data?.[setting.name]}
                                    onChange={(ev) => {
                                        data[setting.name] = ev.target.value;
                                        handleChange();
                                    }}
                                >
                                    {setting.options?.map((option, oi) =>
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    )}
                                </select>
                                : setting.type === "boolean" ? 
                                    <label>
                                        <input type="checkbox" 
                                            checked={data?.[setting.name]}
                                            onChange={(ev) => {
                                                data[setting.name] = ev.target.checked;
                                                handleChange();
                                            }}
                                        />
                                        <span className="text">
                                            {setting.label || setting.name}
                                        </span>
                                    </label>
                                : setting.type === "number" ? 
                                <span>
                                    <NumberSetting 
                                        setting={setting}
                                        value={data?.[setting.name]}
                                        onChange={newValue => {
                                            if( newValue !== data[setting.name] ){
                                                data[setting.name] = newValue;
                                                handleChange();
                                            }
                                        }}
                                    />
                                </span>
                                : setting.type === "string" ? 
                                <span>
                                    <StringSetting 
                                        setting={setting}
                                        value={data?.[setting.name]}
                                        onChange={newValue => {
                                            if( newValue !== data[setting.name] ){
                                                data[setting.name] = newValue;
                                                handleChange();
                                            }
                                        }}
                                    />
                                </span>
                                : null
                            }
                            
                        </div>
                    )}
                </div>
            })}
        </div>
    </div>
}

export const NumberSetting = (props) => {

    return <Popup
        modal
        trigger={
            <span className="number-box">{props.value || "not set"}</span>
        }
    >
        {closeModal => <TextFrame
            buttonLabel="OK"
            title={`Edit ${props.setting?.label}`}
            label={props.setting?.label}
            initialTextValue={props.value}
            close={closeModal} confirmed={async (text) => {
                try{
                    const f = parseFloat(text);
                    props.onChange?.(f);
                    closeModal();
                }
                catch{
                }
                
            }}
        />}
    </Popup>
    
}

export const StringSetting = (props) => {

    return <Popup
        modal
        trigger={
            <span className="number-box">{props.value || "not set"}</span>
        }
    >
        {closeModal => <TextFrame
            buttonLabel="OK"
            title={`Edit ${props.setting?.label}`}
            label={props.setting?.label}
            initialTextValue={props.value}
            close={closeModal} confirmed={async (text) => {
                try{
                    props.onChange?.(text);
                    closeModal();
                }
                catch{
                }
                
            }}
        />}
    </Popup>
    
}

export const SelectSetting = (props) => {

    const setting = props.setting;

    return <select value={props.value}
        onChange={(ev) => {
            props.onChange?.(ev.target.value);
        }}
    >
        {setting?.options?.map((option) => {
            const value = typeof(option) === "string" ? option : option.value;
            const label = typeof(option) === "string" ? option : option.label || option.value;
            return <option key={value} value={value}>
                {label}
            </option>
        })}
    </select>

}

