import { equal as isShallowEqual } from 'fast-shallow-equal';
import { useEffect, useRef } from 'react';
  
const useShallowCompareMemoize = (value) => {
    const ref = useRef() 
    // it can be done by using useMemo as well
    // but useRef is rather cleaner and easier

    if(!isShallowEqual(value, ref.current)){
      ref.current = value;
    }

    return ref.current
}
  
export const useShallowCompareEffect = (callback, dependencies) => {
    useEffect(
        callback,
        dependencies.map(useShallowCompareMemoize)
    )
}