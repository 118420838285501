import { constructs } from "../../../api/contructs";
import { useCollections } from "../../../hooks/useCollection";
import { useDataSources } from "../../../hooks/useDataSource";
import { useImages } from "../../../hooks/useImage";
import { usePalettes } from "../../../hooks/usePalette";
import { useProjectId } from "../../../hooks/useProjectId";
import { useResources } from "../../../hooks/useResources";
import "./ResourceList.scss";



const getIcon = (resourceType) => {
    const c = constructs[resourceType];
    return <i className={`fas ${c.icon}`} />;

    // switch (resourceType) {
    //     case "palette": return <i className="fas fa-swatchbook" />;
    //     case "collection": return <i className="fas fa-album-collection" />;
    //     case "data-source": return <i className="fas fa-database" />;
    //     case "image": return <i className="fas fa-image" />;
    //     default: return null;
    // }
}

export const ResourceList = (props) => {

    const list = props.value;
    const [resources, resourcesLoading, resourcesError] = useResources(list, false);


    if (resourcesLoading)
        return <div>
            Loading...
        </div>

    if (resourcesError)
        return <div>
            Error loading resource list.
        </div>
    
    

    if (!list || list.length < 1)
        return <div style={{
            textAlign: "center",
            padding: 10,
            fontSize: 12
        }}>
            (0 items)
        </div>;

    const fResources = resources?.filter(r => !r?.name?.endsWith(".rev"));
    
    return <div className="resource-list" style={{ marginBottom: 10 }}>
        {fResources?.map(r => 
            <div className="entry" key={r.name}>
                <span className="icon">
                    {getIcon(r.type)}
                </span>
                <span className="name">
                    {r.name} = 
                </span>
                <span className="rlabel">
                    {r.value?.label || r.id}
                </span>
                <span className='btn del' onClick={() => {
                    props.onDelete?.(r)
                }}>
                    <i className="fal fa-times"/>
                </span>
            </div>
        )}
        {/* <pre>
            {JSON.stringify(resources, null, 4)}
        </pre> */}
    </div>
    
}