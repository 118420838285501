import { useContext } from "react";
import { ContentAdderButton } from "../content/ContentAdderButton";
import { DisplayContext, getDisplayClass } from "../content/DisplayContext";
import { EditContentContext } from "../content/EditContentContext";
import { PresentContent } from "../content/PresentContent";
import "./VHScroller.scss";

export const VHScroller = (props) => {

    const ec = useContext(EditContentContext);
    const mode = ec.mode;
    const item = ec.item;
    const children = item?.children;
    const firstChild = children?.[0];
    // const display = useContext(DisplayContext);
    // const displayClass = getDisplayClass(display);

    const style = { ...props.style, ...item?.style }

    return <div className={`mstudio-vhscroller ${props.displayClass}`} style={style} {...props.atts}>
        {(firstChild || mode === "edit") &&
            <DisplayContext.Provider value={"block"}>
                {firstChild ?  
                    <PresentContent id={firstChild.id} />
                    :
                    <DisplayContext.Provider value={"block-fill"}>
                        <ContentAdderButton label="Set scoller content" description="Hint: Use a layout (e.g., stack or grid) if multiple content items are needed" />
                    </DisplayContext.Provider>
                }
            </DisplayContext.Provider>
        }
    </div>
}