import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactFlow, { addEdge, Background, Controls } from 'react-flow-renderer';
//import { removeElements } from 'react-flow-renderer';
import { applyNodeChanges, applyEdgeChanges } from 'react-flow-renderer';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import * as restMethods from "../../api/restMethods";
import { EditDataSourceContext, EtlFlowContext, HasDataSourcesContext } from "../../context/Contexts";
import * as globals from "../../globals";
import { useAuth } from '../../hooks/useAuth';
import { useDataSources } from '../../hooks/useDataSource';
import { useDataSourceRevision } from '../../hooks/useDataSourceRevision';
import { useProjectId } from '../../hooks/useProjectId';
import { newId } from "../../utils/uuid";
import { DataSourceRevisionSelector } from './DataSourceRevisionSelector';
import "./EditETL.scss";
import { EditETLNode } from './EditETLNode';
import { EtlNodeTypeInfos, EtlNodeTypes } from "./etl/EtlNodes";
import { UploadDataFileModal } from './UploadDataFileModal';


const snapElements = (elements, snapSize) => {

    return elements?.map(el => {
        return {
            ...el,
            position: el.position ? {
                ...el.position,
                x: Math.round(el.position.x / snapSize) * snapSize,
                y: Math.round(el.position.y / snapSize) * snapSize,
            } : null
        }
    })

}


const getNewNodeId = () => `dndnode_${newId()}`;

export const EditETL = (props) => {

    const reactFlowWrapper = useRef(null);
    const [rfInstance, setRfInstance] = useState(null);
    const { subscription_id, project_id } = useProjectId();

    const flowId = props.data_source_id;

    const dsContext = useContext(EditDataSourceContext);
    const ds = dsContext?.ds;
    const auth = useAuth();

    const [selectedNode, setSelectedNode] = useState(null);
    const [editNode, setEditNode] = useState(null);
    const [running, setRunning] = useState(false);
    const [nodeMenuCloser, setNodeMenuCloser] = useState(null);

    const [rev, revLoading, revError, revRefresh] = useDataSourceRevision(ds?.id, dsContext?.selectedRevisionId);



    // set up HasDataSourcesContext for the nodes to be able to access
    const [dsList, dsListLoading, dsListError, dsListRefresh] = useDataSources();
    const dsContextValue = {
        dsList,
        dsListLoading,
        dsListError,
        dsListRefresh,
    };


    
    useEffect(() => {
        // set elements from rev.udata
        if( rev?.udata ){
            try{
                const flow = JSON.parse(rev?.udata);

                if( flow?.elements ){
                    // old style, need to convert
                    // todo:
                    //setElements(flow?.elements);
                    // todo: separate nodes and edges, once i see what they look like structurally
                    console.log("THIS IS THE OLD FLOW");
                    console.log("flow", flow);
                    const parsed = flow.elements.reduce((acc, el) => {
                        const isEdge = el.source || el.sourceHandle || el.target || el.targetHandle;
                        if( isEdge ){
                            acc._edges.push(el);
                        }
                        else{
                            acc._nodes.push(el);
                        }
                        return acc;
                    }, { _edges: [], _nodes: [] });

                    setNodes(parsed._nodes);
                    setEdges(parsed._edges);
                }
                else{
                    console.log("THIS IS THE NEW FLOW");
                    console.log("flow", flow);
                    // new style
                    setNodes(flow?.nodes);
                    setEdges(flow?.edges);
                }

                
            }
            catch{
                //setElements([]);
                setNodes([]);
                setEdges([]);
            }
        }
        else{
            //setElements([]);
            setNodes([]);
            setEdges([]);
        }

    }, [rev])

    //const [elements, setElements] = useState(props.flow?.elements || []);

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);


    // const onElementsRemove = (elementsToRemove) =>
    //     setElements((els) => removeElements(elementsToRemove, els));

    const onNodesChange = useCallback(
        (changes) => setNodes((ns) => applyNodeChanges(changes, ns)),
        []
    );

    const onEdgesChange = useCallback(
        (changes) => setEdges((es) => applyEdgeChanges(changes, es)),
        []
    );


    const getFlow = useCallback(() => {
        if (rfInstance) {
            const flow = rfInstance.toObject();
            return flow;
        }
        return null;
    }, [rfInstance]);

    const patchRevision = async () => {
        try {

            const flow = getFlow();

            //setWorkStatusMessage(`Uploading data [${parseInfo.name}]...`);
            //setWorking(true);

            const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/data-source/${ds.id}/revision/${rev.id}`;
            try{
                const response = await restMethods.aPatch(auth, url, {
                    udata: JSON.stringify(flow, null, 4)
                });
                console.log("patch response", response);
                revRefresh();
            }
            catch(err){
                alert(err);
                return;
            }

            //setWorking(false);
            return true;
            
        }
        catch (error) {
            console.error(error);
            //setError(error.message);
            //setWorking(false);
            return false;
        }
    }

    const toastRun = () => {
        toast.promise(run,
            {
                pending: "Running Data Flow...",
                success: "Data Flow succeeded",
                error: "Error running Data Flow.",
            },
            {
                autoClose: false
            })
    }

    const run = async () => {
        // console.log("elements", elements);
        // console.log("CANCELLING");
        // return;

        setRunning(true);
        setFlowAnimation(true);

        let success = false;
        
        try{
            const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/data-source/${ds.id}/revision/${rev.id}/run-flow`;
            var response = await restMethods.getJson(auth, url);
            console.log("ETL ran", response);
            if( response.ok ){
                //alert("ETL ran successfully.")
                success = true;
            }
            else{
                //alert("ETL gave error. See console log.")
                //throw "ETL gave error. See console log.";
            }
        }
        catch( err ){
            console.log("Error running ETL", err);
            //alert("ETL failed. See console log.");
            //throw "ETL failed. See console log.";
        }
        setRunning(false);
        setFlowAnimation(false);

        if( success ) return true;
        else throw {};
    }

    const setNodeData = (id, newData) => {
        console.log('update node data', id, newData);
        const newNodes = nodes.map(n => n.id === id ? {
            ...n,
            data: newData
        } : n);
        setNodes(newNodes);
        
    }

    // const onConnect = (params) => setElements((els) => addEdge({ 
    //     ...params, 
    //     //animated: true,
    //     style: {
    //         ...params?.style,
    //         strokeWidth: 2,
    //         //fill: "red"
    //     }
    // }, els));

    const onConnect = useCallback((connection) => 
            setEdges((eds) => addEdge({ 
                    ...connection, 
                    //animated: true,
                    style: {
                        ...connection?.style,
                        strokeWidth: 2,
                        //fill: "red"
                    }
                },
                eds
            )),
        []
    );

    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    const onDrop = (event) => {
        event.preventDefault();

        console.log("drop event", event);

        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow');
        const position = rfInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });

        // find initial data
        const info = EtlNodeTypeInfos[type];

        const newNode = {
            id: getNewNodeId(),
            type,
            position,
            data: { label: null, ...info?.data },
        };

        //setElements((es) => es.concat(newNode));
        setNodes((prevNodes) => prevNodes.concat(newNode));
    };

    const setFlowAnimation = (animated) => {
        const newEdges = edges.map(el => {
            // if el is connector
            //if(el.sourceHandle){
                // this is a connector
                return {
                    ...el,
                    animated
                }
            //}
            //else return el;
        })
        setEdges(newEdges);
    }

    console.log("editNode", editNode);

    //fullScreenEditor ? fullScreenEditorStyle : editorStyle
    const [fullScreenEditor, setFullScreenEditor] = useState(false);
    const editorStyle = {
        border: "1px #cfcfcf",
        width: 500,
        height: 600,
        maxHeight: "100%"
    }

    const fullScreenEditorStyle = {
        ...editorStyle,
        width: "100%",
        height: "100%"
    }

    return <HasDataSourcesContext.Provider value={dsContextValue}>
        <EtlFlowContext.Provider value={{ setNodeData, setNodeMenuCloser, fullScreenEditor, setFullScreenEditor, setEditNode }}>
            <div className="edit-etl">
                <div className="heading-pane">
                    <DataSourceRevisionSelector width={400}/> 

                    <span style={{ display: "inline-block", paddingTop: 15 }}>
                        <span className="btn action-light bg2 margin-left"
                            onClick={() => patchRevision()}
                        >
                            Patch revision
                        </span>
                        <Popup
                            contentStyle={{
                                width: 500
                            }}
                            closeOnDocumentClick={false}
                            closeOnEscape={false}
                            trigger={
                                <span className="btn action-light bg2 margin-left">
                                    Save as new Revision...
                                </span>
                            }
                            modal
                        >
                            {close => <UploadDataFileModal
                                ds={ds}
                                id={ds.id}
                                getFlow={getFlow}
                                close={close}
                                reload={(newId) => {
                                    props.reload?.();
                                    dsContext?.setSelectedRevisionId?.(newId);
                                    // todo: change the selected revision id
                                }}
                            />}
                        </Popup>
                        <span className="btn action margin-left"
                            onClick={() => {
                                if( !running ){
                                    toastRun();
                                }
                            }}
                        >
                            <i className="fas fa-play" />&nbsp;
                            {running ? "Running..." : "Run"}
                        </span>
                        {/* <span className="btn icon"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                                toggleFlowAnimation();
                            }}
                        >
                            <i className="far fa-ghost"/>
                        </span> */}
                    </span>

                </div>
                <div className="flow-section" ref={reactFlowWrapper}>
                    <Popup modal
                    contentStyle={fullScreenEditor ? fullScreenEditorStyle : editorStyle}
                        open={editNode != null}
                        closeOnDocumentClick={false}
                        closeOnEscape={false}
                    >
                        <EditETLNode 
                            node={editNode} 
                            saveNode={(newNode) => {
                                const newNodes = nodes.map(n => n.id === editNode.id ? newNode : n);
                                console.log("newNodes", newNodes);
                                setNodes(newNodes);
                                setSelectedNode(newNode);
                            }} 
                        />
                    </Popup>
                    {revLoading ? <div style={{ padding: 10 }}>loading...</div> : 
                        <div className="full-dock fill-parent-absolute">
                            <div className="center">
                                
                                <ReactFlow
                                    proOptions={{ hideAttribution: true, account: "paid-custom" }}
                                    nodeTypes={EtlNodeTypes}
                                    onInit={setRfInstance}

                                    nodes={nodes}
                                    edges={edges}
                                    
                                    onNodesChange={onNodesChange}
                                    onEdgesChange={onEdgesChange}
                                    onConnect={onConnect}

                                    onDrop={onDrop}
                                    onDragOver={onDragOver}

                                    onNodeDoubleClick={(ev, node) => {
                                        console.log("node", node);
                                        setEditNode(node);
                                    }}

                                    onPaneClick={ev => {
                                        toast("pane click: close menu");
                                        if( nodeMenuCloser?.close ){
                                            //nodeMnuCloser?.close();
                                        }

                                        //nodeMenuCloser?.();
                                    }}

                                    deleteKeyCode={['Backspace', 'Delete']}
                                    snapToGrid={true}
                                    snapGrid={[15,15]}

                                    onSelectionChange={(selectedElements) => {
                                        //console.log("onSelectionChanged", selectedElements);
                                        const node = selectedElements?.nodes?.[0];
                                        setSelectedNode(node);
                                    }}
                                >
                                    <Background
                                        variant="dots"
                                        gap={15}
                                        size={0.5}
                                    />
                                    <Controls />

                                </ReactFlow>
                            </div>
                            {false && selectedNode && <EditETLNode className="right" node={selectedNode} saveNode={(newNode) => {
                                const newNodes = nodes.map(n => n.id === selectedNode.id ? newNode : n);
                                console.log("newNodes", newNodes);
                                setNodes(newNodes);
                                setSelectedNode(newNode);
                            }} />}
                            

                        </div>
                    }       
                </div>
            </div>
        </EtlFlowContext.Provider>
    </HasDataSourcesContext.Provider>;
    
    
}


