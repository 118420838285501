import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { compileFunction } from '../../utils/Compiler';
import './AttInput.scss';
import { format } from "d3-format"
import { debounce } from "../../utils/debounce";

export const ifNDef = (val, fallback) => {
    if (val === null || val === undefined) return fallback;
    return val;
}



export const AttInput = (props) => {
    const att = props.att;
    const productValue = props.productValue;
    const options = att.options || att.levels;
    const myValue = productValue[att.name];
    return <div className='att-input' style={props.style}>
        {props.hideLabel ? null : <div className="att-label">{att.label}</div>}
        <div className="att-value">
            <select
                disabled={att.readOnly}
                value={myValue}
                onChange={(ev) => {
                    const newProductValue = {
                        ...productValue,
                        [att.name]: options.find(op => op.value == ev.target.value).value // get as original type
                    }
                    props.onChange(newProductValue);
                }}
            >
                {options?.map(lev =>
                    <option key={lev.value} value={lev.value}>{lev.label}</option>
                )}
            </select>
        </div>
    </div>
}

const debouncedSaveValue = debounce((val, callback) => {
    callback?.(val);
}, 1000);

export const AttInputContin = (props) => {

    const att = props.att;
    const product = props.product;
    const compProduct = props.compProduct;
    const myValue = product[att.name];
    const [value, setValue] = useState(`${myValue}`);
    const [selected, setSelected] = useState(false); // todo: move this up in component tree

    const dirty = product?.[att.name] != compProduct?.[att.name];

    const dirtyBitClicked = () => {
        if( dirty ){
            const newProductValue = {
                ...product,
                [att.name]: compProduct[att.name]
            };
            props.onChange?.(newProductValue);
        }
    }
    

    const handleSetValue = (ev) => {
        const newVal = ev.target.value;
        setValue(newVal);
        debouncedSaveValue(newVal, saveValue);
    }

    const step = att.interval || 1;
    const adjust = (multiplier) => {
        try {
            const f = parseFloat(value);
            if (isNaN(f)) return;
            if (!isFinite(f)) return;
            let newVal = f + (step * multiplier);

            // remove anything past hundredth
            newVal = Math.round(newVal * 100) / 100;

            // constrain to domain
            if (newVal < att.min) newVal = att.min;
            if (newVal > att.max) newVal = att.max;

            setValue(newVal);
            debouncedSaveValue(newVal, saveValue);
        }
        catch { }
    }

    const toggleSelected = () => setSelected(!selected);
    

    const saveValue = (newVal) => {

        const success = internalSaveValue(newVal);
        if( !success ){
            setValue(myValue);
        }
    }

    const internalSaveValue = (newVal) => {
        
        // ensure it's a valid float
        try {
            const f = parseFloat(newVal);
            if (isNaN(f)) return;
            if (!isFinite(f)) return;
            newVal = f;
        }
        catch {
            return;
        }

        // reject if outside domain
        if (newVal < att.min) newVal = att.min;
        if (newVal > att.max) newVal = att.max;

        // constrain to domain
        // if (newVal < att.min) newVal = att.min;
        // if (newVal > att.max) newVal = att.max;

        // save
        const newProductValue = {
            ...product,
            [att.name]: newVal
        };
        props.onChange?.(newProductValue);
        return newVal;
        
    }

    useEffect(() => {
        setValue(`${myValue}`);
    }, [myValue])

    const f1 = format(att.format || "$.2f");
    return <div className={`att-input ${selected ? "sel" : ""}`} style={props.style}>
        {props.hideLabel ? null : <div className="att-label">{att.label}</div>}
        {/* <div className={`att-select ${selected ? "sel" : ""}`} onClick={ev => toggleSelected()}>
            <i className="fas fa-circle-check" />
        </div> */}
        <div className="att-value">

            <div className="contin-box">
                <input type="text" 
                    tabIndex={props.tabIndex}
                    value={value}
                    onChange={handleSetValue}
                />
            </div>
        </div>

        <div className="att-edit-btn">
            
            <Popup
                position="center center"
                keepTooltipInside
                trigger={
                    <span className="btn">
                        <i className="fal fa-ellipsis" />
                    </span>
                }
                contentStyle={{
                    width: 300,
                    height: 150,
                    backgroundColor: "#ffffff",
                    border: "1px solid #cfcfcf",
                    boxShadow: "5px 5px 5px #cfcfcf"
                }}
            >
                {close => <div className="contin-att-edit-dlg">
                    <div className="title">
                        {att.label}
                    </div>
                    <div className="full-dock text-section">
                        <div className="left  flex-vcenter adjust-btn dec"
                            onClick={() => adjust(-1)}
                        >
                            <i className="fal fa-circle-minus"/>
                        </div>
                        <div className="center flex-vcenter">
                            <input
                                type="text"
                                className="edit-box"
                                value={value}
                                onChange={handleSetValue}
                            />
                        </div>
                        <div className="right flex-vcenter adjust-btn inc"
                            onClick={() => adjust(1)}
                        >
                            <i className="fal fa-plus-circle"/>
                        </div>
                    </div>
                    <div className="full-dock slider-section">
                        <div className="left flex-vcenter domain-value min">
                            Min: {f1(att.min)}
                        </div>
                        <div className="center flex-vcenter">
                            <input
                                className="edit-slider"
                                type="range"
                                value={value}
                                min={att.min}
                                max={att.max}
                                step={step}
                                onChange={handleSetValue}
                            />
                        </div>
                        <div className="right flex-vcenter domain-value max">
                            Max: {f1(att.max)}
                        </div>
                    </div>
                    <div className="bottom-buttons">
                        <button onClick={() => {
                            close();
                        }}>ok</button>
                        <button onClick={() => {
                            
                        }}>
                            cancel
                        </button>
                    </div>
                </div>}
            </Popup>
            
        </div>

        <div className="att-dirty-bit" 
            onClick={() => dirtyBitClicked()}
            title={dirty ? "Click to reset value to base case" : "Value matches base case"}
            >
            {dirty ? <i className="fas fa-circle dirty" /> : <i className="fal fa-circle" />}
        </div>
    </div>
    
}

export const OldAttInputContin2 = (props) => {

    const att = props.att;
    const productValue = props.productValue;
    const myValue = productValue[att.name];
    const [value, setValue] = useState(`${myValue}`);

    const handleSetValue = (ev) => {
        const newVal = ev.target.value;
        setValue(newVal);
        debouncedSaveValue(newVal, saveValue);
    }

    const step = att.interval || 1;
    const adjust = (multiplier) => {
        try {
            const f = parseFloat(value);
            if (isNaN(f)) return;
            if (!isFinite(f)) return;
            let newVal = f + (step * multiplier);

            // remove anything past hundredth
            newVal = Math.round(newVal * 100) / 100;

            // constrain to domain
            if (newVal < att.min) newVal = att.min;
            if (newVal > att.max) newVal = att.max;

            setValue(newVal);
            debouncedSaveValue(newVal, saveValue);
        }
        catch { }
    }

    const saveValue = (newVal) => {
        
        // ensure it's a valid float
        try {
            const f = parseFloat(newVal);
            if (isNaN(f)) return;
            if (!isFinite(f)) return;
            newVal = f;
        }
        catch {
            return;
        }

        // reject if outside domain
        if (newVal < att.min) return;
        if (newVal > att.max) return;

        // constrain to domain
        // if (newVal < att.min) newVal = att.min;
        // if (newVal > att.max) newVal = att.max;

        // save
        const newProductValue = {
            ...productValue,
            [att.name]: newVal
        };
        props.onChange?.(newProductValue);
    }

    useEffect(() => {
        setValue(`${myValue}`);
    }, [myValue])

    const f1 = format(att.format || "$.2f");
    return <div className="att-input" style={props.style}>
        {props.hideLabel ? null : <div className="att-label">{att.label}</div>}
        <div className="att-select">
            <i className="fal fa-circle-check" />
        </div>
        <div className="att-value">
            <Popup
                position="center center"
                keepTooltipInside
                trigger={
                    <div className="contin-box">
                        {f1(myValue)}
                    </div>
                }
                contentStyle={{
                    width: 300,
                    height: 150,
                    backgroundColor: "#ffffff",
                    border: "1px solid #cfcfcf",
                    boxShadow: "5px 5px 5px #cfcfcf"
                }}
            >
                <div className="contin-att-edit-dlg">
                    <div className="title">
                        {att.label}
                    </div>
                    <div className="full-dock text-section">
                        <div className="left  flex-vcenter adjust-btn dec"
                            onClick={() => adjust(-1)}
                        >
                            <i className="fal fa-circle-minus"/>
                        </div>
                        <div className="center flex-vcenter">
                            <input
                                type="text"
                                className="edit-box"
                                value={value}
                                onChange={handleSetValue}
                            />
                        </div>
                        <div className="right flex-vcenter adjust-btn inc"
                            onClick={() => adjust(1)}
                        >
                            <i className="fal fa-plus-circle"/>
                        </div>
                    </div>
                    <div className="full-dock slider-section">
                        <div className="left flex-vcenter domain-value min">
                            Min: {f1(att.min)}
                        </div>
                        <div className="center flex-vcenter">
                            <input
                                className="edit-slider"
                                type="range"
                                value={value}
                                min={att.min}
                                max={att.max}
                                step={step}
                                onChange={handleSetValue}
                            />
                        </div>
                        <div className="right flex-vcenter domain-value max">
                            Max: {f1(att.max)}
                        </div>
                    </div>
                </div>
            </Popup>
            
        </div>
    </div>
    
}

export const OldAttInputContin = (props) => {
    const att = props.att;
    return <div className='att-input'>
        <div className="att-label">{att.label}</div>
        <div className="att-value">
            <input type="text" value="100.0"/>
        </div>
    </div>
}

export const AttInputBool = (props) => {
    const att = props.att;
    const productValue = props.productValue;
    const myValue = productValue[att.name];
    const trueValue = ifNDef(att.trueValue, 1);
    const falseValue = ifNDef(att.falseValue, 0);

    let style = {
        ...props.style
    }
    
    if (props.hideLabel) {
        style.textAlign = "center";
    }

    return <div className="att-input-bool" style={style}>
        <label>
            <input type="checkbox" checked={myValue == trueValue} onChange={(ev) => {
                const newProductValue = {
                    ...productValue,
                    [att.name]: ev.target.checked ? trueValue : falseValue
                }
                props.onChange(newProductValue);
            }} />
            {props.hideLabel ? null : <span style={{ marginLeft: 5 }}>{att.label}</span>}
        </label>
    </div>

}

export const AttInputSlider = (props) => {
    const att = props.att;
    return <div className='att-input sep'>
        <div className="att-label">{att.label}</div>
        <div className="att-value">
            <input type="range"/>

        </div>
    </div>
}