import { useDataSource, useDataSourceApi, useDataSourcesApi } from "../../hooks/useDataSource";
import "./DataSourceRevision.scss";
import moment from 'moment';
import Popup from "reactjs-popup";
import { UploadDataFileModal } from "./UploadDataFileModal";
import { TextFrame, VerifyFrame } from "../modal-dialog/VerifyFrame";
import { Link } from "react-router-dom";
import { useProjectId } from "../../hooks/useProjectId";
import React from "react";
import * as globals from "../../globals";
import * as restMethods from "../../api/restMethods";
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";
import { useState } from "react";
import { useDataSourceRevision } from "../../hooks/useDataSourceRevision";
import { toast } from "react-toastify";
import axios from "axios";
import { getAuthHeaders } from "../../api/restMethods.js";
import { saveAs } from "file-saver";

export const DataSourceRevision = (props) => {

    const ds = props.ds;
    const revEntry = props.entry;
    const reload = props.reload;
    const ds_id = props.ds_id;
    const rev_id = props.id;

    const [status, setStatus] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(false);
    
    const dssApi = useDataSourcesApi();
    const api = useDataSourceApi(ds_id);

    const auth = useAuth();

    const { subscription_id, project_id } = useProjectId();

    const prod_revision = ds.prod_revision;
    const dev_revision = ds.dev_revision;

    const setDevPointer = async () => {
        await dssApi.patchItem(ds_id, {
            dev_revision: rev_id
        })
    }

    const setProdPointer = async () => {
        await dssApi.patchItem(ds_id, {
            prod_revision: rev_id
        })
    }

    // not using this yet. not sure if it works. not sure if i'll use it.
    const getStatus = async (rev) => {
        
        const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/data-source/${ds_id}/revision/${rev_id}/status`;
        try{
            setLoadingStatus(true);
            const response = await restMethods.getJson(auth, url);
            if( response.ok ){
                const s = await response.json();
                setStatus(s);
            }
        }
        catch(err){
            alert(err);
            setStatus(null);
            return;
        }
        setLoadingStatus(false);

    }

    const setMountState = async (rev, mount) => {

        const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/data-source/${ds_id}/revision/${rev_id}/${mount ? "mount" : "unmount"}`;
        
        console.log("setMountState", rev, mount);
        let response;
        try{
            response = await restMethods.getJson(auth, url);
            if( response.ok ){
                //const status = await response.json();

            }
        }
        catch(err){
            console.error(err);
            return;
        }
        
        if( !response.ok ){
            let message = response.statusText;
            try{
                message = await response.text();
            }
            catch{}
            alert(message);
            return false;
        }
        else{
            // var item = await response.json();
            // alert(item);
            //return item;
            return true;
        }
    }
        
    const toastMount = (rev, mountState = true) => {
        toast.promise(
            async () => {
                await setMountState(rev, mountState)
                reload?.();
            },
            {
                pending: mountState ? "Mounting. This can take a while..." : "Unmounting...",
                success: mountState ? "Mount success" : "Unmounted",
                error: mountState ? "Mount failed": "Error unmounting",
            },
            {
                autoClose: 1000
            }
        );
    }

    // const mount = async (rev) => {
    //     await setMountState(rev, true);
    //     reload?.();
    // }

    // const unmount = async (rev) => {
    //     await setMountState(rev, false);
    //     reload?.();
    // }

    const toastUninspect = async (rev) => {
        toast.promise(uninspect,
            {
                pending: "Removing inspection...",
                success: "Inspection removed",
                error: "Inspection removal failed.",
            },
            {
                autoClose: 1000
            }
        );
    }

    const uninspect = async (rev) => {

        const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/data-source/${ds_id}/revision/${rev_id}/uninspect`;
        
        console.log("uninspect", rev);
        let response;
        try{
            response = await restMethods.getJson(auth, url);
            reload?.();
        }
        catch(err){
            console.error(err);
            return false;
        }

        if( response.ok ){
            return true;
        }
        else{
            return false;
        }

    }

    const toastInspect = async (rev) => {
        toast.promise(inspect,
            {
                pending: "Inspecting. Please wait...",
                success: "Inspection succeeded",
                error: "Inspection failed.",
            },
            {
                autoClose: 1000
            }
        );
    }

    const inspect = async (rev) => {

        const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/data-source/${ds_id}/revision/${rev_id}/inspect`;
        
        console.log("inspect", rev);
        let response;
        try{
            response = await restMethods.getJson(auth, url);
            reload?.();
        }
        catch(err){
            console.error(err);
            return false;
        }

        if( response.ok ){
            return true;
        }
        else{
            return false;
        }
    }

    const active = false;

    const downloadFile = async (revEntry) => {
        const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/data-source/${ds.id}/revision/${revEntry.id}/file`;
        
        const res = await axios.get(
            url,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    ...getAuthHeaders(auth)
                },
                responseType: "blob"
            }
        );
        console.log("res", res?.data)
        const buffer = res.data;
        const fileName = revEntry.file_info?.filename;
        saveAs(
            //new Blob([buffer], { type: 'application/x-binary' }),
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName
        )

    }

    if( !revEntry ) return <div>no rev</div>;

    return <div className={`data-source-revision-box ${active ? "active" : ""} ${revEntry.mounted ? "mounted" : ""}`}>
        <div className="rev-number">
            {revEntry.id}
            {props.compareUrl && <div className="compare-button" title="Compare to previous revision">
                <Link 
                    className="compare-link"
                    to={props.compareUrl}
                >
                    <i className="fal fa-code-compare" />
                </Link>
            </div>}
        </div>
        
        <div className="rev-content">
            {<div className={`rev-status ${revEntry.mounted ? "mounted" : "unmounted"}`}>
                {(ds.type === "flow") ? (
                    <span></span>
                ) : ds.structured ?
                    (revEntry.mounted ?
                        <span className={`mount-pill mounted`}
                            onClick={() => toastMount(revEntry, false)} style={{ cursor: "pointer" }} title="Mounted"
                        >
                            <span className="icon">
                                <i className="fas fa-circle-check" />
                            </span>
                            Mounted
                        </span>
                        :
                        <span className={`mount-pill not-mounted`}
                            onClick={() => toastMount(revEntry)} style={{ cursor: "pointer" }} title="Not mounted"
                        >
                            <span className="icon">
                                <i className="fal fa-times-circle" />
                            </span>
                            Not mounted
                        </span>
                    ) : <span className={`mount-pill mounted`}
            >
                <span className="icon">
                    <i className={ds.fileType === "json" ? "fas fa-brackets-curly" : "fas fa-code"} />
                </span>
                {ds.fileType} (semi-structured)
            </span>}
                {ds.structured && (revEntry.inspected ?
                    <span className={`inspect-pill inspected`}
                        onClick={() => toastUninspect(revEntry)} style={{ cursor: "pointer" }} 
                        title="Inspected"
                    >
                        <i className="fas fa-microscope" />
                    </span>
                    :
                    <span className={`inspect-pill not-inspected`}
                        onClick={() => toastInspect(revEntry)} 
                        style={{ cursor: "pointer" }} 
                        title="Not inspected"
                    >
                        <i className="fal fa-microscope" />
                    </span>
                )}
                <span 
                    className={`env-pill dev ${revEntry.id === dev_revision ? "on": ""}`} 
                    title="Set as Dev"
                    onClick={() => setDevPointer()}
                >
                    Dev
                </span>
                <span 
                    className={`env-pill prod ${revEntry.id === prod_revision ? "on" : ""}`} 
                    title="Set as Prod"
                    onClick={() => setProdPointer()}
                >
                    Prod
                </span>
                {/* <span className="status" onClick={() => getStatus()}>
                    working_status: {loadingStatus ? "..." : (status?.working_status ?? revEntry?.working_status)}
                </span> */}
            </div>}
            <div className="rev-notes">
                {revEntry.notes}
            </div>
            <div className="rev-filename">
                <span className="label">file: </span>
                
                <span className="btn-filename-download" onClick={async ev => {

                    toast.promise(
                        async () => {
                            await downloadFile(revEntry);
                        },
                        {
                            pending: "Downloading file in non-optimal manner. Huge files may not work...",
                            success: "Downloaded",
                            error: "Error downloading",
                        },
                        {
                            autoClose: 1000
                        }
                    );

                }}>
                    {revEntry.file_info?.filename}
                    <i className="fal fa-arrow-down-to-line" />
                </span>
                
                {(revEntry.file_info?.size && !isNaN(revEntry.file_info.size)) &&
                    <span className="size">
                        {Math.round(10 * revEntry.file_info?.size / 1024 / 1024) / 10.0} MB
                    </span>
                }
            </div>
            <div className="rev-created">
                Uploaded {moment.utc(revEntry?.created).local().fromNow()} by {revEntry.author?.name}
            </div>
            {revEntry.error ? <div className="rev-error">
                Mount error: {revEntry.error}
            </div> : null
            }
        </div>
        <div className="rev-menu">
            
            {false &&
                <Popup 
                    position={"bottom right"}
                    keepTooltipInside
                    contentStyle={{
                        width: 250
                    }}
                    trigger={
                        <span className="btn icon" style={{ color: "#ffffff", width: "unset", padding: "0 10px" }}>
                            <span>
                                <i className='far fa-paperclip' style={{ color: revEntry.attachments?.length ? "#ffffff" : "#404040" }} />&nbsp;{revEntry.attachments?.length && `(${revEntry.attachments.length})`}
                            </span>
                        </span>
                    }>
                    <div className="popup-menu" style={{ width: 250 }}>
                        {revEntry.attachments?.map(att => 
                            <div className="menu-item" key={att.filename}>
                                <i className="fal fa-arrow-down-to-line"/>&nbsp;&nbsp;
                                {att.filename}
                            </div>
                        )}
                        <div className="menu-item">Attach file...</div>
                    </div>
                </Popup>
            }
            
            <Popup
                nested
                contentStyle={{ 
                    padding: '0px', 
                }}
                trigger={<span className='btn icon' ><i className='far fa-ellipsis-h' /></span>}
                arrow={false}
                position="bottom right">
                {closeMenu => <ul className='popup-menu'>
                        <Popup modal 
                            contentStyle={{ width: 500 }}
                        trigger={
                            <li>Edit notes</li>
                        }>
                            {closeModal => <TextFrame
                                title={"Edit revision notes"}
                                label={`Revision ${revEntry.id}`}
                                initialTextValue={revEntry.notes}
                                close={closeModal} 
                                confirmed={async (text) => {

                                    const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/data-source/${ds_id}/revision/${rev_id}`;
                                    try{
                                        const response = await restMethods.aPatch(auth, url, {
                                            notes: text
                                        });
                                    }
                                    catch(err){
                                        alert(err);
                                        return;
                                    }

                                    props.reload?.();

                                    closeMenu();
                                }}
                            />}
                        </Popup>
                    
                        <Popup modal trigger={
                            <li>Delete revision</li>
                        }>
                            {closeModal => <VerifyFrame
                                title={"Delete revision"}
                                label={`Revision ${revEntry.id}: ${revEntry.notes}`}
                            close={closeModal}
                            confirmed={async () => {
                                await api.deleteRevision(revEntry.id);
                                reload?.();
                                closeMenu();
                            }}
                        />}
                        </Popup>
                </ul>}
            </Popup>
        </div>
    </div>
    
}