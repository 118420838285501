import { getJson } from "../api/restMethods";
import * as globals from "../globals";
import { useAuth } from "./useAuth";
import { useProjectId } from "./useProjectId";

export const useClusterApi = () => {
    const {subscription_id} = useProjectId();
    const auth = useAuth();
    return {
        getStats: async () => {
            const stats = await getJson(auth, `${globals.apiRoot}/subscription/${subscription_id}/cluster/stats`);
            return stats;
        }
    }
}
