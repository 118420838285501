import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import * as globals from "../../globals";
import "./RightMenu.scss";


export const RightMenu = (props) => {

    const title = props.title || "needs title";

    const open = props.open;// useSelector(state => state.ui.rightMenuOpen);
    //const [open, setOpen] = useState(false);
    const [pinned, setPinned] = useState(false);
    const dispatch = useDispatch();

    let actualPinned = pinned;
    let actualOpen = open;

    const collapsePoint = props.v2 ? globals.metrics["left-menu-collapse-point2"] :
        globals.metrics["left-menu-collapse-point"];

    const skinny = useMediaQuery(`(max-width: ${collapsePoint}px)`);

    // if skinny, ignore pinned state
    if (skinny) actualPinned = false;

    // if wide enough, force pin
    //if (!skinny) actualPinned = true;

    // if pinned, force open
    // if (actualPinned) {
    //     actualOpen = true;
    // }

    // if skinny and !open, okay

    let classNames = [];
    classNames.push("right-menu");
    //classNames.push(props.className || "");
    classNames.push(actualOpen ? "open" : "collapsed");


    return <div className={classNames.join(" ")}>
        {/* {actualPinned ? null : <div
            className="openment-tab"
            onClick={() => {
                //setOpen(!open);
                dispatch({ type: "RIGHT_MENU_OPEN_SET", value: !open });
            }}
        >
            {actualOpen ? <i className="far fa-chevron-down"/> : <i className="far fa-chevron-up"/>}
        </div>} */}
        <div className="right-menu-content">
            {props.children}
        </div>
    </div>;
}

