import { useContext, useEffect, useState } from "react";
import { DataSourceContext, HasPipelineContext } from "../../context/Contexts";
import { useDataSource } from "../../hooks/useDataSource";
import { useDataSourceRevisions } from "../../hooks/useDataSourceRevision";
import { ContentAdderButton } from "../content/ContentAdderButton";
import { DisplayContext, getDisplayClass } from "../content/DisplayContext";
import { EditContentContext } from "../content/EditContentContext";
import { PresentContent } from "../content/PresentContent";
import { EditPipelineWithPreview } from "../pipeline/EditPipelineWithPreview";
import "./PipelineContext.scss";

export const PipelineContext = (props) => {

    const ec = useContext(EditContentContext);
    const mode = ec.mode;
    const item = ec.item;
    const children = item?.children;
    const firstChild = children?.[0];

    const jdata = item?.jdata;
    const dataSourceId = jdata?.dataSourceId;

    const [ds, dsLoading, dsError, dsTriggerRefresh] = useDataSource(dataSourceId);
    const [revisions, revsLoading, revsError, revsTriggerReload] = useDataSourceRevisions(dataSourceId);
    const [selectedRevisionId, setSelectedRevisionId] = useState(null);

    const [pipeline1, setPipeline1] = useState(null);
    const p1Context = {
        pipeline: pipeline1,
        setPipeline: setPipeline1
    }

    useEffect(() => {
        if( !ds ) return;
        setSelectedRevisionId(ds.dev_revision);
        setPipeline1({ ...pipeline1, dataSourceId: ds?.id });
    }, [ds])
    

    return <div className={`mstudio-pipeline-context ${props.displayClass}`} style={{ position: "relative" }} {...props.atts}
        onClick={(ev) => {
            ev.stopPropagation();
        }}
    >
        <DataSourceContext.Provider value={ds}>
            <HasPipelineContext.Provider value={p1Context}>
                <EditPipelineWithPreview />
            </HasPipelineContext.Provider>
        </DataSourceContext.Provider>
        


    </div>
}