import { useEffect, useState } from "react";
import { useStatsApi } from "../../hooks/useStatsApi";
import moment from "moment";
import "./MyLoginStats.scss";


const getDayValue = (dateObj) => {
    const month = dateObj.getUTCMonth() + 1; //months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    return year * 10000 + month * 100 + day;
}

function getDateXDaysAgo(numOfDays, date) {
    const daysAgo = new Date(date.getTime());
    daysAgo.setDate(date.getDate() - numOfDays);
    return daysAgo;
}

export const MyLoginStats = (props) => {

    const [stats, setStats] = useState(null);
    const api = useStatsApi();

    useEffect(() => {
        const f = async () => {
            const x = await api.getMyLoginStats();
            setStats(x);
        }
        f();
    }, [])

    // get today
    const now = new Date();

    // get last sunday
    const sunday = getDateXDaysAgo(now.getDay(), now);

    let days = [];
    // const nbDays = 100;
    // for( let i = 1; i <= nbDays; i++ ){
    //     const daysAgo = nbDays - i;
    //     const dateObj = getDateXDaysAgo(daysAgo, now);
    //     const day = getDayValue(dateObj);
    //     days.push({ dateObj, day });
    // }
    let dict = stats?.reduce?.((prev, row) => {
        prev[row.day] = row;
        return prev;
    }, {});
    

    const nbWeeks = 17;
    let weeks = [];
    for( let i = 1; i <= nbWeeks; i++ ){
        const weeksAgo = nbWeeks - i;
        const dateObj = getDateXDaysAgo(weeksAgo * 7, sunday);
        weeks.push({ dateObj });
    }

    return <div className="my-login-stats">
        <div className="title">
            Past logins
        </div>
        <div className="box">
            <span className="week-column">
                {["S", "M", "T", "W", "Th", "F", "S"].map((w, idx) => 
                    <span key={idx} className="day-label">
                        {w}
                    </span>
                )}
            </span>
            {weeks.map((weekObj, idx) => {

                const days = [];
                for( let di = 0; di > -7; di-- ){
                    const dateObj = getDateXDaysAgo(di, weekObj.dateObj);
                    days.push({ dateObj });
                }

                return <span key={idx} className="week-column" xtitle={weekObj.dateObj.toDateString()}>
                    {days.map((dayObj, dayIdx) => {
                        const cell = dict?.[getDayValue(dayObj.dateObj)];
                        const hit = cell?.count;
                        const dayStr = moment.utc(dayObj.dateObj).local().fromNow();
                        const future = dayObj.dateObj > now;
                        return <span key={dayIdx} 
                            className={`day-box ${dayIdx === 0 || dayIdx == 6 ? "weekend" : ""} ${hit ? "hit" : ""} ${future ? "future" : ""}`}
                            title={dayStr + "\n" + dayObj.dateObj.toDateString()}>
                                
                        </span>
                    })}
                </span>
            })}
        </div>
    </div>
}