import * as d3Format from 'd3-format';
import { useState } from 'react';
import { isObj } from '../../utils/ContentProcessor';
import './DataTableDiff.scss';

const DEFAULT_COL_WIDTH = 120;

export const isCellObj = (obj) => {
    if(!isObj(obj))
        return false;
    if( obj.val !== undefined && obj.n !== undefined ){
        return true;
    }
}

export const toCellJsx = (obj) => {
    return <span title={`n=${obj.n}`}>{obj?.val}</span>;
}

const valFmt = d3Format.format(",.4f");

export const toSmartObj = (obj) => {
    if( !obj ) return null;
    if( obj.val !== undefined ) {
        try{
            const fmt = obj.format ? d3Format.format(obj.format) : valFmt;
            return {
                className: "val-obj",
                title: JSON.stringify(obj, null, 4), // `n=${obj.n}`,
                content: (obj.val === null ? "." : fmt(obj.val)),
                n: obj.n
            }
            ;
        }
        catch{
            // value format probably failed
            return {
                className: "val-obj",
                //title: JSON.stringify(obj, null, 4),// `n=${obj.n}`,
                content: obj.val,
                n: obj.n
            }
        }
    }
    if( obj.label && obj.id ){
        return {
            className: "label-obj",
            title: JSON.stringify(obj, null, 4), // `id=${obj.id}`,
            content: obj.label,
            markup: obj.syntax
        }
        ;
    }
    return {
        className: "generic-obj",
        content: JSON.stringify(obj)
    }
}


export const DataTableDiff = (props) => {

    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);

    const data1 = props.data1 || props.rows1 || [];
    const data2 = props.data2 || props.rows2 || [];
    const allData = [...data1, ...data2];

    let columns = props.columns || props.cols || [];
    if( props.autoGenerateColumns ){
        const allowNest = false;
        columns = [];
        allData?.forEach?.(row => {
            Object.keys(row).forEach(key => {
                
                // is this an object?
                if( allowNest && row[key] && typeof(row[key]) === "object" ){
                    Object.keys(row[key]).forEach(nestedKey => {
                        const nkey = `${key}.${nestedKey}`;
                        let findCol = columns.find(col => nkey === col.key);
                        if( !findCol ){
                            columns.push({ key: nkey, nested: true, parentKey: key, childKey: nestedKey });
                        }
                    })
                }
                else{
                    let findCol = columns.find(col => key === col.key);
                    if( !findCol ){
                        columns.push({ key: key });
                    }
                }
            })
        })
    }
    
    const options = props.options || {};
    const { rowHover, selectRow } = options;
    
    const getCategoryKey = (col) => `${col.category}${col.sticky ? "[sticky]" : ""}`;

    let categories = [];
    {
        let lastCategoryObj = undefined;
        for(let c of columns){
            if( lastCategoryObj && getCategoryKey(c) === lastCategoryObj.uKey ){
                lastCategoryObj.columns.push(c);
            }
            else{
                lastCategoryObj = {
                    label: c.category,
                    uKey: getCategoryKey(c),
                    columns: [c],
                    sticky: c.sticky
                }
                categories.push(lastCategoryObj);
            }
        }
    }

    const hasAtLeastOneCategory = categories.find(c => c.label) ? true : false;


    const combinedRows = [];
    for( let i = 0; i < data1?.length || i < data2?.length; i++ ){
        const row1 = data1?.[i];
        const row2 = data2?.[i];
        combinedRows.push({ index: i, row1, row2 });
    }
    

    return <div className={`data-table-diff ${selectRow ? ' no-select' : ''} ${props.className || ""}`} style={props.style} >
        <table>
            <thead>

                {/* categories */}
                {hasAtLeastOneCategory ?
                <tr>
                    {categories.map((cat, i) => {
                        const width = cat.columns.reduce((a, e) => a + (e.width || DEFAULT_COL_WIDTH), 0);
                        
                        let catHeaderStyle = {
                            width,
                            minWidth: width,
                            textAlign: "center",
                        }

                        if( cat.sticky ){
                            catHeaderStyle.position = "sticky";
                            catHeaderStyle.left = 0;
                            catHeaderStyle.backgroundColor = "#ffffff";
                            catHeaderStyle.zIndex = 1;
                        }

                        return <th className="cat" key={i} style={catHeaderStyle} colSpan={cat.columns.length}>
                            {cat.label}
                        </th>;
                    })}
                </tr> : null}

                {/* column headers */}
                {[0].map((_, _idx) => {
                    //let lastColSep = false;
                    return <tr key={_idx}>
                        {categories.map((cat, catIdx) =>
                            cat.columns.map((col, colIdx) => {
                                let headerStyle={
                                    width: col.width || DEFAULT_COL_WIDTH,
                                    minWidth: col.width,
                                    ...col.style,
                                    ...col.headerStyle
                                }

                                if((colIdx === cat.columns.length - 1) && (catIdx < categories.length - 1)){
                                    headerStyle.borderRight = "2px solid #cfcfcf";
                                }
                                                                
                                return <th key={col.key} style={headerStyle}>
                                    {col.label || col.key}
                                </th>
                            })
                        )}
                    </tr>
                })}

            </thead>
            <tbody>

                {/* rows */}
                {combinedRows.map?.((cRow, rowIndex) => {

                    const { row1, row2 } = cRow;

                    return <tr key={rowIndex} className={`${rowHover ? 'row-hover' : ''} ${selectedRow === cRow ? ' sel' : ''}`}
                        onClick={() => {
                        if (selectRow) {
                            setSelectedRow(cRow);
                        }
                    }}>
                        {categories.map((cat, catIdx) => 
                            cat.columns.map((col, colIdx) => {

                                let cellStyle={
                                    width: col.width || DEFAULT_COL_WIDTH,
                                    minWidth: col.width,
                                    ...col.style,
                                    ...col.cellStyle
                                }

                                if((colIdx === cat.columns.length - 1) && (catIdx < categories.length - 1)){
                                    cellStyle.borderRight = "2px solid #cfcfcf";
                                }
                                
                                const val1 = col.nested ? row1?.[col.parentKey]?.[col.childKey] : row1?.[col.key];
                                const val2 = col.nested ? row2?.[col.parentKey]?.[col.childKey] : row2?.[col.key];

                                let val1Str;
                                let val2Str;

                                let cellClasses1 = "";
                                let cellClasses2 = "";
                                
                                let title1 = null;
                                let title2 = null;
                                let n1 = null;
                                let n2 = null;
                                let markup1 = null;
                                let markup2 = null;


                                if (col.type === "string") {
                                    
                                    // val1
                                    if (val1 === null) val1Str = <i>null</i>;
                                    else if (val1 === undefined) val1Str = null;
                                    else if (isObj(val1)) val1Str = "[object]";
                                    else val1Str = val1;

                                    // val2
                                    if (val2 === null) val2Str = <i>null</i>;
                                    else if (val2 === undefined) val2Str = null;
                                    else if (isObj(val2)) val2Str = "[object]";
                                    else val2Str = val1;

                                }
                                else if (col.type === "number") {
                                    
                                    // val1
                                    if (isNaN(val1) || val1 === null || val1 === undefined) {
                                        val1Str = ".";
                                    }
                                    else if (isObj(val1)) val1Str = "[object]";
                                    else {
                                        val1Str = col.valueFormat ? d3Format.format(col.valueFormat)(val1) : val1;
                                    }

                                    // val2
                                    if (isNaN(val2) || val2 === null || val2 === undefined) {
                                        val2Str = ".";
                                    }
                                    else if (isObj(val2)) val2Str = "[object]";
                                    else {
                                        val2Str = col.valueFormat ? d3Format.format(col.valueFormat)(val2) : val2;
                                    }

                                }
                                else {

                                    // val1
                                    if (val1 === null) val1Str = <i>null</i>;
                                    else if (val1 === undefined) val1Str = null;
                                    else if (isObj(val1) && props.smartObj) {
                                        const sObj = toSmartObj(val1);
                                        val1Str = sObj?.content;
                                        title1 = sObj?.title;
                                        if( sObj?.className ){
                                            cellClasses1 += " " + sObj?.className;
                                        }
                                        n1 = sObj?.n;
                                        markup1 = sObj?.markup;
                                    }
                                    else if (isObj(val1)) val1Str = JSON.stringify(val1);
                                    else if (Array.isArray(val1)) val1Str = "[array]";
                                    else val1Str = val1;

                                    // val2
                                    if (val2 === null) val2Str = <i>null</i>;
                                    else if (val2 === undefined) val2Str = null;
                                    else if (isObj(val2) && props.smartObj) {
                                        const sObj = toSmartObj(val2);
                                        val2Str = sObj?.content;
                                        title2 = sObj?.title;
                                        if( sObj?.className ){
                                            cellClasses2 += " " + sObj?.className;
                                        }
                                        n2 = sObj?.n;
                                        markup2 = sObj?.markup;
                                    }
                                    else if (isObj(val2)) val2Str = JSON.stringify(val2);
                                    else if (Array.isArray(val2)) val2Str = "[array]";
                                    else val2Str = val2;

                                }

                                if( val1Str === val2Str ){
                                    return <td className={`cell ${cellClasses1}`} key={col.key} style={cellStyle} title={title1}>
                                        {val1Str}
                                        {markup1 ? <span className="markup">{markup1}</span> : null}
                                        {n1 ? <span className="n-size">{n1}</span> : null}
                                    </td>
                                }
                                else{
                                    return <td className={`cell flag-diff ${cellClasses1}`} key={col.key} style={cellStyle} title={title1 + "|" + title2}>
                                        <div>
                                            L:{val1Str}
                                            {markup1 ? <span className="markup">{markup1}</span> : null}
                                            {n1 ? <span className="n-size">{n1}</span> : null}
                                        </div>
                                        <div>
                                            R:{val2Str}
                                            {markup2 ? <span className="markup">{markup2}</span> : null}
                                            {n2 ? <span className="n-size">{n2}</span> : null}
                                        </div>
                                    </td>
                                }
                            })
                        )}
                    </tr>
                })}
            </tbody>
        </table>
    </div>;
    
}