
export const getAuthHeaders = (auth) => {
    if( !auth ) return null;
    return {
        Authorization: `Bearer ${auth.token}`
    }
}

export const aDelete = (auth, url) => fetch(url, {
    method: 'delete',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...getAuthHeaders(auth)
    }
});

export const getJson = (auth, url) => fetch(url, {
    method: 'get',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...getAuthHeaders(auth)
    }
});

export const aGet = getJson;

export const postJson = (auth, url, body) => fetch(url, {
    method: 'post',
//    mode: "cors",
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...getAuthHeaders(auth)
    },
    body: JSON.stringify(body)
});

export const aPut = (auth, url, body) => fetch(url, {
    method: 'put',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...getAuthHeaders(auth)
    },
    body: JSON.stringify(body)
});


export const aPatch = (auth, url, body) => fetch(url, {
    method: "PATCH",
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...getAuthHeaders(auth)
    },
    body: JSON.stringify(body)
});
