//import ExcelJS from "exceljs";
import { getProperty } from 'dot-prop';
//import {getProperty, setProperty, hasProperty, deleteProperty} from 'dot-prop';

export const tableToExcel = async (rows, columns, options) => {

    const ExcelJS = await import("exceljs");

    const workbook = new ExcelJS.Workbook();

    const sheet1 = workbook.addWorksheet('Sheet1');

    if( !columns || options.autoGenerateColumns ){
        const allowNest = true;
        columns = [];
        rows?.forEach?.(row => {
            Object.keys(row).forEach(key => {
                
                // is this an object?
                if( allowNest && row[key] && typeof(row[key]) === "object" ){
                    Object.keys(row[key]).forEach(nestedKey => {
                        const nkey = `${key}.${nestedKey}`;
                        let findCol = columns.find(col => nkey === col.key);
                        if( !findCol ){
                            columns.push({ key: nkey, nested: true, parentKey: key, childKey: nestedKey });
                        }
                    })
                }
                else{
                    let findCol = columns.find(col => key === col.key);
                    if( !findCol ){
                        columns.push({ key: key });
                    }
                }
            })
        })
    }

    sheet1.columns = columns.map(col => ({
        header: col.key,
        key: col.key,
        width: 20
    }))


    let rowPos = 1;
    let colStart = 1;

    // add column headers
    {
        const xrow = sheet1.getRow(rowPos++);
        for( let colIndex in columns ){
            const col = columns[colIndex];
            const xcell = xrow.getCell(col.key);
            xcell.value = col.label || col.key;
        }
    }


    // add rows
    for( let rowIndex in rows ){
        const row = rows[rowIndex];
        const xrow = sheet1.getRow(rowPos++);
        for( let colIndex in columns ){
            const col = columns[colIndex];
            const xcell = xrow.getCell(col.key);
            xcell.value = getProperty(row, col.key);
            xcell.numFmt = col.numFmt;
        }
    }

    const buffer = await workbook.xlsx.writeBuffer({ base64: true });
    return buffer;

}
    


export const tableToString = async (rows, columns, options) => {

    let lines = [];

    if( !columns || options.autoGenerateColumns ){
        const allowNest = true;
        columns = [];
        rows?.forEach?.(row => {
            Object.keys(row).forEach(key => {
                
                // is this an object?
                if( allowNest && row[key] && typeof(row[key]) === "object" ){
                    Object.keys(row[key]).forEach(nestedKey => {
                        const nkey = `${key}.${nestedKey}`;
                        let findCol = columns.find(col => nkey === col.key);
                        if( !findCol ){
                            columns.push({ key: nkey, nested: true, parentKey: key, childKey: nestedKey });
                        }
                    })
                }
                else{
                    let findCol = columns.find(col => key === col.key);
                    if( !findCol ){
                        columns.push({ key: key });
                    }
                }
            })
        })
    }

    // add column headers
    lines.push(columns.map(col => col.key).join("\t"));

    // add rows
    for( let rowIndex in rows ){
        const row = rows[rowIndex];
        lines.push(columns.map(col => getProperty(row, col.key)).join("\t"));
    }

    return lines.join("\r\n");

}