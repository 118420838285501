import { compileFunction } from "../../utils/Compiler";
import { getImagePath } from "../../utils/ImageUtils";

export const parseResource = (type, value, options) => {

    if (!value) return null;

    const subscription_id = options.subscription_id;
    const project_id = options.project_id;
    const dispatch = options.dispatch;
    const id = options.id;
    const auth = options.auth;

    if (type === "palette") {
        return {
            label: value?.label,
            swatches: value?.swatches,
            swatch: value?.swatches?.reduce((a, e) => {
                if (e.name) {
                    a[e.name] = e;
                }
                return a;
            }, {})
        }
    }
    // else if (type === "xmodel") {
    //     return {
    //         id: value.id,
    //         compute: (query) => modelComputeApi.compute(
    //             auth,
    //             subscription_id,
    //             project_id,
    //             value.id, // model id?
    //             query
    //         ),
    //         yak: (query) => modelComputeApi.computeYak(
    //             auth,
    //             subscription_id,
    //             project_id,
    //             value.id,
    //             query
    //         )
    //     }
    // }
    else if (type === "model") {
        let ret = {
            ...value,
            run: compileFunction(value.jdata?.syntax)
        }
        delete ret.jdata;
        delete ret.xtran; // just leftover, right? should remove this.
        return ret;
    }
    else if (type === "config") {
        return {
            id: value.id,
            atts: value.atts,
            baseCase: value.baseCase
        }
    }
    else if (type === "selection") {
        return {
            value,
            setValue: (x) => {
                dispatch({
                    type: "SELECTION_STATE_SET",
                    id,
                    value: x
                })
            }
        }
    }
    // else if( type === "data-source"){
    //     let retVal = {
    //         ...value,
    //     };
    //     if(value?.type === "file" && value?.fileType === "json" && !value.structured){
    //         retVal.load = () => { return "todo" };
    //     }
    //     return retVal;
    // }
    else if( type === "data-source-revision" ){
        let retVal = { ...value };
        if( value?.udata ){
            try{
                retVal.value = JSON.parse(value.udata);
                delete retVal.udata;
            }
            catch{

            }
        }
        return retVal;
    }
    else if (type === "image"){
        return {
            ...value,
            path: getImagePath(subscription_id, project_id, value.filename)
        }
    }
    else if (type === "collection"){
        return value;

        // todo: attach image paths somehow
        // return {
        //     ...value,
        //     getImagePath: () => 
        //     entries: value?.entries?.map(e => {
        //         if( e.imageId ){
        //             return { ...e, imagePath: `${globals.blobUrl}client1-images/${subscription_id}/${project_id}/${value.filename}` }
        //         }
        //         else return e;
        //     })
        // }
    }
    else {
        // parsing not implemented for this type, maybe nothing to do
        return value;
    }
}