import { createContext } from "react";
import { useState } from "react";
import { useContext } from "react";
import { PipelineEditorContext, PipelineStageEditorContext } from "../../../../context/Contexts";
import { useAuth } from "../../../../hooks/useAuth";
import { useProjectId } from "../../../../hooks/useProjectId";
import { InspectStages } from "../../../../utils/stageInspection";
import "./StagesInspectButton.scss";

export const InspectionContext = createContext();

export const StagesInspectButton = (props) => {

    const pipelineContext = useContext(PipelineEditorContext);
    const stageContext = useContext(PipelineStageEditorContext);
    const inspectionContext = useContext(InspectionContext);

    const stage = stageContext.stage;
    const priorStages = stageContext.priorStages;

    const auth = useAuth();
    const { subscription_id, project_id } = useProjectId();
    const env = {}; // todo: resources, context
    
    const ds = pipelineContext.dataSource;
    const rev = pipelineContext.revision;

    //const [inspection, setInspection] = useState(null);
    const inspection = inspectionContext?.inspection;
    const setInspection = inspectionContext?.setInspection;
    const inspecting = inspectionContext?.inspecting;
    const setInspecting = inspectionContext?.setInspecting;
    const [internalInspecting, setInternalInspecting] = useState(false);
    const anyInspecting = internalInspecting || inspecting;

    const inspect = async (limit, inspectionLevels) => {
        if( anyInspecting ) return;
        
        setInternalInspecting(true);
        setInspecting?.(true);
        try{
            const i = await InspectStages(auth, env, subscription_id, project_id, ds.id, rev.id, priorStages, limit, inspectionLevels);
            setInspection(i);
        }
        catch(err){
            console.error(err);
        }
        setInternalInspecting(false);
        setInspecting?.(false);

    };

    // const inspectParial = async () => {
    //     await inspect(1000);
    // }

    // const inspectFull = async () => {
    //     await inspect(undefined);   
    // }

    const uninspect = () => {
        setInspection(null);
    }

    return <div className="stages-inspect-button">
        {true && <span className="spinner" title="Inspection status">
            {anyInspecting ? <i className="fa-solid fa-circle-notch fa-spin" />
                : inspection ? <i className="fad fa-microscope" />
                : <i className="fal fa-microscope" />}
        </span>}
        <button
            disabled={anyInspecting}
            onClick={() => inspect(1000, 2)}
            title="Inspect top 1000 records, 2 levels deep"
        >
            Inspect (1k 2d)
        </button>
        <button
            disabled={anyInspecting}
            onClick={() => inspect(1000, 1)}
            title="Inspect top 100 records, 1 level deep"
        >
            (1d)
        </button>
        <button
            onClick={() => inspect(undefined, 2)}
            disabled={anyInspecting}
            title="Inspect all records, 2 levels deep"
        >
            [full 2d]
        </button>
        <button
            disabled={anyInspecting}
            onClick={uninspect}
            title="Clear inspection"
        >
            x
        </button>
        
    </div>
}