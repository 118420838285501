import { useState } from "react";
import { useParams } from "react-router-dom";
import { useImages } from "../../hooks/useImage";
import { ModalFrame } from "../modal-dialog/ModalFrame";
import { debounce } from "../../utils/debounce";
import "./ImagePicker.scss";

const saveSearchText = debounce((text, setA) => {
    console.log('saveSearchText', text)
    setA(text);
}, 1000);


export const ImagePicker = (props) => {

    const routerParams = useParams();
    const project_id = routerParams.project_id;

    const [images, imagesLoading, imagesError] = useImages();
    const [searchText, setSearchText] = useState("");
    const [sloppyFixMe, setSloppyFixMe] = useState(null);

    const sortedImages = images ? [...images]?.sort((a, b) => a.label?.localeCompare(b.label)) : null;


    const filteredImages = sloppyFixMe ? sortedImages.filter(img => {
        return img.label?.toLowerCase()?.includes(sloppyFixMe?.toLowerCase())
    }) : sortedImages;

    return <ModalFrame title="Select Image"
        close={props.close}
    >
        <div className='image-picker'>

            <div className='search-section'>
                <input type='text' className='tb_search' value={searchText} placeholder="search" onChange={(ev) => {
                    setSearchText(ev.target.value)
                    saveSearchText(ev.target.value, setSloppyFixMe);
                }} />
                <span className='cancel-btn' onClick={() => {
                    setSearchText("")
                    saveSearchText("", setSloppyFixMe);
                }}>
                    <i className='fal fa-times'/>
                </span>
            </div>

            A = {sloppyFixMe}
            <div className='image-list'>
                {filteredImages?.map(img => <div key={img.id} className='image-entry' onClick={() => {
                    // select image
                    props.onSelect?.(img);
                }}>
                    {img.label}
                </div>)}
            </div>
            
        </div>

        
    </ModalFrame>
}