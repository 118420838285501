import { aDelete, postJson } from "../api/restMethods";
import * as globals from "../globals";
import { useAuth } from "./useAuth";


export const useTeamsApi = () => {
    
    const auth = useAuth();

    const now = new Date();
    const offset = 0 - now.getTimezoneOffset();

    return {
        addUser: async (team_id, user_id) => {
            const res = await postJson(auth, `${globals.apiRoot}/team/${team_id}/users`, { user_id });
            return res;
        },

        removeUser: async (team_id, user_id) => {
            const res = await aDelete(auth, `${globals.apiRoot}/team/${team_id}/user/${user_id}`);
            return res;
        }
    }
}
