

import * as React from 'react';
import * as d3 from 'd3';
import { useEffect } from 'react';
import { useState } from 'react';
import { getProperty } from 'dot-prop';
import { useRecoilValue } from 'recoil';
import { computeContextState, ComputeRunnerContext } from "./ComputeContext";

const arcVisible = (d) => {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
}

const labelVisible = (d) => {
    return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
}

const width = 900
const radius = width / 6;

const labelTransform = (d) => {
    const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
    const y = (d.y0 + d.y1) / 2 * radius;
    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
}

export const ZSunburst2 = (props) => {

    const item = props.item;
    const style = { margin: 0, ...props.style, ...item?.style }
    const data = props.data;

    // const cHook = item?.jdata?.contextHooks?.[0];
    // const ccState = useRecoilValue(computeContextState(cHook?.id));
    // const result = ccState?.result;
    // const data = result?.data || result?.rows || result;

    return <div style={style} className={`${props.displayClass}`} {...props.atts}>
        {data ? 
            <Sunburst data={data} />
            : "no data"
        }
    </div>
    
}


export const ZSunburst = (props) => {

    const item = props.item;
    const style = { margin: 0, ...props.style, ...item?.style }

    const cHook = item?.jdata?.contextHooks?.[0];
    const ccState = useRecoilValue(computeContextState(cHook?.id));
    const result = ccState?.result;
    const data = result?.data || result?.rows || result;

    return <div style={style} className={`${props.displayClass}`} {...props.atts}>
        {data ? 
            <Sunburst data={data} />
            : "no data"
        }
    </div>
    
}

const shorten = (text) => {
    if( text?.length > 20 ){
        return text.substring(0, 20) + "...";
    }
    return text;
}


export class Sunburst extends React.Component {

    constructor(props) {
        super(props);
        this.createChart = this.createChart.bind(this);
    }

    node;

    componentDidMount() {
        this.createChart();
    }

    componentDidUpdate() {
        this.createChart();
    }

    formatDataLabel(val) {
        if (val == null) return '.';
        //return this.props.valueFormat || "hi";
        if (this.props.valueFormat === '0%') {
            return (val * 100.0).toFixed(0) + '%';
        } else 
            return val.toFixed(0);
    }


    arc = d3.arc()
        .startAngle(d => d.x0)
        .endAngle(d => d.x1)
        .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
        //.padAngle(d => 10)//Math.min((d.x1 - d.x0) / 2, 5))
        .padRadius(radius * 1.5)
        .innerRadius(d => d.y0 * radius)
        .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1));

    width = width
    radius = radius; //this.width / 6

    root = null;
    parent = null;
    g = null;
    path = null;
    label = null;
    title = null;


    createChart = async () => {

        const node = this.node;
        const width = this.width;
        
        const format = d3.format(",d")
        const data = this.props.data;// || await d3.json("https://raw.githubusercontent.com/d3/d3-hierarchy/v1.1.8/test/data/flare.json")
        //const data = await d3.json("https://raw.githubusercontent.com/d3/d3-hierarchy/v1.1.8/test/data/flare.json")
        
        //const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1))

        
        const interpolator1 = d3.scaleLinear()
            .domain([
                0,
                0.2,
                0.4,
                0.6,
                1
            ])
            .range([
                "#81AFB8",
                "#1C6D7D",
                "#E6EFF1",
                "#EF7B26",
                "#D9D9D9",
            ]);
        const color = d3.scaleOrdinal(d3.quantize(interpolator1, (data.children?.length || 0) + 1))
    
        const fg_interpolator = d3.scaleLinear()
        .domain([
            0,
            0.2,
            0.4,
            0.6,
            1.0
        ])
        .range([
            '#000000',
            '#000000',
            '#000000',
            '#000000',
            '#cfcfcf',
        ]);
    
        const fg_color = d3.scaleOrdinal(d3.quantize(fg_interpolator, (data.children?.length || 0) + 1))



        this.root = this.partition(data);
        const root = this.root;
        root.each(d => d.current = d);

        const svg = d3.select(node) //DOM.svg(width, width))
            .style("width", "700")
            .style("height", "auto")
            .style("font", "10px sans-serif");

        svg.selectAll("*").remove();

        svg
            .style("width", "700")
            .style("height", "auto")
            .style("font", "10px sans-serif");
        

        this.g = svg.append("g")
            .attr("transform", `translate(${width / 2},${width / 2})`);
        const g = this.g;
      
        this.path = g.append("g")
          .selectAll("path")
          .data(root.descendants().slice(1))
          .join("path")
            //.attr("fill", d => { while (d.depth > 1) d = d.parent; return color(d.data.name); })
            //.attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)

            .attr("fill", d => { while (d.depth > 1) d = d.parent; return color(d.data.name); })
            //.attr("fill", d => { return colors[(this.getLevel(d) - 1) % colors.length]; })
            .attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 1.0 : 0.8) : 0)
            .attr("d", d => this.arc(d.current));
      
        this.path.filter(d => d.children)
            .style("cursor", "pointer")
            .on("click", this.clicked);
      
        this.path.append("title")
            //.text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${format(d.value)}`);
            //.text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${d.data.valueLabel}`);
            .text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}`);
      
        this.label = g.append("g")
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .attr('font-size', 14)
            .style("user-select", "none")
            .selectAll("text")
            .data(root.descendants().slice(1))
            .join("text")
            .attr("dy", "0.35em")
            .attr("fill-opacity", d => +labelVisible(d.current))
            .attr("transform", d => labelTransform(d.current))
            .attr("fill", d => { while (d.depth > 1) d = d.parent; return fg_color(d.data.name); })
            .text(d => shorten(d.data.name));
            //.text(d => d.data.name);
      
        
        this.title = g.append("text")
            .attr("x", 0)
            .attr("y", -25)
            .attr('text-anchor', 'middle')
            .attr('font-size', 16)
            .attr("style", "fill: #000000")
            .text(data.name)

        this.subTitle = g.append("text")
            .attr("x", 0)
            .attr("y", 0)
            .attr('text-anchor', 'middle')
            .attr('font-size', 16)
            .attr("style", "fill: #000000")
            .text(data.subTitle)

        this.titleNote = g.append("text")
            .attr("x", 0)
            .attr("y", 25)
            .attr('text-anchor', 'middle')
            .attr('font-size', 14)
            .attr("style", "fill: #afafaf")
            .text(data.note)
            .attr("data-hideexport", 1)
        
        this.parent = g.append("circle")
            .datum(root)
            .attr("r", this.radius)
            .attr("fill", "none")
            .attr("pointer-events", "all")
            .on("click", this.clicked);


        
    }


    clicked = (ev, p) => {
        ev.stopPropagation();
        ev.preventDefault();
        

        this.parent.datum(p.parent || this.root);
    
        this.root.each(d => d.target = {
          x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth)
        });

        if( p.data ){
            this.title.text(p.data.name);
            this.subTitle.text(p.data.subTitle);
            this.titleNote.text(p.data.note);
        }
    
        const t = this.g.transition().duration(750);
    
        // Transition the data on all arcs, even the ones that aren’t visible,
        // so that if this transition is interrupted, entering arcs will start
        // the next transition from the desired position.
        this.path.transition(t)
            .tween("data", d => {
              const i = d3.interpolate(d.current, d.target);
              return t => d.current = i(t);
            })
          .filter(function(d) {
            return +this.getAttribute("fill-opacity") || arcVisible(d.target);
          })
            //.attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
            //.attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 1.0 : 0.4) : 0)
            .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 1.0 : 0.8) : 0)
            .attrTween("d", d => () => this.arc(d.current));
    
        this.label.filter(function(d) {
            return +this.getAttribute("fill-opacity") || labelVisible(d.target);
          }).transition(t)
            .attr("fill-opacity", d => +labelVisible(d.target))
            .attrTween("transform", d => () => labelTransform(d.current));
    }
      
    getLevel = data => {
        //return data.height;
        return data.parent ? 1 + this.getLevel(data.parent) : 1;
    }    
    
    
    partition = data => {
        const root = d3.hierarchy(data)
            .sum(d => d.value)
            .sort((a, b) => b.value - a.value);
        return d3.partition()
            .size([2 * Math.PI, root.height + 1])
          (root);
    }
    

    render() {

        const style = {
            //border: "5px dashed red",
            textAlign: "center"
        }

        return <div style={style}>
            <svg
                onClick={ev => {
                    ev.stopPropagation();
                    ev.preventDefault();
                }}
                ref={node => (this.node = node)}
                style={{
                    //width: 700, // createChart sets this
                    //height: 500, // createChart sets this
                    //backgroundColor: "#efefef"
                }}
                viewBox={'0 0 ' + width + ' ' + width}
                preserveAspectRatio="xMidYMid meet"
            />
        </div>;
    }
}
