import { ModalFrame } from "../modal-dialog/ModalFrame";
import { SketchPicker } from 'react-color';
import { useState } from "react";
import { Swatch } from "./Swatch";

export const SwatchEditor = (props) => {

    const [newName, setNewName] = useState(props.name);
    const [fg, setFg] = useState(props.fg);
    const [bg, setBg] = useState(props.bg);

    return <ModalFrame title="Edit Swatch"
        buttons={[
            {
                label: "Save",
                callback: () => {
                    const newItem = {
                        name: newName,
                        fg: fg,
                        bg: bg
                    }
                    props?.onSave(newItem);
                }
            },
            
        ]}
        close={props.close}
    >
        <div className='form1'>
            <div style={{ display: "flex", rowGap: 15, columnGap: 15, marginBottom: 15 }}>

                <div style={{ width: 130 }}>
                    <Swatch name={newName} fg={fg} bg={bg} staticHeight={true} hideDelete={true} />
                </div>

                <div style={{ flexGrow: "1" }}>
                    <label className="label-line">
                        Name (optional):
                    </label>
                    <div className="value-line">
                        <input type="text"
                            autoFocus className='full-width' 
                            placeholder="(e.g., good, bad, neutral, primary, comparison)"
                            value={newName || ""}
                            onChange={(ev) => {
                                setNewName(ev.target.value)
                            }}/>
                    </div>
                </div>

            </div>
            
            <div style={{ display: "flex", rowGap: 15, columnGap: 15 }}>

                <div>
                    <label className="label-line">
                        Fill color:
                    </label>
                    <div className="value-line">
                        <SketchPicker
                            color={bg}
                            onChangeComplete={(color) => {
                                const alphaHex = color.rgb.a < 1 ?
                                    Math.round(255 * color.rgb.a,0).toString(16).toUpperCase() :
                                    "";
                                setBg(color?.hex + alphaHex)
                            }} />
                    </div>
                </div>

                <div>
                    <label className="label-line">
                        Text color:
                    </label>
                    <div className="value-line">
                        <SketchPicker
                            color={fg}
                            onChangeComplete={(color) => {
                                const alphaHex = color.rgb.a < 1 ?
                                    Math.round(255 * color.rgb.a,0).toString(16).toUpperCase() :
                                    "";
                                setFg(color?.hex + alphaHex)
                            }} />
                    </div>
                </div>

            </div>
            
        </div>

        
    </ModalFrame>
}