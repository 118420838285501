import { useContext, useState } from "react";
import { useLocation } from "react-router";
import { AppContext, ContentItemContext, defaultAppState, ProjectIdContext, UrlRootContext } from "../context/Contexts";
//import { useGetPageIdByUrl, usePage } from "../hooks/usePage";
//import { usePageInstances } from "../hooks/usePageInstances";
import { useProject } from "../hooks/useProject";
import { useProjectId } from "../hooks/useProjectId";
import { MainMenu } from "./page-content/view/MainMenu";
//import { ViewPage } from "./page-content/view/ViewPage";
import "./PreviewLayout.scss";



export const PreviewLayout = (props) => {

    const location = useLocation();

    const subscription_id = location.pathname.split('/')[2];
    const project_id = location.pathname.split('/')[3];
    
    const pathSegments = 
        location.pathname.split('/')
        .slice(4); // eats "preview", sub-id and project-id

    const [project, projectLoading, projectError] = useProject(subscription_id, project_id);
    if( projectLoading ) return <div className="message centered">Loading project...</div>;
    if( projectError ) return <div className="message centered">Error loading project</div>;
    if( !project ) return <div className="message centered">Project not found</div>;

    const context = {
        project,
        pagePath: `/preview/${subscription_id}/${project_id}`,
        pageLabel: `[root]`,
        breadCrumb: []
    }

    return <ProjectIdContext.Provider value={{ project_id, subscription_id }}>
        <UrlRootContext.Provider value={{ url: `/preview/${subscription_id}/${project_id}` }}>
            <ContentItemContext.Provider value={context}>
                <div>
                    todo: render content
                </div>
                {/* <RenderPage pathSegments={pathSegments} /> */}
            </ContentItemContext.Provider>
        </UrlRootContext.Provider>
    </ProjectIdContext.Provider>

}

export const wrapMessage = (message) => <MainMenu>
    <div className='centered-message'>
        {message}
    </div>
</MainMenu>

// export const RenderPage = (props) => {

//     const { project_id, subscription_id } = useProjectId();

//     const parent_id = props.parent_id;
//     const context = useContext(ContentItemContext);

//     const pathSegments = props.pathSegments;
//     const page_url = pathSegments?.[0] || null; // null page_id means to use default
//     let newPathSegments = pathSegments.slice(1);

//     const [page_id, idLoading, idError] = useGetPageIdByUrl(subscription_id, project_id, parent_id, page_url);
    
//     const [page, pageLoading, pageError] = usePage(page_id);


//     // if the page has instances, grab them -- pass in context so they can compute if needed
//     const [instances, instancesLoading, instancesError] = usePageInstances(page_id);

//     if( idLoading ) return wrapMessage("Loading page...");
//     if( idError ) return wrapMessage("Error loading page id");

//     if( pageLoading ) return wrapMessage("Loading page...");
//     if( pageError ) return wrapMessage("Error loading page {page_id}")
//     if( !page ) return wrapMessage("Page not found");

//     if( instancesLoading ) return wrapMessage("Loading instances...");
//     if( instancesError ) return wrapMessage(`Error loading instances for page ${page_id}`);

//     let newContext = {
//         ...context
//     };
//     newContext.pagePath = `${newContext.pagePath}/${page_url}`;
//     newContext.pageLabel = page?.label;
    

//     const hasInstances = instances?.length > 0;
//     if( hasInstances ){
//         const instanceId = newPathSegments[0];
//         newContext.pagePath = `${newContext.pagePath}/${instanceId}`;
//         newPathSegments = newPathSegments.slice(1);
//         const instance = instances?.find(inst => inst.id === instanceId) || instances?.[0];
//         newContext[page.url] = instance;
//         newContext.pageLabel = page?.label + " (" + instance?.label + ")";
//     }

//     newContext.breadCrumb = [
//         ...(context.breadCrumb || []),
//         { path: newContext.pagePath, label: newContext.pageLabel }
//     ];

//     if( newPathSegments.length > 0 ){
//         return <ContentItemContext.Provider value={newContext}>
//             <RenderPage pathSegments={newPathSegments} parent_id={page_id}/>
//         </ContentItemContext.Provider>
//     }

//     return <ContentItemContext.Provider value={newContext}>
//         <div>
//             todo: view page
//         </div>
//         {/* <ViewPage
//             page={page}
//             edit={false}
//             level={1}
//         /> */}
//     </ContentItemContext.Provider>;
    
    
    
// }