import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { ApiReducer } from "./ApiReducer";
import { CollectionsReducer } from "./CollectionsReducer";
import { ImagesReducer } from "./ImagesReducer";
import { PagesReducer } from "./PagesReducer";
import { ContentItemsReducer } from "./ContentItemsReducer";
import { PalettesReducer } from "./PalettesReducer";
import { ProjectsReducer } from "./ProjectsReducer";
import { SubscriptionsReducer } from "./SubscriptionsReducer";
import { DataSourcesReducer } from "./DataSourcesReducer";
import { ConfigsReducer } from "./ConfigsReducer";
import { ModelsReducer } from "./ModelsReducer";
import { ConfigStateReducer } from "./ConfigStateReducer";
import { SelectionStateReducer } from "./SelectionStateReducer";
import { SelectionsReducer } from "./SelectionsReducer";
import { FilterBooksReducer } from "./FilterBooksReducer";
import { UIReducer } from "./UIReducer";
import { DataSourceRevisionsReducer } from "./DataSourceRevisionsReducer";
import { PipelinesReducer } from "./PipelinesReducer";


const combinedReducers = combineReducers({
    ui: UIReducer,
    api: ApiReducer,
    subscriptions: SubscriptionsReducer,
    projects: ProjectsReducer,
    palettes: PalettesReducer,
    pipelines: PipelinesReducer,
    collections: CollectionsReducer,
    images: ImagesReducer,
    pages: PagesReducer,
    contentItems: ContentItemsReducer,
    dataSources: DataSourcesReducer,
    dataSourceRevisions: DataSourceRevisionsReducer,
    configs: ConfigsReducer,
    models: ModelsReducer,
    configState: ConfigStateReducer,
    selections: SelectionsReducer,
    selectionState: SelectionStateReducer,
    filterBooks: FilterBooksReducer
})

export default configureStore({
    reducer: combinedReducers
})


