import { useContext, useState } from "react";
import Popup from "reactjs-popup";
import { PipelineStageEditorContext } from "../../../../context/Contexts";
import { debounce } from "../../../../utils/debounce";
import { TextFrame } from "../../../modal-dialog/VerifyFrame";
import "./EditSkipStage.scss";

export const EditSkipStage = (props) => {

    const stageContext = useContext(PipelineStageEditorContext);
    const stage = stageContext.stage;
    const [n, setN] = useState(stage.data?.$skip);

    const save = (val) => {
        try{
            const i = parseInt(val);
            stageContext.replaceStageData({ $skip: i });
        }
        catch{}
    }

    return <div className="edit-skip-stage">
        {/* <p>
            Limits the number of records that flow into subsequent stages.
        </p> */}
        Skip: 
        &nbsp;
        <Popup
            modal
            keepTooltipInside
            trigger={
                <span className="value-box">
                    {n}
                </span>        
            }
        >
            {closeModal => <TextFrame
                title={"Edit value"}
                label={`Skip:`}
                initialTextValue={`${n}`}
                close={closeModal} confirmed={async (text) => {
                    try{
                        const newVal = parseInt(text);
                        setN(newVal);
                        save(newVal);
                    }
                    catch(err){
                        alert(err.message);
                    }
                    closeModal();
                }}
            />}
        </Popup>
        
        &nbsp; record(s).
    </div>
    
}