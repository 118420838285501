import { useContext, useEffect, useState } from "react";
import { useQuery } from "../../hooks/useQuery";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { GetToken_Password } from "../../api/oauth";
import * as restMethods from "../../api/restMethods";
import { AuthContext } from "../../context/Contexts";
import * as globals from "../../globals";
import "./Login.scss";
import "../MainLayout.scss";
import "../nav/LeftMenu.scss";
import { AuthOuter } from "./AuthOuter";
import ReCAPTCHA from "react-google-recaptcha";
import Popup from "reactjs-popup";

const translateError = (msg) => {
    switch (msg) {
        case "Failed to fetch":
            return "There is a problem connecting to the server. Please check your internet connection or try again later.";
        case "Invalid grant: user credentials are invalid":
            return "User credentials are invalid or expired.";
        default:
            return msg;
    }
}


export const passwordRulesCheck = (newPassword) => {

    newPassword = [...(newPassword || "")]; // convert to char array

    const specialChars = "!@#$%^&*()-=_+[]{};:'\"\\|,.<>/?`~";
    const upperCaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerCaseLetters = "abcdefghijklmnopqrstuvwxyz";
    const digits = "0123456789";

    let result = {
        longEnough: newPassword?.length >= 10,
        //hasSpecialChar: newPassword?.filter(ch => specialChars.includes(ch))?.length ? true : false,
        hasDigit: newPassword?.filter(ch => digits.includes(ch))?.length ? true : false,
        hasUpper: newPassword?.filter(ch => upperCaseLetters.includes(ch))?.length ? true : false,
        hasLower: newPassword?.filter(ch => lowerCaseLetters.includes(ch))?.length ? true : false,
    }
    //result.pass = result.longEnough && result.hasSpecialChar && result.hasDigit && result.hasUpper && result.hasLower;
    result.pass = result.longEnough && result.hasDigit && result.hasUpper && result.hasLower;
    result.fail = !result.pass;
    return result;
}

const STEP_INPUT = 1;
const STEP_RESULT = 2;

export const SignUp = (props) => {

    const query = useQuery();
    const history = useHistory();

    const [email, setEmail] = useState(null);
    const [name, setName] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [company, setCompany] = useState(null);
    const [token, setToken] = useState(null);
    const [companyWebsite, setCompanyWebsite] = useState(null);
    const [companyTelephone, setCompanyTelephone] = useState(null);

    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);
    const authContext = useContext(AuthContext);

    const [step, setStep] = useState(STEP_INPUT);

    
    const submitRequest = async (body) => {

        console.log('submitRequest', body);
        let success = false;

        setError(false);
        setFetching(true);
        try {
            const res1 = await restMethods.postJson(null, `${globals.apiRoot}/auth/sign-up-request`, body);
            console.log('res1', res1);
            if (res1.status === 200) {
                success = true;
            }
            else {
                setError("Request failed. Please try again later.")
            }
        }
        catch (err) {
            setError(err.message);
        }
        setFetching(false);

        if (success) {
            setStep(STEP_RESULT);
        }
        
    }

    const onTokenChange = (t) => {
        setToken(t);
    }

    let passProps = {};
    passProps.showBackLink = false;

    // new password rule check
    const newPasswordRuleCheck = passwordRulesCheck(newPassword);
    console.log("newPasswordRuleCheck", newPasswordRuleCheck);
    

    return <AuthOuter title="Create an Account" {...passProps}>

        {error ? <div className="error">
            <i className="fas fa-warning" /><br/>
            {translateError(error)}
        </div> : null}

        {step === STEP_INPUT ? <>
            <div className="entry">
                <div className="label">
                    Email:
                </div>
                <input id="email" type="text" placeholder="email" value={email || ""} onChange={(ev) => setEmail(ev.target.value)} />
            </div>
            <div className="entry">
                <div className="label">
                    Name: (first last)
                </div>
                <input id="name" type="text" placeholder="Name" value={name || ""} onChange={(ev) => setName(ev.target.value)} />
            </div>
            <div className="entry">
                <div className="label">
                    Create password
                    <div className="new-password-note">
                        Your password must contain at least 10 characters. It must
                        include a special character, a number, and an upper and lowercase character.
                    </div>
                </div>
                <div className="password-row">
                    {showPassword ?
                        <input id="name" type="text" placeholder="Password" value={newPassword || ""} 
                            onChange={(ev) => {
                                setNewPassword(ev.target.value)
                            }} 
                        />
                    :
                        <input id="name" type="password" placeholder="Password" value={newPassword || ""} 
                            onChange={(ev) => {
                                setNewPassword(ev.target.value)
                            }} 
                        />
                    }
                    <span className="eyeball"
                        onClick={ev => setShowPassword(!showPassword)}
                    >
                        <i className={showPassword ? "far fa-eye" : "far fa-eye-slash"} />
                    </span>
                </div>
                
                {newPassword?.length ? 
                    <div className={`new-password-rule-check ${newPasswordRuleCheck.pass ? "pass" : "fail"}`}>
                        <i className={newPasswordRuleCheck.pass ? "fas fa-check-circle icon" : "fas fa-warning icon"} />
                        {
                            //!newPasswordRuleCheck.hasSpecialChar ? "Password must contain a special character" :
                            !newPasswordRuleCheck.hasDigit ? "Password must contain a digit" :
                            !newPasswordRuleCheck.hasUpper ? "Password must contain an uppercase letter" :
                            !newPasswordRuleCheck.hasLower ? "Password must contain a lowercase letter" :
                            !newPasswordRuleCheck.longEnough ? "Password isn't at least 10 characters" :
                            "Password is okay"
                        }
                    </div>
                    : null
                }
            </div>
            <div className="entry">
                <div className="label">
                    Company Name:
                </div>
                <input id="company" type="text" placeholder="Company Name, Inc." value={company || ""} onChange={(ev) => setCompany(ev.target.value)} />
            </div>
            <div className="entry">
                <div className="label">
                    Website:
                </div>
                <input id="website" type="text" placeholder="www.my-company.com" value={companyWebsite || ""} onChange={(ev) => setCompanyWebsite(ev.target.value)} />
            </div>
            <div className="entry">
                <div className="label">
                    Company telephone:
                </div>
                <input id="tel" type="text" placeholder="+1 (000) 000-0000" value={companyTelephone || ""} onChange={(ev) => setCompanyTelephone(ev.target.value)} />
            </div>
            <div className="entry">
                <ReCAPTCHA
                    sitekey="6LdOtTkUAAAAAM3Wr_s30GcEXrXcRKjXlr6AU_9P"
                    onChange={onTokenChange}
                />
            </div>
            {error ? <div className="error">
                <i className="fas fa-warning" /><br/>
                {translateError(error)}
            </div> : null}
        </>
        : null
        }

        {step === STEP_RESULT ? <>
            <div className="entry">
                <div className="label">
                    <div>
                        <i className="far fa-circle-check" style={{ fontSize: 24 }}/>
                    </div>
                    <p>
                        Thank you. Your request has been sent to an account administrator for approval.
                    </p>
                    <div>
                        <i className="fal fa-envelope" style={{ fontSize: 24 }}/>
                    </div>
                    <p>
                        Please watch your email inbox for a welcome email from support@metricstudios.com.
                    </p>
                </div>
            </div>
        </>
        : null
        }

        {fetching ? <div>
            Please wait...
        </div> : null}

        <div className="submit-section">
            
            <button
                type="submit"
                className="login"
                disabled={fetching || !token}
                
                onClick={async (ev) => {
                ev.stopPropagation();
                ev.preventDefault();
                if (step === STEP_INPUT) {

                    if (!email) {
                        setError("Please provide an email address");
                    }
                    else if (!name) {
                        setError("Please provide your name");
                    }
                    else if (!newPasswordRuleCheck.pass){
                        setError("Password does not meet requirements");
                    }
                    else if (!company) {
                        setError("Please provide company name");
                    }
                    else if (!token) {
                        setError("Please complete the reCaptcha");
                    }
                    else {
                        setError(null);
                        submitRequest({ email, name, newPassword, company, companyWebsite, companyTelephone });
                    }
                }
                else if (step === STEP_RESULT) {
                    history.push("/");
                }
                
            }}>
                {step === STEP_INPUT ? "Submit" : null}
                {step === STEP_RESULT ? "OK" : null}
            </button>

        </div>

    </AuthOuter>



}