import { useContext, useState } from "react";
import { ContentItemContext, PageContext } from "../../../context/Contexts";
import { useContentItem } from "../../../hooks/useContentItem";
import { useContentItemsApi } from "../../../hooks/useContentItems";
import { useResources } from "../../../hooks/useResources";
import { ContentValueInput } from "../../edit/ContentValueInput";
import { DataBindButton } from "../../edit/DataBindButton";
import { InstancesInput } from "../../instances/InstancesInput";
import { EditBackground } from "./EditBackground";
import { EditBorder } from "./EditBorder";
import { EditResources } from "./EditResources";
import { EditSyntax } from "./EditSyntax";

const TABS = [
    { key: "content", label: "Content" },
    { key: "style", label: "Border" },
    { key: "background", label: "Background" },
]

export const EditReactCardProperties = (props) => {

    const [selTab, setSelTab] = useState("content");
    const api = useContentItemsApi();
    const page = useContext(PageContext);
    

    const id = props.content_item_id;
    const [item, itemLoading, itemError] = useContentItem(page?.id, id);

    //const item = props.content_item;
    

    const [resources, resourcesLoading, resourcesError] = useResources(item?.resources, true);

    
    if (!item) return null;

    const updateItem = async (newItem) => {
        console.log('updateItem', newItem);
        await api.updateItem(item.id, newItem, { page_id: page.id });
    }

    const rowPad = 10;

    return <div>

        <div className='h-dock' style={{ marginBottom: rowPad }}>
                    
            <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                Instances
            </span>
            <span className='center'>
                <InstancesInput
                    style={{ width: "100%" }}
                    value={item.instances}
                    onChange={async (newInstances) => {
                        console.log('instancesInput changed', newInstances)
                        const newItem = {
                            ...item,
                            instances: newInstances
                        };
                        await updateItem(newItem);
                    }}
                />
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton/>
            </span>
        </div>

        <div className='h-dock' style={{ marginBottom: rowPad }}>
            
            <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                Visible
            </span>
            <span className='center v-center'>
                <input type="checkbox" />
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton/>
            </span>
        </div>

        <div className="tab-section reset-margin">
            {TABS.map(tab =>
                <div
                    key={tab.key}
                    onClick={() => setSelTab(tab.key)}
                    className={`tab ${selTab === tab.key ? "sel" : ""}`}
                >
                    {tab.label}
                </div>
            )}
        </div>

        {selTab === "content" ? <>
            <EditResources value={item.resources} onChange={async (newList) => {
                const newItem = {
                    ...item,
                    resources: newList
                };
                await updateItem(newItem); //api.updateItem(item.id, newItem, { page_id: page.id });
            }} />

            <div style={{ marginBottom: 10 }}>
                Render:
            </div>
            <div style={{ marginBottom: 20 }}>
                <EditSyntax
                    title="Render Syntax"
                    value={item.render}
                    resources={resources}
                    placeholder={"[render syntax]"}
                    onChange={
                        async (newSyntax) => {
                            const newItem = {
                                ...item,
                                render: newSyntax
                            }
                            await updateItem(newItem);
                        }
                    }
                />
            </div>

            {/* <ContentValueInput
                value={item.content}
                context={context}
                onChange={async (newObj) => {
                    const newItem = {
                        ...item,
                        content: newObj
                    }
                    //await api.updateItem(item.id, newItem, { page_id: page.id });
                    await updateItem(newItem);
                }} /> */}

        </>
            : null
        }

        {selTab === "style" ?
                    <EditBorder
                        value={item.border}
                        onChange={async (val) => {
                            const newItem = {
                                ...item,
                                border: val
                            };
                            await updateItem(newItem);
                        }}
                    />
                    : null}

                {selTab === "background" ?
                    <EditBackground
                        value={item.background}
                        onChange={async (val) => {
                            const newItem = {
                                ...item,
                                background: val
                            }
                            await updateItem(newItem);
                        }}
                    />
                    : null}

    </div>
}