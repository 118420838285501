import React from "react";
import { EditContentItemWrapper } from "../../edit/EditContentItemWrapper";
import { ErrorBoundary } from "../../error-boundary/ErrorBoundary";
import "./ViewUnknownWidget.scss";





export const ViewUnknownWidget = (props) => {

    const item = props.content_item;
    let text = "Unknown widget";
    try {
        text = JSON.stringify(item, null, 2);
    }
    catch(err) {
    }

    return <EditContentItemWrapper {...props}>
        <div className='view-unknown-widget'>
            <div>
                Unknown widget:
            </div>
            <ErrorBoundary>{text}</ErrorBoundary>
        </div>
    </EditContentItemWrapper>;
        
}


