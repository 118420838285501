import React, { useEffect, useState } from "react";
import "./LoadPipelineWin.scss";
import { useProjectId } from "../../hooks/useProjectId";
import { useSavedPipelines, useSavedPipelinesApi } from "../../hooks/usePipeline";
import { controllers } from "../../api/controllers";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import moment from "moment/moment";

export const LoadPipelineWinStyle = {
    width: 800,
    maxWidth: "100%",
    height: 500,
    maxHeight: "100%"
}

const controller = controllers["pipeline"];

export const LoadPipelineWin = (props) => {

    const { subscription_id, project_id } = useProjectId();
    const [file, setFile] = useState(null);
    const [pipelines, pipelines_loading, pipelines_error] = useSavedPipelines();
    const api = useSavedPipelinesApi();
    const auth = useAuth();

    const arr = Array.isArray(pipelines) ? pipelines : null;

    const close = () => {
        props?.close();
    }

    const openPipeline = () => {

        //const file = arr.find(entry => entry.label === filename);
        const id = file.id;
        console.log("file", file);

        toast.promise(
            async () => {
                props.close?.();
                const data = await controller.auth_getItem(auth, { subscription_id, project_id }, id);
                console.log("data", data);
                props.open(data, file?.label);
            },
            {
                pending: "Loading...",
                error: "Error loading pipeline",
                success: null
            },
            {
                //autoClose: 1000
            }
        );
        
    }

    const deletePipeline = (id) => {
        toast.promise(
            async () => {
                await controller.auth_deleteItem(auth, { subscription_id, project_id }, id);
            },
            {
                pending: "Deleting...",
                error: "Error deleting pipeline",
                success: null
            },
            {
                //autoClose: 1000
            }
        );

    }
    
    

    return <div className="load-pipeline-win">
        <div className="header">
            <div className="title">
                Open Pipeline
            </div>
        </div>
        <div className="main">

            <div className="list">
                <table className="table-filenames">
                    <thead>
                        <tr>
                            <th className="label">Label</th>
                            <th className="modified">Last modified</th>
                            <th className="actions"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {arr?.map(el =>
                            <tr key={el.id} className={`item ${el.id === file?.id ? "sel" : ""}`} onClick={ev => setFile(el)}>
                                {/* <td>{el.id}</td> */}
                                <td className="label">{el.label}</td>
                                <td className="modified">{moment.utc(el.modified).local().fromNow()}</td>
                                <td className="actions">
                                    <span className="btn1" onClick={ev => {
                                        deletePipeline(el.id);
                                        ev.stopPropagation();
                                        ev.preventDefault();
                                    }}>
                                        <i className="fal fa-times"/>
                                    </span>
                                </td>
                            </tr>
                        )}                        
                    </tbody>
                
                </table>
            </div>

            <div className="filename">
                <input 
                    type="text" 
                    className="tb-filename"
                    readOnly
                    disabled
                    value={file?.label || ""}
                />

            </div>

        </div>
        <div className="footer">
            <span className="btn toggle" onClick={ev => openPipeline()}>
                Open
            </span>

            <span className="btn toggle" onClick={() => close()}>
                Cancel
            </span>
        </div>
    </div>
}