import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useImage } from "../../hooks/useImage";
import { ModalFrame } from "../modal-dialog/ModalFrame";
import * as globals from "../../globals";
import Popup from "reactjs-popup";
import { ImagePicker } from "../images/ImagePicker";
import { ControlledCodeEditorFillContainer } from "../code-editor/CodeEditor";
import ReactJson from "react-json-view";
import { getImagePath } from "../../utils/ImageUtils";
import JSON5 from "json5";

const TABS = [
    { key: "form", label: "As Form" },
    { key: "json", label: "As Json" }
]

const getExtraProps = (item) => {
    const extraProps = { ...item };
    delete extraProps.id;
    delete extraProps.label;
    delete extraProps.description;
    delete extraProps.imageId;
    return extraProps;
}

const tryParse = (str) => {
    try {

    }
    catch (err) {
        
    }
}

const toPrettyJson = (obj) => JSON.stringify(obj, null, 2);

const nullifyEmptyStr = (str) => str ? str : null;

export const CollectionEntryEditor = (props) => {

    const routerParams = useParams();
    const subscription_id = routerParams.subscription_id;
    const project_id = routerParams.project_id;

    const [item, setItem] = useState({ ...props.item });
    const [image, imageLoading, imageError] = useImage(item?.imageId);

    const [selTab, setSelTab] = useState(TABS[0].key);
    const [parseError, setParseError] = useState(null);
    const [code, setCode] = useState(toPrettyJson(item));
    
    useEffect(() => {
        setItem({ ...props.item });
    }, [props.item])

    useEffect(() => {
        setCode(toPrettyJson(item));
    }, [item])

    const filepath = getImagePath(subscription_id, project_id, image?.filename);
    
    const imgStyle = {
        width: 230,
        height: 160,
        objectFit: "contain"
    }

    const commitCode = (newCode = code) => {
        try {
            const obj = JSON5.parse(newCode);
            setParseError(null);
            setItem(obj);
            return obj;
        }
        catch (err) {
            setParseError(err.message);
            return null;
        }
    }

    return <ModalFrame title="Edit Collection Entry"
        buttons={[
            {
                label: "Save",
                callback: () => {
                    if (selTab === "json") {
                        const saved = commitCode(code);
                        if (saved) {
                            props?.onSave(saved);
                        }
                    }
                    else {
                        props?.onSave(item);
                    }
                }
            },
        ]}
        close={props.close}
    >
        <div className='form1'>

            <div className="tab-section" style={{ margin: "5px 0 10px 0" }}>
                {TABS.map(tab => <span key={tab.key} className={`tab ${selTab === tab.key ? "sel" : ""}`} onClick={() => {
                    if (selTab === "json") {
                        const saved = commitCode(code);
                        if (saved) {
                            setSelTab(tab.key);    
                        }
                    }
                    else {
                        setSelTab(tab.key);
                    }
                }}>
                    {tab.label}
                </span>)}
                
            </div>

            {selTab === "form" ?

                <>
                    <div style={{ display: "flex", rowGap: 15, columnGap: 15, marginBottom: 15 }}>

                        <div style={{ width: 250 }}>

                            <div style={{ flexGrow: "1" }}>
                                <label className="label-line">
                                    <span className='code-font bold'>.imageId</span> (click to change)
                                </label>
                                <Popup modal nested trigger={<div className="value-line" style={{
                                    border: "1px solid #cfcfcf",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: 180
                                }}>
                                    {image ? <img src={filepath} style={imgStyle} /> : "..."}
                                </div>}>
                                    {close => <ImagePicker close={close} onSelect={(img) => {
                                        setItem({
                                            ...item,
                                            imageId: img?.id
                                        });
                                        close();
                                    }} />}
                                </Popup>
                                
                            </div>

                        </div>

                        <div style={{ width: 350 }}>

                            <div >
                                <label className="label-line">
                                    <span className='code-font bold'>.label</span>:
                                </label>
                                <div className="value-line">
                                    <input type="text"
                                        autoFocus className='full-width'
                                        placeholder="(e.g., good, bad, neutral, primary, comparison)"
                                        value={item?.label || ""}
                                        onChange={(ev) => {
                                            setItem({
                                                ...item,
                                                label: nullifyEmptyStr(ev.target.value)
                                            })
                                        }} />
                                </div>
                            </div>

                            <div >
                                <label className="label-line">
                                    <span className="code-font bold">.id</span>: (unique string identifier)
                                </label>
                                <div className="value-line">
                                    <input type="text"
                                        className='full-width'
                                        value={item?.id || ""}
                                        onChange={(ev) => {
                                            setItem({
                                                ...item,
                                                id: nullifyEmptyStr(ev.target.value)
                                            })
                                        }} />
                                </div>
                            </div>

                            
                            <div >
                                <label className="label-line">
                                    <span className='code-font bold'>.description</span>: <span><i className='fal fa-question-circle' style={{ color: "#afafaf", fontSize: "9pt" }} /></span>
                                </label>
                                <div className="value-line no-margin">
                                    <input type="text"
                                        autoFocus className='full-width'
                                        placeholder=""
                                        value={item.description || ""}
                                        onChange={(ev) => {
                                            setItem({
                                                ...item,
                                                description: nullifyEmptyStr(ev.target.value)
                                            })
                                        }} />
                                </div>
                            </div>
                            
                        </div>

                        

                
                    </div>
                    
                    <div>
                        <label className="label-line">
                            All properties:
                        </label>
                        <div className="value-line" style={{ maxWidth: 600, height: 200, border: "1px solid #cfcfcf", overflowY: "auto" }}>
                            <ReactJson src={item}
                                        name={null}
                                        theme={"rjv-default"}
                                        iconStyle="circle"
                                        displayDataTypes={false}
                                        enableClipboard={true} />
                            {/* <ControlledCodeEditorFillContainer value={code} onChange={(newCode) => setCode(newCode)} language="json" /> */}
                        </div>
                    </div>
                </>
                : selTab === "json" ? <div style={{
                    width: 615, height: 477,
                    display: "grid",
                    gridTemplateRows: "min-content 1fr",
                    gridTemplateAreas: "'message' 'editor'",
                    //border: "1px solid red"
                }}>
                    <div style={{ gridArea: "message", fontSize: "9pt", xbackgroundColor: "$gray2", padding: 10, marginBottom: 5 }}>
                        {parseError ?
                            <span key={"error"}>
                                <i className='fal fa-exclamation-circle' style={{ color: "#aa0000" }} /> &nbsp;
                                {parseError}
                            </span> : 
                            <span key={"okay"}>
                                <i className='fal fa-check-circle' style={{ color: "$primary" }} /> &nbsp;
                                JSON is valid 
                            </span>
                        }
                    </div>
                    <div style={{ gridArea: "editor", position: "relative" }}>
                        <ControlledCodeEditorFillContainer
                            value={toPrettyJson(item)}
                            style={{ position: "absolute", border: "1px solid #cfcfcf" }}
                            onChange={(newCode) => {
                                setCode(newCode)
                                try {
                                    JSON5.parse(newCode);
                                    setParseError(null);
                                }
                                catch (err) {
                                    setParseError(err.message);
                                }
                            }}
                            language="json" />
                    </div> 
                </div>
                : <div>unknown tab</div>
            }
                

            
            
            
        </div>

        
    </ModalFrame>
}