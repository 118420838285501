import {useEffect, useState} from 'react';

export const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => {
            setMatches(e.matches);
        }
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

// export const useMediaQueries = (queries) => {

//     const mediaMatch = queries.match(query => window.matchMedia(query));
    
//     const [matches, setMatches] = useState(mediaMatch.matches);

//     useEffect(() => {
//         const handler = e => {
//             setMatches(e.matches);
//         }
//         mediaMatch.addEventListener("change", handler);
//         return () => mediaMatch.removeEventListener("change", handler);
//     });
//     return matches;
// };