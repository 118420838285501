import { useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelection, useSelectionState } from "../../hooks/useSelection";
import { EditContentContext } from "../content/EditContentContext";
import "./ToggleStateButton.scss";


export const ToggleStateButton = (props) => {
    const item = props.item;

    const ec = useContext(EditContentContext);
    const ref1 = useRef();

    const sel_id = item?.jdata?.selection;
    const [sel, selLoading, selError] = useSelection(sel_id);
    const [state, stateLoading, stateError] = useSelectionState(sel_id);

    const dispatch = useDispatch();

    console.log("sel", sel);
    console.log("state", state);

    const setValue = (x) => {
        console.log("dd setValue", x);
        dispatch({
            type: "SELECTION_STATE_SET",
            id: sel_id,
            value: x
        })
    }

    const style = { ...props.style, ...item?.style }
    return <div className={`mstudio-toggle-state-button ${props.displayClass}`} style={style} {...props.atts}
    >
        <button 
            className={`${state ? 'sel' : ''}`}
            onClick={ev => {
                ev.preventDefault();
                ev.stopPropagation();
                if( ec.mode === "edit" && (ev.altKey)){
                    ec.setSelectionId(item?.id);
                }
                else{
                    setValue(!state)
                }
            }}
        >
            {item.label}
        </button>
    </div>
};

