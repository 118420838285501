import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { ContentItemContext } from "../../../context/Contexts";
import { useImage } from "../../../hooks/useImage";
import { useProjectId } from "../../../hooks/useProjectId";
import { getPropertyValue } from "../../../utils/ContentProcessor";
import { getImagePath } from "../../../utils/ImageUtils";
import { EditContentItemWrapper } from "../../edit/EditContentItemWrapper";
import { ErrorBoundary } from "../../error-boundary/ErrorBoundary";
import { getAllowedChildTypes, getTypeInfo } from "../ContentTypes";
import "./ViewTile.scss";






export const ViewTile = (props) => {

    const {project_id, subscription_id} = useProjectId();
    const item = props.content_item;
    const context = useContext(ContentItemContext);

    const [backgroundImage] = useImage(item?.background?.imageId);
    const backgroundImagePath = backgroundImage ? getImagePath(subscription_id, project_id, backgroundImage?.filename) : null;
    

    const text = getPropertyValue(item?.text, context, {});
    
    let tileStyle = {
        position: "absolute",
        height: "100%",
        width: "100%",

        // border
        borderStyle: item?.border?.style,
        borderWidth: item?.border?.width + "px",
        borderColor: item?.border?.color,

        // background
        backgroundAttachment: item?.background?.attachment,
        backgroundBlendMode: item?.background?.blendMode,
        backgroundClip: item?.background?.clip,
        backgroundColor: item?.background?.color,
        //backgroundImage: item?.background?.image,
        backgroundOrigin: item?.background?.origin,
        backgroundPosition: item?.background?.position,
        backgroundRepeat: item?.background?.repeat,
        backgroundSize: item?.background?.size,

    }

    if (item?.background?.imageType === "url") {
        tileStyle.backgroundImage = `url("${backgroundImagePath}")`
    }
    else if (item?.background?.imageType === "none") {
    }

    return props.edit ? <EditContentItemWrapper {...props}>
        <div className='view-tile' style={tileStyle}>
            {text}
        </div>
    </EditContentItemWrapper> :
    <div className='view-tile' style={tileStyle}>
        {text}
    </div>
        
}


