import * as Recharts from 'recharts';
import * as ReactRedux from "react-redux";
import { useImage } from '../hooks/useImage';
// import * as Vega from 'react-vega';
// import * as Visx from '@visx/visx';
import * as d3Format from 'd3-format';
import * as d3Interpolate from 'd3-interpolate';
import { ProductEdit } from "../components/model/ProductEdit"
import { EditContentItemWrapper } from '../components/edit/EditContentItemWrapper';
import { ExportContentItemWrapper } from '../components/edit/ExportContentItemWrapper';
import { newId, newId2 } from './uuid';
import Popup from 'reactjs-popup';
import * as ReactRouterDom from 'react-router-dom';
import { DataTable } from "../components/common/DataTable";
import { ZSunburst, ZSunburst2 } from "../components/content-mstudio/ZSunburst";
import { ExportDataTable } from "../components/common/DataTableHelper";
import { useLazyLib } from '../hooks/useLazyLib';
//import * as TopoJsonClient from "topojson-client";
//import aes256 from 'aes256';
//import CryptoJS from "crypto-js";
//import AES from "crypto-js/aes";
//import SHA256 from "crypto-js/sha256";
import * as DotProp from 'dot-prop';

export const defaultLib = {
    Recharts,
    // Vega,
    // Visx,
    ReactRedux,
    ReactRouterDom,
    d3Format,
    //d3Interpolate,
    //d3Array,
    MStudio: {
        useImage,
        ProductEdit,
        //EditContentItemWrapper,
        //ExportContentItemWrapper,
        DataTable,
        ZSunburst: ZSunburst2,
        ExportDataTable
    },
    ReactJsPopup: Popup,
 //   TopoJsonClient,
    uuid: {
        newId,
        newId2
    },
    DotProp
    //aes256,
    //CryptoJS
}