import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";
import { useContentItem } from "../../hooks/useContentItem";
import { usePages, usePagesApi } from "../../hooks/usePage";
import { useProject } from "../../hooks/useProject";
import { useProjectId } from "../../hooks/useProjectId";
import { useContentItemsApi } from "../../hooks/useContentItems";
import { LeftMenu } from "../nav/LeftMenu";
import { Main } from "../nav/Main";
import { getTypeInfo } from "../page-content/ContentTypes";
import "./ProjectContent.scss";
import { CreateContent, GetCTypeGenerator, GetRegistryArr } from "./ContentRegistry";
import { PresentContent } from "./PresentContent";
import { ContentAdderButton } from "./ContentAdderButton";
import { DisplayContext } from "./DisplayContext";
import { DocTreeNode } from "./DocTreeNode";
import { RightMenu } from "../nav/RightMenu";
import { EditContentItem } from "./EditContentItem";
import { EditContentContext, IdContext } from "./EditContentContext";
import { ROOT_ID } from "../../hooks/helper/ContentItemHelper";
import { useLayoutEffect } from "react";

const TABS = [
    { key: "tree", label: "Tree" },
    //{ key: "tree", label: "Page Layout Tree" },
]

const MODES = [
    { id: "edit", label: "Edit" },
    { id: "preview", label: "Preview" },
]


export const ProjectContent = (props) => {

    
    const history = useHistory();
    const search = history.location.search;
    const routerParams = useParams();
    const { project_id, subscription_id } = useProjectId();

    const [project, projectLoading, projectError] = useProject();
    
    const api = useContentItemsApi();
    //const [root, rootLoading, rootError, rootReload] = useRootContentItem();
    const [root, rootLoading, rootError, rootReload] = useContentItem(ROOT_ID);
    
    const [message, setMessage] = useState();
    const [selectionId, setSelectionId] = useState(null);
    const [confirm, setConfirm] = useState(null);


    let selectedTabKey = routerParams.tab_id || TABS[0].key;

    const contentError = null;
    const contentLoading = false;

    const messageStr = rootLoading || rootError || message;

    const selContentItem = null;

    useLayoutEffect(() => {
        // scroll the selected item into view
        const el = document.getElementById(`docnode-${selectionId}`);
        if( el ){
            el.scrollIntoView({behavior: "smooth", block: "nearest"});
        }
    })
    

    const [isDev, setIsDev] = useState(true);
    const [mode, setMode] = useState(MODES[0].id);

    if( !project ) return null;
    //console.log("project", project);
    
    return <>
        
        <LeftMenu v2>
            <div className="tab-section">
                {TABS.map(tab => 
                    <Link 
                        key={tab.key}
                        to={`/subscription/${subscription_id}/project/${project_id}/content/${tab.key}`}
                        className={`tab ${tab.key === selectedTabKey ? "sel" : ""}`}>
                        {tab.label}
                    </Link>
                )}
            </div>
            {/* <div className='title'>
                Pages &amp; Components
            </div> */}
            <div className='top-section' style={{ 
                padding: "0 20px 10px 20px",
                borderBottom: "1px solid #cfcfcf"
            }}>
                
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr"
                }}>
                    <div className="center">
                        {MODES.map(m =>
                            <span key={m.id} 
                                className={`btn toggle-small ${mode === m.id ? "sel" : ""}`}
                                onClick={() => setMode(m.id)}>
                                    {m.label}
                            </span>
                        )}
                    </div>
                    <div className="right">
                        <span style={{ fontSize: "8pt" }}>
                            Use data:&nbsp;
                        </span>
                        <span className={`btn toggle-small ${isDev ? "sel" : ""}`}
                            onClick={() => setIsDev(true)}>
                                dev
                        </span>
                        <span className={`btn toggle-small ${isDev ? "" : "sel"}`}
                            onClick={() => setIsDev(false)}>
                                prod
                        </span>
                    </div>
                </div>
                {/* <div className='tab-section'>
                    {TABS.map((tab) => {
                        return <span key={tab.id} className={`tab ${tab.id === selTab ? 'sel' : ''}`} onClick={() => setSelTab(tab.id)}>
                            {tab.icon ? <i className={tab.icon}/> : null} {tab.label}
                        </span>
                    })}
                </div> */}
            </div>
            
            <div className='scroller' style={{ padding: 10 }}
                onClick={() => {
                    // this occurs when user clicks left menu outside the doctree
                    setSelectionId(null);
                }}
            >
                {/* <div className='summary'>
                    <p>
                        Content description goes here.
                    </p>
                </div>
                <div className='action-bar-sticky'>

                    <span className={`btn toggle margin-right`}
                        style={{
                            padding: "7px 7px"
                        }}
                        onClick={() => {
                        // todo: expand all or collapse all
                        //dispatch({ type: "PAGES_SET_MISC_VALUE", name: "showUrl", value: !showUrl });
                    }}>
                        <i className='fal fa-plus-circle'/>
                    </span>

                </div>
                 */}
                {contentError ? <div className='list-something error'>Error loading content</div> :
                    <div className="project-content-doctree">
                        <EditContentContext.Provider value={{
                            selectionId,
                            setSelectionId,
                            mode,
                            itemStack: [null],
                            reloadStack: [async () => {
                                setMessage("Reloading root");
                                await rootReload();
                                setMessage(null);
                            }],
                            message,
                            setMessage,
                            // addChild: async (entry) => {
                            //     alert("does this get used!?")
                            //     // add root content item
                            //     setMessage("Creating root content item...", entry);
                            //     //await api.newItem({ ctype: entry.id, jdata: entry.jdata, label: entry.label });
                            //     const newItem = CreateContent(entry);
                            //     console.log("newItem", newItem);
                            //     //await api.newItem(newItem);
                                
                            //     setMessage(null);
                            // },
                            navigate: () => {
                                //console.log("root navigate returning:", history.location.search);
                                //const search = history.location.search;
                                return history.location.search;
                            },
                            project: project
                        }}>
                            {rootLoading ? null :
                                rootError ? null :
                                root ? <DocTreeNode id={root.id} />
                                : null
                            }
                        </EditContentContext.Provider>
                    </div>
                }
            </div>
        </LeftMenu>

        <RightMenu
            open={mode === "edit"}
            className={mode === "edit" ? 'open' : "collapsed"}>

            <div className='title'>
                Edit Properties
                <span className='right'>
                    <span className='btn icon' onClick={() => {
                        setSelectionId(null);
                    }}>
                        <i className='fal fa-times'/>
                    </span>
                </span>
                <div style={{ fontSize: 9, color: "#a0a0a0" }}>{selContentItem?.id}</div>
            </div>
            <div className='top-section'>
                <div>
                
                </div>
            </div>
            
            <div className='scroller'>
                <div className='summary'>
                    <p>
                        Edit properties for the selected content item here. Click the close
                        button (top-right) to cancel and/or close this window. Have a nice day.
                    </p>
                </div>
                {/* <div className='action-bar-sticky'>
                </div> */}

                
                <EditContentItem id={selectionId} message={message} setMessage={setMessage} />
                
                
                
            </div>

        </RightMenu>

        <Main v2 rightMenuOpen={mode === "edit"}>
            <div className="project-content">
                {messageStr && <div className="message-bar">
                    {messageStr}
                </div>}
                <EditContentContext.Provider value={{
                    selectionId,
                    setSelectionId,
                    mode,
                    dsRevisionSlot: isDev ? "__dev" : "__prod",
                    itemStack: [null],
                    reloadStack: [async () => {
                        setMessage("Reloading root");
                        await rootReload();
                        setMessage(null);
                    }],
                    message,
                    setMessage,
                    addChild: async (entry) => {
                        // add root content item
                        setMessage("Creating root content item...");
                        //await api.newItem({ ctype: entry.id, jdata: entry.jdata, label: entry.label }); // key not needed
                        const newItem = CreateContent(entry);
                        await api.newItem(newItem);
                        setMessage(null);
                    },
                    project: project
                }}>
                    <DisplayContext.Provider value={"block-fill"}>
                        {rootLoading ? null :
                            rootError ? null :
                            root ? <PresentContent 
                                id={root.id} 
                                // onDelete={async () => {
                                //     await api.deleteItem(root.id);
                                // }}
                            />
                            : <ContentAdderButton description="Hint: Click here to add content. Consider starting with a layout" />
                        }
                    </DisplayContext.Provider>
                </EditContentContext.Provider>
            </div>
        </Main>

    </>;


    
}

