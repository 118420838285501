import { useMemo } from "react";
import { createContext, useContext } from "react";
import { useDispatch } from "react-redux";
import { reduceResources } from "../../hooks/helper/reduceResources";
import { useAuth } from "../../hooks/useAuth";
import { useContentItem } from "../../hooks/useContentItem";
import { useContentItemsApi } from "../../hooks/useContentItems";
import { useProjectId } from "../../hooks/useProjectId";
import { useResources } from "../../hooks/useResources";
import { CreateContent, GetCTypeGenerator } from "./ContentRegistry";
import { DisplayContext, getDisplayClass } from "./DisplayContext";
import { EditContentContext } from "./EditContentContext";
import { EditWrapper } from "./EditWrapper";




export const getMax = (vals) => vals?.reduce((prev, val) => Math.max(prev, val), 0) || 0;

export const getMaxDisplayIndex = (items) => getMax(items?.map(i => i?.display_index || 0));

export const PresentContent = (props) => {

    const id = props.id;
    const api = useContentItemsApi();
    
    // load the content item specified by id (ideally it is likely already in redux)
    const [item, itemLoading, itemError, itemReload] = useContentItem(id);

    // determine display characteristics
    const display = useContext(DisplayContext);
    const displayClass = getDisplayClass(display);
    const editContext = useContext(EditContentContext);
    //console.log("Present content editContext", editContext);

    const { subscription_id, project_id } = useProjectId();
    const auth = useAuth();
    const dispatch = useDispatch();

    const [resources, resourcesLoading, resourcesError] = useResources(item?.jdata?.resources, true);
    
    const rResources = useMemo(() => {
        if( !item || resourcesLoading || resourcesError ) return null;
        return reduceResources(resources, subscription_id, project_id, dispatch, auth);
    }, [resources, subscription_id, project_id]) 

    // wait until the item is loaded before generating the component
    if( itemLoading ) return <div className={`content-message ${displayClass}`}>Loading...</div>;
    if( itemError ) return <div className={`content-message error ${displayClass}`}>Error: {itemError}</div>;
    if( !item ) return null; // <div className={`content-message error ${displayClass}`}>item missing</div>;
    if( item.jdata?.visible === false ) return null;

    // load item resources
    if( resourcesLoading ) return <div className={`content-message ${displayClass}`}>Loading....</div>;
    if( resourcesError ) return <div className={`content-message error ${displayClass}`}>Error: { resourcesError }</div>;
    //const rResources = reduceResources(resources, subscription_id, project_id, dispatch, auth);

    // get the component generator
    const generateComponent = GetCTypeGenerator(item.ctype);
    if( !generateComponent ) return <div className={`content-message error ${displayClass}`}>ctype "{item.ctype}" not found</div>;

    const mode = editContext?.mode;

    const deleteChild = async (childId, childLabel) => {
        editContext.setMessage(
            <div>
                <div style={{ marginBottom: 5 }}>
                    <i className="fas fa-skull" />&nbsp; <span className="alt-color">Delete "{childLabel}"</span> Are you sure?
                </div>
                <div>
                    <button onClick={async () => {

                        editContext.setMessage("Deleting...");
                        await api.deleteItem(childId);
                        editContext.setMessage(null);

                        const reload = editContext.reloadStack?.[editContext.reloadStack?.length - 1];
                        //await itemReload?.();
                        await reload?.();

                        editContext.setMessage(null);
                    }}>
                        Yes
                    </button>
                    <button onClick={() => {
                        editContext.setMessage(null);
                    }}>
                        Cancel
                    </button>
                </div>
            </div>
        );
        
        
    }

    const addChild = async (entry, key) => {
        editContext.setMessage("Creating child...");

        const obj = CreateContent(entry);
        obj.ctype = entry.ctype;
        obj.label = entry.label;
        if( key ){
            obj.key = key;
        }
        // if( entry.jdata ){
        //     obj.jdata = entry.jdata;
        // }

        // add display index for the new guy
        if( !key ){
            obj.display_index = (getMaxDisplayIndex(item.children) || 0) + 1;
        }

        const newInfo = await api.newItem(obj, id);
        editContext.setMessage("Loading...");
        await itemReload?.();
        
        editContext.setSelectionId(newInfo.newId); // select the new item
        //editContext.setSelectionId(null); // clear selection

        editContext.setMessage(null);
    }

    let content = generateComponent({ ...props, display, displayClass, item, resources: rResources });

    // wrap in editor
    if( mode === "edit" ){
        const newEditContext = {
            parentContext: editContext,
            ...editContext,
            deleteChild,
            addChild,
            item,
            itemStack: [...editContext.itemStack, item],
            reloadStack: [...editContext.reloadStack, async () => {
                editContext.setMessage("Reloading...");
                await itemReload();
                editContext.setMessage(null);
            }]
        };
        content = <EditContentContext.Provider value={newEditContext}>
            <EditWrapper>
                {content}
            </EditWrapper>
        </EditContentContext.Provider>
    }
    else{
        // mode === "preview"
        const newEditContext = {
            ...editContext,
            item,
        }
        content = <EditContentContext.Provider value={newEditContext}>
            {content}
        </EditContentContext.Provider>
    }

    return content;

    
}