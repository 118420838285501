import { format } from "d3-format";
import { saveAs } from 'file-saver';
import JSON5 from "json5";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import { useRecoilValue } from "recoil";
import { ContentItemContext } from "../../context/Contexts";
import { useAuth } from "../../hooks/useAuth";
import { useDataSource } from "../../hooks/useDataSource";
import { useDataSourceRevision } from "../../hooks/useDataSourceRevision";
import { useProjectId } from "../../hooks/useProjectId";
import { useSelection, useSelectionState } from "../../hooks/useSelection";
import { debounce } from "../../utils/debounce";
import { tableToExcel } from "../../utils/ExportUtils";
import { computeContextState } from "../content-mstudio/ComputeContext";
import { EditContentContext } from "../content/EditContentContext";
import { EditContentItemWrapper } from "../edit/EditContentItemWrapper";
import { EditAttCell } from "../model/ProductEdit";
import "./Configurator.scss";

const COMPARE_OPTIONS = [
    { key: "none", label: "All Metrics" },
    { key: "scenarios", label: "Compare Scenarios" },
    { key: "groups", label: "Compare Groups" },
]

const METRICS = [
    { key: "share", label: "Preference Share", valueFormatter: format(".1%"), format: ".1%" },
    { key: "volume", label: "Volume", valueFormatter: format(",.0f"), format: ",.0f" },
    { key: "revenue", label: "Revenue", valueFormatter: format("$,.0f"), format: "$,.0f" },
];


export const Configurator = (props) => {

    const {project_id, subscription_id} = useProjectId();
    const item = props.content_item;
    const jdata = item?.jdata;
    const ec = useContext(EditContentContext);

    const [attsDs, attsDsLoading] = useDataSource(jdata?.attsId);
    const [attsRev, attsRevLoading] = useDataSourceRevision(jdata?.attsId, attsDs?.dev_revision);

    const context = useContext(ContentItemContext);
    const dispatch = useDispatch();
    const auth = useAuth();
    
    const [showProps, setShowProps] = useState(true);
    const [showAdvancedProps, setShowAdvancedProps] = useState(false);
    const [showResults, setShowResults] = useState(true);
    const [showHiddenProps, setShowHiddenProps] = useState(false);
    //const [aggregationProp, setAggregationProp] = useState(null);
    const [aggregationExpanded, setAggregationExpanded] = useState(false);
    //const [expandedAggs, setExpandedAggs] = useState([]);
    const [collapsedAggs, setCollapsedAggs] = useState([]);
    const [sortInfo, setSortInfo] = useState(null);
    const [selMetrics, setSelMetrics] = useState(["share", "revenue"]);

    const [compare, setCompare] = useState(COMPARE_OPTIONS[0].key);
    const [metric, setMetric] = useState(METRICS[0].key);

    const [selectedProductIds, setSelectedProductIds] = useState([]);

    const wcHook = item?.jdata?.contextHooks?.find(h => h.name === "wc");
    const bcHook = item?.jdata?.contextHooks?.find(h => h.name === "bc");
    
    const wcState = useRecoilValue(computeContextState(wcHook?.id));
    const bcState = useRecoilValue(computeContextState(bcHook?.id));
    
    const [selection, selectionLoading, selectionError] = useSelection(item?.selectionId); // for default value
    const [state, stateLoading, stateError] = useSelectionState(item?.selectionId);
    const [compState, compStateLoading, compStateError] = useSelectionState(item?.compSelectionId);

    const [aggAttNameState, aggAttNameStateLoading, aggAttNameStateError] = useSelectionState(item?.aggAttNameState);

    //const [layout, setLayout] = useState(item?.defaultLayout);
    

    // const allResourcesReady = item && state && compState && (
    //     (resources && !resourcesLoading) || !item?.resources?.length
    // );

    // let rResources = null;
    // if (allResourcesReady) {
    //     rResources = reduceResources(resources, subscription_id, project_id, dispatch, auth);
    //     rResources.selection = state
    //     rResources.compSelection = compState;
    // }

    const resources = props.resources;
  

    let atts;
    try{
        atts = attsRev?.udata ? JSON5.parse(attsRev?.udata) : null;
    }
    catch{
        console.error("error parsing atts revision udata", attsRev?.udata)
    }

    



    const wrapMessage = (message) => {

        return <EditContentItemWrapper {...props}>
            <div className="message h-centered">
                {message}
            </div>
        </EditContentItemWrapper>
    }

    const allowComparison = item.compSelectionId ? true : false;

    if (stateLoading) return wrapMessage("Loading configuration...");
    //if (!config?.atts) return wrapMessage("config missing");
    if (!state) return wrapMessage("state missing");
    if (stateError) return wrapMessage("Error loading state");

    //const atts = config.atts;
    if (!atts && !attsDsLoading && !attsRevLoading) return wrapMessage("atts missing");
    if( !atts ) return wrapMessage("Loading configuration...");

    const _atts = atts
        ?.filter(att => (!att.advanced || showAdvancedProps) && (!att.hidden || showHiddenProps));
    

    const saveState = (newState) => {
        // dispatch({
        //     type: "CONFIG_STATE_SET",
        //     id: stateId,
        //     value: newState
        // })
        dispatch({
            type: "SELECTION_STATE_SET",
            id: item?.selectionId,
            value: newState
        })
    }

    const resetConfig = () => {
        // change the selection back to its default value
        saveState(selection.defaultValue);
    }

    const setBase = () => {
        // set the comparion state to be the same as this one
        if (item?.compSelectionId) {
            dispatch({
                type: "SELECTION_STATE_SET",
                id: item?.compSelectionId,
                value: state
            })
        }
    }

    let content = null;


    
    
    const resultInfo = {
        showResults,
        //...payload,
        results: wcState?.result?.whatif || wcState?.result, // payload.result?.whatif,
        compResults: bcState?.result?.base || bcState?.result // payload.result?.comp
        // todo: computing??
    }
    //console.log("payload", payload);

    

    const exProps = {
        compare,
        metric,
        selMetrics,
        showProps,
        showAdvancedProps,
        //aggregationProp: aggregationProp || item?.jdata?.aggAttName || aggAttNameState,
        aggregationProp: aggAttNameState,
        aggregationExpanded,
        showAvailability: item?.jdata?.showAvailability !== "no",
        setAggregationExpanded,
        // expandedAggs,
        // setExpandedAggs,
        collapsedAggs,
        setCollapsedAggs,
        sortInfo,
        setSortInfo,
        selectedProductIds,
        setSelectedProductIds
    }
    const selComp = COMPARE_OPTIONS.find(c => c.key === compare);
    const selMetric = METRICS.find(m => m.key === metric);

    if (item.layout === "rows") {
        content = <ViewConfigAsRows ui_atts={props.atts} atts={_atts} state={state} compState={compState} exProps={exProps} resultInfo={resultInfo} onChange={(newState) => saveState(newState)}/>
    }
    else{ // tiles is the deafult if (layout === "tiles") {
        content = <ViewConfigAsTiles ui_atts={props.atts} atts={_atts} state={state} exProps={exProps} resultInfo={resultInfo} onChange={(newState) => saveState(newState)}/>
    }

    const exportTable = async () => {
        exportConfigAsRows({
            atts: _atts,
            state,
            exProps,
            resultInfo,
        })
    };

    return (
        <div className={`configurator ${props.displayClass}`} {...props.atts}
            onClick={(ev) => {
                if( ec?.mode === "edit" && ev.altKey ){
                    ec?.setSelectionId(item?.id);
                    ev.stopPropagation();
                    ev.preventDefault();
                }
                else{
                    ev.stopPropagation();
                    ev.preventDefault();
                }
            }}

        >
            <div className="configurator-content"
            >
                {content}
            </div>
        </div>
    );
    

}

const defaultValueFormatter = format(".6f");

const isNull = (x) => x === null || x === undefined;

const nullCheckCompare = (a, b) => {
    if( isNull(a) && isNull(b) )
        return 0;
    if( isNull(a) )
        return -1;
    if( isNull(b) )
        return 1;
    return b - a;
}

const aggregateResultCells = (cells) => {
    let share = null;
    let volume = null;
    let revenue = null;
    for( let cell of cells ){
        if(!isNull(cell?.share)){
            share = (share || 0) + cell.share;
        }
        if(!isNull(cell?.volume)){
            volume = (volume || 0) + cell.volume;
        }
        if(!isNull(cell?.revenue)){
            revenue = (revenue || 0) + cell.revenue;
        }
    }
    return {
        share,
        volume,
        revenue,
        nbCells: cells.length
    }
}

export const GetRowsCols = (props) => {

    const { atts, state, compState } = props; 
    const { compare, metric, selMetrics, aggregationProp, sortInfo, setSortInfo, showProps, showAdvancedProps } = props.exProps;
    //const { expandedAggs, setExpandedAggs } = props.exProps;
    const { collapsedAggs, setCollapsedAggs } = props.exProps;
    const { showResults, results, compResults, needsCompute, computing } = props.resultInfo;
    const products = state?.products;
    const compProducts = compState?.products;
    const { aggregationExpanded, showAvailability } = props.exProps;

  

    let cols = [];

    // determine if there exists aggregation, because we'll need a column for the collapser
    const aggAtt = atts.find(att => att.name === aggregationProp);
    if( aggAtt ){
        //cols.push({ key: "expander", type: "expander", label: "Expander", category: "_Label" });
    }

    // add available prop
    if( showProps && showAvailability ){
        cols.push({ key: "available", type: "availability", label: "Available", category: "_Label" });
    }

    // add label
    cols.push({ key: "label", type: "label", label: "Label", category: "_Label" });

    // add other props
    if( showProps || showAdvancedProps ){
        atts.forEach(att => {
            if( att.ui ){
                if( att.advanced ){
                    if( showAdvancedProps ){
                        cols.push({ key: att.name, type: "property", label: att.label, category: "_Atts", property: att });
                    }
                }
                else{
                    if( showProps ){
                        cols.push({ key: att.name, type: "property", label: att.label, category: "_Atts", property: att });
                    }
                }
            }
        })
    }

    const metrics = METRICS.filter(m => selMetrics.includes(m.key));
    if( showResults ){
        metrics.forEach((metric) => {
            cols.push({ key: `${metric.key}_scenario`, type: "result", label: "Scenario", format: metric.format, category: metric.label });
            cols.push({ key: `${metric.key}_base`,     type: "result", label: "Base", format: metric.format, category: metric.label });
            cols.push({ key: `${metric.key}_delta`,    type: "result", label: "Delta", format: metric.format, category: metric.label });
            cols.push({ key: `${metric.key}_index`,    type: "result", label: "Index", format: ".1f", category: metric.label });
        })
    }

    let rows = [];
    
    // add products and result cells to items array
    let items = products.map(product => {
        const resultCell = showResults ? results?.find?.(row => row.id === product.id) || { share: results?.[0]?.iProbs?.[product.id] } : null;
        const compResultCell = showResults ? compResults?.find?.(row => row.id === product.id) || { share: compResults?.[0]?.iProbs?.[product.id] } : null;
        let row = {
            ...product,
            isProduct: true,
            product,
            compProduct: compProducts?.find(cp => cp.id === product.id),
            resultCell,
            compResultCell
        }
        return applyResults(row, metrics);
    })
    rows = [...rows, ...items];

    // add aggregated items if needed
    let atLeastOneProductIsShown = true;
    if( aggAtt ){
        atLeastOneProductIsShown = false;

        // aggregate into a dictionary
        let aggDict = {};
        for( let item of items ){
            let key = item.product[aggAtt.name];
            let find = aggDict[key];
            if( !find ){
                //const id = `agg-${aggAtt.name}-${key}`;
                find = {
                    id: `agg-${aggAtt.name}-${key}`,// + Object.keys(aggDict).length,
                    label: (aggAtt.levels?.find?.(level => level.value == key)?.label) || key,
                    resultCells: [],
                    compResultCells: [],
                    products: [],
                    isAggregation: true,
                    aggAttValue: key
                };
                aggDict[key] = find;
            }
            find.resultCells.push(item.resultCell);
            find.compResultCells.push(item.compResultCell);
            find.products.push(item.product);
        }

        // convert dictionary to array, aggregate result cells, prelim sort by label
        let aggRows = Object.entries(aggDict).reduce((acc, entry) => {

            let aggRow = { ...entry[1] };
            aggRow.resultCell = aggregateResultCells(aggRow.resultCells);
            aggRow.compResultCell = aggregateResultCells(aggRow.compResultCells);

            acc.push(applyResults(aggRow, metrics));
            return acc;
        }, [])
            .sort((a, b) => a.label?.localeCompare?.(b.label)); // this is just a prelim sort - actual sort would override

        // sort agg rows
        aggRows = sortRows(sortInfo, aggRows);
        
        // attach child rows, sorted, (for any expanded aggRow)
        rows = aggRows.reduce((acc, aggRow) => {
            acc.push(aggRow);
            if( !collapsedAggs.includes(aggRow.id) ){
                const childRows = sortRows(sortInfo, rows.filter(row => aggRow.products.find(p => p.id === row.product.id)));
                acc = [...acc, ...childRows];
                if( childRows.length > 0 ){
                    atLeastOneProductIsShown = true;
                }
            }
            return acc;
        }, []);

        
    }
    else{
        rows = sortRows(sortInfo, rows);
    }

    // exclude product-only columns if no products are being shown
    if( !atLeastOneProductIsShown ){
        cols = cols.filter(col => 
            col.type !== "availability"
            && col.type !== "property"
        )
    }

    // group cols into categories
    let categoryCols = [];
    {
        let prevCategory = null;
        for( let i = 0; i < cols.length; i++ ){
            if( !prevCategory || cols[i].category !== prevCategory.label ){
                prevCategory = {
                    label: cols[i].category,
                    cols: [cols[i]]
                };
                categoryCols.push(prevCategory);
            }
            else{
                prevCategory.cols.push(cols[i]);
            }
        }
    }

    return { rows, cols, categoryCols };

}



const applyResults = (row, metrics) => {

    let newRow = { ...row };

    let { resultCell, compResultCell } = row;


    metrics.forEach(metric => {
        newRow[`${metric.key}_scenario`] = resultCell?.[metric.key];
        newRow[`${metric.key}_base`] = compResultCell?.[metric.key];
        newRow[`${metric.key}_delta`] = safeDiff(compResultCell?.[metric.key], resultCell?.[metric.key]);
        newRow[`${metric.key}_index`] = safeIndex(compResultCell?.[metric.key], resultCell?.[metric.key]);
    })

    return newRow;

}

const sortRows = (sortInfo, rows) => {

    if( !sortInfo?.name || !sortInfo?.dir ) return rows;

    let sortedRows = [...rows];
    sortedRows = sortedRows.sort((a, b) => {
        if( sortInfo.type === "string" ){
            const cmp = `${a[sortInfo.name]}`.localeCompare(`${b[sortInfo.name]}`);
            return sortInfo.dir === "asc" ? cmp : 0 - cmp;
        }
        else{
            const cmp = nullCheckCompare(a[sortInfo.name], b[sortInfo.name]);
            return sortInfo.dir === "desc" ? cmp : 0 - cmp;
        }
    })
    return sortedRows;

}


export const ViewConfigAsRows = (props) => {
   

    const atts = props.atts;
    const state = props.state;
    const compState = props.compState;
    const products = state?.products;
    const compProducts = compState?.products;

    const { compare, metric, aggregationProp, sortInfo, setSortInfo } = props.exProps;
    const { expandedAggs, setExpandedAggs } = props.exProps;
    const { collapsedAggs, setCollapsedAggs } = props.exProps;
    const { showResults, results, compResults, needsCompute, computing } = props.resultInfo || {};

    const { aggregationExpanded, setAggregationExpanded } = props.exProps;

    const { selectedProductIds, setSelectedProductIds } = props.exProps;

    

    // console.log("results", results);
    // console.log("compResults", compResults);

    const toggleSort = (name, colType) => {

        let type = null;
        if( colType === "label" ){
            type = "string";
        }
        else if( colType === "result" ){
            type = "numeric";
        }
        else{
            // todo
        }


        const dirOrder = type === "string" ? ["asc", "desc", null] : ["desc", "asc", null];
        if( sortInfo?.name !== name ){
            setSortInfo({ name, dir: dirOrder[0], type });
        }
        else{
            const dirIndex = dirOrder.indexOf(sortInfo?.dir);
            const newDir = dirOrder[(dirIndex + 1) % dirOrder.length];
            setSortInfo(newDir ? { name, dir: newDir, type } : null);
        }
    }

    const getSortIndicator = (name, type) => {
        if( !sortInfo?.dir || sortInfo?.name !== name )
            return null;
        const style = { float: "right" };
        if( type === "string" ){
            return sortInfo?.dir === "asc" ? <i className="fad fa-caret-up" style={style} /> : <i className="fad fa-caret-down" style={style} />;
        }
        else{
            return sortInfo?.dir === "asc" ? <i className="fad fa-caret-up" style={style} /> : <i className="fad fa-caret-down" style={style} />;
        }
    }

    let { rows, cols, categoryCols } = GetRowsCols(props);

    const aggAtt = atts.find(att => att.name === aggregationProp);

    const copyValues = async (col, includeInfo) => {

        const colNames = [];
        if( includeInfo ) colNames.push("Label");
        const att = col.property;
        colNames.push(att?.label || att?.name || col.label);
        if( includeInfo && att?.ui === "range" ){
            colNames.push("Min");
            colNames.push("Max");
        }

        const strs = rows
            .filter(row => row.product) // has product
            .map(row => {
                const vals = [];
                if( includeInfo ) vals.push(row["label"]);
                vals.push(row[col.key]);
                if( includeInfo && att?.ui === "range" ){
                    vals.push(att.min);
                    vals.push(att.max);
                }
                return vals.join("\t");
            })

        const str = [colNames.join("\t"), ...strs].join("\n");
        await navigator.clipboard.writeText(str);
    }

    const resetValues = async (col) => {

        const att = col.property;
        if( !att ) return;

        let newProducts = products.map(p => ({...p}));
        for( let i = 0; i < products.length; i++ ){
            const p = products[i];
            const cp = compProducts.find(cp => cp.id === p.id);
            newProducts[i][att.name] = cp[att.name];
        }

        const newState = {
            ...state,
            products: newProducts
        };
        props.onChange?.(newState);
    }

    const pasteValues = async (col) => {

        try{
            const att = col.property;
            if( !att ){
                alert("Cannot paste into non-attribute column");
                return;
            }

            const str = await navigator.clipboard.readText();
            if( !str ) return;
            const pasted_rows = str.split('\n').map(s => s?.trim());

            if( pasted_rows?.length < 1 ) {
                alert("No rows found");
                return; // no rows
            }

            const firstCells = pasted_rows[0].split('\t').map(s => s?.trim());
            if( !firstCells || firstCells.length < 1 ) {
                alert("No data found");
                return; // weird
            }
            const nbCols = firstCells.length;

            const hasLabelColumn = firstCells.includes("Label");
            const labelIndex = hasLabelColumn ? firstCells.indexOf("Label") : -1;

            const dataName = att?.label || att?.name || col.label;
            const hasDataNameColumn = firstCells.includes(dataName);
            const dataIndex = hasDataNameColumn ? firstCells.indexOf(dataName) : 0;

            // if only one column, does the first row contain the att or col label?
            let hasColumnNames = hasLabelColumn || hasDataNameColumn;
            if( nbCols > 1 && !hasColumnNames ) {
                alert("Column names are required when multiple columns exist");
                return;
            }

            let new_products = [];
            let pr_index = hasColumnNames ? 1 : 0;
            for( let r = 0; r < rows.length; r++ ){
                let pr = pr_index;
                let row = rows[r];
                let prow = pasted_rows[pr]?.split('\t')?.map(s => s?.trim());

                if( row.product ){

                    let product = { ...row.product };
                    new_products.push(product);

                    // check label if exists
                    if( hasLabelColumn ){
                        if( prow[labelIndex] != product.label ){
                            alert(`product label mismatch {"${prow[labelIndex]}", "${product.label}"}`);
                            return;
                        }
                    }
                    
                    let new_value = prow[dataIndex];
                    let new_value_num = parseFloat(new_value);
                
                    product[att.name] = new_value_num;
                    pr_index++;
                }
            }

            // save changes
            const newState = {
                ...state,
                products: new_products
            }
            props.onChange?.(newState);

        }
        catch(err){
            alert("Error pasting values: " + err.message);

        }

    }

    return <div className="view-config-as-rows">
        <table className="">
            <thead>
                {/* category headers */}
                <tr>
                    {categoryCols.map((cat, i) => {
                        return <th key={i} className="sticky-top cat-label border-right" colSpan={cat.cols.length}
                            style={{ 
                                //border: "1px dashed black",
                                textAlign: "center"
                            }}
                        >
                            {cat.label?.startsWith("_") ? null : cat.label}
                        </th>
                    })}
                </tr>
                {/* column names */}
                <tr className={` `}>
                    {cols.map((col, colIndex) => {
                        let isLastColInCategory = cols[colIndex+1]?.category !== col.category;
                        let style = {
                            minWidth: 100
                        };
                        let hideLabel = false;
                        if( col.type === "availability" || col.type === "expander" ){
                            hideLabel = true;
                            style.width = 40;
                            style.minWidth = 40;
                        }

                        const allowCopy = col.type !== "expander";
                        const allowPaste = col.property;
                        const allowReset = col.property;

                        return <th key={col.key} className={`sticky-top ${isLastColInCategory ? "border-right" : ""}`} style={style}
                            
                        >
                            <div className={`height-transitioner ${selectedProductIds?.length ? 'taller' : ''}`}>
                                <div className='col-label hover-sort _relative extra-right-pad'
                                    onClick={() => toggleSort(col.key, col.type)}
                                >
                                    {hideLabel ? null : col.label} {getSortIndicator(col.key)}
                                    <span className="btn-column-menu-space">
                                        <Popup
                                            position="bottom right"
                                            keepTooltipInside
                                            arrow={false}
                                            trigger={
                                                <span className="btn-column-menu" onClick={(ev) => {
                                                    ev.stopPropagation();
                                                }}>
                                                    <i className="fal fa-ellipsis-v" />
                                                </span>
                                            }
                                            >
                                            {close => <div className="popup-menu">
                                                {allowCopy ? <div className="menu-group">
                                                    <div className="menu-item" onClick={async () => {
                                                        await copyValues(col, false);
                                                        close();
                                                    }}>
                                                        Copy values
                                                        <i className="far fa-copy icon" />
                                                    </div>
                                                    <div className="menu-item" onClick={async () => {
                                                        await copyValues(col, true);
                                                        close();
                                                    }}>
                                                        Copy values and info
                                                    </div>
                                                </div> : null}
                                                {allowPaste ? <div className="menu-group">
                                                    <div className="menu-item" onClick={async () => {
                                                        await pasteValues(col);
                                                        close();
                                                    }}>
                                                        Paste values
                                                        <i className="far fa-paste icon" />
                                                    </div>
                                                </div> : null}
                                                {allowReset ? <div className="menu-group">
                                                    <div className="menu-item" onClick={async () => {
                                                        resetValues(col);
                                                        close();
                                                    }}>
                                                        Reset to base case
                                                    </div>
                                                </div> : null}
                                            </div>}
                                        </Popup>
                                    </span>
                                </div>
                                {col.type === "property" ?
                                        <div className={`col-actions ${selectedProductIds?.length ? "visible" : "hidden"}`}>
                                            <EditColumnProp col={col} selectedProductIds={selectedProductIds} products={products} compProducts={compProducts} onChange={props.onChange} state={state} />
                                        </div> 
                                    : col.type === "result" ? 
                                        <div className={`col-actions ${selectedProductIds?.length ? "visible" : "hidden"}`}>
                                            <ResultAgg col={col} selectedProductIds={selectedProductIds} results={results} compResults={compResults} />
                                        </div> 
                                    : col.type === "label" ?
                                        <div className={`col-actions ${selectedProductIds?.length ? "visible" : "hidden"}`}>
                                            
                                            <button
                                                className={'btn-reset-sel-products'}
                                                title="Clear selection"
                                                onClick={() => setSelectedProductIds([])}
                                                style={{ marginRight: 5 }}
                                            >
                                                <i className="fas fa-times-circle" />
                                            </button>
                                            {selectedProductIds?.length} selected product(s)
                                        </div>
                                    : null
                                }
                            </div>
                        </th>
                    })}
                </tr>
            </thead>
            <tbody>
                {rows?.map(row => {

                    const product = row.isProduct ? products.find(p => p.id === row.id) : null; // can't I just use row.product?
                    const compProduct = row.compProduct;

                    const isSelected = product && selectedProductIds.includes(product?.id);

                    //const resultCell = showResults ? results?.find?.(row => row.id === product.id) : null;

                    return <tr key={row.id} className={`${row.isProduct ? (product.available ? "available" : "not-available") : "agg"} ${isSelected ? "sel" : ""}`}>
                        {
                            cols.map((col, colIndex) => {
                                let isLastColInCategory = cols[colIndex+1]?.category !== col.category;
                                const br = isLastColInCategory ? "border-right" : "";
                                if( col.type === "expander" ){

                                    //const isExpanded = expandedAggs.includes(row.id);
                                    const isCollapsed = collapsedAggs.includes(row.id);
                                    
                                    return <td key={`${col.key}/${colIndex}`} className={`cell centered white sticky-left ${br}`}>
                                    {row.isProduct ? null 
                                        :
                                        <span xclassName='btn-layout' 
                                            onClick={() => {
                                                // if( isExpanded ){
                                                //     setExpandedAggs(expandedAggs.filter(x => x !== row.id));
                                                // }
                                                // else{
                                                //     setExpandedAggs([...expandedAggs, row.id]);
                                                // }
                                                if( isCollapsed ){
                                                    setCollapsedAggs(collapsedAggs.filter(x => x !== row.id));
                                                }
                                                else{
                                                    setCollapsedAggs([...collapsedAggs, row.id]);
                                                }
                                            }}
                                        >
                                            {/* <i className={isExpanded ? "fal fa-circle-minus" : "fal fa-circle-plus"} /> */}
                                            <i className={isCollapsed ? "fal fa-circle-plus" : "fal fa-circle-minus"} />
                                        </span>
                                    }
                                </td>;
                                }
                                else if( col.type === "availability" ){
                                    return <td key={`${col.key}/${colIndex}`} className={`cell centered white sticky-left ${br}`}>
                                        {row.isProduct ?
                                            // <input type="checkbox" checked={product.available} tabIndex={colIndex} onChange={(ev) => {
                                            //     const newState = {
                                            //         ...state,
                                            //         products: products.map(p => p.id === product.id ? { ...p, available: ev.target.checked } : p)
                                            //     }
                                            //     props.onChange?.(newState);
                                            // }}/>
                                            <span className="availability-toggle"
                                                onClick={ev => {

                                                    const newState = {
                                                        ...state,
                                                        products: products.map(p => p.id === product.id ? { ...p, available: !row.product.available } : p)
                                                    }
                                                    props.onChange?.(newState);
                                                }}
                                            >
                                                {product.available ? <i className="fad fa-toggle-on available" /> : <i className="fad fa-toggle-off not-available" />}
                                            </span>
                                            :
                                            null
                                        }
                                    </td>;
                                }
                                else if( col.type === "label" ){
                                    if( !row.isProduct ){
                                        //console.log("agg row", row);
                                    }
                                    return <td key={`${col.key}/${colIndex}`} className={`cell label white sticky-left ${br}`} style={{ padding: "5px 20px" }}
                                    >
                                        {false && <span className="sel-btn"
                                            onClick={() => {
                                                if( row.isProduct ){
                                                    if( isSelected ){
                                                        setSelectedProductIds(selectedProductIds.filter(x => x !== product.id));
                                                    }
                                                    else{
                                                        setSelectedProductIds([...selectedProductIds, product.id]);
                                                    }
                                                }
                                                else{
                                                    // todo:
                                                    // get list of products that apply
                                                    const applicableProducts = products.filter(p => p[aggAtt.name] === row.aggAttValue);
                                                    const applicableProductIds = applicableProducts.map(p => p.id);

                                                    // get applicable products not selected
                                                    const not_selected = applicableProducts.reduce((accum, p) => {
                                                        if(!selectedProductIds.includes(p.id))
                                                            return [...accum, p.id];
                                                        return accum;
                                                    }, [])

                                                    // are all applicable products selected?
                                                    const all_selected = not_selected?.length === 0;

                                                    if( all_selected ){
                                                        // deselect all
                                                        const newSelection = selectedProductIds.filter(id => !applicableProductIds.includes(id));
                                                        setSelectedProductIds(newSelection);
                                                    }
                                                    else{
                                                        // select all
                                                        const newSelection = selectedProductIds.filter(id => !applicableProductIds.includes(id));
                                                        setSelectedProductIds([...newSelection, ...applicableProductIds]);
                                                    }
                                                    

                                                }
                                            }}
                                        >
                                            <i className="fas fa-circle-check" />
                                        </span>}
                                        {row.label}
                                    </td>;
                                }
                                else if( col.type === "property" ){
                                    const att = col.property;
                                    return <td key={`${att.name}/${colIndex}`} className={`cell row-style ${br}`}>
                                        {row.isProduct &&
                                            <EditAttCell
                                                product={product}
                                                compProduct={compProduct}
                                                tabIndex={colIndex}
                                                att={att}
                                                hideLabel={true}
                                                onChange={(newValue) => {
                                                    const newState = {
                                                        ...state,
                                                        products: products.map(row => row.id === newValue.id ? newValue : row)
                                                    }
                                                    props.onChange?.(newState);
                                                }}
                                            />
                                        }
                                    </td>
                                }
                                else if( col.type === "result" ){
                                    const val = row?.[col.key];
                                    const valid = val !== null && val !== undefined;
                                    const valueFormatter = col.format ? format(col.format) : defaultValueFormatter;

                                    return <td key={`${col.key}/${colIndex}`} className={`cell row-style has-value ${needsCompute ? "stale" : ""} ${br}`}>
                                        {valid ? valueFormatter(val) : "."}
                                    </td>
                                }
                            })
                        }
                        
                    </tr>
                })}
            </tbody>
        </table>
    </div>


}

export const exportConfigAsRows = async (options) => {

    const atts = options.atts;
    const state = options.state;
    const products = state?.products;
    const { compare, metric } = options.exProps;
    const { showResults, results, compResults } = options.resultInfo;

    const { rows, cols, categoryCols } = GetRowsCols(options);

    let xcols = [];
    {

        // push categories
        // categoryCols.forEach((cat, i) => { 
        //     xcols.push({
        //         key: ccol.key ,
        //         label: cat.label?.startsWith("_") ? null : cat.label,
        //         style: {
        //             textAlign: "center"
        //         }
        //     })
        // }};

        cols.forEach(col => {
            xcols.push({ 
                key: col.key, 
                type: col.type === "result" ? "number" : "string",
                label: col.label, 
                category: col.category,
                //style,
                numFmt: col.format && getNumFmt(col.format)
            });
        })
        
    }

    let xrows = [];
    {rows?.map(r => {

        const product = r.isProduct ? products.find(p => p.id === r.id) : null

        let xrow = {};
        xrows.push(xrow);

        cols.forEach(col => {

            const val = r?.[col.key];
            const valid = val !== null && val !== undefined;
            //const valueFormatter = col.valueFormatter || defaultValueFormatter;
            xrow[col.key] = val;

            // return <td key={col.key} className={"cell row-style has-value"}>
            //     {valid ? valueFormatter(val) : "."}
            // </td>
        });
        
    })}
       
    
    const xoptions = {};
    const buffer = await tableToExcel(xrows, xcols, xoptions);
    const fileName = "export.xlsx";
    saveAs(
        new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        fileName
    )


}

export const ViewConfigAsTiles = (props) => {

    const atts = props.atts;
    const state = props.state;
    const products = state?.products;

    return <div className="view-config-as-tiles"  {...props.ui_atts}>
        {products?.map(product => {
            return <div className='tile'>
                <div className="product-label sticky-top">
                    <label>
                        <input type="checkbox" checked={product.available} onChange={(ev) => {
                            const newProduct = { ...product, available: !product.available };
                            const newState = {
                                ...state,
                                products: products?.map(p => p.id === product.id ? newProduct : p)
                            }
                            props.onChange?.(newState);
                        }} />
                        {product.label}
                    </label>
                </div>
                {atts.map((att) => {
                    return <div className="cell" >
                        <EditAttCell
                            //style={{ borderBottom: "1px solid #cfcfcf" }}
                            productValue={product}
                            att={att}
                            onChange={(newValue) => {
                                const newState = {
                                    ...state,
                                    products: products.map(row => row.id === newValue.id ? newValue : row)
                                }
                                props.onChange?.(newState);
                            }}
                        />
                    </div>
                })}
            </div>
        })}
    </div>

}

const safeDiff = (a, b) => {
    if( isNull(a) || isNull(b) ) return null;
    return b - a;
}

const safeIndex = (a, b) => {
    if( isNull(a) || isNull(b) ) return null;
    return (b / a) * 100.0;
}

const getNumFmt = (str) => {
    if( str === ".0f" ) return "0";
    if( str === ".1f" ) return "0.0";
    if( str === ".2f" ) return "0.00";
    if( str === ".3f" ) return "0.000";
    if( str === ".4f" ) return "0.0000";
    if( str === ".0%" ) return "0%";
    if( str === ".1%" ) return "0.0%";
    if( str === ".2%" ) return "0.00%";
    if( str === ".3%" ) return "0.000%";
    if( str === ".4%" ) return "0.0000%";
    if( str === ",.0f" ) return "#,##0";
    if( str === ",.1f" ) return "#,##0.0";
    if( str === ",.2f" ) return "#,##0.00";
    if( str === ",.3f" ) return "#,##0.000";
    if( str === ",.4f" ) return "#,##0.0000";
    if( str === "$.0f" ) return "$0";
    if( str === "$.1f" ) return "$0.0";
    if( str === "$.2f" ) return "$0.00";
    if( str === "$.3f" ) return "$0.000";
    if( str === "$.4f" ) return "$0.0000";
    if( str === "$,.0f" ) return "$#,##0";
    if( str === "$,.1f" ) return "$#,##0.0";
    if( str === "$,.2f" ) return "$#,##0.00";
    if( str === "$,.3f" ) return "$#,##0.000";
    if( str === "$,.4f" ) return "$#,##0.0000";
    return null;
}



export const EditColumnProp = (props) => {
    const col = props.col;
    const attType = col.property?.ui;
    switch( attType ){
        case "contin" : return <EditContinColumnProp {...props} />;
        case "range" : return <EditContinColumnProp {...props} />;
        case "select" : return <EditSelectColumnProp {...props} />;
    }
    return null;
}


const debounce1000 = debounce((callback, arg1) => {
    callback(arg1);
}, 1000);


export const EditContinColumnProp = (props) => {

    const [dVal, setDVal] = useState(null);
        
    const { col, products, compProducts, selectedProductIds, state, onChange } = props;
    const att = col.property;

    const [adjustmentType, setAdjustmentType] = useState("%");
    
    const toggleAdjustmentType = () => {
        const newAdjustmentType = adjustmentType === "%" ? "#" : "%";
        setAdjustmentType(newAdjustmentType);
    }

    const adjust = (amount) => {
        const newDVal = Math.round((dVal || 0) + amount);
        setDVal(newDVal);
        debounce1000(saveDVal, newDVal + "");
    }

    const reset = () => {
        const newState = {
            ...state,
            products: state.products.map(p => {
                if( selectedProductIds.includes(p.id) ){
                    const cp = compProducts.find(cp => cp.id === p.id);
                    return {
                        ...p,
                        [att.name]: cp[att.name]
                    }
                }
                else{ return p; }
            })
        }
        onChange?.(newState);
    }

    
    //const round = (v) => Math.round(v * 1000000) / 1000000.0;
    const round = (v) => Math.round(v * 100) / 100.0;

    const saveDVal = (dval_str) => {
        try{
            const dval = parseFloat(dval_str);
            if(isNaN(dval)) return;

            const newProducts = products.map(p => {
                if( selectedProductIds?.includes(p.id) ){
                    const compP = compProducts.find(cp => cp.id === p.id);
                    const compVal = compP?.[att.name];
                    let pVal = adjustmentType === "%" ? round(compVal * (1.0 + (dval / 100.0))) : round(compVal + dval);

                    return {
                        ...p,
                        [att.name]: pVal
                    }
                }
                else{
                    return p;
                }
            })
            onChange?.({
                ...state,
                products: newProducts
            });
        }
        catch{}
    }



    // calculate difference from base for selected products, show it can be shown in adjuster
    const get_dval = () => {
        const distinct_dvals = [];
        selectedProductIds.forEach(id => {
            const val = products.find(p => p.id === id)?.[att.name];
            const compVal = compProducts.find(p => p.id === id)?.[att.name];
            if( isNull(val) || isNull(compVal) ){
                // skip
            }
            else{
                const diff_amount = val - compVal;
                const diff_percent = compVal === 0 ? null : diff_amount / compVal;
                const dval = adjustmentType === "%" ? round(diff_percent * 100.0) : round(diff_amount);
                if( !distinct_dvals.includes(dval) ){
                    distinct_dvals.push(dval);
                }
            }
        })
        return distinct_dvals?.length === 1 ? distinct_dvals[0] : null;
    }

    

    useEffect(() => {
        const dval =  get_dval();
        setDVal(dval);
    }, [products, col, selectedProductIds])

    

    return <div className='edit-contin-column'>
        <span className="tb">
            <button className="btn-adjustment-type"
                onClick={() => toggleAdjustmentType()}
            >
                {adjustmentType}
            </button>
            <input 
                type="text" 
                value={dVal === null ? "" : dVal}
                onChange={ev => {
                    setDVal(ev.target.value);
                    debounce1000(saveDVal, ev.target.value);
                    //debounced_set_uniform_dval(ev.target.value)
                }}
                style={{ width: 50, border: 0, padding: "2px 5px" }}
            />
            <span className="adjuster">
                <button className="btn-adjust" onClick={() => adjust(1)}>
                    <i className="fal fa-chevron-up" />
                </button>
                <button className="btn-adjust" onClick={() => adjust(-1)}>
                    <i className="fas fa-chevron-down" />
                </button>        
            </span>
            <button className="btn-reset" onClick={() => reset()}>
                <i className="fal fa-times" />
            </button>
        </span>
        {/* <button className="btn-adjust">
            <i className="fas fa-minus-circle" />
        </button>
        <button className="btn-adjust">
            <i className="fas fa-plus-circle" />
        </button> */}
        {/* <button className="btn-adjust">
            <i className="fal fa-circle-x" style={{ fontSize: "7pt", margin: 3 }} />
        </button> */}
    </div>
}

export const EditSelectColumnProp = (props) => {
    const { col, products, selectedProductIds } = props;
    return <span></span>
}

export const ResultAgg = (props) => {
    const { col, results, compResults, selectedProductIds } = props;

    let val = null;
    

    if( col.key === "share_scenario" ){
        val = selectedProductIds.reduce((accum, id) => {
            const rr = results?.find(row => row.id === id);
            return !isNull(rr?.share) ? (accum || 0) + rr.share : accum;
        }, null)
    }
    else if( col.key === "share_base" ){
        val = selectedProductIds.reduce((accum, id) => {
            const rr = compResults?.find(row => row.id === id);
            return !isNull(rr?.share) ? (accum || 0) + rr.share : accum;
        }, null)
    }
    else{
        val = undefined;
    }

    if( val === undefined ) return null;

    const valid = val !== null && val !== undefined;
    const valueFormatter = col.format ? format(col.format) : defaultValueFormatter;
    return <div style={{
        textAlign: "right",
        paddingRight: 20,
        fontWeight: 500
    }}>
        {valid ? valueFormatter(val) : "."}
    </div>
}