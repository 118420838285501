import React, { useContext, useEffect, useState } from 'react';
import Popup from "reactjs-popup";
import { EditDataSourceContext } from "../../context/Contexts";
import { useDataSourceApi, useDataSourcesApi } from "../../hooks/useDataSource";
import { useDataSourceRevision } from "../../hooks/useDataSourceRevision";
import { ControlledCodeEditorFillContainer } from "../code-editor/CodeEditor";
import { DataSourceRevisionSelector } from "./DataSourceRevisionSelector";
import { PatchFrame } from "./PatchFrame";
import "./ViewUnstructuredContent.scss";


export const ViewUnstructuredContent = (props) => {

    const dsContext = useContext(EditDataSourceContext);
    const ds = dsContext?.ds;
    const api = useDataSourceApi(ds?.id);
    const dssApi = useDataSourcesApi();
    
    const [code, setCode] = useState(null);
    const [dirty, setDirty] = useState(false);
    const [saving, setSaving] = useState(false);
    const [rev, revLoading, revError, revRefresh] = useDataSourceRevision(ds?.id, dsContext.selectedRevisionId);
    const reload = null;

    useEffect(() => {
        setCode(rev?.udata);
    }, [rev])

    const modalSaved = async (data) => {
        console.log("modalSaved");
        if( data.method === "patch" ){
            setSaving(true);
            await api.patchRevision(
                rev?.id, 
                { 
                    udata: code, 
                    notes: data.notes
                }
            );
            setSaving(false);
            setDirty(false);
        }
        else{
            // todo: save new
            setSaving(true);
            const newRev = {
                notes: data.notes,
                udata: code
            };
            console.log("newRev", newRev);
            const rr = await api.createRevision(newRev);
            console.log("rr", rr);

            if( data.makeDev && rr?.newId ){
                await dssApi.patchItem(ds.id, {
                    dev_revision: rr.newId
                })
            }
            props.reloadRevisions?.();
            setSaving(false);
            setDirty(false);
            
        }
        
    }
    
    return <div className="view-semistructured-content">
        <div className="heading-pane">
            <DataSourceRevisionSelector width={400}/> 

            <span style={{ display: "inline-block", paddingTop: 15, marginLeft: 5 }}>
                
                <Popup
                    contentStyle={{
                        width: 500
                    }}
                    closeOnDocumentClick={false}
                    closeOnEscape={false}
                    trigger={
                        <span className={`btn action-light bg2 margin-right ${(saving || !dirty) ? "disabled" : ""}`}
                        >
                            {/* <i className="fal fa-save" /> &nbsp; */}
                            Save...
                        </span>
                    }
                    modal
                >
                    {close => 
                        <PatchFrame ds={ds} rev={rev} close={close} tab="new"
                            onSave={(data) => modalSaved(data)}
                        />
                    }
                </Popup>
                {saving && <span>Saving...</span>}

            </span>

        </div>
        <div className="code-section">
            <ControlledCodeEditorFillContainer
                message={revLoading ? "Loading..." : saving ? "Saving..." : null}
                language={
                    ds?.fileType === "xml" ? "xml" :
                    ds?.fileType === "json" ? "json" : "txt"}
                value={code}
                onChange={str => {
                    setDirty(true);
                    setCode(str);
                }}
            />
        </div>
    </div>
    
    
}


