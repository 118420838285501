
const docsRoot = "https://docs.metricstudios.com";


export const docLinks = {

    home: `${docsRoot}`,

    aggregationPipeline: `${docsRoot}/docs/analytics/aggregation-pipeline/`,
    stages: `${docsRoot}/docs/analytics/aggregation-pipeline/stages/`,

    stage_addFields: `${docsRoot}/docs/analytics/aggregation-pipeline/stages/add-fields`,
    stage_addWindowFields: `${docsRoot}/docs/analytics/aggregation-pipeline/stages/add-window-fields`,
    stage_aggregate: `${docsRoot}/docs/analytics/aggregation-pipeline/stages/aggregate/`,
    stage_filter: `${docsRoot}/docs/analytics/aggregation-pipeline/stages/filter`,
    stage_limit: `${docsRoot}/docs/analytics/aggregation-pipeline/stages/limit`,
    stage_sample: `${docsRoot}/docs/analytics/aggregation-pipeline/stages/sample`,
    stage_select: `${docsRoot}/docs/analytics/aggregation-pipeline/stages/select`,
    stage_skip: `${docsRoot}/docs/analytics/aggregation-pipeline/stages/skip`,
    stage_sort: `${docsRoot}/docs/analytics/aggregation-pipeline/stages/sort`,

    project: `${docsRoot}/docs/cloud/project/`,
    data_management: `${docsRoot}/docs/cloud/data-management/`,
    reports: `${docsRoot}/docs/cloud/report/`,
    collections: `${docsRoot}/docs/cloud/collections/`,
    web_content: `${docsRoot}/docs/cloud/ui-content/`,
    user_management: `${docsRoot}/docs/cloud/account-management/`,

    cloud_getting_started: `${docsRoot}/docs/category/getting-started`,

    analytics: `${docsRoot}/docs/analytics/`,
    visualization: `${docsRoot}/docs/visualization/`

}