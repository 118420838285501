import Popup from "reactjs-popup"
import { useSelection, useSelectionState } from "../../hooks/useSelection"
import { EditContentItemWrapper } from "../edit/EditContentItemWrapper"
import { SelectCollectionEntryDlg } from "./SelectCollectionEntryDlg"
import "./SelectCollectionEntryInput.scss"

export const SelectCollectionEntryInput = (props) => {

    const item = props.content_item;
    const [state, stateLoading, stateError] = useSelectionState(item.selectionId);
        
    const selectionId = item.selectionId; // selection?.label;
    let label = state?.label || "[Select Entry]";
    
    const style1 = {
        ...item.style
    };
    
    const content =
        stateLoading ? <div className="message h-centered no-pad">Loading selection...</div> :
            stateError ? <div className="message h-centered error no-pad">Error loading selection...</div> :
                <Popup
                    modal
                    trigger={<div className="select-coll-entry-input" style={style1}>
                        {label}
                    </div>}
                >
                    {close => <SelectCollectionEntryDlg id={item?.filterBookId} selectionId={selectionId} close={close} />}
                </Popup>;

    return <EditContentItemWrapper {...props} actionsPosition="above">
        {content}
    </EditContentItemWrapper>
    
}