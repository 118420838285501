import React, { useContext, useState } from "react";
import { ContentItemContext } from "../../../context/Contexts";
import { useImage } from "../../../hooks/useImage";
import { useProjectId } from "../../../hooks/useProjectId";
import { useResources } from "../../../hooks/useResources";
import { useShallowCompareEffect } from "../../../hooks/useShallowCompareEffect";
import { compileReact, runCompute, runRender } from "../../../utils/ContentProcessor";
import { getImagePath } from "../../../utils/ImageUtils";
import { defaultLib } from "../../../utils/Lib";
import { EditContentItemWrapper } from "../../edit/EditContentItemWrapper";
import { ErrorBoundary } from "../../error-boundary/ErrorBoundary";
import "./ViewReactCard.scss";

const Test1 = (props) => <div>test1</div>;

export const ViewReactCard = (props) => {

    const {project_id, subscription_id} = useProjectId();
    const item = props.content_item;
    const context = useContext(ContentItemContext);

    const [backgroundImage] = useImage(item?.background?.imageId);
    const backgroundImagePath = backgroundImage ? getImagePath(subscription_id, project_id, backgroundImage?.filename) : null;

    const [resources, resourcesLoading, resourcesError] = useResources(item?.resources, true);
    const allResourcesReady = item && (
        (resources && !resourcesLoading) || !item?.resources?.length
    );
    const resourcesEqualityHash = allResourcesReady ? resources?.equalityHash : null;

    const api = {};
    const lib = defaultLib;

    const [data, setData] = useState(null);
    const [dataError, setDataError] = useState(null);
    const [rendered, setRendered] = useState(null);
    const [renderError, setRenderError] = useState(null);

    // RENDER
    useShallowCompareEffect(() => {
        
        if( !allResourcesReady ) return;
        if (!item?.render) return;

        try {
            const r = compileReact(item.render, data, context, resources, api, lib);
            setRendered(() => r); // workaround to store a function in useState
        }
        catch (err) {
            setRenderError(err.message);
            console.error(err);
        }

    }, [subscription_id, project_id, item?.render, data, allResourcesReady, resources, context])//, resourcesEqualityHash, data])

    const wrapMessage = (message) => <div>{message}</div>

    if (!allResourcesReady) return wrapMessage("Loading resources...");
    //if (resourcesError) return wrapMessage("Error loading resources");

    if (!item) return wrapMessage("Item missing");


    // todo: execute the compute function
        // I think this goes before the data binding, allowing data binding to the output??
        // I'm not sure. I would need to know to were exactly to pipe the output
        // Besides, binding can be dynamic functions themselves, so maybe screw this.
        // It just needs to be available in render.
        // compute is just something special for JsxCard. Not everything. 
        // Mabye it could have a checkbox[x] pipe output into context pre - binding, but i doubt it
        // there should be a way to put stuff in context beforehand ??
    
    // todo: apply all the data binding in item
        // allow context binding
        // allow output binding?
        // don't do it this way:
    //const text = getPropertyValue(item?.content, context, {});

    // instead, do it something like this:
        // const binders = findDataBinding(item);
        // const [resources2, ...] = useResources(binders.getRequiresResources());
        // const item2 = binders.apply(context, { ...resources, ...resources2 }, ...)
        // const text = item2.content;

    // do i require the item to load resources first, before allowing binding to a construct?
        // e.g., in order to bind to a palette or collection or style, it must be a resource
        // would make it a little easier (faster) for an inspector to find all content_items that use a certain resource
        // would make it easier to swap out stuff if say multiple props pointed to a single resource
        // *MAKES binding more powerful* because a function could refer to a resource in a way that a parser might find hard to interpret
    
    
    //const r = data ? JSON.stringify(data, null, 2) : null;
    // const r = <div>
    //     <div>
    //         data:
    //     </div>
    //     <pre>{JSON.stringify(data, null, 2)}</pre>
    //     <div>
    //         item:
    //     </div>
    //     <pre>{JSON.stringify(item, null, 2)}</pre>
    //     <div>
    //         resources:
    //     </div>
    //     <div>
    //         <pre>{JSON.stringify(resources, null, 2)}</pre>
    //     </div>
    // </div>;
    //const Rendered = rendered;

    let tileStyle = {
        // position: "absolute",
        // height: "100%",
        // width: "100%",

        // border
        borderStyle: item?.border?.style,
        borderWidth: item?.border?.width + "px",
        borderColor: item?.border?.color,

        // background
        backgroundAttachment: item?.background?.attachment,
        backgroundBlendMode: item?.background?.blendMode,
        backgroundClip: item?.background?.clip,
        backgroundColor: item?.background?.color,
        //backgroundImage: item?.background?.image,
        backgroundOrigin: item?.background?.origin,
        backgroundPosition: item?.background?.position,
        backgroundRepeat: item?.background?.repeat,
        backgroundSize: item?.background?.size,

    }

    if (item?.background?.imageType === "url") {
        tileStyle.backgroundImage = `url("${backgroundImagePath}")`
    }
    else if (item?.background?.imageType === "none") {
    }


    const Rendered = rendered;
    
    return props.edit ?
        <EditContentItemWrapper {...props}>
            <div className='view-react' style={tileStyle}>
                <ErrorBoundary>{Rendered ? <Rendered/> : null}</ErrorBoundary>
            </div>
        </EditContentItemWrapper> :
        <div className="view-react" style={tileStyle}>
            <ErrorBoundary>{Rendered ? <Rendered/> : null}</ErrorBoundary>
        </div>
        ;
        
}


