import { useState } from "react";
import Popup from "reactjs-popup";
import { useContentItem } from "../../../hooks/useContentItem";
import "./EditCRSyntax.scss";
import { EditCRSyntaxBox } from "./EditCRSyntaxBox";
import { EditCRSyntaxBox2 } from "./EditCRSyntaxBox2";


export const EditCRSyntax = (props) => {

    const item = props.item;
    if( !item ) return null;

    return <div>
        <Popup
            modal
            closeOnEscape={false}
            closeOnDocumentClick={false}
            trigger={
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        border: "1px solid #cfcfcf",
                        width: "100%",
                        padding: "5px 10px",
                        userSelect: "none"
                    }}
                >
                    {props.placeholder || "[syntax]"}
                    <span style={{ float: "right" }}>
                        <i className='fal fa-ellipsis-h'/>
                    </span>
                </div>
            }
        >
            {closeModal => 
                <EditCRSyntaxBox
                    title={"Syntax"}
                    compute={item.compute}
                    render={item.render}
                    renderTable={item.renderTable}
                    resources={props.resources}
                    onChange={props.onChange}
                    close={closeModal}
                />}
        </Popup>
    </div>


}

export const EditCRSyntaxLite = (props) => {

    const item = props.item;
    if (!item) return null;
    
    return <Popup
            modal
            closeOnEscape={false}
            closeOnDocumentClick={false}
            trigger={props.trigger}
        >
            {closeModal => 
                <EditCRSyntaxBox
                    title={"Syntax"}
                    compute={item.compute}
                    render={item.render}
                    renderTable={item.renderTable}
                    resources={props.resources}
                    onChange={props.onChange}
                    close={closeModal}
                />}
        </Popup>


}

export const EditCRSyntaxV2Wrapper = (props) => {
    const id = props.id;
    const [item] = useContentItem(id);
    return <EditCRSyntaxV2 item={item} />;
}

export const EditCRSyntaxV2 = (props) => {

    const [popupExpanded, setPopupExpanded] = useState(false);

    const item = props.item;
    if( !item ) return null;

    return <div>
        <Popup
            modal
            closeOnEscape={false}
            closeOnDocumentClick={false}
            contentStyle={{
                width: "100%",
                height: "100%",
                background: "transparent"
            }}
            trigger={
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        border: "1px solid #cfcfcf",
                        width: "100%",
                        padding: "5px 10px",
                        userSelect: "none"
                    }}
                >
                    {props.placeholder || "[syntax]"}
                    <span style={{ float: "right" }}>
                        <i className='fal fa-ellipsis-h'/>
                    </span>
                </div>
            }
        >
            {closeModal => 
                <EditCRSyntaxBox2
                    title={"Syntax"}
                    compute={item.compute}
                    pipelines={item.pipelines}
                    render={item.render}
                    renderTable={item.renderTable}
                    resources={props.resources}
                    onChange={props.onChange}
                    close={closeModal}
                />}
        </Popup>
    </div>


}


export const EditCRSyntaxLiteV2 = (props) => {

    const item = props.item;
    if (!item) return null;
    
    return <Popup
            modal
            closeOnEscape={false}
            closeOnDocumentClick={false}
            trigger={props.trigger}
        >
            {closeModal => 
                <EditCRSyntaxBox2
                    title={"Syntax"}
                    compute={item.compute}
                    pipelines={item.pipelines}
                    render={item.render}
                    renderTable={item.renderTable}
                    resources={props.resources}
                    onChange={props.onChange}
                    close={closeModal}
                />}
        </Popup>


}
