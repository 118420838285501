import { constructs } from "../../../api/contructs";
import { getHooksForType } from "../../../hooks/hooksHelper";
import "./GenericResourceInput.scss";

const getIcon = (resourceType) => {
    const c = constructs[resourceType];
    return <i className={`fas ${c.icon}`} />;
}


export const GenericResourceInputList = (props) => {

    const selectedId = props.selectedId;
    const type = props.type;

    const hooks = getHooksForType(type);
    const useItems = hooks?.[1];

    const [items, itemsLoading, itemsError] = useItems();


    return <div className='generic-resource-input-list'>
        {itemsLoading ? <div className='message'>Loading...</div> : null}
        {itemsError ? <div className='message error'>Error loading items</div> : null}
        {items?.map(item =>
            <div
                key={item.id}
                className={`entry ${selectedId === item.id ? "sel" : ""}`}
                onClick={() => props.onChange?.(item.id)}
            >
                <span className="icon">
                    {getIcon(type)}
                </span>
                <span className="label">
                    {item.label}
                </span>
            </div>
        )}
    </div>

}