import { useContext, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import Popup from "reactjs-popup";
import { ControlledCodeEditorFillContainer } from "../../code-editor/CodeEditor";
import { ModalFrame } from "../../modal-dialog/ModalFrame";
import "./EditSyntax.scss";
import { ContentItemContext } from "../../../context/Contexts";
import { runCompute, transpileJs } from "../../../utils/ContentProcessor";
import { defaultLib } from "../../../utils/Lib";

const TABS = [
    { key: "output", label: "Output" },
    { key: "context", label: "Context" },
    { key: "resources", label: "Resources" },
    { key: "api", label: "API" },
    { key: "lib", label: "Library" },
]

const isMissing = (x) => x === null || x === undefined;
const sortCellsAsc = (a, b) => // todo!
    isMissing(a?.value) && isMissing(b?.value) ? 0 :
        isMissing(a?.value) ? -1 :
            isMissing(b?.value) ? 1 :
                (a.value - b.value);

const defaultApi = {
    isMissing,
    sortCellsAsc,
    sortCellsDesc: (a, b) => sortCellsAsc(a, b) * -1,
    rankCellsAsc: {},
    rankCellsDesc: {},
    computeGap: {}
}

const defaultApiHack = Object.keys(defaultApi).reduce(
    (accum, key) => {
        accum[key] = "(function)"
        return accum;
    },
    {}
)

const getDefaultLibHack = () => Object.keys(defaultLib).reduce(
    (accum, key) => {
        accum[key] = "(...)"
        return accum;
    },
    {}
)


export const EditSyntax = (props) => {

    const [value, setValue] = useState(props.value);
    const [selTab, setSelTab] = useState("output");
    const [testResult, setTestResult] = useState("(not run yet)");
    const [testRunning, setTestRunning] = useState(false);

    const context = useContext(ContentItemContext);
    const defaultLibHack = getDefaultLibHack();
    
    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const test = async () => {

        try {
            setTestRunning(true);
            const api = {};
            const lib = defaultLib;
            const r = await runCompute(value, context, props.resources, api, lib)
            setTestResult(r);
            setTestRunning(false);
        }
        catch (err) {
            setTestResult({ error: err.message });
            setTestRunning(false);
        }


    }

    return <div>
        <Popup
            modal
            closeOnEscape={false}
            closeOnDocumentClick={false}
            trigger={
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        border: "1px solid #cfcfcf",
                        width: "100%",
                        padding: "5px 10px",
                        userSelect: "none"
                    }}
                >
                    {props.placeholder || "[syntax]"}
                    <span style={{ float: "right" }}>
                        <i className='fal fa-ellipsis-h'/>
                    </span>
                </div>
            }
        >
            {closeModal =>
                <ModalFrame
                    title={props.title || "Syntax"}
                    caption={props.caption || "async (context, resources, api, lib) => { <your syntax> }"}
                    close={closeModal}
                    style={{ width: 1200, height: 600 }}
                    contentStyle={{ padding: 0 }}
                    buttons={[
                        {
                            label: "Save Changes",
                            callback: () => {
                                props.onChange?.(value);
                            }
                        }
                    ]}
                >
                    <div style={{ width: "100%", height: "100%" }} className="edit-syntax full-dock">
                        <div className="right" style={{ width: 450 }}>
                            <div className="tab-section">
                                {TABS.map(tab =>
                                    <div
                                        key={tab.key}
                                        className={`tab ${selTab === tab.key ? "sel" : ""}`}
                                        onClick={() => setSelTab(tab.key)}
                                    >
                                        {tab.label}
                                    </div>
                                )}
                            </div>
                            <div style={{
                                padding: "0 20px 20px 20px",
                                width: "100%",
                                height: "435px",
                                overflowY: "auto",
                                //border: '2px dashed purple'
                            }}>
                                {selTab === "context" ? 
                                    <ReactJson src={context}
                                        name={"context"}
                                        theme={"rjv-default"}
                                        iconStyle="circle"
                                        displayDataTypes={false}
                                        enableClipboard={true}
                                        collapsed={1}
                                    />
                                : selTab === "resources" ? 
                                    <ReactJson src={props.resources}
                                        name={"resources"}
                                        theme={"rjv-default"}
                                        iconStyle="circle"
                                        displayDataTypes={false}
                                        enableClipboard={true}
                                        collapsed={1}
                                        />
                                : selTab === "api" ? 
                                    <ReactJson src={props.api || defaultApiHack}
                                        name={"api"}
                                        theme={"rjv-default"}
                                        iconStyle="circle"
                                        displayDataTypes={false}
                                        enableClipboard={true}
                                        collapsed={1}
                                    />
                                : selTab === "lib" ?
                                    <ReactJson
                                        src={defaultLibHack}
                                        name={"lib"}
                                        theme={"rjv-default"}
                                        iconStyle="circle"
                                        displayDataTypes={false}
                                        enableClipboard={true}
                                        />
                                : selTab === "output" ? <div>
                                    <div style={{ marginBottom: 10 }}>
                                        <span className="action btn" onClick={test}>Test</span>
                                    </div>
                                    <div>
                                        {testRunning ? "Running test..." : null}
                                        <ReactJson
                                            src={{ testResult }}
                                            name={null}
                                            theme={"rjv-default"}
                                            iconStyle="circle"
                                            displayDataTypes={false}
                                            enableClipboard={true}
                                            />
                                    </div>
                                </div>
                                : null
                                }
                            </div>
                        </div>
                        <div className="center" style={{ borderRight: "1px solid #cfcfcf" }}>
                            <ControlledCodeEditorFillContainer
                                value={value}
                                onChange={async (newSyntax) => {
                                    setValue(newSyntax)
                                    // note: doesn't notify consumer until 'save' is clicked
                                }}
                            />
                        </div>
                    </div>
                </ModalFrame>
            }
        
        </Popup>
    </div>


}