import { useContext } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { a } from "react-spring";
import Popup from "reactjs-popup";
import { AuthContext } from "../../context/Contexts";
import { useTeamsApi } from "../../hooks/useTeamsApi";
import { useTeamUsers } from "../../hooks/useTeamUsers";
import { SelectUserWin } from "./SelectUserWin";

const getMatches = (searchText, items) => {
    let text = searchText.toLocaleLowerCase();
    let textArr = text.split(" ");
    return items.filter(item => {
        let itemText = item.name?.toLocaleLowerCase() + " " + item.email?.toLocaleLowerCase();
        for( let t of textArr ){
            if(!itemText.includes(t)) return false;
        }
        return true;
    })
}

export const TeamUsersBlock = (props) => {

    const team_id = props.teamId;
       
    const authContext = useContext(AuthContext);
    const [users, usersLoading, usersError, refreshUsers] = useTeamUsers(team_id);
    const [expandedUserIds, setExpandedUserIds] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const api = useTeamsApi();

    let displayUsers = users;
    
    const isSuperAdmin = authContext?.authUser?.isSuperAdmin;

    if( searchText ){
        displayUsers = getMatches(searchText, users);
    }

    const userClicked = (user) => {
        props.userClicked?.(user);
    }

    const addUser = async (user) => {
        await api.addUser(team_id, user?.id);
        refreshUsers?.();
    }

    const removeUser = async (user) => {
        await api.removeUser(team_id, user?.id);
        refreshUsers?.();
    }

    return <div className="recent-block" style={{ marginTop: 10 }}>
        
        <div className="title">
            <span className="label search">
                <input type="text" placeholder="&#xF002; Find a user" 
                    value={searchText || ""}
                    onChange={(ev) => setSearchText(ev.target.value)}
                />
            </span>
            <Popup
                position={"bottom right"}
                contentStyle={{ width: 400 }}
                trigger={
                    <span className="btn action smaller new" style={{ marginLeft: 10 }}>
                        Add
                    </span>
                }
                >
                {close => 
                    <SelectUserWin 
                        excludeUserIds={users?.map?.(u => u.id)}
                        close={close} 
                        selectUser={(user) => {
                            addUser(user);
                        }}
                    />
                }
            </Popup>
        </div>
        <div className="item-list">
            {usersLoading ? <div className="item loading">
                Loading...
            </div> :
            <>
                {displayUsers?.map?.(item =>
                    <div key={item.id} className="item2" onClick={() => userClicked(item)}>
                        <div className="icon">
                            <i className={item.is_admin ? "fas fa-shield-halved admin" : "fas fa-user-circle"} />
                        </div>
                        <div className="ilabel">
                            {item.name}
                        </div>
                        <div className="plabel">
                            {item.email}
                        </div>
                        <div className="del" onClick={() => removeUser(item)}>
                            <i className="fal fa-times" />
                        </div>
                    </div>
                )}
            </>
            }
            { !team_id && !usersLoading && !usersError ? "Select a team to view its members" : null}
            {
                team_id && !usersLoading && !usersError && users && users.length === 0 && <>
                    {isSuperAdmin ? 
                        "This team is empty." :
                        "This team is empty or requires administrator privileges to view members."}
                    
                </>
            }
        </div>
    </div>;
    

}