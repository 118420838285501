import React, { useEffect, useState } from "react";
import "./SavePipelineWin.scss";
import { useProjectId } from "../../hooks/useProjectId";
import { useSavedPipelines, useSavedPipelinesApi } from "../../hooks/usePipeline";
import { toast } from "react-toastify";
import moment from "moment";

export const SavePipelineWinStyle = {
    width: 800,
    maxWidth: "100%",
    height: 500,
    maxHeight: "100%"
}


export const SavePipelineWin = (props) => {

    const { subscription_id, project_id } = useProjectId();
    const [filename, setFilename] = useState(null);
    const [pipelines, pipelines_loading, pipelines_error] = useSavedPipelines();
    const api = useSavedPipelinesApi();
    

    const arr = Array.isArray(pipelines) ? pipelines : null;

    const close = () => {
        props?.close();
    }

    const save = async (overwrite) => {
        toast.promise(
            async () => {
                const item = {
                    label: filename,
                    jdata: props.data,
                    overwrite
                }

                try{
                    await api.newItem(item);
                    props.saved?.(filename);
                }
                catch(err){
                    if( err.message === "Already exists" ){
                        const c = window.confirm("Pipeline already exists. Overwrite?");
                        if( c ){
                            //save(true);
                            await api.newItem({ ...item, overwrite: true });
                            props.saved?.(filename);
                        }
                        else{
                            throw new Error("Not saved");
                        }
                    }
                }
                props.close?.();
            },
            {
                pending: "Saving...",
                error: "Error saving",
                success: null
            },
            {
                //autoClose: 1000
            }
        )
        
        
    }

    return <div className="save-pipeline-win">
        <div className="header">
            <div className="title">
                Save Pipeline
            </div>
        </div>
        <div className="main">

            <div className="list">
                <table className="table-filenames">
                    <thead>
                        <tr>
                            <th className="label">Label</th>
                            <th className="modified">Last modified</th>
                        </tr>
                    </thead>
                    <tbody>
                        {arr?.map(el =>
                            <tr key={el.id} className={`item`} onClick={ev => setFilename(el.label)}>
                                {/* <td>{el.id}</td> */}
                                <td className="label">{el.label}</td>
                                <td className="modified">{moment.utc(el.modified).local().fromNow()}</td>
                            </tr>
                        )}                        
                    </tbody>
                </table>

                {/* {arr?.map(el =>
                    <div className="item" onClick={ev => setFilename(el.label)}>
                        [{el.id}] {el.label}
                    </div>
                )} */}
            </div>
            <div className="filename">
                <input 
                    type="text" 
                    className="tb-filename"
                    value={filename || ""}
                    onChange={ev => setFilename(ev.target.value)} 
                />
            </div>

        </div>
        <div className="footer">
            <span className="btn toggle xaction-light" onClick={() => save()}>
                Save
            </span>
            <span className="btn toggle" onClick={() => close()}>
                Cancel
            </span>
        </div>
    </div>
}