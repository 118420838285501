import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { useDataSource, useDataSourceApi, useDataSourcesApi } from "../../hooks/useDataSource";
import { DataTable } from "../common/DataTable";
import { UploadDataFileModal } from "./UploadDataFileModal";
import "./EditDataSource.scss";
//import { EditPipeline } from "../aggregation/EditPipeline";
import { EditPipeline } from "../pipeline/EditPipeline";
import { useParams } from "react-router-dom";
import { EditETL } from "./EditETL";
import { DataSourceRevisions } from "./DataSourceRevisions";
import { TextFrame } from "../modal-dialog/VerifyFrame";
import { ViewFields } from "./ViewFields";
import { ViewRevisionInfo } from "./ViewRevisionInfo";
import { DataSourceContext, EditDataSourceContext, HasPipelineContext } from "../../context/Contexts";
import { useDataSourceRevisions } from "../../hooks/useDataSourceRevision";
import { useQuery } from "../../hooks/useQuery";
import { useLocation, useHistory } from "react-router-dom";
import { ViewUnstructuredContent } from "./ViewUnstructuredContent";
import { EditPipelineStages } from "../pipeline/EditPipelineStages";
import { EditPipelineWithPreview } from "../pipeline/EditPipelineWithPreview";


const TABS_FILE = [
    { key: "revs", label: "Revisions" },
    { key: "fields", label: "Fields", structured: true },
    { key: "content", label: "Content", semistructured: true },
    { key: "scratch", label: "Data", structured: true },

    { key: "dependents", label: "Dependents" },
    { key: "test", label: "Health Tests" },
    { key: "info", label: "Debug Info" },
]

const TABS_FLOW = [
    { key: "revs", label: "Revisions" },
    { key: "etl", label: "Data Flow" },
    { key: "fields", label: "Fields" },
    { key: "scratch", label: "Data" },

    { key: "dependents", label: "Dependents" },
    { key: "test", label: "Test" },
    { key: "info", label: "Debug Info" },
]

export const EditDataSource = (props) => {

    const id = props.id;
    const [ds, dsLoading, dsError, dsTriggerRefresh] = useDataSource(id);
    const [revisions, revsLoading, revsError, revsTriggerReload] = useDataSourceRevisions(id);
    const [selectedRevisionId, setSelectedRevisionId] = useState(null);

    const [pipeline1, setPipeline1] = useState(null);
    const p1Context = {
        pipeline: pipeline1,
        setPipeline: setPipeline1
    }

    //const [fields, setFields] = useState(null);
    //const [preview, setPreview] = useState(null);
    //const [dataLoading, setDataLoading] = useState(false);
    const query = useQuery();
    const history = useHistory();
    const loc = useLocation();
    const selTab = query.get('tab');



    useEffect(() => {
        if( !ds ) return;
        setSelectedRevisionId(ds.dev_revision);

        //dataSourceId
        setPipeline1({ ...pipeline1, dataSourceId: ds?.id });

    }, [ds])
    
    const TABS = ds?.type === "flow" ? TABS_FLOW : TABS_FILE;
    const defaultTab = ds?.type === "flow" ? "etl" :
        (ds?.type === "file" && ds?.structured) ? "fields" :
        (ds?.type === "file" && !ds?.structured) ? "content" :
        TABS[0];

    const selTab2 = selTab || defaultTab;

    const routerParams = useParams();
    

    
    const ids = {
        subscription_id: routerParams.subscription_id,
        project_id: routerParams.project_id,
        data_source_id: id,
    }

    // const fields2 = fields?.map(f => ({
    //     ...f,
    //     label: f.name,
    //     //type: "number",
    //     //valueFormat: ".6f"
    // }))
    
    const api1 = useDataSourcesApi(id);
    // useEffect(() => {
    //     if (!id) return;
    //     (async () => {
    //         setDataLoading(true);
    //         try {
    //             const fields = await api.getFields();
    //             const records = await api.getPreview(fields, 50);
    //             setFields(fields);
    //             setPreview(records);
    //             setDataLoading(false);
    //         }
    //         catch (err) {
    //             console.error(err);
    //             setDataLoading(false);
    //         }
    //     })();
    // }, [id]);

    const contextValue = {
        ds,
        dsLoading,
        dsError,
        dsTriggerRefresh,
        revisions,
        revsLoading,
        revsError,
        revsTriggerReload,
        selectedRevisionId,
        setSelectedRevisionId
    }
    
    return <EditDataSourceContext.Provider value={contextValue}>
        
        <div className="edit-data-source">

            <div className="actions">

                <div className="title">
                    {ds?.label}
                </div>

            </div>


            
            <div className="tab-section">
                {TABS.map(tab => {
                    if( !ds?.structured && tab.structured ) return null;
                    if( ds?.structured && tab.semistructured ) return null;
                    return <div key={tab.key}
                        className={`tab ${tab.key === selTab2 ? "sel" : ""}`}
                        onClick={() => {
                            //setSelTab(tab.key)
                            query.set(`tab`, tab.key);
                            
                            const newQueryStr = query.toString();
                            history.push(`${loc.pathname}?${newQueryStr}`)
                        }}
                    >
                        {tab.label}
                    </div>
                })}
            </div>

            {dsLoading ?
                <div className="tab-content" style={{ padding: 20 }}>
                    loading...
                </div>
                : null
            }

            {!dsLoading && selTab2 === "revs" ?
                <div className="tab-content">

                    <DataSourceRevisions id={id}/>
                </div>
                : null
            }

            {!dsLoading && selTab2 === "fields" ?
                <div className="tab-content">
                    <ViewFields key={ds?.id} ds_id={ds?.id}/>
                </div>
                : null
            }

            {!dsLoading && selTab2 === "content" ?
                <div className="tab-content">
                    <ViewUnstructuredContent ds_id={ds?.id} reloadRevisions={revsTriggerReload}/>
                </div>
                : null
            }

            {!dsLoading && selTab2 === "info" ?
                <div className="tab-content">
                    <ViewRevisionInfo ds_id={ds?.id}/>
                </div>
                : null
            }

            {!dsLoading && selTab2 === "etl" ? 
                <div className="tab-content">
                    <EditETL
                        key={ds?.id}
                        {...ids}
                        flow={ds?.flow}
                        reload={revsTriggerReload}
                    />
                </div>
                : null
            }
            
            {!dsLoading && selTab2 === "scratch" ? 
                <div className="tab-content">
                    {ds &&
                        <DataSourceContext.Provider value={ds}>
                            <HasPipelineContext.Provider value={p1Context}>
                                <EditPipelineWithPreview />
                            </HasPipelineContext.Provider>
                        </DataSourceContext.Provider>
                    }
                </div>
                : null
            }

            {!dsLoading && selTab2 === "dependents" ?
                <div className="tab-content">
                    Not yet available. This tab will show all the places this data source is used.
                </div>
                : null
            }

            {!dsLoading && selTab2 === "test" ?
                <div className="tab-content">
                    Not yet available. Use this tab to define custom automated tests that will run on each
                    revision to verify accuracy and integrity of data.
                </div>
                : null
            }
            
            
        </div>

    </EditDataSourceContext.Provider>;


    
}

