import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { ContentItemContext } from "../../../context/Contexts";
import { useProjectId } from "../../../hooks/useProjectId";
import { EditContentItemWrapper } from "../../edit/EditContentItemWrapper";
import { ErrorBoundary } from "../../error-boundary/ErrorBoundary";
import { getAllowedChildTypes, getTypeInfo } from "../ContentTypes";
import { LoadContentItem } from "../LoadContentItem";
import "./ViewSection.scss";




export const ViewSection = (props) => {

    const page = props.page;
    const item = props.content_item;

    const xcontent = <EditContentItemWrapper {...props} actionsPosition="above" hover="hover-pad-top">
        <div>
            {item && (!item?.children || item?.children?.length === 0) ? <div style={{ textAlign: "center"}}>
                blank section
            </div> : null}
            {item?.children?.map(child =>
                <LoadContentItem
                    level={props.level + 1}
                    key={child.id}
                    page={page}
                    id={child.id}
                    edit={props.edit}
                    pType={props.pType} />
            )}
        </div>
       
    </EditContentItemWrapper>;
      
    
    return <ErrorBoundary>{xcontent}</ErrorBoundary>;
        
}



