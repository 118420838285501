import "./LogicInspector.scss";
import Chart from 'react-google-charts'
import { useEffect, useState } from "react";
import { computeApi } from "../../api/computeApi";
import { useAuth } from "../../hooks/useAuth";
import { useProjectId } from "../../hooks/useProjectId";
import { EqualsCondition, GetVarsFromExprs } from "./logic-inspector/InspectorExpressions";
import { Bar, BarChart, Tooltip, XAxis, YAxis } from "recharts";
import { useDataSourceRevision } from "../../hooks/useDataSourceRevision";

const TABS = [
    { 
        id: "flow", 
        label: "Flow Tree",
        docs: <div>
            <p>
                Tree shows a logical flow that may duplicate respondents within a stage.
            </p>
        </div>
    },
    { 
        id: "sankey", 
        label: "Flow Sankey",
        docs: <div>
            <p>
                Sankey shows a true flow of respondents through various stages. Each stage MUST contain mutually exclusive buckets, and a bucket must exist for every respondent.
            </p>
        </div>
    },
    { 
        id: "venn", 
        label: "Venn",
        docs: <div>
            <p>
                Venn illustrates the amount of overlap among 2 or 3 groups.
            </p>
        </div>
    },
    { 
        id: "venn-grid", 
        label: "Overlap grid",
        docs: <div>
            <p>
                Shows percent overlap amoung selected groups. Kind of like correlation, but not. Maybe more like a crosstab.
            </p>
        </div>
    },
    { 
        id: "fdgraph", 
        label: "Force-Directed graph",
        docs: <div>
        </div>
    },
    { 
        id: "validhist", 
        label: "Validity Hist",
        docs: <div>
        </div>
    },
]

const getColor = (cell) => {
    const pct = cell?.pct;
    let val = 255 * pct;
    if( val < 0 ) val = 0;
    if( val > 255 ) val = 255;
    if( isNaN(val) ) return "transparent";
    return `rgba(0,100,200,${pct})`;
}

export const LogicInspector = (props) => {

    const auth = useAuth();
    const { subscription_id, project_id } = useProjectId();

    const [selTab, setSelTab] = useState(TABS[0].id);
    const selectedTab = TABS.find(tab => tab.id === selTab);

    const [data, setData] = useState(null);
    const [vHist, setVHist] = useState(null);

    const datasource_id = "dc54bc31a2c5493d84b74412d2a1fad4";
    const revision_id = 5;

    const [revision, revisionLoading, revisionError, revisionRefresh] = useDataSourceRevision(datasource_id, revision_id);
    

    const groups = [
		{ expr: new EqualsCondition( "qa8_1", 1), "label": "Purchase in-store " },
		{ expr: new EqualsCondition( "qa8_2", 1), "label": "Purchase online (ship to self or other) " },
		{ expr: new EqualsCondition( "qa8_3", 1), "label": "Purchase online and pick up in-store " },
		{ expr: new EqualsCondition( "qa8_4", 1), "label": "Purchase online and pick up at the curb" },
		{ expr: new EqualsCondition( "qa8_9", 1), "label": "I didn’t buy apparel in the past month" },

        { expr: new EqualsCondition( "qa9_01", 1), "label": "Entertainment" },
		{ expr: new EqualsCondition( "qa9_02", 1), "label": "Tech" },
		{ expr: new EqualsCondition( "qa9_03", 1), "label": "Auto" },
		{ expr: new EqualsCondition( "qa9_04", 1), "label": "Sports Equipment" },
		{ expr: new EqualsCondition( "qa9_05", 1), "label": "Footwear" },
		{ expr: new EqualsCondition( "qa9_06", 1), "label": "Accessories (handbag, wallet)" },
		{ expr: new EqualsCondition( "qa9_07", 1), "label": "Food" },
		{ expr: new EqualsCondition( "qa9_08", 1), "label": "Educational needs" },
		{ expr: new EqualsCondition( "qa9_09", 1), "label": "Home" },
		{ expr: new EqualsCondition( "qa9_10", 1), "label": "Beauty" },
		{ expr: new EqualsCondition( "qa9_11", 1), "label": "Travel" },
		{ expr: new EqualsCondition( "qa9_98", 1), "label": "Other" },
		{ expr: new EqualsCondition( "qa9_99", 1), "label": "I didn't forego buying apparel for these categories" }

        // { expr: new EqualsCondition( "s3_1", 1 ), "label": "Apparel for myself or another" },
		// { expr: new EqualsCondition( "s3_2", 1 ), "label": "Movie theater tickets" },
		// { expr: new EqualsCondition( "s3_3", 1 ), "label": "Brand new consumer electronics" },
		// { expr: new EqualsCondition( "s3_4", 1 ), "label": "Office/school supplies and related products" },
		// { expr: new EqualsCondition( "s3_5", 1 ), "label": "Grocery delivery services" },
		// { expr: new EqualsCondition( "s3_6", 1 ), "label": "Services/products for my vehicle(s)" },
		// { expr: new EqualsCondition( "s3_7", 1 ), "label": "Stocks and other financial investments" },
		// { expr: new EqualsCondition( "s3_8", 1 ), "label": "Items for DIY home improvement" },
		// { expr: new EqualsCondition( "s3_9", 1 ), "label": "None of these" }
    ]
        //.map((g, idx) => ({ ...g, id: `g${idx+1}` }));
        .map((g, idx) => ({ ...g, id: g.expr.GetSyntax() }));

    useEffect(() => {
        if( !groups?.length || !subscription_id || !project_id || !datasource_id || !revision_id ) return;

        (async () => {

            const vars = GetVarsFromExprs(groups.map(g => g.expr));
            const _id = vars.reduce((a, v) => ({ ...a, [v]: `$${v}` }), {});

            const mstages = [
                {
                    $group: {
                        _id,
                        n: {
                            $sum: 1
                        }
                    }
                }
            ]

            try{
                const d = await computeApi.compute(auth, subscription_id, project_id, datasource_id, revision_id, mstages);

                // transform it
                let map = [];
                for( let g1 of groups ){
                    for( let g2 of groups ){
                        let freq = 0;
                        let n = 0;
                        for( let row of d ){
                            if(g1.expr.Verify(row._id) && g2.expr.Verify(row._id)){
                                freq += row.n;
                            }
                            if( g1.expr.Verify(row._id)){
                                n += row.n;
                            }
                            
                        }
                        let pct = n === 0 ? null : freq / n;
                        map.push({ g1: g1.id, g2: g2.id, cell: { freq, n, pct }});
                    }
                }
                console.log("map", map)

                // create table
                setData(map);
            }
            catch{}

        })();
        
    }, [])

    const fields = revision?.fields;

    const getValidCountAgg = (name) => ({ $sum: {
        $cond: [{ $gt: [`$${name}`, null] },1,0]
    }})

    useEffect(() => {

        if( !fields ) return;

        (async () => {

            const mstages = [
                {
                    $group: {
                        _id: null,
                        ...fields.reduce((a, f) => {
                            a[f.name] = getValidCountAgg(f.name);
                            return a;
                        }, {})
                    }
                }
            ];

            try{
            const d = await computeApi.compute(auth, subscription_id, project_id, datasource_id, revision_id, mstages);
            setVHist(d);
            }
            catch{}



        })();

    }, [fields])

    let vhist = [];
    if( vHist?.[0] ){
        const d = Object.entries(vHist[0]).map(entry => {
            return { name: entry[0], n: entry[1] };
        })
        vhist = d;
    }
    

    return <div className="logic-inspector full-dock">

        <div className="top">

            <div className="title">
                Logic Inspector
            </div>

            <div className="tab-section">
                {TABS.map(tab => 
                    <div 
                        key={tab.id} 
                        className={`tab ${tab.id === selTab ? "sel" : ""}`}
                        onClick={() => setSelTab(tab.id)}
                    >
                        {tab.label}
                    </div>
                )}
            </div>

        </div>

        <div className="center">
            {selectedTab.docs}
        

        
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        {groups.map((g2, g2Idx) => {
                            return <th style={{
                                fontWeight: "normal",
                                verticalAlign: "bottom",
                                fontSize: "9pt"
                            }}
                                rowSpan={1 + g2Idx}
                            >
                                {g2.id}<br/>
                                {g2.label}
                            </th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {/* <tr>
                        <td></td>
                        <td></td>
                        {groups.map((g2, g2Idx) => {
                            return <td style={{
                                fontWeight: "normal",
                                verticalAlign: "bottom",
                                fontSize: "9pt",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: 40
                            }}
                                rowSpan={1 + g2Idx}
                            >
                                {g2.id}<br/>
                                {g2.label}
                            </td>
                        })}
                    </tr> */}
                    {groups?.map((g1, g1Idx) => 
                        <tr key={g1.id}>
                            <td>{g1.id}</td>
                            <td>{g1.label}</td>
                            {groups.map((g2, g2Idx) => {

                                const show = true;// g1Idx >= g2Idx;

                                if( !show ){
                                    return <td key={g2.id}></td>;
                                }

                                const cell = data?.find(entry => entry.g1 === g1.id && entry.g2 === g2.id)?.cell;
                                return <td 
                                    key={g2.id}
                                    style={{
                                    backgroundColor: getColor(cell),
                                    width: 40,
                                    height: 25,
                                    textAlign: "center",
                                    verticalAlign: "center",
                                    fontSize: "8pt",
                                    border: "1px solid #cfcfcf",
                                    
                                }}>
                                    {/* {cell?.pct ? Math.round(cell.pct * 100) + "%" : ""} */}
                                    {cell?.freq ? cell.freq : ""}
                                </td>
                                
                            })}
                        </tr>
                    )}
                </tbody>
            </table>


            <BarChart
                width={4000}
                height={275}
                data={vhist}
                barGap={0}
                >
                <XAxis dataKey="name" angle={-90} textAnchor="end" fontSize={7} />
                <YAxis width={50} />
                <Tooltip />
                <Bar dataKey="n" fill="#00A3AD" />
            </BarChart>

            
            
        </div>

    </div>
}