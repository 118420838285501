import { TopBar } from "../nav/TopBar";
import "./AdminLayout.scss";



export const AdminLayout = (props) => {

    return <div className='admin-page'>

        <TopBar />

        <div className="main empty-space1">

            <div className='tab-section' style={{ marginTop: 15, marginBottom: 0 }}>
                <div className='tab sel' >
                    Subscriptions
                </div>
                <div className='tab'>
                    Users
                </div>
            </div>
            
        </div>
    
    </div>;
};

