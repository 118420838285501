import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSubscriptionSocket } from "../api/useSubscriptionSocket";
import { SubscriptionIdContext } from "../context/Contexts";
import { useEnforceSubscriptionIdFromRoute } from "../hooks/useEnforceSubscriptionIdFromRoute";
import "./ProjectLayout.scss";
import { ProjectLayoutV2 } from "./ProjectLayoutV2";
import { SelectProject } from "./select-project/SelectProject";


export const SubscriptionLayout = (props) => {

    const subscription_id = useEnforceSubscriptionIdFromRoute();
    const subscription_socket_id = useSubscriptionSocket(subscription_id);
    
    return <SubscriptionIdContext.Provider value={{ subscription_id, subscription_socket_id }}>
        <Switch>
            <Route path="/subscription/:subscription_id/project/:project_id">
                <ProjectLayoutV2 />
            </Route>
            <Route>
                <SelectProject />
            </Route>
        </Switch>
        {props.children}
    </SubscriptionIdContext.Provider>;

}