import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Route, Switch
} from "react-router-dom";
import { projectSocketApi } from "../api/projectSocketApi";
import { SocketHandlerProject } from "../api/SocketHandlerProject";
import { ProjectIdContext } from "../context/Contexts";
import { useEnforceProjectIdFromRoute } from "../hooks/useEnforceProjectIdFromRoute";
import { Collections } from "./collections/Collections";
import { Configs } from "./configs/Configs";
import { DataSources } from "./data-sources/DataSources";
import { FilterBooks } from "./filter-books/FilterBooks";
import { Images } from "./images/Images";
import { Models } from "./model/Models.js";
import { BottomBar } from "./nav/BottomBar";
import { TopBar } from "./nav/TopBar";
import { Pages } from "./pages/Pages.js";
import { ColorPalettes } from "./palettes/ColorPalettes.js";
import { ProjectHome } from "./project-info/ProjectHome";
import { ProjectSettings } from "./project-info/ProjectSettings";
import "./ProjectLayout.scss";
import { Selections } from "./selections/Selections";
import { Tools } from "./tools/Tools";
import * as globals from "../globals";
import { io } from "socket.io-client";
import { UseProjectSocket } from "../api/useProjectSocket";
import { ProjectContent } from "./content/ProjectContent";
import { Reports } from "./reports/Reports";

export const ProjectLayoutV2 = (props) => {

    const dispatch = useDispatch();
    const [project_id, subscription_id] = useEnforceProjectIdFromRoute();
    const project_socket_id = UseProjectSocket(subscription_id, project_id);

    return <ProjectIdContext.Provider value={{ project_id, subscription_id, project_socket_id }}>
        <div className='project-layout'>
        
            <TopBar />
            
            <BottomBar />

            <Switch>
                <Route path="/subscription/:subscription_id/project/:project_id" exact={true}>
                    <ProjectHome />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/info">
                    <ProjectHome />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/settings">
                    <ProjectSettings />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/palette/:id">
                    <ColorPalettes />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/colors" exact={true}>
                    <ColorPalettes />
                </Route>
                {/* <Route path="/subscription/:subscription_id/project/:project_id/page/:page_id">
                    <Pages />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/pages/:tab_id">
                    <Pages />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/pages">
                    <Pages />
                </Route> */}
                <Route path="/subscription/:subscription_id/project/:project_id/content">
                    <ProjectContent />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/collection/:id">
                    <Collections />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/collections" exact={true}>
                    <Collections />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/image/:id">
                    <Images />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/images" exact={true}>
                    <Images />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/data">
                    <DataSources />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/data-source/:id">
                    <DataSources />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/data" exact={true}>
                    <DataSources />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/reports">
                    <Reports />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/model/:id">
                    <Models />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/models">
                    <Models />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/selection/:id">
                    <Selections />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/selections">
                    <Selections />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/form/:id">
                    <Configs />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/forms" exact={true}>
                    <Configs />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/filter-book/:id">
                    <FilterBooks />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/filter-books">
                    <FilterBooks />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/tool/:id">
                    <Tools />
                </Route>
                <Route path="/subscription/:subscription_id/project/:project_id/tools">
                    <Tools />
                </Route>
                
                
            </Switch>

        </div>
    </ProjectIdContext.Provider>;

}