import { useState } from "react";
import { useContext } from "react";
import { useContentItem } from "../../hooks/useContentItem";
import { useContentItemsApi } from "../../hooks/useContentItems";
import { camelToDash } from "../../utils/camelCase";
import { EditContextHooks } from "../page-content/edit/EditContextHooks";
import { EditCPipeline } from "../page-content/edit/EditCPipeline";
import { EditCRSyntaxV2, EditCRSyntaxV2Wrapper } from "../page-content/edit/EditCRSyntax";
import { EditRSyntaxV2Wrapper } from "../page-content/edit/EditRSyntax";
import { EditRenderSyntax } from "../page-content/edit/EditRenderSyntax";
import { EditResources } from "../page-content/edit/EditResources";
import { GenericResourceInput } from "../page-content/edit/GenericResourceInput";
import { SelectSetting, StringSetting } from "../project-info/SettingsViewer";

import { registryDict } from "./ContentRegistry";
import { IdContext } from "./EditContentContext";
import "./EditContentItem.scss";
import { EditJsSyntaxWrapper } from "../page-content/edit/EditJsSyntax";
import { StringPropertyEditor } from "./property-editors/StringPropertyEditor";
import { SelectPropertyEditor } from "./property-editors/SelectPropertyEditor";
import { ResourcePropertyEditor } from "./property-editors/ResourcePropertyEditor";


export const EditContentItem = (props) => {

    const api = useContentItemsApi()

    const id = props?.id;

    const [item, itemLoading, itemError, itemReload] = useContentItem(id);
    const [parentItem, parentItemLoading, parentItemError, parentItemReload] = useContentItem(item?.parent_id);
        
    if( !item ) return null;
    const jdata = item?.jdata;
    const style = item?.style;

    const patchItem = async (patchBody) => {
        props.setMessage("Updating...");
        console.log("patchItem body", patchBody);
        await api.patchItem(id, patchBody);
        props.setMessage(null);
    }

    const patchJData = async (patchBody) => {
        props.setMessage("Updating property...");
        console.log("patchJData body", patchBody);
        await api.patchItem(id, { jdata: { ...jdata, ...patchBody }});
        props.setMessage(null);
    }

    const patchStyle = async (styleBody) => {
        props.setMessage("Updating style...");
        const new_style = {
            ...style,
            ...styleBody
        };
        console.log("patchStyle", new_style );
        await api.patchItem(id, { style: new_style });
        props.setMessage(null);
    }

    
    
    
    const itemEntry = registryDict[item?.ctype];
    const itemSettings = itemEntry?.schema || itemEntry?.properties || [];
    const itemProps = itemSettings.filter(s => !s.name.startsWith("style."));
    const itemStyles = itemSettings.filter(s => s.name.startsWith("style."));

    const parentEntry = registryDict[parentItem?.ctype];
    const settingsFromParent = parentEntry?.childSchema || parentEntry?.childProperties || [];

    const all_settings = [
        {
            category: "Properties",
            name: "label",
            type: "string",
            topLevel: true
        },
        ...(itemProps.map(s => ({ category: `Properties`, ...s }))),
        ...(settingsFromParent.map(s => ({ category: "Child Component Style", idPrec: parentItem?.ctype, ...s }))),
        ...(itemStyles.map(s => ({ category: `Component Style`, ...s })))
    ]
        .map(setting => {
            if( setting.name.startsWith("style.") ){
                return {
                    ...setting,
                    isStyle: true,
                    name: setting.name.substring("style.".length)
                }
            }
            return setting;
        })


    const categories = [];
    const categoryDict = {};
    all_settings.forEach(setting => {
        const category = setting.category;
        let find = categoryDict[category];
        if( !find ){
            find = { label: category, settings: [] };
            categoryDict[category] = find;
            categories.push(find);
        }
        find.settings.push(setting);
    })

    return <div className="edit-content-item">
        <div className="item-id">
            {id}
        </div>
        <div className="item-ctype">
            {item?.ctype}
        </div>

        <div className="category">
            <div className="name">
                Compute Contexts
            </div>
            <EditContextHooks id={id} value={item.jdata?.contextHooks} onChange={async (newList) => {
                const newItem = {
                    ...item,
                    jdata: {
                        ...item?.jdata,
                        contextHooks: newList
                    }
                };
                await patchItem(newItem);
            }} />
        </div>

        <div className="category">
            <div className="name">
                Resource Hooks
            </div>
            <EditResources value={item.jdata?.resources} onChange={async (newList) => {
                const newItem = {
                    ...item,
                    jdata: {
                        ...item?.jdata,
                        resources: newList
                    }
                };
                await patchItem(newItem);
            }} />
        </div>

        {categories?.map(category => 
            <div key={category.label} className="category">
                {true && 
                    <div className="name">
                        {category.label || "null"}
                    </div>
                }
                {category.settings?.map(setting => {

                    const value = setting.topLevel ? item?.[setting.name] :
                        setting.isStyle ? style?.[setting.name] :
                        jdata?.[setting.name];
                    const onChange = 
                        setting.topLevel ? (newValue) => patchItem({ [setting.name]: newValue })
                        : setting.isStyle ? (newValue) => patchStyle({ [setting.name]: newValue })
                        : (newValue) => patchJData({ [setting.name]: newValue });
                    const sparams = { 
                        setting, 
                        value, 
                        onChange, 
                        options: setting.options,
                        id,  // only needed this for crsyntaxv2
                    }

                    return <div key={setting.name} className="setting single-line">
                        <span className="name">
                            {camelToDash(setting.name)}:
                        </span>
                        <span className="value">
                            {
                                getEditor(setting.type, sparams) ||
                                // setting.type === "string" ? <StringSetting {...sparams} />
                                // : setting.type === "select" ? <SelectSetting {...sparams} />
                                <pre>
                                        {JSON.stringify(setting, null, 4)}
                                </pre>
                            }
                        </span>
                        {/* <span className="help">
                            <i className="fal fa-question-circle" />
                        </span> */}
                    </div>
                })}
            </div>
        )}
        
        
    </div>;
}



const getEditor = (type, p) => {
    return type === "string" ? <StringPropertyEditor {...p}/>
        : type === "select" ? <SelectPropertyEditor {...p}/>
        : type === "yes/no" ? <SelectPropertyEditor {...p} options={["yes", "no"]} />

        : type === "flex.alignItems" ? <SelectPropertyEditor {...p} options={["baseline", "center", "flex-end", "flex-start", "stretch"]} />
        : type === "flex.alignContent" ? <SelectPropertyEditor {...p} options={["space-between", "space-around", "strech", "center", "flex-start", "flex-end"]} />
        : type === "flex.flexDirection" ? <SelectPropertyEditor {...p} options={["column", "column-reverse", "row", "row-reverse"]} />
        : type === "flex.flexWrap" ? <SelectPropertyEditor {...p} options={["nowrap", "wrap", "wrap-reverse"]} />
        : type === "flex.flexWrap" ? <SelectPropertyEditor {...p} options={["nowrap", "wrap", "wrap-reverse"]} />
        : type === "flex.justifyContent" ? <SelectPropertyEditor {...p} options={["flex-start", "flex-end", "center", "space-between", "space-around", "space-evenly"]} />
        : type === "flex-item.align-self" ? <SelectPropertyEditor {...p} options={["auto", "stretch", "center", "flex-start", "flex-end", "baseline", "initial", "inherit"]} />

        : type === "data-source" ? <ResourcePropertyEditor type="data-source" {...p} />
        : type === "selection" ? <ResourcePropertyEditor type="selection" {...p} />
        : type === "collection" ? <ResourcePropertyEditor type="collection" {...p} />

        //: type === "crsyntaxv2" ? <EditCRSyntaxV2Wrapper placeholder={"[compute]"} {...p} />
        : type === "cpipeline" ? <EditCPipeline placeholder={"[pipeline]"} {...p} />
        : type === "render-syntax" ? <EditRSyntaxV2Wrapper placeholder={"[render]"} {...p} />
        : type === "syntax" ? <EditJsSyntaxWrapper placeholder={"[syntax]"} {...p} />
        : null;
}