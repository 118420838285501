import JSON5 from "json5";
import { useEffect, useState } from "react";
import { useFilterBook, useFilterBooksApi } from "../../hooks/useFilterBook";
import { ControlledCodeEditorFillContainer } from "../code-editor/CodeEditor";
import "./EditFilterBook.scss";

const TABS = [
    {
        key: "def",
        label: "Definition"
    },
]

const safeJson = (obj) => {
    if (!obj) return null;
    return JSON.stringify(obj, null, 4);
}

export const EditFilterBook = (props) => {

    const id = props.id;
    
    const [filterBook, filterBookLoading, filterBookError] = useFilterBook(id);
    const api = useFilterBooksApi();

    const [selTab, setSelTab] = useState(TABS[0].key);
    const [defCode, setDefCode] = useState(filterBook?.defStr || safeJson(filterBook?.def));

    useEffect(() => {
        if (!filterBook) return;
        
        setDefCode(filterBook?.defStr || safeJson(filterBook?.def));

    }, [filterBook?.def])


    const saveDef = async () => {
        try {
            const newItem = {
                ...filterBook,
                def: JSON5.parse(defCode),
                defStr: defCode
            };
            await api.updateItem(id, newItem);
        }
        catch (err) {
            alert(err.message);
        }
    }

    return <div className='edit-filterbook'>
        <div className="filterbook-header">
            
            <div className="tab-section no-margin">
                {TABS.map(tab =>
                    <span
                        className={`tab ${tab.key === selTab ? "sel" : ""}`}
                        onClick={() => setSelTab(tab.key)}
                    >
                        {tab.label}
                    </span>
                )}
            </div>
        </div>

        {selTab === "def" ?
            <>
                <div className="filterbook-sel-header">
                    <span
                        className="btn action"
                        onClick={() => saveDef()}
                    >
                        Save changes
                    </span>
                </div>
                <div className="filterbook-sel">
                    
                    <div style={{
                        //border: "5px solid blue",
                        position: "relative",
                        height: "100%",
                        overflow: "hidden"
                    }}>
                        <ControlledCodeEditorFillContainer value={defCode} onChange={(code) => setDefCode(code)} />
                    </div>
                    
                </div>
            </>
            : null
        }




    </div>
}