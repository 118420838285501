import { useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import { AuthContext } from "../../context/Contexts";
import { useProject } from "../../hooks/useProject";
import { useProjectId } from "../../hooks/useProjectId";
import { useSubscription } from "../../hooks/useSubscription";
import "./TopBar.scss";
import * as globals from "../../globals";

export const TopBar = (props) => {

    //const [projectLabel, setProjectLabel] = useState(null);

    const routerParams = useParams();
    // const page_id = routerParams.page_id;

    const history = useHistory();

    const authContext = useContext(AuthContext);

    //const pid = useProjectId();
    // const project_id = pid?.project_id;
    // const subscription_id = pid?.subscription_id;



    const { project_id } = useProjectId();
    const subscription_id = routerParams.subscription_id;

    const [ subscription ] = useSubscription(subscription_id);
    const [ project ] = useProject(subscription_id, project_id);



    // useEffect(() => {
    //     const loadProject = async () => {
    //         if (!project_id) return;
    //         var p = await api.getProject(project_id);
    //         setProjectLabel(p?.label);
    //     }
    //     loadProject();

    // }, [project_id]);

    return <div className='top-bar'>

        <div className='left-section'>
            <Link to={"/"} className='logo' >
                {globals.player_title || "[m]Studio"}
            </Link>
            {/* {subscription_id && 
                <Link to={`/subscription/${subscription_id}`} className='logo' style={{ marginLeft: 5 }}>
                    <i className="fal fa-briefcase" />
                </Link>
            } */}
            {project_id && 
                <Link 
                    className='menu-button' 
                    to={`/subscription/${subscription_id}`}
                    style={{ marginLeft: 10 }}
                >
                    <i className='glyph fad fa-briefcase' /><span className="hide-on-tablet">&nbsp;{subscription?.label}</span>
                </Link>
            }
            {project_id && <span style={{ marginLeft: 0 }}>
                <Link
                    className='menu-button project-label'
                    to={`/subscription/${subscription_id}/project/${project_id}`}
                >
                    <i className="fad fa-campground"/><span className="hide-on-tablet">&nbsp;{project?.label}</span>
                </Link>
            </span>}
        </div>
        {project ?
            <div className='middle-section nowrap'>
                <a
                    href={`/preview/${subscription_id}/${project_id}`}
                    target="_blank"
                    className='btn1 nowrap hide-on-tablet'
                >
                    Preview <i className='fas fa-external-link-square' />
                </a>
                <div className='btn2 margin-left margin-right hide-on-tablet'>
                    Publish
                </div>
                <div className="menu-button color-primary show-on-tablet">
                    <i className='fas fa-external-link-square' />
                </div>
            </div> : null}
        <div className='top-bar-right-menu'>
            {/* <Link to="/admin" className='menu-button'>
                <i className='glyph fad fa-cog' /> Admin
            </Link> */}
            <a href="https://docs.metricstudios.com" target="_blank" className='menu-button'>
                <i className='glyph fad fa-question-circle' /><span className="hide-on-tablet">&nbsp;Help</span>
            </a>
            <Popup
                nested
                arrow={false}
                trigger={
                    <div className='menu-button' >
                        <i className='glyph fas fa-user-circle' /><span className="hide-on-mobile">&nbsp;{authContext?.authUser?.name}</span>
                    </div>
                }
                contentStyle={{
                    width: 250
                }}
                position="bottom right"
            >
                {closeUserMenu =>
                    <div className="popup-menu" style={{ width: 250 }}>
                        <div className="menu-item" onClick={ev => {
                            authContext.signOut();
                            history.push(`/`);
                        }}>
                            Sign Out
                            <i className="icon fal fa-power-off" />
                        </div>
                        <div className="separator"></div>
                        <Link className="menu-item" to="/profile" onClick={closeUserMenu}>
                            Edit Profile
                        </Link>
                        <div className="menu-item">
                            Change Password
                        </div>
                        <Link className="menu-item" to="/account"  onClick={closeUserMenu}>
                            View Account
                        </Link>
                        <div className="menu-item">
                            View Subscription(s)
                        </div>


                    </div>
                }
            </Popup>
        </div>
    </div>;

}

