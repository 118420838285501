export class GenericStage {

    constructor(data){
        this.data = data;
    }

    data;

    toJson = () => {
        return {
            ...this.data
        }
    }

    reduceMeta = (meta) => meta;

    reduceFields = (fields) => fields;

    getFieldsUsed = () => {
        
    }
}