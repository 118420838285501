export const getFilterBookCategories = (filterBook) => {
    return filterBook?.reduce((accum, el) => {
        const category = el.category || "Other";
        const find = accum.find(c => c.label === category);
        if (find) {
            find.filterGroups.push(el);
        }
        else {
            accum.push({
                label: category,
                filterGroups: [el]
            });
        }
        return accum;
    }, []);
}

export const getFilterGroupId = (filterGroup) => {
    return filterGroup.id || filterGroup.name;
}

export const getFilterId = (filter) => {
    return filter.id || filter.syntax || `${filter.name || filter.id}==${filter.value}`;
}

const getRangeLabel = (filterGroup, selectedFilters) => {

    // group by adjacent filters
    let sets = [];
    


    if (filterGroup.range) {
        let rangeItems = [];

        for (let filter of filterGroup.filters) {
            
            const fId = getFilterId(filter);

            if (selectedFilters.find(f => getFilterId(f) === fId)) {
        
                // add selected filter to range
                rangeItems.push(filter);
            }
            else {
                // not selected, so break the range
                if (rangeItems?.length > 0) {
                    sets.push(rangeItems);
                    rangeItems = [];
                }
            }
           
        }
        // add last range if exists
        if (rangeItems?.length > 0) {
            sets.push(rangeItems);
        }
    }
    else {
        sets.push(selectedFilters);
    }

    // here we should have sets of ranges
    let labels = sets.reduce((accum, set) => {
        if (set?.length === 1) {
            accum.push(set[0].label);
        }
        else if (!filterGroup.range) {
            accum.push(set.map(f => f.label).join(" or "));
        }
        else {
            const first = 0;
            const last = set.length - 1;
            const rangePrec = filterGroup.rangePrec ? `${filterGroup.rangePrec} ` : "";
            const rangeSep = set[last].rangeSep || set[first].rangeSep || filterGroup.rangeSep || " to ";
            accum.push(`${rangePrec}${set[first].rangeMin}${rangeSep}${set[last].rangeMax}`);
        }
        return accum;
    }, []);
    return labels.join(", ");

}



export const smartOR = (filters) => {
    if (filters?.length === 1)
        return filters[0];
    
    // group filters into sets by filterGroup
    let filterGroupSets = [];
    for (let filter of filters) {
        let find = filterGroupSets.find(set => set.group.id === filter.group.id);
        if (!find) {
            find = {
                group: filter.group,
                filters: []
            };
            filterGroupSets.push(find);
        }
        find.filters.push(filter);
    }

    // get range label for each set, combine using "or"
    const label = filterGroupSets
        .map(set => getRangeLabel(set.group, set.filters))
        .join(" or ");

    return {
        label,
        syntax: filters.map(f => `(${f.syntax})`).join(" or "),
        n: filters.length
    }
}

export const smartAND = (filters) => {
    if (filters?.length === 1)
        return filters[0];
    
    return {
        label: filters.n > 1 ?
            filters.map(f => `(${f.label})`).join(" ")
            : filters.map(f => `${f.label}`).join(" ")
            ,
        syntax: filters.map(f => `(${f.syntax})`).join(" and ")
    }
}

export const smartCombine = (availableFilters, selectedFilterIds) => {
    
    const selectedFilters = selectedFilterIds.map(id => availableFilters.find(f => getFilterId(f) === id));
    
    // combine selections by group
    // so we can OR within groups and AND across groups

    let groupArr = [];
    for (let filter of selectedFilters) {
        const group = filter.group;
        let find = groupArr.find(el => el.groupId === group.id);
        if (!find) {
            find = {
                groupId: group.id,
                filters: []
            }
            groupArr.push(find);
        }
        find.filters.push(filter);
    }

    // OR within groups
    for (let g of groupArr) {
        g.filter = smartOR(g.filters);
    }

    // AND across groups
    const filter = smartAND(groupArr.map(g => g.filter));
    return filter;

}