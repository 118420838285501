import { useContext, useEffect, useState } from "react";
import { PageContext } from "../../../context/Contexts";
import { useContentItem } from "../../../hooks/useContentItem";
import { useContentItemsApi } from "../../../hooks/useContentItems";
import { ControlledCodeEditorFillContainer } from "../../code-editor/CodeEditor";
import { DataBindButton } from "../../edit/DataBindButton";
import { InstancesInput } from "../../instances/InstancesInput";
import JSON5 from "json5";
import { GenericResourceInput } from "./GenericResourceInput";
import { EditCRSyntaxV2 } from "./EditCRSyntax";
import { useProjectId } from "../../../hooks/useProjectId";
import { useResources } from "../../../hooks/useResources";
import { reduceResources } from "../../../hooks/helper/reduceResources";
import { useDispatch } from "react-redux";
import { useAuth } from "../../../hooks/useAuth";
import { EditResources } from "./EditResources";
import { useSelection, useSelectionState } from "../../../hooks/useSelection";
import { useShallowCompareEffect } from "../../../hooks/useShallowCompareEffect";

const safeJson = (obj) => {
    if (!obj) return null;
    return JSON.stringify(obj, null, 4);
}

export const EditConfiguratorProperties = (props) => {

    const {project_id, subscription_id} = useProjectId();
    const api = useContentItemsApi();
    const page = useContext(PageContext);
    const dispatch = useDispatch();
    const auth = useAuth();

    const id = props.content_item_id;
    const [item] = useContentItem(page?.id, id);

    const [resources, resourcesLoading, resourcesError] = useResources(item?.resources, true);

    useShallowCompareEffect(() => {
        console.log("resources changed", resources);
    }, [resources])
    

    const [state, stateLoading, stateError] = useSelectionState(item?.selectionId);
    const [compState, compStateLoading, compStateError] = useSelectionState(item?.compSelectionId);

    let rResources = reduceResources(resources, subscription_id, project_id, dispatch, auth);
    rResources.selection = state;
    rResources.compSelection = compState;
    

    if (!item) return null;

    const rowPad = 10;

    const updateItem = async (newItem) => {
        console.log('updateItem', id, newItem);
        await api.updateItem(id, newItem, { page_id: page.id });
    }

    return <div>

        <div className='h-dock' style={{ marginBottom: rowPad }}>
                    
            <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                Instances
            </span>
            <span className='center'>
                <InstancesInput
                    style={{ width: "100%" }}
                    value={item.instances}
                    onChange={async (newInstances) => {
                        const newItem = {
                            ...item,
                            instances: newInstances
                        };
                        updateItem(newItem);
                    }}
                />
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton/>
            </span>
        </div>

        <div className="form1">

        <EditResources value={item.resources} onChange={async (newList) => {
                const newItem = {
                    ...item,
                    resources: newList
                };
                await updateItem(newItem); //api.updateItem(item.id, newItem, { page_id: page.id });
            }} />

        <div className='label'>
                <div className='label-line'>
                    Layout
                </div>
                <div className='value-line'>
                    <select 
                        style={{ width: "100%" }}
                        value={item.layout} onChange={(ev) => {
                        const newItem = {
                            ...item,
                            layout: ev.target.value
                        }
                        updateItem(newItem);
                    }}>
                        <option value="tiles">Tiles</option>
                        <option value="rows">Rows</option>
                    </select>
                    {/* <pre>
                        {JSON.stringify(item, null, 2)}
                    </pre> */}
                </div>
            </div>

            <div className='label'>
                <div className='label-line'>
                    Atts
                </div>
                <div className='value-line'>
                    <GenericResourceInput 
                        type="data-source" 
                        value={item.attsId}
                        onChange={async (newId) => {
                            const newItem = {
                                ...item,
                                attsId: newId
                            }
                            updateItem(newItem);
                        }}
                    />
                </div>
            </div>

            <div className='label'>
                <div className='label-line'>
                    Selection (User state)
                </div>
                <div className='value-line'>
                    <GenericResourceInput 
                        type="selection" 
                        value={item.selectionId}
                        onChange={async (newId) => {
                            const newItem = {
                                ...item,
                                selectionId: newId
                            }
                            updateItem(newItem);
                        }}
                    />
                </div>
            </div>

            <div className='label'>
                <div className='label-line'>
                    Comparison Selection (User state)
                </div>
                <div className='value-line'>
                    <GenericResourceInput 
                        type="selection" 
                        value={item.compSelectionId}
                        onChange={async (newId) => {
                            const newItem = {
                                ...item,
                                compSelectionId: newId
                            }
                            updateItem(newItem);
                        }}
                    />
                </div>
            </div>

            {/* <div className='label'>
                <div className='label-line'>
                    Results (User state)
                </div>
                <div className='value-line'>
                    <GenericResourceInput 
                        type="selection" 
                        value={item.resultsId}
                        onChange={async (newId) => {
                            const newItem = {
                                ...item,
                                resultsId: newId
                            }
                            updateItem(newItem);
                        }}
                    />
                </div>
            </div> */}

            <div className='label'>
                <div className='label-line'>
                    Compute
                </div>
                <div className='value-line'>
                    <EditCRSyntaxV2
                        item={item}
                        resources={rResources}
                        placeholder={"[compute]"}
                        onChange={
                            async (newStuff) => {
                                const newItem = {
                                    ...item,
                                    compute: newStuff.compute,
                                    pipelines: newStuff.pipelines,
                                    render: newStuff.render,
                                    renderTable: newStuff.renderTable,
                                }
                                updateItem(newItem);
                            }
                        }
                    />
                </div>
            </div>
            




        </div>


    </div>
}
