
const initialState = {
}


export const ConfigStateReducer = (state = initialState, action) => {

    switch( action.type ){
        case 'CONFIG_STATE_SET' : return {
            ...state,
            [action.id]: action.value
        }
        default:
    }

    return state;
}

