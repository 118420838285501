import { useContext, useState } from "react";
import Popup from "reactjs-popup";
import { PipelineStageEditorContext } from "../../../../context/Contexts";
import { debounce } from "../../../../utils/debounce";
import { TextFrame } from "../../../modal-dialog/VerifyFrame";
import "./EditSampleStage.scss";

export const EditSampleStage = (props) => {

    const stageContext = useContext(PipelineStageEditorContext);
    const stage = stageContext.stage;
    const [size, setSize] = useState(stage.data?.$sample?.size);

    const save = (newSize) => {
        try{
            const i = parseInt(newSize);
            stageContext.replaceStageData({ 
                $sample: {
                    size: i
                }
            });
        }
        catch{}
    }

    return <div className="edit-skip-stage">
        Sample: 
        &nbsp;
        <Popup
            modal
            keepTooltipInside
            trigger={
                <span className="value-box">
                    {size}
                </span>        
            }
        >
            {closeModal => <TextFrame
                title={"Edit value"}
                label={`Size:`}
                initialTextValue={`${size}`}
                close={closeModal} confirmed={async (text) => {
                    try{
                        const newVal = parseInt(text);
                        setSize(newVal);
                        save(newVal);
                    }
                    catch(err){
                        alert(err.message);
                    }
                    closeModal();
                }}
            />}
        </Popup>
        
        &nbsp; record(s) max.
    </div>
    
}