import { useContext } from "react"
import { EditContentContext } from "../content/EditContentContext"
import { Configurator } from "../configurator/Configurator";

export const ViewConfigWrapper = (props) => {

    const ec = useContext(EditContentContext);
    const mode = ec.mode;
    const item = ec.item;

    const style = { ...props.style, ...item?.style }

    const content_item = {
        ...item?.jdata,
        ...item,
    }

    return <Configurator content_item={content_item} atts={props.atts} />

}