import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import {
    NavLink,
    Route, Switch, useHistory, useParams
} from "react-router-dom";
import { AuthContext } from "../../context/Contexts";
import { useEnforceSubscriptionIdFromRoute } from "../../hooks/useEnforceSubscriptionIdFromRoute";
import { useSubscription } from "../../hooks/useSubscription";
import { TopBar } from "../nav/TopBar";
import { EditAccount } from "./EditAccount";
import { EditProfile } from "./EditProfile";
import { EditSubscription } from "./EditSubscription";
import { ListProjects } from "./ListProjects";
import "./SelectProject.scss";
import * as globals from "../../globals";

export const SelectProject = (props) => {

    const subscription_id = useEnforceSubscriptionIdFromRoute();
    const [subscription, subscriptionLoading, subscriptionError] = useSubscription(subscription_id);

    return <div className='select-project-page'>

        <TopBar />

        <div className="main empty-space1">

            <div className='tab-space'>
                <b><i className="fad fa-briefcase" /> {subscription?.label || "Loading subscription..."}</b>
            </div>
            <div className="tab-content" >
                <Switch>
                    <Route path="/profile" component={EditProfile} />
                    <Route path="/account" component={EditAccount} />
                    <Route path={subscription_id ? "/subscription/:subscription_id/info" : "/subscriptions"} component={EditSubscription} />
                    <Route path="/subscription/:subscription_id" component={ListProjects} />
                    <Route path="/subscription/:subscription_id/projects" component={ListProjects} />
                    {globals.player_sub &&
                        <Route path="/" component={ListProjects} />
                    }
                </Switch>
            </div>

            
        </div>
    
    </div>;
};

