import { constructs } from "../../../api/contructs";
import { useCollections } from "../../../hooks/useCollection";
import { useDataSources } from "../../../hooks/useDataSource";
import { useImages } from "../../../hooks/useImage";
import { usePalettes } from "../../../hooks/usePalette";
import { useProjectId } from "../../../hooks/useProjectId";
import { useResources } from "../../../hooks/useResources";
import "./ResourceList.scss";


export const HookList = (props) => {

    const list = props.value;
    //const [resources, resourcesLoading, resourcesError] = useResources(list, false);
    const hooks = list;
    const hooksLoading = undefined;
    const hooksError = undefined;
    

    if (hooksLoading)
        return <div>
            Loading...
        </div>

    if (hooksError)
        return <div>
            Error loading hooks.
        </div>
    
    if (!list || list.length < 1)
        return <div style={{
            textAlign: "center",
            padding: 10,
            fontSize: 12
        }}>
            (0 items)
        </div>;

    return <div className="resource-list" style={{ marginBottom: 10 }}>
        {hooks?.map(hook => 
            <div className="entry" key={hook.name}>
                <span className="icon">
                    <i className={`fas fa-rocket`}/>
                </span>
                <span className="name">
                    {hook.name} = 
                </span>
                <span className="rlabel">
                    {hook.value?.label || hook.id}
                </span>
                <span className='btn del' onClick={() => {
                    props.onDelete?.(hook)
                }}>
                    <i className="fal fa-times"/>
                </span>
            </div>
        )}
    </div>
    
}