import { useContext } from "react";
import { useContentItemsApi } from "../../hooks/useContentItems";
import { ContentAdderButton } from "../content/ContentAdderButton";
import { DisplayContext, getDisplayClass } from "../content/DisplayContext";
import { EditContentContext } from "../content/EditContentContext";
import { PresentContent } from "../content/PresentContent";
import { GenWrapper } from "./ArbitraryContent"
import "./HDockLayout.scss";
import { VH_DOCK_HINT } from "./VDockLayout";

export const HDockLayout = (props) => {

    const ec = useContext(EditContentContext);
    const mode = ec.mode;
    const item = ec.item;
    const children = item?.children;
    const leftChild = children?.find(child => child.key === "left");
    const rightChild = children?.find(child => child.key === "right");
    const centerChild = children?.find(child => child.key === "center");
    const locked = item?.jdata?.locked;
    const style = { ...props.style, ...item?.style }


    return <div className={`mstudio-h-dock-layout ${props.displayClass}`} style={style} {...props.atts}>
        {(leftChild || mode === "edit") &&
            <div className="left">
                <DisplayContext.Provider value={"block-fill"}>
                    {leftChild ?  
                        <PresentContent id={leftChild.id} />
                        :
                        !locked && <ContentAdderButton keyName="left" label="" />
                    }
                </DisplayContext.Provider>
            </div>
        }
        <div className="center">
            <DisplayContext.Provider value={"block-fill"}>
                {centerChild ?  
                    <PresentContent id={centerChild.id} />
                    : mode === "edit" && !locked ? <div className="_block-fill" style={{ padding: 10 }}>
                            <ContentAdderButton keyName="center" label="Add center content" description={VH_DOCK_HINT} />
                        </div>
                    : null
                }
            </DisplayContext.Provider>
        </div>
        {(rightChild || mode === "edit") &&
            <div className="right">
                <DisplayContext.Provider value={"block-fill"}>
                    {rightChild ?  
                        <PresentContent id={rightChild.id} />
                        :
                        !locked && <ContentAdderButton keyName="right" label="" />
                    }
                </DisplayContext.Provider>
            </div>
        }
    </div>

    
}