import { useContext, useState } from "react";
import { PipelineStageEditorContext } from "../../../../context/Contexts";
import { debounce } from "../../../../utils/debounce";
import { ControlledCodeEditorFillContainer } from "../../../code-editor/CodeEditor";
//import { getStageInnerData } from "../_PipelineStages";
import "./EditCustomStage.scss";

const debounce1000 = debounce((callback, ...args) => {
    callback(...args);
}, 1000);

export const EditCustomStage = (props) => {

    const stageContext = useContext(PipelineStageEditorContext);
    const stage = stageContext.stage;
    const firstKey = stage?.data ? Object.keys(stage?.data)?.[0] : null;
    const innerData = stage?.data?.[firstKey]; // getStageInnerData(stage?.data);

    const [syntax, setSyntax] = useState(innerData?.syntax);

    const save = (val) => {

        try{
            stageContext.replaceStageData({
                [firstKey]: {
                    syntax: val
                }
            })
        }
        catch{}
    }

    return <div style={{ height: 200 }}>
        <ControlledCodeEditorFillContainer 
            language="javascript" 
            value={syntax}
            onChange={(newCode) => {
                setSyntax(newCode)
                debounce1000(save, newCode);
            }} 
            editorProps={{
                options: {
                    lineNumbers: 'off',
                    glyphMargin: false,
                    folding: false,
                    minimap: {
                        enabled: false
                    }
                }
            }}
        />
    </div>
    
}