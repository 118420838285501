import JSON5 from "json5";
import { useEffect, useState } from "react";
import { useSelection, useSelectionsApi } from "../../hooks/useSelection";
import { ControlledCodeEditorFillContainer } from "../code-editor/CodeEditor";
import { EditResources } from "../page-content/edit/EditResources";
import "./EditSelection.scss";


const TABS = [
    {
        key: "default",
        label: "Default Value"
    },
]

const safeJson = (obj) => {
    if (!obj) return null;
    return JSON.stringify(obj, null, 4);
}

export const EditSelection = (props) => {

    const id = props.id;
    
    const [selection, selectionLoading, selectionError] = useSelection(id);
    const api = useSelectionsApi();

    const [selTab, setSelTab] = useState(TABS[0].key);
    
    const [selType, setSelType] = useState(selection?.type);
    const [scope, setScope] = useState(selection?.scope);
    const [lifespan, setLifespan] = useState(selection?.lifespan);
    const [defaultValueStr, setDefaultValueStr] = useState(JSON.stringify(selection?.defaultValue, null, 4));
    const [init, setInit] = useState(selection?.init);
    const [resourceList, setResourceList] = useState(null);
    
    
    useEffect(() => {
        setSelType(selection?.type);
        setScope(selection?.scope);
        setDefaultValueStr(JSON.stringify(selection?.defaultValue, null, 4));
        setInit(selection?.init);
        setResourceList(selection?.resources);
    }, [selection])


    const saveDefaultValue = async () => {
        try {


            let newItem = {
                ...selection,
                type: selType,
                defaultValue: selType === "json" ? JSON5.parse(defaultValueStr) : undefined,
                init: selType === "js" ? init : undefined,
                resources: selType === "js" ? resourceList : undefined,
                scope: scope
            };
            console.log("newItem", newItem);
            await api.updateItem(id, newItem);
        }
        catch (err) {
            alert(err.message);
        }
    }
    

    return <div className='edit-selection'>
        <div className="selection-header">
            
            <div className="tab-section no-margin">
                {TABS.map(tab =>
                    <span
                        className={`tab ${tab.key === selTab ? "sel" : ""}`}
                        onClick={() => setSelTab(tab.key)}
                    >
                        {tab.label}
                    </span>
                )}
            </div>
        </div>

        {selTab === "default" ?
            <>
                <div className="selection-sel-header" style={{ fontSize: "9pt" }}>
                    Initial Value:&nbsp;
                    <span className={`btn toggle ${selType === "json" ? "sel" : ""}`}
                        onClick={() => setSelType("json")}
                    >
                        JSON
                    </span>
                    <span className={`btn toggle ${selType === "js" ? "sel" : ""}`}
                        onClick={() => setSelType("js")}
                    >
                        JS init()
                    </span>
                    <span style={{ width: 10, display: "inline-block" }}/>
                    Lifespan:&nbsp;
                    <span className={`btn toggle ${lifespan === "session" ? "sel" : ""}`}
                        onClick={() => setLifespan("session")}
                    >
                        Session
                    </span>
                    <span className={`btn toggle ${lifespan === "forever" ? "sel" : ""}`}
                        onClick={() => setLifespan("forever")}
                    >
                        Forever
                    </span>
                    <span style={{ width: 10, display: "inline-block" }}/>
                    Scope:&nbsp;
                    <span className={`btn toggle ${scope === "user" ? "sel" : ""}`}
                        onClick={() => setScope("user")}
                    >
                        User
                    </span>
                    <span className={`btn toggle ${scope === "all" ? "sel" : ""}`}
                        onClick={() => setScope("all")}
                    >
                        All Users
                    </span>
                    <span style={{ width: 10, display: "inline-block" }}/>
                    <span style={{ width: 10, display: "inline-block" }}/>
                    <span
                        className="btn action"
                        onClick={() => saveDefaultValue()}
                    >
                        Save changes
                    </span>
                </div>
                <div className="selection-sel">
                    {selType === "json" ?
                        <ControlledCodeEditorFillContainer id="json-edit" value={defaultValueStr} language={"json"} onChange={(code) => setDefaultValueStr(code)} />
                        :
                        <div className="full-dock fill-parent-absolute">
                            <div className="left" style={{ width: 300, borderRight: "1px solid #cfcfcf", padding: 10 }}>
                            <EditResources value={resourceList} onChange={async (newList) => {
                                setResourceList(newList);
                            }} />
                            </div>
                            <div className="center">
                                <ControlledCodeEditorFillContainer id="js-edit" value={init} language={"javascript"} onChange={(code) => setInit(code)} />
                            </div>
                        </div>
                    }
                </div>
            </>
            : null
        }




    </div>
}