import { useState } from "react";
import { Link } from "react-router-dom";
import { a } from "react-spring";
import { useAllProjects, useProjects } from "../../hooks/useProject";


const getMatches = (searchText, items) => {
    let text = searchText.toLocaleLowerCase();
    let textArr = text.split(" ");
    return items.filter(item => {
        let itemText = item.subscription_label.toLocaleLowerCase() + " " + item.label.toLocaleLowerCase();
        for( let t of textArr ){
            if(!itemText.includes(t)) return false;
        }
        return true;
    })
}

export const RecentProjectsBlock = (props) => {

       

    const [projects, projectsLoading, projectsError, refreshProjects] = useAllProjects();
    const [searchText, setSearchText] = useState(null);

    let recentProjects = projects
        ?.filter(p => p.last_access)
        ?.sort((a, b) => b.last_access?.localeCompare(a.last_access)); // string compare since last_access is a string

    // remove anything older than last_access N months ago
    const cutoff = new Date();
    cutoff.setMonth(cutoff.getMonth() - 1); // n months set here
    recentProjects = recentProjects
        ?.filter(p => new Date(p.last_access) > cutoff);


    if( searchText ){
        recentProjects = getMatches(searchText, projects);
    }

    return <div className="recent-block">
        <div className="title">
            <span className="label">
                Recent Projects
            </span>
            <span className="btn action smaller new">
                New
            </span>
        </div>
        <div className="search">
            <input type="text" placeholder="&#xF002; Find a project" 
                value={searchText || ""}
                onChange={(ev) => setSearchText(ev.target.value)}
            />
        </div>
        <div className="item-list">
            {projectsLoading && <div className="item loading">
                Loading...
            </div>}
            {recentProjects?.map(item =>
                <Link key={item.id} className="item2" to={`/subscription/${item.subscription_id}/project/${item.id}`}>
                    <div className="icon">
                        <i className="fad fa-campground" />
                    </div>
                    <div className="ilabel">
                        {item.label}
                    </div>
                    <div className="plabel">
                        {item.subscription_label}
                    </div>
                </Link>
            )}
            <Link className="item view-all" to={`/subscriptions`}>
                View all projects...
            </Link>
        </div>
    </div>;
    

}