import { useContext } from "react";
import { AuthContext } from "../context/Contexts";

export const useAuth = () => {
    const c = useContext(AuthContext);
    return {
        token: c?.authToken?.access_token,
        refresh_token: c?.authToken?.refresh_token
    }
}


