
const initialState = {
}


export const SelectionStateReducer = (state = initialState, action) => {

    switch( action.type ){
        case 'SELECTION_STATE_SET' : {
            //console.log("running sss reducer", action);
            return {
                ...state,
                [action.id]: action.value
            }
        }
        default:
    }

    return state;
}

