import React from 'react';
import { compileFunction } from '../../utils/Compiler';
import { AttInput, AttInputBool, AttInputContin, AttInputSlider } from '../configs/AttInput';
import "./ProductEdit.scss";


export const executeProperty = (productValue, syntax, fallback) => {

    if (!syntax) return fallback;

    try {
        const f = compileFunction(syntax);
        if (!f) return fallback;
        return f(productValue);
    }
    catch (err) {
        console.error(err);
        return fallback;
    }

}

export const EditAttCell = (props) => {

    const product = props.product;
    const compProduct = props.compProduct;
    const att = props.att;
    const attType = att.ui; // || att.type;

    const handleChange = (newValue) => {
        props.onChange?.(newValue);
    }

    const exclude = executeProperty(product, att.excludeIf, false);
    if (exclude) return null;

    const include = executeProperty(product, att.includeIf, true);
    if (!include) return null;
    
    const pass = {
        att,
        hideLabel: props.hideLabel,
        style: props.style
    }

    if (attType === "contin" || attType === "range") {
        return <div className='att-box'>
            <AttInputContin {...pass} product={product} compProduct={compProduct} onChange={handleChange} tabIndex={props.tabIndex} />
        </div>
    }
    else if (attType === "select" || attType === "discrete") {
        return <div className='att-box'>
            <AttInput {...pass} productValue={product} compProduct={compProduct} onChange={handleChange} tabIndex={props.tabIndex}/>
        </div>;
    }
    else if (attType === "checkbox" || attType === "bool") {
        return <div className='att-box light'>
            <AttInputBool {...pass} productValue={product} compProduct={compProduct} onChange={handleChange} tabIndex={props.tabIndex}/>
        </div>;
    }
    else if (attType === "slider") {
        return <div className='att-box'>
            <AttInputSlider {...pass} productValue={product} compProduct={compProduct} onChange={handleChange} tabIndex={props.tabIndex}/>
        </div>;
    }
    else {
        return null;
    }

}


export const ProductEdit = (props) => {

    const atts = props.atts || [];
    const value = props.value || {};

    const handleChange = (newValue) => {
        props.onChange?.(newValue);
    }

    return <div className='product-edit' style={props.style}>
        <div className='product-label'>
            {props.label}
        </div>
        <form>
            {atts.map(att => {

                const attType = att.ui;// || att.type;
                if( !attType ) return null;

                const exclude = executeProperty(value, att.excludeIf, false);
                if (exclude) return null;
                //console.log('exclude', exclude);

                const include = executeProperty(value, att.includeIf, true);
                if (!include) return null;
                //console.log('include', include);
                
                const pass = {
                    att,
                    hideLabel: props.hideLabel,
                    productUid: props.uid,
                    defaultValue: (att.label === "Available" ? true : false)
                }

                if (attType === "contin" || attType === "range") {
                    return <AttInputContin {...pass} productValue={value} onChange={handleChange} />
                }
                else if (attType === "select" || attType === "discrete") {
                    return <AttInput {...pass} productValue={value} onChange={handleChange}/>
                }
                else if (attType === "checkbox" || attType === "bool") {
                    return <AttInputBool {...pass} productValue={value} onChange={handleChange}/>
                }
                else if (attType === "slider") {
                    return <AttInputSlider {...pass} productValue={value} onChange={handleChange}/>
                }
                else {
                    return null;
                }

            })}
        </form>
    </div>


}