import { useState } from "react";
import Popup from "reactjs-popup";
import { useRecoilValue } from "recoil";
import { useContentItem } from "../../../hooks/useContentItem";
import { computeContextState, computeContextStates } from "../../content-mstudio/ComputeContext";
import "./EditCRSyntax.scss";
import { EditRSyntaxBox } from "./EditRSyntaxBox";





export const EditRSyntaxV2Wrapper = (props) => {
    const id = props.id;
    const [item] = useContentItem(id);
    return <EditRSyntaxV2 item={item} render={item?.jdata?.render} {...props} />;
}

export const EditRSyntaxV2 = (props) => {

    const [popupExpanded, setPopupExpanded] = useState(false);

    const item = props.item;
    const cHook = item?.jdata?.contextHooks?.[0];
    //const ccState = useRecoilValue(computeContextState(cHook?.id));
    const ccStateR = useRecoilValue(computeContextStates(item?.jdata?.contextHooks));//(item?.jdata?.contextHooks));
    //const ccStateR = item?.jdata?.contextHooks?.length > 1 ? ccState2 : ccState2[item?.jdata?.contextHooks[0].name];
    if( !item ) return null;


    return <div>
        <Popup
            modal
            closeOnEscape={false}
            closeOnDocumentClick={false}
            contentStyle={{
                width: "100%",
                height: "100%",
                background: "transparent"
            }}
            trigger={
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        border: "1px solid #cfcfcf",
                        width: "100%",
                        padding: "5px 10px",
                        userSelect: "none"
                    }}
                >
                    <span style={{ display: "inline-block", marginRight: 10 }}>
                        {props.placeholder || "[syntax]"}
                    </span>
                    <span style={{ float: "right" }}>
                        <i className='fal fa-ellipsis-h'/>
                    </span>
                </div>
            }
        >
            {closeModal => 
                <EditRSyntaxBox
                    item={item}
                    title={"Syntax"}
                    render={props.render}
                    resources={props.resources}
                    payload={ccStateR}
                    onChange={props.onChange}
                    close={closeModal}
                />}
        </Popup>
    </div>


}

