import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useDataSourcesApi } from "../../../hooks/useDataSource";
import { CONNECTORS } from "./_Connectors";
import * as globals from "../../../globals";
import axios from "axios";
import { getAuthHeaders } from "../../../api/restMethods";
import { ModalFrame } from "../../modal-dialog/ModalFrame";
import { NewDataSourceContext } from "../NewDataSource";
import { format } from "d3-format";
import { fileSizeFormat } from "../../../utils/FileSizeFormat";
import { XMLParser } from "fast-xml-parser";
import JSON5 from "json5";
import ReactJson from "react-json-view";

const fmt1 = format(",.0f");


export const PasteMetaConnector = (props) => {

    const connector_id = props.connector_id;

    const routerParams = useParams();
    const subscription_id = routerParams.subscription_id;
    const project_id = routerParams.project_id;

    const ndsContext = useContext(NewDataSourceContext);
    const { working, setWorking } = ndsContext;
    const { workStatusMessage, setWorkStatusMessage } = ndsContext;
    const { setButtonText } = ndsContext;
    

    const [newName, setNewName] = useState("");
    const [error, setError] = useState(null);
    const [pasting, setPasting] = useState(false);
    const [pastedData, setPastedData] = useState(null);
    const [parseInfo, setParseInfo] = useState(null);
    const auth = useAuth();
    const api = useDataSourcesApi();

    
    setButtonText("Upload");
    ndsContext.hackHandler.buttonClicked = async () => {
        let id = props.id;
        if (!props.alreadyExists) {
            id = await createDataSource();
        }
        const success = await uploadRevision(id); // returns true if success
        return success ? id : null;
    }

    const createDataSource = async () => {

        let newId = null;

        setWorkStatusMessage("Creating data source...");
        setWorking(true);
        
        try {

            // create data source
            let item = {
                type: "file",
                fileType:
                    parseInfo?.format === "JSON" ? "json" :
                    parseInfo?.format === "XML" ? "xml" : null,
                structured: false,
                connector: connector_id,
                label: newName || parseInfo?.format + " Data source"
            }
            const itemInfo = await api.newItem(item);
            newId = itemInfo.newId;

        }
        catch (err) {
            setError(err.message);
        }
        setWorking(false);

        return newId;
        
    }

    const uploadRevision = async (ds_id) => {

        try {

            setWorkStatusMessage(`Uploading data [${parseInfo.name}]...`);
            setWorking(true);

            const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/data-source/${ds_id}/file`;

            let formData = {
                pastedData
            }
            if (ndsContext.notes) {
                formData.notes = ndsContext.notes;
            }

            await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    ...getAuthHeaders(auth)
                },
                onUploadProgress: (ev) => {
                    console.log("onUploadProgress", ev);
                }
            });

            setWorking(false);
            return true;
            
        }
        catch (error) {
            setError(error.message);
            setWorking(false);
            return false;
        }
    }

    

    //console.log("parseInfo", parseInfo);
    

    return <div className='form1 new-data-source' style={{ maxWidth: 500 }}>

        <div className="upload-data-file">

            <div className="icon" style={{ fontSize: 50, float: "right", marginRight: 10 }}>
                <i className="fal fa-paste" />
            </div>

            <div className="instructions" style={{ maxWidth: 400, marginBottom: 20, fontSize: "10pt" }}>
                {!props.alreadyExists && <>
                    <div>
                        Name:
                    </div>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <input type="text" style={{ width: 370 }} placeholder="Enter data source name" 
                            value={newName}
                            onChange={(ev) => setNewName(ev.target.value)}
                        />
                    </div>
                </>}
                <p>
                    Copy JSON or XML content into clipboard, then click the paste button below:<br />
                </p>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 20,
                    fontSize: 12,
                    //border: "1px dashed teal"
                }}>

                    <button style={{
                        padding: 20
                    }}
                        onClick={async () => {
                            setPasting(true);
                            const str = await navigator.clipboard.readText();
                            setPastedData(str);
                            setParseInfo(parseData(str));
                            //console.log("pastedContent", str);
                            setPasting(false);
                        }}
                    >
                        Paste
                    </button>
                    <div style={{ 
                        //border: "1px dashed red"
                     }}>
                        {pasting ? <div>
                            pasting...
                        </div>
                            :
                            <div style={{ lineHeight: "20px" }}>
                                <div>
                                    {fileSizeFormat(pastedData?.length || 0)} pasted
                                </div>
                                {parseInfo?.error ?
                                    <div>Error</div>
                                    : null
                                }
                                {parseInfo?.format ?
                                    <div>
                                        <b>{parseInfo.format}</b>
                                    </div>
                                    : null
                                }
                                {parseInfo?.format && parseInfo?.data ?
                                    <div>
                                        Click upload button below to continue.
                                    </div>
                                    : null
                                }
                                
                            </div>
                        }
                    </div>

                </div>
            </div>

            <div style={{
                    border: "1px solid #cfcfcf",
                    height: 200,
                    overflowY: "scroll"
                }}>
                    <ReactJson 
                        name={null}
                        iconStyle="circle" 
                        theme={"rjv-default"}
                        displayDataTypes={false}
                        collapsed={1} 
                        src={parseInfo?.data || undefined}
                        style={{ margin: 10 }}
                    />
                </div>

            <div className="input-section">
                {working ?
                    <div style={{ fontSize: "10pt", marginBottom: 5 }}>
                        Please wait. Creating data source...
                    </div>
                    : null
                }
                {(working || workStatusMessage) ?
                    <div style={{ fontSize: "10pt", marginBottom: 5 }}>
                        {workStatusMessage}
                    </div>
                    : null
                }
                
            </div>
            
        </div>

        <div className="label-line">
        </div>

        {error && !working ?
            <div>
                <p style={{ color: "red" }}>
                    <i className="fas fa-warning" />
                    &nbsp;
                    {error}
                </p>
            </div>
            : null
        }
        
    </div>


}



const parseData = (str) => {
    if (!str) return null;

    let data = null;
    let format = null;

    console.log("str", str);

    try{
        data = JSON5.parse(str)
        if( data ){
            return {
                format: "JSON",
                data
            }
        }
    }
    catch{}

    try{
        const parser = new XMLParser({ ignoreAttributes: false, attributeNamePrefix: "@" });
        data = parser.parse(str);
        if( data ) {
            return {
                format: "XML",
                data
            }
        }
    }
    catch(err){
        console.error(err);
    }

    return { 
        error: "Cannot parse as XML or JSON" 
    };

    

}
