import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router";
import Popup from 'reactjs-popup';
import { usePalettes, usePalettesApi } from "../../hooks/usePalette";
import { usePalette } from "../../hooks/usePalette";
import { useProjectId } from "../../hooks/useProjectId";
import { TextFrame, VerifyFrame } from "../modal-dialog/VerifyFrame";
import { NewPalette } from "./NewPalette";
import { SwatchesView } from "./SwatchesView";
import { LeftMenu } from "../nav/LeftMenu";
import { Main } from "../nav/Main";


export const ColorPalettes = (props) => {

    const { project_id, subscription_id } = useProjectId();
    const dispatch = useDispatch();

    const routerParams = useParams();
    const selectedPaletteId = routerParams.id;
    const history = useHistory();

    const api = usePalettesApi();
    
    const [palettes, palettesLoading, palettesError] = usePalettes();
    const [selectedPalette] = usePalette(selectedPaletteId);

    //const selectedPalette = palettes?.find(p => p.id === selectedPaletteId);

    const closeLeftMenu = () => {
        //dispatch({ type: "LEFT_MENU_OPEN_SET", value: false });
    }

    return <>
        
        <LeftMenu>
            <div className='title'>
                Color Palettes
            </div>
            <div className='top-section'>

            </div>
            <div className='scroller'>
                <div className='summary'>
                    <p>
                        Define named color palettes and color arrays. Swatches define both background fill and foreground text colors.
                    </p>
                </div>
                {
                    palettesError ? <div className='list-content error'>Error loading palettes</div> :
                    palettesLoading ? <div className='list-content'>Loading...</div> :
                    !palettes ? null :
                    [...palettes]
                        .sort((a, b) => a.label?.localeCompare(b.label))
                        .map(pal => <div key={pal.id} className={`menu-item ${pal.id === selectedPaletteId ? 'sel' : ''}`}>
                            <span className='grip'>
                                <i className='fad fa-swatchbook' />
                            </span>
                            <Link
                                className='label'
                                to={`/subscription/${subscription_id}/project/${project_id}/palette/${pal.id}`}
                                onClick={() => closeLeftMenu()}
                            >
                                {pal.label}
                            </Link>
                            <Popup
                                nested
                                contentStyle={{ padding: '0px' }}
                                trigger={<span className='menu' ><i className='far fa-ellipsis-h' /></span>}
                                position="right top">
                                {closeMenu => <ul className='popup-menu'>
                                        <Popup modal trigger={
                                            <li>Rename</li>
                                        }>
                                            {closeModal => <TextFrame
                                                title={"Rename color palette"}
                                                label={pal.label}
                                                initialTextValue={pal.label}
                                                close={closeModal} confirmed={async (text) => {
                                                    const newPal = {
                                                        ...selectedPalette,
                                                        label: text
                                                    };
                                                    await api.updateItem(pal.id, newPal);
                                                }}
                                            />}
                                        </Popup>
                                        <Popup modal trigger={
                                            <li>Duplicate palette</li>
                                        }>
                                            {closeModal => <TextFrame
                                                title={"Name of new color palette"}
                                                label={pal.label}
                                                initialTextValue={pal.label + " Copy"}
                                                close={closeModal} confirmed={async (text) => {
                                                    const newPal = {
                                                        ...selectedPalette,
                                                        label: text
                                                    };
                                                    delete newPal.id;
                                                    await api.newItem(newPal);
                                                }}
                                            />}
                                        </Popup>
                                        <Popup modal trigger={
                                            <li>Delete palette</li>
                                        }>
                                            {closeModal => <VerifyFrame
                                                title={"Delete color palette"}
                                                label={pal.label}
                                            close={closeModal}
                                            confirmed={async () => {
                                                await api.deleteItem(pal.id);
                                                // navigate away?
                                            }}
                                        />}
                                        </Popup>
                                </ul>}
                            </Popup>
                        </div>)
                }
                <div className='menu-item-stub center'>
                    <Popup
                        modal
                        trigger={
                            <div className="btn action" >
                                <i className='glyph fal fa-plus' /> New palette
                            </div>
                        }>
                        {close => <NewPalette close={close} onNew={(palInfo) => {
                            console.log('palInfo', palInfo);
                            history.push(`/subscription/${subscription_id}/project/${project_id}/palette/${palInfo.newId}`);
                        }} />}
                    </Popup>
                </div>
                
            </div>
        </LeftMenu>

        <Main>
            {selectedPalette ? <SwatchesView palette_id={selectedPaletteId} /> : null}
        </Main>
        
    </>;


    
}

