import { Link } from "react-router-dom";
import "../MainLayout.scss";
import "../nav/LeftMenu.scss";
import "./Login.scss";
import * as globals from "../../globals";

export const AuthOuter = (props) => {

    const extraStyle = {};
    const extraStyle2 = {};

    if( globals.login_bgColor ){
        extraStyle.backgroundColor = globals.login_bgColor;
    }
    else{
        //extraStyle.background = "linear-gradient(to bottom right, rgba(255,255,255,0.4), rgba(20,150,220,0.9))";
    }

    if( globals.login_bgColor2 ){
        extraStyle2.backgroundColor = globals.login_bgColor2;
    }
    else{
        //extraStyle.background = "linear-gradient(to bottom right, rgba(255,255,255,0.4), rgba(20,150,220,0.9))";
    }

    return <div className="login-page" style={extraStyle}>

        <div className="lower-half" style={extraStyle2}/>

        <div className="form-wrapper">
            <div className="form-area">

                <div className="credentials-form">

                    <Link to="/" className="logo">
                        {/* [m]Studio */}
                        {globals.login_image ? 
                            <div>
                                <img src={globals.login_image}
                                    style={{ height: 70 }}
                                />
                                <hr/>
                            </div>
                            : <span>
                                {globals.player_title || "[m]Studio"}
                            </span>
                        }
                    </Link>

                    {props.showBackLink ?
                        <Link to="/" className="go-back">
                            <i className="fal fa-arrow-left"/> Back
                        </Link>
                        : props.backAction ?
                            <span className="go-back" onClick={() => props.backAction()}>
                                <i className="fal fa-arrow-left"/> Back
                            </span>
                            :
                            <div style={{ marginTop: 10 }} />
                    }
                    

                    <h1>{props.title}</h1>

                    {props.children}

                    <div className="footer-section">

                        {/* <i className="fal fa-question-circle" />
                        &nbsp; <a href="tel:808-721-7530"
                        >
                            808.721.7530
                        </a>
                        <span style={{ color: "#efefef" }}>
                        &nbsp; · &nbsp;
                        </span>
                        <i className="fal fa-envelope" />
                        &nbsp; <a href="mailto:support@metricstudios.com"
                        >support@metricstudios.com</a> */}

                        <i className="fad fa-user-headset" />
                        &nbsp; <a href="https://metricstudios.com/contact" rel="noreferrer" target="_blank"
                        >
                            Support
                        </a>
                        <span className="version-info">
                            <span className="name">v</span>
                            <span className="val">{globals.version}</span>
                            &nbsp;
                            <span className="name">b</span>
                            <span className="val">{globals.build}</span>
                            {globals.player_sub ? 
                                <span className="val">&nbsp;[{globals.player_sub}]</span>
                                : null
                            }
                            &nbsp;
                            
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>



}