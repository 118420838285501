import { useState } from "react";
import { useConfigsApi } from "../../hooks/useConfig";
import { ModalFrame } from "../modal-dialog/ModalFrame";

export const NewConfig = (props) => {

    const [newName, setNewName] = useState("");
    const api = useConfigsApi();

    const createItem = async (pal) => {
        return await api.newItem(pal);
    }

    return <ModalFrame close={props.close}
        title="Create new config"
        buttons={[
            {
                label: "Create",
                disabled: !newName,
                callback: async () => {
                    const item = {
                        label: newName,
                        //type: newType
                    }
                    setNewName("");
                    const itemInfo = await createItem(item);
                    props?.onNew(itemInfo);
                    props.close();
                }
            },
        ]}
    >
        <div className='form1' style={{ maxWidth: 500 }}>
            <label className="label-line">
                Label:
            </label>
            <div className="value-line">
                <input type="text"
                    autoFocus className='full-width' 
                    placeholder="Enter label for new config"
                    value={newName}
                    onChange={(ev) => {
                        setNewName(ev.target.value)
                    }}/>
            </div>

            <div className="label-line">
            </div>

        </div>

    </ModalFrame>

    
}


