import { useEffect, useState } from "react";
import { ControlledCodeEditor, ControlledCodeEditorFillContainer } from "../code-editor/CodeEditor";
import { DataTable } from "../common/DataTable";
import { useConfig, useConfigsApi } from "../../hooks/useConfig";

import "./EditConfig.scss";
//import SplitterLayout from "react-splitter-layout";
import SplitterLayout from "../extlib/react-splitter-layout/SplitterLayout";
import { ProductEdit } from "../model/ProductEdit";
import ReactJson from "react-json-view";
import { ifNDef } from "./AttInput";
import { getKeysFromRows } from "../collections/CollectionEntriesView";
import JSON5 from "json5";
import { EditConfigAtts } from "./EditConfigAtts";

const TABS = [
    {
        key: "pdef",
        label: "Attributes"
    },
    {
        key: "bc",
        label: "Base case"
    },
    {
        key: "options",
        label: "Options"
    },
    
]

const safeJson = (obj) => {
    if (!obj) return null;
    return JSON.stringify(obj, null, 4);
}

export const getDefaultProductState = (atts) => {
    if (!atts) return null;
    
    let state = {};
    for (let att of atts) {
        const fallback = (att.type === "contin" || att.type === "range") ? (att.min + att.max) / 2.0 : 1;
        const defaultValue = ifNDef(att.defaultValue, fallback);
        state[att.name] = defaultValue;
        // todo: support of multi-select checkbox atts would require adjustment here
    }
    return state;
}

const getColumns = (rows) => {
    if (!rows) return null;
    if (rows.length < 1) return null;
    return Object.keys(rows[0]).map(key => {
        return {
            name: key,
            label: key,
            key: key
        }
    });
}

const getExpectedColumns = (atts) => {
    const cols = atts?.map(att => {
        if (att.type === "string") {
            return {
                key: att.name,
                label: att.name,
                type: "string"
            }
        }
        else {
            return {
                key: att.name,
                label: att.name,
                type: "number"
            }
        }
    })
    return [
        {
            key: "id",
            label: "id",
            type: "string"
        },
        {
            key: "label",
            label: "label",
            type: "string"
        },
        ...(cols || [])
    ]
}

const generateRandomBaseCase = (atts, nbRows) => {

    let rows = [];
    for (let i = 0; i < nbRows; i++){
        let row = {
            id: "p" + (i + 1),
            label: "Product " + (i + 1)
        };
        atts.forEach(att => {
            const defaultValue = ifNDef(att.defaultValue, 1);
            row[att.name] = defaultValue;

            const options = att.options || att.levels;
            if (options) {
                const pick = Math.floor(Math.random() * options.length);
                const lev = options[pick];
                if (lev) {
                    row[att.name] = lev?.value
                }
            }
            else if (att.type === "checkbox" || att.type === "bool") {
                const pick = Math.round(Math.random());
                const trueValue = ifNDef(att.trueValue, 1);
                const falseValue = ifNDef(att.falseValue, 0);
                row[att.name] = pick ? trueValue : falseValue;
            }
        });
        rows.push(row);
    }
    return rows;

}

export const EditConfig = (props) => {

    const id = props.id;
    console.log('EditConfig.id', id);
    const [config, configLoading, configError] = useConfig(id);
    const api = useConfigsApi();

    console.log("config", config);

    const [selTab, setSelTab] = useState(TABS[0].key);
    const [attsCode, setAttsCode] = useState(config?.attsString || safeJson(config?.atts));
    const [testProductState, setTestProductState] = useState({});
    //const [baseCaseColumns, setBaseCaseColumns] = useState(null);
    const baseCaseData = config?.baseCase;

    useEffect(() => {
        setAttsCode(config?.attsString || safeJson(config?.atts));

        // set testProductState
        if (config?.atts) {
            setTestProductState(getDefaultProductState(config.atts));
        }

    }, [config?.atts])


    const saveAttributes = async () => {
        console.log('saveAttributes', id);
        try {
            const newItem = {
                ...config,
                atts: JSON5.parse(attsCode),
                attsString: attsCode
            };
            await api.updateItem(id, newItem);
        }
        catch (err) {
            alert(err.message);
        }
    }

    const saveBaseCase = async (newData) => {
        const newItem = {
            ...config,
            baseCase: newData
        };
        await api.updateItem(id, newItem);
    }

    const copyBaseCaseJson = async () => {
        const data = config?.baseCase;
        const dataStr = JSON.stringify(data, null, 4);
        await navigator.clipboard.writeText(dataStr);
        alert('copied');

    }

    const copyBaseCase = async () => {

        const data = config?.baseCase;

        const keys = getKeysFromRows(data);
        if (!keys) return;

        const headerStr = keys.join("\t");
        const rowsStr = data.map(entry => {
            return keys.reduce((res, key) => [...res, entry[key]], []).join('\t');
        }).join("\r\n");

        const dataStr = headerStr + "\r\n" + rowsStr;
        
        await navigator.clipboard.writeText(dataStr);
        alert('copied');

    }

    const pasteBaseCase = async () => {

        const str = await navigator.clipboard.readText();
        if (!str) {
            alert("Clipboard has nothing to paste");
            return;
        }
        
        const rowStrs = str
            .split("\n")
            .map(rowStr => {
                if (rowStr.endsWith("\r")) {
                    return rowStr.substr(0, rowStr.length - 1);
                }
                return rowStr;
            });
        if (rowStrs.length < 1) {
            alert("Clipboard has no rows");
        }
        
        const headerRowStr = rowStrs.shift();
        const headerKeys = headerRowStr.split("\t")
            .map(key => key?.trim())
            ;

        let newData = rowStrs.map(rowStr => {
            let obj = {};
            let arr = rowStr.split("\t");
            let foundValue = false;
            for (let keyIndex = 0; keyIndex < headerKeys.length; keyIndex++){
                let key = headerKeys[keyIndex];
                if (key) {
                    const val = arr[keyIndex] 
                    obj[key] = val;
                    if (val) {
                        foundValue = true;
                    }
                }
            }
            return foundValue ? obj : null;
        })
            .filter(_ => _) // remove empty rows
        ;

        //console.log("newEntries", newEntries);
        const newItem = {
            ...config,
            baseCase: newData
        }
        await api.updateItem(id, newItem);
        alert('Entries pasted');

    }

    

    const optionsRowStyle = {
        padding: "5px",
        display: "flex",
        alignItems: "center",
    }

    const baseCaseColumns = selTab === "bc" ?
        getExpectedColumns(config?.atts)
        : null;

    return <div className='edit-config'>
        <div className="config-header">
            
            <div className="tab-section no-margin">
                {TABS.map(tab =>
                    <span
                        className={`tab ${tab.key === selTab ? "sel" : ""}`}
                        onClick={() => setSelTab(tab.key)}
                    >
                        {tab.label}
                    </span>
                )}
            </div>
        </div>

        {selTab === "pdef" ?
            <>
                <div className="config-sel-header">
                    <span
                        className="btn action"
                        onClick={() => saveAttributes()}
                    >
                        Save changes
                    </span>
                </div>
                <div className="config-sel">
                    <SplitterLayout
                        vertical={false}
                        secondaryInitialSize={300}
                        primaryMinSize={50}
                        onSecondaryPaneSizeChange={(secondaryPaneSize) => {
                            //setPreviewSize(secondaryPaneSize);
                        }}
                    >
                        <div style={{
                            //border: "5px solid blue",
                            position: "relative",
                            height: "100%",
                            overflow: "hidden"
                        }}>
                            <EditConfigAtts id={id} />
                            {/* <ControlledCodeEditorFillContainer value={attsCode} onChange={(code) => setAttsCode(code)} /> */}
                        </div>

                        <div style={{
                            //border: "5px solid orangered",
                            position: "relative",
                            height: "100%"
                        }}>
                            <SplitterLayout
                                vertical={true}
                                percentage={true}
                                
                                secondaryInitialSize={50}
                            >
                                <ProductEdit
                                    label={"Test Product"}
                                    style={{ width: "100%" }}
                                    atts={config?.atts}
                                    value={testProductState}
                                    onChange={(newState) => setTestProductState(newState)}
                                />
                                <div className="full-dock" style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",

                                    //border: "2px dashed teal"
                                }}>
                                    <div className="top" style={{
                                        textAlign: "center",
                                        fontWeight: 500,
                                        padding: 5
                                    }}>
                                        Test JSON
                                    </div>
                                    <div
                                        className="center"
                                        style={{ padding: 10, overflowY: "scroll" }}
                                        
                                    >
                                        <ReactJson src={testProductState}
                                            name={null}
                                            theme={"rjv-default"}
                                            iconStyle="circle"
                                            displayDataTypes={false}
                                            enableClipboard={true} />
                                    </div>
                                </div>
                            </SplitterLayout>
                        </div>
                    </SplitterLayout>
                </div>
            </>
            : null
        }

        {selTab === "bc" ?
            <>
                <div className="config-sel-header">
                    <span className="btn action margin-right">
                        <i className="fal fa-arrow-to-top"/>&nbsp; Upload file...
                    </span>
                    <span className="btn action margin-right"
                        onClick={() => copyBaseCase()}
                    >
                        <i className="fad fa-copy"/>&nbsp; Copy (as table)
                    </span>
                    <span className="btn action margin-right"
                        onClick={() => pasteBaseCase()}
                    >
                        <i className="fad fa-paste"/>&nbsp; Paste (table)
                    </span>
                    <span className="btn action margin-right"
                        onClick={() => copyBaseCaseJson()}
                    >
                        <i className="fad fa-copy"/>&nbsp; Copy as JSON
                    </span>

                    <span className="btn action" style={{ float: "right" }}
                        onClick={() => {
                            const rows = generateRandomBaseCase(config?.atts, 10);
                            saveBaseCase(rows);
                        }}
                    >
                        <i className="fad fa-random"/>&nbsp; Generate Random
                    </span>
                </div>
                <div className="config-sel">
                    <DataTable columns={baseCaseColumns} data={baseCaseData}/>
                </div>
            </>
            : null
        }

        {selTab === "options" ?
            <>
                <div className="config-sel-header">
                    <span className="action btn">
                        Save changes
                    </span>
                </div>
                <div className="config-sel" style={{ padding: 15 }}>
                    {/* <div style={optionsRowStyle}>
                        Default layout: &nbsp;
                        <select style={{ width: 200 }}>
                            <option>[select]</option>
                        </select>
                    </div> */}
                    <div style={optionsRowStyle}>
                        <input type="checkbox"/>&nbsp; 
                        User can create (and delete) new products
                    </div>
                    <div style={optionsRowStyle}>
                        <input type="checkbox"/>&nbsp; 
                        User can edit product labels
                    </div>
                    
                </div>
                
                    
                
            </>
            : null
        }


    </div>
}