import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useProject, useProjectsApi } from "../../hooks/useProject";
import { Main } from "../nav/Main";
import "./ProjectHome.scss";
import { CollectionsTile } from "./tiles/CollectionsTile";
import { ContentTile } from "./tiles/ContentTile";
import { DataSourcesTile } from "./tiles/DataSourcesTile";
import { HelpTile } from "./tiles/HelpTile";
import { PagesTile } from "./tiles/PagesTile";
import { ProjectInfoTile } from "./tiles/ProjectInfoTile";
import { ProjectUsersTile } from "./tiles/ProjectUsersTile";
import { ReportsTile } from "./tiles/ReportsTile";


export const ProjectHome = (props) => {

    //const {project_id, subscription_id} = useProjectId();
    
    const history = useHistory();
    
    const [project, projectLoading, projectError] = useProject();
    const api = useProjectsApi();

    const [saving, setSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [saveMessage, setSaveMessage] = useState(null);

    const [deleting, setDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null);

    const [projectLabel, setProjectLabel] = useState(project?.label);
    const [projectClient, setProjectClient] = useState(project?.client);
    const [projectCopyright, setProjectCopyright] = useState(project?.copyright);
    const [projectFootnote, setProjectFootnote] = useState(project?.footnote);

    useEffect(() => {
        //console.log('ProjectInfo effect', project)
        if (!project) return null;
        setProjectLabel(project.label);
        setProjectClient(project.client);
        setProjectCopyright(project.copyright);
        setProjectFootnote(project.footnote);

    }, [project])
    
    const saveChanges = async () => {
        const obj = {
            ...project,
            label: projectLabel,
            client: projectClient,
            copyright: projectCopyright,
            footnote: projectFootnote
        }
        setSaving(true);
        try {
            await api.updateItem(project.id, obj);
            //await updateProject(obj);
            setSaveMessage("Saved");
        }
        catch (err) {
            setSaveError(err?.message);
        }
        setSaving(false);
    }


    return <Main noLeftMenu>

        <div className="empty-space1" style={{ xposition: 'relative', overflowY: "auto" }} >

            <div className="project-home" >


                <div className="tile-container">

                    <ProjectInfoTile/>

                    <DataSourcesTile/>

                    {/* <ReportsTile/> */}

                    <ContentTile/>

                    <CollectionsTile/>

                    {/* <PagesTile/> */}

                    

                    <ProjectUsersTile/>

                    <HelpTile />

                </div>

                


            </div>
            
        </div>

    </Main>;


    
}