import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Popup from "reactjs-popup";
import { useCollection, useCollectionsApi } from "../../hooks/useCollection";
import { ControlledCodeEditor, ControlledCodeEditorFillContainer } from "../code-editor/CodeEditor";
import { DataTable } from "../common/DataTable";
import "./CollectionEntriesView.scss";
import { CollectionEntryEditor } from "./CollectionEntryEditor";
import { CollectionEntryTile } from "./CollectionEntryTile";
import JSON5 from "json5";
import { ReactSortable } from "react-sortablejs";
import { FieldSearch } from "../common/FieldSearch";
import { isMatch } from "../../utils/SearchHelper";
import { debounce } from "../../utils/debounce";


const VIEWS = [
    { key: "tiles", label: "Tiles" },
    { key: "table", label: "Table" },
    { key: "json", label: "Json" },
]

export const getKeysFromRows = (rows, initialKeys) => {
    let cols = initialKeys || [];
    rows?.forEach(entry => {
        const keys = Object.keys(entry);
        keys?.forEach(key => {
            if (!cols.includes(key)) {
                cols.push(key);
            }
        })
    });
    return cols;
}

const idify = (label) => {
    const ret =(label || "")
        .toLowerCase()
        .replaceAll(" ", "-")
        .replaceAll(/[^a-zA-Z0-9-]/g, "");
    if (ret?.endsWith("s")) {
        return ret.substring(0, ret.length - 1);
    }
    return ret;
}

const getUnusedId = (list, name = "entry") => {
    let i = 1;
    while (list.find(item => item.id === `${name}${i}`)) {
        i++;
    }
    return `${name}${i}`;
}

const toPrettyJson = (obj) => JSON.stringify(obj, null, 2);

const debounce1000 = debounce((callback, ...args) => {
    callback(...args);
}, 500);


export const CollectionEntriesView = (props) => {

    const [searchText, setSearchText] = useState(null);
    const [searchTerms, setSearchTerms] = useState(null);

    const routerParams = useParams();
    const project_id = routerParams.project_id;
    const collection_id = props.collection_id;

    const api = useCollectionsApi();
    const [collection, collectionLoading, collectionError] = useCollection(collection_id);
    const [view, setView] = useState("tiles");

    const [code, setCode] = useState(toPrettyJson(collection?.entries));
    useEffect(() => {
        setCode(toPrettyJson(collection?.entries));
    }, [collection]);

    const [savingCode, setSavingCode] = useState(false);
    const [saveCodeError, setSaveCodeError] = useState(null);

    const updateSearchTerms = (stext) => {
        const terms = stext ? stext.split(" ") : null;
        console.log("updatingSearchTerms", terms);
        setSearchTerms(terms);
        // const options = {};
        // props.onChange?.({ terms, options });
    }

    let list = collection?.entries?.map((i, idx) => ({
        payload: i,
        id: i.id || idx
    })) || [];

    if( searchTerms ){
        list = list.filter(entry => isMatch(entry, searchTerms, (entry) => entry.payload?.label));
        console.log("filtered list", list);
    }
        
    const [hideHover, setHideHover] = useState(false);
    let saveList = null;

    let tableColumns = [];
    if (view === "table") {
        collection?.entries?.forEach(entry => {
            const keys = Object.keys(entry);
            keys?.forEach(key => {
                if (!tableColumns.find(c => c.key === key)) {
                    tableColumns.push({ key, label: key });
                }
            })
        })
    }
       
    const content =
        collectionError ? <div className="list-content error">Error loading collection</div> :
        collectionLoading ? <div className="list-content unknown">Loading...</div> :
        !list ? null :
        view === "tiles" ?            
            <ReactSortable
                className='list-content tiles'
                animation={200}
                delayOnTouchStart={true}
                delay={2}
                list={list}
                setList={(list) => {
                    saveList = list; // save this ordering so we can updateCollection onEnd
                }}
                onStart={() => setHideHover(true)}
                onEnd={async () => {
                    
                    const newEntries = saveList.map(i => ({
                        ...i.payload
                    }));
                    const newColl = {
                        ...collection,
                        entries: newEntries
                    }
                    await api.updateItem(collection_id, newColl);
                    setHideHover(false);
                }}
        >
            {list?.map((item, itemIdx) => {
                
                const id = item.id;
                const key = `[${itemIdx}] ${id}`;
                const tile = <CollectionEntryTile
                    key={key}
                    hideHover={hideHover}
                    item={item.payload}

                    onDelete={async () => {
                        // delete item
                        const newList = list.filter(i => i.id !== item.id);
                        const newEntries = newList.map(i => ({ ...i.payload }));
                        const newColl = {
                            ...collection,
                            entries: newEntries
                        }
                        await api.updateItem(collection_id, newColl);
                    }}

                    onSave={async (newEntry) => {
                        const newList = list.map(i => i.id !== item.id ? i : { id: newEntry.id, payload: newEntry });
                        const newEntries = newList.map(i => ({
                            ...i.payload
                        }))
                        const newColl = {
                            ...collection,
                            entries: newEntries
                        }
                        await api.updateItem(collection_id, newColl);
                    }}
                    
                />;

                return tile;
            })}
        </ReactSortable>
        : view === "table" ?
            <div style={{
                position: "relative",
                
            }}>
                            
            {/* <DataTable columns={tableColumns} data={collection?.entries} style={{ height: "100%" }}/> */}
            <DataTable autoGenerateColumns data={collection?.entries} style={{ height: "100%" }}/>
                            
        </div>
        : view === "json" ? <div>
            <ControlledCodeEditorFillContainer language="json" value={code} onChange={(newCode) => setCode(newCode)} />
        </div>
        : <div>unknown view</div>;

    
    return <div className="collection-entries-viewbox">

        <div className='top-header'>
            <span className='left-section'>
            </span>
            <span className='right-section'>
                {VIEWS.map(v => <span key={v.key} className={`view-button ${view === v.key ? "sel" : ""}`} onClick={() => setView(v.key)}>
                    {v.label}
                </span>)}
            </span>
        </div>

        <div className='actions-header'>

            {view === "tiles" ?
                <span>
                    <span className="entry-search">

                        <span className="icon">
                            <i className="fal fa-search"/>
                        </span>

                        <span className="search-main">
                            <span className="text">
                                <input 
                                    type="text" 
                                    className="tb1"
                                    value={searchText || ""}
                                    placeholder={"search entries"}
                                    onChange={(ev) => {
                                        const str = ev.target.value;
                                        setSearchText(str);
                                        debounce1000(updateSearchTerms, str);
                                    }}/>
                            </span>
                        </span>
                    </span>
                    <span className='btn action' onClick={async () => {

                        const name = idify(collection.label);
                        //console.log('name!', name);
                        const newId = getUnusedId(list, name);
                        const newEntry = {
                            id: newId,
                            label: "New entry"
                        };
                        const newEntries = [...(collection.entries || []), newEntry];
                        const newColl = {
                            ...collection,
                            entries: newEntries
                        }
                        await api.updateItem(collection_id, newColl);
                    }}>
                        <i className='fal fa-plus' />&nbsp; New Entry
                    </span>
                </span>
                : null
            }

            {view === "json" ?
                <span className={`btn action ${savingCode ? "disabled" : ""}`} onClick={async () => {

                    if (savingCode) return;

                    // test parse to verify that JSON is valid
                    let newColl = null;
                    try {
                        const newEntries = JSON5.parse(code);
                        newColl = {
                            ...collection,
                            entries: newEntries
                        }
                    }
                    catch (err) {
                        alert("Invalid JSON. Save cancelled. Please fix.");
                        return;
                    }
                    

                    try {
                        setSavingCode(true);
                        await api.updateItem(collection_id, newColl);
                        setSavingCode(false);
                    }
                    catch (err) {
                        setSaveCodeError(err.message);
                        setSavingCode(false);
                    }
                }}>
                    {savingCode ? "Saving..." : "Save Changes"}
                </span>
                : null
            }

            {view === "table" ?
                <>
                    <span className={`btn action margin-right`} onClick={async () => {
                        
                        const keys = getKeysFromRows(collection?.entries, ["id", "label"]);
                        if (!keys) return;

                        const headerStr = keys.join("\t");
                        const rowsStr = collection.entries.map(entry => {
                            return keys.reduce((res, key) => [...res, entry[key]], []).join('\t');
                        }).join("\r\n");

                        const dataStr = headerStr + "\r\n" + rowsStr;
                        
                        await navigator.clipboard.writeText(dataStr);
                        alert('Entries copied');

                    }}>
                        Copy
                    </span>
                    
                    <span className={`btn action`} onClick={async () => {

                        const str = await navigator.clipboard.readText();
                        if (!str) {
                            alert("Clipboard has nothing to paste");
                            return;
                        }
                        
                        const rowStrs = str
                            .split("\n")
                            .map(rowStr => {
                                if (rowStr.endsWith("\r")) {
                                    return rowStr.substr(0, rowStr.length - 1);
                                }
                                return rowStr;
                            });
                        if (rowStrs.length < 1) {
                            alert("Clipboard has no rows");
                        }
                        
                        const headerRowStr = rowStrs.shift();
                        const headerKeys = headerRowStr.split("\t")
                            .map(key => key?.trim())
                            ;

                        let newEntries = rowStrs.map(rowStr => {
                            let obj = {};
                            let arr = rowStr.split("\t");
                            let foundValue = false;
                            for (let keyIndex = 0; keyIndex < headerKeys.length; keyIndex++){
                                let key = headerKeys[keyIndex];
                                if (key) {
                                    const val = arr[keyIndex] 
                                    obj[key] = val;
                                    if (val) {
                                        foundValue = true;
                                    }
                                }
                            }
                            return foundValue ? obj : null;
                        })
                            .filter(_ => _) // remove empt rows
                        ;

                        //console.log("newEntries", newEntries);
                        const newColl = {
                            ...collection,
                            entries: newEntries
                        }
                        alert('Entries pasted');

                        await api.updateItem(collection_id, newColl);
                        

                    }}>
                        Paste
                    </span>
                </>
                : null
            }



        </div>

        {content}
        
    </div>

    


}




