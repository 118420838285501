import { useContext } from "react"
import { DisplayContext, getDisplayClass } from "./DisplayContext"
import "./ContentAdderButton.scss";
import Popup from "reactjs-popup";
import { SelectNewContentItem } from "./SelectNewContentItem";
import { EditContentContext } from "./EditContentContext";

const isNull = (x, fallback) => x === null || x === undefined ? fallback : x;

export const ContentAdderButton = (props) => {

    const display = useContext(DisplayContext);
    const displayClass = getDisplayClass(display);
    const editContext = useContext(EditContentContext);
    const label = isNull(props.label, "Add Content");

    const style={ ...props.style };

    return <Popup
        modal
        contentStyle={{
            width: 600,
            height: 300
        }}
        trigger={
            <div className={`content-adder-button ${displayClass} ${props.className || ""}`} style={style}>
                <div className="internal">
                    <div>
                        <i className="fal fa-plus-circle"/>
                        {label && <span className="label">{label}</span>}
                    </div>
                    {props.description && 
                        <div className="description">
                            {props.description}
                        </div>
                    }
                </div>
            </div>
        }
    >
        {close => <SelectNewContentItem onClick={entry => {
            close();
            editContext.addChild?.(entry, props.keyName)
        }}/>}
    </Popup>

}