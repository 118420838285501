import { useContext, useState } from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { controllers } from "../api/controllers";
import { AuthContext } from "../context/Contexts";
//import { apis, useApi } from "../api/_apis";
import { genericParseEntry } from "../store/CreateGenericReducer";
import { useAuth } from "./useAuth";
import { useProjectId } from "./useProjectId";

export const createUseChildItemsHook = (options) => (parent_id) => {

    const [reloadCounter, setReloadCounter] = useState(1);
    const [lastReload, setLastReload] = useState(1);

    const auth = useAuth();
    const authContext = useContext(AuthContext);

    const {project_id, subscription_id} = useProjectId();
    const dispatch = useDispatch();


    const name = options.name;
    const namePlural = options.namePlural || name + "s";
    const storeName = options.storeName || namePlural;
    const reduxNamePlural = options.reduxName || namePlural.toUpperCase();
    const controller = controllers[options.name];
    
    const { entries, loading, error } = useSelector(state => ({
        entries: state[storeName][parent_id]?.entries,
        loading: state[storeName][parent_id]?.loading,
        error: state[storeName][parent_id]?.error
    }), shallowEqual)

    const parentAssignment = {
        [options.parents[0] + "_id"]: parent_id
    }

    useEffect(() => {
        
        const forceReload = reloadCounter != lastReload;

        if (project_id && parent_id && !loading && (forceReload || (!entries && !error))) {
            dispatch({ type: `${reduxNamePlural}_SET_LOADING_ENTRIES`, parent_id });
            (async () => {
                try {
                    const _entries = await controller.auth_getItems(auth, { subscription_id, project_id, ...parentAssignment });
                    if( _entries?.error ){
                        if( _entries.error === "invalid_token"){
                            dispatch({ type: "INVALID_TOKEN_ENCOUNTERED" });
                            authContext.getNewToken();
                        }
                        throw Error(_entries.error);
                    }

                    dispatch({ type: `${reduxNamePlural}_SET_ENTRIES`, parent_id, entries: _entries });
                }
                catch (err) {
                    dispatch({ type: `${reduxNamePlural}_SET_LOADING_ENTRIES`, parent_id, loading: false, error: err.message });
                    //dispatch({ type: `${reduxNamePlural}_SET_ENTRIES`, entries: null, error: err.message });
                }
                setLastReload(reloadCounter);
            })();
        }
    }, [parent_id, project_id, reloadCounter]);

    const reload = () => {
        setReloadCounter(reloadCounter+1);
        
    }

    return [
        entries,
        loading,
        error,
        reload
    ]
    
}

export const createUseChildItemHook = (options) => (parent_id, id) => {
    
    const name = options.name;
    const namePlural = options.namePlural || name + 's';
    const storeName = options.storeName || namePlural;
    const parseEntry = options.parseEntry || genericParseEntry;
    const reduxName = name.toUpperCase(); // SHOULDN'T WE CHECK construct FOR THIS?

    const [reloadCounter, setReloadCounter] = useState(1);
    const [lastReload, setLastReload] = useState(1);

    const {project_id, subscription_id} = useProjectId();
    const auth = useAuth();
    const authContext = useContext(AuthContext);

    const dispatch = useDispatch();
    //const api = useApi();
    const controller = controllers[name];

    

    const { item, loading, error } = useSelector(state => ({
        item: state[storeName][parent_id]?.dict?.[id],
        loading: state[storeName][parent_id]?.entryLoading?.[id],
        error: state[storeName][parent_id]?.entryError?.[id],
    }), shallowEqual);

    const parentAssignment = {
        [options.parents[0] + "_id"]: parent_id
    };

    useEffect(() => {
        const forceReload = reloadCounter != lastReload;

        if( id && !loading && (forceReload || (!item && !error)) ){
            dispatch({ type: `${reduxName}_SET_LOADING`, parent_id, id });
            (async () => {
                try {
                    const value = await controller.auth_getItem(auth, { subscription_id, project_id, ...parentAssignment }, id);
                    if( value?.error ){
                        if( value.error === "invalid_token" ){
                            dispatch({ type: "INVALID_TOKEN_ENCOUNTERED" });
                            authContext.getNewToken();
                        }
                        throw Error(value?.error);
                    }

                    const newItem = {
                        ...value,
                        id
                    }
                    const newEntry = parseEntry(newItem);
                    dispatch({
                        type: `${reduxName}_SET`,
                        id,
                        parent_id,
                        entry: newEntry,
                        value: newItem
                    });
                }
                catch (err) {
                    dispatch({ type: `${reduxName}_SET_LOADING`, parent_id, id, loading: false, error: err.message });
                    //dispatch({ type: `${reduxName}_SET`, id, value: null, error: err.message });
                }
                setLastReload(reloadCounter);
            })();
        }
    }, [subscription_id, project_id, id, reloadCounter])

    const reload = () => {
        setReloadCounter(reloadCounter+1);
    }

    //console.log(`useChildItemsHook(${parent_id}, ${id}) [${storeName}] returning`, [item, loading, error, reload]);
    return [
        item,
        loading,
        error,
        reload
    ];

}