import { useContext, useState } from "react";
import { HasRenderTableContext } from "../../../context/Contexts";
import { ControlledCodeEditorFillContainer } from "../../code-editor/CodeEditor";
import "./EditRenderTableSyntax.scss";

export const EditRenderTableSyntax = (props) => {

    const { renderTable, setRenderTable } = useContext(HasRenderTableContext);
    const [syntax, setSyntax] = useState(renderTable);

        
    const style1 = {
        borderBottom: "1px solid #cfcfcf",
        padding: "5px 10px",
        textAlign: "center"
    }
    

    return <div className="edit-render-table-syntax fill-parent-absolute full-dock">
        <div className="top" style={style1}>
            <span className="btn action-light" style={{ border: "1px solid #cfcfcf" }}
                onClick={() => setRenderTable(syntax)}
            >
                <i className="fal fa-check"/>&nbsp; Apply
            </span>
        </div>
        <div className="center fill-parent-absolute">
            <ControlledCodeEditorFillContainer
                value={syntax}
                onChange={async (newSyntax) => {
                    setSyntax(newSyntax)
                    // note: don't notify consumer until 'save' is clicked
                }}
            />
        </div>
    </div>

    ;

}