import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { atomFamily, useRecoilState, useRecoilValue } from "recoil";
import { computeApi } from "../../api/computeApi";
import { ProjectContext } from "../../context/Contexts";
import { reduceResources } from "../../hooks/helper/reduceResources";
import { useAuth } from "../../hooks/useAuth";
import { useLazyLib } from "../../hooks/useLazyLib";
import { useProject } from "../../hooks/useProject";
import { useProjectId } from "../../hooks/useProjectId";
import { useResources } from "../../hooks/useResources";
import { compileJs100 } from "../../utils/ContentProcessor";
import { defaultLib } from "../../utils/Lib";
import { ContentAdderButton } from "../content/ContentAdderButton";
import { DisplayContext } from "../content/DisplayContext";
import { EditContentContext } from "../content/EditContentContext";
import { PresentContent } from "../content/PresentContent";
import { computeContextSelector, computeContextState, computeContextStates, ComputeRunnerContext, computeRunnerForScopeState } from "./ComputeContext";




export const ComputeJsContext = (props) => {

    const id = props.id;
    const ec = useContext(EditContentContext);
    //console.log("editContext is ", ec);
    const mode = ec.mode;
    const item = ec.item;
    const firstChild = item?.children?.[0];

    const auth = useAuth();
    const { subscription_id, project_id } = useProjectId();

    // get PARENT data from hook (if any)
    const parentState = useRecoilValue(computeContextStates(item?.jdata?.contextHooks));//(item?.jdata?.contextHooks));
    //console.log("parentState", parentState);
    // item?.jdata?.contextHooks?.forEach(cHook => {
    //     parentState[cHook.name] = useRecoilValue(computeContextState(cHook?.id));    
    // });


    const syntax = item.jdata?.syntax
    
    const lib2 = useLazyLib();
    const lib = {
        ...defaultLib,
        ...lib2
    }

    const resources = props.resources;

    const [ccState, setCCState] = useRecoilState(computeContextState(id));
    const [runCounter, setRunCounter] = useState(0);
    const [lastRunCounter, setLastRunCounter] = useState(0);

    const setStatus = (newStatus, clear_state) => {
        // the problem is that ccState isn't necessarily the latest!
        // console.log("setStatus called");
        // console.log("old ccState was", ccState);
        const newState = {
            // result: clear_state ? null : ccState?.result,
            // // needsCompute? should that be here?
            running: true,// ccState.running,
            status: newStatus
        };
        //console.log("setting new state as", newState);
        setCCState(newState);
    }
    
    const run = async () => {

        // update status
        setCCState({
            result: ccState?.result, // keep old value until the new one is ready
            running: true
        });

        // run
        try{
            const context = { subscription_id, project_id, parentState, project: ec?.project };
            const env = { resources, context };
            const revision = ec.dsRevisionSlot;

            // api contains methods that are passed into render function
            const api = {
                computePipeline: (datasource_id, stages, internalOptions) => {
                    //console.log("api.computePipeline", datasource_id, stages, internalOptions)
                    return computeApi.computeV2(auth, env, subscription_id, project_id, datasource_id, revision, stages, internalOptions)
                }
            };
            const f = compileJs100(syntax, context, resources, api, lib, setStatus);
            const r = await f();
            setCCState({
                result: r,
            })
        }
        catch( err ){
            setCCState({
                result: null, // keep existing result or not?
                error: err.message
            })
        }

        setLastRunCounter(runCounter);
    }

    const runAfter = () => {
        setRunCounter(runCounter + 1);
    }

    const crc = useContext(ComputeRunnerContext);
    const new_crc = {
        ...crc,
        [id]: {
            run,
            runAfter
        }
    }

    // run if auto recalc
    useEffect(() => {
        if((runCounter === lastRunCounter) && item.jdata?.recalc !== "auto" ) return;
        run();
    }, [syntax, resources, runCounter] )

    const style = {
        //backgroundColor: "#ffffff",
        //padding: "5px 10px",
        ...props.style,
        ...item?.style
    }

    return <ComputeRunnerContext.Provider value={new_crc}>
        <div className={`mstudio-compute-context ${props.displayClass}`} style={style} {...props.atts}>
            {(firstChild || mode === "edit") &&
                <>
                    {firstChild ?  
                        <PresentContent id={firstChild.id} />
                        :
                        <DisplayContext.Provider value={"block-fill"}>
                            <ContentAdderButton label="Set content" xdescription="Hint: Use a layout (e.g., stack or grid) if multiple content items are needed" />
                        </DisplayContext.Provider>
                    }
                </>
            }
        </div>
    </ComputeRunnerContext.Provider>
}