import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { computeApi } from "../../../api/computeApi";
import { ContentItemContext } from "../../../context/Contexts";
import { useAuth } from "../../../hooks/useAuth";
import { useImage } from "../../../hooks/useImage";
import { useProjectId } from "../../../hooks/useProjectId";
import { reduceResources } from "../../../hooks/helper/reduceResources";
import { useResources } from "../../../hooks/useResources";
import { useShallowCompareEffect } from "../../../hooks/useShallowCompareEffect";
import { runCompute, runRender } from "../../../utils/ContentProcessor";
import { getImagePath } from "../../../utils/ImageUtils";
import { defaultLib } from "../../../utils/Lib";
import { DataTable } from "../../common/DataTable";
import { EditContentItemWrapper } from "../../edit/EditContentItemWrapper";
import { ExportContentItemWrapper } from "../../edit/ExportContentItemWrapper";
import { ErrorBoundary } from "../../error-boundary/ErrorBoundary";
import { PageEditorContext } from "../../pages/Pages";
import "./ViewJsxCard.scss";
import { compileFunction } from "../../../utils/Compiler";
import { useLazyLib } from "../../../hooks/useLazyLib";

export const ViewJsxCard = (props) => {

    const lib2 = useLazyLib();
    //console.log("lib2!", lib2);

    const {project_id, subscription_id} = useProjectId();
    const item = props.content_item;
    const context = useContext(ContentItemContext);
    const dispatch = useDispatch();
    const auth = useAuth();

    const peContext = useContext(PageEditorContext);
    const isDev = peContext?.isDev;

    const [backgroundImage] = useImage(item?.background?.imageId);
    const backgroundImagePath = backgroundImage ? getImagePath(subscription_id, project_id, backgroundImage?.filename) : null;

    const [resources, resourcesLoading, resourcesError] = useResources(item?.resources, true);
    const allResourcesReady = item && lib2 && (
        (resources && !resourcesLoading) || !item?.resources?.length
    );

    let rResources = null;
    if (allResourcesReady) {
        rResources = reduceResources(resources, subscription_id, project_id, dispatch, auth);
        rResources.compute = () => compute();
    }
    //const resourcesEqualityHash = allResourcesReady ? resources?.equalityHash : null;

    
    const lib = {
        ...defaultLib,
        ...lib2
    }
    
    
    const [dataError, setDataError] = useState(null);
    const [rendered, setRendered] = useState(null);
    const [renderError, setRenderError] = useState(null);
    const [viewAsTable, setViewAsTable] = useState(false);

    const [lastComputeId, setLastComputeId] = useState(0);
    const [computeCounter, setComputeCounter] = useState(1);
    

    // const [data, setData] = useState(null);
    // const [computing, setComputing] = useState(false);
    const [payload, setPayload] = useState({
        data: null,
        computing: false,
        needsCompute: false,
        status: null
    });

    const statusFunc = (newStatus) => {
        //if( !mounted ) return;
        setPayload({ ...payload, status: newStatus });
    }

    const compute = async (mounted) => {
        //console.log("compute called");
        try {
            setPayload({ ...payload, computing: true });

            const env = {
                resources: rResources,
                context,
                lib
            }

            let pipelines2 = item?.pipelines
                .filter(p => !p.disabled)
                .map(p => {
                    const stages = p.stages
                        ?.filter(s => !s.disabled)
                        ?.map(s => s.data);
                    return {
                        id: p.id,
                        dataSourceId: p.dataSourceId,
                        stages,
                        compute: async (args) => {
                            const res = await computeApi.computeV2(
                                auth, { ...env, args }, subscription_id, project_id, 
                                p.dataSourceId,
                                "__dev",
                                stages,
                                {} // internal options
                            );
                            return res;
                        }
                    }
                });

            const computeSyntax = "async (context, lib, resources, pipelines, setStatus) => { " + item?.compute + " \n\n}\n";
            const computeFunc = compileFunction(computeSyntax);
            const r = await computeFunc?.(context, lib, rResources, pipelines2, statusFunc);

            // let r = {};
            // for( let pipeline of item?.pipelines ){

            //     const enabled_stages = pipeline.stages
            //         .filter(s => !s.disabled)
            //         .map(s => s.data);
                
            //     const res = await computeApi.computeV2(
            //         auth,
            //         env,
            //         subscription_id,
            //         project_id,
            //         pipeline.dataSourceId,
            //         isDev ? "__dev" : "__prod",
            //         enabled_stages,
            //         {
            //             //limit: 100 // cursor limit
            //         });
            //     console.log("res", res);
            //     //if( !mounted ) return;
                
            //     r[pipeline.id] = res;

            // }
            
            //const r = await runCompute(item.compute, context, rResources, api, lib);
            //if (!mounted) return;
            // setData(r);
            // setComputing(false);
            setPayload({ result: r, computing: false, needsCompute: false });
        }
        catch (err) {
            //if (!mounted) return;
            setDataError(err.message);
            //setComputing(false);
            setPayload({ ...payload, computing: false, needsCompute: false });
        }
    }

    const api = {
        compute
    };



    // COMPUTE
    // useShallowCompareEffect(() => {

    //     if( !allResourcesReady ) return;
    //     if (!item?.compute && !item?.pipelines) return;

    //     let mounted = true;
    //     compute();
    //     return () => mounted = false;

    // }, [subscription_id, project_id, item?.compute, item?.pipelines, allResourcesReady, resources, context])//, resourcesEqualityHash])//item?.compute, resources])
    
    useShallowCompareEffect(() => {

        if( !allResourcesReady ) return;
        if (!item?.compute && !item?.pipelines) return;

        setPayload({ ...payload, needsCompute: true });

        if( item?.delayCompute ){
            //console.log("delaying compute");
            return;
        }

        let mounted = true;
        compute(mounted);
        return () => mounted = false;

    //}, [computeCounter])//, resourcesEqualityHash])//item?.compute, resources])
    }, [subscription_id, project_id, item?.compute, item?.pipelines, allResourcesReady, resources, context])//, resourcesEqualityHash])//item?.compute, resources])




    // RENDER
    useShallowCompareEffect(() => {
        
        if( !allResourcesReady ) return;
        if (!viewAsTable && !item?.render) return;
        //if( viewAsTable && !item?.renderTable) return;
        //console.log("render effect. payload", payload);
        try {
            if( viewAsTable ){
                const r = runRender(item.renderTable, context, rResources, api, lib, payload)
                //const r2 = <pre>{r ? JSON.stringify(r, null, 4) : "nothing rendered"}</pre>;
                const r2 = r ? 
                    <div>
                        <div style={{ 
                            margin: 10,
                            marginBottom: 15,
                            fontWeight: 600,
                            textTransform: "uppercase",
                            fontSize: 13,
                            textAlign: "center"
                            
                        }}>
                            {r.title}
                        </div>
                        <div style={{ 
                            
                            //height: 350, 
                            //position: "relative", 
                            //border: "1px solid #cfcfcf",
                            marginBottom: 15
                        }}>
                            <center>
                            <DataTable className="auto-height" {...r} />
                            </center>
                        </div>
                    </div>
                    : "table not rendered";
                setRendered(r2);
            }
            else{
                const r = runRender(item.render, context, rResources, api, lib, payload);
                setRendered(r || null);
            }
            //setRendered(<div>hack</div>);
        }
        catch (err) {
            setRenderError(err.message);
            console.error(err);
        }

    }, [subscription_id, project_id, item?.render, payload, allResourcesReady, resources, context, viewAsTable])//, resourcesEqualityHash, data])

    const wrapMessage = (message) => <div className="message h-centered">{message}</div>

    if (!allResourcesReady) return wrapMessage("Loading resources...");
    //if (resourcesError) return wrapMessage("Error loading resources");

    if (!item) return wrapMessage("Item missing");


    // todo: execute the compute function
        // I think this goes before the data binding, allowing data binding to the output??
        // I'm not sure. I would need to know to were exactly to pipe the output
        // Besides, binding can be dynamic functions themselves, so maybe screw this.
        // It just needs to be available in render.
        // compute is just something special for JsxCard. Not everything. 
        // Mabye it could have a checkbox[x] pipe output into context pre - binding, but i doubt it
        // there should be a way to put stuff in context beforehand ??
    
    // todo: apply all the data binding in item
        // allow context binding
        // allow output binding?
        // don't do it this way:
    //const text = getPropertyValue(item?.content, context, {});

    // instead, do it something like this:
        // const binders = findDataBinding(item);
        // const [resources2, ...] = useResources(binders.getRequiresResources());
        // const item2 = binders.apply(context, { ...resources, ...resources2 }, ...)
        // const text = item2.content;

    // do i require the item to load resources first, before allowing binding to a construct?
        // e.g., in order to bind to a palette or collection or style, it must be a resource
        // would make it a little easier (faster) for an inspector to find all content_items that use a certain resource
        // would make it easier to swap out stuff if say multiple props pointed to a single resource
        // *MAKES binding more powerful* because a function could refer to a resource in a way that a parser might find hard to interpret
    
    
    //const r = data ? JSON.stringify(data, null, 2) : null;
    // const r = <div>
    //     <div>
    //         data:
    //     </div>
    //     <pre>{JSON.stringify(data, null, 2)}</pre>
    //     <div>
    //         item:
    //     </div>
    //     <pre>{JSON.stringify(item, null, 2)}</pre>
    //     <div>
    //         resources:
    //     </div>
    //     <div>
    //         <pre>{JSON.stringify(resources, null, 2)}</pre>
    //     </div>
    // </div>;
    //const Rendered = rendered;

    let tileStyle = {
        // position: "absolute",
        // height: "100%",
        // width: "100%",

        // border
        borderStyle: item?.border?.style,
        borderWidth: item?.border?.width + "px",
        borderColor: item?.border?.color,

        // background
        backgroundAttachment: item?.background?.attachment,
        backgroundBlendMode: item?.background?.blendMode,
        backgroundClip: item?.background?.clip,
        backgroundColor: item?.background?.color,
        //backgroundImage: item?.background?.image,
        backgroundOrigin: item?.background?.origin,
        backgroundPosition: item?.background?.position,
        backgroundRepeat: item?.background?.repeat,
        backgroundSize: item?.background?.size,

    }

    if (item?.background?.imageType === "url") {
        tileStyle.backgroundImage = `url("${backgroundImagePath}")`
    }
    else if (item?.background?.imageType === "none") {
    }

    

    let passProps = {
        ...props,
        viewAsTable,
        setViewAsTable: item.renderTable ? setViewAsTable : null
    }

    // if( item?.fillParentAbsolute ){
    //     passProps.position = "absolute";
    //     passProps.left = "0";
    //     passProps.top = "0";
    //     passProps.width = "100%";
    //     passProps.height = "100%";
    // }

    const fpaStr = item?.fillParentAbsolute ? "fill-parent-absolute" : "";
    
    return props.edit ?
        <EditContentItemWrapper {...passProps} key={item.id} fill={item?.fillParentAbsolute}>
            <div id={`__${item.id}`} className={`view-jsx ${fpaStr}`} style={tileStyle}>
                <ErrorBoundary>{rendered}</ErrorBoundary>
            </div>
        </EditContentItemWrapper> :
        <ExportContentItemWrapper {...passProps} hide={!item.allowExport} key={item.id} fillParentAbsolute={item?.fillParentAbsolute}>
            <div id={`__${item.id}`} className={`view-jsx ${fpaStr}`} style={tileStyle}>
                <ErrorBoundary>{rendered}</ErrorBoundary>
            </div>
        </ExportContentItemWrapper>
        ;
        
}


