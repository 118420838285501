import { constructs } from "./contructs";
import { createController } from "./createController";

export const controllers = {
    project: createController(constructs.project),
    page: createController(constructs.page),
    "content-item": createController(constructs["content-item"]),
    collection: createController(constructs.collection),
    palette: createController(constructs.palette),
    pipeline: createController(constructs.pipeline),
    image: createController(constructs.image),
    "data-source": createController(constructs["data-source"]),
    "data-source-revision": createController(constructs["data-source-revision"]),
    config: createController(constructs.config),
    model: createController(constructs.model),
    selection: createController(constructs.selection),
    "filter-book": createController(constructs["filter-book"])
}

