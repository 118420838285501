import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useDataSourcesApi } from "../../../hooks/useDataSource";
import { CONNECTORS } from "./_Connectors";
import * as globals from "../../../globals";
import axios from "axios";
import { getAuthHeaders } from "../../../api/restMethods";
import { ModalFrame } from "../../modal-dialog/ModalFrame";
import { NewDataSourceContext } from "../NewDataSource";


export const XlsxUploadConnector = (props) => {

    const connector_id = props.connector_id;

    const routerParams = useParams();
    const subscription_id = routerParams.subscription_id;
    const project_id = routerParams.project_id;

    const ndsContext = useContext(NewDataSourceContext);
    const { working, setWorking } = ndsContext;
    const { workStatusMessage, setWorkStatusMessage } = ndsContext;
    const { setButtonText } = ndsContext;
        
    const [error, setError] = useState(null);
    
    const auth = useAuth();
    const api = useDataSourcesApi();

    
    setButtonText("Upload .xlsx");
    ndsContext.hackHandler.buttonClicked = async () => {
        let id = props.id;
        if (!props.alreadyExists) {
            id = await createDataSource();
        }
        const success = await uploadRevision(id); // returns true if success
        return success ? id : null;
    }

    const createDataSource = async () => {

        let newId = null;
        
        setWorkStatusMessage("Creating data source...");
        setWorking(true);
        try {
            const fileInput = document.getElementById("file-input");

            // create data source
            let item = {
                type: "file",
                fileType: "xlsx",
                structured: true,
                connector: connector_id,
                label: fileInput.files?.[0]?.name || "Data source"
            }
            const itemInfo = await api.newItem(item);
            newId = itemInfo.newId;
        }
        catch (err) {
            setError(err.message);
        }
        setWorking(false);

        return newId;
        
    }

    const uploadRevision = async (ds_id) => {

        const fileInput = document.getElementById("file-input");
        try {

            const file = fileInput.files[0];
            setWorkStatusMessage(`Uploading file ${file.name}...`);
            setWorking(true);

            const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/data-source/${ds_id}/file`;

            let formData = new FormData();
            formData.append("file1", file);
            //formData.append("fileType", "xlsx");
            if (ndsContext.notes) {
                formData.append("notes", ndsContext.notes);
            }

            await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...getAuthHeaders(auth)
                },
                onUploadProgress: (ev) => {
                    console.log("onUploadProgress", ev);
                }
            });

            setWorking(false);
            return true;
            
        }
        catch (error) {
            console.log("error!", error);
            setError(error.message);
            setWorking(false);
            return false;
        }
    }

    

    return <div className='form1 new-data-source' style={{ maxWidth: 500 }}>

        <div className="upload-data-file">

            <div className="icon" style={{ fontSize: 50, float: "right", marginRight: 10 }}>
                <i className="fal fa-cloud-upload-alt" />
            </div>

            <div className="instructions" style={{ maxWidth: 400 }}>
                <p>
                    Supported file formats:<br />
                    <ul>
                        <li>Excel files (*.xlsx)</li>
                    </ul>
                </p>
            </div>

            <div className="input-section">
                {working ?
                    <div style={{ fontSize: "10pt", marginBottom: 5, color: "1298df" }}>
                        Please wait. Creating data source...
                    </div>
                    : null
                }
                {(working || workStatusMessage) ?
                    <div style={{ fontSize: "10pt", marginBottom: 5, color: "#1298df" }}>
                        {workStatusMessage}
                    </div>
                    : null
                }
                <input type="file" id="file-input"
                    disabled={working}
                    style={{ visibility: working ? "hidden" : "visible" }}
                    className='full-width' />
                
            </div>
            
        </div>

        <div className="label-line">
        </div>

        {error && !working ?
            <div>
                <p style={{ color: "red" }}>
                    <i className="fas fa-warning" />
                    &nbsp;
                    {error}
                </p>
            </div>
            : null
        }
        
    </div>


}