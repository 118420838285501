import { useContext, useEffect, useState } from "react";
import { PageContext } from "../../../context/Contexts";
import { useContentItem } from "../../../hooks/useContentItem";
import { useContentItemsApi } from "../../../hooks/useContentItems";
import { ControlledCodeEditorFillContainer } from "../../code-editor/CodeEditor";
import { DataBindButton } from "../../edit/DataBindButton";
import { InstancesInput } from "../../instances/InstancesInput";
import JSON5 from "json5";
import { GenericResourceInput } from "./GenericResourceInput";

const safeJson = (obj) => {
    if (!obj) return null;
    return JSON.stringify(obj, null, 4);
}

export const EditCollectionEntrySelectProperties = (props) => {

    const api = useContentItemsApi();
    const page = useContext(PageContext);

    const id = props.content_item_id;
    const [item] = useContentItem(page?.id, id);

    // const [itemStr, setItemStr] = useState(safeJson(item));
    // useEffect(() => {
    //     setItemStr(safeJson(item));
    // }, [item])

    if (!item) return null;

    const rowPad = 10;

    const saveItem = async (newItem) => {
        await api.updateItem(id, newItem, { page_id: page.id });
    }

    return <div>

        <div className='h-dock' style={{ marginBottom: rowPad }}>
                    
            <span className='left v-center' style={{ width: 100, marginRight: 10 }}>
                Instances
            </span>
            <span className='center'>
                <InstancesInput
                    style={{ width: "100%" }}
                    value={item.instances}
                    onChange={async (newInstances) => {
                        const newItem = {
                            ...item,
                            instances: newInstances
                        };
                        saveItem(newItem);
                    }}
                />
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton/>
            </span>
        </div>

        <div className="form1">

            <div className='label'>
                <div className='label-line'>
                    Collection
                </div>
                <div className='value-line'>
                    <GenericResourceInput 
                        type="collection" 
                        value={item.collectionId}
                        onChange={async (newId) => {
                            const newItem = {
                                ...item,
                                collectionId: newId
                            }
                            saveItem(newItem);
                        }}
                    />
                </div>
            </div>

            <div className='label'>
                <div className='label-line'>
                    Selection (user state)
                </div>
                <div className='value-line'>
                    <GenericResourceInput 
                        type="selection" 
                        value={item.selectionId}
                        onChange={async (newId) => {
                            const newItem = {
                                ...item,
                                selectionId: newId
                            }
                            saveItem(newItem);
                        }}
                    />
                </div>
            </div>

        </div>


    </div>
}
