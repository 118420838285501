import { useContext, useState } from "react";
import Popup from "reactjs-popup";
import { ContentItemContext, PageContext } from "../../context/Contexts";
import { useContentItemsApi } from "../../hooks/useContentItems";
import { AddChildContentModal } from "../page-content/AddChildContent";
import { getAllowedChildTypes, getTypeInfo } from "../page-content/ContentTypes";
import { EditCRSyntaxLite, EditCRSyntaxLiteV2 } from "../page-content/edit/EditCRSyntax";
import { PageEditorContext } from "../pages/Pages";
import "./EditContentItemWrapper.scss";


const FRAME_COLORS = [
    { fill: "#00517D", text: "#ffffff" },
    { fill: "#0078BE", text: "#ffffff" },
    { fill: "#00A3AD", text: "#ffffff" },
    { fill: "#82C341", text: "#ffffff" },
    { fill: "#84329B", text: "#ffffff" },
]

export const EditContentItemWrapper = (props) => {

    const api = useContentItemsApi();

    const pe_context = useContext(PageEditorContext);
    const page = useContext(PageContext);

    const frameBoxing = pe_context?.frameBoxing;
    const setFrameBoxing = pe_context?.setFrameBoxing;

    const item = props.content_item;
    const level = props.level || 0;
    const frameColor = FRAME_COLORS[level % FRAME_COLORS.length];
    const context = useContext(ContentItemContext);
    
    const ctype = item?.["ctype"];

    const [keepOpen, setKeepOpen] = useState(false);//ctype === "PAGE" ? true : false);

    // useEffect(() => {
    //     console.log('EditContentItemWrapper page changed', keepOpen);
    //     setKeepOpen(true);//ctype === "PAGE" ? true : false);
    // }, [page])

    if (!props.edit) {
        return props.children;
    }

    
    const ctypeInfo = getTypeInfo(ctype);
    const pTypes = getAllowedChildTypes(props.pType);
    const childTypes = getAllowedChildTypes(ctype, pTypes);

    
    const allowsChildren = childTypes?.length > 0;
    
    
    const hasCRSyntax = ctypeInfo?.hasCRSyntax;

    const moveUpDown = async (dir) => {
        await api.moveItem(item.id, dir, { page_id: page.id });
        alert("hit refresh");
    }
    
    
    return <div
        className={`edit-item-wrapper ${frameBoxing ? "boxed" : ""} ${props.fill ? 'fill' : ''} ${keepOpen ? 'open' : ''} ${allowsChildren ? "" : "no-children"}`}
        style={{ borderColor: frameColor.fill }}
    >
        <div className="outline" style={{ borderColor: frameColor.fill }}></div>
        <span className={`ctype ${props.actionsPosition}`} style={{ backgroundColor: frameColor.fill, color: frameColor.text }}>
            <span style={{ marginRight: 10 }}>
                <span style={{
                    //backgroundColor: "red",
                    padding: "2px 6px",
                    marginRight: 4,
                }}
                    onClick={() => setFrameBoxing(!frameBoxing)}
                >
                    {!frameBoxing ? <i className="far fa-compress-alt" />
                        : <i className="far fa-expand-alt" />}
                </span>
                {ctypeInfo?.label}
            </span>
            {props.setViewAsTable ?
                <span 
                    className={`smallbtn ${props.viewAsTable ? "sel" : ""}`} 
                    onClick={() => {
                        //pe_context?.setSelectedContentItem(item, context);
                        props.setViewAsTable(!props.viewAsTable);
                    }}
                >
                    <i className='fal fa-table'/>
                </span>
                : null
            }
            <span className='smallbtn' onClick={() => {
                pe_context?.setSelectedContentItem(item, context);
            }}
                title="Edit properties"
            >
                <i className='fas fa-list'/>
            </span>

            {hasCRSyntax ?
                <EditCRSyntaxLiteV2
                    item={item}
                    trigger={
                        <span className='smallbtn' title="Edit compute/render">
                            <i className='fal fa-function' />
                        </span>
                    }
                    onChange={
                        async (newStuff) => {
                            const newItem = {
                                ...item,
                                compute: newStuff.compute,
                                render: newStuff.render,
                                renderTable: newStuff.renderTable,
                            }
                            //await updateItem(newItem);
                            await api.updateItem(item.id, newItem, { page_id: page.id });
                        }
                    }
                />
            : null}

            {/* {
                item && hasCRSyntax ? 
                <Popup
                    modal
                    trigger={
                        <span className='smallbtn'>
                            <i className='fal fa-brackets-curly' />
                        </span>
                    }
                >
                    {closeModal => <EditCRSyntaxBox
                        title={"Syntax"}
                        compute={item.compute}
                        render={item.render}
                        renderTable={item.renderTable}
                        resources={null}
                        onChange={() => {
                            // todo: save!!
                        }}
                        close={closeModal}
                    />}
                </Popup>
                : null
            } */}

            

            {allowsChildren ?
                <Popup
                    keepTooltipInside
                    modal
                    position="bottom right"
                    onOpen={() => setKeepOpen(true)}
                    onClose={() => setKeepOpen(false)}
                    contentStyle={{
                        width: 900,
                        height: 500
                    }}
                    trigger={
                        <span className='smallbtn' title="Add child content">
                            <i className='fal fa-plus-circle' />
                        </span>
                    }
                >
                    {closeModal => <AddChildContentModal
                        close={closeModal}
                        type={item?.["ctype"]}
                        parentAllowedTypes={pTypes}
                        onAdd={async (newObj) => {

                            // calculate display index
                            let max_display_index = 0;
                            item?.children?.forEach(child => {
                                max_display_index = Math.max(max_display_index, child.display_index);
                            })
                            const display_index = max_display_index + 1;
                            newObj = {
                                ...newObj,
                                display_index
                            }

                            await api.newItem(newObj, item?.id, { page_id: page.id });
                        }}
                    />}
                </Popup>
                : null
            }
            <Popup
                keepTooltipInside
                position="bottom right"
                onOpen={() => setKeepOpen(true)}
                onClose={() => setKeepOpen(false)}
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                trigger={
                    <span className='smallbtn'>
                        <i className='fal fa-ellipsis-h' />
                    </span>
                }
            >
                <div className='popup-menu'>
                    <div className="menu-group">
                        <div className="menu-item" onClick={() => moveUpDown("up")}>
                            Move up
                            <span className="icon">
                                <i className="fal fa-arrow-up"/>
                            </span>
                        </div>
                        <div className="menu-item" onClick={() => moveUpDown("down")}>
                            Move down
                            <span className="icon">
                                <i className="fal fa-arrow-down"/>
                            </span>
                        </div>
                    </div>
                    <div className="menu-group">
                        <div className="menu-item" onClick={() => moveUpDown("top")}>
                            Move to top
                            <span className="icon">
                                <i className="fal fa-arrow-up-to-line"/>
                            </span>
                        </div>
                        <div className="menu-item" onClick={() => moveUpDown("bottom")}>
                            Move to bottom
                            <span className="icon">
                                <i className="fal fa-arrow-down-to-line"/>
                            </span>
                        </div>
                    </div>
                    <div className="menu-group">
                        <div className="menu-item">
                            Copy
                            <span className="icon">
                                <i className="fal fa-copy" />
                            </span>
                        </div>
                        <div className="menu-item">
                            Duplicate
                        </div>
                    </div>
                    <div className="menu-group">
                        <div className="menu-item">
                            Paste Into
                            {/* <span className="icon">
                                <i className="fal fa-paste" />
                            </span> */}
                        </div>
                    </div>
                    <div className='menu-group'>
                        <div className='menu-item' onClick={async () => {
                            // todo: verify
                            await api.deleteItem(item.id, { page_id: page.id });
                        }}>
                            <span>Delete {ctypeInfo.label}</span>
                            <span className='icon'>
                                <i className='fal fa-trash-alt'/>
                            </span>
                        </div>
                    </div>
                    
                    
                </div>
            </Popup>
        </span>
        {props.children}
    </div>;


}