import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { CodeEditor, ControlledCodeEditor } from "../code-editor/CodeEditor";
import { CollectionPicker } from "../collections/CollectionPicker";
import { ModalFrame } from "../modal-dialog/ModalFrame";
import "./InstancesInput.scss";

export const InstancesInput = (props) => {

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const onChange = (newValue) => {
        console.log('InstancesInput internal onChange');
        setValue(newValue);
        props?.onChange?.(newValue);
    }
    
    return <div className='instances-input'>
        <div>
            <select className='source-box' value={value?.sourceType} onChange={ev => {
                const newValue = ev.target.value === "none" ? null : {
                    sourceType: ev.target.value
                }
                onChange(newValue);
            }}>
                <option value="none">None</option>
                <option value="collection">From Collection</option>
                <option value="json">From JSON Array</option>
                <option value="func">From JS Function</option>
            </select>
        </div>
        {value?.sourceType === "collection" ? <div style={{ marginTop: 10 }}>
            <CollectionPicker value={value.collectionId} onChange={(id) => {
                const newValue = {
                    sourceType: "collection",
                    collectionId: id
                }
                onChange(newValue);
            }} />
        </div> : null}
        {value?.sourceType === "json" ? <div style={{ marginTop: 10 }}>
            <Popup modal trigger={<span className='btn action'>Edit array...</span>}>
                {closeModal => <EditJson close={closeModal} defaultValue={value?.json} onSave={(code) => {
                    const newValue = {
                        sourceType: "json",
                        json: code
                    }
                    onChange(newValue);
                    closeModal();
                }} />}
            </Popup>
        </div> : null}
        {value?.sourceType === "func" ? <div style={{ marginTop: 10 }}>
            <Popup modal trigger={<span className='btn action'>Edit function...</span>}>
                {closeModal => <EditFunction close={closeModal} defaultValue={value?.func || defaultFuncText} onSave={(code) => {
                    const newValue = {
                        sourceType: "func",
                        func: code
                    }
                    onChange(newValue);
                    closeModal();
                }} />}
            </Popup>
        </div> : null}
    </div>;

}

const defaultFuncText = `
const func = async (context) => {
    return [
        { id: 1, label: "first instance" }
    ]
}
`;

const EditJson = (props) => {

    const [value, setValue] = useState(props.defaultValue);

    useEffect(() => {
        setValue(props.defaultValue);
    }, [props.defaultValue])

    const buttons = [
        {
            label: "Save",
            callback: () => {
                props?.onSave(value);
            }
        }
    ];

    return <ModalFrame title={"Edit instances array"} close={props.close} buttons={buttons}>
        <ControlledCodeEditor value={value} onChange={(newValue) => setValue(newValue)}/>
    </ModalFrame>

}

const EditFunction = (props) => {

    const [value, setValue] = useState(props.defaultValue);

    useEffect(() => {
        setValue(props.defaultValue);
    }, [props.defaultValue])

    const buttons = [
        {
            label: "Save",
            callback: () => {
                props?.onSave(value);
            }
        }
    ];

    return <ModalFrame title={"Edit instances function"} close={props.close} buttons={buttons}>
        <ControlledCodeEditor value={value} onChange={(newValue) => setValue(newValue)}/>
    </ModalFrame>

}