import {
    Route, Switch
} from "react-router-dom";
import * as globals from "../globals";
import { AdminLayout } from "./admin/AdminLayout";
import { DashboardOuter } from "./dashboard/DashboardOuter";
import "./MainLayout.scss";
import "./nav/LeftMenu.scss";
import { PlayerLayout } from "./player/PlayerLayout";
import { PreviewLayout } from "./PreviewLayout";
import { SelectSubscription } from "./select-project/SelectSubscription";
import { SubscriptionLayout } from "./SubscriptionLayout";

export const MainLayout = (props) => {

    //const authContext = useContext(AuthContext);
    //const default_subscription_id = authContext?.selectedSubscription?.id;

    if( globals.player_sub ){
        return <PlayerLayout />;
    }

    return <Switch>
        <Route path="/preview/:subscription_id/:project_id">
            <PreviewLayout />
        </Route>
        <Route path="/admin">
            <AdminLayout />
        </Route>
        <Route path="/subscription/:subscription_id">
            <SubscriptionLayout/>
        </Route>
        <Route path="/subscriptions">
            <SelectSubscription />
        </Route>
        <Route exact path="/">
            <DashboardOuter />
        </Route>
        <Route>
            <DashboardOuter />
        </Route>
    </Switch>;

}