import { useState } from "react";
import { ModalFrame } from "../modal-dialog/ModalFrame";

export const NewCollection = (props) => {

    const [newCollectionLabel, setNewCollectionLabel] = useState("");

    return <ModalFrame close={props.close}
        title="Create new collection"
        buttons={[
            {
                label: "Create collection",
                disabled: !newCollectionLabel,
                callback: async () => {
                    const body = {
                        label: newCollectionLabel,
                    }
                    setNewCollectionLabel("");
                    props.createCollection?.(body);
                    props.close();
                }
            },
        ]}
    >
        <div className='form1'>
            <label className="label-line">
                Name:
            </label>
            <div className="value-line">
                <input type="text"
                    autoFocus className='full-width' 
                    placeholder="enter name of new collection"
                    value={newCollectionLabel}
                    onChange={(ev) => {
                        setNewCollectionLabel(ev.target.value)
                    }}/>
            </div>
        </div>

    </ModalFrame>

    
}

