import { createContext } from "react";

/**
 * This is NOT just an Edit Context. It should be relabeled probably.
 * 
 * dsRevisionSlot
 * navigate (function) (maybe only in doctree)
 * project??
 * item
 * 
 * When used in context of editing, it should have the following
 *      parentContext
 *      selectionId (setSelectionId)
 *      mode (edit or preview)
 *      itemStack
 *      reloadStack
 *      message (setMessage)
 *      addChild
 *      deleteChild
 *      
 * 
 */
export const EditContentContext = createContext();

