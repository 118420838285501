import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as globals from "../globals";

export const useEnforceSubscriptionIdFromRoute = () => {

    const routerParams = useParams();
    
    const route_sid = globals.player_sub || routerParams.subscription_id;
    const redux_sid = useSelector(state => state.subscriptions.subscription_id);

    const dispatch = useDispatch();

    if (redux_sid !== route_sid) {
        dispatch({ type: "CLEAR_SUBSCRIPTION", new_sid: route_sid });
    }

    return route_sid;

}


export const useEnforceSubscriptionId = ( subscription_id ) => {

    const redux_sid = useSelector(state => state.subscriptions.subscription_id);

    const dispatch = useDispatch();

    if (redux_sid !== subscription_id ) {
        dispatch({ type: "CLEAR_SUBSCRIPTION", new_sid: subscription_id });
    }

    return subscription_id;

}

