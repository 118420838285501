import { useContext } from "react";
import { EtlFlowContext } from "../../context/Contexts";
import "./EditETLNode.scss";
import { GenericEtlNodeEditor } from "./etl/EtlNodeEditors";
import { EtlNodeTypeInfos } from "./etl/EtlNodes";

export const EditETLNode = (props) => {

    const flowContext = useContext(EtlFlowContext);

    const { node, saveNode } = props;
    const nodeInfo = EtlNodeTypeInfos[node?.type];
    if( !node  ) return null;

    const Editor = nodeInfo?.editor || GenericEtlNodeEditor;
 
    return <div className={`${props.className || ""} edit-etl-node`}>
        <div className="title">
            Selected Node: <b>{nodeInfo?.label}</b>
        </div>
        <div className="top-right-buttons">
            <span 
                className={'btn icon'}
                onClick={() => flowContext.setFullScreenEditor(!flowContext.fullScreenEditor)}>
                {flowContext.fullScreenEditor ? 
                    <i className="fal fa-expand" />
                    : <i className="fal fa-expand" />
                }
            </span>
            <span 
                className="btn icon"
                onClick={() => {
                    flowContext.setEditNode(null);
                }}
            >
                <i className="fal fa-times" />
            </span>
        </div>
        <div className="content">
            <Editor key={node.id} data={node.data} saveData={(newData) => {
                saveNode({ ...node, data: newData });
            }} />
        </div>
    </div>

}