import { getStageInnerData } from "../components/aggregation/stages/_PipelineStages";
import * as globals from "../globals";
import { compileFunction } from "../utils/Compiler";
import * as restMethods from "./restMethods";


export const computeApi = {

    compute: async (auth, subscription_id, project_id, datasource_id, revision, stages, options) => {
        
        const revisionStr = revision ? `/${revision}` : "";
        const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/data-source/${datasource_id}/compute${revisionStr}`;
        //console.log("url", url);
        const body = {
            stages,
            options
        }
        //console.log("url, body", url, body);
        var response = await restMethods.postJson(auth, url, body);
        if( !response.ok ){
            let errorText = response.statusText;
            try{
                errorText = await response.text()
            }
            catch{
                errorText = response.statusText;
            }
            throw new Error(errorText);
        }
        var rows = await response.json();
        //console.log("rows", rows);
        return rows;
    },

    computeV2: async (auth, env, subscription_id, project_id, datasource_id, revision, stages, internalOptions) => {
        
        const revisionStr = revision ? `/${revision}` : "";
        const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/data-source/${datasource_id}/compute${revisionStr}`;
        //console.log("url", url);

        // pre-process the stages if needed (for custom stages and accumulators)
        let stages2 = [];
        for( let stage of stages ){
            const stageType = Object.keys(stage)?.[0];
            if( stageType === "$mstudio.custom-stage" ) {
                const r = processCustomStage(stage, env);
                if( r ){
                    if( Array.isArray(r) ){
                        r.forEach(e => stages2.push(e));
                    }
                    else{
                        stages2.push(r);
                    }
                }
            }
            else if( stageType === "$mstudio.group_v2" ){
                // remove _id from dim items
                stages2.push({
                    [stageType]: {
                        ...stage[stageType],
                        dims: stage[stageType].dims?.map(dim => {
                            return {
                                ...dim,
                                items: dim.items?.map(item => {
                                    const new_item = { ...item };
                                    delete new_item._id;
                                    return new_item;
                                })
                            }
                        })
                    }
                });
            }
            else if( stageType === "$sort" ){
                // only push if there's a sort defined
                const empty = !stage[stageType] || !Object.keys(stage[stageType])?.length;
                if(!empty){
                    stages2.push(stage);
                }
            }
            //else if( stageType === "$mstudio.custom-accumulator" ) return processCustomAccumulator(stage, context);
            else stages2.push(stage);
        };

        const body = {
            stages: stages2,
            options: internalOptions
        }

        //console.log("url, body", url, body);
        var response = await restMethods.postJson(auth, url, body);
        if( !response.ok ){
            let errorText = response.statusText;
            try{
                errorText = await response.text()
            }
            catch{
                errorText = response.statusText;
            }
            throw new Error(errorText);
        }
        var rows = await response.json();
        //console.log("rows", rows);
        return rows;
    },

    
};

const processCustomStage = (stage, env) => {

    try{
        // get the model's producer syntax from resources
        const s = getStageInnerData(stage);
        const producerSyntax = s?.syntax;
        
        // compile the code
        const syntax = `(resources, context, lib, args) => { ${producerSyntax} }`;
        const f = compileFunction(syntax);
        
        // exec the code, passing in env
        try{
            const res2 = f(env?.resources, env?.context, env?.lib, env?.args);
            return res2;    
        }
        catch( err ){
            console.log("error in code execution", err.message);
            throw new Error("Error in custom stage code execution");
        }

    }
    catch(err){
        console.error(err.message)
        throw new Error("Error processing custom stage");
    }

}




