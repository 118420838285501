import Editor from "@monaco-editor/react";
import { format } from "d3-format";
import { useRef, useState } from "react";
import ReactJson from "react-json-view";
import { useDispatch } from "react-redux";
//import SplitterLayout from "react-splitter-layout";
import SplitterLayout from "../../extlib/react-splitter-layout/SplitterLayout";
import { computeApi } from "../../../api/computeApi";
import { reduceResources } from "../../../hooks/helper/reduceResources";
import { useAuth } from "../../../hooks/useAuth";
import { useLazyLib } from "../../../hooks/useLazyLib";
import { minifyProject, useProject } from "../../../hooks/useProject";
import { useProjectId } from "../../../hooks/useProjectId";
import { useResources } from "../../../hooks/useResources";
import { compileJs100 } from "../../../utils/ContentProcessor";
import { defaultLib } from "../../../utils/Lib";
import { isNull } from "../../common/DataTableHelper";
import { truncateObjectAtLevel } from "./EditCRSyntaxBox";
import "./EditCRSyntaxBox2.scss";



const CODE_TABS = [
    { id: "syntax", label: "Syntax" },
]

const HELPER_TABS = [
    { id: "data", label: "Result" },
    { id: "context", label: "Context" },
    { id: "lib", label: "Lib" },
    { id: "api", label: "Api" },
    { id: "resources", label: "Resources" },
]

const defaultApiHack = {
}

export const EditJsSyntaxBox = (props) => {

    
    let safeSyntax = props.syntax;
    if( Array.isArray(safeSyntax )){
        console.error("IS ARRAY!");
        safeSyntax = safeSyntax[0];
    }

    const editorRef = useRef(null);

    const { subscription_id, project_id } = useProjectId();
    const [project, projectLoading, projectError] = useProject();

    const auth = useAuth();
    const dispatch = useDispatch();

    const lib2 = useLazyLib();
    const lib = {
        ...defaultLib,
        ...lib2
    };

    const item = props.item;
    const [resources, resourcesLoading, resourcesError] = useResources(item?.jdata?.resources, true);
    //const rResources = useMemo(() => reduceResources(resources, subscription_id, project_id, dispatch, auth), resources);
    const rResources = reduceResources(resources, subscription_id, project_id, dispatch, auth);
    
    const getLibHack = () => truncateObjectAtLevel(lib, 1);
    

    const [pipelines, setPipelines] = useState(props.pipelines);
    const [compute, setCompute] = useState(props.compute);
    //const [payload, setPayload] = useState({ computing: false, needsCompute: false, result: null, status: null });
    const payload = props.payload;

    //const [syntax, setSyntax] = useState(props.syntax);
    const [result, setResult] = useState(payload?.result); // temp result just for this window

    const [selCodeTab, setSelCodeTab] = useState("syntax");
    const [selHelperTab, setSelHelperTab] = useState("data");
    const [running, setRunning] = useState(false);
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);
    

    const [expanded, setExpanded] = useState(false);


    
    

    const context = {
        project: minifyProject(project)
    }; // useContext(ContentItemContext);
    
    // useEffect(() => {
    //     setSyntax(props.syntax);
    // }, [props.syntax])

    const env = {
        resources: rResources,
        context: context
    };

    const revision = "__dev"; // TODO!

    const api = {
        computePipeline: (datasource_id, stages, internalOptions) => 
            computeApi.computeV2(auth, env, subscription_id, project_id, datasource_id, revision, stages, internalOptions)
    };

    // // compile the syntax and run it
    // useEffect(() => {

    //     if( !lib2 ) return;
    //     if( !syntax ) return;
    //     if( !project ) return;
    //     console.log("running syntax");
        
    //     (async () => {

    //         // compute
    //         const f = compileJs100(syntax, context, rResources, api, lib) 
    //         const r = await f();
    //         setResult(r);

    //     })();

    // }, [syntax, rResources]);

    

    const run = async () => {
        // compute
        setStatus(null);
        setError(null);
        setRunning(true);
        const _syntax = editorRef.current.getValue();
        try{
            const f = compileJs100(_syntax, context, rResources, api, lib, setStatus) 
            const r = await f();
            setResult(r);
        }
        catch(ex){
            setResult(null);
            setError(ex.message);
        }
        setRunning(false);
    }


    const saveMe = () => {
        const _syntax = editorRef.current.getValue();
        //console.log("_syntax", _syntax);
        props.onChange?.(_syntax)
    }

    // const rContext = {
    //     syntax,
    //     setSyntax,
    //     autoApply: true
    // }

    if( !project ) return null;

    const pbar_width = 200;
    const pbar_height = 20;

    

    return <div className="edit-crsyntax-box2">
        <div className={`frame1 ${expanded ? "expanded" : ""}`}>
            <div className="titlebar full-dock">

                <div className="center xtab-section">
                    {/* Title goes here */}
                </div>

                <span className="buttons right">
                <span className="btn icon" onClick={() => saveMe()}>
                        <i className="fas fa-save" />
                    </span>
                    <span className="btn icon" onClick={() => setExpanded(!expanded)}>
                        <i className="fal fa-expand" />
                    </span>
                    <span className="btn icon" onClick={() => {
                        const _syntax = editorRef.current.getValue();
                        if( _syntax === safeSyntax || window.confirm("Close without saving?") ){
                            props.close?.()
                        }
                    }}>
                        <i className="fal fa-times" />
                    </span>
                </span>

            </div>
            <div className="main">
                <div className="all" >
                    <SplitterLayout primaryIndex={1}>
                        <div className="full-dock fill-parent-absolute">
                            <div className="top tab-section no-margin">
                                {CODE_TABS.map(tab =>
                                    <span 
                                        key={tab.id}
                                        className={`tab ${selCodeTab === tab.id ? "sel" : ""}`}
                                        onClick={() => setSelCodeTab(tab.id)}
                                    >
                                        {tab.label}
                                    </span>
                                )}
                            </div>
                            <div className="center fill-parent-absolute" style={{ overflow: "hidden" }} >
                                {selCodeTab === "syntax" ? 
                                    
                                    <div className="edit-render-syntax fill-parent-absolute full-dock">
                                        {/* <div 
                                            className="top" 
                                            style={{
                                                borderBottom: "1px solid #cfcfcf",
                                                padding: "5px 10px",
                                                textAlign: "center"
                                            }}
                                        >
                                            <span className="btn action-light" style={{ border: "1px solid #cfcfcf" }}
                                                onClick={() => setSyntax(code)}
                                            >
                                                <i className="fal fa-check"/>&nbsp; Apply
                                            </span>
                                            <span style={{
                                                marginLeft: 10,
                                                color: "#afafaf"
                                            }}>
                                                ({dirty ? "dirty" : "applied"})
                                            </span>
                                        </div> */}
                                        <div className="center fill-parent-absolute">
                                        <Editor
                                            defaultLanguage={"javascript"}
                                            options={{
                                                tabSize: 4,
                                            }}
                                            value={safeSyntax}
                                            height={"100%"}
                                            width={"100%"}
                                            onMount={(editor, monaco) => {
                                                editorRef.current = editor;
                                            }}
                                        />
                                        </div>
                                    </div>
                                
                                
                                : null}
                            </div>
                        </div>
                        <div className="full-dock fill-parent-absolute">
                            <div className="top tab-section no-margin">
                                {HELPER_TABS.map(tab =>
                                    <span 
                                        key={tab.id}
                                        className={`tab ${selHelperTab === tab.id ? "sel" : ""}`}
                                        onClick={() => setSelHelperTab(tab.id)}
                                    >
                                        {tab.label}
                                    </span>
                                )}
                            </div>
                            <div className="center full-dock fill-parent-absolute">
                                {payload.computing &&
                                    <div className="top compute-running">
                                        Computing...
                                    </div>
                                }
                                <div className="center">
                                    {selHelperTab === "data" && 
                                        <div className="full-dock fill-parent-absolute">
                                            <div className="top" style={{ 
                                                    padding: "5px 10px", 
                                                    borderBottom: "1px solid #cfcfcf",
                                                    display: "flex",
                                                    gap: 10,
                                                    flexDirection: "row",
                                                    alignItems: "center"
                                                }}>
                                                <div className={`arbitrary-button _inline-block`} 
                                                    onClick={() => run()}
                                                >
                                                    <span style={{ display: "inline-block", marginRight: 6 }}>
                                                        {running ?
                                                            <i className={`far fa-circle-notch fa-spin`}/> 
                                                            : <i className={`far fa-circle-play`}/> 
                                                        }
                                                    </span>
                                                    Run
                                                </div>
                                                {running ? 
                                                    <div style={{
                                                        flexGrow: 1,
                                                        position: "relative",
                                                        margin: 0,
                                                        padding: 0,
                                                        height: "100%",
                                                        border: "1px solid #cfcfcf"
                                                    }}>

                                                        {!isNull(status?.progress) ? 
                                                            <div style={{
                                                                display: "inline-block",
                                                                backgroundColor: "#64c8ff",
                                                                position: "absolute",
                                                                height: "100%",
                                                                width: format(".0%")(status.progress)
                                                            }}/>
                                                            : null
                                                        }

                                                        <div style={{
                                                            position: "absolute",
                                                            padding: 0,
                                                            width: "100%",
                                                            height: "100%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}>
                                                            {status?.text || <i>setStatus(&#123; text, progress &#125;)</i>}
                                                        </div>

                                                    </div>
                                                : <div style={{ flexGrow: 1 }}/>}
                                                <div className={`arbitrary-button _inline-block`} 
                                                    onClick={() => {
                                                        setError(null);
                                                        setResult(null);
                                                    }}
                                                >
                                                    <span style={{ display: "inline-block", marginRight: 6 }}>
                                                        <i className={`far fa-times`}/> 
                                                    </span>
                                                    Clear
                                                </div>

                                                
                                                    
                                                
                                                
                                                
                                            </div>
                                            <div className="json-wrap center">
                                                {error ? <span>Error: {error}</span> :
                                                result ?
                                                    <ReactJson
                                                        src={result}
                                                        name={"result"}
                                                        theme={"rjv-default"}
                                                        iconStyle="circle"
                                                        displayDataTypes={false}
                                                        enableClipboard={true}
                                                        collapsed={1}
                                                        />
                                                    :
                                                    <span>(no result)</span>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {selHelperTab === "context" ? 
                                        <div className="json-wrap">
                                            <ReactJson src={context}
                                                name={"context"}
                                                theme={"rjv-default"}
                                                iconStyle="circle"
                                                displayDataTypes={false}
                                                enableClipboard={true}
                                                collapsed={1}
                                            />
                                        </div>
                                    : selHelperTab === "resources" ? 
                                        <div className="json-wrap">
                                            <ReactJson src={rResources}
                                                name={"resources"}
                                                theme={"rjv-default"}
                                                iconStyle="circle"
                                                displayDataTypes={false}
                                                enableClipboard={true}
                                                collapsed={1}
                                                />
                                        </div>
                                    : selHelperTab === "api" ? 
                                        <div className="json-wrap">
                                            <ReactJson src={api}
                                                name={"api"}
                                                theme={"rjv-default"}
                                                iconStyle="circle"
                                                displayDataTypes={false}
                                                enableClipboard={true}
                                                collapsed={1}
                                            />
                                        </div>
                                    : selHelperTab === "lib" ?
                                        <div className="json-wrap">
                                            <ReactJson
                                                src={getLibHack()}
                                                name={"lib"}
                                                theme={"rjv-default"}
                                                iconStyle="circle"
                                                displayDataTypes={false}
                                                enableClipboard={true}
                                                collapsed={1}
                                                />
                                        </div>
                                    : null
                                    }



                                </div>
                            </div>
                        </div>
                    </SplitterLayout>
                </div>
            </div>
            
        </div>
    </div>


}

