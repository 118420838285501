import { useContext, useEffect, useState } from "react";
import { ContentItemContext, ResourcesContext } from "../../../context/Contexts";
import { ColorPickerInput } from "../../edit/ColorPickerInput";
import { DataBindButton } from "../../edit/DataBindButton";
import { ImagePickerInput } from "../../edit/ImagePickerInput";
import { EditImageSource } from "./EditImageSource";

const IMAGE_FITMENT = [
    { key: "none", label: "No fit", icon: "fal fa-ban" },
    { key: "cover", label: "Cover", icon: "fas fa-square-full" },
    { key: "contain", label: "Contain", icon: "fas fa-rectangle-wide" },
    { key: "tile", label: "Tile", icon: "fas fa-th-large" },
    { key: "fix", label: "Prlx", icon: "fas fa-alien" },
]
const defaultFitment = "cover";

const ATTACHMENTS = [
    { value: "scroll", description: "(background image scrolls with page)" },
    { value: "fixed",  description: "(background image doesn't scroll with page)" },
    { value: "local",  description: "(background image scrolls with element's contents)" },
]

const BLEND_MODES = [
    "normal",
    "multiply",
    "screen",
    "overlay",
    "darken",
    "lighten",
    "color-dodge",
    "saturation",
    "color",
    "luminosity",
]

const IMAGE_TYPES = [
    "none",
    "url",
    "linear-gradient",
    "radial-gradient",
    "repeating-linear-gradient",
    "repeating-radial-gradient"
]

const POSITIONS = [
    "left top",
    "left center",
    "left bottom",
    "right top",
    "right center",
    "right bottom",
    "center top",
    "center center",
    "center bottom",
    "50% 25%",
    "50 100"
]

const REPEATS = [
    { value: "repeat"       , description: "(image is repeated both vertically and horizontally. The last image will be clipped if it does not fit. This is default)" },
    { value: "repeat-x"	    , description: "(image is repeated only horizontally)" },
    { value: "repeat-y"	    , description: "(image is repeated only vertically)" },
    { value: "no-repeat"	, description: "(image is not repeated. The image will only be shown once)" },
    { value: "space"	    , description: "(image is repeated as much as possible without clipping. The first and last images are pinned to either side of the element, and whitespace is distributed evenly between the images)" },
    { value: "round"	    , description: "(image is repeated and squished or stretched to fill the space - no gaps)" },
]

const SIZES = [
    { value: "auto" },
    //{ value: "length", needsValues: true },
    //{ value: "percentage", needsValues: true },
    { value: "cover" },
    { value: "contain" },
    { value: "50%" },
    { value: "50% 50%" },
    { value: "100px 100px" },
]

// background-attachment
// background-blend-mode
// background-clip
// background-color
// background-image
// background-origin
// background-position
// background-repeat
// background-size


export const EditBackground = (props) => {


    const context = useContext(ContentItemContext);
    const resources = useContext(ResourcesContext);

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const onChange = (newValue) => {
        props?.onChange(newValue);
    }

    const rowPad = 10;

    const radioRowStyle = {
        display: "block",
        margin: "5px 0"
    }

    return <>
        
        <div className='form-group-header'>
            Background
        </div>

        <div className='full-dock' style={{ marginBottom: rowPad }}>
            <span className='left v-center nowrap' style={{ width: 100, marginRight: 10 }}>
                Attachment
            </span>
            <span className='center'>
                <div>
                    <select
                        className="full-width"
                        value={value?.attachment}
                        onChange={(ev) => {
                            const newValue = {
                                ...value,
                                attachment: ev.target.value
                            };
                            setValue(newValue);
                            onChange(newValue);
                        }}
                    >
                        <option value={null}>(not set)</option>
                        {ATTACHMENTS.map(entry =>
                            <option
                                key={entry.value}
                                value={entry.value}
                            >
                                {entry.value}
                            </option>
                        )}
                    </select>
                </div>
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton />
            </span>
        </div>

        <div className='full-dock' style={{ marginBottom: rowPad }}>
            <span className='left v-center nowrap' style={{ width: 100, marginRight: 10 }}>
                Blend Mode
            </span>
            <span className='center'>
                <select
                    className="full-width"
                    value={value?.blendMode}
                    onChange={(ev) => {
                        const newValue = {
                            ...value,
                            blendMode: ev.target.value
                        };
                        setValue(newValue);
                        onChange(newValue);
                    }}
                >
                    <option value={null}>(not set)</option>
                    {BLEND_MODES.map(mode =>
                        <option
                            key={mode}
                            value={mode}
                        >
                            {mode}
                        </option>
                    )}
                </select>
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton />
            </span>
        </div>

        {/* <div className='full-dock' style={{ marginBottom: rowPad }}>
            <span className='left v-center nowrap' style={{ width: 100, marginRight: 10 }}>
                Clip
            </span>
            <span className='center'>
                n/a
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton />
            </span>
        </div> */}

        <div className='h-dock' style={{ marginBottom: rowPad }}>
            <span className='left v-center nowrap' style={{ width: 100, marginRight: 10 }}>
                Color:
            </span>
            <span className='center'>
                <ColorPickerInput value={value?.color} onChange={(newColor) => {
                    const newValue = {
                        ...value,
                        color: newColor
                    }
                    setValue(newValue);
                    onChange(newValue);
                }} />
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton />
            </span>
        </div>

        <div className='h-dock' style={{ marginBottom: rowPad }}>
            <span className='left v-center nowrap' style={{ width: 100, marginRight: 10 }}>
                Image:
            </span>
            <span className='center'>
                {/* <EditImageSource value={value?.image} /> */}


                <div>
                    <select
                        className="full-width"
                        value={value?.imageType}
                        onChange={(ev) => {
                            const newValue = {
                                ...value,
                                imageType: ev.target.value
                            };
                            setValue(newValue);
                            onChange(newValue);
                        }}
                    >
                        <option value={null}>(not set)</option>
                        {IMAGE_TYPES.map(entry =>
                            <option
                                key={entry}
                                value={entry}
                            >
                                {entry}
                            </option>
                        )}
                    </select>
                </div>
                <div>
                    {value?.imageType === "url" ?
                        <ImagePickerInput
                            value={value?.imageId}
                            onChange={async (val) => {
                                const newValue = {
                                    ...value,
                                    imageId: val
                                };
                                setValue(newValue);
                                onChange(newValue);
                            }}
                            style={{ width: "100%" }}
                        />
                        : null
                    }
                </div>
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton />
            </span>
        </div>

        

        {/* <div className='full-dock' style={{ marginBottom: rowPad }}>
            <span className='left v-center nowrap' style={{ width: 100, marginRight: 10 }}>
                Origin
            </span>
            <span className='center'>
                n/a
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton />
            </span>
        </div> */}

        <div className='full-dock' style={{ marginBottom: rowPad }}>
            <span className='left v-center nowrap' style={{ width: 100, marginRight: 10 }}>
                Position
            </span>
            <span className='center'>
                <select
                    className="full-width"
                    value={value?.position}
                    onChange={(ev) => {
                        const newValue = {
                            ...value,
                            position: ev.target.value
                        };
                        setValue(newValue);
                        onChange(newValue);
                    }}
                >
                    <option value={null}>(not set)</option>
                    {POSITIONS.map(pos =>
                        <option
                            key={pos}
                            value={pos}
                        >
                            {pos}
                        </option>
                    )}
                </select>
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton />
            </span>
        </div>

        <div className='full-dock' style={{ marginBottom: rowPad }}>
            <span className='left v-center nowrap' style={{ width: 100, marginRight: 10 }}>
                Repeat
            </span>
            <span className='center'>
                <select
                    className="full-width"
                    value={value?.repeat}
                    onChange={(ev) => {
                        const newValue = {
                            ...value,
                            repeat: ev.target.value
                        };
                        setValue(newValue);
                        onChange(newValue);
                    }}
                >
                    <option value={null}>(not set)</option>
                    {REPEATS.map(entry =>
                        <option
                            key={entry.value}
                            value={entry.value}
                        >
                            {entry.value}
                        </option>
                    )}
                </select>
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton />
            </span>
        </div>


        <div className='full-dock' style={{ marginBottom: rowPad }}>
            <span className='left v-center nowrap' style={{ width: 100, marginRight: 10 }}>
                Size
            </span>
            <span className='center'>
                <div>
                    <select
                        className="full-width"
                        value={value?.size}
                        onChange={(ev) => {
                            const newValue = {
                                ...value,
                                size: ev.target.value
                            };
                            setValue(newValue);
                            onChange(newValue);
                        }}
                    >
                        <option value={null}>(not set)</option>
                        {SIZES.map(entry =>
                            <option
                                key={entry.value}
                                value={entry.value}
                            >
                                {entry.value}
                            </option>
                        )}
                    </select>
                </div>
            </span>
            <span className='right v-center' style={{ marginLeft: 10 }}>
                <DataBindButton />
            </span>
        </div>



        

    </>

}