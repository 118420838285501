
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Popup from "reactjs-popup";
import { EditMeasures } from "./EditMeasures";

const getIcon = (item) =>
    item.type === "agg" ? <i className="fad fa-sigma"/> :
    item.type === "group" ? <i className="fal fa-knife-kitchen"/> :
    <i className="fal fa-rocket"/>

export const AggsDimContainer = (props) => {

    const containerId = props.id;
    //const initialItems = props.items || [];
    //const [items, setItems] = useState(initialItems)
    const items = props.items || [];
    const showLabel = items?.length < 3;
    
    const canAddGroups = items.length < 5;

    const [measures, setMeasures] = useState();

    return <Droppable droppableId={containerId} direction="horizontal">
        {(provided, snapshot) => 
            <div ref={provided.innerRef}
                className={`container ${snapshot.isDraggingOver ? "dragging-over" : ""}`}
                {...provided.droppableProps}
            >
                {items.map((item, itemIndex) => {
                    const canDelete = item.type === "group";
                    return <Draggable key={item.id} draggableId={item.id} index={itemIndex}>
                        {(provided, snapshot) => 
                            <div ref={provided.innerRef}
                                title={item.label}
                                alt={item.label}
                                className={`btn1`}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => props.itemClicked?.({ item })}
                            >
                                {getIcon(item)}&nbsp;
                                {showLabel ? item.id : null}
                                {canDelete ?
                                    <span className="btn-delete"
                                        onClick={(ev) => {
                                            props.deleteClicked?.({ containerId, item })
                                            ev.stopPropagation();
                                        }}
                                    >
                                        <i className="fal fa-times"/>
                                    </span>
                                    : null
                                }
                            </div>
                        }
                    </Draggable>
                })}
                {canAddGroups ?
                <div className={`btn1 ghost ${snapshot.isDraggingOver ? "hide" : ""}`}
                        {...provided.dragHandleProps}
                        onClick={() => props.addGroupsClicked?.({ containerId })}
                    >
                        <i className="fal fa-plus"/>&nbsp;
                        {getIcon({ type: "group" })}
                </div>
                : null}
            </div>
        }
    </Droppable>


}