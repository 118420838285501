import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { GetToken_Password } from "../../api/oauth";
import * as restMethods from "../../api/restMethods";
import { AuthContext } from "../../context/Contexts";
import * as globals from "../../globals";
import "./Login.scss";
import "../MainLayout.scss";
import "../nav/LeftMenu.scss";
import { AuthOuter } from "./AuthOuter";
import ReCAPTCHA from "react-google-recaptcha";


const translateError = (msg) => {
    switch (msg) {
        case "Failed to fetch":
            return "There is a problem connecting to the server. Please check your internet connection or try again later.";
        case "Invalid grant: user credentials are invalid":
            return "User credentials are invalid or expired.";
        default:
            return msg;
    }
}


export const RecoverPassword = (props) => {

    const history = useHistory();
    const [email, setEmail] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);
    //const authcontext = useContext(AuthContext);
    const authContext = useContext(AuthContext);
    const [token, setToken] = useState(null);
    const [step, setStep] = useState(1);

    const onTokenChange = (t) => {
        setToken(t);
    }

    const submitRequest = async () => {
        console.log("submitRequest");
        const body = {
            email
        };
        console.log('submit Password Recovery request', body);
        let success = false;

        setError(false);
        setFetching(true);
        try {
            const res1 = await restMethods.postJson(null, `${globals.apiRoot}/auth/send-password-recovery`, body);
            console.log('res1', res1);
            if (res1.status === 200) {
                success = true;
            }
            else {
                setError("Request failed. Please try again later.")
            }
        }
        catch (err) {
            setError(err.message);
        }
        setFetching(false);

        if (success) {
            setStep(2);
        }
        
    }


    return <AuthOuter title={step === 1 ? "Recover Password" : "Email Sent"} showBackLink>

        {error ? <div className="error">
            <i className="fas fa-warning" /><br/>
            {translateError(error)}
        </div> : null}

        {step === 1 ? <>
            <div className="entry">
                <div className="label">
                    Email:
                </div>
                <input id="email" type="text" placeholder="email" value={email || ""} onChange={(ev) => setEmail(ev.target.value)} />
            </div>
            <div className="entry">
                <ReCAPTCHA
                    sitekey="6LdOtTkUAAAAAM3Wr_s30GcEXrXcRKjXlr6AU_9P"
                    onChange={onTokenChange}
                />
            </div>
        </> : <>
            <div className="entry">
                <div className="label">
                        <p>
                            Please check your inbox for an email with
                            instructions on creating a new password.
                        </p>
                        <p>
                            If you don't see it, please wait a few minutes or check your spam folder.
                        </p>
                </div>
            </div>
        </>}
        {fetching ? <div>
            Please wait...
        </div> : null}
        <div className="submit-section">
            
            
            <button type="submit" className="login" disabled={fetching || (step === 1 && !token)} onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
                
                if (step === 2) {
                    history.push("/");
                }
                else {
                    submitRequest();
                }
            }}>
                {step === 1 ? "Submit" : "OK"}
            </button>


        </div>
        <div className="signup-section">
            <div className="intro">
                Don't have an account?
                <Link to="/sign-up">
                    Sign up
                </Link>
            </div>
        </div>

    </AuthOuter>



}