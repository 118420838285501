import { Link } from "react-router-dom";
import { docLinks } from "../../../doclinks";
import { useCollections } from "../../../hooks/useCollection";
import { useProjectId } from "../../../hooks/useProjectId";
import { NavButtons } from "../../nav/_NavButtonsDef";

const buttons = [
    "content",
    //"reports",
    "colors",
    "images",
    //"menus",
    "selections",
    //"components",
    "models",
    
    //"files"
    ].map(key => {
        return NavButtons.find(b => b.key === key);
    })
    .map(button => {
        return {
            ...button,
            icon: button.icon.replace("fas ", "fad ")
        }
    })


export const ContentTile = (props) => {

    const { subscription_id, project_id } = useProjectId();

    return <div className="tile white-bg pad">
        <div className="title">
            Content, etc.

            <div className="help-link">
                <a href={docLinks.web_content} target="_blank" className="icon">
                    <i className="fal fa-question-circle"/>
                </a>
            </div>
        </div>

        <div className="buttons-grid">

            {buttons.map(btn =>
                <Link
                    key={btn.key}
                    className="button"
                    to={`/subscription/${subscription_id}/project/${project_id}/${btn.key}`}
                >
                    <i className={`${btn.icon} glyph`}/>
                    {btn.label}
                </Link>
            )}
            
            
            

        </div>

        
    </div>
}