import * as globals from "../globals";
import * as restMethods from "./restMethods";

const isNull = (x, fallback) => x === null || x === undefined ? fallback : x;

// def is a construct
export const createController = (construct) => {

    const def = {
        ...construct,
        namePlural: construct.namePlural || construct.name + 's',
        parents: construct.isProject ? ["subscription"] : ["subscription", "project", ...(construct.parents || [])]
    }

    let controller = {};

    controller.auth_getItems = async (auth, keys) => {
        let root = "";
        def.parents?.forEach(parent => root = root + `${parent}/` + keys?.[`${parent}_id`] + "/");
        const url = `${globals.apiRoot}/${root}${def.namePlural}${construct.getItemsFull ? "?full=1" : ""}`;
        var response = await restMethods.getJson(auth, url);
        var items = await response.json();
        return items;
    }

    controller.auth_getItem = async (auth, keys, id) => {
        let root = "";
        def.parents?.forEach(parent => root = root + `${parent}/` + keys?.[`${parent}_id`] + "/");
        const url = `${globals.apiRoot}/${root}${def.apiName || def.name}/${isNull(id,"")}`;
        console.log("getItem", url);
        var response = await restMethods.getJson(auth, url);
        var item = await response.json();
        return item;
    }

    // if construct hasrevisions?
    controller.auth_getItemRevision = async (auth, keys, id, revision) => {
        let root = "";
        def.parents?.forEach(parent => root = root + `${parent}/` + keys?.[`${parent}_id`] + "/");
        const url = `${globals.apiRoot}/${root}${def.name}/${id}/revision/${revision}`;
        var response = await restMethods.getJson(auth, url);
        var item = await response.json();
        return item;
    }

    controller.auth_getItemRevisions = async (auth, keys, id) => {
        let root = "";
        def.parents?.forEach(parent => root = root + `${parent}/` + keys?.[`${parent}_id`] + "/");
        const url = `${globals.apiRoot}/${root}${def.name}/${id}/revisions`;
        var response = await restMethods.getJson(auth, url);
        var item = await response.json();
        return item;
    }


    controller.auth_createItem = async (auth, keys, item, parent_id) => {
        let root = "";
        def.parents?.forEach(parent => root = root + `${parent}/` + keys[`${parent}_id`] + "/");
        const parentIdStr = parent_id ? `&parent_id=${parent_id}` : "";
        const url = `${globals.apiRoot}/${root}${def.apiNamePlural || def.namePlural}?sender_id=${keys.socket_id}${parentIdStr}`;
        const response = await restMethods.postJson(auth, url, { ...item });
        if( !response.ok ){
            const err = {
                status: response.status,
                statusText: response.statusText,
            }
            try{
                err.text = await response.text();
            } catch{}
            //throw new Error(err);
            throw new Error(err.text || err.statusText || err.status);
        }
        
        const result = await response.json();
        return result;
    }

    controller.auth_createItemRevision = async (auth, keys, item_id, rev) => {
        let root = "";
        def.parents?.forEach(parent => root = root + `${parent}/` + keys[`${parent}_id`] + "/");
        const url = `${globals.apiRoot}/${root}${def.name}/${item_id}/revisions?sender_id=${keys.socket_id}`;
        console.log("createItemRevision", url);
        const response = await restMethods.postJson(auth, url, { ...rev });
        const result = await response.json();
        return result;
    }

    controller.auth_deleteItem = async (auth, keys, item_id) => {
        let root = "";
        def.parents?.forEach(parent => root = root + `${parent}/` + keys[`${parent}_id`] + "/");
        let url = `${globals.apiRoot}/${root}${def.apiName || def.name}/${item_id}?sender_id=${keys.socket_id}`;
        if (keys.filename) {
            url += '&filename=' + keys.filename
        };
        var response = await restMethods.aDelete(auth, url);
        
        try{
            // old style
            var result = await response.json();
            return result?.rowsAffected;
        }
        catch{
            // migrate to this
            return response.ok;
        }
    }

    controller.auth_deleteItemRevision = async (auth, keys, item_id, revision) => {
        let root = "";
        def.parents?.forEach(parent => root = root + `${parent}/` + keys[`${parent}_id`] + "/");
        let url = `${globals.apiRoot}/${root}${def.name}/${item_id}/revision/${revision}?sender_id=${keys.socket_id}`;
        if (keys.filename) {
            url += '&filename=' + keys.filename
        };
        var response = await restMethods.aDelete(auth, url);
        //var result = await response.json();
        //return result?.rowsAffected;
        return response.ok;
    }

    controller.auth_replaceItem = async (auth, keys, item_id, item) => {
        let root = "";
        def.parents?.forEach(parent => root = root + `${parent}/` + keys[`${parent}_id`] + "/");
        const url = `${globals.apiRoot}/${root}${def.name}/${item_id}?sender_id=${keys.socket_id}`;
        var response = await restMethods.aPut(auth, url, { ...item });
        try{
            var result = await response.json();
            return result?.rowsAffected;
        }
        catch{
            return response.ok;
        }
    }

    controller.auth_patchItem = async (auth, keys, item_id, item) => {
        let root = "";
        def.parents?.forEach(parent => root = root + `${parent}/` + keys[`${parent}_id`] + "/");
        const url = `${globals.apiRoot}/${root}${def.apiName || def.name}/${item_id}?sender_id=${keys.socket_id}`;
        console.log("url", url);
        const response = await restMethods.aPatch(auth, url, { ...item });
        return response.ok;
    }

    controller.auth_patchItemRevision = async (auth, keys, item_id, revision, body) => {
        let root = "";
        def.parents?.forEach(parent => root = root + `${parent}/` + keys[`${parent}_id`] + "/");
        const url = `${globals.apiRoot}/${root}${def.name}/${item_id}/revision/${revision}?sender_id=${keys.socket_id}`;
        var response = await restMethods.aPatch(auth, url, { ...body });
        return response.ok;
    }

    controller.auth_moveItem = async (auth, keys, item_id, dir) => {
        let root = "";
        def.parents?.forEach(parent => root = root + `${parent}/` + keys[`${parent}_id`] + "/");
        const url = `${globals.apiRoot}/${root}${def.apiName || def.name}/${item_id}/move/${dir}?sender_id=${keys.socket_id}`;
        console.log("moveItem url", url);
        const response = await restMethods.aGet(auth, url);
        return response.ok;
    }

    controller.auth_orderItems = async (auth, keys, item_ids) => {
        let root = "";
        def.parents?.forEach(parent => root = root + `${parent}/` + keys[`${parent}_id`] + "/");
        const url = `${globals.apiRoot}/${root}${def.namePlural}-order?sender_id=${keys.socket_id}`;
        var response = await restMethods.aPut(auth, url, item_ids);
        var result = await response.json();
        return result; // return value is not important here
    }

    controller.auth_getTable = async (auth, keys, id) => {
        let root = "";
        def.parents?.forEach(parent => root = root + `${parent}/` + keys?.[`${parent}_id`] + "/");
        const url = `${globals.apiRoot}/${root}${def.name}/${id}/file`;
        var response = await restMethods.getJson(auth, url);
        var item = await response.json();
        return item;
    }

    return controller;
    
}

