import { computeApi } from "../api/computeApi";

export const InspectStages = async (auth, env, sub_id, p_id, ds_id, rev_id, priorStages, limit, inspectionLevels) => {

    console.log("inspect stages", { priorStages, limit });

    const options = {
        limit,
        inspect: true,
        inspectionLevels
    };

    const eStages = priorStages?.filter(s => !s.disabled)?.map(s => s.data) || [];

    const stages = [
        ...(eStages),
        ...(limit ? [{ $limit: limit }] : [])
    ];
    
    try{
        console.log("running inspection using computeV2", stages)
        console.log("options", options)
        const r = await computeApi.computeV2(auth, env, sub_id, p_id, ds_id, rev_id, stages, options);
        console.log("inspect stages returned", r);
        return r;
    }
    catch(err){
        console.error(err);
    }


}