import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { controllers } from "../api/controllers";
import { constructs } from "../api/contructs";
import { createSyncedApiHook } from "../api/createSyncedApiHook";
import { useAuth } from "./useAuth";
import { usePageId } from "./usePageId";
import { useProjectId } from "./useProjectId";

const construct = constructs["content-item"];

export const useContentItemsApi = createSyncedApiHook(construct);

// export const NOTUSED__useRootContentItem = () => {

//     // note: this hook attempts to load the root content item, but a root content item might not exist (yet).
//     // When a root content item is added elsewhere, it will update the redux entries and this hook should pick it up.
    
//     const { project_id, subscription_id } = useProjectId();
//     const dispatch = useDispatch();
//     const controller = controllers["content-item"];
//     const auth = useAuth();

//     const { contentItem, entries, loading, error } = useSelector(state => {
//         return {
//             contentItem: state.contentItems.dict?.[state.contentItems.entries?.[0]?.id],
//             entries: state.contentItems.entries,
//             loading: state.contentItems.loading,
//             error: state.contentItems.error
//         }
//     }, shallowEqual)

//     const load = async () => {
//         // "entries" is just [rootEntry] in the case of this 'construct'
//         console.log("ROOT LOAD!");
//         if( !loading ){
//             dispatch({ type: "CONTENTITEM_SET_LOADING_ENTRIES" }); 
//         }

//         try {
//             // this will return the root plus all children that aren't set to lazy load
//             const items = await controller.auth_getItem(auth, { subscription_id, project_id });
//             const root = items?.[0];

//             // should I attach parent ids here? (or should api do it) (or nobody)
//             // attach parent_id
//             items.forEach(item => {
//                 item.children?.forEach(childRef => {
//                     const child = items.find(i => i.id === childRef.id);
//                     child.parent_id = item.id;
//                 })
//             })
//             console.log("items after parent_id assign", items);

//             dispatch({ type: "CONTENTITEM_SET_MULTIPLE", 
//                 newEntries: root ? [{ id: root?.id, ctype: root?.ctype }] : [],
//                 values: items
//             });
//         }
//         catch (err) {
//             dispatch({ type: "CONTENTITEM_SET_LOADING_ENTRIES", loading: false, error: err.message });
//         }

//     }

//     useEffect(() => {

//         if(!subscription_id || !project_id || loading || error) return;
//         if( contentItem ) return;
//         dispatch({ type: "CONTENTITEM_SET_LOADING_ENTRIES" }); 
//         load();

//     }, [subscription_id, project_id]);

//     return [
//         entries?.[0],
//         loading,
//         error,
//         load // reload
//     ];

// }