import { useProject, useProjectsApi } from "../../../hooks/useProject"
import Popup from "reactjs-popup";
import { TextFrame } from "../../modal-dialog/VerifyFrame";
import { Link } from "react-router-dom";
import { useProjectId } from "../../../hooks/useProjectId";
import { docLinks } from "../../../doclinks";

export const ProjectInfoTile = (props) => {

    const api = useProjectsApi();
    const { project_id, subscription_id } = useProjectId();
    const [project, projectLoading, projectError] = useProject();
    

    return <div className="tile gray-bg pad">
        <div className="title">
            <Popup 
                modal
                trigger={
                    <div className="supacool">
                    {project?.label}
                    </div>
                }
            >
                {closeModal => <TextFrame
                    title={"Edit project label"}
                    label={project?.label}
                    initialTextValue={project?.label}
                    close={closeModal} confirmed={async (text) => {
                        const newProject = {
                            ...project,
                            label: text
                        };
                        await api.updateItem(project.id, newProject);
                        closeModal();
                    }}
                />}
            </Popup>

            <div className="help-link">
                <a href={docLinks.project} target="_blank" className="icon">
                    <i className="fal fa-question-circle"/>
                </a>
            </div>
        </div>

        <div className="details">
            <Popup 
                modal
                trigger={
                    <div className="client">
                    {!project ? "loading..." : project?.client || "[Client unspecified]"}
                    </div>
                }
            >
                {closeModal => <TextFrame
                    title={"Edit project client"}
                    label={project?.client}
                    initialTextValue={project?.client}
                    close={closeModal} confirmed={async (text) => {
                        const newProject = {
                            ...project,
                            client: text
                        };
                        await api.updateItem(project.id, newProject);
                        closeModal();
                    }}
                />}
            </Popup>

            <Popup 
                modal
                trigger={
                    <div className="project-date">
                    {!project ? "loading..." : project?.date || "[Date unspecified]"}
                    </div>
                }
            >
                {closeModal => <TextFrame
                    title={"Edit project date"}
                    label={project?.date}
                    initialTextValue={project?.date}
                    close={closeModal} confirmed={async (text) => {
                        const newProject = {
                            ...project,
                            date: text
                        };
                        await api.updateItem(project.id, newProject);
                        closeModal();
                    }}
                />}
            </Popup>

            <div>
                <Link className="project-settings" to={`/subscription/${subscription_id}/project/${project_id}/settings`}>
                    Settings
                </Link>

                {/* <div className="project-settings">
                    Help
                </div> */}
            </div>
        </div>
        
        <div className="copyright">
            {project?.copyright || "Copyright © 2022 Company Name Inc. All Rights Reserved"}
        </div>
        
    </div>
}