import './DataTable.scss';
import * as d3Format from 'd3-format';
import React, { useState } from 'react';
import { isObj } from '../../utils/ContentProcessor';
import { getProperty } from 'dot-prop';
import { DEFAULT_COL_WIDTH, explodeCols, getAlignment, getColumnGlyph, getColumnWidth, GetDataTableLayout, getHeaderAlignment, isCellObj, isNull } from './DataTableHelper';
import { DataTableCell } from './DataTableCell';
import { useContext } from 'react';
import { EditContentContext } from '../content/EditContentContext';

export const toCellJsx = (obj) => {
    return <span title={`n=${obj.n}`}>{obj?.val}</span>;
}




const sortDirLists = {
    string: ["asc", "desc", null],
    number: ["desc", "asc", null]
}

const getNextDir = (dir, type) => {
    const sortDirList = sortDirLists[type];
    if( !sortDirList ) return null;

    const idx = sortDirList.indexOf(dir);
    const newDir = sortDirList[(idx + 1) % sortDirList.length];
    return newDir;
}



export const DataTable = (props) => {

    const ec = useContext(EditContentContext);

    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [sortState, setSortState] = useState(null);

    
    const options = props.options || {};
    const { rowHover, selectRow } = options;

    const sortClicked = (col) => {
        const key = col.key;
        const colType = col.type || "number"; // a column won't have a type if parent is comet. will probably need to enhance this eventually.
        if( key === sortState?.key ){
            const newDir = getNextDir(sortState?.dir, colType);
            setSortState(newDir ? { key, dir: newDir } : null);
        }
        else{
            const newDir = getNextDir(null, colType);
            setSortState(newDir ? { key, dir: newDir } : null)
        }
    }


    const { sections, columns, categories, hasAtLeastOneCategory, showSectionHeadings } = 
        GetDataTableLayout(props, { sortState }); // this also adds descriptives where necessary
    


    return <div className={`data-table ${selectRow ? ' no-select' : ''} ${props.className || ""}`} style={props.style} 
        onClick={ev => {
            // todo: i don't really like this. instead i should just intercept the column clicking
            // because clicking on the table should probably enter edit mode            
            if( ec?.mode === "edit" && (ev.altKey)){
                //ec?.setSelectionId(item?.id);
            }
            else{
                ev.stopPropagation();
                ev.preventDefault();
            }

        }}
    >
        <table>
            <thead>

                {/* categories */}
                {hasAtLeastOneCategory ?
                <tr>
                    {categories.map((cat, i) => {
                        const width = cat.columns.reduce((a, col) => a + getColumnWidth(col), 0);
                        
                        let catHeaderStyle = {
                            width,
                            minWidth: width,
                            textAlign: "center",
                        }

                        if( cat.sticky ){
                            catHeaderStyle.position = "sticky";
                            catHeaderStyle.left = 0;
                            catHeaderStyle.backgroundColor = "#ffffff";
                            catHeaderStyle.zIndex = 1;
                        }

                        return <th className="cat" key={i} style={catHeaderStyle} colSpan={cat.columns.length}>
                            {cat.label}
                        </th>;
                    })}
                </tr> : null}

                {/* column headers */}
                <tr>
                    {categories.map((cat, catIdx) => {

                        const catCols = explodeCols(cat.columns);
                        
                        return catCols.map((col, colIdx) => {
                            let headerStyle={
                                width: getColumnWidth(col),
                                //minWidth: col.width,
                                //textAlign: getHeaderAlignment(col),
                                ...col.style,
                                ...col.headerStyle
                            }

                            if((colIdx === cat.columns.length - 1) && (catIdx < categories.length - 1)){
                                headerStyle.borderRight = "2px solid #cfcfcf";
                            }

                            const sortIndicator = sortState?.key === col.key ?
                                <span style={{ marginLeft: 5 }}>
                                    {col.type === "string" ? 
                                        <i className={sortState.dir === "asc" ? "fal fa-arrow-down-a-z" : "fal fa-arrow-up-z-a"} /> :
                                        <i className={sortState.dir === "asc" ? "fal fa-arrow-down-1-9" : "fal fa-arrow-up-9-1"} />
                                    }
                                </span>
                                : null;
                                                            
                            return <th 
                                key={col.key} 
                                style={headerStyle}
                                className={(hasAtLeastOneCategory ? "nested" : "")}
                                onClick={() => {
                                    // only allow sort if column has a type (?)
                                    if( col.type || col.parent?.type ){
                                        sortClicked(col);
                                    }
                                }}
                            >
                                <span className="th-content" style={{
                                    textAlign: getHeaderAlignment(col)
                                }}>
                                    {getColumnGlyph(col)}
                                    <span className='th-label'>
                                        {col.label || col.key}
                                    </span>
                                    {sortIndicator && <span className='th-sort-ind'>
                                        {sortIndicator}
                                    </span>}
                                </span>
                            </th>
                        })
                    })}
                </tr>
            

            </thead>
            <tbody>

                {/* sections of rows */}
                {sections?.map?.((section, sectionIdx) => 
                    <React.Fragment key={sectionIdx}>

                        {showSectionHeadings && 
                            <tr className="section-header">
                                <td className="cell section-label" colSpan={columns?.length}>
                                    {section.label}
                                </td>
                            </tr>
                        }

                        {section.rows.map((row, rowIndex) => {
                            let lastColSep = false;
                            return <tr key={rowIndex} className={`${rowHover ? 'row-hover' : ''} ${selectedRow === row ? ' sel' : ''}`}
                                onClick={() => {
                                if (selectRow) {
                                    setSelectedRow(row);
                                }
                            }}>
                                {categories.map((cat, catIdx) => {
                                    
                                    return cat.columns.map((col, colIdx) => {

                                        const lastInCategory = colIdx === cat.columns.length - 1;
                                        const lastCategory = catIdx === categories.length - 1;

                                        return <DataTableCell 
                                            key={catIdx + "|" + colIdx}
                                            row={row} 
                                            col={col} 
                                            lastInCategory={lastInCategory}
                                            lastCategory={lastCategory}
                                            smartObj={props.smartObj}
                                            />
        
                                    })
                                })}
                            </tr>
                        })}

                    </React.Fragment>

                )}
                
            </tbody>
        </table>
    </div>;
    
}