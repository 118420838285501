import { useContext, useRef, useState } from "react";
import Popup from "reactjs-popup";
import { ContentItemContext, PageContext } from "../../context/Contexts";
import { useContentItemsApi } from "../../hooks/useContentItems";
import { getAllowedChildTypes, getTypeInfo } from "../page-content/ContentTypes";
import { PageEditorContext } from "../pages/Pages";
import "./ExportContentItemWrapper.scss";
import domtoimage from "dom-to-image-more";
import { saveAs } from 'file-saver';


const FRAME_COLORS = [
    { fill: "#efefef", text: "#808080" }
    // { fill: "#00517D", text: "#ffffff" },
    // { fill: "#0078BE", text: "#ffffff" },
    // { fill: "#00A3AD", text: "#ffffff" },
    // { fill: "#82C341", text: "#ffffff" },
    // { fill: "#84329B", text: "#ffffff" },
]


const exportPng = async (item_id) => {
    const id = "__" + item_id;
    const node = document.getElementById(id);
    const scale = 2;
    const blob = await domtoimage.toPng(node, {
        style: {
            'background-color': '#ffffff',
            'transform': `scale(${scale})`,
            'transform-origin': '0 0' 
        },
        // filter: (el) => !(el.dataset && el.dataset['hideexport'] === '1'),
        width: node.scrollWidth * scale,
        height: node.scrollHeight * scale
    });
    saveAs(blob, "export.png");
}

const exportSvg = async (item_id) => {
    const id = "__" + item_id;
    const node = document.getElementById(id);
    const scale = 1;
    const blob = await domtoimage.toSvg(node, {
        style: {
            'background-color': '#ffffff',
            'transform': `scale(${scale})`,
            'transform-origin': '0 0' 
        },
        // filter: (el) => !(el.dataset && el.dataset['hideexport'] === '1'),
        width: node.scrollWidth * scale,
        height: node.scrollHeight * scale
    });
    saveAs(blob, "export.svg");
}

export const ExportContentItemWrapper = (props) => {

    const api = useContentItemsApi();

    const hackRef = useRef(null);

    const pe_context = useContext(PageEditorContext);
    const page = useContext(PageContext);

    const item = props.content_item;
    const level = props.level || 0;
    const frameColor = FRAME_COLORS[level % FRAME_COLORS.length];
    const context = useContext(ContentItemContext);
    
    const viewAsTable = props.viewAsTable;
    const setViewAsTable = props.setViewAsTable;

    const ctype = item?.["ctype"];

    const [keepOpen, setKeepOpen] = useState(false);//ctype === "PAGE" ? true : false);

    // useEffect(() => {
    //     console.log('EditContentItemWrapper page changed', keepOpen);
    //     setKeepOpen(true);//ctype === "PAGE" ? true : false);
    // }, [page])

    // if (!props.edit) {
    //     return props.children;
    // }

    
    const ctypeInfo = getTypeInfo(ctype);
    const pTypes = getAllowedChildTypes(props.pType);
    const childTypes = getAllowedChildTypes(ctype, pTypes);

    
    const allowsChildren = childTypes?.length > 0;
    
    
    const hasCRSyntax = ctypeInfo?.hasCRSyntax;
    
    const exportRootId = `${item?.id}-export-root`;
    //console.log('item', item);
    const exportId = `__${item?.id}`;
    const exportHackId = `_hack_${item?.id}`;

    const hide = props.hide;

    const fpaStr = props?.fillParentAbsolute ? "fill-parent-absolute" : "";

    return <div
        id={exportRootId}
        ref={hackRef}
        className={`export-item-wrapper ${fpaStr} ${ctype === "PAGE" ? "page-style" : ""} ${props.fill ? 'fill' : ''} ${keepOpen ? 'open' : ''} ${allowsChildren ? "" : "no-children"}`} style={{ borderColor: frameColor.fill }}>
        {!hide ?
            <span className='ctype' style={{ backgroundColor: frameColor.fill, color: frameColor.text }}>
                {setViewAsTable ? <>
                    <span style={{ marginRight: 5 }}>
                        View:
                    </span>
                    <span
                        style={{ marginRight: 10 }}
                        className={`smallbtn ${viewAsTable ? "sel" : ""}`}
                        title="Table"
                        onClick={() => setViewAsTable?.(!viewAsTable)}
                    >
                        <i className='fal fa-table' />
                    </span>
                </>
                    : null}
                <span style={{ marginRight: 10 }}>
                    Export:
                </span>
                <span className='smallbtn' title="Image (.png)" onClick={() => exportPng(item.id)}>
                    <i className='fal fa-image' />
                </span>
                <span className='smallbtn' title="Vector Image (.svg)" onClick={() => exportSvg(item.id)}>
                    <i className='fal fa-vector-square' />
                </span>
                <span className='smallbtn disabled' title="Document (.pdf)">
                    <i className='fas fa-file-pdf' />
                </span>
                <span className='smallbtn disabled' title="Table (.csv)">
                    <i className='fal fa-table' />
                </span>
                <span className='smallbtn disabled' title="Formatted Table (.xls)">
                    <i className='fas fa-file-excel' />
                </span>

                <Popup
                    keepTooltipInside
                    position="bottom right"
                    onOpen={() => setKeepOpen(true)}
                    onClose={() => setKeepOpen(false)}
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                    trigger={
                        <span className='smallbtn'>
                            <i className='fal fa-ellipsis-h' />
                        </span>
                    }
                >
                    <div className='popup-menu'>
                        <div className="menu-item">
                            Image (.png)
                        </div>
                        <div className="menu-item">
                            Vector Image (.svg)
                        </div>
                        <div className="menu-item disabled">
                            Document (.pdf)
                        </div>
                        <div className="menu-item disabled">
                            Table (.csv)
                        </div>
                        <div className="menu-item disabled">
                            Formatted Table (.xlsx)
                        </div>
                    </div>
                </Popup>
            </span>
            : null}
        {props.children}
    </div>;


}