import "./SelectPropertyEditor.scss";

export const SelectPropertyEditor = (props) => {
    const options = props.options;
    return <span className="select-property-editor">
        <select value={props.value} onChange={ev => props.onChange?.(ev.target.value)}>
            <option value="">[not set]</option>
            {options?.map(option => {
                const value = typeof(option) === "string" ? option : option.label || option.value;
                const label = typeof(option) === "string" ? option : option.label;
                return <option key={value} value={value}>{label}</option>
            })}
        </select>
    </span>
}

