
const initialState = {
    leftMenuOpen: true,
    rightMenuOpen: false
}


export const UIReducer = (state = initialState, action) => {

    switch( action.type ){
        case 'LEFT_MENU_OPEN_SET' : return {
            ...state,
            leftMenuOpen: action.value
        }
        case 'RIGHT_MENU_OPEN_SET' : return {
            ...state,
            rightMenuOpen: action.value
        }
        default:
    }

    return state;
}

