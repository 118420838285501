import React from "react";


export const AuthContext = React.createContext(null);
export const SubscriptionIdContext = React.createContext(null);
export const ProjectContext = React.createContext(null);
export const ProjectIdContext = React.createContext(null);

export const ContentItemContext = React.createContext(null);
//export const ComputableContentItemContext = React.createContext(null);
export const HasPipelinesContext = React.createContext(null);
export const HasPipelineContext = React.createContext(null);

export const HasComputeContext = React.createContext(null);
export const HasRenderContext = React.createContext(null);
export const HasRenderTableContext = React.createContext(null);

export const HasSyntaxContext = React.createContext(null);

export const ResourcesContext = React.createContext(null);
export const PageContext = React.createContext(null);
export const UrlRootContext = React.createContext(null);
export const EtlFlowContext = React.createContext(null);
export const PipelineEditorContext = React.createContext(null);
export const PipelineStageEditorContext = React.createContext(null);

export const EditDataSourceContext = React.createContext(null); // should this be renamed?

export const HasDataSourcesContext = React.createContext(null); // loads list of all available data sources

export const DataSourceContext = React.createContext(null);
export const HasDataSourceIdContext = React.createContext(null);


export const SelectDataSourceContext = React.createContext(null);
export const SelectDataSourceRevisionContext = React.createContext(null);

