import { useState } from "react";
import { useModelsApi } from "../../hooks/useModel";
import { ModalFrame } from "../modal-dialog/ModalFrame";

export const MODEL_TYPES = [
    { id: "js", label: "Javascript Function" },
    // { id: "accum", label: "JS Accumulator" },
    // { id: "stage", label: "JS Stage" },
    //{ id: "dcm", label: "DCM model" },
]

export const NewModel = (props) => {

    const [newName, setNewName] = useState("");
    const [newType, setNewType] = useState(MODEL_TYPES[0].id);

    const api = useModelsApi();

    const createItem = async (item) => {
        return await api.newItem(item);
    }

    const cBoxStyle = {
        display: "inline-flex",
        alignItems: "center",
        width: 150,
        height: 60,
        fontSize: "9pt",
        border: "1px solid #cfcfcf",
        margin: "0 10px 10px 0",
        padding: 10,
    }

    const cBoxStyleSelected = {
        ...cBoxStyle,
        border: "1px solid #003264",
        backgroundColor: "#efefef"
    }

    return <ModalFrame close={props.close}
        title="Create new model"
        buttons={[
            {
                label: "Create",
                disabled: !newName,
                callback: async () => {
                    const item = {
                        label: newName,
                        type: newType
                    }
                    setNewName("");
                    const itemInfo = await createItem(item);
                    props?.onNew(itemInfo);
                    props.close();
                }
            },
        ]}
    >
        <div className='form1' style={{ maxWidth: 500 }}>
            <label className="label-line">
                Label:
            </label>
            <div className="value-line">
                <input type="text"
                    autoFocus className='full-width' 
                    placeholder="Enter label for new model"
                    value={newName}
                    onChange={(ev) => {
                        setNewName(ev.target.value)
                    }}/>
            </div>

            <div className="label-line">
                <br/>
            </div>
            <div className="value-line">
                {MODEL_TYPES.map(conn => 
                    <span key={conn.id} 
                        style={conn.id === newType ? cBoxStyleSelected : cBoxStyle}
                        onClick={() => setNewType(conn.id)}
                    >
                    {conn.label}
                    </span>
                )}
            </div>
            
        </div>

    </ModalFrame>

    
}


