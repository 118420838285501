import { useRef } from "react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useRecoilValue } from "recoil";
import { useCollection } from "../../hooks/useCollection";
import { useSelection, useSelectionState } from "../../hooks/useSelection";
import { EditContentContext } from "../content/EditContentContext";
import { computeContextState, ComputeRunnerContext } from "./ComputeContext";
import "./DropDown.scss";


export const DropDown = (props) => {
    const item = props.item;

    const ec = useContext(EditContentContext);
    const ref1 = useRef();

    const collection_id = item?.jdata?.collection;
    const [collection, collectionLoading, collectionError] = useCollection(collection_id);

    const sel_id = item?.jdata?.selection;
    const [sel, selLoading, selError] = useSelection(sel_id);
    const [state, stateLoading, stateError] = useSelectionState(sel_id);

    const format = item?.jdata?.format;

    const dispatch = useDispatch();

    // console.log("sel", sel);
    // console.log("state", state);

    const setValue = (x) => {
        console.log("dd setValue", x);
        dispatch({
            type: "SELECTION_STATE_SET",
            id: sel_id,
            value: x
        })
    }

    const style = { ...props.style, ...item?.style }
    return <div className={`mstudio-dropdown ${props.displayClass}`} style={style} {...props.atts}
    >
        {format === "buttons" ?
            <div>
                {collection?.entries?.map(entry =>
                    <button 
                        key={entry.id} 
                        className={`${state === entry.id ? 'sel' : ''}`}
                        onClick={ev => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            if( ec.mode === "edit" && (ev.altKey)){
                                ec.setSelectionId(item?.id);
                            }
                            else{
                                setValue(entry.id)
                            }
                        }}
                    >
                        {entry.label}
                    </button>
                )}
            </div>
        :
        <select value={state} ref={ref1}
            onChange={ev => setValue(ev.target.value)}
            onMouseDown={ev => {
                if( ec.mode === "edit" && (ev.altKey)){
                    ev.preventDefault();
                ev.stopPropagation();
                }
            }}
            onClick={ev => {
                ev.preventDefault();
                ev.stopPropagation();
                if( ec.mode === "edit" && (ev.altKey)){
                    ec.setSelectionId(item?.id);
                    ref1.current.blur();
                }
            }}
        >
            {!collection?.entries && 
                <option>
                    {!collection_id ? "Specify collection" :
                        selLoading ? "Loading..." :
                        selError ? "Error" :
                        "Missing entries"
                    }
                </option>
            }
            {collection?.entries?.map(entry =>
                <option key={entry.id} value={entry.id}>
                    {entry.label}
                </option>
            )}
        </select>
        }
    </div>
};

