import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../hooks/useAuth";
import { useProjectId } from "../hooks/useProjectId";
import { useSubscriptionId } from "../hooks/useSubscriptionId";
import { genericParseEntry } from "../store/CreateGenericReducer";
import { controllers } from "./controllers";

/**
 * Creates and API hook that uses dispatch to keep redux store in sync
 * @param {*} construct 
 * @returns redux-synced hook
 */
export const createSyncedApiHook = (construct) => () => {

    const { subscription_id, subscription_socket_id } = useSubscriptionId();
    const { project_id, project_socket_id } = useProjectId();
    const socket_id = (construct.isSubscription || construct.isProject) ? subscription_socket_id : project_socket_id;

    const dispatch = useDispatch();
    const auth = useAuth();

    const name = construct.name;
    const namePlural = construct.namePlural || name + "s";
    const parseEntry = construct.parseEntry || genericParseEntry;
    const actionName = construct.actionName || name.toUpperCase();
    const actionNamePlural = construct.actionNamePlural || actionName + "S";

    const controller = controllers[construct.name];

    return {

        newItem: async (value, parent_id, keys) => {
            console.log("newItem", value, parent_id, keys);
            const newInfo = await controller.auth_createItem(auth, { subscription_id, project_id, socket_id, ...keys }, value, parent_id);
            const newId = newInfo.newId;
            const newValue = {
                ...value,
                id: newId,
                project_id // why is this needed!? i don't want it to be needed
            }

            // mini exception for filename?
            if( newInfo.uploadInfo ){
                newValue.filename = newInfo.uploadInfo.filename
            }

            const action = {
                type: `${actionNamePlural}_ADD_VALUE`,
                id: newId,
                parent_id,
                entry: parseEntry(newValue),
                value: newValue
            }
            
            if( newValue.filename ){
                // another exception for filename -- delay the dispatch so caller has time to upload file
                return {
                    ...newInfo,
                    dispatch: () => dispatch(action) // return a callback to complete the dispatch
                }
            }
            else{
                dispatch(action);
                return newInfo;
            }
            
        },

        updateItem: async (id, value, parentIds) => {
            console.log('updateItem', id, value, parentIds);
            await controller.auth_replaceItem(auth, { subscription_id, project_id, socket_id, ...parentIds }, id, value);
            const newValue = {
                ...value,
                id,
                
                project_id // why is project_id needed?? i don't want it to be needed
            }
            dispatch({
                type: `${actionName}_SET`,
                id,
                ...parentIds,
                entry: parseEntry(newValue),
                value: newValue
            });
        },

        patchItem: async (id, value, parentIds) => {
            console.log('patchItem', id, value, parentIds);
            const res = await controller.auth_patchItem(auth, { subscription_id, project_id, socket_id, ...parentIds }, id, value);
            if( res ){
                const newValue = {
                    ...value,
                    //id,
                    //project_id // why is project_id needed?? i don't want it to be needed
                }
                dispatch({
                    type: `${actionName}_PATCH`,
                    id,
                    ...parentIds,
                    //entry: parseEntry(newValue), // if we do this, we should wrap in a 
                    // function that removes undefined keys; otherwise id: undefined would delete existing id
                    value: newValue
                });
            }
        },

        moveItem: async (id, dir, parentIds) => {
            console.log('moveItem', id, dir, parentIds);
            const res = await controller.auth_moveItem(auth, { subscription_id, project_id, socket_id, ...parentIds }, id, dir);
            if( res ){
                // const newValue = {
                //     ...value,
                //     //id,
                //     //project_id // why is project_id needed?? i don't want it to be needed
                // }
                // dispatch({
                //     type: `${actionName}_PATCH`,
                //     id,
                //     ...parentIds,
                //     //entry: parseEntry(newValue), // if we do this, we should wrap in a 
                //     // function that removes undefined keys; otherwise id: undefined would delete existing id
                //     value: newValue
                // });
            }
        },

        deleteItem:
            construct.hasFilename ?
                async (id, filename) => {
                    // passing the filename into deleteItem ensures that the attached blob is deleted
                    // wait, shouldn't the API do this? yes. this is stupid here.
                    await controller.auth_deleteItem(auth, { subscription_id, project_id, socket_id, filename }, id);
                    dispatch({ type: `${actionName}_DELETE`, id });
                }
                :
                async (id, keys) => {
                    await controller.auth_deleteItem(auth, { subscription_id, project_id, socket_id, ...keys }, id);
                    dispatch({ type: `${actionName}_DELETE`, id });
                }

      
    }

};