import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import Popup from "reactjs-popup";
import { ModalFrame } from "../modal-dialog/ModalFrame";
import "./ColorPickerInput.scss";


export const ColorPickerInput = (props) => {

    const [color, setColor] = useState(props.value);

    useEffect(() => {
        setColor(props.value);
    }, [props.value])

    const onChange = (newColor) => {
        props.onChange?.(newColor);
    }

    return <div className='color-picker flex-vcenter'>
        <Popup
            position="bottom left"
            keepTooltipInside
            closeOnEscape
            closeOnDocumentClick
            trigger={
            
                <span className='circular-swatch'>
                    <span
                        className={`fill ${color ? "" : "cancel"}`}
                        style={{
                            backgroundColor: color || "transparent"
                        }}
                    />
                </span>
            }
        >
            {closeModal =>
                <ModalFrame close={closeModal}
                    title="Select Color"
                    style={{ width: 300, textAlign: "center" }}
                >
                    
                    <SketchPicker
                        color={color}
                        onChangeComplete={(newColor) => {
                            //console.log('newColor', newColor);
                            setColor(newColor.hex);
                            onChange(newColor.hex);
                        }} />
                </ModalFrame>
            }
        </Popup>
        <span style={{ marginLeft: 10 }}>
            {color || "(Not set)"}
        </span>
    </div>;
    
    
}