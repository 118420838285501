export const getDataSourceIconClass = (ds) => (
    !ds ? "fs-circle-question" :
    ds.type === "flow" ? "fa-random" :
    ds.structured ? "fa-database" :
    ds.filetype === "json" ? "fa-brackets-curly" :
    "fa-code"
);


export const getReportIcon = (r) => 
    r.type === "spreadsheet" ? <i className={`fas fa-table`} style={{ color: "#207446" }}/> :
    r.type === "slides" ? <i className={`fas fa-presentation-screen`} style={{ color: "#e55a0e" }}/> :
    <i className={'fas fa-book'} style={{ color: "#42b4ff" }}/>

export const getReportIconClass = (r) =>
    r.type === "spreadsheet" ? "fa-sharp fa-solid fa-file-spreadsheet" :
    r.type === "slides" ? "fa-file-powerpoint" :
    r.type === "notebook" ? "fa-file-lines" :
    "fa-file-contract";