import { useContext } from "react";
import { DataSourceContext, EditDataSourceContext, HasDataSourcesContext, HasPipelineContext } from "../../context/Contexts";
import { useDataSource } from "../../hooks/useDataSource";
import { DataSourceSelector } from "../data-sources/DataSourceSelector";
import { DataSourceRevisionSelector } from "../data-sources/DataSourceRevisionSelector";
import "./EditPipeline.scss";
import { EditPipelineStages } from "./EditPipelineStages";

export const EditPipeline = (props) => {

    const { pipeline, setPipeline } = useContext(HasPipelineContext);

    const [ds, dsLoading, dsError] = useDataSource(pipeline?.dataSourceId);

    const dsContextCheck = useContext(HasDataSourcesContext);
    const edsContextCheck = useContext(EditDataSourceContext);

    return <div className={`edit-pipeline ${props.noOutput ? "no-output" : ""}`}>
        
        {dsContextCheck &&
            <div className="select-dataset">
                <DataSourceSelector 
                    id={pipeline?.dataSourceId} 
                    onChange={(newId) => setPipeline({ ...pipeline, dataSourceId: newId})}
                    width={"100%"}
                />
            </div>
        }

        {edsContextCheck &&
            <div className="select-revision">
                <DataSourceRevisionSelector
                    width={"100%"}
                />
            </div>
        }

        <div className="edit-pstages">
            {dsError && <span className="message error">{dsError}</span>}
            {dsLoading && <span className="message">loading data source...</span>}
            {ds && 
                <DataSourceContext.Provider value={ds}>
                    <EditPipelineStages showRevisionId={props.showRevisionId} />
                </DataSourceContext.Provider>
            }
        </div>
        
    </div>

}