import { useContext, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import Popup from "reactjs-popup";
import { ControlledCodeEditorFillContainer } from "../../code-editor/CodeEditor";
import { ModalFrame } from "../../modal-dialog/ModalFrame";
import "./EditCRSyntax.scss";
import { ContentItemContext } from "../../../context/Contexts";
import { isObj, runCompute, runRender, transpileJs } from "../../../utils/ContentProcessor";
import { defaultLib } from "../../../utils/Lib";

const CODE_TABS = [
    { key: "compute", label: "Compute(old)" },
    { key: "render", label: "Render" },
    { key: "renderTable", label: "TableSpec" },
]

const HELPER_TABS = [
    { key: "computed", label: "(Compute) Result" },
    { key: "context", label: "Context" },
    { key: "resources", label: "Resources" },
    { key: "api", label: "API" },
    { key: "lib", label: "Library" },
]

const defaultApiHack = {
}

// const getDefaultLibHack = () => Object.keys(defaultLib).reduce(
//     (accum, key) => {
//         accum[key] = "(...)";
//         return accum;
//     },
//     {}
// );

export const truncateObjectAtLevel = (obj, level = 0) => Object.keys(obj).reduce(
    (accum, key) => {
        accum[key] = level > 0 ? truncateObjectAtLevel(obj[key], level - 1) : "(...)";
        return accum;
    },
    {}
);


export const EditCRSyntaxBox = (props) => {

    const [compute, setCompute] = useState(props.compute);
    const [render, setRender] = useState(props.render);
    const [renderTable, setRenderTable] = useState(props.renderTable);

    const [selCodeTab, setSelCodeTab] = useState("compute");
    const [selHelperTab, setSelHelperTab] = useState("computed");
    const [testResult, setTestResult] = useState("(not run yet)");
    const [testRunning, setTestRunning] = useState(false);

    const context = useContext(ContentItemContext);
    
    useEffect(() => {
        setCompute(props.compute);
    }, [props.compute])

    useEffect(() => {
        setRender(props.render);
    }, [props.render])

    useEffect(() => {
        setRenderTable(props.renderTable);
    }, [props.renderTable])


    const test = async () => {
        try {
            setTestRunning(true);
            const api = {};
            const lib = defaultLib;
            const r = await runCompute(compute, context, props.resources, api, lib)
            const r2 = runRender(renderTable, r, context, props.resources, api, lib, false);
            setTestResult({
                result: r,
                tableSpec: r2
            });

            setTestRunning(false);
        }
        catch (err) {
            setTestResult({ error: err.message });
            setTestRunning(false);
        }
    }

    return <ModalFrame
        title={props.title || "Syntax"}
        caption={props.caption}
        close={props.close}
        style={{ width: 1200, height: 600 }}
        contentStyle={{ padding: 0 }}
        buttons={[
            {
                label: "Save Changes",
                callback: () => {
                    props.onChange?.({
                        compute,
                        render,
                        renderTable
                    });
                }
            }
        ]}
    >
        <div style={{ width: "100%", height: "100%" }} className="edit-cr-syntax full-dock">

            <div className="right full-dock" style={{ width: 450 }}>

                <div className="top tab-section">
                    {HELPER_TABS.map(tab =>
                        <div
                            key={tab.key}
                            className={`tab ${selHelperTab === tab.key ? "sel" : ""}`}
                            onClick={() => setSelHelperTab(tab.key)}
                        >
                            {tab.label}
                        </div>
                    )}
                </div>
                <div className="center" 
                    style={{
                    padding: "10px 20px",
                    width: "100%",
                    height: "435px",
                    overflowY: "auto",
                    //position: "relative",
                    //border: '2px dashed purple'
                }}>
                    {selHelperTab === "context" ? 
                        <ReactJson src={context}
                            name={"context"}
                            theme={"rjv-default"}
                            iconStyle="circle"
                            displayDataTypes={false}
                            enableClipboard={true}
                            collapsed={1}
                        />
                    : selHelperTab === "resources" ? 
                        <ReactJson src={props.resources}
                            name={"resources"}
                            theme={"rjv-default"}
                            iconStyle="circle"
                            displayDataTypes={false}
                            enableClipboard={true}
                            collapsed={1}
                            />
                    : selHelperTab === "api" ? 
                        <ReactJson src={props.api || defaultApiHack}
                            name={"api"}
                            theme={"rjv-default"}
                            iconStyle="circle"
                            displayDataTypes={false}
                            enableClipboard={true}
                            collapsed={1}
                        />
                    : selHelperTab === "lib" ?
                        <ReactJson
                            src={truncateObjectAtLevel(defaultLib, 2)}
                            name={"lib"}
                            theme={"rjv-default"}
                            iconStyle="circle"
                            displayDataTypes={false}
                            enableClipboard={true}
                            />
                    : selHelperTab === "computed" ? 
                        <div style={{
                            //border: "2px solid red",
                        }}>
                            <div style={{ marginBottom: 10 }}>
                                <span className="action btn" onClick={test}>Test</span>
                            </div>
                            <div >
                                {testRunning ? "Running test..." : null}
                                {testResult && (isObj(testResult) || Array.isArray(testResult)) ?
                                    <ReactJson
                                        src={testResult}
                                        name={null}
                                        collapsed={2}
                                        theme={"rjv-default"}
                                        iconStyle="circle"
                                        displayDataTypes={false}
                                        enableClipboard={true}
                                        />
                                    : testResult === null ? "result: null"
                                    : testResult === undefined ? "result: undefined"
                                    : "result: " + testResult}
                            </div>
                        </div>
                    : null
                    }
                </div>
            </div>
            <div className="center" style={{ 
                borderRight: "1px solid #cfcfcf", 
                display: "grid",
                gridTemplateRows: "min-content min-content 1fr"
            }}>

                <div className="tab-section">
                    {CODE_TABS.map(tab =>
                        <div
                            key={tab.key}
                            className={`tab ${selCodeTab === tab.key ? "sel" : ""}`}
                            onClick={() => setSelCodeTab(tab.key)}
                        >
                            {tab.label}
                        </div>
                    )}
                </div>

                <div style={{ padding: "0px 20px 10px 20px"}}>
                    {selCodeTab === "compute" ? <div>
                        const compute = async (context, resources, api, lib) =&gt; {"{ ... }"}
                    </div> : null}
                    {selCodeTab === "render" ? <div>
                        const render = (result, context, resources, api, lib) =&gt; {"{ ... }"}
                    </div> : null}
                    {selCodeTab === "renderTable" ? <div>
                        const renderTable = (result, context, resources, api, lib) =&gt; {"{ ... }"}
                    </div> : null}
                </div>

                <div style={{ 
                }}>
                {selCodeTab === "compute" ? 
                    <ControlledCodeEditorFillContainer
                        //theme="vs-dark"
                        value={compute}
                        onChange={async (newSyntax) => {
                            setCompute(newSyntax);
                            // note: don't notify consumer until 'save' is clicked
                        }}
                    /> :
                    selCodeTab === "render" ? 
                    <ControlledCodeEditorFillContainer
                        value={render}
                        onChange={async (newSyntax) => {
                            setRender(newSyntax)
                            // note: don't notify consumer until 'save' is clicked
                        }}
                    /> :
                    selCodeTab === "renderTable" ? 
                    <ControlledCodeEditorFillContainer
                        value={renderTable}
                        onChange={async (newSyntax) => {
                            setRenderTable(newSyntax)
                            // note: don't notify consumer until 'save' is clicked
                        }}
                    /> :
                    null
                }
                </div>

            </div>
        </div>
    </ModalFrame>


}