import { DiffEditor } from "@monaco-editor/react";
import { useEffect, useState } from "react";
import { computeApi } from "../../api/computeApi";
import { DataSourceContext, HasPipelineContext } from "../../context/Contexts";
import { useAuth } from "../../hooks/useAuth";
import { useProjectId } from "../../hooks/useProjectId";
import { DataTableDiff } from "../common/DataTableDiff";
import { EditPipelineStages } from "../pipeline/EditPipelineStages";

import "./CalcDiffTool.scss";

const OUTPUT_TABS = [
    { id: "json-diff", label: "JSON Diff" },
    { id: "table", label: "Table" },
]

export const CalcDiffTool = (props) => {
    

    const { subscription_id, project_id } = useProjectId();
    const auth = useAuth();
    const [results, setResults] = useState(null);

    const { ds1, ds2, rev1, rev2 } = props;

    const [pipeline1, setPipeline1] = useState(null);
    const [pipeline2, setPipeline2] = useState(null);
    const [useSamePipeline, setUseSamePipeline] = useState(true);

    const [computing, setComputing] = useState(false);

    const [selectedTab, setSelectedTab] = useState(OUTPUT_TABS[0].id);

    const p1Context = {
        pipeline: pipeline1,
        setPipeline: setPipeline1
    }

    const p2Context = {
        pipeline: pipeline2,
        setPipeline: setPipeline2
    }


    // compute stuff
    useEffect(() => {

        if( !ds1 || !ds2 || !rev1 || !rev2 ) return;

        console.log("asdf");

        (async () => {

            setComputing(true);

            const p1 = { ...pipeline1, dataSourceId: ds1.id, revisionId: rev1.id, id: "p1" };
            const p2 = { ...(useSamePipeline ? pipeline1 : pipeline2), dataSourceId: ds2.id, revisionId: rev2.id, id: "p2" };
            const pipelines = [p1, p2];

            let allResults = {};
            for( let pipeline of pipelines ){
                console.log("running pipeline", pipeline);
                const enabled_stages = (pipeline?.stages || [])
                    ?.filter(s => !s.disabled)
                    ?.map(s => s.data);
                try {
                    // const res = await computeApi.compute(
                    //     auth, 
                    //     subscription_id,
                    //     project_id, 
                    //     pipeline.dataSourceId,
                    //     pipeline.revisionId,
                    //     enabled_stages,
                    //     {
                    //         limit: 1000 // cursor limit
                    //     });
                    const env = {};
                    const res = await computeApi.computeV2(
                        auth,
                        env,
                        subscription_id,
                        project_id,
                        pipeline.dataSourceId,
                        pipeline.revisionId,
                        enabled_stages,
                        {
                            limit: 1000 // cursor limit
                        }
                    )
                    
                    allResults[pipeline.id] = res;
                }
                catch (err) {
                    allResults[pipeline.id] = { error: err.message };
                }
            }
            
            setResults(allResults);
            setComputing(false);



        })();

    }, [ds1?.id, ds2?.id, rev1?.id, rev2?.id, pipeline1, pipeline2]);
    
    const str1 = JSON.stringify(results?.p1, null, 4);
    const str2 = JSON.stringify(results?.p2, null, 4);

    const rows1 = results?.p1?.data;
    const rows2 = results?.p2?.data;
    const smartObj = true;


    return <div className="calc-diff-tool">

        <div className="settings">
            <span className={`btn toggle ${useSamePipeline ? "sel" : ""}`}
                onClick={() => {
                    if( !useSamePipeline ){
                        setPipeline2(pipeline1);
                    }
                    setUseSamePipeline(!useSamePipeline);

                }}
            >
                useSamePipeline
            </span>
        </div>

        <div className="input1">
            <DataSourceContext.Provider value={ds1}>
                <HasPipelineContext.Provider value={p1Context}>
                    <EditPipelineStages revisionId={rev1.id} />
                </HasPipelineContext.Provider>
            </DataSourceContext.Provider>
        </div>

        {!useSamePipeline && <div className="input2">
            <DataSourceContext.Provider value={ds2}>
                <HasPipelineContext.Provider value={p2Context}>
                    <EditPipelineStages revisionId={rev2.id} />
                </HasPipelineContext.Provider>
            </DataSourceContext.Provider>
        </div>}


        {computing && <div className="calc-note">
            Computing...
        </div>}

        <div className="output">
            <div className="header tab-section no-margin">
                {OUTPUT_TABS.map(tab =>
                    <div key={tab.id} className={`tab ${selectedTab === tab.id ? "sel" : ""}`} onClick={() => setSelectedTab(tab.id)}>
                        {tab.label}
                    </div>
                )}
            </div>
            <div className="content">
                {selectedTab === "json-diff" ? <DiffEditor
                    height="100%"
                    defaultLanguage="javascript"
                    original={str1}
                    modified={str2}
                />
                : selectedTab === "table" ? <DataTableDiff data1={rows1} data2={rows2} autoGenerateColumns smartObj={smartObj} />
                : null}
                
                
            </div>
        </div>


    </div>;

}