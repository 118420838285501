import React, { useEffect, useState } from "react";
//import SplitterLayout from "react-splitter-layout";
import SplitterLayout from "../extlib/react-splitter-layout/SplitterLayout";
import { useModel, useModelsApi } from "../../hooks/useModel";
import { useProjectId } from "../../hooks/useProjectId";
import { useResources } from "../../hooks/useResources";
import { reduceResources } from "../../hooks/helper/reduceResources";
import { compileFunction } from "../../utils/Compiler";
import { ControlledCodeEditorFillContainer } from "../code-editor/CodeEditor";
import { EditResources } from "../page-content/edit/EditResources";
import "./EditJsModel.scss";


export const EditJsModel = (props) => {

    const { subscription_id, project_id } = useProjectId();

    const id = props.id;
    const [model, modelLoading, modelError] = useModel(id);
    const api = useModelsApi();

    const [showTest, setShowTest] = useState(false);

    const [syntax, setSyntax] = useState(null);
    
    
    const [testResourceList, setTestResourceList] = useState(null);
    const [testResult, setTestResult] = useState(null);

    const [testSyntax, setTestSyntax] = useState(null);

    const [resources, resourcesLoading, resourcesError] = useResources(testResourceList, true);
    
    useEffect(() => {
        
        const jdata = model?.jdata;
        if( !jdata ) return;

        setSyntax(jdata.syntax);
        setTestSyntax(jdata.testSyntax);
        setTestResourceList(jdata.testResources);

    }, [model])


    const save = async () => {
        const jdata = {
            syntax: syntax,
            testSyntax: testSyntax,
            testResources: testResourceList
        };
        await api.patchItem(id, { jdata });
    }

    const run = async () => {

        try{
            const rr = resources ? reduceResources(resources, subscription_id, project_id, null, null) : null;

            // compile test function
            const fTestSyntax = `(resources, model) => {
                ${testSyntax}
            }
            `;
            const fTest = compileFunction(fTestSyntax)

            // compile model
            const fSyntax = syntax;
            const f = compileFunction(fSyntax);

            // run the test func, passing in resources and model as function
            const res = fTest?.(rr, f);
            setTestResult(JSON.stringify(res, null, 4));
            //console.log("result:", result);
        }
        catch( err ){
            console.error(err.message);            
        }

    }

    return <div className='edit-js-model full-dock'>
        <div className="top tab-section no-marginx">
            <span className="help-link" 
                onClick={() => setShowTest(!showTest)}
            >
                <i className="far fa-flask"/>
            </span>
            <span style={{ marginLeft: 5 }}>
                <span className="btn action"
                    onClick={() => save()}
                >
                    Save
                </span>
            </span>
        </div>
        <div className="center main-center full-dock fill-parent-absolute">
            <div className="top">
                {showTest && 
                    <div className="full-dock">
                        <div className="top">
                            Test code: {"(resources, model) => { [your test code] }"}
                            <div>
                                example: return await model(...); // model might be async or sync
                            </div>
                        </div>
                        <div className="right" style={{ width: 400 }}>
                            <EditResources value={testResourceList} onChange={async (newList) => setTestResourceList(newList)} />
                        </div>
                        <div className="center">
                            <ControlledCodeEditorFillContainer value={testSyntax} onChange={(newCode) => setTestSyntax(newCode)} style={{height: 250}} />
                        </div>
                    </div>
                }
            </div>

            {model && 
                <div className="center fill-parent-absolute code-section1" key={model.id}>
                    <SplitterLayout primaryIndex={1} secondaryMinSize={300}>
                        <div className="code-container full-dock">
                            <div className="top" style={{ 
                                        borderBottom: "1px solid #cfcfcf",
                                        paddingBottom: "5px"
                                    }}>
                                <span className="btn toggle-small" onClick={() => run()}>
                                    <i className="fas fa-play" />&nbsp;
                                    Run
                                </span>
                            </div>
                            <div className="center">
                                <ControlledCodeEditorFillContainer key={id} value={syntax} onChange={(newCode) => setSyntax(newCode)} />
                            </div>
                        </div>
                        <div className="code-container">
                            <ControlledCodeEditorFillContainer language="json" value={testResult} onChange={(newCode) => setTestResult(newCode)} />
                        </div>
                    </SplitterLayout>
                </div>
            }

        </div>
    </div>
    
}