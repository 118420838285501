import { useContext } from "react";
import { Area, AreaChart, Bar, CartesianGrid, ComposedChart, Legend, Line, LineChart, ResponsiveContainer, Scatter, Tooltip, XAxis, YAxis } from "recharts";
import { useRecoilState, useRecoilValue } from "recoil";
import { DisplayContext, getDisplayClass } from "../content/DisplayContext";
import { EditContentContext } from "../content/EditContentContext";
import "./ArbitraryContent.scss";
import { computeContextState, computeRunnerForScopeState } from "./ComputeContext";

const strs = [
    "Lorem ipsum",
    "dolor sit amet",
    "consectetur adipiscing elit.",
    "Donec tincidunt arcu nec",
    "tortor dictum ultricies",
    "Nunc vehicula",
    "nisi in interdum",
    "scelerisque",
    "tortor eros molestie tellus",
    "pretium"
];



export const ArbitraryWords = (props) => {
    const item = props.item;
    const style = { ...props.style, ...item?.style }
    
    let str = item?.jdata?.words;
    if( !str ){
        const i = Math.round(Math.random() * 100) % strs.length;
        str = strs[i];
    }
    return <div className={`arbitrary-content ${props.displayClass}`} style={style} {...props.atts}>{str}</div>;
}

export const ArbitraryParagraph = (props) => {
    const item = props.item;
    const style = { ...props.style, ...item?.style }
    const str = "Ornare orci turpis a metus. Nunc at nunc commodo, varius massa non, commodo lorem. Nulla massa eros, imperdiet id odio ut, euismod dignissim dolor. Cras ac purus dolor. Nulla facilisi. Suspendisse at dignissim tellus. Nullam non risus quis leo ultrices venenatis ut id eros. Nullam eleifend quis tortor at luctus. Nullam mattis est sagittis laoreet gravida. Curabitur in rhoncus odio. Integer eget orci posuere, viverra enim et, venenatis sem. Vivamus eget velit lobortis, pulvinar erat id, vestibulum est. Aliquam erat volutpat. Donec ultricies vel elit sit amet condimentum. Proin felis est, molestie in sapien nec, ultricies consequat dolor."
    return <div className={`arbitrary-content v2 ${props.displayClass}`} style={style} {...props.atts}>{str}</div>;
};

export const ArbitraryParagraphs = (props) => {
    const item = props.item;
    const style = { ...props.style, ...item?.style }
    const str = <>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eget ante ac ex sodales mollis. Proin vestibulum fringilla nisi et commodo. Cras et felis tempus, luctus leo ut, aliquet tortor. Aenean vel iaculis ipsum, eget euismod velit. Aliquam nec varius magna. Etiam pretium nunc neque, quis consectetur nisi porta et. Morbi nec enim mattis leo faucibus cursus vitae eget neque. Fusce quam ligula, imperdiet fermentum aliquam et, fringilla ac eros.</p>
        <p>Nulla sed velit quis diam interdum vulputate. Nam convallis ullamcorper finibus. Nulla vestibulum eros id nibh laoreet, vehicula tincidunt nibh mattis. Phasellus euismod urna eget scelerisque aliquam. Aliquam consectetur lectus nisi, et rhoncus sapien sollicitudin id. Donec consequat, ex ac varius sollicitudin, sapien turpis sollicitudin magna, eu placerat tellus augue et arcu. Phasellus nec ultrices velit. Ut aliquet ex eu vehicula vulputate.</p>
        <p>Mauris viverra tristique porta. Vestibulum porta scelerisque turpis, eu maximus orci scelerisque non. In hac habitasse platea dictumst. Nulla efficitur mauris ac sem blandit, ac commodo urna varius. Nulla fringilla porta magna, et placerat ante porta ut. Mauris urna dui, ornare at fringilla sit amet, pellentesque in neque. Aenean sit amet turpis fringilla, vulputate turpis at, elementum velit. Integer molestie vehicula turpis, sit amet tincidunt felis aliquam ac.</p>
        <p>Nunc risus lectus, maximus in purus non, lobortis dapibus quam. Pellentesque iaculis quam vel orci facilisis egestas. Aliquam erat volutpat. Suspendisse molestie velit diam, ac molestie urna dapibus et. Integer sagittis, diam id tempor congue, metus nibh blandit est, ut tincidunt nibh sem in augue. Morbi nulla sem, tempor et tortor non, scelerisque vehicula leo. Nulla pharetra scelerisque mi. Ut quam ex, consectetur eu posuere vel, dictum vitae felis. Donec laoreet posuere pretium. Vivamus et orci ut sapien convallis suscipit. Sed facilisis lorem id arcu interdum, nec pretium leo venenatis.</p>
        <p>Ut vel laoreet diam. Cras pellentesque erat eu sem ornare pellentesque. Sed mollis tellus ac diam hendrerit interdum. Nullam nec efficitur odio, nec interdum urna. Etiam non ante consectetur, interdum risus non, egestas justo. Sed tortor orci, sagittis ut aliquet non, semper id sem. Integer vulputate sem in imperdiet vestibulum. Donec laoreet urna tellus, ac lobortis ante pharetra eu. Duis porttitor mi et ligula ullamcorper elementum. Praesent sit amet metus nisi. Integer malesuada convallis justo, a tempor nisl. Mauris vulputate vel risus sed tristique. Donec enim velit, vulputate sed dictum at, sagittis at orci. Vestibulum eu mattis lorem. Integer ultrices viverra felis, at egestas tortor imperdiet sit amet.`;</p>
    </>;
    return <div className={`arbitrary-content v3 ${props.displayClass}`} style={style} {...props.atts}>{str}</div>;
};

export const ArbitraryButton1 = (props) => {
    const item = props.item;

    // get data from hook
    const ec = useContext(EditContentContext);
    

    const style = { ...props.style, ...item?.style }
    return <div className={`arbitrary-button ${props.displayClass}`} style={style} {...props.atts}
        onMouseDown={(ev) => {
            if( ev.which === 3){
                ev.preventDefault();
            }
        }}
        onClick={(ev) => {
            if( ec.mode === "edit" && (ev.altKey)){
                ec.setSelectionId(item?.id);
            }
            ev.stopPropagation();
            ev.preventDefault();
        }}
    >
        {item?.label || "Button"}
    </div>
};

export const ArbitraryMenu = (props) => {
    const item = props.item;
    const style = { ...props.style, ...item?.style }
    return <div className={`arbitrary-menu ${props.displayClass}`} style={style} {...props.atts}>
        {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25].map(i => 
            <div key={i} className={`item`}>
                Menu Item {i}
            </div>
        )}
    </div>
};

export const ArbitraryLineChart1 = (props) => {

    const item = props.item;
    const style = { margin: 0, ...props.style, ...item?.style }
    const display = props.display;
    const displayClass = props.displayClass;
    const jdata = item.jdata;
    
    const width = (display === "inline-block" ? 400 : "100%");
    const height = (display === "block-fill" ? "100%" : 300);

    return <div className={`arbitrary-chart ${displayClass}`} style={style} {...props.atts}>
        <ResponsiveContainer width={width} height={height}>
            <LineChart
                data={data1}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 20,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
        </ResponsiveContainer>
    </div>
};

export const ArbitraryAreaChart1 = (props) => {

    const item = props.item;
    const style = { margin: 0, ...props.style, ...item?.style }
    const display = props.display;
    const displayClass = props.displayClass;
    const jdata = item.jdata;

    const width = (display === "inline-block" ? 400 : "100%");
    const height = (display === "block-fill" ? "100%" : 300);

    return <div className={`arbitrary-chart ${displayClass}`} style={style} {...props.atts}>
        <ResponsiveContainer width={width} height={height}>
            <AreaChart
                width={500}
                height={400}
                data={data1}
                margin={{
                    top: 20,
                    right: 30,
                    left: 0,
                    bottom: 20,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
                <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" />
            </AreaChart>
        </ResponsiveContainer>
    </div>
};

export const ArbitraryComposedChart1 = (props) => {

    const item = props.item;
    const style = { ...props.style, ...item?.style }
    const display = props.display;
    const displayClass = props.displayClass;
    const jdata = item.jdata;
    
    const width =  (display === "inline-block" ? 400 : "100%");
    const height =  (display === "block-fill" ? "100%" : 300);

    return <div className={`arbitrary-chart ${displayClass}`} style={style} {...props.atts}>
        <ResponsiveContainer width={width} height={height}>
            <ComposedChart
                
                data={data1}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
                >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="name" scale="band" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" />
                <Bar dataKey="pv" barSize={20} fill="#413ea0" />
                <Line type="monotone" dataKey="uv" stroke="#ff7300" />
                <Scatter dataKey="cnt" fill="red" />
            </ComposedChart>
        </ResponsiveContainer>
    </div>
};

export const JsonViewer = (props) => {

    const item = props.item;
    const style = { margin: 0, ...props.style, ...item?.style }
    const display = props.display;
    const displayClass = props.displayClass;
    const jdata = item.jdata;
    
    const width = (display === "inline-block" ? 400 : "100%");
    const height = (display === "block-fill" ? "100%" : 300);

    // get data from hook
    const cHook = item?.jdata?.contextHooks?.[0];
    const ccState = useRecoilValue(computeContextState(cHook?.id));
    const data = cHook?.id ? ccState : { "note": "no compute context"};

    return <div className={`arbitrary-chart ${displayClass}`} style={style} {...props.atts}>
        <pre>
            {JSON.stringify(data, null, 4)}
        </pre>
    </div>
};

const data1 = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];