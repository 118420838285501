import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { controllers } from "../api/controllers";
import { useCollection } from "./useCollection";
import { useProjectId } from "./useProjectId";
import JSON5 from "json5";
import { useAuth } from "./useAuth";
import { ROOT_ID } from "./helper/ContentItemHelper";



export const useContentItem = (id) => {

    const {project_id, subscription_id} = useProjectId();
    const dispatch = useDispatch();
    const controller = controllers["content-item"];
    const auth = useAuth();

    // note: should fail if id == null
    // if( id === undefined )

    const { contentItem, loading, error } = useSelector(state => ({
        contentItem: id === ROOT_ID ? state.contentItems.dict?.[state.contentItems.entries?.[0]?.id] : state.contentItems.dict?.[id],
        loading: state.contentItems.dict?.[id]?.loading,
        error: state.contentItems.dict?.[id]?.error,
    }), shallowEqual);

    const load = async (setLoading) => {
        console.log("LOAD!", id);
        if( setLoading ){
            dispatch({ type: "CONTENTITEM_SET_LOADING", id });
        }
        try {
            // this will return the root plus all children that aren't set to lazy load
            const items = await controller.auth_getItem(auth, { subscription_id, project_id }, id === ROOT_ID ? null : id);

            // should I attach parent ids here? (or should api do it) (or nobody)
            // attach parent_id
            items.forEach(item => {
                item.children?.forEach(childRef => {
                    const child = items.find(i => i.id === childRef.id);
                    child.parent_id = item.id;
                })
            })

            // note: this also turns off the loading bit
            const action = { type: "CONTENTITEM_SET_MULTIPLE", id, values: items };
            if( id === ROOT_ID ){
                const root = items?.[0];
                action.newEntries = root ? [{ id: root?.id, ctype: root?.ctype }] : [];
            }
            dispatch(action);
        }
        catch (err) {
            dispatch({ type: "CONTENTITEM_SET_LOADING", id, loading: false, error: err.message });
        }
    }
            
    useEffect(() => {
        if( !subscription_id || !project_id || loading || error || !id ) return;
        if( contentItem ) return;
        dispatch({ type: "CONTENTITEM_SET_LOADING", id });
        load(false);
        
    }, [subscription_id, project_id, id])

    return [
        contentItem,
        loading,
        error,
        load // reload
    ];

}



