import { useContext, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { ContentAdderButton } from "../content/ContentAdderButton";
import { DisplayContext, getDisplayClass } from "../content/DisplayContext";
import { PresentContent } from "../content/PresentContent";
import "./TabControl.scss";
import queryString from "query-string";
import { EditContentContext } from "../content/EditContentContext";

export const TabControl = (props) => {

    const ec = useContext(EditContentContext);
    const mode = ec.mode;
    const item = ec.item;
    const children = item?.children;
    const style = { ...props.style, ...item?.style }
    const locked = item?.jdata?.locked;
    const align = item?.jdata?.align || "left"; // = "left center right"

    // selected tab
    const history = useHistory();
    let search = queryString.parse(history.location.search);
    let selectedChildId = search?.[item.id] || children?.[0]?.id;
    const selectedChild = children?.find(ch => ch.id === selectedChildId);
    
    return <div className={`mstudio-tab-control-layout ${props.displayClass}`} style={style} {...props.atts}>
        <div className="tab-strip centered">
            <DisplayContext.Provider value={"inline-block"}>
                {(align === "center" || align === "right") && <span className="leftover"/>}
                {children?.map((ch, chIdx) => {
                    const newSearch = {
                        ...search,
                        [item.id]: ch?.id
                    };
                    const url = `${history.location.pathname}?${queryString.stringify(newSearch)}`;
                    const editorSelected = ec.mode === "edit" && ec.selectionId === ch.id;
                    return <span
                        key={chIdx}
                        className={`tab ${ch?.id === selectedChildId ? "sel" : ""} ${editorSelected ? "esel" : ""}`}
                        to={url}
                        onClick={(ev) => {
                            history.push(url);
                            ev.stopPropagation();
                            ev.preventDefault();

                            // if something is already selected in editor,
                            // OR if this tab was already the selected tab
                            if( ec.selectionId || (ch.id === selectedChildId) ){
                                if( ec.selectionId === ch.id )
                                    ec.setSelectionId(null); // child was already selected, clear editor selection.
                                else{
                                    ec.setSelectionId(ch.id); // set editor selection
                                    // todo: it might be nice if we could collapse the tab siblings here
                                    // but that would also require expanding this tab, which is also not being done
                                }
                            }
                        }}
                    >
                        {ch.label || `Tab ${chIdx}`}
                    </span>
                })}
                {mode === "edit" && !locked &&
                    <ContentAdderButton label="Add tab" className="mini" />
                }
                {(align === "center" || align === "left") && <span className="leftover"/>}
            </DisplayContext.Provider>
        </div>
        <div className="tab-content">
            {selectedChild ?
                <PresentContent id={selectedChild.id} />
                : (!children?.length) ? 
                    <ContentAdderButton label="Add first tab" description="Hint: Consider adding a layout if tab page will contain multiple items" /> 
                : null
            }
        </div>


    </div>

    
}