import { useState } from "react";
import { ReactSortable } from "react-sortablejs";
import Popup from "reactjs-popup";
import { useDataSource } from "../../hooks/useDataSource";
import { useDataSourceRevision } from "../../hooks/useDataSourceRevision";
import { useFilterBook } from "../../hooks/useFilterBook";
import { useSelectionState } from "../../hooks/useSelection";
import { getFilterBookCategories, getFilterGroupId, getFilterId, smartCombine } from "../../utils/FilterUtils";
import { newId } from "../../utils/uuid";

import { ModalFrame } from "../modal-dialog/ModalFrame";
import { NewFilterDlg } from "./NewFilterDlg";
import "./SelectCGroupsDlg.scss";
import JSON5 from "json5";

const checkMatch = (str, terms) => {
    if (!str || !terms || terms.length < 1) return false;
    const lstr = str?.toLowerCase();
    for (let term of terms) {
        if (!lstr.includes(term)) {
            return false;
        }
    }
    return true;
}

const isFilterGroupMatch = (fg, terms) => {
    if (checkMatch(fg.label, terms)) return true;
    // label isn't a match, but what about child group labels?
    if (!fg.filters || fg.filters.length < 1) return false;
    for (let filter of fg.filters) {
        if (checkMatch(filter.label, terms)) return true;
    }
    return false;
}


export const SelectCGroupsDlg = (props) => {

    //const filterBookId = props.id;
    const filtersId = props.id;
    console.log("filtersId", filtersId);
    //const selectionStateId = props.selectionId || "fb_" + filterBookId;
    const selectionStateId = props.selectionId || "fb_" + filtersId;

    const [state, stateLoading, stateError, setState] = useSelectionState(selectionStateId);
    const [search, setSearch] = useState(null);

    //const [filterBook, filterBookLoading, filterBookError] = useFilterBook(filterBookId);
    const [filters, filtersLoading, filtersError] = useDataSource(filtersId)
    const [filtersRev, filtersRevLoading, filtersRevError] = useDataSourceRevision(filtersId, filters?.dev_revision); // todo: __dev or __prod
    console.log("filters", filters, filtersLoading, filtersError);
    // console.log("revision", filters?.dev_revision);
    // console.log("filtersRev", filtersRev);
    const filterBookDef = filtersRev?.udata ? JSON5.parse(filtersRev?.udata) : null;
    const [advancedMode, setAdvancedMode] = useState(false);
    
    const [selectedCategory, setSelectedCategory] = useState("[all]");
    const [selectedFilterGroups, setSelectedFilterGroups] = useState([]);

    const [selectedFilters, setSelectedFilters] = useState([]);

    const [newPendingFilter, setNewPendingFilter] = useState(null);
    const [editFilter, setEditFilter] = useState(null);

    const categories = getFilterBookCategories(filterBookDef);
    const selCat = selectedCategory === "[all]" ? {
        label: "All",
        filterGroups: filterBookDef
    }
        : categories?.find(c => c.label === selectedCategory) || categories?.[0];
    
    let filterGroups = selCat?.filterGroups;

    if (search) {
        const terms = search?.toLowerCase().split(' ');
        filterGroups = filterGroups.filter(fg => isFilterGroupMatch(fg, terms));
        // reduce filterGroups based on search terms
    }


    const selFgs = selectedFilterGroups.map(
        fg => filterGroups?.find(g => getFilterGroupId(g) === fg)
    )
        .filter(_ => _); // remove null entries

    
    const availableFilters = selFgs?.reduce((accum, el) => {
        if (el.filters) {
            accum = [...accum, ...el.filters?.map(f => ({
                ...f,
                groupLabel: el.label,
                group: el
            }))]
        }
        return accum;
    }, [])
    

    let saveList = null;

    return <ModalFrame
        close={props.close}
        title={"Select Comparison Groups"}
        buttons={[
            {
                label: "Close",
                callback: () => {
                    props.close?.();
                }
            }
        ]}
    >
    <div className="select-cgroups-dlg">
        <div className="title1">
                Available Groups
        </div>
        <div className="title2">
            Selected Groups
        </div>
        <div className="select-category">
            <select value={selectedCategory || ""} onChange={(ev) => {
                setSelectedCategory(ev.target.value)
                }}>
                    <option value="[all]">All</option>
                {categories?.map((category, i) =>
                    <option key={i + "/" + category.label} value={category.label}>{category.label}</option>
                )}
                
            </select>
            </div>
            <div className="fgsearch">
                <input type="text" placeholder="search" className="tb-search"
                    value={search || ""} onChange={(ev) => setSearch(ev.target.value)}
                />
                {search ? <span className='btn-cancel-search' onClick={() => setSearch(null)}>
                    <i className='fal fa-times'/>
                </span> : null}
            </div>
        <div className="fglist">
            {filterGroups?.map((fg,fgi) =>
                <div
                    key={`${fg.id}/${fgi}`}
                    className={`item ${selectedFilterGroups.includes(fg.id) ? "sel" : ""}`}
                    onClick={() => {
                        if (selectedFilters?.length > 0) {
                            setSelectedFilters([]);
                        }
                        if (window.event.ctrlKey) {
                            if (selectedFilterGroups.find(id => id === fg.id)) {
                                // already exists, remove it
                                setSelectedFilterGroups(selectedFilterGroups.filter(id => id !== fg.id));
                            }
                            else {
                                setSelectedFilterGroups([...selectedFilterGroups, fg.id]);
                            }
                        }
                        else {
                            setSelectedFilterGroups([fg.id])
                        }
                    }}
                >
                    {fg.label}
                </div>
            )}
        </div>
            <div className="list1-actions">
                
                <span className={`toggle-btn margin-right ${advancedMode ? "" : "sel"}`}
                    onClick={() => {
                        setSelectedFilters([]);
                        setAdvancedMode(false);
                    }}
                >
                    Single
                </span>
                <span className={`toggle-btn margin-right ${advancedMode ? "sel" : ""}`}
                    onClick={() => {
                        setSelectedFilters([]);
                        setAdvancedMode(true);
                    }}
                >
                    Combine
                </span>
                <span className="stretch-spacer"/>

                {advancedMode ?
                    (
                        selectedFilters?.length > 0 ?
                        <span className="toggle-btn"
                            onClick={() => {
                                setSelectedFilters([]);
                            }}
                        >
                            Clear
                        </span>
                        :
                        <span className="toggle-btn"
                            onClick={() => {
                                setSelectedFilters(availableFilters?.map(f => getFilterId(f)));
                            }}
                        >
                            Select All
                        </span>
                    )
                :
                    <span className="toggle-btn"
                        onClick={() => {
                            const newState = [
                                ...(state || []),
                                ...(availableFilters?.map(f => ({
                                    ...f,
                                    id: newId()
                                })) || [])
                            ];
                            setState(newState);
                        }}
                    >
                        <i className="fal fa-plus" />&nbsp; Add All
                    </span>
                }
        </div>
        <div className="list1">
                {availableFilters?.map((f, fi) => {
                    const fId = getFilterId(f);
                    const selected = advancedMode && selectedFilters.includes(fId);
                    return <div
                        key={`${fId}/${fi}`}
                        className={`item ${selected ? 'sel' : ''} ${advancedMode ? "can-select" : ""}`}
                        onClick={() => {
                            if (advancedMode) {
                                if (selected) {
                                    // remove from selections
                                    setSelectedFilters(selectedFilters.filter(id => id !== fId));
                                }
                                else {
                                    // add to selections
                                    setSelectedFilters([...selectedFilters, fId]);
                                }
                            }
                        }}
                    >
                        {advancedMode ?
                            <>
                                <i className={selected ? "fas fa-check-square" : "fal fa-square"} />&nbsp; {f.label}
                            </>
                            : <>
                                {f.label}
                                <span className="add-btn"
                                    onClick={() => {
                                        const newState = [
                                            ...(state || []),
                                            {
                                                ...f,
                                                id: newId()
                                            }
                                        ]
                                        setState(newState);
                                    }}
                                >
                                    <i className="fal fa-plus" />&nbsp; Add
                                </span>
                            </>}
                    
                    </div>
                })}
        </div>
            {advancedMode ? <div className="arrow-section">
                <div className="arrow" onClick={() => {
                    const f = smartCombine(availableFilters, selectedFilters);
                    setNewPendingFilter(f)
                }}>
                    <i className='fal fa-arrow-right' />
                </div>
                <Popup
                    modal
                    nested
                    closeOnEscape={false}
                    closeOnDocumentClick={false}
                    open={newPendingFilter ? true : false}
                >
                    {closeMe => <NewFilterDlg filter={newPendingFilter}
                        onSave={(newFilter) => {
                            const newState = [
                                ...(state || []),
                                {
                                    ...newFilter,
                                    id: newId()
                                }
                            ]
                            setState(newState);
                            setSelectedFilters([]);
                            setNewPendingFilter(null);
                        }}
                        close={() => {
                            setNewPendingFilter(null);
                            closeMe();
                        }}
                    />}
                </Popup>
        </div> : null}
        <div className="list2-actions">
            <span className="toggle-btn"
                onClick={() => {
                    setState([]);
                }}
            >
                <i className="fal fa-minus"/>&nbsp; Remove All
            </span>
        </div>
            <ReactSortable
                className='list2'
                animation={200}
                delayOnTouchStart={true}
                delay={2}
                list={state || []}
                setList={(list) => {
                    saveList = list; // save this ordering so we can update state onEnd
                }}
                onStart={() => {
                    // todo:
                    //setHideHover(true)
                }}
                onEnd={async () => {
                    const newState = saveList;
                    setState(newState);
                }}
            >
                {state?.map?.(f =>
                    <div className="item" key={f?.id} onClick={() => {
                        setEditFilter(f);
                    }}>
                        {f?.label}

                        <span className="add-btn"
                            onClick={(ev) => {
                                const newState = state?.filter(sf => sf.id !== f.id);
                                setState(newState);
                                ev.preventDefault();
                                ev.stopPropagation();
                            }}
                        >
                            <i className="fal fa-minus"/>&nbsp; Remove
                        </span>
                    </div>
                )}
            </ReactSortable>
            <Popup
                modal
                nested
                closeOnDocumentClick={false}
                closeOnEscape={false}
                open={editFilter ? true : false}
            >
                {closeMe => <NewFilterDlg
                    title={"Edit Filter"}
                    filter={editFilter}
                    onSave={(newFilter) => {
                        const newState = state.map(f => f.id === newFilter.id ? newFilter : f)
                        setState(newState);
                        setEditFilter(null);
                        closeMe();
                    }}
                    close={() => {
                        setEditFilter(null);
                        closeMe();
                    }}
                />}
            </Popup>
        </div>
    </ModalFrame>
}