import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useRecoilValue } from "recoil";
import { useSelection, useSelectionState } from "../../hooks/useSelection";
import { EditContentContext } from "../content/EditContentContext";
import "./ArbitraryContent.scss";
import { computeContextState, ComputeRunnerContext } from "./ComputeContext";

const updateState = (id, value) => dispatch => {
    dispatch({
        type: "SELECTION_STATE_SET",
        id,
        value
    })
    return Promise.resolve();
};


export const CopyStateButton = (props) => {
    const item = props.item;

    const ec = useContext(EditContentContext);

    const crc = useContext(ComputeRunnerContext);


    const [state, stateLoading, stateError] = useSelectionState(item?.jdata?.selectionFrom)
    const [sel, selLoading, selError] = useSelection(item?.jdata?.selectionTo);
    const dispatch = useDispatch();


    // get data from hook
    const cHook = item?.jdata?.contextHooks?.[0];
    const ccState = useRecoilValue(computeContextState(cHook?.id));
    //const runner = useRecoilValue(computeRunnerForScopeState(cHook?.id));
    const runner = crc[cHook?.id];

    const label = item?.label; //ccState?.computing ? "Running..." :  "Run";

    const style = { ...props.style, ...item?.style }
    return <div className={`arbitrary-button ${props.displayClass}`} style={style} {...props.atts}
        onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            if( ec.mode === "edit" && (ev.altKey)){
                ec.setSelectionId(item?.id);
            }
            else{

                // copy the state
                dispatch(updateState(sel.id, state)).then(() => {
                    if( item?.jdata?.recalc === "yes" ){
                        // trigger re-compute
                        runner?.runAfter?.();
                    }
                })   
                
            }
        }}
    >
        {label}
    </div>
};

