import { useMonaco } from "@monaco-editor/react";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
//import 'react-splitter-layout/lib/index.css';
import "./components/extlib/react-splitter-layout/index.scss";
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RecoilRoot } from 'recoil';
import { RefreshToken } from './api/oauth';
import './App.scss';
import { Login, StoreToken } from './components/auth/Login';
import { RecoverPassword } from './components/auth/RecoverPassword';
import { SignUp } from './components/auth/SignUp';
import { UpdatePassword } from './components/auth/UpdatePassword';
import { MainLayout } from './components/MainLayout';
import { AuthContext } from './context/Contexts';
import * as globals from "./globals";
import './styles/Basics.scss';
import "./styles/font-awesome/css/all.css";
import "./styles/font-awesome/css/sharp-solid.css";
import './styles/Form.scss';

const refreshTokenEarlySeconds = 100;// 1 * 60; // refresh 5 minutes early


const wrapAuthToken = (t) => {
    if( !t ) return null;
    const token = {...t};
    token.expires = new Date(token.expires);
    token.valid = token.expires.getTime() - Date.now() > 0;
    token.expired = !token.valid;
    const earlyMilliseconds = refreshTokenEarlySeconds * 1000;
    token.needsRefresh = token.expires.getTime() - Date.now() - earlyMilliseconds < 0;
    return token;
}

const getJSONFromStorage = (key) => {
    //const objStr = localStorage.getItem(key);
    const objStr = sessionStorage.getItem(key);
    return objStr ? JSON.parse(objStr) : null;
}

const getAuthToken = () => {
    const token = getJSONFromStorage("authToken");
    return wrapAuthToken(token);
}


const App = ()=> {
    //console.log("App render");
    //const [authUser, setAuthUser] = useState(getJSONFromLocalStorage("authUser"));
    //const [authToken, setAuthToken] = useState(getJSONFromLocalStorage("authToken"));
    const dispatch = useDispatch();

    const [refreshCounter, setRefreshCounter] = useState(0);


    const [authToken, setAuthToken] = useState(getAuthToken());
    //const authToken = getAuthToken();

    const authUser = getJSONFromStorage("authUser");




    const monaco = useMonaco();

    console.log("App render, token:", authToken?.access_token);

    useEffect(() => {
        const intervalMinutes = 1; // how often to check token
        const intervalSeconds = intervalMinutes * 60;
        setInterval(checkTokenExpiration, intervalSeconds * 1000);
        checkTokenExpiration();
    }, []);

    useEffect(() => {
        if (!monaco) return;

        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
            allowComments: true,
            trailingCommas: "ignore"
        });
        
        // monaco.languages.registerCompletionItemProvider('yak', {
        //     provideCompletionItems: () => {
        //         var suggestions = [{
        //             label: 'simpleText',
        //             kind: monaco.languages.CompletionItemKind.Text,
        //             insertText: 'simpleText'
        //         }, {
        //             label: 'testing',
        //             kind: monaco.languages.CompletionItemKind.Keyword,
        //             insertText: 'testing(${1:condition})',
        //             insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
        //         }, {
        //             label: 'pctinrange',
        //             kind: monaco.languages.CompletionItemKind.Snippet,
        //             insertText: [
        //                 'pctinrange(${1:expr},${2:min},${3:max})',
        //             ].join('\n'),
        //             insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        //             documentation: 'Percent with range (inclusive of min and max)'
        //         }];
        //         return { suggestions: suggestions };
        //     }
        // });

    }, [monaco])
    
    const checkTokenExpiration = () => {
        //console.log("checkTokenExpiration");
        const token = getAuthToken();
        //console.log("token.expires", token?.expires);

        {
            let diff_ms = token?.expires.getTime() - Date.now();
            console.log("token expires in (seconds)", diff_ms / 1000);
        }

        if (token?.needsRefresh) {
            refreshToken();
        }
    }

    const refreshToken = async () => {

        // console.log("cancelling refreshToken");
        // return;

        const token = getAuthToken();
        if (!token) return;

        console.log("attempting to refresh token");
        const rtoken = await RefreshToken(globals.client_id, globals.client_secret, token.refresh_token, "myscope");
        if (rtoken.error) {
            //setError(token.error_description);
            await StoreToken(null);
            setAuthToken(null);
        }

        else {
            console.log('no error')
            const new_token = {
                ...token,
                ...rtoken
            }
            await StoreToken(rtoken);
            setAuthToken(rtoken);
            //reloadAuth();
        }
    }

    // const refreshToken = () => {
    //     const token = getJSONFromLocalStorage("authToken");
    //     if (!token) return;
    //     console.log("refreshToken");
    //     //console.log("now", new Date());
    //     //console.log("expires", authToken?.expires);
    //     if (authToken?.expires) {
    //         //console.log("diff", new Date(authToken.expires).getTime() - Date.now());
    //     }
    //     //alert("time to refresh token");
    //     localStorage.setItem("authToken", null);
    //     setAuthToken(null);
    // }

    const reloadAuth = () => {
        //console.log("reloadingAuth...");
        setRefreshCounter(refreshCounter + 1);
    }

    const getNewToken = () => {
        // this is called from places like: useItem hook useItems hook
        // when an API call returns invalid_token
        //alert("Authorization expired. Please click refresh.");
        reloadAuth();
    }

    const signOut = () => {
        //localStorage.setItem("authToken", null);
        sessionStorage.setItem("authToken", null);
        setAuthToken(null);

        dispatch({ type: "CLEAR_PROJECT" }); // weird
        //reloadAuth();
    }

    const callFailed401 = () => {
        alert("authentication failed");
    }

    const authorized = wrapAuthToken(authToken)?.valid;
    return (
        <Router>
            <RecoilRoot>
                <div className="App">
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        transition={Slide}
                        theme="light"
                    />
                    <AuthContext.Provider value={{
                        authToken,
                        setAuthToken: (v) => {
                            console.log("setAuthToken", v);
                            setAuthToken(v);
                        },
                        authUser,
                        getNewToken,
                        reloadAuth,
                        signOut,
                        callFailed401
                        }}>
                            <Switch>
                                <Route path="/recover-password">
                                    <RecoverPassword />
                                </Route>
                                <Route path="/create-new-password">
                                    <UpdatePassword />
                                </Route>
                                <Route path="/sign-up" component={SignUp}/>
                                <Route>
                                    {authorized ? <MainLayout/> : <Login />}
                                </Route>
                            </Switch>
                    </AuthContext.Provider>
                </div>
            </RecoilRoot>
        </Router>
    );
}

export default App;
