import * as globals from "../globals";
import * as utf8 from 'utf8';
import * as base64 from 'base-64';

const authRoot = globals.apiRoot + "/oauth";

export const GetToken_Password = async (client_id, client_secret, username, password, scope) => {
    
    const url = `${authRoot}/token`;

    const bytes = utf8.encode(client_id + ':' + client_secret);
    const basicstr = base64.encode(bytes);

    const body = {
        grant_type: "password",
        username,
        password,
        scope
    };

    var formBody = [];
    for (var property in body) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const response = await fetch(url, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            //"Content-Type": 'application/json',
            "Content-Type": 'application/x-www-form-urlencoded',
            Authorization: "Basic " + basicstr
        },
        body: formBody
    });

    const token = await response.json();
    let token2 = {
        ...token,
    }

    // add expires timestamp
    if (token?.expires_in) {
        let expires = new Date();
        expires.setSeconds(expires.getSeconds() + token.expires_in);
        token2.expires = expires;
        delete token2.expires_in;
    }

    return token2;
    
}


export const RefreshToken = async (client_id, client_secret, refresh_token, scope) => {
    
    const url = `${authRoot}/token`;

    const bytes = utf8.encode(client_id + ':' + client_secret);
    const basicstr = base64.encode(bytes);

    const body = {
        grant_type: "refresh_token",
        refresh_token,
        scope
    };

    var formBody = [];
    for (var property in body) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const response = await fetch(url, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            //"Content-Type": 'application/json',
            "Content-Type": 'application/x-www-form-urlencoded',
            Authorization: "Basic " + basicstr
        },
        body: formBody
    });

    const token = await response.json();

    let token2 = {
        ...token,
    }

    // add expires timestamp
    if (token?.expires_in) {
        let expires = new Date();
        expires.setSeconds(expires.getSeconds() + token.expires_in);
        token2.expires = expires;
        delete token2.expires_in;
    }

    return token2;
    
}


