import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { ContentItemContext } from "../../../context/Contexts";
import { useImage } from "../../../hooks/useImage";
import { useProjectId } from "../../../hooks/useProjectId";
import { getImagePath } from "../../../utils/ImageUtils";
import { EditContentItemWrapper } from "../../edit/EditContentItemWrapper";
import { ErrorBoundary } from "../../error-boundary/ErrorBoundary";
import { getAllowedChildTypes, getTypeInfo } from "../ContentTypes";
import { LoadContentItem } from "../LoadContentItem";
import "./ViewRow.scss";





export const ViewRow = (props) => {

    const {project_id, subscription_id} = useProjectId();
    const item = props.content_item;

    const [backgroundImage] = useImage(item?.background?.imageId);
    const backgroundImagePath = backgroundImage ? getImagePath(subscription_id, project_id, backgroundImage?.filename) : null;


    let rowStyle = {
        display: "grid",
        gridTemplateColumns: item.gridTemplateColumns || "repeat(auto-fill,minmax(200px,1fr)",
        //gridAutoRows: "250px",
        ...item.style,

        //position: "absolute",
        //height: "100%",
        //width: "100%",

        // border
        borderStyle: item?.border?.style,
        borderWidth: item?.border?.width + "px",
        borderColor: item?.border?.color,

        // background
        backgroundAttachment: item?.background?.attachment,
        backgroundBlendMode: item?.background?.blendMode,
        backgroundClip: item?.background?.clip,
        backgroundColor: item?.background?.color,
        //backgroundImage: item?.background?.image,
        backgroundOrigin: item?.background?.origin,
        backgroundPosition: item?.background?.position,
        backgroundRepeat: item?.background?.repeat,
        backgroundSize: item?.background?.size,

    }

    if (item?.background?.imageType === "url") {
        rowStyle.backgroundImage = `url("${backgroundImagePath}")`
    }
    else if (item?.background?.imageType === "none") {
    }
    
    const content = <div style={rowStyle}>
        {item?.children?.map(child => <LoadContentItem
            level={props.level + 1}
            key={child.id}
            id={child.id} 
            edit={props.edit} 
            pType={"ROW"} />)}
    </div>

    const xcontent = props.edit ? 
        <EditContentItemWrapper {...props} actionsPosition="above" >
            <div style={rowStyle}>
                {item && (!item?.children || item?.children?.length === 0) ? <div className="centered-message">
                    [blank row]
                </div> : null}
                {item?.children?.map(child =>
                    <LoadContentItem
                        level={props.level + 1}
                        key={child.id}
                        id={child.id}
                        edit={props.edit}
                        pType={"ROW"}
                    />
                )}
            </div>
        
        </EditContentItemWrapper>
    :
    content;
    
    return <ErrorBoundary>{xcontent}</ErrorBoundary>
}
