import { useContext } from "react";
import { useContentItemsApi } from "../../hooks/useContentItems";
import { ContentAdderButton } from "../content/ContentAdderButton";
import { DisplayContext, getDisplayClass } from "../content/DisplayContext";
import { EditContentContext } from "../content/EditContentContext";
import { PresentContent } from "../content/PresentContent";
import { GenWrapper } from "./ArbitraryContent"
import "./FlexLayout.scss";

export const FLEX_HINT = "Hint: Consider adding a VScroller before adding content";

export const FlexLayout = (props) => {

    const ec = useContext(EditContentContext);
    const mode = ec.mode;
    const item = ec.item;
    const children = item?.children;
    const jdata = item?.jdata;

    const style = { ...props.style, ...item?.style }

    return <div className={`mstudio-flex-layout ${props.displayClass}`} style={style} {...props.atts}>
        {children?.map?.(child =>
            <DisplayContext.Provider value={"block"} key={child.id}>
                <PresentContent id={child.id} />
            </DisplayContext.Provider>
        )}
        {(mode === "edit" && !jdata?.locked) &&
            <DisplayContext.Provider value={"block"}>
                <ContentAdderButton label="Add flex content" className="mini"/>
            </DisplayContext.Provider>
        }
    </div>
    
}