import React, { useEffect, useState } from "react";
//import SplitterLayout from "react-splitter-layout";
import SplitterLayout from "../extlib/react-splitter-layout/SplitterLayout";
import { useModel, useModelsApi } from "../../hooks/useModel";
import { useProjectId } from "../../hooks/useProjectId";
import { useResources } from "../../hooks/useResources";
import { reduceResources } from "../../hooks/helper/reduceResources";
import { compileFunction } from "../../utils/Compiler";
import { ControlledCodeEditorFillContainer } from "../code-editor/CodeEditor";
import { EditResources } from "../page-content/edit/EditResources";
import "./EditStageModel.scss";


export const EditStageModel = (props) => {

    const { subscription_id, project_id } = useProjectId();

    const id = props.id;
    const [model, modelLoading, modelError] = useModel(id);
    const api = useModelsApi();

    const [showTest, setShowTest] = useState(false);

    const [producer, setProducer] = useState(null);
    
    const [resourceList, setResourceList] = useState(null);
    const [result, setResult] = useState(null);

    const [resources, resourcesLoading, resourcesError] = useResources(resourceList, true);
    
    useEffect(() => {
        
        const jdata = model?.jdata;
        if( !jdata ) return;

        setProducer(jdata.producer);
        setResourceList(jdata.testResources);

    }, [model])


    const save = async () => {
        const jdata = {
            producer: producer,
            testResources: resourceList
        };
        await api.patchItem(id, { jdata });
    }

    const run = async () => {

        try{
            const rr = resources ? reduceResources(resources, subscription_id, project_id, null, null) : null;
            const fSyntax = `(resources) => {
                ${producer}
            }
            `;
            const f = compileFunction(fSyntax)
            const res = f?.(rr);
            setResult(JSON.stringify(res, null, 4));
            //console.log("result:", result);
        }
        catch( err ){
            console.error(err.message);            
        }

    }

    return <div className='edit-stage-model full-dock'>
        <div className="top tab-section no-marginx">
            <span className="help-link" 
                onClick={() => setShowTest(!showTest)}
            >
                <i className="far fa-flask"/>
            </span>
            <span style={{ marginLeft: 5 }}>
                <span className="btn action"
                    onClick={() => save()}
                >
                    Save
                </span>
            </span>
        </div>
        <div className="center main-center full-dock fill-parent-absolute">
            <div className="top">
                {showTest && <div style={{ maxWidth: 400 }}>
                    <EditResources value={resourceList} onChange={async (newList) => setResourceList(newList)} />
                </div>}
                {/* <div key={tab.id} style={{ margin: "0 0 5px 0", ...(tab.id === selTabId ? null : { display: "none" }) }}>
                    <label>
                        <input 
                            id={tab.id} 
                            type="checkbox"
                            checked={getter[tab.id]?.produce}
                            onChange={(ev) => updateCodeProduceBit(tab.id, ev.target.checked)}
                        /> Produce with a function ({tab.id})
                    </label>
                </div> */}
            </div>

            {model && 
                <div className="center fill-parent-absolute code-section1" key={model.id}>
                    <SplitterLayout primaryIndex={1} secondaryMinSize={300}>
                        <div className="code-container full-dock">
                            <div className="top" style={{ 
                                        borderBottom: "1px solid #cfcfcf",
                                        paddingBottom: "5px"
                                    }}>
                                <span className="btn toggle-small" onClick={() => run()}>
                                    <i className="fas fa-play" />&nbsp;
                                    Run
                                </span>
                            </div>
                            <div className="center">
                                <ControlledCodeEditorFillContainer key={id} value={producer} onChange={(newCode) => setProducer(newCode)} />
                            </div>
                        </div>
                        <div className="code-container">
                            <ControlledCodeEditorFillContainer language="json" value={result} onChange={(newCode) => setResult(newCode)} />
                        </div>
                    </SplitterLayout>
                </div>
            }

        </div>
    </div>
    
}