import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useProjectId } from "../../hooks/useProjectId";
import { ModalFrame } from "../modal-dialog/ModalFrame";
import { CONNECTORS } from "./connectors/_Connectors";
import "./NewDataSource.scss";

// https://api.qualtrics.com/guides/ZG9jOjg3NzY3MA-getting-survey-responses-via-the-new-export-ap-is

const PAGES = [
    {
        id: "select-connector",
        actionButtonText: "Next"
    },
    {
        id: "connector-input",
        //actionButtonText: "Continue"
    },
    {
        id: "done",
        actionButtonText: "Close"
    }
]

export const NewDataSourceContext = React.createContext(null);

export const NewDataSource = (props) => {

    const [page, setPage] = useState(PAGES[0].id);
    // const [structure, setStructure] = useState(STRUCTURES[0].id);
    const [dsType, setDsType] = useState(CONNECTORS[0].id);
    const { project_id, subscription_id } = useProjectId();
    
    const [working, setWorking] = useState(false);
    const [workStatusMessage, setWorkStatusMessage] = useState(null);
    const history = useHistory();

    const [buttonText, setButtonText] = useState(null);
    //const [buttonClicked, setButtonClicked] = useState(() => () => alert('not implemented'));

    let hackHandler = {};

    const selPage = PAGES.find(p => p.id === page);
    const selConnector = CONNECTORS.find(c => c.id === dsType);
    const SelWidget = selConnector.widget || ((props) => <div>not implemented</div>);

    let buttons = [];
    if (page === "connector-input") {
        buttons.push({
            label: "Back",
            disabled: working,
            callback: () => {
                // assuming only 2 pages; todo
                setPage("select-connector");
            }
        });
    }
    buttons.push({
        label: selPage.actionButtonText || buttonText || "Continue",
        disabled: working,
        callback: async () => {

            if (page === "select-connector") {
                setPage("connector-input");
            }
            else if (page === "connector-input") {
                console.log("hackHandler", hackHandler);
                const newId = await hackHandler?.buttonClicked?.();
                if (newId) {
                    setPage("done");
                    
                    // select the new data source
                    history.push(`/subscription/${subscription_id}/project/${project_id}/data-source/${newId}`)
                }
            }
            else {
                // todo: test close
                props.close?.();
            }
            
        }
    });

    const providerObj = {
        setButtonText,
        hackHandler,
        setPage,
        working,
        setWorking,
        workStatusMessage,
        setWorkStatusMessage
    };

    return <NewDataSourceContext.Provider value={providerObj}>
     <ModalFrame close={props.close}
        title="Create new data source"
        buttons={buttons}
    >
        <div className='form1 new-data-source' style={{ maxWidth: 500 }}>

            {(page === "select-connector") ? <>
                <label className="label-line">
                    Select connector:
                </label>
                <div className="connectors-scroller">
                    {CONNECTORS.map(conn =>
                        <span
                            key={conn.id}
                            className={`connector ${(dsType === conn.id ? "sel" : "")}`}
                            onClick={() => setDsType(conn.id)}
                            style={conn.style}
                        >
                            {conn.label}
                        </span>
                    )}
                </div>
            </>
                :
                    (page === "connector-input") ? <SelWidget connector_id={selConnector?.id} />
                        : (page === "done") ? 
                            <div>
                                Done.
                            </div>
                        : null
            }

            <div className="label-line">
            </div>

        </div>

        </ModalFrame>
    </NewDataSourceContext.Provider>

    
}


