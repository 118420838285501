import { useContext } from "react"
import { DisplayContext, getDisplayClass } from "./DisplayContext";
import { EditContentContext } from "./EditContentContext"
import "./EditWrapper.scss";
import Popup from "reactjs-popup";
import { aGet } from "../../api/restMethods";
import { useContentItemsApi } from "../../hooks/useContentItems";
import { SelectNewContentItem } from "./SelectNewContentItem";

// only used when mode=edit
export const EditItemContextMenu = (props) => {
    
    const editContext = useContext(EditContentContext);
    const api = useContentItemsApi();

    const item = editContext.item;
    const parentItem = editContext.itemStack[editContext.itemStack.length - 2];
    const selected = item && editContext.selectionId === item?.id;
    const close = props.close;

    const move = async (dir) => {
        editContext.setMessage("Moving...");
        props?.close();
        await api.moveItem(item?.id, dir)
        const reload = editContext.reloadStack[editContext.reloadStack.length - 2];
        await reload?.();
        // clear message?
    }

    return <div className="edit-wrapper-menu">
        <div className="menu-item-group">
            <div className="menu-item" onClick={() => move("top")}>
                <i className="fal fa-arrow-up-to-line" />
                Move to top
            </div>
            <div className="menu-item" onClick={() => move("up")}>
                <i className="fal fa-arrow-up" />
                Move up
            </div>
            <div className="menu-item" onClick={() => move("down")}>
                <i className="fal fa-arrow-down" />
                Move down
            </div>
            <div className="menu-item" onClick={() => move("bottom")}>
                <i className="fal fa-arrow-down-to-line" />
                Move to bottom
            </div>
        </div>
        <div className="menu-item-group">
            <div className="menu-item disabled">
                <i className="fad fa-copy" />
                Copy
            </div>
            <div className="menu-item disabled">
                
                Duplicate
            </div>
        </div>
        <div className="menu-item-group">
            <Popup
                modal
                contentStyle={{
                    width: 600,
                    height: 300
                }}
                trigger={
                    <div className="menu-item">
                        Enclose item in...
                    </div>
                }
            >
                {close => <SelectNewContentItem onClick={entry => {
                    alert("not implemented");
                    close();
                    //editContext.addChild?.(entry, props.keyName)
                }}/>}
            </Popup>
            <div className="menu-item disabled">
                Replace with only child
            </div>
            <div className="menu-item disabled">
                Replace with...
            </div>
        </div>
        <div className="menu-item-group">
            <div className="menu-item"
                onClick={(ev) => {
                    close();
                    ev.stopPropagation();
                    ev.preventDefault();
                    editContext.deleteChild?.(item?.id, item?.label);
                }}
            >
                <i className="fas fa-skull" />
                Delete
            </div>
        </div>
    </div>

}