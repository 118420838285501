import { useContext, useState } from "react";
import Popup from "reactjs-popup";
import { PipelineEditorContext, PipelineStageEditorContext } from "../../../../context/Contexts";
import { FIELD_STRINGIFIER, getLowerCaseSearchTerms, isMatch } from "../../../../utils/SearchHelper";
import { ModalFrame } from "../../../modal-dialog/ModalFrame";
import { InspectionContext, StagesInspectButton } from "../inspector/StagesInspectButton";
import "./EditSortStage.scss";


export const EditSortStage = (props) => {

    const pipelineContext = useContext(PipelineEditorContext);
    const stageContext = useContext(PipelineStageEditorContext);
    const stage = stageContext.stage;

    
    let defaultValue = Object.entries(stage.data?.$sort || {})
        .map(e => ({ field: e[0], dir: e[1] }))

    const [inspection, setInspection] = useState(null);
    const [sortEntries, setSortEntries] = useState(defaultValue);

    const save = (entries) => {
        
        // convert to expected format
        const data = entries.reduce(
            (acc, el) => {
                acc[el.field] = el.dir;
                return acc;
            }, 
            {}
        )
        const newData = { $sort: data };
        stageContext.replaceStageData(newData);
        
    }

    const allFields = inspection ? Object.entries(inspection)?.map(entry_arr => {
        const key = entry_arr[0];
        const val = entry_arr[1];
        return {
            name: key
        }
    }) : [];



    return <div className="edit-sort-stage">
        <InspectionContext.Provider value={{ setInspection }}>
            <div style={{ marginBottom: 10 }}>
                Sort by:
            </div>
            {sortEntries?.map((sortEntry, idx) => {
                let res = [];
                res.push(<EditSortEntry 
                    key={idx} 
                    entry={sortEntry} 
                    fields={allFields}
                    saveEntry={(newEntry) => {
                        const newEntries = sortEntries.map(e => e === sortEntry ? newEntry : e);
                        setSortEntries(newEntries);
                        save(newEntries);
                    }}
                    deleteEntry={() => {
                        const newEntries = sortEntries.filter(e => e !== sortEntry);
                        setSortEntries(newEntries);
                        save(newEntries);
                    }}
                />);
                if( idx === -1 ){
                    res.push(<div style={{ marginTop: 10, marginBottom: 10 }}>
                        ...then by:
                    </div>)
                }
                return res;
            })}
            <EditSortEntry
                key={"new-item"}
                entry={null}
                fields={allFields}
                saveEntry={(newEntry) => {
                    newEntry = { ...newEntry, dir: newEntry.dir || 1 };
                    const newEntries = [...(sortEntries || []), newEntry];
                    setSortEntries(newEntries);
                    save(newEntries);
                }}
            />
        </InspectionContext.Provider>
    </div>
    
}

const EditSortEntry = (props) => {

    //const [entry, setEntry] = useState(props.entry);
    const entry = props.entry;
    const dir = entry?.dir;

    const isString = entry && props.fields?.find(f => f.name === entry.field)?.type === "string";


    return <div className="sort-entry">
        <Popup
            modal
            keepTooltipInside
            contentStyle={{
                width: 500,
                maxWidth: "100%",
                height: 700,
                maxHeight: "100%",
                overflow: "hidden"
                
            }}
            trigger={
                <span className={`field-name ${entry ? "" : "new-entry"}`}>
                    {entry ? entry.field : "[select field...]"}
                </span>
            }
        >
            {close => <SelectInspectedField
                fields={props.fields}
                close={close}
                save={(field) => {
                    const newEntry = { ...entry, field };
                    props.saveEntry(newEntry);
                    close();
                }}
            />}
        </Popup>
        
        <span className={`sort-dir btn icon ${entry ? "" : "disabled invisible"}`}
            onClick={(ev) => {
                if( !entry ) return;
                const newDir = dir === 1 ? -1 : 1;
                const newEntry = { ...entry, dir: newDir };
                props.saveEntry(newEntry);
            }}
        >
            <i className={isString ? 
                (dir === 1 ? `fal fa-arrow-down-a-z` : `fal fa-arrow-up-z-a`)
                : (dir === 1 ? `fal fa-arrow-down-1-9` : `fal fa-arrow-down-9-1`)} />
        </span>

        <span 
            className={`actions btn icon light ${entry ? "" : "disabled invisible"}`}
            onClick={() => props.deleteEntry?.()}
        >
            <i className="fal fa-times"/>
        </span>
    </div>
}

const SelectInspectedField = (props) => {

    const [field, setField] = useState(null);

    const { inspecting, inspect } = props;

    //const sFields = fields.filter(f => f.name?.includes())
    const searchTerms = getLowerCaseSearchTerms(field);
    const fields = props.fields?.filter(f => isMatch(f, searchTerms, FIELD_STRINGIFIER));

    const noFields = !fields || fields.length < 1;

    return <ModalFrame
        title={"Select Field"}
        className="no-content-padding"
        //style={{ width: 400, height: 400 }}
        contentStyle={{ position: "relative" }}
        close={props.close}
        buttons={[
            {
                label: "OK",
                callback: () => {
                    props.save?.(field);
                }
            },
            {
                label: "Cancel",
                callback: () => {
                    props.close?.();
                }
            }
        ]}
    >
        <div className="sort-stage-select-field-modal full-dock">

            <div className="top">
                <StagesInspectButton />
                <input type="text" 
                    className="text1"
                    value={field}
                    onChange={(ev) => setField(ev.target.value)}
                />
            </div>
            
            <div className="center popup-menu menu1">
                {noFields ? <div className="menu-item">
                    [no fields available]
                </div> : null}
                {
                    fields?.map(field =>
                        <div key={field.name} className="menu-item"
                            onClick={() => {
                                setField(field?.name);
                                //props.onClick?.(field);
                            }}
                        >
                            {field.name}
                        </div>
                    )
                }
            </div>

        </div>
    </ModalFrame>
    

}