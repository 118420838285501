import { useContext, useEffect, useMemo, useState } from "react";
import { PipelineEditorContext, PipelineStageEditorContext } from "../../../../context/Contexts";
import { debounce } from "../../../../utils/debounce";
import { computeApi } from "../../../../api/computeApi";
import { useAuth } from "../../../../hooks/useAuth";
import { useProjectId } from "../../../../hooks/useProjectId";
//import SplitterLayout from "react-splitter-layout";
import SplitterLayout from "../../../extlib/react-splitter-layout/SplitterLayout";
import "./EditMeasures.scss";
import { FieldSearch } from "../../../common/FieldSearch";
import { GetDistinctValues } from "../../../../utils/FieldValuesUtil";
import { newId } from "../../../../utils/uuid";
import { SelectFieldsContext, SelectFieldsList } from "../../../common/SelectFieldsList";
import { ControlledCodeEditorFillContainer } from "../../../code-editor/CodeEditor";
import JSON5 from "json5";
import Popup from "reactjs-popup";
import { EditAggItems, EditAggItemsContext } from "./EditAggItems";
import { InspectionContext, StagesInspectButton } from "../inspector/StagesInspectButton";
import { autoTrim } from "../../../data-sources/ViewFieldsHelper";

export const INSPECTION_NOTES = {
    INSP: "Using fields from inspection. Clear inspection to use data source meta instead.",
    DS: "Using fields from data source. Inspect to find actual fields instead."
}

const AGG_TABS = [
    { id: "basic", label: "Descriptives" },
    { id: "pct", label: "Percentages" },
    { id: "freq", label: "Frequencies" }
]

const AGG_VIEWS = [
    { id: "list", label: "List" },
    { id: "json", label: "JSON" },
]

const DESCRIPTIVES = [
    { id: "n", label: "Valid count (n)", format: ",.0f", syntaxFunc: (name) => `validcount(${name})` },
    { id: "mean", label: "Average (mean)", syntaxFunc: (name) => `mean(${name})` },
    //{ id: "median", label: "Median", syntaxFunc: (name) => `median(${name})` },
    { id: "sum", label: "Sum", syntaxFunc: (name) => `sum(${name})` },
    { id: "min", label: "Min", syntaxFunc: (name) => `min(${name})` },
    { id: "max", label: "Max", syntaxFunc: (name) => `max(${name})` },
    //{ id: "variance", label: "Variance", syntaxFunc: (name) => `var(${name})` },
    //{ id: "stdev", label: "Standard deviation", syntaxFunc: (name) => `stdev(${name})` },
    //{ id: "effss", label: "Effective sample size", syntaxFunc: (name) => `effss(${name})` },
]


const find_matches = (fields, searchTerms) => {
    try{
        // todo: this regex isn't perfect. probably fix it by removing the regexp and doing manually
        const regex = new RegExp(searchTerms.map(t => `(?=.*${t})`).join( "" ), "i");
        return fields.filter(f => regex.test(`[${f.name}] :${f.type} ${f.label}`));
    }
    catch{
        return [];
    }
}

export const EditMeasures = (props) => {

    const auth = useAuth();
    const { project_id, subscription_id } = useProjectId();

    const pipelineContext = useContext(PipelineEditorContext);
    const stageContext = useContext(PipelineStageEditorContext);
    const inspectionContext = useContext(InspectionContext);
    
    const [searchTerms, setSearchTerms] = useState(null);

    const [aggTab, setAggTab] = useState(AGG_TABS[0].id);
    const [aggView, setAggView] = useState(AGG_VIEWS[0].id);
    const [selFieldNames, setSelFieldNames] = useState([]);
    const [selAvailableAggs, setSelAvailableAggs] = useState([]);
    const dim = props.dim;
    const [selAggs, setSelAggs] = useState(dim?.items);
    const [axisId, setAxisId] = useState(dim?.id);
    const [selAggIds, setSelAggIds] = useState([]);
    const [aggSyntax, setAggSyntax] = useState(null);
    const [editAggItemsContext, setEditAggItemsContext] = useState(null);

    const [aggItemsEditorOpen, setAggItemsEditorOpen] = useState(false);

    const stage = stageContext.stage;
    const priorStages = stageContext.priorStages;

    const { dataSource, revision } = pipelineContext;

    //const _fields = revision?.fields;
    const __inspection = inspectionContext?.inspection;
    const _fields = useMemo(() => {
        return __inspection ? Object.keys(__inspection)?.map(name => ({ name })) : revision?.fields;
    }, [__inspection]) 
    

    const fields = searchTerms?.length > 0 ? find_matches(_fields, searchTerms) : _fields;

    const fieldDic = useMemo(() => {
        return _fields?.reduce((accum, f) => {
            accum[f.name] = f;
            return accum;
        }, {});
    }, [_fields]);

    const inspection = __inspection || revision?.inspection;

    // calculate values
    const selFields = useMemo(() => {
        return selFieldNames?.map(name => fieldDic?.[name]);
    }, [selFieldNames]);

    const distinctValues = useMemo(() => {
        return GetDistinctValues(selFields, inspection);
    }, [selFields])

    const save = () => {
        console.log("save from within editmeasures", axisId);

        props.saveDim?.(
            dim,
            {
            ...dim,
            id: axisId,
            items: selAggs.map((agg, idx) => {
                let retAgg = { ...agg };
                delete retAgg.__fieldName;
                return retAgg;
            })
            }
        );
    }

    const okay = () => {
        save();
        props.close?.(true);
    }

    const cancel = () => {
        props.close?.();
    }

    const addAggs = (newAggs) => {
        setSelAggs([...(selAggs || []), ...(newAggs || [])]);
    }

    const deleteSel = () => {

        // if nothing is selected, select everything so a second click can delete all of them
        if( !selAggIds?.length ){
            setSelAggIds(selAggs.map(agg => agg._id));
        }
        else{
            const newAggs = selAggs.filter(agg => !selAggIds.includes(agg._id));
            setSelAggs(newAggs);
            setSelAggIds([]);
        }
    }

    const removeFieldName = (label, fieldName) => {
        if( !label || label?.trim?.() === "") return label;
        let newLabel = label;
        if( label?.startsWith?.(fieldName + ": ") ){
            newLabel = label.substring(fieldName.length + 2 );
        }
        else if( label?.startsWith?.(fieldName) ){
            newLabel = label.substring(fieldName.length);
        }

        if( newLabel?.trim?.() != "" ) return newLabel;
        return label;
    }

    const moveRight = () => {
        const singleFieldSelected = selFields?.length === 1;
        const multipleFieldsSelected = selFields?.length > 1;

        if( aggTab === "basic" ){
            
            const aggItems = selAvailableAggs.map(aa => DESCRIPTIVES.find(d => d.id === aa));

            const singleAggSelected = aggItems?.length === 1;
            //const multipleAggsSelected = aggItems?.length > 1;

            let newAggs = [];
            selFields?.forEach(selField => {
                aggItems?.forEach(aggItem => {
                    if( selField && aggItem ){
                        const newAgg = {
                            _id: newId(),
                            label: removeFieldName(
                                singleFieldSelected ? aggItem.label : 
                                singleAggSelected ? (selField.label || selField.name) :
                                `${(selField.label || selField.name)}: ${aggItem.label}`,
                                selField.name
                            )
                            ,
                            syntax: aggItem.syntaxFunc?.(selField.name),
                            //format: aggItem.format
                            __fieldName: selField.name
                        };
                        newAggs.push(newAgg);
                    }
                })
            })
            addAggs(newAggs);
            setSelAggIds(newAggs.map(agg => agg._id));
        }
        else if( aggTab === "pct" || aggTab === "freq" ){

            const aggItems = selAvailableAggs.map(aa => distinctValues.find(dv => `${dv.val}` === aa));
            console.log("aggItems", aggItems);

            const singleAggSelected = aggItems?.length === 1;
            const multipleAggsSelected = aggItems?.length > 1;

            const funcName = aggTab === "pct" ? "pct" :
                aggTab === "freq" ? "freq" : 
                "error";
            const format = aggTab === "pct" ? ".1%" : ",.0f";

            let newAggs = [];
            selFields?.forEach(selField => {
                aggItems?.forEach(aggItem => {
                    if( selField && aggItem ){
                        const newAgg = {
                            _id: newId(),
                            label: removeFieldName(
                                singleFieldSelected ? aggItem.label : 
                                singleAggSelected ? (selField.label || selField.name) :
                                `${(selField.label || selField.name)}: ${aggItem.label}`,
                                selField.name
                            )
                            ,
                            syntax: typeof(aggItem.val) === "string" ? 
                                `${funcName}(${selField.name}=="${aggItem.val}")`
                                : `${funcName}(${selField.name}==${aggItem.val})`
                            ,
                            //format
                            __fieldName: selField.name
                        };
                        newAggs.push(newAgg);
                    }
                })
            })
            addAggs(newAggs);
            setSelAggIds(newAggs.map(agg => agg._id));
        }
    }

    const moveRightCombineDiscrete = () => {
        const singleFieldSelected = selFields?.length === 1;
        const multipleFieldsSelected = selFields?.length > 1;

        if( aggTab === "basic" ){
            // nothing to do            
        }
        else if( aggTab === "pct" || aggTab === "freq" ){

            const aggItems = selAvailableAggs.map(aa => distinctValues.find(dv => `${dv.val}` === aa));
            
            const singleAggSelected = aggItems?.length === 1;
            const multipleAggsSelected = aggItems?.length > 1;

            const funcName = aggTab === "pct" ? "pct" :
                aggTab === "freq" ? "freq" : 
                "error";
            
            let newAggs = [];
            selFields?.forEach(selField => {

                const terms = [];
                aggItems?.forEach(aggItem => {
                    if( selField && aggItem ){
                        const syntax = typeof(aggItem.val) === "string" ? 
                            `"${aggItem.val}"`
                            : `${aggItem.val}`;
                        terms.push(syntax);
                    }
                })

                const newAgg = {
                    _id: newId(),
                    label: removeFieldName(
                        //singleFieldSelected ? aggItem.label : 
                        //singleAggSelected ? (selField.label || selField.name) :
                        `${(selField.label || selField.name)}: (${terms.join(",")})`,
                        selField.name
                    )
                    ,
                    syntax: `${funcName}(${selField.name} in (${terms.join(",")}))`,
                    //format
                    __fieldName: selField.name
                };
                if( terms.length ){
                    newAggs.push(newAgg);
                }

                
            })
            addAggs(newAggs);
            setSelAggIds(newAggs.map(agg => agg._id));
        }
    }
    
    const createOnClick = (getSyntax, applySyntax, options) => {

        return () => {

            const aggs = selAggs
                .map((agg, __index) => ({ ...agg, __index }))
                .filter(agg => selAggIds.includes(agg._id));

            const strs = aggs?.map(getSyntax);
            const str = JSON.stringify(strs, null, 4);

            setEditAggItemsContext({
                syntax: str,
                options,
                items: aggs,
                saveSyntax: (newStr) => {

                    const newStrs = JSON5.parse(newStr);

                    let newAggs = [...selAggs];
                    let selIds = [];
                    aggs.forEach((agg, idx) => {
                        let newItem = {
                            ...agg,
                            //label: labels?.[idx]?.trim()
                        }
                        applySyntax(newItem, newStrs[idx]);
                        if( newItem.__replace ){
                            newItem = newItem.__replace;
                        }
                        delete newItem.__index;
                        newAggs[agg.__index] = newItem;
                        selIds.push(newAggs[agg.__index]._id);
                    })
                    console.log('selIds', selIds);
                    setSelAggs(newAggs);
                    setSelAggIds(selIds);
                }
            })
            setAggItemsEditorOpen(true);
        };
    }

    const autoTrimLabels = () => {
        
        console.log("selAggs", selAggs);
        
        const aggs = selAggs
            .map((agg, __index) => ({ ...agg, __index }))
            .filter(agg => selAggIds.includes(agg._id));

        console.log("aggs", aggs);


        const strs = aggs?.map(agg => {
            return agg.label?.replace?.(agg.__fieldName, "[var]");
        });
        const newStrs = autoTrim(strs);
        for( let i = 0; i < aggs.length; i++ ){
            const agg = aggs[i];
            selAggs[agg.__index].label = newStrs[i];
        }
        
        setSelAggs(selAggs.map(agg => agg)); // to refresh the listbox
        
    }

    return <div className="edit-measures">
        
        <div className="content1">
            <SplitterLayout
                vertical={false}
                
                primaryIndex={1}
                percentage={true}
                xprimaryMinSize={50}
                secondaryInitialSize={33}
                
                onSecondaryPaneSizeChange={(secondaryPaneSize) => {
                //setPreviewSize(secondaryPaneSize);
                }}
            >

                <div className="input-section">
                    <div className="axis-id">
                        <span className="label">
                            Measures Axis Id:
                        </span>
                        <input type="text" value={axisId} onChange={ev => setAxisId(ev.target.value)} />
                    </div>
                    <div className="title">
                        Available Field(s)
                    </div>
                    <div className="actions">
                        <StagesInspectButton />
                        <div className="inspection-note">
                            <i className="fas fa-warning" />&nbsp;
                            { __inspection ? INSPECTION_NOTES.INSP : INSPECTION_NOTES.DS }
                        </div> 
                    </div>
                    <div className="list">
                        <SelectFieldsContext.Provider value={{ 
                            fields: _fields,
                            selectedFieldNames: selFieldNames,
                            setSelectedFieldNames: setSelFieldNames
                        }}>
                            <SelectFieldsList />
                        </SelectFieldsContext.Provider>
                    </div>
                </div>


                <SplitterLayout
                    vertical={false}
                    primaryIndex={1}
                    percentage={true}
                    xprimaryMinSize={50}
                    secondaryInitialSize={50}
                >
                    

                    <div className="input-section">
                        <div className="title">
                            Available Aggregations
                        </div>

                        <div className="arrows">
                            <span />

                            <span className={`btn1 inner`}
                                onClick={() => moveRight()}
                            >
                                <i className="fas fa-arrow-right fa-2x"/>
                            </span>
                            <Popup
                                position={"right top"}
                                arrow={false}
                                contentStyle={{ width: 240, marginLeft: 5 }}
                                trigger={
                                    <span className={`btn1 inner`} style={{ fontSize: 10 }}>
                                        <i className="fas fa-arrow-right" />
                                        <i className="fas fa-ellipsis blk" />
                                    </span>
                                }
                            >
                                {close => <div className="popup-menu">
                                    <div className="menu-item-group">
                                        <div className="menu-item disabled">
                                            Replace missings (increase base)
                                        </div>
                                        <div className="menu-item disabled">
                                            Exclude value(s) (reduce base)
                                        </div>
                                    </div>
                                    <div className="separator" />
                                    <div className="menu-item-group">
                                        <div className="menu-item" onClick={() => {
                                            close();
                                            moveRightCombineDiscrete();
                                        }}>
                                            {/* <i className="fal fa-brackets-curly icon" /> */}
                                            Combine as discrete values
                                        </div>
                                        <div className="menu-item disabled">
                                            {/* <i className="fal fa-brackets-square icon" /> */}
                                            Combine as range
                                        </div>
                                    </div>
                                    <div className="separator" />
                                    <div className="menu-item-group">
                                        <div className="menu-item disabled">
                                            Multi-var combine using OR
                                        </div>
                                    </div>
                                </div>}
                            </Popup>
                            <span className={`btn1`} style={{ fontSize: 10 }} 
                                onClick={() => deleteSel()}
                            >
                                <i className="fas fa-arrow-left" />
                                {selAggIds.length > 0 ? <i key={1} className="fas fa-trash-alt blk" /> : <i key={2} className="fal fa-trash-alt blk" />}    
                            </span>
                            <span />
                            
                            
                        </div>
                        <div className="actions">
                            <div className="section-title">
                                {AGG_TABS.map(tab =>
                                    <div 
                                        key={tab.id} 
                                        className={`tab ${aggTab === tab.id ? "sel" : ""}`}
                                        onClick={() => {
                                            setAggTab(tab.id)
                                            setSelAvailableAggs([]);
                                        }}
                                    >
                                        {tab.label}
                                    </div>    
                                )}
                            </div>
                        </div>
                        <div className="list">
                            <select
                                multiple
                                className="aggs-select"
                                value={selAvailableAggs}
                                onChange={(ev) => {
                                    const options = ev.target.options;
                                    let newVal = [];
                                    for(let option of options){
                                        if( option.selected ){
                                            newVal.push(option.value);
                                        }
                                    }
                                    setSelAvailableAggs(newVal);
                                }}
                            >
                                {(aggTab === "basic") && selFieldNames?.length ?
                                    DESCRIPTIVES.map(d =>
                                        <option key={d.id} value={d.id} className="item agg">
                                            {d.label}
                                        </option>
                                    )
                                    : null
                                }
                                {(aggTab === "pct" || aggTab === "freq") ? 
                                    distinctValues?.map(dv =>
                                        <option 
                                            key={dv.val} 
                                            value={dv.val} 
                                            className={`item ${aggTab}`}
                                            >
                                            [{dv.val}] {dv.label}
                                        </option>
                                    )
                                    : null
                                }
                            </select>
                        </div>
                    </div>


                    <div className="input-section">
                        <div className="title">
                            Selected Aggregations
                        </div>
                        <Popup
                            modal
                            closeOnDocumentClick={false}
                            closeOnEscape={false}
                            open={aggItemsEditorOpen}
                        >
                            <EditAggItemsContext.Provider value={editAggItemsContext}>
                                <EditAggItems close={() => setAggItemsEditorOpen(false)} />
                            </EditAggItemsContext.Provider>
                        </Popup>
                        <div className="actions" style={{ margin: "0px 5px" }}>
                            <span className="btn2-strip" >
                                <span className="btn2" 
                                    onClick={createOnClick(
                                        (agg) => agg.label,
                                        (agg, data) => { agg.label = data },
                                        { isStringArray: true, compareProp: "label" }
                                    )}
                                >
                                    Label
                                </span>
                                <span className="btn2 icon" onClick={() => autoTrimLabels()}>
                                    Auto-trim
                                </span>
                                <span className="btn2" onClick={createOnClick(
                                        (agg) => agg.id,
                                        (agg, data) => { agg.id = data },
                                        { isStringArray: true, compareProp: "id" }
                                    )}>
                                    id
                                </span>
                                <span className="btn2" onClick={createOnClick(
                                        (agg) => agg.syntax,
                                        (agg, data) => { agg.syntax = data },
                                        { isStringArray: true, compareProp: "syntax" }
                                    )}>
                                    Syntax
                                </span>
                                {/* <span className="btn2" onClick={createOnClick(
                                        (agg) => agg.format,
                                        (agg, data) => { agg.format = data },
                                        { isStringArray: true, compareProp: "fmt" }
                                    )}>
                                    Fmt
                                </span> */}
                                <span className="btn2" onClick={createOnClick(
                                        (agg) => {
                                            let newAgg = { ...agg };
                                            delete newAgg._id;
                                            delete newAgg.__index;
                                            return newAgg;
                                        },
                                        (agg, data) => { agg.__replace = { ...data, _id: newId() } }
                                    )}>
                                    ...
                                </span>
                                <span style={{ flexGrow: 1 }}>
                                </span>
                            
                                <span className="btn2 icon" onClick={() => null}>
                                    <i className="fal fa-copy"/>
                                </span>
                                {/* <span className="btn2" onClick={() => null}>
                                    <i className="fal fa-paste"/> Paste
                                </span> */}
                                {/* <span className="btn2 icon" onClick={() => null}>
                                    <i className="fal fa-arrow-down-a-z"/>
                                </span> */}
                                <span className="btn2 icon" onClick={() => null}>
                                    <i className="fal fa-arrow-up"/>
                                </span>
                                <span className="btn2 icon" onClick={() => null}>
                                    <i className="fal fa-arrow-down"/>
                                </span>
                                <span className="btn2 icon" onClick={() => null}>
                                    <i className="fal fa-ellipsis"/>
                                </span>
                                <span className="btn2 icon"
                                    onClick={() => deleteSel()}
                                >
                                    {selAggIds.length > 0 ? <i key={1} className="fa fa-trash-alt" /> : <i key={2} className="fal fa-trash-alt" />}
                                </span>
                            </span>
                        </div>
                        <div className="list" style={{ borderTop: "1px solid #cfcfcf" }}>
                            <select
                                multiple
                                className="aggs-select"
                                value={selAggIds}
                                onChange={(ev) => {
                                    const options = ev.target.options;
                                    let newVal = [];
                                    for(let option of options){
                                        if( option.selected ){
                                            newVal.push(option.value);
                                        }
                                    }
                                    setSelAggIds(newVal);
                                }}
                                onKeyDown={(ev) => {
                                    if( ev.code === "Delete" ){
                                        deleteSel();
                                    }
                                }}
                            >
                                {selAggs.map(agg =>
                                    <option 
                                        key={agg._id} 
                                        value={agg._id} 
                                        className="item agg"
                                        data-xcontent={agg.syntax}
                                    >
                                        {agg.id && `[${agg.id}] `}
                                        {agg.label}
                                    </option>
                                )}
                            </select>
                        </div>
                        {true && 
                            <div className="footer">
                                {selAggIds.length === 1 ? 
                                    <span>
                                        {selAggs?.find(a => a._id == selAggIds[0])?.syntax || "[syntax missing]"}
                                    </span> :
                                    <span>
                                        {selAggIds?.length} measure(s) selected
                                    </span>
                                }
                            </div>
                        }
                    </div>
                </SplitterLayout>
                
            </SplitterLayout>
        </div>
        <div className="bottom-section">
            
                <span className="btn action margin-right" onClick={() => okay()}>
                    OK
                </span>

                <span className="btn action" onClick={() => cancel()}>
                    Cancel
                </span>
            
        </div>
        
    </div>
    
}