import { createContext, useContext, useEffect, useState } from "react";
import { FieldSearch } from "./FieldSearch";
import "./SelectFieldsList.scss";

export const SelectFieldsContext = createContext();

const find_matches = (fields, searchTerms) => {
    try{
        // todo: this regex isn't perfect. probably fix it by removing the regexp and doing manually
        const regex = new RegExp(searchTerms.map(t => `(?=.*${t})`).join( "" ), "i");
        return fields.filter(f => regex.test(`[${f.name}] :${f.type} ${f.label}`));
    }
    catch{
        return [];
    }
}

export const SelectFieldsList = (props) => {

    const [searchTerms, setSearchTerms] = useState(null);
    //const [selFieldNames, setSelFieldNames] = useState([]);

    const context = useContext(SelectFieldsContext);
    const fields = context?.fields;
    const selFieldNames = context?.selectedFieldNames;
    const setSelFieldNames = context?.setSelectedFieldNames;
    
    const matchingFields = searchTerms?.length > 0 ? find_matches(fields, searchTerms) : fields;

    // useEffect(() => {
    //     console.log("setting selection");
    //     context?.setSelectedFieldNames(selFieldNames);
    // }, [selFieldNames]);
    
    

    return <div className="select-fields-list">

        <div className="sfl-search">
            <FieldSearch onChange={(ev) => {
                setSearchTerms(ev.terms);
            }} />
        </div>
        
        <div className="sfl-list">
            <select
                multiple
                className="fields-select"
                style={{ border: 0 }}
                value={selFieldNames || []}
                onChange={(ev) => {
                    const options = ev.target.options;
                    let newVal = [];
                    for(let option of options){
                        if( option.selected ){
                            newVal.push(option.value);
                        }
                    }
                    setSelFieldNames?.(newVal);
                }}
            >
                {matchingFields?.map(field => {
                    const fieldType = field.type === "string" || field.type === 1 || field.type?.startsWith?.("varchar") ? "string" : "numeric";
                    return <option 
                        key={field.name} 
                        className={fieldType} 
                        value={field.name}
                        data-content={field.label}
                    >
                        {field.name}
                    </option>
                })}
            </select>
        </div>

        <div className="sfl-footer">
            <span className='info-label'>
                {selFieldNames?.length} field(s) selected
            </span>
            <span className="clear-btn"
                onClick={() => setSelFieldNames?.([])}
            >
                Clear
            </span>
        </div>
    </div>;
}