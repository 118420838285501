import React, { useState } from "react";
import { EditContentItemWrapper } from "../../edit/EditContentItemWrapper";
import { ErrorBoundary } from "../../error-boundary/ErrorBoundary";
import { LoadContentItem } from "../LoadContentItem";
import "./ViewCarousel.scss";





export const ViewCarousel = (props) => {

    const item = props.content_item;

    const [selectedIndex, setSelectedIndex] = useState(0);
    let style = {
        ...item.style
    }
    let contentStyle = {
        ...item.contentStyle
    }

    const selectedChild = item?.children?.[selectedIndex] || item?.children?.[0];

    const content = <EditContentItemWrapper {...props} actionsPosition="above">
        <div className="view-carousel" style={style}>
            {item && (!item?.children || item?.children?.length === 0) ? <div className="centered-message">
                [blank carousel]
            </div> : null}
            <div className="carousel-content" style={contentStyle}>
                {selectedChild ? 
                    <LoadContentItem
                        level={props.level + 1}
                        id={selectedChild.id}
                        edit={props.edit}
                        pType={"CAROUSEL"}
                    />
                    : null}
            </div>
            <div className="flipper">
                {item?.children?.map((child, idx) => 
                    <span 
                        className="btn-dot" 
                        onClick={() => setSelectedIndex(idx)}
                    >
                        <i className={idx === selectedIndex ? 'fas fa-circle' : 'fal fa-circle'} />
                    </span>
                )}
            </div>
        </div>
    </EditContentItemWrapper>
    
    return <ErrorBoundary>{content}</ErrorBoundary>
}
