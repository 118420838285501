import { getContentTypes as mstudio_getContentTypes } from "../content-mstudio/_Registry";
import { getCreators as mstudio_getCreators } from "../content-mstudio/_Registry";

export const registryDict = {};
export const registryArr = [];
export const creatorsArr = [];

// register mstudio
const mstudio_entries = mstudio_getContentTypes();
for( let entry of mstudio_entries ){
    registryDict[`mstudio.${entry.id}`] = entry;
    registryArr.push({ ...entry, id: `mstudio.${entry.id}` });
}
console.log("registry", registryDict);

const mstudio_creators = mstudio_getCreators();
for( let entry of mstudio_creators ){
    creatorsArr.push({ ...entry, ctype: `mstudio.${entry.ctype}`})
}


export const GetCreatorsArr = () => creatorsArr;

export const GetRegistryArr = () => {
    return registryArr;
}

export const GetCTypeGenerator = (name) => {
    // parse namespace
    // const idxFirstDot = name.indexOf(".");
    // const ns = name.substring(0, idxFirstDot);
    // const local_name = name.substring(idxFirstDot+1);

    return registryDict[name]?.generator;
}


export const CreateContent = (creator) => {
    console.log("creator", creator);
    
    // find ctype
    const ctype = creator.ctype;
    const label = creator.label;
    //const ctypeEntry = registryDict[ctype];

    const obj = creator.createItem();

    return {
        ...obj,
        ctype,
        label
    };
}