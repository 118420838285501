import { useContext, useState } from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { controllers } from "../api/controllers";
import { constructs } from "../api/contructs";
import { createSyncedApiHook } from "../api/createSyncedApiHook";
import { AuthContext } from "../context/Contexts";
import { useAuth } from "./useAuth";
import { useProjectId } from "./useProjectId";
import * as globals from "../globals";
import * as restMethods from "../api/restMethods";
import JSON5 from "json5";

const construct = constructs.project;

export const useProjectsApi = createSyncedApiHook(construct);

export const useProject = (_subscription_id, project_id) => {

    const { project_id: fallback_project_id, subscription_id: fallback_subscription_id } = useProjectId();
    
    const id = project_id || fallback_project_id;
    const subscription_id = _subscription_id || fallback_subscription_id;

    const auth = useAuth();

    const dispatch = useDispatch();
    const controller = controllers.project;

    const { project, loading, error } = useSelector(state => ({
        project: state.projects.dict?.[id],
        loading: state.projects.entryLoading?.[id],
        error: state.projects.entryError?.[id]
    }), shallowEqual);

    useEffect(() => {
        if (id && !project && !loading && !error) {
            dispatch({ type: "PROJECT_SET_LOADING", id });
            (async () => {
                try {
                    let value = await controller.auth_getItem(auth, { subscription_id }, id);
                    dispatch({ type: "PROJECT_SET", id, value });

                    // todo: load selection states?
                    //console.log("TODO: LOAD SELECTION STATES FOR " + id)
                }
                catch (err) {
                    dispatch({ type: "PROJECT_SET", id, value: null, error: err.message });
                }
            })();
        }
    }, [subscription_id, id]);

    return [
        project,
        loading,
        error
    ];

}

export const minifyProject = (project) => {
    const retVal = { ...project };
    delete retVal.settings_str;
    delete retVal.schema_str;
    return retVal;
}

export const useProjects = (subscription_id) => {

    //const authContext = useContext(AuthContext);
    //const subscription_id = authContext.selectedSubscription?.id;

    const dispatch = useDispatch();
    const auth = useAuth();
    const controller = controllers.project;

    const { entries, loading, error } = useSelector(state => ({
        entries: state.projects.entries,
        loading: state.projects.loading,
        error: state.projects.error
    }), shallowEqual);

    useEffect(() => {
        if (!entries && !loading && !error) {
            dispatch({ type: "PROJECTS_SET_LOADING_ENTRIES" });
            (async () => {
                try {
                    const entries = await controller.auth_getItems(auth, { subscription_id });
                    dispatch({ type: "PROJECTS_SET_ENTRIES", entries, extra: { subscription_id } });
                }
                catch (err) {
                    dispatch({ type: "PROJECTS_SET_ENTRIES", entries: null, error: err.message, extra: { subscription_id } });
                }
            })();
        }
    });

    return [
        entries,
        loading,
        error,
    ];

}



export const useAllProjects = () => {

    // TODO: could move this into REDUX to eliminate fetches each time?
    // But if so, the websocket would need to notify clients when new projects are added
    // But the websocket can't do that because websockets are subscription-specific or project-specific and not global yet
    // so if user isn't attached to a subscription, they wouldn't get notified

    const auth = useAuth();
    const [entries, setEntries] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [refreshCounter, setRefreshCounter] = useState(0);

    useEffect(() => {

        const f = async () => {
            setLoading(true);
            try {
                let url = `${globals.apiRoot}/projects`;
                const response = await restMethods.getJson(auth, url);
                const entries = await response.json();
                setEntries(entries);
            }
            catch (err) {
                console.error(err);
                setError(err.message);
                setEntries([]);
            }
            setLoading(false);
        }
        f();

    }, [refreshCounter]);

    const refresh = () => setRefreshCounter(refreshCounter+1);
    
    return [
        entries,
        loading,
        error,
        refresh
    ];

}






