import { io } from "socket.io-client";
import { constructs } from "./contructs";
import * as globals from "../globals";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../hooks/useAuth";


export const UseProjectSocket = (subscription_id, project_id) => {

    const [SOCKET, setSOCKET] = useState(null);
    const dispatch = useDispatch();
    const auth = useAuth();

    useEffect(() => {
        if( !subscription_id || !project_id ) return null;

        // disconnect from previous socket if exists
        if( SOCKET ){
            SOCKET.disconnect();
        }

        // establish socket
        //const socket = io(`${globals.webSocketEndpoint}/project/${subscription_id}/${project_id}`);
        const socket = io(
            `${globals.webSocketEndpoint}/project/${subscription_id}/${project_id}`,
            { 
                auth: {
                    "authorization": auth.token
                }
            }
        );

        socket.on("connect", () => {
            console.log(`** Project[${project_id}] socket ${socket.id} connected=${socket.connected}`);
            setSOCKET(socket);
        })

        socket.on("connect_error", (err) => {
            console.log(`** Project[${project_id}] socket connection error`, err);
        });
        
        socket.on("disconnect", () => {
            console.log(`** Project[${project_id}] socket disconnected`);
            setSOCKET(null);
        })

        // notification handling
        socket.on("notif", (payload) => {

            if( payload?.action === "NEW_CLIENT_CONNECTED" ){
                if( socket.id !== payload.socket_id ){
                    console.log(`** New client ${payload.socket_id} connected to project (not me ${socket.id})`);
                }
                const action = {
                    type: `PROJECT_SOCKET_UPDATE`,
                    patch: {
                        nbClients: payload.nbClients
                    }
                };
                dispatch(action);
            }
            else if( payload?.action === "CLIENT_DISCONNECTED" ){
                if( socket.id !== payload.socket_id ){
                    console.log(`** Client ${payload.socket_id} disconnected from project ${project_id}`);
                }
                const action = {
                    type: `PROJECT_SOCKET_UPDATE`,
                    patch: {
                        nbClients: payload.nbClients
                    }
                }
                dispatch(action);
            }
            else if( payload.action === "NEW_ITEM" ){

                // ignore notification if self sent
                if( payload.sender_id === socket.id ) return;

                console.log('handling NEW_ITEM', payload);
                const construct = constructs[payload.item_type];
                if( construct ){
                    const action = {
                        type: `${construct.actionNamePlural}_ADD_ENTRY`,
                        entry: {
                            ...construct.parseEntry(payload.item),
                            id: payload.item_id
                        }
                        // it doesn't add the item into the dict, but it seems like it could
                    };
                    dispatch(action);
                }

            }
            else if( payload.action === "DELETE_ITEM" ){

                // ignore notification if self sent
                if( payload.sender_id === socket.id ) return;

                console.log('handling DELETE_ITEM', payload);
                const construct = constructs[payload.item_type];
                if( construct ){
                    const action = {
                        type: `${construct.actionName}_DELETE`,
                        id: payload.item_id
                    };
                    dispatch(action);
                }

            }
            else if( payload.action === "REPLACE_ITEM" ){

                // ignore notification if self sent
                if( payload.sender_id === socket.id ) return;
                    
                console.log("handling project REPLACE_ITEM", payload);
                const construct = constructs[payload.item_type];
                if( construct ){
                    const value = {
                        ...payload.item,
                        id: payload.item_id
                    };
                    const action = {
                        type: `${construct.actionName}_SET`,
                        id: payload.item_id,
                        entry: construct.parseEntry(value),
                        value
                    };
                    dispatch(action);
                }
            }
            else if( payload.action === "PATCH_ITEM" ){

                // ignore notification if self sent
                if( payload.sender_id === socket.id ) return;

                console.log("handling project PATCH_ITEM", payload);
                const construct = constructs[payload.item_type];
                if( construct ){
                    const value = {
                        ...payload.item,
                        id: payload.item_id
                    };
                    const action = {
                        type: `${construct.actionName}_PATCH`,
                        id: payload.item_id,
                        //entry: construct.parseEntry(value), // may need to do something like this for entry-specific patches (e.g., label)
                        ...(payload.parent_id ? { parent_id : payload.parent_id } : null),
                        value
                    };
                    dispatch(action);
                }

            }
            else if( payload.action === "PATCH_CHILD_ORDER" ){

                // ignore notification if self sent
                if( payload.sender_id === socket.id ) return;

                console.log("handling project PATCH_CHILD_ORDER", payload);
                const construct = constructs[payload.item_type];
                if( construct ){
                    const value = {
                        ...payload.item,
                        id: payload.item_id
                    };
                    const action = {
                        type: `${construct.actionName}_PATCH_CHILD_ORDER`,
                        id: payload.item_id,
                        //entry: construct.parseEntry(value), // may need to do something like this for entry-specific patches (e.g., label)
                        //...(payload.parent_id ? { parent_id : payload.parent_id } : null),
                        ordered_ids: payload.ordered_ids
                    };
                    console.log("action", action);
                    dispatch(action);
                }

            }
            else{
                console.log(`Project[${project_id}] socket UNKNOWN notif received:`, payload);;
            }
        })

        // cleanup
        return () => {
            console.log(`** Project[${project_id}] socket[${socket?.id}] cleanup`);
            socket?.disconnect();
            setSOCKET(null);
        }

    }, [subscription_id, project_id])


    return SOCKET?.id;

}