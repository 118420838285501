import { controllers } from "../api/controllers";
import { constructs } from "../api/contructs";
import { createSyncedApiHook } from "../api/createSyncedApiHook";
import { createUseItemHook, createUseItemsHook } from "./createUseItemsHook";
import { useAuth } from "./useAuth";
import { useProjectId } from "./useProjectId";

const construct = constructs["data-source"];

export const useDataSource = createUseItemHook(construct);
export const useDataSources = createUseItemsHook(construct);
export const useDataSourcesApi = createSyncedApiHook(construct);

export const useDataSourceApi = (id) => {

    const {project_id, subscription_id, project_socket_id} = useProjectId();
    const auth = useAuth();
    const controller = controllers["data-source"];
    const socket_id = project_socket_id;
    

    return {

        createRevision: async (body) => {
            return await controller.auth_createItemRevision(auth, { subscription_id, project_id, socket_id }, id, body );
        },

        getRevisions: async () => {
            return await controller.auth_getItemRevisions(auth, { subscription_id, project_id }, id );
        },

        patchRevision: async (revision, body) => {
            return await controller.auth_patchItemRevision(auth, { subscription_id, project_id, socket_id }, id, revision, body);
        },

        deleteRevision: async (revision) => {
            return await controller.auth_deleteItemRevision(auth, { subscription_id, project_id, socket_id }, id, revision)
        },

        getFields: async () => {
            // todo: create new server method for this
            
            const rows = await controller.auth_getTable(auth, { subscription_id, project_id }, id);
            const fields = Object.keys(rows?.[0] || {}).map(key => ({
                name: key,
                key: key,
                type: "number",
                valueFormat: ".6f"
            }))
            return fields;
        },

        getPreview: async (fields) => {
            const rows = await controller.auth_getTable(auth, { subscription_id, project_id }, id);
            return rows;
        },
      
    }
}
