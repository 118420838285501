

export const FIELD_STRINGIFIER = (fied) => `${fied.name} ${fied.label}`;

export const getLowerCaseSearchTerms = (searchString) => {
    if( !searchString ) return null;
    let lower = searchString.toLowerCase();
    let pcs = lower.split(" ");
    return pcs.filter(pc => pc); // remove empty entries
}

export const isMatch = (item, lowerCaseSearchTerms, getItemText) => {

    if( !lowerCaseSearchTerms ) return true;
    if( !lowerCaseSearchTerms.length ) return true;

    const text = getItemText(item)?.toLowerCase();

    for( let searchTerm of lowerCaseSearchTerms ){
        if( !text?.includes(searchTerm) ){
            return false;
        }
    }
    return true;

}