

export const GetDistinctValues = (fields, inspection) => {

    if( !fields ) return null;

    let values = [];

    fields?.forEach(field => {

        // get declared values
        if( field?.valueLabels ){
            field.valueLabels?.forEach(vl => {
                let f = values.find(v => v.val === vl.val);
                if( !f ){
                    values.push({ ...vl });
                }
            })
        }

        // get inspected values
        const iEntry = inspection?.[field?.name];
        if( iEntry ){
            iEntry.i?.forEach(vl => {
                let f = values.find(v => v.val === vl.v);
                if( !f ){
                    values.push({ val: vl.v, label: vl.v });
                }
            })
        }
        
    })
    
    values = values.sort((a, b) => a.val - b.val);

    return values;

}