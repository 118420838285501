import { constructs } from "../api/contructs";
import { createSyncedApiHook } from "../api/createSyncedApiHook";
import * as restMethods from "../api/restMethods";
import { createUseItemHook, createUseItemsHook } from "./createUseItemsHook";
import * as globals from "../globals";

const construct = constructs["model"];

export const useModel = createUseItemHook(construct);
export const useModels = createUseItemsHook(construct);
export const useModelsApi = createSyncedApiHook(construct);

export const modelComputeApi = {

    compute: async (auth, subscription_id, project_id, model_id, query) => {

        const body = query;
        const url = `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/model/${model_id}/compute`;
        const response = await restMethods.postJson(auth, url, body);
        const result = await response.json();
        return result;

    },

    computeYak: async (auth, subscription_id, project_id, model_id, query) => {

        const body = query;
        // todo: integrate subscription_id and project_id into the yak api
        const url = `${globals.yakApiRoot}/compute`;
        const response = await restMethods.postJson(auth, url, body);
        const result = await response.json();
        if (!result.success) {
            throw Error(result.error);
        }
        return result?.results;

    }

    

}
