import moment from "moment";
import { aGet, getJson } from "../api/restMethods";
import * as globals from "../globals";
import { useAuth } from "./useAuth";
import { useProjectId } from "./useProjectId";


export const useStatsApi = () => {
    
    const auth = useAuth();

    const now = new Date();
    const offset = 0 - now.getTimezoneOffset();

    return {
        getMyLoginStats: async () => {
            //console.log("offset", offset);
            const res = await aGet(auth, `${globals.apiRoot}/my-login-stats?offset=${offset}`);
            const stats = await res.json();
            return stats;
        },

        getProjectLoginStats: async (subscription_id, project_id) => {
            const res = await aGet(auth, `${globals.apiRoot}/subscription/${subscription_id}/project/${project_id}/usage-stats?offset=${offset}`);
            const stats = await res.json();
            return stats;
        }
    }
}
