
export const GetVarsFromExprs = (exprs) => {
    return exprs.reduce((a, e) => {
        const vars = e.GetVars();
        vars?.forEach(v => {
            if( !a.includes(v) ){
                a.push(v);
            }
        })
        return a;
    }, [])
}

export class Condition {
    constructor(){

    }
    Verify = (obj) => { throw Error("not implemented"); }
    GetVars = () => { throw Error("not implemented"); }
    GetSyntax = () => { throw Error("not implemented"); }
}

export class EqualsCondition extends Condition {
    constructor(name, value){
        super();
        this.name = name;
        this.value = value;
    }
    Verify = (obj) => this.value === null ? (obj?.[this.name] === null || obj?.[this.name] === undefined) : obj?.[this.name] === this.value;
    GetVars = () => [this.name];
    GetSyntax = () => `${this.name}==${this.value}`
}

export class IsValidCondition extends Condition {
    constructor(name){
        super();
        this.name = name;
    }
    Verify = (obj) => (obj?.[this.name] !== null && obj?.[this.name] !== undefined);
    GetVars = () => [this.name];
    GetSyntax = () => `isValid(${this.name})`
}


export class AlwaysTrueCondition extends Condition {
    constructor(){
        super();
    }
    Verify = () => true;
    GetVars = () => [];
    GetSyntax = () => `true`
}

export class AndCondition extends Condition {
    constructor(...exprs){
        super();
        this.exprs = exprs;
    }
    Verify = (obj) => {
        for( let expr of this.exprs ){
            const v = expr.Verify(obj);
            if( !v ) return false;
        }
        return true;
    }
    GetVars = () => GetVarsFromExprs(this.exprs);
    GetSyntax = () => `and(...)`
}

export class OrCondition extends Condition {
    constructor(...exprs){
        super();
        this.exprs = exprs;
    }
    Verify = (obj) => {
        for( let expr of this.exprs ){
            const v = expr.Verify(obj);
            if( v ) return true;
        };
        return false;
    }
    GetVars = () => GetVarsFromExprs(this.exprs);
    GetSyntax = () => `or(...)`
}