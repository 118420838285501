
const initialState = {
    socket_client_id: null,
}


export const ApiReducer = (state = initialState, action) => {

    switch( action.type ){
        case 'API_SET_CLIENT_ID' : return {
            ...state,
            socket_client_id: action.client_id
        }
        case "API_SET_VALUE": return {
            ...state,
            [action.name]: action.value
        }
        default:
    }

    return state;
}

