import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCollections } from "../../hooks/useCollection";
import "./CollectionPicker.scss";

export const CollectionPicker = (props) => {

    const routerParams = useParams();
    const project_id = routerParams.project_id;

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const [collections, collectionsLoading, collectionsError] = useCollections();

    return <select
        className={`collection-picker ${props.className}`}
        style={{ ...props.style }}
        value={value}
        onChange={(ev) => {
            setValue(ev.target.value);
            props.onChange?.(ev.target.value);
        }}
    >
        {
            collectionsError ? <option value="-2">Error loading collections</option> :
            collectionsLoading ? <option value="-1">Loading...</option> :
            !collections ? null : <>
            <option>[select collection]</option>
                {collections.map(coll => <option key={coll.id} value={coll.id}>
                    {coll.label}
                </option>)}
            </>
        }
    </select>

}