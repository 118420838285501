import { genericParseEntry } from "../store/CreateGenericReducer";

export const finish = (c) => {

    const name = c.name;
    const namePlural = c.namePlural || c.name + "s";
    const actionName = c.actionName || name.toUpperCase();
    const actionNamePlural = c.actionNamePlural || actionName + "S";
    const storeName = c.storeName || namePlural;
    const parseEntry = c.parseEntry || genericParseEntry;

    return {
        ...c,
        namePlural,
        actionName,
        actionNamePlural,
        storeName,
        parseEntry,
    }
    
}

export const constructs = {

    subscription: finish({
        name: "subscription",
        label: "Subscription",
        icon: "fa-briefcase",
        isSubscription: true,
        isProjectItem: false
    }),

    collection: finish({
        name: "collection",
        label: "Collection",
        icon: "fa-album-collection",
        isResource: true,
    }),

    "content-item": finish({
        name: "content-item",
        apiName: "content", // temporary
        apiNamePlural: "contents",
        actionName: "CONTENTITEM",
        //parents: ["page"],
        storeName: "contentItems"
    }),

    "data-source": finish({
        name: "data-source",
        label: "DataSource",
        parseEntry: (value) => ({
            id: value.id,
            label: value.label,
            type: value.type,
            fileType: value.fileType,
            structured: value.structured
        }),
        //actionName: "DATA-SOURCES",
        storeName: "dataSources",
        icon: "fa-database",
        isResource: true
    }),

    "data-source-revision": finish({
        //name: "data-source-revision",
        name: "data-source-revision",
        apiName: "revision",
        namePlural: "revisions",
        label: "DataSourceRevision",
        //parent: "data-source", // for childitemshook (experimental)
        parents: ["data-source"],
        parseEntry: (value) => ({
            id: value.id,
            label: value.label,
            file_info: value.file_info,
            created: value.created,
            mounted: value.mounted,
            working: value.working,
            working_status: value.working_status,
            error: value.error,
            notes: value.notes,
            author: value.author
        }),
        reduxName: "DATA-SOURCE-REVISIONS",
        actionName: "DATA-SOURCE-REVISION",
        storeName: "dataSourceRevisions",
        icon: "fa-database",
        isResource: false
    }),

    image: finish({
        name: "image",
        label: "Image",
        parseEntry: (value) => ({
            id: value.id,
            label: value.label,
            filename: value.filename
        }),
        hasFilename: true, // has an attached blob
        icon: "fa-image",
        isResource: true
    }),

    page: finish({
        name: "page",
        label: "Page",
        parseEntry: (value) => ({
            id: value.id,
            label: value.label,
            url: value.url,
            showInMenu: value.showInMenu,
            hasChildren: value.children?.length > 0
        }),
        hasUrl: true,
        isHierarchy: true,
        getItemsFull: true,
        isResource: false
    }),

    palette: finish({
        name: "palette",
        label: "Color Palette",
        icon: "fa-swatchbook",
        isResource: true
    }),

    pipeline: finish({
        name: "pipeline",
        label: "Saved Pipeline",
        icon: "fa-swatchbook",
        isResource: true
    }),

    project: finish({
        name: "project",
        isProject: true, // top-level project (subscription is higher level)
        parseEntry: (value) => ({
            id: value.id,
            label: value.label,
            client: value.client,
            date: value.date,
            created: value.created
        }),
    }),

    config: finish({
        name: "config",
        label: "Config",
        storeName: "configs",
        icon: "fa-sliders-h",
        isResource: false
    }),

    model: finish({
        name: "model",
        label: "Model",
        storeName: "models",
        icon: "fa-cube",
        isResource: true
    }),

    selection: finish({
        name: "selection",
        label: "User state",
        storeName: "selections",
        icon: "fa-hand-pointer",
        isResource: true
    }),

    "filter-book": finish({
        name: "filter-book",
        label: "Filter Book",
        //actionName: "DATA-SOURCES",
        storeName: "filterBooks",
        icon: "fa-filter",
        isResource: false
    }),
    
};
