import { Link } from "react-router-dom";
import { docLinks } from "../../../doclinks";
import { useDataSources } from "../../../hooks/useDataSource";
import { useProjectId } from "../../../hooks/useProjectId";

export const DataSourcesTile = (props) => {

    const { subscription_id, project_id } = useProjectId();
    const [dataSources, dataSourcesLoading, dataSourcesError] = useDataSources();

    return <div className="tile white-bg pad">
        <div className="title">
            Data Sources

            <div className="help-link">
                <a href={docLinks.data_management} target="_blank" className="icon">
                    <i className="fal fa-question-circle"/>
                </a>
            </div>
        </div>
        

        <div className="details">

            {dataSourcesLoading ? <span className="loading">loading...</span> : null}
            
            <div>
            {dataSources?.map(ds => 
                <Link
                    key={ds.id}
                    className="item-row"
                    to={`/subscription/${subscription_id}/project/${project_id}/data-source/${ds.id}`}
                >
                    <i className={ds.type === "flow" ? "fad fa-random" : "fad fa-database"}/>
                    &nbsp; {ds.label}
                </Link>
                )}
            </div>

        </div>
        
        <Link className="edit-link" to={`/subscription/${subscription_id}/project/${project_id}/data`}>
            Edit data sources...
        </Link>

        
    </div>
}