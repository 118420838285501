import Popup from "reactjs-popup";
import { constructs } from "../../../api/contructs";
import { useResource } from "../../../hooks/useResource";
import { ResourceInputList } from "./ResourceInputList";
import "./ResourcePropertyEditor.scss";
 

const getIcon = (resourceType) => {
    const c = constructs[resourceType];
    return <i className={`fas ${c.icon}`} />;
}

const wrapMessage = (message) => <div className="resource-property-editor">
    <span className="label">
        {message}
    </span>
</div>;

export const ResourcePropertyEditor = (props) => {

    const type = props.type;
    const id = props.value;

    const [item, itemLoading, itemError] = useResource(type, id);

    if (itemLoading)
        return wrapMessage("Loading...");

    if (id && itemError)
        return wrapMessage("Error loading resource");
    
    return <Popup
        keepTooltipInside={true}
        position="bottom left"
        contentStyle={{ width: 400 }}
        trigger={
            <div className="resource-property-editor">
                <span className="icon">
                    {getIcon(type)}
                </span>
                <span className="label">
                    {(id && item) ? item.label : "(select...)"}
                </span>
            </div>
        }
    >
        {close =>
            <ResourceInputList
                selectedId={id}
                type={type}
                onChange={(newId) => {
                    props.onChange?.(newId);
                    close();
                }}
            />
        }
    </Popup>
    
}