import React from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';


export const FakeChart1 = (props) => {

    const data = [
    {
        name: 'Seg 1',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Seg 2',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Seg 3',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Seg 4',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Seg 5',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    ];
    

    return (
        <BarChart
          width={380}
          height={275}
            data={data}
            barGap={0}
          margin={{
            top: 20,
            right: 20,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name"  />
          <YAxis width={50} />
          <Tooltip />
          <Bar dataKey="pv" fill="#00A3AD" />
          <Bar dataKey="uv" fill="#0078BE" />
        </BarChart>

    );

  
}


