import { useContext, useEffect, useMemo, useState } from "react";
import { PipelineEditorContext, PipelineStageEditorContext } from "../../../../context/Contexts";
import { debounce } from "../../../../utils/debounce";
import { computeApi } from "../../../../api/computeApi";
import { useAuth } from "../../../../hooks/useAuth";
import { useProjectId } from "../../../../hooks/useProjectId";
//import SplitterLayout from "react-splitter-layout";
import SplitterLayout from "../../../extlib/react-splitter-layout/SplitterLayout";
import "./EditGroups.scss";
import { FieldSearch } from "../../../common/FieldSearch";
import { GetDistinctValues } from "../../../../utils/FieldValuesUtil";
import { newId } from "../../../../utils/uuid";
import { UseStageInspection } from "../../UseStageInspection";
import { SelectFieldsContext, SelectFieldsList } from "../../../common/SelectFieldsList";
import JSON5 from "json5";
import Popup from "reactjs-popup";
import { EditAggItems, EditAggItemsContext } from "./EditAggItems";
import { InspectionContext, StagesInspectButton } from "../inspector/StagesInspectButton";
import { INSPECTION_NOTES } from "./EditMeasures";
import { autoTrim } from "../../../data-sources/ViewFieldsHelper";

const AGG_TABS = [
    { id: "spec", label: "Specify Groups", starDim: false },
    { id: "star", label: "Dynamic", starDim: true },
]


export const EditGroups = (props) => {

    const pipelineContext = useContext(PipelineEditorContext);
    const stageContext = useContext(PipelineStageEditorContext);
    const inspectionContext = useContext(InspectionContext);

    const [aggTab, setAggTab] = useState(AGG_TABS[0].id);

    const { dataSource, revision } = pipelineContext;

    //const [aggTab, setAggTab] = useState(AGG_TABS[0].id);
    
    const dim = props.dim;
    console.log("dim", dim);
    const [selFieldNames, setSelFieldNames] = useState(dim?.starDim ? [dim?.starField] : []);
    const [selAvailableGroups, setSelAvailableAggs] = useState([]);
    const [selGroups, setSelGroups] = useState(dim?.items);
    const [axisId, setAxisId] = useState(dim?.id);
    const [starDim, setStarDim] = useState(dim?.starDim);
    const [starField, setStarField] = useState(dim?.starField);
    const specDim = !starDim;

    console.log("starDim", starDim);
    console.log("starField", starField);
    console.log("selFieldNames", selFieldNames);

    const [selGroupIds, setSelGroupIds] = useState([]);

    const [editAggItemsContext, setEditAggItemsContext] = useState(null);
    const [aggItemsEditorOpen, setAggItemsEditorOpen] = useState(false);


    //const _fields = revision?.fields;
    // const [fields] = UseStageInspection(stageContext?.priorStages);
    // const _fields = fields;
    const __inspection = inspectionContext?.inspection;
    const _fields = useMemo(() => {
        return __inspection ? Object.keys(__inspection)?.map(name => ({ name })) : revision?.fields;
    }, [__inspection]) 


    const fieldDic = useMemo(() => {
        return _fields?.reduce((accum, f) => {
            accum[f.name] = f;
            return accum;
        }, {});
    }, [_fields]);

    const inspection = revision?.inspection;

    // calculate values
    const selFields = useMemo(() => {
        return selFieldNames?.map(name => fieldDic?.[name]);
    }, [selFieldNames]);

    const distinctValues = useMemo(() => {
        return GetDistinctValues(selFields, inspection);
    }, [selFields])


    if( !dim ) return null;

    const save = () => {
        console.log("save from within editgroups", axisId);

        if( starDim ){
            let newDim = {
                ...dim,
                id: axisId,
                starDim: true,
                starField: selFieldNames?.[0],
                items: undefined
            };
            delete newDim.items;
            console.log("saved dim", newDim);
            props.saveDim?.(dim, newDim);
        }
        else{
            let newDim = {
                ...dim,
                id: axisId,
                starDim: undefined,
                starField: undefined,
                items: selGroups?.map((g, idx) => {
                    let newGroup = { ... g };
                    delete newGroup.__fieldName;
                    return newGroup;
                })
            };
            delete newDim.starDim;
            delete newDim.starField;
            console.log("saved dim", newDim);
            props.saveDim?.(dim, newDim);
        }
    }

    const okay = () => {
        save();
        props.close?.(true);
    }

    const cancel = () => {
        props.close?.();
    }

    const addGroups = (newGroups) => {
        setSelGroups([...(selGroups || []), ...(newGroups || [])]);
    }

    const deleteSel = () => {

        // if nothing is selected, select everything so a second click can delete all of them
        if( !selGroupIds?.length ){
            setSelGroupIds(selGroups.map(g => g._id));
        }
        else{
            const newGroups = selGroups.filter(g => !selGroupIds.includes(g._id));
            setSelGroups(newGroups);
            setSelGroupIds([]);
        }
    }

    const removeFieldName = (label, fieldName) => {
        if( !label || label?.trim?.() === "") return label;
        let newLabel = label;
        if( label?.startsWith?.(fieldName + ": ") ){
            newLabel = label.substring(fieldName.length + 2 );
        }
        else if( label?.startsWith?.(fieldName) ){
            newLabel = label.substring(fieldName.length);
        }

        if( newLabel?.trim?.() != "" ) return newLabel;
        return label;
    }


    const moveRight = () => {
        const singleFieldSelected = selFields?.length === 1;
        const multipleFieldsSelected = selFields?.length > 1;

        const groupItems = selAvailableGroups?.map(aa => distinctValues.find(dv => `${dv.val}` === aa));
        //console.log("aggItems", aggItems);

        const singleGroupSelected = groupItems?.length === 1;
        //const multipleAggsSelected = groupItems?.length > 1;

        let newGroups = [];
        selFields?.forEach(selField => {
            groupItems?.forEach(groupItem => {
                if( selField && groupItem ){
                    const newGroup = {
                        _id: newId(),
                        label: removeFieldName(
                            singleFieldSelected ? groupItem.label : 
                            singleGroupSelected ? (selField.label || selField.name) :
                            `${(selField.label || selField.name)}: ${groupItem.label}`,
                            selField.name
                        )
                        ,
                        syntax: typeof(groupItem.val) === "string" ? 
                            `${selField.name}=="${groupItem.val}"`
                            : `${selField.name}==${groupItem.val}`,
                        __fieldName: selField.name
                    };
                    newGroups.push(newGroup);
                }
            })
        })
        addGroups(newGroups);
        setSelGroupIds(newGroups.map(g => g._id));
    }

    const createOnClick = (getSyntax, applySyntax, options) => {

        return () => {

            console.log("selGroupIds", selGroupIds);
            const groups = selGroups
                .map((g, __index) => ({ ...g, __index }))
                .filter(g => selGroupIds.includes(g._id));
            console.log("groups", groups);

            const strs = groups?.map(getSyntax);
            const str = JSON.stringify(strs, null, 4);
            
            setEditAggItemsContext({
                syntax: str,
                options,
                items: groups,
                saveSyntax: (newStr) => {
                    console.log("saveSyntax", newStr);

                    const newStrs = JSON5.parse(newStr);

                    let newGroups = [...selGroups];
                    console.log("")
                    let selIds = [];
                    groups.forEach((g, idx) => {
                        let newItem = {
                            ...g,
                            //label: labels?.[idx]?.trim()
                        }
                        applySyntax(newItem, newStrs[idx]);
                        if( newItem.__replace ){
                            newItem = newItem.__replace;
                        }
                        delete newItem.__index;
                        newGroups[g.__index] = newItem;
                        selIds.push(newGroups[g.__index]._id);
                    })
                    console.log('selIds', selIds);
                    setSelGroups(newGroups);
                    setSelGroupIds(selIds);
                }
            })
            setAggItemsEditorOpen(true);
        };
    }

    const autoTrimLabels = () => {
        
        console.log("selGroups", selGroups);
        
        const groups = selGroups
            .map((g, __index) => ({ ...g, __index }))
            .filter(g => selGroupIds.includes(g._id));

        console.log("groups", groups);


        const strs = groups?.map(g => {
            return g.label?.replace?.(g.__fieldName, "[var]");
        });
        const newStrs = autoTrim(strs);
        for( let i = 0; i < groups.length; i++ ){
            const g = groups[i];
            selGroups[g.__index].label = newStrs[i];
        }
        
        setSelGroups(selGroups.map(g => g)); // to refresh the listbox
        
    }


    return <div className="edit-groups">

        {/* <div className="title">
            <span 
                className={`btn icon ${starDim ? "sel" : ""}`}
                onClick={() => {
                    setStarDim(!starDim);
                }}
            >
                <i className="fal fa-asterisk"/>
            </span>
            {starDim && <span style={{ marginLeft: 5, fontWeight: 600 }}>
                {starField}
            </span>}
        </div> */}
        
        <div className="content1">
            
            <SplitterLayout
                vertical={false}

                primaryIndex={1}
                percentage={true}
                xprimaryMinSize={50}
                secondaryInitialSize={33}
                onSecondaryPaneSizeChange={(secondaryPaneSize) => {
                //setPreviewSize(secondaryPaneSize);
                }}
            >
                <div className="input-section">
                    <div className="axis-id">
                        <span className="label">
                            Groups Axis Id:
                        </span>
                        <input type="text" value={axisId} onChange={ev => setAxisId(ev.target.value)} />
                    </div>
                    <div className="title">
                        Available Field(s)
                    </div>
                    <div className="actions">
                        <StagesInspectButton />
                        <div className="inspection-note">
                            <i className="fas fa-warning" />&nbsp;
                            { __inspection ? INSPECTION_NOTES.INSP : INSPECTION_NOTES.DS }
                        </div> 
                    </div>
                    <div className="list">
                        <SelectFieldsContext.Provider value={{ 
                            fields: _fields,
                            selectedFieldNames: selFieldNames,
                            setSelectedFieldNames: (x) => {
                                
                                if( starDim ){
                                    // for helpfulness: change axis id to name of first selected var
                                    if( x?.length ){
                                        setAxisId(x?.[0])
                                    }
                                }

                                setSelFieldNames(x);
                            }
                        }}>
                            <SelectFieldsList />
                        </SelectFieldsContext.Provider>
                    </div>
                </div>
                
                <SplitterLayout
                    vertical={false}
                    primaryIndex={1}
                    percentage={true}
                    xprimaryMinSize={50}
                    secondaryInitialSize={50}
                >
                    <div className="input-section">
                        <div className="title">
                            Available Groups
                            {/* <span 
                                    className={`btn icon ${starDim ? "sel" : ""}`}
                                    onClick={() => {
                                        setStarDim(!starDim);
                                        // for helpfulness: change axis id to name of first selected var
                                        if( selFieldNames?.length ){
                                            setAxisId(selFieldNames[0]);
                                        }

                                    }}
                                >
                                    <i className="fal fa-asterisk"/>
                            </span> */}
                        </div>
                        <div className="actions">
                            <div className="section-title">
                                {AGG_TABS.map(tab =>
                                    <div 
                                        key={tab.id} 
                                        className={`tab ${(starDim||false) == tab.starDim ? "sel" : ""}`}
                                        onClick={() => {
                                            setStarDim(tab.starDim);
                                            // for helpfulness: change axis id to name of first selected var
                                            if( tab.starDim && selFieldNames?.length ){
                                                setAxisId(selFieldNames[0]);
                                            }
                                        }}
                                    >
                                        {tab.label}
                                        {tab.starDim && <i className="fal fa-asterisk" style={{paddingLeft: 7 }}/>}
                                    </div>    
                                )}
                            </div>
                        </div>
                        {specDim ?

                            <>
                                <div className="arrows">
                                    <span />
                                    <span className="btn1"
                                        onClick={() => moveRight()}
                                    >
                                        <i className="fas fa-arrow-right fa-2x"/>
                                    </span>
                                    <span className="btn1" style={{ fontSize: 10 }}>
                                        <i className="fas fa-arrow-right" />
                                        <i className="fas fa-ellipsis blk" />
                                    </span>
                                    <span className="btn1" style={{ fontSize: 10 }}
                                        onClick={() => deleteSel()}
                                    >
                                        <i className="fas fa-arrow-left blk" />
                                        {selGroupIds.length > 0 ? <i key={1} className="fas fa-trash-alt blk" /> : <i key={2} className="fal fa-trash-alt blk" />}
                                        
                                    </span>
                                    <span />
                                </div>
                                
                                <div className="list">
                                    <select
                                        multiple
                                        className="groups-select"
                                        value={selAvailableGroups}
                                        onChange={(ev) => {
                                            const options = ev.target.options;
                                            let newVal = [];
                                            for(let option of options){
                                                if( option.selected ){
                                                    newVal.push(option.value);
                                                }
                                            }
                                            setSelAvailableAggs(newVal);
                                        }}
                                    >
                                        {
                                            distinctValues?.map(dv =>
                                                <option key={dv.val} value={dv.val} className="item group">
                                                    {dv.label || dv.val}
                                                </option>
                                            )
                                        }
                                    </select>
                                </div>
                            </> 
                            : 
                            <>
                                <div className="list">
                                    <div style={{ padding: 10 }}>
                                        Star field: <b>{selFieldNames?.[0] || "not set"}</b>
                                    </div>
                                    <div style={{ padding: 10, paddingTop: 0 }}>
                                        This axis will contain all discovered occurances of the above star field.
                                    </div>
                                    <div style={{ padding: 10, paddingTop: 0 }}>
                                        This axis cannot be used in columns container.
                                    </div>
                                    
                                    
                                </div>
                            </>
                        }
                    </div>

                    {specDim ? 
                        <div className="input-section">
                            <div className="title">
                                Selected Groups
                            </div>
                            <Popup
                                modal
                                closeOnDocumentClick={false}
                                closeOnEscape={false}
                                open={aggItemsEditorOpen}
                            >
                                <EditAggItemsContext.Provider value={editAggItemsContext}>
                                    <EditAggItems close={() => setAggItemsEditorOpen(false)} />
                                </EditAggItemsContext.Provider>
                            </Popup>
                            <div className="actions">
                                <span className="btn2-strip" >
                                    <span className="btn2" 
                                        onClick={createOnClick(
                                            (g) => g.label,
                                            (g, data) => { g.label = data },
                                            { isStringArray: true, compareProp: "label" }
                                        )}
                                    >
                                        Label
                                    </span>
                                    <span className="btn2 icon" onClick={() => autoTrimLabels()}>
                                        Auto-trim
                                    </span>
                                    <span className="btn2" onClick={createOnClick(
                                            (g) => g.id,
                                            (g, data) => { g.id = data },
                                            { isStringArray: true, compareProp: "id" }
                                        )}>
                                        id
                                    </span>
                                    <span className="btn2" onClick={createOnClick(
                                            (g) => g.syntax,
                                            (g, data) => { g.syntax = data },
                                            { isStringArray: true, compareProp: "syntax" }
                                        )}>
                                        Syntax
                                    </span>
                                    <span className="btn2" onClick={createOnClick(
                                            (g) => {
                                                let newGroup = { ...g };
                                                delete newGroup._id;
                                                delete newGroup.__index;
                                                console.log("newGroup", newGroup);
                                                return newGroup;
                                            },
                                            (g, data) => { g.__replace = { ...data, _id: newId() } }
                                        )}>
                                        ...
                                    </span>
                                    <span style={{ flexGrow: 1 }}>
                                    </span>
                                
                                    <span className="btn2 icon" onClick={() => null}>
                                        <i className="fal fa-copy"/>
                                    </span>
                                    {/* <span className="btn2" onClick={() => null}>
                                        <i className="fal fa-paste"/> Paste
                                    </span> */}
                                    {/* <span className="btn2 icon" onClick={() => null}>
                                        <i className="fal fa-arrow-down-a-z"/>
                                    </span> */}
                                    <span className="btn2 icon" onClick={() => null}>
                                        <i className="fal fa-arrow-up"/>
                                    </span>
                                    <span className="btn2 icon" onClick={() => null}>
                                        <i className="fal fa-arrow-down"/>
                                    </span>
                                    <span className="btn2 icon" onClick={() => null}>
                                        <i className="fal fa-ellipsis"/>
                                    </span>
                                    <span className="btn2 icon"
                                        onClick={() => deleteSel()}
                                    >
                                        {selGroupIds.length > 0 ? <i key={1} className="fa fa-trash-alt" /> : <i key={2} className="fal fa-trash-alt" />}
                                    </span>
                                </span>
                                {/* <div style={{ float: "right", marginRight: 20 }}>
                                    <span className="btn icon"
                                        onClick={() => deleteSel()}
                                    >
                                        {selGroupIds.length > 0 ? <i key={1} className="fa fa-trash-alt" /> : <i key={2} className="fal fa-trash-alt" />}
                                    </span>
                                </div> */}
                            </div>
                            <div className="list">
                                <select
                                    multiple
                                    className="groups-select"
                                    value={selGroupIds}
                                    onChange={(ev) => {
                                        const options = ev.target.options;
                                        let newVal = [];
                                        for(let option of options){
                                            if( option.selected ){
                                                newVal.push(option.value);
                                            }
                                        }
                                        setSelGroupIds(newVal);
                                    }}
                                    onKeyDown={(ev) => {
                                        if( ev.code === "Delete" ){
                                            deleteSel();
                                        }
                                    }}
                                >
                                    {selGroups?.map(g =>
                                        <option key={g._id} value={g._id} className="item group">
                                            {g.id && `[${g.id}] `}
                                            {g.label}
                                        </option>
                                    )}
                                </select>
                                
                            </div>
                            {true &&
                                <div className="footer">
                                    {selGroupIds.length === 1 ? 
                                        <span>
                                            {selGroups?.find(a => a._id == selGroupIds[0])?.syntax || "[syntax missing]"}
                                        </span> :
                                        <span>
                                            {selGroupIds.length} group(s) selected
                                        </span>
                                    }
                                </div>
                            }

                        </div>
                        :
                        null 
                        /* starfield - don't show anything in third column */
                    }

                </SplitterLayout>

                

            </SplitterLayout>
        </div>
        <div className="bottom-section">
            
                <span className="btn action margin-right" onClick={() => okay()}>
                    OK
                </span>

                <span className="btn action" onClick={() => cancel()}>
                    Cancel
                </span>
            
        </div>
        
    </div>
    
}