import { io } from "socket.io-client";
import { constructs } from "./contructs";
import * as globals from "../globals";


export const subscriptionSocketApi = {

    connect: (subscription_id, messageCallback, dispatch) => {

        console.log(`connect to subscription ${subscription_id}`)
        const socket = io(`${globals.webSocketEndpoint}/subscription/${subscription_id}`);
        
        socket.on("connect", () => {
            console.log(`Subscription[${subscription_id}] socket ${socket.id} connected=${socket.connected}`);
            const action = {
                type: `SUBSCRIPTION_SOCKET_UPDATE`,
                patch: {
                    id: socket.id
                }
            };
            dispatch(action);
        })
    
        socket.on("connect_error", () => {
            console.log(`Subscription[${subscription_id}] socket connection error`);
        })
    
        socket.on("disconnect", () => {
            console.log(`Subscription[${subscription_id}] socket disconnected`);
        })
 
        socket.on("notif", (payload) => {

            if( payload?.action === "NEW_CLIENT_CONNECTED" ){
                if( socket.id !== payload.new_socket_id ){
                    console.log("new client connected to subscription: ", payload);
                }
                else{
                    console.log("server sent a message that my client connected to subscription", payload);
                }
                const action = {
                    type: `SUBSCRIPTION_SOCKET_UPDATE`,
                    patch: {
                        nbClients: payload.nbClients
                    }
                };
                dispatch(action);
            }
            else if( payload?.action === "CLIENT_DISCONNECTED" ){
                const action = {
                    type: `SUBSCRIPTION_SOCKET_UPDATE`,
                    patch: {
                        nbClients: payload.nbClients
                    }
                }
                dispatch(action);
            }
            // else if( payload.action === "NEW_ITEM" ){

            //     console.log('handling NEW_ITEM', payload);
            //     const construct = constructs[payload.item_type];
            //     if( construct ){
            //         const action = {
            //             type: `${construct.actionNamePlural}_ADD_ENTRY`,
            //             entry: {
            //                 ...construct.parseEntry(payload.item),
            //                 id: payload.item_id
            //             }
            //             // it doesn't add the item into the dict, but it seems like it could
            //         };
            //         dispatch(action);
            //     }

            // }
            // else if( payload.action === "DELETE_ITEM" ){

            //     console.log('handling DELETE_ITEM', payload);
            //     const construct = constructs[payload.item_type];
            //     if( construct ){
            //         const action = {
            //             type: `${construct.actionName}_DELETE`,
            //             id: payload.item_id
            //         };
            //         dispatch(action);
            //     }

            // }
            // else if( payload.action === "REPLACE_ITEM" ){

            //     console.log("handling subscription REPLACE_ITEM", payload);
            //     const construct = constructs[payload.item_type];
            //     if( construct ){
            //         const value = {
            //             ...payload.item,
            //             id: payload.item_id
            //         };
            //         const action = {
            //             type: `${construct.actionName}_SET`,
            //             id: payload.item_id,
            //             entry: construct.parseEntry(value),
            //             value
            //         };
            //         dispatch(action);
            //     }
            // }
            // else if( payload.action === "PATCH_ITEM" ){

            // }
            else{
                console.log(`Subscription[${subscription_id}] socket UNKNOWN notif received:`, payload);;
            }
            messageCallback?.(payload);
        })

        return socket;
    },

    disconnect: (subscription_id) => {
        console.log(`todo: disconnect from subscription ${subscription_id}`)
    }

}