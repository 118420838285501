import { io } from "socket.io-client";
import { constructs } from "./contructs";
import * as globals from "../globals";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../hooks/useAuth";


export const useSubscriptionSocket = (subscription_id) => {

    const [SOCKET, setSOCKET] = useState(null);
    const dispatch = useDispatch();
    const auth = useAuth();

    useEffect(() => {
        if( !subscription_id ) return null;

        // disconnect from previous socket if exists
        if( SOCKET ){
            SOCKET.disconnect();
        }

        // establish socket
        const socket = io(
            `${globals.webSocketEndpoint}/subscription/${subscription_id}`,
            { 
                auth: {
                    "authorization": auth.token
                }
            }
        );
        
        socket.on("connect", () => {
            console.log(`** Subscription[${subscription_id}] socket ${socket.id} connected=${socket.connected}`);
            setSOCKET(socket);
        })
    
        socket.on("connect_error", (err) => {
            console.log(`** Subscription[${subscription_id}] socket connection error`, err);
        })
    
        socket.on("disconnect", () => {
            console.log(`** Subscription[${subscription_id}] socket disconnected`);
            setSOCKET(null);
        })
 
        socket.on("notif", (payload) => {

            if( payload?.action === "NEW_CLIENT_CONNECTED" ){
                if( socket.id !== payload.socket_id ){
                    console.log(`new client ${payload.socket_id} connected to subscription (not me ${socket.id})`);
                }
                const action = {
                    type: `SUBSCRIPTION_SOCKET_UPDATE`,
                    patch: {
                        nbClients: payload.nbClients
                    }
                };
                dispatch(action);
            }
            else if( payload?.action === "CLIENT_DISCONNECTED" ){
                if( socket.id !== payload.socket_id ){
                    console.log(`** Client ${payload.socket_id} disconnected from subscription ${subscription_id}`);
                }
                const action = {
                    type: `SUBSCRIPTION_SOCKET_UPDATE`,
                    patch: {
                        nbClients: payload.nbClients
                    }
                }
                dispatch(action);
            }
            // else if( payload.action === "NEW_ITEM" ){

            //     console.log('handling NEW_ITEM', payload);
            //     const construct = constructs[payload.item_type];
            //     if( construct ){
            //         const action = {
            //             type: `${construct.actionNamePlural}_ADD_ENTRY`,
            //             entry: {
            //                 ...construct.parseEntry(payload.item),
            //                 id: payload.item_id
            //             }
            //             // it doesn't add the item into the dict, but it seems like it could
            //         };
            //         dispatch(action);
            //     }

            // }
            // else if( payload.action === "DELETE_ITEM" ){

            //     console.log('handling DELETE_ITEM', payload);
            //     const construct = constructs[payload.item_type];
            //     if( construct ){
            //         const action = {
            //             type: `${construct.actionName}_DELETE`,
            //             id: payload.item_id
            //         };
            //         dispatch(action);
            //     }

            // }
            else if( payload.action === "REPLACE_ITEM" ){

                // ignore notification if self sent
                if( payload.sender_id === socket.id ) return;

                console.log("Handling subscription REPLACE_ITEM", payload);
                const construct = constructs[payload.item_type];
                if( construct ){
                    const value = {
                        ...payload.item,
                        id: payload.item_id
                    };
                    const action = {
                        type: `${construct.actionName}_SET`,
                        id: payload.item_id,
                        entry: construct.parseEntry(value),
                        value
                    };
                    dispatch(action);
                }
            }
            else if( payload.action === "PATCH_ITEM" ){

                // ignore notification if self-sent
                if( payload.sender_id === socket.id ) return;

                console.log("Handling subscription PATCH_ITEM", payload);
                const construct = constructs[payload.item_type];
                if( construct ){
                    const value = {
                        ...payload.item,
                        id: payload.item_id
                    }
                    const action = {
                        type: `${construct.actionName}_PATCH`,
                        id: payload.item_id,
                        // parent_id? can't think of a sub-level construct that would need it
                        value
                    }
                    dispatch(action);
                }

            }
            else{
                console.log(`Subscription[${subscription_id}] socket [UNKNOWN] notif received:`, payload);;
            }
            //messageCallback?.(payload);
        })

        // cleanup
        return () => {
            console.log(`** Subscription[${subscription_id}] socket[${socket?.id}] cleanup`);
            socket?.disconnect();
            setSOCKET(null);
        }
        
    }, [subscription_id])

    return SOCKET?.id;
    
}